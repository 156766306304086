import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { useTranslation } from "react-i18next";
import { Paper, Tooltip } from "@mui/material";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { cloneDeep, getTailwindColor, truncate } from "../../../../Helper/data";
import CategoryTreeSelect from "../../CategoryTreeSelect";
import FilterBaseView from "../FilterBaseView";
import theme from "../../../../theme";

const CategoryFilterView = ({
  heading = "Category_Filter",
  tooltip = "tooltip_Category_Filter",
  categoryType = 3,
  selectedIds = [],
  onClickReset,
  onOpen,
  onClose,
  onChangeValue,
  hideHeading,
  maxWidth = "fit-content",
  isFilterOpen,
  isMultiple,
  hideUncategorize,
}) => {
  const { t } = useTranslation();
  const _selectionCategories = useSelector(
    (state) => state.categorySlice.selectionCategories
  );
  const selectionCategories = useMemo(
    () => [
      {
        uuid: "unCategorized_category",
        title: "unCategorized_category",
        immutable: true,
        color: "slate",
      },
      ..._selectionCategories,
    ],
    [_selectionCategories]
  );

  //state
  const [anchorEl, setAnchorEl] = useState(null);
  const Filtered = useMemo(
    () => selectionCategories?.filter((o1) => selectedIds?.includes(o1?.uuid)),
    [selectedIds, selectionCategories]
  );
  const allView = useMemo(() => {
    if (selectedIds?.length > 0) {
      return Filtered?.map((item, index) => {
        const title = item?.immutable ? t(item?.title) : item?.title;
        return (
          <div
            key={item?.uuid}
            style={{
              marginRight: index !== Filtered.length - 1 ? "0.25rem" : 0,
              width: "fit-content",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              display: "block",
              borderRadius: theme.borderRadius.borderRadiusXL,
              paddingInline: "8px",
              fontSize: "0.8rem",
              lineHeight: "0.8rem",
              paddingBlock: "0.35rem",
              marginTop: "1px",
              border: `1px solid ${getTailwindColor(item?.color, 300)}`,
              backgroundColor: getTailwindColor(item?.color, 100),
              color: item?.color,
            }}
          >
            {truncate(title, 50)}
          </div>
        );
      });
    }

    return "";
  }, [Filtered, selectedIds?.length, t]);

  const VALUE = useMemo(() => {
    const obj = {
      value:
        isMultiple && selectedIds?.length > 1 ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: "4px",
              borderRadius: theme.borderRadius.borderRadiusXL,
              paddingInline: "8px",
              paddingBlock: "4px",
              backgroundColor: theme.palette.color.slate[200],
              color: theme.palette.color.slate[600],
            }}
          >
            {t("Multiple")}
          </div>
        ) : (
          allView
        ),
    };
    return obj;
  }, [allView, isMultiple, selectedIds?.length, t]);

  const tooltipView = useMemo(() => {
    return (
      <Paper
        elevation={1}
        sx={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          width: "22rem",
          height: "fit-content",
          gap: "0.5rem",
          p: "0.75rem",
          borderRadius: "4px",
        }}
      >
        {allView}
      </Paper>
    );
  }, [allView]);

  // const isActive = selectedIds?.length > 0;

  //function
  const onClickCategoryTitle = (e, item) => {
    let data = selectedIds ? cloneDeep(selectedIds) : [];

    const find = selectedIds?.filter((o1) => o1 === item?.uuid);
    if (find?.length > 0) {
      data = data?.filter((o1) => o1 !== item?.uuid);
    } else {
      data.push(item?.uuid);
    }
    onChangeValue(data);
  };

  const onOpenDropDown = (e) => {
    e.stopPropagation();
    if (isFilterOpen) {
      isFilterOpen.current = true;
    }
    setAnchorEl(e.currentTarget);
    if (onOpen) onOpen();
  };

  const onCloseDropDown = (e) => {
    if (isFilterOpen) {
      isFilterOpen.current = false;
    }
    setAnchorEl(null);
    if (onClose) onClose();
  };

  return (
    <>
      <CategoryTreeSelect
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        anchorEl={anchorEl}
        type={categoryType}
        handleClosePopOver={onCloseDropDown}
        onClickCategoryTitle={onClickCategoryTitle}
        allowParentCategory
        isSelect
        hideUncategorize={hideUncategorize}
      />
      <FilterBaseView
        icon={<LocalOfferIcon style={{ fontSize: "1rem", marginTop: "2px" }} />}
        heading={hideHeading ? null : heading}
        tooltip={tooltip}
        maxWidth={maxWidth}
        // isActive={isActive}
        isActive={false}
        value={VALUE?.value}
        allValue={isMultiple ? tooltipView : null}
        onClick={onOpenDropDown}
        onClear={onClickReset}
      />
    </>
  );
  // return (
  //   <Box
  //     onClick={onOpenDropDown}
  //     component="span"
  //     variant="span"
  //     sx={{
  //       ml: "1rem",
  //       display: "flex",
  //       alignItems: "center",
  //       color: getTailwindColor(color, 500),
  //       border: selected
  //         ? `${borderWidth} solid ${getTailwindColor(color, 600)}`
  //         : `${borderWidth} dashed ${getTailwindColor(color, 300)}`,
  //       cursor: "pointer",
  //       backgroundColor: selected ? Color.white : "transparent",
  //       borderRadius,
  //       position: "relative",
  //       ...sx,
  //     }}
  //   >
  //     {hideIcon ? null : (
  //       <LocalOfferIcon
  //         sx={{
  //           fontSize: "1.2rem",
  //           color: Color.themeColor,
  //           ml: "0.5rem",
  //           opacity: activeFilter === "category" ? 1 : 0.2,
  //         }}
  //       />
  //     )}

  //     <CategoryTreeSelect
  //       anchorOrigin={{
  //         vertical: "bottom",
  //         horizontal: "center",
  //       }}
  //       transformOrigin={{
  //         vertical: "top",
  //         horizontal: "center",
  //       }}
  //       anchorEl={anchorEl}
  //       handleClosePopOver={onCloseDropDown}
  //       onClickCategoryTitle={onClickCategoryTitle}
  //       type={categoryType}
  //       allowParentCategory={allowParentCategory}
  //       isSelect={isSelect}
  //       height={height}
  //     />

  //     <Tooltip title={t(tooltip)} disableFocusListener arrow placement="top">
  //       <Box
  //         sx={{
  //           display: "inline-flex",
  //           width: width,
  //           height: "2rem",
  //           alignItems: "center",
  //           borderRadius,
  //           scrollbarWidth: "none",
  //           "&::-webkit-scrollbar": {
  //             width: "0px",
  //             height: "0px",
  //             background: "transparent" /* make scrollbar transparent */,
  //           },
  //           overflowX: "scroll",
  //         }}
  //         onClick={onClick}
  //       >
  //         {value?.length > 0 ? (
  //           value?.map((id) => {
  //             const item = selectionCategoriesByID?.[id]?.[0];
  //             if (!item) return null;
  //             return (
  //               <Chip
  //                 key={item?.value}
  //                 size="small"
  //                 label={item?.label}
  //                 onDelete={() => onDelete(item?.value)}
  //                 sx={{
  //                   height: "1.6rem",
  //                   color: getTailwindColor(color, 700),
  //                   fontSize: "0.6rem",
  //                   fontWeight: 500,
  //                   fontFamily: Fonts.Text,
  //                   backgroundColor: alpha(
  //                     item?.color ?? Color.unCategorizedCardBg,
  //                     0.2
  //                   ),
  //                   borderRadius,
  //                   ml: "2px",
  //                 }}
  //               />
  //             );
  //           })
  //         ) : (
  //           <Typography
  //             sx={{
  //               width: "fit-content",
  //               display: "inline-flex",
  //               alignItems: "center",
  //               fontSize: "0.8rem",
  //               fontWeight: 500,
  //               fontFamily: Fonts.Text,
  //               textAlign: "left",
  //               color: getTailwindColor(color, 500),
  //               ml: "1rem",
  //             }}
  //           >
  //             {t(Placeholder)}
  //           </Typography>
  //         )}
  //       </Box>
  //     </Tooltip>

  //     {isFiltered ? (
  //       <ClearIcon
  //         onClick={onClickClear}
  //         sx={{
  //           marginRight: "0.5rem",
  //           fontSize: "1.1rem",
  //         }}
  //       />
  //     ) : null}
  //   </Box>
  // );
};
export default CategoryFilterView;
