import React, { createContext, Suspense, useRef } from "react";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";

const FinbanInfoOverlay = React.lazy(
  () => import("../components/FInbanInfo/FinbanInfoOverlay")
);
const CategoryCommonFunctions = React.lazy(
  () => import("../Pages/Settings/Categories/CategoryCommonFunctions")
);
const RulesCommonView = React.lazy(
  () => import("../Pages/Settings/Rules/RulesCommonView")
);
const RecurringSeqFunctions = React.lazy(
  () => import("../components/TableEditModal/RecurringSeqFunctions")
);
const ClientsFunctions = React.lazy(
  () => import("../Pages/Datasets/Employees/Clients/ClientsFunctions")
);
const EnableInternalDSFunctions = React.lazy(
  () => import("../components/Header/DatasetHeader/EnableInternalDSFunctions")
);
const DSConnections = React.lazy(
  () => import("../Pages/Settings/DataSource/DSConnections")
);
const OnBoardingFunctions = React.lazy(
  () => import("../components/Stepper/OnBoardingFunctions")
);
export const GlobalContext = createContext({});

const GlobalContextWrapper = ({ children }) => {
  const location = useLocation();

  const categoryRef = useRef(null);
  const rulesRef = useRef(null);
  const finbanInfoRef = useRef(null);
  const seqFunctionRef = useRef(null);
  const clientFunctionRef = useRef(null);
  const internalDsRef = useRef(null);
  const dsRef = useRef(null);
  const onbRef = useRef(null);
  const value = {
    categoryRef,
    rulesRef,
    finbanInfoRef,
    seqFunctionRef,
    clientFunctionRef,
    internalDsRef,
    dsRef,
    onbRef,
  };

  //redux
  const auth = useSelector((state) => state.auth);
  const isAllHeaderApiFetched = useSelector(
    (state) => state.commonSlice.isAllHeaderApiFetched
  );

  //state
  const show =
    auth?.token &&
    auth?.refreshToken &&
    !location.pathname.includes("/login") &&
    !location.pathname.includes("/register") &&
    !location.pathname.includes("/registration/") &&
    !location.pathname.includes("/auth/") &&
    isAllHeaderApiFetched;

  return (
    <GlobalContext.Provider value={value}>
      {show ? (
        <Suspense fallback={undefined}>
          <CategoryCommonFunctions ref={categoryRef} />
          <RulesCommonView ref={rulesRef} />
          <FinbanInfoOverlay ref={finbanInfoRef} />
          <RecurringSeqFunctions ref={seqFunctionRef} />
          <ClientsFunctions ref={clientFunctionRef} />
          <EnableInternalDSFunctions ref={internalDsRef} />
          <DSConnections ref={dsRef} />
          <OnBoardingFunctions ref={onbRef} />
        </Suspense>
      ) : null}
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContextWrapper;
