import {
  startOfQuarter,
  startOfMonth,
  endOfQuarter,
  startOfWeek,
  endOfMonth,
  addMonths,
  subMonths,
  endOfWeek,
  format,
} from "date-fns";
import {
  ResponsiveContainer,
  CartesianGrid,
  ComposedChart,
  LabelList,
  Legend,
  XAxis,
  YAxis,
  Bar,
} from "recharts";
import { Typography, Divider, Tooltip, Paper, alpha, Box } from "@mui/material";
import React, { forwardRef, useEffect, useState, useRef } from "react";
import { PiInfoFill, PiMagicWandFill } from "react-icons/pi";
import { BiEdit, BiSolidRightArrow } from "react-icons/bi";
import NotesIcon from "@mui/icons-material/Notes";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import { useSelector } from "react-redux";
import styled from "styled-components";

import {
  getStatisticsDataWithParams,
  getTransactionByParams,
  formatDateToLocal,
  getTailwindColor,
  formatAmount,
  getBookedIds,
  Highlighted,
  remToPx,
} from "../../../../../Helper/data";
import TailwindButton from "../../../../../components/Overlay/TailwindButton";
import { getConnectedScenariosUuid } from "../../../../../Helper/functions";
import { setPopupStatus4 } from "../../../../../store/slices/datasets";
import ToolTipWrapper from "../../../../../components/TooltipWrapper";
import DropDown from "../../../../../components/Overlay/DropDown";
import { setLoading } from "../../../../../store/slices/appmain";
import { Color, Constant, Fonts } from "../../../../../Helper";
import Translate from "../../../../../hooks/HOC/Translate";
import useWidth from "../../../../../hooks/useWidth";
import Icon from "../../../../../components/Icon";
import BarShape from "../../Chart/BarShape";
import theme from "../../../../../theme";
import store from "../../../../../store";

const CellWrapper = styled("span")((props) => ({
  padding: "4px",
  margin: 0,
  position: "relative",
  display: "flex",
  justifyContent: "end",
  alignItems: "center",
  "& #magic-icon-wrapper:hover": {
    display: "flex !important",
  },
  "& #magic-icon:hover": {
    backgroundColor: `${theme?.palette?.color.primary?.main} !important`,
    "& svg": {
      color: `${theme?.palette?.color.white} !important`,
    },
  },
  "& #note-icon:hover": {
    borderBottom: `20px solid ${theme?.palette?.color?.amber?.[400]} !important`,
    "& svg": {
      color: `${theme?.palette?.color.white} !important`,
    },
  },
  "&:hover": {
    border: `1px solid ${theme?.palette?.primary?.main}`,
    backgroundColor:
      !props?.cell?.isColumnStartCell && !props?.cell?.isHeader
        ? theme.palette.color.primary[100]
        : "transparent",
    "& #magic-icon-wrapper": {
      display: "flex !important",
    },
    "& #note-icon": {
      display: `flex !important`,
    },
    "& #table_link_icon": {
      display: `flex !important`,
    },
    "& .inner-cell": {
      backgroundColor:
        !props?.cell?.isColumnStartCell && !props?.cell?.isHeader
          ? theme.palette.color.primary[100]
          : "transparent",
      border: `${
        !props?.cell?.isColumnStartCell
          ? `1px solid ${theme?.palette?.primary?.main} !important`
          : 0
      }`,
    },
    [`& ${`#${props?.$columnKey}-column`}`]: {
      backgroundColor: alpha(theme.palette.primary.main, 0.05),
    },
  },
  "& .inner-cell": {
    textAlign: "left",
    lineHeight: "normal",
    verticalAlign: "top",
    fontWeight: 500,
    fontSize: "8px",
    overflow: "hidden",
    fontFamily: Fonts.Text,
    borderStyle: " solid",
    color: "black",
    position: "absolute",
    width: "100%",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
  },

  "& input": {
    padding: "6px",
    zIndex: 10,
    fontWeight: 500,
    lineHeight: "normal",
    verticalAlign: "top",
    fontSize: "13px",
    overflow: "hidden",
    fontFamily: Fonts.Text,
    color: "black",
    position: "absolute",
    top: "1px",
    left: "1px",
    right: "1px",
    bottom: "1px",
    border: 0,
    textAlign: "right",
    width: `calc(100% - 2px)`,
    height: `calc(100% - 2px)`,
  },
}));
const regex = /^\d{0,8}(\.\d{0,2})?$/;
const expandedCellBorderColor = theme.palette.color.slate[400];
const defaultBorderColor = "#cacaca7d";
const Cell = (props) => {
  const {
    cell = {},
    expanded = [],
    selectedCell = {},
    commonFunctionRef = {},
    seqFunctionRef = {},
    isSubscriptionValid = () => {},
    t = () => {},
    navigate = () => {},
  } = props;
  const cellUuid = useRef(null);
  const timer = useRef(0);
  const prevent = useRef(false);
  const delay = useRef(400);
  const isEdited = useRef(false);
  const bgColor = useRef([]);

  const cellKey = `${cell?.rowId}-${cell?.cellId}`;
  const tableType = store?.getState()?.boardSlice?.tableType;

  //states
  const [isEditing, setIsEditing] = useState(false);
  const [anchorEl, setAnchorEl] = useState(false);
  const [value, setValue] = useState(cell?.item?.total?.planned);

  const isEmptyCell =
    cell?.item?.count?.bookedCount === 0 &&
    cell?.item?.count?.plannedCount === 0 &&
    cell?.item?.count?.openCount === 0 &&
    (cell?.item?.isCurrentMonth ? !cell?.item?.pastTotal : true);

  const clicked = (e) => {
    timer.current = setTimeout(() => {
      if (!prevent.current) {
        props?.onClick({ cellKey, cell, isEditing });
      }
      prevent.current = false;
    }, delay.current);
  };

  const doubleClicked = () => {
    if (isSubscriptionValid({ showMessage: true })) {
      clearTimeout(timer.current);
      if (cell?.editable && !cell?.hasChildren) {
        prevent.current = true;
        props?.onDoubleClick({ cellKey, cell });

        if (!commonFunctionRef?.current?.isTableFetching) {
          setValue(cell?.item?.total?.scenario_planned);
          setIsEditing(true);
        } else {
          enqueueSnackbar(t("please wait table is being updated"), {
            variant: "info",
            preventDuplicate: true,
          });
        }
      }
    }
  };

  const onChange = (e) => {
    const inputValue = e.target.value;

    if (regex?.test(inputValue)) {
      isEdited.current = true;
      setValue(inputValue);
    }
  };

  const onKeyPressOnInput = (e) => {
    if (e.key === "Enter") {
      hasNewValue(e.target.value);
    }
  };

  const onBlur = (e) => {
    hasNewValue(e.target.value);
  };

  const hasNewValue = (inputValue) => {
    if (regex?.test(inputValue) && isEdited.current) {
      isEdited.current = false;
      props?.onChangedValue(
        {
          rowId: cell?.rowId,
          columnId: cell?.cellId,
          cell: cell,
        },
        inputValue,
        cellUuid.current
      );
    }
    setIsEditing(false);
  };

  const onClickLink = (e) => {
    e.stopPropagation();
    if (cell?.item?.action === "edit_category") {
      store?.dispatch(
        setPopupStatus4({
          open: true,
          anchorEl: true,
          overlay_type: "category",
          payload: {
            type: 3,
            isOverlay: false,
            hideUncategorize: true,
            anchorReference: "anchorPosition",
            anchorPosition: {
              top: 0,
              left: window.innerWidth / 2,
            },
          },
        })
      );
    } else {
      navigate(cell?.item?.link, {
        state: {
          isOutflow: cell?.item?.uuid === "outflow",
          fromCategories: true,
        },
      });
    }
  };

  const onMouseEnter = () => {
    if (cell?.rowId && cell?.isHover) {
      const elements = document.querySelectorAll(`#row-${cell?.rowId}`);
      bgColor.current = [];
      elements.forEach((element, index) => {
        bgColor.current.push(element.style.backgroundColor);
        element.style.backgroundColor =
          index === 0 && cell?.item?.income_expense_type === 1
            ? Color.tailwind.green[100]
            : index === 0 && cell?.item?.income_expense_type === 2
              ? Color.tailwind.red[100]
              : Color.tailwind.slate[100];
      });
    }
  };

  const onMouseLeave = () => {
    if (cell?.rowId && cell?.isHover) {
      const elements = document.querySelectorAll(`#row-${cell?.rowId}`);
      elements.forEach((element, index) => {
        element.style.backgroundColor = bgColor.current?.[index];
      });
    }
  };

  const onClickIcon = (e) => {
    props?.onClickArrow(e, { cellKey, cell, isEditing });
  };

  const onOpen = async (e) => {
    setAnchorEl(true);
  };

  const onClose = async (e) => {
    setAnchorEl(false);
  };

  const handleClickTooltip = (e) => {
    e?.stopPropagation();
  };

  //lifecycle
  useEffect(() => {
    if (isEditing && !cellUuid.current) {
      cellUuid.current = cell?.item?.uuid;
      // getUuid();
    } else {
      cellUuid.current = null;
    }
  }, [isEditing]);

  const isParentExpanded =
    cell?.rowId !== "net_change" &&
    cell?.rowId !== "start_liquidity" &&
    cell?.rowId !== "end_liquidity" &&
    cell?.rowId !== "VAT_Imputation" &&
    cell?.rowId !== "vat" &&
    cell?.rowId !== "inflow" &&
    cell?.rowId !== "outflow" &&
    expanded?.includes(cell?.rowId);

  const isChildExpanded =
    cell?.parentRowId !== "inflow" &&
    cell?.parentRowId !== "outflow" &&
    expanded?.includes(cell?.parentRowId);

  const isExpanded = isParentExpanded || isChildExpanded;
  // const _scenario = scenarioById?.[highlightedScenarios?.[0]]?.[0];
  const showNoteIcon =
    !isEditing &&
    !cell?.isColumnStartCell &&
    !cell?.isHeader &&
    !cell?.hasChildren &&
    !isEmptyCell &&
    cell?.rowId !== "VAT_Imputation" &&
    cell?.is_note_available;
  const showMagicIcon =
    tableType === "monthly" &&
    cell?.item?.isCurrentMonth &&
    !cell?.hasChildren &&
    !cell?.isHeader &&
    cell?.rowId !== "uncategorizedInflow" &&
    cell?.rowId !== "uncategorizedOutflow" &&
    cell?.rowId !== "VAT_Imputation";
  const showInfoIcon =
    tableType === "monthly" &&
    cell?.item?.isCurrentMonth &&
    !cell?.isHeader &&
    cell?.rowId !== "uncategorizedInflow" &&
    cell?.rowId !== "uncategorizedOutflow" &&
    cell?.rowId !== "VAT_Imputation";
  const showPatternCell =
    (cell?.hasChildren && !cell?.isHeader) ||
    (cell?.pattern && !cell.isCurrentMonth);
  const showLateDot =
    Math.abs(cell?.item?.pastTotal) > 0 &&
    cell?.item?.isCurrentMonth &&
    !cell.isHeader;
  const id = `${cell?.rowId}-${cell?.cellId}`;
  return (
    <CellWrapper
      id={id}
      $cellKey={cellKey}
      $columnKey={cell?.cellId}
      $selectedCell={selectedCell}
      onClick={clicked}
      onDoubleClick={doubleClicked}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      cell={cell}
      style={{
        paddingInline: cell?.isColumnStartCell ? "0px" : "12px",
        width: cell?.gridWidth,
        height: cell?.rowHeight,
        color:
          getTailwindColor(
            cell?.isExcludedFromCalculation ? "slate" : cell?.color,
            cell?.isExcludedFromCalculation ? 400 : 600
          ) || "inherit",

        borderLeftWidth:
          cell?.mergeCell && !cell?.isFirstCell
            ? 0
            : cell?.borderLeftWidth || "1px",
        borderRightWidth:
          cell?.isLastCell || cell?.isSticky
            ? cell?.borderRightWidth || "1px"
            : 0,
        borderTopWidth:
          cell.isFirstRowCell || isParentExpanded
            ? cell?.borderTopWidth || "1px"
            : 0,
        borderBottomWidth: cell?.borderBottomWidth || "1px",

        borderLeftStyle: isExpanded && cell?.isFirstCell ? "dashed" : "solid",
        borderRightStyle: isExpanded && cell?.isLastCell ? "dashed" : "solid",
        borderTopStyle: isParentExpanded ? "dashed" : "solid",
        borderBottomStyle:
          isChildExpanded && cell?.isLastCategoryChildCell ? "dashed" : "solid",

        borderLeftColor:
          isExpanded && cell?.isFirstCell
            ? expandedCellBorderColor
            : cell?.borderColor || defaultBorderColor,
        borderRightColor:
          isExpanded && cell?.isLastCell
            ? expandedCellBorderColor
            : cell?.borderColor || defaultBorderColor,
        borderTopColor: isParentExpanded
          ? expandedCellBorderColor
          : cell?.borderColor || defaultBorderColor,
        borderBottomColor:
          isChildExpanded && cell?.isLastCategoryChildCell
            ? expandedCellBorderColor
            : cell?.borderColor || defaultBorderColor,

        borderTopLeftRadius:
          cell?.isFirstRowCell && cell?.isFirstCell ? props?.borderRadius : 0,
        borderTopRightRadius:
          cell?.isFirstRowCell && cell?.isLastCell ? props?.borderRadius : 0,
        borderBottomLeftRadius:
          props?.isLastRowCell && cell?.isFirstCell ? props?.borderRadius : 0,
        borderBottomRightRadius:
          props?.isLastRowCell && cell?.isLastCell ? props?.borderRadius : 0,

        fontFamily: Fonts.Text,
        textTransform: cell?.textTransform || "initial",
        fontStyle: cell?.isExcludedFromCalculation
          ? "italic"
          : cell?.fontStyle || "normal",
        fontSize:
          cell?.fontSize ||
          (parseFloat(cell?.item?.value)?.toFixed(0)?.toString()?.length >= 7
            ? "10px"
            : "12px"),
        fontWeight: cell?.fontWeight || 500,
        backgroundColor: cell?.backgroundColor,
        position: cell?.isSticky ? "sticky" : "relative",
        zIndex: cell?.isSticky ? 17 : 0,
        left: 0,
        cursor: cell?.clickable || cell?.showCursor ? "pointer" : "default",
        // overflow: "auto",
      }}
      role="presentation"
    >
      <span
        className="inner-cell"
        style={{
          zIndex: 10,
          borderRadius: "inherit",
          borderWidth: selectedCell === cellKey ? "2px" : "0",
          borderColor: isEditing
            ? Color.tailwind.purple[700]
            : Color.tailwind.purple[400],
        }}
      ></span>
      {showMagicIcon || showInfoIcon ? (
        <div
          id="magic-icon-wrapper"
          style={{
            display: !!anchorEl ? "flex" : "none",
            backgroundColor: "transparent",
            position: "absolute",
            left: 0,
            top: -29,
            zIndex: 22,
            justifyContent: "center",
            alignItems: "center",
            paddingBottom: 30,
            gap: "2px",
          }}
        >
          {showMagicIcon ? (
            <ToolTipWrapper
              isOverlay
              tooltipSx={{
                maxWidth: "600px",
              }}
              title={
                <TooltipView
                  cell={cell}
                  seqFunctionRef={seqFunctionRef}
                  t={t}
                />
              }
              restProps={{
                onOpen: onOpen,
                onClose: onClose,
                open: !!anchorEl,
                disableFocusListener: true,
              }}
            >
              <div
                id="magic-icon"
                onMouseEnter={onOpen}
                style={{
                  // border: `1px solid ${theme?.palette?.primary?.main}`,
                  backgroundColor: theme.palette.color.primary[100],
                  padding: "2.5px",
                  paddingLeft: "3px",
                  borderRadius: 4,
                  width: 28,
                  height: 28,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Icon
                  icon={<PiMagicWandFill />}
                  fontSize={"1.3rem"}
                  color={theme?.palette?.primary?.main}
                  style={{ cursor: "pointer" }}
                ></Icon>
              </div>
            </ToolTipWrapper>
          ) : null}
          {showInfoIcon ? (
            <ToolTipWrapper isOverlay title={<InfoTooltipView cell={cell} />}>
              <div
                id="magic-icon"
                onClick={handleClickTooltip}
                style={{
                  // border: `1px solid ${theme?.palette?.primary?.main}`,
                  backgroundColor: theme.palette.color.primary[100],
                  padding: "2.5px",
                  paddingLeft: "3px",
                  borderRadius: 4,
                  width: 28,
                  height: 28,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Icon
                  icon={<PiInfoFill />}
                  fontSize={"1.3rem"}
                  color={theme?.palette?.primary?.main}
                  style={{ cursor: "pointer" }}
                ></Icon>
              </div>
            </ToolTipWrapper>
          ) : null}
        </div>
      ) : null}

      {showNoteIcon ? (
        <ToolTipWrapper isOverlay title={<NoteTooltipView cell={cell} />}>
          <div
            id="note-icon"
            style={{
              borderBottom: `20px solid ${getTailwindColor(
                "amber",
                cell?.is_note_highlighted
                  ? 100
                  : cell?.is_note_user_edited
                    ? 300
                    : 200
              )}`,
              borderLeft: `20px solid transparent`,
              width: 0,
              height: 0,
              display: cell?.is_note_highlighted ? "flex" : "none",
              position: "absolute",
              right: "2px",
              bottom: "2px",
              zIndex: 20,
              border: "none !important",
            }}
          ></div>
        </ToolTipWrapper>
      ) : null}
      {showPatternCell ? (
        <svg
          width="100%"
          height="100%"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
          }}
        >
          <defs>
            <pattern
              id={`Pattern-${cellKey}`}
              width="8"
              height="4"
              patternUnits="userSpaceOnUse"
              patternTransform="rotate(45)"
            >
              <rect
                width="4"
                height="4"
                fill={getTailwindColor(
                  cell?.patternColor || "slate",
                  cell?.isColumnStartCell ? 100 : 50
                )}
              />
            </pattern>
          </defs>
          <rect width="100%" height="100%" fill={`url(#Pattern-${cellKey})`} />
        </svg>
      ) : null}
      {showLateDot ? (
        <Tooltip placement="top" title={t("Late Transactions")}>
          <div
            style={{
              width: "6px",
              height: "6px",
              position: "absolute",
              borderRadius: 8,
              top: 3,
              right: 3,
              zIndex: 10,
              backgroundColor: Color.tailwind.red[500],
            }}
          ></div>
        </Tooltip>
      ) : null}
      {isEditing ? (
        <input
          inputMode={cell?.item?.type ? cell?.item?.type : "text"}
          onBlur={onBlur}
          onKeyDown={onKeyPressOnInput}
          value={!value || value === 0 ? "" : value}
          onChange={onChange}
          autoFocus
          style={{
            outline: `2px solid ${Color.tailwind.purple[700]}`,
          }}
        />
      ) : (
        <Tooltip title={cell?.item?.value2 || ""} placement="top">
          <span
            style={{
              fontSize: "inherit",
              fontFamily: "inherit",
              fontWeight: "inherit",
              fontStyle: "inherit",
              display: "flex",
              alignItems: "center",
              width: "100%",
              zIndex: 10,
              paddingLeft: cell?.isColumnStartCell
                ? (cell?.depth || 0) * 26
                : 0,
            }}
          >
            {cell?.isColumnStartCell ? (
              <span
                style={{
                  width: "16%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {cell?.item?.icon ? (
                  <div
                    style={{
                      display: "contents",
                    }}
                    onClick={onClickIcon}
                  >
                    {cell?.item?.icon}
                  </div>
                ) : cell?.hasChildren ? (
                  <Icon
                    key={cell?.rowId}
                    onClick={onClickIcon}
                    icon={<BiSolidRightArrow />}
                    color={
                      Color.tailwind.slate[
                        expanded?.includes(cell?.rowId) ? 700 : 500
                      ]
                    }
                    fontSize={"12px"}
                    style={{
                      transform: expanded?.includes(cell?.rowId)
                        ? "rotate(90deg)"
                        : "none",
                      transition: "transform 0.3s ease-in-out",
                      zIndex: 15,
                    }}
                  ></Icon>
                ) : null}
              </span>
            ) : null}
            <span
              onClick={
                cell?.item?.value === "Outflow" ||
                cell?.item?.value === "Inflow"
                  ? onClickIcon
                  : undefined
              }
              style={{
                width: cell?.isColumnStartCell
                  ? `calc(86% - ${cell?.isHeader ? 16 : 0}% - ${(cell?.item?.postIcon?.length || 0) * 10}%)`
                  : "100%",

                display: "block",
                textAlign: cell?.isColumnStartCell ? "left" : "center",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                fontSize: "inherit",
                fontFamily: "inherit",
                fontStyle: "inherit",
                color: "inherit",
              }}
            >
              {isEmptyCell ? null : cell?.hasOwnProperty("customCellRender") ? (
                cell?.customCellRender()
              ) : cell?.customCell ? (
                <CustomCell
                  {...{
                    cell,
                    cellKey,
                    uuid: cellUuid.current,
                    ...props,
                  }}
                />
              ) : cell?.isColumnStartCell && cell?.item?.searchText ? (
                <Highlighted
                  text={cell?.item?.value}
                  highlight={cell?.item?.searchText}
                  key={`cell-${cell?.rowId}-${cell?.cellId}`}
                />
              ) : (
                cell?.item?.value
              )}
            </span>
            {cell?.item?.postIcon?.map((item, index) => (
              <Icon
                key={`${item?.rowId} - ${index}`}
                tooltip={item?.tooltip}
                icon={item?.icon}
                color={theme.palette.color.slate[500]}
                fontSize={"12px"}
                onClick={onClickLink}
                style={{
                  width: "10%",
                  marginRight: "0.25rem",
                  cursor: "pointer",
                  transition: "transform 0.3s ease-in-out",
                }}
              ></Icon>
            ))}
            {cell?.item?.link || cell?.item?.action ? (
              <Icon
                id="table_link_icon"
                key={"table_link_icon" - cell?.rowId}
                icon={<BiEdit />}
                color={
                  cell?.rowId === "inflow"
                    ? Color.tailwind.green[500]
                    : Color.tailwind.red[500]
                }
                fontSize={"18px"}
                onClick={onClickLink}
                style={{
                  display: "none",
                  // position: "absolute",
                  // right: 10,
                  marginRight: "0.5rem",
                  cursor: "pointer",
                  transition: "transform 0.3s ease-in-out",
                }}
              ></Icon>
            ) : null}
          </span>
        </Tooltip>
      )}
    </CellWrapper>
  );
};

export default React.memo(Cell);
const CustomCell = (props) => {
  const { cell } = props;

  if (!props) {
    return null;
  }

  return (
    <Tooltip placement="top" title={cell?.item?.value2 || ""}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          position: "relative",
          paddingRight: "4px",
          fontSize: "inherit",
          color: cell?.showFontColor
            ? cell?.item?.value >= 0
              ? Color.tailwind.green[500]
              : Color.tailwind.red[500]
            : "inherit",
        }}
      >
        {cell?.item?.type === "numeric"
          ? formatAmount({
              amount: cell?.item?.value,
              hidePrefix: true,
            })
          : null}

        <div
          style={{
            minWidth: "44px",
            width: "fit-content",
            height: "20px",
            marginLeft: "10px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {cell?.item?.value !== 0 &&
          !cell?.isHeader &&
          !cell?.isColumnStartCell &&
          cell.item?.count?.plannedCount !== 0 &&
          !cell?.hidePercentage ? (
            <PercentageChangeChip
              item={cell?.item}
              type={cell?.item?.income_expense_type}
            />
          ) : null}
        </div>
      </div>
    </Tooltip>
  );
};

const PercentageChangeChip = ({ item, type = 1 }) => {
  let color = "slate";
  let bgShade = 100;
  let colorShade = 500;
  const percentageChange = item?.percentageChange;
  let change =
    !isNaN(percentageChange) && percentageChange ? Number(percentageChange) : 0;

  if (item?.value > 0 || (item?.value === 0 && type === 1)) {
    if (item?.compareValue >= item?.plannedValue || change >= 99) {
      color = "emerald";
      bgShade = 100;
      colorShade = 600;
    }
    if (item?.compareValue < item?.plannedValue && change < 99) {
      if (item?.isFutureMonth) {
        color = "slate";
        bgShade = 100;
        colorShade = 500;
      } else {
        color = "orange";
        bgShade = 100;
        colorShade = 600;
      }
    }
  }
  if (item?.value < 0 || (item?.value === 0 && type === 2)) {
    if (item?.compareValue >= item?.plannedValue || change <= 101) {
      color = "lime";
      bgShade = 100;
      colorShade = 600;
    }
    if (item?.compareValue < item?.plannedValue && change > 101) {
      if (item?.isFutureMonth) {
        color = "slate";
        bgShade = 100;
        colorShade = 500;
      } else {
        color = "red";
        bgShade = 100;
        colorShade = 600;
      }
    }
  }
  if ((item?.value < 0 && type === 1) || (item?.value > 0 && type === 2)) {
    change = -Math.abs(change);
  }
  if (percentageChange === 0) {
    color = "slate";
    bgShade = 100;
    colorShade = 500;
  }

  const chipStyle = {
    borderRadius: 4,
    backgroundColor: getTailwindColor(color, bgShade),
    color: getTailwindColor(color, colorShade),
    width: "100%",
    height: "100%",
    fontSize: change?.toString()?.length > 3 ? "9px" : "10px",
    fontFamily: Fonts.Text,
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    paddingInline: "4px",
    fontWeight: 700,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  };

  return <div style={chipStyle}>{change}%</div>;
};

const TooltipView = forwardRef(({ cell, t, seqFunctionRef }, _ref) => {
  const bgColor = useRef(theme.palette.color.slate[400]);
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);
  const highlightedScenarios = useSelector(
    (state) => state.boardSlice?.highlightedScenarios
  );

  //state
  const [tooltip, setTooltip] = useState(""); //auto-forecast-cell-tooltip

  useEffect(() => {
    generateTooltip();
  }, []);

  const generateTooltip = async () => {
    const category =
      cell?.rowId === "uncategorizedInflow" ||
      cell?.rowId === "uncategorizedOutflow"
        ? null
        : cell?.rowId;
    let response = await getTransactionByParams({
      dataset: dataSetData?.uuid,
      category: category ? [category] : [],
      scenario: [highlightedScenarios?.[0]],
      startDate: format(addMonths(new Date(cell?.item?.date), 1), "yyyy-MM-dd"),
      categorized: category ? true : false,
      is_recurred: true,
      income_expense_type: cell?.item?.income_expense_type,
    });
    let recurring_rules_list = response
      ? [...new Set(response?.results?.map((o1) => o1.recurring_rule))]
      : [];

    if (recurring_rules_list?.length === 0) {
      setTooltip("auto-forecast-simple-tooltip");
    }
    if (recurring_rules_list?.length === 1) {
      setTooltip("auto-forecast-one-seq-tooltip");
    }
    if (recurring_rules_list?.length > 1) {
      setTooltip("auto-forecast-multiple-seq-tooltip");
    }
  };

  const handleClickTooltip = (e) => {
    e?.stopPropagation();
    e?.preventDefault();
  };

  return (
    <div
      onClick={handleClickTooltip}
      onDoubleClick={handleClickTooltip}
      style={{
        display: "flex",
        height: "fit-content",
        flexWrap: "wrap",
        paddingBlock: "1rem",
        gap: "0.5rem",
        position: "relative",
        flexDirection: "column",
        borderRadius: theme.borderRadius.borderRadiusXL,
        backgroundColor: theme.palette.color.slate[50],
      }}
    >
      <span
        style={{
          color: theme.palette.color.slate[800],
          fontFamily: theme.typography.fontFamily,
          marginInline: "1rem",
          fontSize: "0.85rem",
        }}
      >
        <Translate i18nkey={tooltip ? t(tooltip) : `${t("Loading")}..`} />
      </span>
      {tooltip ? (
        <ForeCastePreferenceDropDown
          t={t}
          cell={cell}
          seqFunctionRef={seqFunctionRef}
          bgColor={bgColor.current}
        />
      ) : null}
    </div>
  );
});

const ForeCastePreferenceDropDown = ({ t, cell, seqFunctionRef, bgColor }) => {
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);
  const scenarioById = useSelector((state) => state.globalSlice.scenarioById);
  const highlightedScenarios = useSelector(
    (state) => state.boardSlice?.highlightedScenarios
  );
  const stateByTitle = useSelector((state) => state.globalSlice.stateByTitle);
  const recurring_rulesById = useSelector(
    (state) => state.globalSlice?.recurring_rulesById
  );
  //state
  const [autoForeCastPastPreference, setAutoForeCastPastPreference] =
    useState("3");
  const [autoForeCastPreference, setAutoForeCastPreference] = useState("6");

  useEffect(() => {
    const _autoForeCastPreference = localStorage.getItem(
      "autoForeCastPreference"
    );
    const _autoForeCastPastPreference = localStorage.getItem(
      "autoForeCastPastPreference"
    );
    setAutoForeCastPastPreference(_autoForeCastPastPreference || "3");
    setAutoForeCastPreference(_autoForeCastPreference || "6");
  }, []);

  const onGo = async (e) => {
    e?.stopPropagation();
    const category =
      cell?.rowId === "uncategorizedInflow" ||
      cell?.rowId === "uncategorizedOutflow"
        ? null
        : cell?.rowId;

    store?.dispatch(setLoading(true));
    const autoForeCastPreference = localStorage.getItem(
      "autoForeCastPreference"
    );
    const autoForeCastPastPreference = localStorage.getItem(
      "autoForeCastPastPreference"
    );
    let _autoForeCastPastPreference = autoForeCastPastPreference || 3;
    let _autoForeCastPreference = autoForeCastPreference || 6;
    if (autoForeCastPastPreference === "copy_last_year") {
      _autoForeCastPastPreference = "12";
    }
    let recurring_rules_list = [];
    const scenario = scenarioById?.[highlightedScenarios?.[0]]?.[0];
    const selected_scenario_uuid = getConnectedScenariosUuid({
      scenarioById,
      _scenario: scenario,
    });
    let response = await getTransactionByParams({
      dataset: dataSetData?.uuid,
      category: category ? [category] : [],
      scenario: [highlightedScenarios?.[0]],
      startDate: format(
        addMonths(
          new Date(cell?.item?.date),
          autoForeCastPastPreference === "copy_last_year" ? 0 : 1
        ),
        "yyyy-MM-dd"
      ),
      endDate:
        autoForeCastPastPreference === "copy_last_year"
          ? format(endOfMonth(new Date(cell?.item?.date)), "yyyy-MM-dd")
          : null,
      categorized: category ? true : false,
      is_recurred:
        autoForeCastPastPreference === "copy_last_year" ? false : true,
      income_expense_type: cell?.item?.income_expense_type,
      state:
        autoForeCastPastPreference === "copy_last_year"
          ? [stateByTitle?.["Planned"]?.[0]?.uuid]
          : [],
    });
    let deleteIds = response?.results.map((o1) => o1.uuid);

    let current_start_date = format(startOfMonth(new Date()), "yyyy-MM-dd");

    const past_start_date = startOfMonth(
      subMonths(
        new Date(cell?.item?.date),
        Number(_autoForeCastPastPreference || 3)
      )
    );
    const past_end_date = endOfMonth(
      subMonths(
        new Date(cell?.item?.date),
        Number(
          autoForeCastPastPreference === "copy_last_year"
            ? _autoForeCastPastPreference
            : 1
        )
      )
    );
    const Booked_ids = getBookedIds();
    const table_row_past_data = await getStatisticsDataWithParams({
      type: "monthly",
      dataset: dataSetData?.uuid,
      from_payment_date: format(past_start_date, "yyyy-MM-dd"),
      to_payment_date: format(past_end_date, "yyyy-MM-dd"),
      multiStatesIds: Booked_ids,
      group_by: ["state"],
      multiCategoryIds: [cell?.rowId],
    });
    const total = table_row_past_data?.results?.reduce(
      (a, b) => a + (b?.[cell?.parentRowId] || 0),
      0
    );
    const value =
      autoForeCastPastPreference !== "copy_last_year"
        ? parseFloat(total / (autoForeCastPastPreference || 3))?.toFixed(2)
        : 0;
    if (Number(total) === 0) {
      store?.dispatch(setLoading(false));
      enqueueSnackbar(t("No_Data_Found"), {
        variant: "info",
        autoHideDuration: 3000,
        preventDuplicate: true,
      });
      return null;
    }
    if (autoForeCastPastPreference === "copy_last_year") {
      seqFunctionRef?.current?.addTransaction({
        deleteIds,
        cardItem: {
          recurring_rule: null,
          scenario: scenario?.title,
          gross_value: parseFloat(total)?.toFixed(2),
          category,
          state: "Planned",
          title: `copied amount from ${formatDateToLocal(past_start_date, "yyyy MMM")}`,
          source: "1",
          due_date: format(new Date(), "yyyy-MM-dd"),
          data_source: dataSetData?.internal_data_source,
        },
      });
      setTimeout(() => {
        store?.dispatch(setLoading(false));
      }, 1000);
      return null;
    } else {
      recurring_rules_list = response
        ? [...new Set(response?.results?.map((o1) => o1.recurring_rule))]
        : [];
      let update_rules = [];
      let updated_end_date = format(endOfMonth(new Date()), "yyyy-MM-dd");

      recurring_rules_list?.forEach((old_rule_uuid) => {
        const old_rule = recurring_rulesById?.[old_rule_uuid]?.[0];
        if (new Date(old_rule?.start_date) >= new Date(updated_end_date)) {
          // delete_rules.push(old_rule?.uuid);
        } else {
          update_rules.push({
            ...old_rule,
            end_date: updated_end_date,
          });
        }
      });
      const start_date = format(
        addMonths(new Date(current_start_date), 1),
        "yyyy-MM-dd"
      );
      const end_date = format(
        addMonths(new Date(cell?.item?.date), Number(_autoForeCastPreference)),
        "yyyy-MM-dd"
      );
      seqFunctionRef?.current?.onSaveRecurrence({
        deleteIds,
        update_rules,
        income_expense_type: cell?.item?.income_expense_type,
        selected_scenarios: selected_scenario_uuid,
        cardItem: {
          recurring_rule: null,
          scenario: scenario?.title,
          category,
          state: "Planned",
          source: "1",
          due_date: current_start_date,
          data_source: dataSetData?.internal_data_source,
        },
        recurring_Obj: {
          name: "Simple Sequence",
          recurring_type: "simple",
          period: Number(_autoForeCastPreference),
          repetition: 1,
          category,
          value,
          start_date,
          end_date,
        },
      });
    }
  };

  const handleChangeDropDown = (e, value, name) => {
    e?.stopPropagation();
    if (name === "autoForeCastPreference") {
      setAutoForeCastPreference(value?.uuid);
    } else {
      setAutoForeCastPastPreference(value?.uuid);
    }
    localStorage.setItem(name, value?.uuid);
  };

  const AutoForeCastPastOptions = [
    {
      uuid: "3",
      title: t("auto_forecast_past_average_3_months"),
    },
    {
      uuid: "6",
      title: t("auto_forecast_past_average_6_months"),
    },
    {
      uuid: "12",
      title: t("auto_forecast_past_average_12_months"),
    },
    {
      uuid: "copy_last_year",
      title: t("auto_forecast_past_copy_last_year_months"),
    },
  ];
  const AutoForeCastOptions = [
    {
      uuid: "6",
      title: t("auto_forecast_future_range_6_months"),
    },
    {
      uuid: "12",
      title: t("auto_forecast_future_range_12_months"),
    },
    {
      uuid: "24",
      title: t("auto_forecast_future_range_24_months"),
    },
  ];

  return (
    <div
      style={{
        width: "100%",
        minWidth: "100%",
        position: "relative",
        marginTop: "2rem",
        marginRight: "1rem",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "0.5rem",
        paddingLeft: "0.25rem",
      }}
    >
      <DropDown
        mb={0}
        disablePortal
        hideTitle
        likeGoogle
        variant="filled"
        fontSize="0.8rem"
        renderOption={(option) => option?.title ?? ""}
        getOptionLabel={(option) => option?.title ?? ""}
        label={t("auto_forecast_select_past_range_title")}
        name="autoForeCastPastPreference"
        options={AutoForeCastPastOptions}
        onChange={handleChangeDropDown}
        value={
          AutoForeCastPastOptions?.find(
            (o1) => o1?.uuid === autoForeCastPastPreference
          ) || ""
        }
        sx={{
          width: "48%",
          "& input": {
            cursor: "default",
          },
        }}
      />
      {autoForeCastPastPreference &&
      autoForeCastPastPreference !== "copy_last_year" ? (
        <DropDown
          mb={0}
          disablePortal
          hideTitle
          likeGoogle
          variant="filled"
          fontSize="0.8rem"
          renderOption={(option) => option?.title ?? ""}
          getOptionLabel={(option) => option?.title ?? ""}
          label={t("auto_forecast_select_future_range_title")}
          name="autoForeCastPreference"
          options={AutoForeCastOptions}
          onChange={handleChangeDropDown}
          value={
            AutoForeCastOptions?.find(
              (o1) => o1?.uuid === autoForeCastPreference
            ) || ""
          }
          sx={{
            width: "48%",
            "& input": {
              cursor: "default",
            },
          }}
        />
      ) : null}
      <TailwindButton
        text={t("Go")}
        onClick={onGo}
        sx={{
          ml: 0,
          mr: "0.75rem",
        }}
        icon={
          <PiMagicWandFill
            style={{
              fontSize: "1.25rem",
              marginRight: "0.5rem",
            }}
          />
        }
      />
    </div>
  );
};

const InfoTooltipView = forwardRef(({ cell }, _ref) => {
  const { t } = useTranslation();
  const currentWidth = useWidth();
  if (!cell?.item || !cell?.item?.total) {
    return null;
  }
  const { booked = 0, open = 0, planned = 0 } = cell?.item?.total;
  const { pastTotal = 0 } = cell?.item;
  const data = [
    {
      name: cell?.item?.date,
      booked: booked,
      open: open + pastTotal,
      remaining: planned === 0 ? 0 : planned - (booked + open) + pastTotal,
    },
  ];

  const handleClickTooltip = (e) => {
    e?.stopPropagation();
  };

  const tickFormatter = (tick) => {
    return formatAmount({
      amount: tick,
    });
  };

  return (
    <Box
      ref={_ref}
      onClick={handleClickTooltip}
      onDoubleClick={handleClickTooltip}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        flexWrap: "wrap",
        width: 250,
        height: 250,
        pt: "1rem",
        gap: "0.5rem",
        position: "relative",
        borderRadius: theme.borderRadius.borderRadiusXL,
        backgroundColor: theme.palette.color.white,
        color: theme.palette.color.white,
        fontSize: "0.8rem",
        "& .recharts-active-dot": {
          display: "none",
        },
        "& .recharts-cartesian-grid-vertical line:nth-of-type(2)": {
          display: "none",
        },
      }}
    >
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart
          renderer="canvas"
          data={data}
          barSize={remToPx(currentWidth, 8)}
        >
          <CartesianGrid strokeDasharray="3" horizontal={false} />
          <XAxis
            dataKey="name"
            tickCount={12}
            type="category"
            allowDataOverflow={false}
            tickLine={false}
            tick={false}
            interval={1}
            stroke={Color.deepPurple}
            strokeWidth={0}
          />
          <YAxis
            type="number"
            tick={{
              dx: 0,
              fontSize: "0.6rem",
              fontWeight: 500,
              fill: theme.palette.color.grey[400],
            }}
            tickFormatter={tickFormatter}
            tickCount={8}
            interval={"preserveStartEnd"}
            strokeWidth={0}
          />
          <Legend
            formatter={(value) => {
              let color =
                cell?.item?.income_expense_type === 1
                  ? theme.palette.color.green[500]
                  : theme.palette.color.red[500];
              let positive = false;
              let negative = false;
              if (value === "overlay_remaining") {
                positive = data?.[0]?.remaining > 0;
                negative = data?.[0]?.remaining < 0;
              }
              if (value === "overlay_open") {
                positive = data?.[0]?.open > 0;
                negative = data?.[0]?.open < 0;
              }
              if (value === "overlay_actual_value") {
                positive = data?.[0]?.booked > 0;
                negative = data?.[0]?.booked < 0;
              }
              if (positive || negative) {
                color = theme.palette.color?.[positive ? "green" : "red"][500];
              }

              return <span style={{ color, fontSize: 11 }}>{t(value)}</span>;
            }}
          />
          <Bar
            dataKey={(v) => Math.abs(v[`booked`] || 0)}
            stackId="a"
            name="overlay_actual_value"
            shape={(props) => (
              <BarShape {...props} theme={theme} _key={"booked"} />
            )}
            fill={
              theme.palette.color?.[
                data?.[0]?.booked > 0
                  ? "green"
                  : data?.[0]?.booked < 0
                    ? "red"
                    : cell?.item?.income_expense_type === 1
                      ? "green"
                      : "red"
              ]?.[500]
            }
          >
            <LabelList
              dataKey={(v) => Math.abs(v[`booked`] || 0)}
              content={<RenderCustomizedLabel _key={"booked"} cell={cell} />}
            />
          </Bar>
          <Bar
            dataKey={(v) => Math.abs(v[`open`] || 0)}
            stackId="a"
            name="overlay_open"
            fill={
              theme.palette.color?.[
                data?.[0]?.open > 0
                  ? "green"
                  : data?.[0]?.open < 0
                    ? "red"
                    : cell?.item?.income_expense_type === 1
                      ? "green"
                      : "red"
              ]?.[200]
            }
          >
            <LabelList
              dataKey={(v) => Math.abs(v[`open`] || 0)}
              content={<RenderCustomizedLabel _key={"open"} cell={cell} />}
            />
          </Bar>
          <defs>
            <pattern
              id={`remaining`}
              width="8"
              height="4"
              patternUnits="userSpaceOnUse"
              patternTransform="rotate(45)"
            >
              <rect
                width="100%"
                height="100%"
                fill={
                  cell?.parentRowId === "inflow"
                    ? theme.palette.color.green[200]
                    : theme.palette.color.red[200]
                }
                fillOpacity={0.25}
              />
              <rect
                width="2"
                height="4"
                fill={
                  cell?.parentRowId === "inflow"
                    ? theme.palette.color.green[200]
                    : theme.palette.color.red[200]
                }
              />
            </pattern>
          </defs>
          <Bar
            dataKey={(v) => Math.abs(v[`remaining`] || 0)}
            stackId="a"
            name="overlay_remaining"
            fill={`url(#remaining`}
          >
            <LabelList
              dataKey={(v) => Math.abs(v[`remaining`] || 0)}
              content={<RenderCustomizedLabel _key={"remaining"} cell={cell} />}
            />
          </Bar>
        </ComposedChart>
      </ResponsiveContainer>
    </Box>
  );
});

const NoteTooltipView = forwardRef(({ cell }, _ref) => {
  const { t } = useTranslation();
  const highlightedNotesFound = useRef(false);
  const stateByTitle = useSelector((state) => state.globalSlice.stateByTitle);
  const uuid = useSelector((state) => state.boardSlice?.dataSetData?.uuid);
  const highlightedScenarios = useSelector(
    (state) => state.boardSlice?.highlightedScenarios
  );

  const dataSetData = {
    uuid: uuid,
  };
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    generateTooltip();
  }, []);

  const generateTooltip = async () => {
    setLoading(true);
    const tableType = store?.getState()?.boardSlice?.tableType;
    const category =
      cell?.rowId === "uncategorizedInflow" ||
      cell?.rowId === "uncategorizedOutflow"
        ? null
        : cell?.rowId;
    const start_date =
      tableType === "quarterly"
        ? startOfQuarter(new Date(cell?.item?.date))
        : tableType === "weekly"
          ? startOfWeek(new Date(cell?.item?.date), {
              weekStartsOn: 1,
            })
          : tableType === "daily"
            ? new Date(cell?.item?.date)
            : new Date(cell?.item?.date);
    const end_date =
      tableType === "quarterly"
        ? endOfQuarter(new Date(cell?.item?.date))
        : tableType === "weekly"
          ? endOfWeek(new Date(cell?.item?.date), {
              weekStartsOn: 1,
            })
          : tableType === "daily"
            ? new Date(cell?.item?.date)
            : endOfMonth(new Date(cell?.item?.date));
    let finalResponse = [];
    let params = {
      dataset: dataSetData?.uuid,
      category: category ? [category] : [],
      scenario: [highlightedScenarios?.[0]],
      categorized: category ? true : false,
      income_expense_type: cell?.item?.income_expense_type,
    };
    const response = await getTransactionByParams({
      ...params,
      from_payment_date: start_date,
      to_payment_date: end_date,
    });
    if (cell?.item?.isCurrentMonth) {
      let state = [];
      Constant?.openPositionsStates?.forEach((title) => {
        state.push(stateByTitle?.[title]?.[0]?.uuid);
      });
      params.state = state;
      const LateResponse = await getTransactionByParams({
        ...params,
        from_payment_date: startOfMonth(subMonths(new Date(), 6)),
        to_payment_date: endOfMonth(subMonths(new Date(cell?.item?.date), 1)),
      });
      finalResponse = [
        ...(response?.results || []),
        ...(LateResponse?.results || []),
      ];
    } else {
      finalResponse = [...(response?.results || [])];
    }
    const filterData = finalResponse
      ?.filter((o1) => o1?.note?.trim() !== "")
      ?.sort((a, b) => b.gross_value - a.gross_value);

    const highlightedData = filterData?.filter((o1) => o1?.is_note_highlighted);
    const restData = filterData?.filter((o1) => !o1?.is_note_highlighted);
    highlightedNotesFound.current = highlightedData?.length > 0;
    setTimeout(() => {
      setData([...highlightedData, ...restData]);
      setLoading(false);
    }, 0);
  };

  return (
    <Paper
      elevation={1}
      component={"div"}
      ref={_ref}
      sx={{
        backgroundColor: Color.white,
        color: theme.palette.color.slate[700],
        borderRadius: theme.borderRadius.borderRadiusXL,
        fontSize: "0.8rem",
        padding: "1rem",
        width: "20rem",
        height: "25rem",
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
        ...theme.thinScrollBar,
      }}
    >
      {loading ? (
        `${t("Loading")}..`
      ) : data?.length > 0 ? (
        <div style={{ width: "100%" }}>
          <p
            style={{
              width: "100%",
              fontFamily: theme.typography.fontFamily,
              fontSize: "1rem",
              fontWeight: 600,
              color: theme.palette.color.slate[700],
              display: "flex",
              alignItems: "center",
              marginTop: 0,
              marginBottom: "0rem",
            }}
          >
            <NotesIcon
              sx={{
                color: theme.palette.color.slate[700],
                mr: "0.5rem",
                fontSize: "1.4rem",
              }}
            />
            {t("table_cell_notes_tooltip_header")}
          </p>
          <Divider
            sx={{
              opacity: 0.6,
              my: "0.5rem",
            }}
          />

          {highlightedNotesFound.current ? (
            <Typography variant="body2" color="color.description" gutterBottom>
              {t("table_cell_tooltip_highlighted_notes_header")}
            </Typography>
          ) : null}
          {data?.map((item, index) => {
            const isSectionEnd =
              highlightedNotesFound.current &&
              item?.is_note_highlighted &&
              !data?.[index + 1]?.is_note_highlighted;
            return (
              <div
                key={item?.uuid}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "start",
                  }}
                >
                  <p
                    style={{
                      fontFamily: theme.typography.fontFamily,
                      fontSize: "0.7rem",
                      fontWeight: 700,
                      display: "flex",
                      alignItems: "center",
                      marginBlock: "0.25rem",
                      marginRight: "0.5rem",
                      color:
                        item?.gross_value < 0
                          ? theme.palette.color.red[500]
                          : theme.palette.color.green[500],
                      backgroundColor:
                        item?.gross_value < 0
                          ? theme.palette.color.red[50]
                          : theme.palette.color.green[50],
                      padding: "0.25rem",
                      paddingInline: "0.5rem",
                      borderRadius: theme.borderRadius.borderRadiusMD,
                    }}
                  >
                    {formatAmount({
                      amount: item?.gross_value,
                    })}
                  </p>
                  <p
                    style={{
                      fontFamily: theme.typography.fontFamily,
                      fontSize: "0.85rem",
                      fontWeight: 500,
                      color: theme.palette.color.slate[700],
                      display: "flex",
                      alignItems: "center",
                      marginBlock: "0.25rem",
                    }}
                  >
                    {item?.title}{" "}
                    {/* {item?.is_note_user_edited ? (
                    <PersonIcon sx={{ fontSize: "1rem", ml: "0.25rem" }} />
                  ) : null} */}
                  </p>
                </div>

                <p
                  style={{
                    fontFamily: theme.typography.fontFamily,
                    fontSize: "0.75rem",
                    fontWeight: 400,
                    color: theme.palette.color.slate[500],
                    marginBlock: "0.25rem",
                  }}
                >
                  {item?.note}
                </p>
                {item?.is_note_highlighted ? (
                  <div
                    style={{
                      borderBottom: `14px solid ${getTailwindColor(
                        "amber",
                        item?.is_note_highlighted
                          ? 100
                          : item?.is_note_user_edited
                            ? 300
                            : 200
                      )}`,
                      borderLeft: `14px solid transparent`,
                      width: 0,
                      height: 0,
                      position: "absolute",
                      right: "2px",
                      bottom: isSectionEnd ? "4rem" : "1rem",
                      zIndex: 20,
                      border: "none !important",
                    }}
                  />
                ) : null}
                {index === data?.length - 1 ? null : (
                  <Divider
                    sx={{
                      border: `1px ${isSectionEnd ? "solid" : "dashed"} ${theme.palette.color.slate[200]}`,
                      my: isSectionEnd ? "2rem" : "0.5rem",
                    }}
                  />
                )}
              </div>
            );
          })}
        </div>
      ) : (
        t("No notes found")
      )}
    </Paper>
  );
});

const RenderCustomizedLabel = (props) => {
  const { x, y, width, height, value, _key, cell } = props;
  const isPositive = value >= 0;
  const labelHeight = 14;
  const isBarHeightIsNotEnough = height < labelHeight;
  let textColor = theme.palette?.color?.slate[500];
  let bgColor = "transparent";

  if (_key === "booked") {
    textColor = theme.palette?.color.white;
  }
  if (_key === "remaining" || _key === "open") {
    textColor =
      theme.palette?.color?.[
        isPositive
          ? cell?.item?.income_expense_type === 1
            ? "green"
            : "red"
          : cell?.item?.income_expense_type === 1
            ? "red"
            : "green"
      ]?.[500];

    bgColor =
      theme.palette?.color?.[
        isPositive
          ? cell?.item?.income_expense_type === 1
            ? "green"
            : "red"
          : cell?.item?.income_expense_type === 1
            ? "red"
            : "green"
      ]?.[50];
  }
  if (!value || Number(value) === 0 || isBarHeightIsNotEnough) {
    return null;
  }

  const formattedValue = formatAmount({ amount: value });

  const textX = x + width / 2;
  const textY = y + height / 2;

  const paddingX = 2;
  const textBoxWidth = formattedValue.length * 6 + paddingX;
  const textBoxHeight = labelHeight;

  return (
    <>
      {/* Background Rectangle */}
      <rect
        x={textX - textBoxWidth / 2}
        y={textY - 1 - textBoxHeight / 2}
        width={textBoxWidth}
        height={textBoxHeight}
        fill={bgColor}
        rx={4} // Rounded corners
        ry={4}
      />
      {/* Text */}
      <text
        x={textX}
        y={textY}
        fill={textColor}
        fontSize={10}
        lineHeight={10}
        fontWeight={600}
        textAnchor={"middle"}
        dominantBaseline="middle"
      >
        {formattedValue}
      </text>
    </>
  );
};
