import { useEffect } from "react";

const KeyboardSortCutKey = ({ keys = [], theme, style, action }) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      const mappedKey = {
        "⌘": event.metaKey || event.ctrlKey, // Meta (Mac) or Ctrl (Windows/Linux)
        "⇧": event.shiftKey,
        "⌃": event.altKey,
        "↑": event.key === "ArrowUp",
        "↓": event.key === "ArrowDown",
        "←": event.key === "ArrowLeft",
        "→": event.key === "ArrowRight",
        "↵": event.key === "Enter",
        Esc: event.key === "Escape",
      };

      const isInputField =
        ["input", "textarea", "select"].includes(
          event.target.tagName.toLowerCase()
        ) ||
        event.target.isContentEditable ||
        event.target.closest("iframe");

      let isCombinationMatch = false;

      if (!!mappedKey?.[keys?.[0]]) {
        if (event.metaKey || event.ctrlKey || event.altKey || event.shiftKey) {
          if (keys?.[1]?.toLowerCase() === event.key?.toLowerCase()) {
            isCombinationMatch = true;
          }
        } else {
          isCombinationMatch = !!mappedKey?.[keys?.[0]];
        }
      } else {
        if (
          !event.metaKey &&
          !event.ctrlKey &&
          !event.altKey &&
          !event.shiftKey &&
          event.key?.toLowerCase() === keys?.[0]?.toLowerCase()
        ) {
          isCombinationMatch = true;
        }
      }

      if (isCombinationMatch && !isInputField) {
        event.preventDefault();
        action();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [keys, action]);

  return (
    <kbd
      style={{
        padding: "3px 6px",
        fontSize: "0.875rem",
        border: `1px solid ${theme.palette.color.grey[300]}`,
        borderRadius: theme.borderRadius.main,
        backgroundColor: theme.palette.color.white,
        color: theme.palette.color.slate?.[500],
        fontFamily: "monospace",
        display: "flex",
        alignItems: "center",
        gap: "1px",
        ...style,
      }}
    >
      {keys.map((key) => (
        <span
          key={key}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "inherit",
            fontSize: key === "⌘" ? "0.6rem" : "0.9rem",
            lineHeight: key === "⌘" ? "0.65rem" : "1rem",
            height: "1rem",
            paddingTop: key === "⌘" ? "1px" : 0,
          }}
        >
          {key}
        </span>
      ))}
    </kbd>
  );
};

export default KeyboardSortCutKey;
