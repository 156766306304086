import {
  ButtonProps as MuiButtonProps,
  useTheme,
  Tooltip,
  alpha,
} from "@mui/material";
import React, { forwardRef, Ref, useRef } from "react";
import { PiArrowElbowDownLeft } from "react-icons/pi";
import Button from "@mui/lab/LoadingButton";
import { useSelector } from "react-redux";

// Only include variant, size, and color
type ButtonBaseProps = Pick<MuiButtonProps, "variant" | "size" | "color">;

// Use all except disableRipple
// type ButtonBaseProps = Omit<MuiButtonProps, "disableRipple">;

interface ButtonProps extends ButtonBaseProps {
  label: string;
  onClick?: (e: any) => void;
  tooltipLabel?: string;
  disabled?: boolean;
  width?: string;
  showIcon?: boolean; // Add this line
  loading?: boolean;
  isSecondary?: boolean;
  isNKey?: boolean;
  hideKey?: boolean;
  doNotListenKey?: boolean;
  doNotListenInForms?: boolean;
  icon?: React.ReactNode;
  sx?: any;
}
const AddButton = forwardRef(
  (props: ButtonProps, _ref: Ref<HTMLButtonElement> | null | any) => {
    const theme = useTheme();
    const addButtonRef = useRef<HTMLButtonElement | null>(null);

    const {
      label,
      onClick,
      tooltipLabel = "Add",
      disabled = false,
      width,
      showIcon = true,
      icon,
      sx,
      loading,
      isSecondary,
      isNKey,
      hideKey,
      doNotListenKey,
      doNotListenInForms,
      ...rest
    } = props;
    const transactionsOverlayStatus = useSelector(
      (state: any) => state.datasetSlice?.transactionsOverlayStatus
    );
    const popupStatus2 = useSelector(
      (state: any) => state.datasetSlice?.popupStatus2
    );

    const isTransactionEditFormOpen =
      transactionsOverlayStatus?.open &&
      transactionsOverlayStatus?.overlay_type === "drawer_modal_edit_form";
    const isTransactionListFormOpen =
      popupStatus2?.open && popupStatus2?.overlay_type === "drawer_modal";

    const keyEnter = React.useCallback(
      (event: {
        key: string;
        target: any;
        preventDefault: () => void;
        stopPropagation: () => void;
      }) => {
        const tagName = event.target.tagName.toLowerCase();
        const isInput =
          tagName === "input" ||
          tagName === "textarea" ||
          tagName === "select" ||
          event?.target?.closest("iframe") !== null;

        if (addButtonRef.current) {
          const rect = addButtonRef.current?.getBoundingClientRect();
          const centerX = rect.left + rect.width / 2;
          const centerY = rect.top + rect.height / 2;
          const topElement = document.elementFromPoint(centerX, centerY);
          // Check if the top element at the button’s center is indeed the button itself
          const isTopElement = topElement === addButtonRef.current;

          if (
            !isInput &&
            !hideKey &&
            !doNotListenKey &&
            isTopElement &&
            (doNotListenInForms
              ? !isTransactionListFormOpen && !isTransactionEditFormOpen
              : true) &&
            (isNKey ? event.key?.toLowerCase() === "n" : event.key === "Enter")
          ) {
            // event.preventDefault();
            if (onClick) {
              onClick({
                stopPropagation: () => event?.stopPropagation(),
                currentTarget: addButtonRef?.current,
              });
            }
          }
        }
      },
      [
        hideKey,
        doNotListenKey,
        doNotListenInForms,
        isTransactionListFormOpen,
        isTransactionEditFormOpen,
        isNKey,
        onClick,
      ]
    );

    React.useEffect(() => {
      document.addEventListener("keydown", keyEnter);

      return () => {
        document.removeEventListener("keydown", keyEnter);
      };
    }, [keyEnter]);

    return (
      <Tooltip title={tooltipLabel}>
        <Button
          ref={addButtonRef}
          onClick={disabled ? undefined : onClick}
          startIcon={showIcon ? (icon ? icon : "+") : undefined}
          sx={{
            textTransform: "initial",
            fontSize: "0.8rem",
            lineHeight: "normal",
            paddingInline: "1rem",
            height: "2.2rem",
            width: width ? width : "auto",
            color: isSecondary
              ? theme.palette.color.slate[600]
              : theme.palette.color.white,
            border: isSecondary
              ? `1px solid ${theme.palette.color.slate[300]}`
              : 0,
            backgroundColor: isSecondary
              ? theme.palette.color.slate[50]
              : theme.palette.primary.main,
            borderRadius: theme.borderRadius.main,
            fontWeight: theme?.typography?.fontWeightBold,
            opacity: disabled ? 0.5 : 1,
            "& .MuiButton-startIcon": {
              fontSize: "1.1rem",
              pb: "1px",
            },
            "&:hover": {
              backgroundColor: isSecondary
                ? theme.palette.color.slate[100]
                : theme.palette.primary.darkHover,
            },
            ...sx,
          }}
          {...rest}
        >
          {label}
          {hideKey ? null : (
            <span
              style={{
                marginLeft: "0.5rem",
                width: "18px",
                height: "18px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontWeight: theme.typography.fontWeightBold,
                borderRadius: theme.borderRadius.main,
                border: `1px solid ${isSecondary ? theme.palette.color.slate[300] : alpha(theme.palette.color.white, 0.3)}`,
                fontSize: "0.725rem",
              }}
            >
              {isNKey ? "N" : <PiArrowElbowDownLeft strokeWidth={10} />}
            </span>
          )}
        </Button>
      </Tooltip>
    );
  }
);
export default AddButton;
