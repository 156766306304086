import {
  useImperativeHandle,
  forwardRef,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import _ from "underscore";

import EnableInternalDSFunctions from "../../../../components/Header/DatasetHeader/EnableInternalDSFunctions";
import useUpdateEffect from "../../../../hooks/4-useUpdateEffect/useUpdateEffect";
import { getAllTransactionsByParams, sleep } from "../../../../Helper/data";
import { useDeferredTimeout } from "../../../../hooks/useDeferredTimeout";
import useDebounce from "../../../../hooks/3-useDebounce/useDebounce";
import { setLoanData } from "../../../../store/slices/loan";
import { calculateEMI } from "../../../../Helper/functions";
import { Constant } from "../../../../Helper";
import store from "../../../../store";

const LoanCommonFunctions = forwardRef(
  (
    {
      rowData,
      setIsLoading,
      allowToFetch,
      isIncludedInFilterList,
      setIsIncludedInFilterList,
      isFilterOpen,
    },
    _ref
  ) => {
    const dispatch = useDispatch();
    const internalDsRef = useRef(null);
    const loadingRef = useRef(null);

    //redux
    const dataset = useSelector((state) => state.boardSlice?.dataSetData?.uuid);
    const refreshData = useSelector((state) => state.appSlice?.refreshData);
    const loanTab = useSelector((state) => state.loanSlice?.loanTab);
    const loanStateTab = useSelector((state) => state.loanSlice?.loanStateTab);
    const loanType = useSelector((state) => state.loanSlice?.loanType);
    const loanCategory = useSelector((state) => state.loanSlice?.loanCategory);
    const loanSearchText = useSelector(
      (state) => state.loanSlice?.loanSearchText
    );
    const datasetAccountList = useSelector(
      (state) => state.boardSlice.datasetAccountList
    );
    const use_global_categories = useSelector(
      (state) => state.boardSlice?.dataSetData?.use_global_categories
    );
    const _recurring_rules = useSelector(
      (state) => state.globalSlice?.recurring_rules
    );

    const internal_data_source = useMemo(() => {
      return datasetAccountList?.find((o1) => o1.internal_dataset === dataset);
    }, [dataset, datasetAccountList]);

    const RecurringRulesById = useMemo(
      () => _.groupBy(_recurring_rules, ({ uuid }) => uuid),
      [_recurring_rules]
    );
    //state
    const DeferredSearchText = useDeferredTimeout({ value: loanSearchText });

    const recurring_rules = useMemo(() => {
      return loanTab
        ? _recurring_rules
            ?.filter((item) => item?.recurring_type === loanTab)
            ?.map((o1) => o1?.uuid)
        : [];
    }, [_recurring_rules, loanTab]);

    const AllLoanData = useQuery({
      queryKey: [
        "transactions",
        {
          apiType: loanTab,
          dataset,
        },
      ],
      queryFn: ({ signal }) => {
        let param = {
          config: {
            signal,
          },
          dataset,
          recurring_rule: recurring_rules,
        };
        if (!use_global_categories) {
          param.category_dataset = dataset;
        } else {
          param.global_category = true;
        }
        const result = getAllTransactionsByParams(param);
        if (result) {
          return result;
        }
      },
      backgroundFetch: true,
      // refetchOnMount: true,
      priority: 3,
      enabled:
        !!dataset &&
        !!loanTab &&
        !!internal_data_source?.active &&
        recurring_rules?.length > 0,
    });

    const loanData = useMemo(() => {
      if (AllLoanData?.data && recurring_rules?.length > 0) {
        const selectionCategoriesByID =
          store.getState().categorySlice?.selectionCategoriesByID;

        return AllLoanData?.data
          ?.filter(
            (item) =>
              (DeferredSearchText?.length > 0
                ? item?.title
                    ?.toLowerCase()
                    ?.includes(DeferredSearchText?.toLowerCase())
                : true) &&
              (loanType?.length > 0 && item?.recurring_rule
                ? loanType?.includes(
                    RecurringRulesById?.[item?.recurring_rule]?.[0]
                      ?.employee_type //employee_type we use as loan_type
                  )
                : true) &&
              (loanCategory?.length > 0 ? loanCategory?.includes(item?.category) : true)
          )
          ?.filter((o1) =>
            use_global_categories
              ? !selectionCategoriesByID?.[o1?.category]?.[0]?.dataset
              : selectionCategoriesByID?.[o1?.category]?.[0]?.dataset ===
                dataset
          );
      } else {
        return [];
      }
    }, [
      AllLoanData?.data,
      recurring_rules?.length,
      DeferredSearchText,
      loanCategory,
      RecurringRulesById,
      loanType,
      use_global_categories,
      dataset,
    ]);

    const loading = AllLoanData?.isFetching;

    const filteredTransactionsByState = useMemo(() => {
      let AllTransactionByState = {
        Planned: [],
        Ignored: [],
        "Written-off": [],
        All: [],
      };
      if (loanData?.length === 0) return AllTransactionByState;

      Object.keys(rowData.current)?.forEach((key) => {
        if (key !== "All") {
          AllTransactionByState[key] = loanData?.filter(
            (o1) => o1.state === key
          );
        } else {
          AllTransactionByState["All"] = loanData?.filter((o1) =>
            Constant.staffState?.includes(o1.state)
          );
        }
      });

      return AllTransactionByState;
    }, [loanData, rowData]);

    //lifecycle
    useImperativeHandle(
      _ref,
      () => ({
        filteredTransactionsByState,
        filteredRecurring_rules: recurring_rules,
      }),
      [filteredTransactionsByState, recurring_rules]
    );

    useEffect(() => {
      if (!internal_data_source?.active) {
        internalDsRef.current?.enableInternalDS();
      }
    }, [internal_data_source]);

    useUpdateEffect(() => {
      if (!loading) {
        clearTimeout(loadingRef.current);
        loadingRef.current = setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      } else {
        setIsLoading(true);
      }
    }, [loading]);

    useDebounce(
      () => {
        setIsLoading(true);
        AllLoanData?.refetch();
      },
      100,
      [refreshData]
    );

    //filter update
    useDebounce(
      () => {
        if (!isFilterOpen?.current) {
          let included_array = [];
          if (loanCategory?.length > 0) {
            if (!isIncludedInFilterList?.includes("category")) {
              included_array.push("category");
            }
          }
          if (loanType?.length > 0) {
            if (!isIncludedInFilterList?.includes("loanType")) {
              included_array.push("loanType");
            }
          }
          setIsIncludedInFilterList(included_array);
        }
      },
      500,
      [loanCategory],
      true
    );

    //loan list
    useDebounce(
      () => {
        if (!loading && allowToFetch && AllLoanData?.data) {
          Object.keys(rowData.current).forEach((key) => {
            const filterData = filteredTransactionsByState[key];
            const loanRecurringList =
              filterData?.length > 0
                ? [...new Set(filterData?.map((o1) => o1?.recurring_rule))]
                : [];

            const TransactionsByRecurringRule = _.groupBy(
              filterData,
              ({ recurring_rule }) => recurring_rule
            );
            let array = [];
            loanRecurringList?.forEach((loan_recurring_id) => {
              const loan_rule = RecurringRulesById?.[loan_recurring_id]?.[0];

              const item =
                TransactionsByRecurringRule?.[loan_recurring_id]?.[0];
              if (!item || !loan_rule) return;

              const { totalInterestPaid } = calculateEMI({
                principal: loan_rule?.value,
                downPayment: loan_rule?.deposit_value,
                interest_rate: loan_rule?.interest_rate,
                interest_rate_type: loan_rule?.interest_rate_type,
                loanTenureInMonths: loan_rule?.payment_default,
              });
              array.push({
                recurring_rule: {
                  ...loan_rule,
                  interest_rate_paid: totalInterestPaid,
                },
                item,
                TransactionsByRecurringRule,
                AllLoanData: AllLoanData?.data,
              });
            });

            rowData.current[key] = array.sort(
              (a, b) => (b?.grossSalary || 0) - (a?.grossSalary || 0)
            );
          });
          sleep(200);
          let data = rowData.current?.[loanStateTab] || [];
          if (data?.length > 1) {
            const footer = {
              item: {
                uuid: "total",
                isTotal: true,
                title: "",
              },
              recurring_rule: {
                uuid: "total",
                isTotal: true,
                value: data
                  ?.map((o1) => o1?.recurring_rule?.value || 0)
                  ?.reduce((total, item) => {
                    return Number(total) + Number(item);
                  }),
                average_value: data
                  ?.map((o1) => o1?.recurring_rule?.average_value || 0)
                  ?.reduce((total, item) => {
                    return Number(total) + Number(item);
                  }),
                interest_rate_paid: data
                  ?.map((o1) => o1?.recurring_rule?.interest_rate_paid || 0)
                  ?.reduce((total, item) => {
                    return Number(total) + Number(item);
                  }),
                interest_rate: parseFloat(
                  data
                    ?.map((o1) => o1?.recurring_rule?.interest_rate || 0)
                    ?.reduce((total, item) => {
                      return Number(total) + Number(item);
                    }) / data?.length
                )?.toFixed(1),
              },
            };
            data.push(footer);
          }
          setIsLoading(false);
          dispatch(setLoanData(data));
        } else {
          clearTimeout(loadingRef.current);
          loadingRef.current = setTimeout(() => {
            setIsLoading(false);
            dispatch(setLoanData([]));
          }, 1000);
        }
      },
      500,
      [
        loading,
        loanData,
        _recurring_rules,
        loanStateTab,
        loanTab,
        allowToFetch,
        filteredTransactionsByState,
        use_global_categories,
        recurring_rules,
      ],
      true
    );

    return <EnableInternalDSFunctions ref={internalDsRef} />;
  }
);

export default LoanCommonFunctions;
