import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Alert, AlertTitle, Box, Modal, useTheme } from "@mui/material";
import { PiChatsDuotone } from "react-icons/pi";
import { Helmet } from "react-helmet";

import Translate from "../../hooks/HOC/Translate";
import ComponentLoader from "../ComponentLoader";

const CalendlyOverlay = forwardRef((props, ref) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleOpen = () => {
    setOpen(true);
    setLoading(true);
  };

  const handleClose = () => {
    setOpen(false);
    setLoading(true);
  };

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [open]);

  useImperativeHandle(ref, () => ({ handleOpen, handleClose }));

  return (
    <Modal
      ref={ref}
      open={open}
      onClose={handleClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
      sx={{ zIndex: 1321 }}
    >
      <Box
        onClick={handleClose}
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          minWidth: "46%",
          minHeight: "50%",
          transform: "translate(-50%, -50%)",
          borderRadius: theme.borderRadius.main,
          ...theme.thinScrollBar,
        }}
      >
        {!loading ? (
          <Alert
            severity="warning"
            icon={<PiChatsDuotone />}
            sx={{ width: "fit-content", mx: "auto" }}
          >
            <AlertTitle sx={{ mb: 0, mt: 0 }}>
              <Translate i18nkey={"calendly_overlay_message"} />
            </AlertTitle>
          </Alert>
        ) : null}
        <Helmet>
          <script
            type="text/javascript"
            src="https://assets.calendly.com/assets/external/widget.js"
            async
          ></script>
        </Helmet>
        <ComponentLoader
          loading={loading}
          hideNoDataPlaceholder
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
        <div
          loading="lazy"
          className="calendly-inline-widget"
          data-url="https://calendly.com/finban/15min"
          style={{
            width: "60vw",
            height: "720px",
          }}
        ></div>
      </Box>
    </Modal>
  );
});

export default CalendlyOverlay;
