import {
  CardActionArea,
  ListItemAvatar,
  ListItemText,
  CardContent,
  Typography,
  CardMedia,
  ListItem,
  Avatar,
  Stack,
  Card,
  Chip,
  Box,
} from "@mui/material";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/styles";

import { setIsFirstLoginOverlayOpen } from "../../store/slices/global";
import { setAddFrom, setProfile } from "../../store/slices/settings";
import initialData, { thinScrollbarStyle } from "../../Helper/data";
import useDebounce from "../../hooks/3-useDebounce/useDebounce";
import DescriptionInput from "./DescriptionInput";
import Translate from "../../hooks/HOC/Translate";
import EndPoints from "../../APICall/EndPoints";
import CustomModal from "../Model/CustomModal";
import TailwindButton from "./TailwindButton";
import { Color, Images } from "../../Helper";
import TitleInput from "./TitleInput";
import APICall from "../../APICall";
import DropDown from "./DropDown";

const purposeOption = [
  {
    display_name: "purpose_title_01",
    title: "Liquidity planning with scenarios",
    value: 1,
  },
  {
    display_name: "purpose_title_02",
    title: "Create financial plans",
    value: 2,
  },
  {
    display_name: "purpose_title_03",
    title: "Analyze cash flow and identify problems early on",
    value: 3,
  },
  {
    display_name: "purpose_title_04",
    title: "Gain more understanding of revenue and customers",
    value: 4,
  },
  {
    display_name: "purpose_title_05",
    title: "For my business plan",
    value: 8,
  },
  {
    display_name: "purpose_title_06",
    title: "Get analysis of the costs of employees and freelancers",
    value: 5,
  },
  {
    display_name: "purpose_title_07",
    title: "Calculate my advance sales tax payments",
    value: 6,
  },
  {
    display_name: "purpose_title_08",
    title:
      "Gain more insight into my expenses, such as recurring payments (subscriptions)",
    value: 7,
  },
];

const FirstLoginOverlay = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();

  //redux
  const profile = useSelector((state) => state.settingsSlice?.profile);
  const isFirstLoginOverlayOpen = useSelector(
    (state) => state.globalSlice.isFirstLoginOverlayOpen
  );

  //state
  const [wantedTools, setWantedTools] = useState("");
  const [isOtherWantedTools, setIsOtherWantedTools] = useState(false);
  const [step, setStep] = useState(1);
  const [error, setError] = useState("");
  const [option, setOption] = useState({
    position: [],
    employee_count: [],
    country: [],
    business_type: [],
    start_with: [],
  });

  const toolsOption = useMemo(() => {
    let data = [];
    //31
    Array.from({ length: 33 }).forEach((_, index) => {
      let count = index + 1;
      if (count < 10) {
        count = `0${count}`;
      }
      if (count !== 28) {
        data.push({
          display_name: `wanted_tool_title_${count}`,
          value: index + 1,
        });
      }
    });

    data.sort((a, b) => {
      const displayNameA = t(a.display_name).toLowerCase();
      const displayNameB = t(b.display_name).toLowerCase();
      return displayNameA.localeCompare(displayNameB);
    });
    data.push({
      display_name: `wanted_tool_title_${28}`,
      value: 28,
    });
    return data;
  }, [t]);

  //lifeCycle
  useEffect(() => {
    getOptions();
  }, []);

  useDebounce(
    () => {
      if (profile) {
        const array1 = profile?.wanted_tools?.filter((item) => item?.trim(""));

        let obj = {
          company_name: profile?.company_name ?? "",
          purpose_of_using_finban: profile?.purpose_of_using_finban ?? "",
          wanted_tools: array1 ?? [],
          other_business_type: profile?.other_business_type,
        };
        updateProfile(obj);
      }
    },
    1000,
    [
      profile?.company_name,
      profile?.purpose_of_using_finban,
      profile?.wanted_tools,
      profile?.other_business_type,
    ]
  );

  useEffect(() => {
    if (
      isFirstLoginOverlayOpen &&
      (wantedTools?.trim("")?.length > 0 ||
        !!profile?.wanted_tools?.find(
          (item) => !toolsOption?.includes(item?.display_name)
        ))
    ) {
      setIsOtherWantedTools(true);
    } else {
      setIsOtherWantedTools(false);
    }
  }, [
    isFirstLoginOverlayOpen,
    wantedTools,
    profile?.wanted_tools,
    toolsOption,
  ]);

  //api
  const updateProfile = async (obj) => {
    await APICall("patch", EndPoints.profile, obj).then((response) => {
      if (response.status === 200 && response.data) {
      }
    });
  };

  const getOptions = async () => {
    await APICall("OPTIONS", EndPoints.profile).then((response) => {
      if (response.status === 200 && response.data) {
        let data = response.data.actions.PUT;
        setOption({
          position: data["position"]?.choices,
          employee_count: data["employee_count"]?.choices,
          country: data["country"]?.choices,
          business_type: data["business_type"]?.choices,
          start_with: data["start_with"]?.choices,
        });
      }
    });
  };

  //function
  const onClose = () => {
    dispatch(setIsFirstLoginOverlayOpen(false));
  };

  const onClickSkip = (obj = {}) => {
    dispatch(setAddFrom(null));
    onClose();
    dispatch(
      setProfile({ ...profile, first_login: false, start_with: 2, ...obj })
    );
    updateProfile({ first_login: false, start_with: 2, ...obj });
  };

  const onClickBack = () => {
    setStep((no) => no - 1);
  };

  const onClickNext = () => {
    if (step === 4) {
      let name = null;
      let errorText = null;
      const array2 = String(wantedTools)?.trim("")
        ? wantedTools?.split(",")?.map((item) => item?.trim(""))
        : [];

      const array1 =
        profile?.wanted_tools?.length > 0
          ? profile?.wanted_tools?.filter((item) => item?.trim(""))
          : [];

      const _wanted_tools = [...array1, ...array2];
      if (
        (!String(_wanted_tools)?.trim("") || _wanted_tools?.length === 0) &&
        !name
      ) {
        name = "wanted_tools";
        errorText = "first_overlay_wanted_tools_error";
      }
      if (name && errorText) {
        setError({ ...error, [name]: errorText });
        return;
      }
      let obj = {
        wanted_tools: _wanted_tools,
      };

      onClickSkip(obj);
    } else {
      let name = null;
      let errorText = null;
      if (step === 2) {
        if (profile?.company_name?.trim()?.length === 0 && !name) {
          name = "company_name";
          errorText = "first_overlay_company_name_error";
        }
        if (!profile?.employee_count && !name) {
          name = "employee_count";
          errorText = "first_overlay_employee_count_error";
        }
        if (profile?.business_type === -1 && !profile?.other_business_type) {
          name = "other_business_type";
          errorText = "first_overlay_other_business_type_error";
        }
        if (!profile?.country && !name) {
          name = "country";
          errorText = "first_overlay_country_error";
        }
        if (!profile?.business_type && !name) {
          name = "business_type";
          errorText = "first_overlay_business_type_error";
        }
        if (!profile?.position && !name) {
          name = "position";
          errorText = "first_overlay_position_error";
        }
      }
      if (step === 3) {
        if (profile?.purpose_of_using_finban?.trim()?.length === 0 && !name) {
          name = "purpose_of_using_finban";
          errorText = "first_overlay_purpose_of_using_finban_error";
        }
      }
      if (name && errorText) {
        setError({ ...error, [name]: errorText });
        return;
      }
      setStep((no) => no + 1);
    }
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    if (error[name]) setError({ ...error, [name]: null });
    dispatch(setProfile({ ...profile, [name]: value || "" }));
    if (
      name !== "purpose_of_using_finban" &&
      name !== "company_name" &&
      name !== "other_business_type"
    ) {
      updateProfile({ [name]: value });
    }
  };

  const handleChangeWantedTools = (e) => {
    setError({ ...error, wanted_tools: null });

    const { value } = e.target;
    setWantedTools(value);
  };

  const onClickText = (display_name) => {
    if (error.purpose_of_using_finban)
      setError({ ...error, purpose_of_using_finban: null });
    let text = profile?.purpose_of_using_finban || "";
    if (text?.trim()?.length !== 0) {
      text = text + ", " + display_name;
    } else {
      text = display_name;
    }
    dispatch(setProfile({ ...profile, purpose_of_using_finban: text }));
  };

  const onClickToolText = (display_name) => {
    if (error?.wanted_tools) setError({ ...error, wanted_tools: null });
    if (display_name === "wanted_tool_title_28") {
      const element = document.getElementById("filled-multiline-flexible");
      element?.focus();
    } else {
      const array1 = profile?.wanted_tools?.filter((item) => item?.trim(""));

      let textArray = [...(array1 || [])];
      if (textArray?.includes(display_name)) {
        textArray = textArray?.filter((item) => item !== display_name);
      } else {
        textArray.push(display_name);
      }

      dispatch(setProfile({ ...profile, wanted_tools: textArray }));
    }
  };

  const onClickCard = (no) => {
    dispatch(setProfile({ ...profile, start_with: no }));
    updateProfile({ start_with: no });
  };

  const handleChangeDropDown = (e, value, name) => {
    if (error[name]) setError({ ...error, [name]: null });
    let obj = { [name]: value?.value || null };
    if (name === "business_type") {
      if (value?.value !== -1) {
        obj.other_business_type = null;
      }
    }
    dispatch(setProfile({ ...profile, ...obj }));
    updateProfile(obj);
  };

  //render function
  const step1old = () => {
    return (
      <>
        <Header
          primary={t("first_login_step1_title")}
          secondary={t("first_login_step1_description")}
          image={Images.hello}
        />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {" "}
          <Stack direction="row" spacing={1} mt="1rem">
            <CardView
              theme={theme}
              title={t("start_with_online_title")}
              description={t("start_with_online_desc")}
              note
              isSelected={profile?.start_with === 1}
              image={Images.setup_online_bank_account}
              onClick={() => onClickCard(1)}
            />
            <CardView
              theme={theme}
              title={t("start_with_offline_title")}
              description={t("start_with_offline_desc")}
              isSelected={profile?.start_with === 2}
              image={Images.setup_offline_organisation}
              onClick={() => onClickCard(2)}
            />
          </Stack>
        </Box>
      </>
    );
  };

  const step1 = () => {
    return (
      <>
        <img
          component="img"
          style={{
            objectFit: "contain",
          }}
          src={Images.latest_updates_banner}
          alt="mailing_banner"
        />

        <div
          style={{
            padding: "2.5rem",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {" "}
          <ListItemText
            primary={<Translate i18nkey={"updates_overlay_title"} />}
            sx={{
              "& span": {
                fontSize: "2rem",
                fontWeight: 700,
              },
            }}
          />{" "}
          <Box
            sx={{
              display: "flex",
              textAlign: "left",
              height: "18rem",
              overflow: "auto",
              ...thinScrollbarStyle,
            }}
          >
            <ListItemText
              primary={""}
              secondary={<Translate i18nkey={"updates_overlay_description"} />}
              sx={{
                "& .MuiTypography-root": {
                  fontSize: "1rem",
                  lineHeight: "1.5rem",
                  fontWeight: 500,
                  "& b": {
                    lineHeight: "1.5rem",
                  },
                },
              }}
            />
          </Box>
        </div>
      </>
    );
  };

  const step2 = () => {
    return (
      <div
        style={{
          padding: "2.5rem",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Header
          primary={<Translate i18nkey={"first_login_step2_title"} />}
          secondary={<Translate i18nkey={"first_login_step2_description"} />}
        />

        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            // mt: "5rem",
          }}
        >
          <Box sx={{ width: "71.5%" }}>
            <TitleInput
              value={profile?.company_name ?? ""}
              onChange={handleChange}
              name="company_name"
              label={t("Company_Name")}
              hideTitle
              likeGoogle
              variant="filled"
              helperText={t(error?.company_name)}
              error={Boolean(error?.company_name)}
            />
          </Box>
          <Box sx={{ width: "71.5%", mt: "2rem" }}>
            <DropDown
              hideTitle
              likeGoogle
              variant="filled"
              fontSize="0.8rem"
              value={
                option?.employee_count?.find(
                  (o1) => o1?.value === profile?.employee_count
                ) || ""
              }
              onChange={(e, value) =>
                handleChangeDropDown(e, value, "employee_count")
              }
              renderOption={(option) => `${option?.display_name} ` ?? ""}
              getOptionLabel={(option) => option?.display_name ?? ""}
              options={option?.employee_count || []}
              label={t("Number_of_Employees_in_company")}
              helperText={t(error?.employee_count)}
              error={Boolean(error?.employee_count)}
            />
          </Box>
          <Box sx={{ width: "71.5%", mt: "2rem" }}>
            <DropDown
              hideTitle
              likeGoogle
              variant="filled"
              fontSize="0.8rem"
              value={
                initialData.euro_countries?.find(
                  (o1) => o1?.market === profile?.country
                ) || ""
              }
              onChange={(e, value) =>
                handleChangeDropDown(
                  e,
                  { ...value, value: value?.market },
                  "country"
                )
              }
              renderOption={(option) => `${option?.country}` ?? ""}
              getOptionLabel={(option) => option?.country ?? ""}
              options={initialData.euro_countries}
              label={t("Country")}
              helperText={t(error?.country)}
              error={Boolean(error?.country)}
            />
          </Box>
          <Box
            sx={{
              width: "71.5%",
              mt: "2rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <DropDown
              hideTitle
              likeGoogle
              tooltip={"first_login_tooltip_business_type"}
              variant="filled"
              fontSize="0.8rem"
              value={
                option?.business_type?.find(
                  (o1) => o1?.value === profile?.business_type
                ) || ""
              }
              onChange={(e, value) =>
                handleChangeDropDown(e, value, "business_type")
              }
              renderOption={(option) => `${t(option?.display_name)}` ?? ""}
              getOptionLabel={(option) => `${t(option?.display_name)}` ?? ""}
              options={option?.business_type || []}
              label={t("business_type")}
              helperText={t(error?.business_type)}
              error={Boolean(error?.business_type)}
            />
            {profile?.business_type === -1 ? (
              <TitleInput
                value={profile?.other_business_type ?? ""}
                onChange={handleChange}
                name="other_business_type"
                label={t("other_business_type")}
                hideTitle
                likeGoogle
                variant="filled"
                helperText={t(error?.other_business_type)}
                error={Boolean(error?.other_business_type)}
                sx={{ ml: "1rem" }}
              />
            ) : null}
          </Box>
          <Box sx={{ width: "71.5%", mt: "2rem" }}>
            <DropDown
              hideTitle
              likeGoogle
              variant="filled"
              fontSize="0.8rem"
              value={
                option?.position?.find(
                  (o1) => o1?.value === profile?.position
                ) || ""
              }
              onChange={(e, value) =>
                handleChangeDropDown(e, value, "position")
              }
              renderOption={(option) => `${t(option?.display_name)}` ?? ""}
              getOptionLabel={(option) => `${t(option?.display_name)}` ?? ""}
              options={option?.position || []}
              label={t("Your_Position")}
              helperText={t(error?.position)}
              error={Boolean(error?.position)}
            />
          </Box>
        </Box>
      </div>
    );
  };

  const step3 = () => {
    const text = profile?.purpose_of_using_finban
      ?.split(",")
      ?.map((item) => t(item?.trim("")))
      ?.join(", ");
    return (
      <div
        style={{
          padding: "2.5rem",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Header
          primary={<Translate i18nkey={"first_login_step3_title"} />}
          secondary={<Translate i18nkey={"first_login_step3_description"} />}
        />

        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              width: "71.5%",
            }}
          >
            <DescriptionInput
              maxRows={6}
              value={text ?? ""}
              onChange={handleChange}
              name="purpose_of_using_finban"
              label={t("purpose_of_using_finban")}
              hideTitle
              likeGoogle
              variant="filled"
              helperText={t(error?.purpose_of_using_finban)}
              error={Boolean(error?.purpose_of_using_finban)}
            />
          </Box>
          <Stack
            direction="row"
            mt="2rem"
            flexWrap="wrap"
            width="71.5%"
            height="70%"
            overflow={"auto"}
            sx={{
              ...thinScrollbarStyle,
            }}
          >
            {purposeOption?.map((item) => (
              <Chip
                key={item?.value}
                label={t(item?.display_name)}
                variant="outlined"
                onClick={() => onClickText(item?.display_name)}
                disabled={profile?.purpose_of_using_finban?.includes(
                  item?.display_name
                )}
                size="small"
                sx={{
                  fontSize: "0.8rem",
                  fontWeight: 500,
                  height: "fit-content",
                  p: "0.7rem",
                  mb: "1rem",
                  mr: "1rem",
                  borderRadius: "2rem",
                }}
              />
            ))}
          </Stack>
        </Box>
      </div>
    );
  };

  const step4 = () => {
    return (
      <div
        style={{
          padding: "2.5rem",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Header
          primary={<Translate i18nkey={"first_login_step4_title"} />}
          secondary={<Translate i18nkey={"first_login_step4_description"} />}
        />

        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              width: "71.5%",
              display: "flex",
              height: "8rem",
              p: "1rem",
              borderRadius: 1.5,
              "&:hover": {
                backgroundColor: Color.inputBG,
              },
            }}
          >
            {profile?.wanted_tools?.length > 0 ? (
              profile?.wanted_tools?.map((item, index) => (
                <Typography
                  color="color.description"
                  key={item}
                  mb="1rem"
                  fontSize="0.9rem"
                  fontWeight={500}
                >
                  {`${index !== 0 ? ", " : ""}${t(item)}`}
                </Typography>
              ))
            ) : (
              <Typography
                color={Color.tailwind.slate[400]}
                mb="1rem"
                fontSize="0.9rem"
                fontWeight={500}
              >
                {t("wanted_tool")}
              </Typography>
            )}
          </Box>
          {error?.wanted_tools && (
            <Typography
              color="red"
              fontSize="0.8rem"
              textAlign={"left"}
              width="71.5%"
            >
              {t(error?.wanted_tools)}
            </Typography>
          )}
          <Stack
            direction="row"
            mt="1rem"
            flexWrap="wrap"
            width="71.5%"
            height="70%"
            overflow={"auto"}
            sx={{
              ...thinScrollbarStyle,
            }}
          >
            {toolsOption?.map((item) => (
              <Chip
                key={item?.value}
                label={t(item?.display_name)}
                variant="outlined"
                onClick={() => onClickToolText(item?.display_name)}
                size="small"
                sx={{
                  fontSize: "0.8rem",
                  fontWeight: 500,
                  height: "fit-content",
                  p: "0.7rem",
                  mb: "0.75rem",
                  mr: "0.75rem",
                  borderRadius: "2rem",
                  backgroundColor:
                    (isOtherWantedTools &&
                      item?.display_name === "wanted_tool_title_28") ||
                    profile?.wanted_tools?.includes(item?.display_name)
                      ? Color.tailwind.slate[100]
                      : "transparent",
                }}
              />
            ))}
          </Stack>
          <DescriptionInput
            maxRows={6}
            value={wantedTools}
            onChange={handleChangeWantedTools}
            name="wanted_tools"
            label={t(
              "Don't find your tools? Enter them here (Comma separated)"
            )}
            hideTitle
            likeGoogle
            variant="filled"
            sx={{
              width: "71.5%",
              "& .MuiInputLabel-root.Mui-focused": {
                maxWidth: "96%",
              },
            }}
          />
        </Box>
      </div>
    );
  };

  const CardView = ({
    theme,
    title,
    description,
    isSelected,
    image,
    onClick,
    note = false,
  }) => {
    return (
      <Card
        variant="outlined"
        sx={{
          width: "18rem",
          height: "24rem",
          mx: "1.5rem",
          border: isSelected && `1px solid ${theme.palette.primary.main}`,
          opacity: !isSelected && 0.7,
        }}
      >
        <CardActionArea
          onClick={onClick}
          sx={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              flex: 1,
              pt: "1rem",
              position: "relative",
              width: "100%",
            }}
          >
            {isSelected ? (
              <CheckCircleIcon
                sx={{
                  position: "absolute",
                  top: "6%",
                  right: "4%",
                  color: theme.palette.primary.main,
                }}
              />
            ) : (
              <RadioButtonUncheckedIcon
                sx={{
                  position: "absolute",
                  top: "6%",
                  right: "4%",
                  color: Color.theme.grey[300],
                }}
              />
            )}
            <CardMedia
              component="img"
              sx={{
                width: "13rem",
                position: "absolute",
                top: "6%",
                right: "14%",
              }}
              image={image}
              alt="setup_online_organisation"
            />
          </Box>

          <CardContent
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              flex: 1,
              pt: "2rem",
            }}
          >
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              display="flex"
              flexDirection="column"
              alignItems="center"
              fontSize="0.9rem"
              mb="0.5rem"
              fontWeight="500"
            >
              {title}
            </Typography>
            <Typography variant="body2" color="color.description">
              {description}
            </Typography>
            {note ? (
              <span
                style={{
                  color: Color.red,
                  fontSize: "0.8rem",
                  marginTop: "1rem",
                }}
              >
                {t("Currently_only_Europe")}
              </span>
            ) : null}
          </CardContent>
        </CardActionArea>
      </Card>
    );
  };

  const Header = ({
    hideImage = false,
    image = Images.onboarding_avatar,
    primary,
    secondary,
  }) => {
    return (
      <ListItem sx={{ px: 0, pt: 0 }}>
        {hideImage ? null : (
          <ListItemAvatar>
            <Avatar
              alt="Hi"
              src={image}
              sx={{
                width: "7.875rem",
                height: "6rem",
                ml: "-1.3rem",
                borderRadius: 0,
              }}
            />
          </ListItemAvatar>
        )}
        <ListItemText
          primary={primary}
          secondary={secondary}
          sx={{
            ml: hideImage ? 0 : "2rem",
            span: {
              fontSize: "2rem",
            },
            ".MuiListItemText-secondary": {
              fontSize: "1.2rem",
            },
          }}
        />
      </ListItem>
    );
  };

  const Footer = () => {
    return (
      <div
        style={{
          display: "inherit",
          justifyContent: step === 1 ? "flex-end" : "space-between",
          alignItems: "center",
          position: "absolute",
          bottom: "2rem",
          left: "2.5rem",
          right: "2.5rem",
        }}
      >
        {step === 1 ? null : (
          <TailwindButton
            variant="outlined"
            text={t("Back")}
            onClick={onClickBack}
            color={Color.tailwind.purple[900]}
          />
        )}
        <TailwindButton
          text={t("Next")}
          onClick={onClickNext}
          backgroundColor={Color.tailwind.purple[900]}
        />
      </div>
    );
  };

  return (
    <CustomModal
      hideClose
      backdropClose={false}
      hideAction
      open={isFirstLoginOverlayOpen}
      backdropStyle={{
        backdropFilter: "blur(6px)",
        WebkitBackdropFilter: "blur(6px)",
      }}
    >
      <Box
        sx={{
          width: "68rem",
          height: "48rem",
          display: "inline-flex",
          flexDirection: "column",
          backgroundColor: `${Color?.white} !important`,
          borderRadius: "6px",
          overflow: "hidden",
          position: "relative",
        }}
      >
        {step === 1 ? step1() : null}
        {step === 2 ? step2() : null}
        {step === 3 ? step3() : null}
        {step === 4 ? step4() : null}
        <Footer />
      </Box>
    </CustomModal>
  );
};

export default FirstLoginOverlay;
