import { Stack, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";

import OverlayFooter from "../../../../../components/Overlay/OverlayFooter";

const SelectSheetStep = ({ state, onNext, onBack }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const sheetNames = state?.workbook?.SheetNames || [];

  //state
  const [selectedSheets, setSelectedSheets] = useState(sheetNames?.[0]);

  //functions
  const onClickAction = () => {
    onNext(selectedSheets);
  };

  const onClickSheet = (sheetName) => {
    setSelectedSheets(sheetName);
  };

  return (
    <Stack
      direction={"column"}
      gap={"0.5rem"}
      sx={{
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <Stack
        direction={"row"}
        gap={"1rem"}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        {sheetNames?.map((sheetName) => (
          <Typography
            onClick={() => onClickSheet(sheetName)}
            variant="body2"
            key={sheetName}
            sx={{
              cursor: "pointer",
              px: "2rem",
              py: "1.5rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minWidth: "12rem",
              borderRadius: theme?.borderRadius?.main,
              fontWeight: theme.typography.fontWeightMediumBold,
              textAlign: "center",
              backgroundColor:
                sheetName === selectedSheets
                  ? theme.palette.color.green[100]
                  : theme.palette.color.slate[100],
              color:
                sheetName === selectedSheets
                  ? theme.palette.color.green[600]
                  : theme.palette.color.slate[600],
              border: `1px solid ${
                sheetName === selectedSheets
                  ? theme.palette.color.green[600]
                  : theme.palette.color.slate[600]
              }`,
              "& svg": {
                fontSize: "1.5rem",
              },
              "&:hover": {
                backgroundColor:
                  sheetName === selectedSheets
                    ? theme.palette.color.green[100]
                    : theme.palette.color.slate[200],
              },
            }}
          >
            {sheetName}
          </Typography>
        ))}
      </Stack>
      <Typography
        variant="h5"
        fontWeight={"fontWeightBold"}
        color={theme.palette.color.slate[800]}
      >
        {t(state?.step?.title)}
      </Typography>
      <Typography
        variant="caption"
        fontWeight={"fontWeightBold"}
        color={theme.palette.color.description}
      >
        {t(state?.step?.des)}
      </Typography>
      <Stack
        sx={{
          width: "100%",
          position: "absolute",
          bottom: "0",
          left: "0",
          right: "0",
          px: "1.75rem",
          py: "1rem",
          backgroundColor: theme.palette.color.slate[100],
        }}
      >
        <OverlayFooter
          onCancel={onBack}
          addIcon={null}
          cancelIcon={null}
          onClickAction={onClickAction}
          addButtonSx={{
            py: "0.75rem",
          }}
          cancelButtonSx={{
            py: "0.75rem",
          }}
        />
      </Stack>
    </Stack>
  );
};

export default SelectSheetStep;
