import { format } from "date-fns";
import React from "react";

import { formatAmount } from "../../Helper/data";

const CustomizedAxisTick = React.memo((props) => {
  const {
    x,
    y,
    payload,
    dy = 0,
    dx = 0,
    formatted = false,
    formatDate,
    highlightValue,
    absoluteValue,
  } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={dy}
        dx={dx}
        fontSize={"0.625rem"}
        fontWeight={highlightValue === payload?.value ? 900 : 500}
        textAnchor="middle"
        fill="#666"
        // transform="rotate(-90)"
      >
        {formatted
          ? formatAmount({
              amount: String(
                parseInt(
                  absoluteValue
                    ? Math.abs(payload?.value ?? 0)
                    : payload?.value ?? 0
                )
              ),
            })
          : formatDate && payload?.value
            ? format(new Date(payload?.value), "MMM-yyyy")
            : payload?.value}
      </text>
    </g>
  );
});
export default CustomizedAxisTick;
