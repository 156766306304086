import {
  useImperativeHandle,
  forwardRef,
  useEffect,
  useState,
  useMemo,
  useRef,
} from "react";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useDispatch, useSelector } from "react-redux";
import UpgradeIcon from "@mui/icons-material/Upgrade";
import SearchIcon from "@mui/icons-material/Search";
import { PiMagicWandFill } from "react-icons/pi";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useSnackbar } from "notistack";
import _ from "underscore";

import CategoryDnDAttentionOverlay from "./CategoryDnDAttentionOverlay";
import useDebounce from "../../../hooks/3-useDebounce/useDebounce";
import ComponentLoader from "../../../components/ComponentLoader";
import CategoryCommonFunctions from "./CategoryCommonFunctions";
import { setCategories } from "../../../store/slices/category";
import { updateFilters } from "../../../store/slices/global";
import MenuView from "../../../components/Overlay/MenuView";
import CategoryMoveOverlay from "./CategoryMoveOverlay";
import CustomPopover from "../../../components/PopOver";
import EndPoints from "../../../APICall/EndPoints";
import initialData from "../../../Helper/data";
import APICall from "../../../APICall";
import { tree } from "./Tree/data";
import Tree from "./Tree";

const defaultMenuOptions = [
  {
    value: 1,
    label: "category_action_find",
    icon: <SearchIcon />,
    tooltip: "category_action_active_find_tooltip",
  },
  {
    value: 2,
    label: "category_action_move",
    icon: <UpgradeIcon />,
    tooltip: "category_action_move_tooltip",
  },
  {
    value: 3,
    label: "category_action_uncategorize",
    icon: <LocalOfferIcon />,
    tooltip: "category_action_uncategorize_tooltip",
  },
];
const TreeView = forwardRef(
  (
    {
      categoryRef,
      value,
      isSaving,
      setIsSaving,
      isOverlay,
      searchText,
      hiddenCategory = [],
      allowParentCategory = false,
      ruleTitle = false,
      onClickCategoryTitle,
      isSelect = false,
      isDataSet = false,
      isGlobal = false,
      hideUncategorize = false,
      preventClose,
      LoaderSx = {},
      sx = {},
    },
    _ref
  ) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isCategorySaving = useRef(false);

    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const SavedCategoryInfo = useRef({});
    const inflowCat = useRef([]);
    const outflowCat = useRef([]);

    //redux
    const use_global_categories = useSelector(
      (state) => state.boardSlice?.dataSetData?.use_global_categories
    );
    const _categories = useSelector((state) => state.categorySlice?.categories);
    const dataSetList = useSelector((state) => state.boardSlice?.dataSetList);
    const dataset = useSelector((state) => state.boardSlice?.dataSetData?.uuid);
    const isAllHeaderApiFetched = useSelector(
      (state) => state.commonSlice.isAllHeaderApiFetched
    );
    const dataSetListById = useMemo(() => {
      if (dataSetList?.length > 0) {
        return _.groupBy(dataSetList, ({ uuid }) => uuid);
      }
    }, [dataSetList]);
    const categories = useMemo(() => {
      return _categories?.filter((o1) =>
        !!isDataSet
          ? dataSetListById?.[isDataSet]?.[0]?.use_global_categories
            ? !o1?.dataset
            : o1?.dataset === isDataSet?.toString()
          : isGlobal || use_global_categories || !dataset
            ? !o1?.dataset
            : o1?.dataset === dataset
      );
    }, [
      _categories,
      isDataSet,
      dataSetListById,
      isGlobal,
      use_global_categories,
      dataset,
    ]);

    //state
    const [openDnd, setOpenDnd] = useState(false);
    const [openMove, setOpenMove] = useState(false);
    const [loading, setLoading] = useState(!isOverlay);
    const [isLoading, setIsLoading] = useState(false);
    const [anchorElMenu, setAnchorElMenu] = useState(null);
    const [menuOptions, setMenuOptions] = useState(defaultMenuOptions);
    const [lastAction, setLastAction] = useState(null);
    const [categoryData, setCategoryData] = useState([]);
    const [isNoteOverlayOpen, setIsNoteOverlayOpen] = useState(false);

    //Api
    const unCategorizeTransactions = async (node) => {
      await APICall(
        "post",
        EndPoints.category + `${node?.uuid}/uncategorize_transactions/`,
        {},
        {
          doNotCatchRespond: true,
        }
      ).then((response) => {
        if (response.status === 200 && response?.data?.success) {
          getCategories();
        }
      });
    };

    const getCategories = async () => {
      // let endUrl = "";
      // if (!use_global_categories) {
      //   endUrl = `?dataset=${dataset}`;
      // } else {
      //   endUrl = `?is_global=true`;
      // }
      await APICall("get", EndPoints.category)
        .then((response) => {
          if (response.status === 200 && response.data) {
            if (categoryRef?.current) {
              categoryRef.current.isCategoryUpdated = false;
            }

            dispatch(
              setCategories({ data: response.data.results, type: "all" })
            );
          }
        })
        .finally(() => {
          setTimeout(() => {
            setLoading(false);
            setIsLoading(false);
          }, 500);
        });
    };

    //functions
    const onMoveNode = (props) => {
      const { treeData, nextParentNode, node } = props;
      if (nextParentNode) {
        if (!node?.has_transactions && nextParentNode?.has_transactions) {
          SavedCategoryInfo.current = {
            nextParentNode,
            node,
            treeData,
            categoryType: value,
          };
          setOpenDnd(true);
          return false;
        } else if (nextParentNode?.has_transactions) {
          enqueueSnackbar(
            t("This parent category has associated transactions."),
            {
              variant: "error",
              autoHideDuration: 5000,
            }
          );

          return false;
        }
      }
      return true;
    };

    const onClickSelectAllCategoryTransactions = (e, node) => {
      e.stopPropagation();
      dispatch(
        updateFilters({
          parent_key: "list",
          obj: { selectedCategory: [node?.uuid] },
        })
      );
      setTimeout(() => {
        navigate(`/${initialData.path.organization}/${dataset}/list/all`);
      }, 0);
    };

    const onClickUncategorize = (e, node) => {
      e.stopPropagation();
      setIsLoading(node?.uuid);
      unCategorizeTransactions(node);
    };

    const onClickMove = (e, node) => {
      SavedCategoryInfo.current = {
        node,
        categoryType: value,
      };
      setOpenMove(true);
    };

    const handleClose = () => {
      setAnchorElMenu(null);
    };

    const onClickMore = (e, item) => {
      e.stopPropagation();
      let disabledValue = [];
      if (!item?.has_transactions) {
        disabledValue = [5, 6];
      } else {
        if (item?.visible) {
          disabledValue.push(3);
        }
      }
      if (use_global_categories) {
        disabledValue.push(4);
      }
      setMenuOptions([
        {
          value: 1,
          label: "Duplicate",
          icon: <ContentCopyIcon />,
          tooltip: "category_action_duplicate_tooltip",
        },

        {
          value: 3,
          label: item?.visible
            ? t("category_action_visible")
            : t("category_action_not_visible"),
          icon: !item?.visible ? <VisibilityIcon /> : <VisibilityOffIcon />,
          tooltip: item?.visible
            ? t("category_action_visible_tooltip")
            : t("category_action_not_visible_tooltip"),
        },
        {
          value: 4,
          label: "category_action_find",
          icon: <SearchIcon />,
          tooltip: disabledValue?.includes(1)
            ? "category_action_inactive_find_tooltip"
            : "category_action_active_find_tooltip",
        },
        {
          value: 5,
          label: "category_action_move",
          icon: <UpgradeIcon />,
          tooltip: disabledValue?.includes(2)
            ? "category_action_inactive_move_tooltip"
            : "category_action_active_move_tooltip",
        },
        {
          value: 6,
          label: "category_action_uncategorize",
          icon: <LocalOfferIcon />,
          tooltip: disabledValue?.includes(3)
            ? "category_action_inactive_uncategorize_tooltip"
            : "category_action_active_uncategorize_tooltip",
        },
        {
          value: 7,
          label: "category_action_add_rule",
          icon: <PiMagicWandFill />,
          tooltip:
            isOverlay && ruleTitle?.length > 0
              ? "category_action_active_add_rule_tooltip"
              : "category_action_inactive_add_rule_tooltip",
          divider: true,
        },
        {
          value: 2,
          label: "Delete",
          icon: <DeleteForeverIcon />,
          tooltip: "category_action_delete_tooltip",
          sx: {
            "& .MuiListItemIcon-root": {
              color: theme.palette.color.red[500],
            },
            "& .MuiTypography-root": {
              color: theme.palette.color.red[500],
            },
          },
        },
      ]);
      SavedCategoryInfo.current = {
        node: item,
        disabledValue,
      };
      setAnchorElMenu(e.currentTarget);
    };

    const onClickItem = (e, value, item) => {
      const { node } = item;
      handleClose();
      if (value === 1) {
        categoryRef.current?.onClickCloneCategory(e, node);
      }
      if (value === 2) {
        categoryRef.current?.onClickDeleteCategory(e, node);
      }
      if (value === 3) {
        categoryRef.current?.onClickVisibleCategory(e, node);
      }
      if (value === 4) {
        onClickSelectAllCategoryTransactions(e, node);
      }
      if (value === 5) {
        onClickMove(e, node);
      }
      if (value === 6) {
        onClickUncategorize(e, node);
      }
      if (value === 7) {
        e.stopPropagation();
        categoryRef.current?.createNewRules({
          transactionsRow: {
            category: item?.uuid,
            transaction_type: item?.type,
            title: ruleTitle,
          },
          preventClose,
        });
      }
    };

    const openNoteOverlay = (obj) => {
      SavedCategoryInfo.current = obj;
      setIsNoteOverlayOpen(obj?.currentTarget);
    };

    const closeNoteOverlay = () => {
      setIsNoteOverlayOpen(false);
    };

    //life cycle method
    useEffect(() => {
      if (isAllHeaderApiFetched) {
        setLoading(true);
        getCategories();
      }
    }, [isOverlay, isAllHeaderApiFetched]);

    useDebounce(
      () => {
        if (isAllHeaderApiFetched) {
          inflowCat.current = categories?.filter((o1) => o1?.type === 1);
          outflowCat.current = categories?.filter((o1) => o1?.type === 2);

          let data = [];
          if (value === 1) {
            data = inflowCat.current;
          }
          if (value === 2) {
            data = outflowCat.current;
          }
          if (searchText?.length > 0) {
            setCategoryData(tree?.searchTree(data, searchText));
          } else {
            setCategoryData(data);
          }
        }
      },
      300,
      [
        isOverlay,
        value,
        categories,
        searchText,
        dataset,
        isAllHeaderApiFetched,
        use_global_categories,
      ],
      true
    );

    useImperativeHandle(
      _ref,
      () => ({
        categoryData,
      }),
      [categoryData]
    );

    return (
      <Box
        sx={{
          width: "100%",
          textAlign: "center",
          backgroundColor: "transparent",
          overflowY: "auto",
          ...theme.thinScrollBar,
          ...sx,
        }}
      >
        <CategoryCommonFunctions
          ref={categoryRef}
          value={value}
          setLoading={setLoading}
          isSaving={isSaving}
          isCategorySaving={isCategorySaving}
          data={categoryData}
          setData={setCategoryData}
          setIsSaving={setIsSaving}
        />

        {!!openDnd ? (
          <CategoryDnDAttentionOverlay
            open={openDnd}
            setOpen={setOpenDnd}
            payload={SavedCategoryInfo?.current}
            title="category_dnd_action_title"
            message="category_dnd_action_description"
          />
        ) : null}
        {!!openMove ? (
          <CategoryMoveOverlay
            open={openMove}
            setOpen={setOpenMove}
            payload={SavedCategoryInfo?.current}
            title="category_move_action_title"
            message="category_move_action_description"
          />
        ) : null}
        {Boolean(anchorElMenu) && !isLoading ? (
          <MenuView
            anchorEl={anchorElMenu}
            open={Boolean(anchorElMenu)}
            options={menuOptions}
            handleClose={handleClose}
            onClickItem={onClickItem}
            item={SavedCategoryInfo?.current}
            disabledValue={SavedCategoryInfo?.current?.["disabledValue"]}
          />
        ) : null}
        <CustomPopover
          type="anchor"
          anchorEl={isNoteOverlayOpen}
          onClose={closeNoteOverlay}
          width={"fit-content"}
          border="none"
          justifyContent="flex-start"
          iconSize={"1.375rem"}
          icon
          iconStyle={{ p: 0 }}
          paperStyle={{
            border: 0,
            cursorCol: "pointer",
            position: "fixed",
            mt: "0.5rem",
          }}
          iconView={<></>}
          hoverBG={"transparent"}
        >
          <Typography
            variant="caption"
            fontWeight={"fontWeightMediumBold"}
            color={"color.description"}
            sx={{
              display: "flex",
              minHeight: "3rem",
              maxWidth: "30rem",
              padding: "1rem",
            }}
          >
            {SavedCategoryInfo.current?.node?.note}
          </Typography>
        </CustomPopover>
        {!loading && categoryData?.length > 0 ? (
          <Grid container spacing={4} sx={{ width: "100%", ml: 0, mt: 0 }}>
            <Tree
              isOverlay={isOverlay}
              hiddenCategory={hiddenCategory}
              allowParentCategory={allowParentCategory}
              ruleTitle={ruleTitle}
              isSelect={isSelect}
              preventClose={preventClose}
              onClickCategoryTitle={onClickCategoryTitle}
              categoryRef={categoryRef}
              hideUncategorize={hideUncategorize}
              data={categoryData}
              setData={setCategoryData}
              lastAction={lastAction}
              setLastAction={setLastAction}
              onMoveNode={onMoveNode}
              isLoading={isLoading}
              isCategorySaving={isCategorySaving}
              SavedCategoryInfo={SavedCategoryInfo}
              onClickMore={onClickMore}
              anchorElMenu={Boolean(anchorElMenu)}
              anchorElNote={Boolean(isNoteOverlayOpen)}
              openNoteOverlay={openNoteOverlay}
              searchText={searchText}
            />
          </Grid>
        ) : (
          <ComponentLoader
            isSkeleton
            loading={loading}
            skeletonCount={6}
            height="100%"
            placeHolderWidth="35%"
            placeHolderHeight={"3.5rem"}
            key1="category_list_no_data_text_01"
            key2="category_list_no_data_text_02"
            placeHolderSx={{
              marginTop: "5rem",
            }}
            skeltonSx={{
              my: 0,
              transform: "scale(1,0.9)",
              backgroundColor: `${theme.palette.color.grey[200]} !important`,
            }}
            {...LoaderSx}
          />
        )}
      </Box>
    );
  }
);

export default TreeView;
