import { Typography, useTheme, Divider, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router";

import RulesListView from "./RulesListView";

const Rules = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        backgroundColor: theme.palette.color.appThemeBg,
        ml: "90px",
        position: "relative",
        display: "inline-flex",
        flexDirection: "column",
      }}
    >
      <Typography
        variant="h5"
        color="color.slate.700"
        fontWeight={"fontWeightMediumBold"}
        sx={{
          mt: "4rem",
        }}
      >
        {t("Rule_plural")}
      </Typography>
      <Typography
        variant="subtitle2"
        color="color.description"
        fontWeight={"fontWeightMedium"}
        sx={{
          my: "0.5rem",
          mb: "1.5rem",
        }}
      >
        {t("rules_list_description")}
      </Typography>
      <Divider
        sx={{
          width: "80%",
          mb: "1rem",
        }}
      />

      <RulesListView />
      <Outlet />
    </Box>
  );
};
export default Rules;
