import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { differenceInMilliseconds } from "date-fns";
import { useTranslation } from "react-i18next";
import Backdrop from "@mui/material/Backdrop";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import * as React from "react";

import { setStageLoadingText } from "../store/slices/appmain";
import TipsWhileLoading from "./TipsWhileLoading";
import CustomModal from "./Model/CustomModal";
import theme from "../theme";

export default function StageLoader() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const start_time = React.useRef(null);
  const end_time = React.useRef(null);
  const oldMessage = React.useRef("");
  const stageText = useSelector((state) => state.appSlice.stageText);
  const [open, setOpen] = React.useState(false);

  const updateOpenStatus = React.useCallback(() => {
    if (Boolean(stageText)) {
      start_time.current = Date.now();
      end_time.current = start_time.current + 4000; //min open 4sec
      oldMessage.current = stageText;
      setOpen(true);
    } else {
      const close_time = differenceInMilliseconds(Date.now(), end_time.current);
      const timer = close_time > 0 ? 0 : Math.abs(close_time);
      setTimeout(() => {
        oldMessage.current = "";
        setOpen(false);
      }, timer);
    }
  }, [stageText]);

  React.useEffect(() => {
    updateOpenStatus();
  }, [stageText, updateOpenStatus]);

  const onClose = () => {
    dispatch(setStageLoadingText(null));
  };

  return (
    <CustomModal
      hideAction
      hideClose
      open={open}
      onClose={
        stageText === "close it by clicking outside overlay"
          ? onClose
          : undefined
      }
      // heading={t("stage_loader_title")}
      icon={<></>}
      slotProps={{
        backdrop: {
          open: open,
          component: Backdrop,
          timeout: 500,
          style: {
            backdropFilter: "blur(6px)",
            WebkitBackdropFilter: "blur(6px)",
          },
        },
      }}
      rootStyle={{
        zIndex: 9999,
      }}
    >
      <Box
        sx={{
          width: "50rem",
          minHeight: "25rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          gap: "1rem",
          py: "3rem",
        }}
      >
        <Alert
          severity="info"
          icon={
            <CircularProgress
              color="inherit"
              size={23}
              sx={{
                color: theme.palette.primary.main,
              }}
            />
          }
          sx={{
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.primary[50],
            display: "flex",
            alignItems: "center",
            fontSize: "1rem",
            width: "80%",
            mx: "auto",
          }}
        >
          {t(stageText || oldMessage.current)}
        </Alert>

        <TipsWhileLoading />
      </Box>
    </CustomModal>
  );
}
