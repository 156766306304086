import { ACTIONS, EVENTS, STATUS } from "react-joyride";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ReactJoyride from "react-joyride";
import { alpha } from "@mui/material";

import { setStepIndex, stopTour } from "../../store/slices/tour";
import { setJoyRidePayload } from "../../store/slices/global";
import { Emitter } from "../../Helper/data";
import { Color } from "../../Helper";

const Tour = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  //redux
  const tourState = useSelector((state) => state.tourSlice);
  //functions
  const middleFunction = (data) => {
    let { name } = tourState;
    const { action, index, type, status } = data;
    if (name === "dataset_header") {
      if (
        type === EVENTS.STEP_AFTER &&
        action === ACTIONS.NEXT &&
        data?.step?.target === ".dataset_header_step_0" &&
        index === 0
      ) {
        dispatch(
          setJoyRidePayload({
            next: {
              key: "dataset_header_step_1",
              payload: {
                index: index + 1,
              },
            },
          })
        );
      }
      if (
        type === EVENTS.STEP_AFTER &&
        action === ACTIONS.PREV &&
        data?.step?.target === ".dataset_header_step_1" &&
        index === 1
      ) {
        dispatch(
          setJoyRidePayload({
            prev: {
              key: "dataset_header_step_0",
            },
          })
        );
      }
    }

    if (name === "reports") {
    }

    if (name === "list") {
      if (
        type === EVENTS.STEP_AFTER &&
        action === ACTIONS.PREV &&
        data?.step?.target === ".list_6_step" &&
        index === 5
      ) {
        Emitter.emit("list_1_step");
      }
      if (
        (type === EVENTS.STEP_AFTER &&
          action === ACTIONS.NEXT &&
          data?.step?.target === ".list_5_step" &&
          index === 4) ||
        (type === EVENTS.STEP_AFTER &&
          action === ACTIONS.PREV &&
          data?.step?.target === ".list_7_step" &&
          index === 6)
      ) {
        Emitter.emit("list_5_step");
      }
      if (
        (type === EVENTS.STEP_AFTER &&
          action === ACTIONS.NEXT &&
          data?.step?.target === ".list_6_step" &&
          index === 5) ||
        (type === EVENTS.STEP_AFTER &&
          action === ACTIONS.PREV &&
          data?.step?.target === ".list_8_step" &&
          index === 7)
      ) {
        Emitter.emit("list_6_step");
      }
      if (
        (type === EVENTS.STEP_AFTER &&
          action === ACTIONS.NEXT &&
          data?.step?.target === ".list_7_step" &&
          index === 6) ||
        (type === EVENTS.STEP_AFTER &&
          action === ACTIONS.PREV &&
          data?.step?.target === ".list_10_step" &&
          index === 9)
      ) {
        Emitter.emit("list_7_step");
      }
      if (
        (type === EVENTS.STEP_AFTER &&
          action === ACTIONS.NEXT &&
          data?.step?.target === ".list_9_step" &&
          index === 8) ||
        (type === EVENTS.STEP_AFTER &&
          action === ACTIONS.PREV &&
          data?.step?.target === ".list_11_step" &&
          index === 10)
      ) {
        Emitter.emit("list_9_step");
      }
      if (
        type === EVENTS.STEP_AFTER &&
        action === ACTIONS.NEXT &&
        data?.step?.target === ".list_10_step" &&
        index === 9
      ) {
        Emitter.emit("list_10_step");
      }
    }

    if (name === "dashboard_overview") {
      if (
        (type === EVENTS.STEP_AFTER &&
          action === ACTIONS.NEXT &&
          data?.step?.target === ".dashboard_overview_1_step" &&
          index === 1) ||
        (type === EVENTS.STEP_AFTER &&
          action === ACTIONS.PREV &&
          data?.step?.target === ".dashboard_overview_3_step" &&
          index === 3)
      ) {
        Emitter.emit("dashboard_overview_1_step", 2);
      }
      if (
        (type === EVENTS.STEP_AFTER &&
          action === ACTIONS.NEXT &&
          data?.step?.target === ".dashboard_overview_2_1_step" &&
          index === 2) ||
        (type === EVENTS.STEP_AFTER &&
          action === ACTIONS.PREV &&
          data?.step?.target === ".dashboard_overview_3_step_after" &&
          index === 4)
      ) {
        Emitter.emit("dashboard_overview_2_1_step", 6);
      }
      if (
        type === EVENTS.STEP_AFTER &&
        action === ACTIONS.NEXT &&
        data?.step?.target === ".dashboard_overview_3_step" &&
        index === 3
      ) {
        Emitter.emit("dashboard_overview_3_step_after", 7);
      }
      if (
        type === EVENTS.STEP_AFTER &&
        action === ACTIONS.PREV &&
        data?.step?.target === ".dashboard_overview_2_1_step" &&
        index === 2
      ) {
        Emitter.emit("dashboard_overview_0_step", 0);
      }
    }

    if (name === "kanban") {
      if (
        ((type === EVENTS.STEP_AFTER || type === "error:target_not_found") &&
          action === ACTIONS.NEXT &&
          data?.step?.target === ".kanban_1_step" &&
          index === 0) ||
        (type === EVENTS.STEP_AFTER &&
          action === ACTIONS.PREV &&
          data?.step?.target === ".kanban_2_step" &&
          index === 2)
      ) {
        Emitter.emit("Event_Open_Transaction_Overlay");
      }
      if (
        type === EVENTS.STEP_AFTER &&
        (action === ACTIONS.NEXT || action === ACTIONS.PREV) &&
        data?.step?.target === ".kanban_3_step_before" &&
        index === 1
      ) {
        Emitter.emit("Event_Close_Transaction_Overlay");
      }
      if (
        type === EVENTS.STEP_AFTER &&
        action === ACTIONS.NEXT &&
        data?.step?.target === ".kanban_2_step_single" &&
        index === 3
      ) {
        Emitter.emit("Event_Open_Transaction_Overlay");
      }
      if (
        type === EVENTS.STEP_AFTER &&
        action === ACTIONS.PREV &&
        data?.step?.target === ".kanban_5_step" &&
        index === 11
      ) {
        Emitter.emit("Event_Close_Grouped_Options");
      }
      if (
        (type === EVENTS.STEP_AFTER &&
          action === ACTIONS.NEXT &&
          data?.step?.target === ".kanban_5_step" &&
          index === 11) ||
        (type === EVENTS.STEP_AFTER &&
          action === ACTIONS.PREV &&
          data?.step?.target === ".kanban_7_step" &&
          index === 15)
      ) {
        Emitter.emit(
          "Event_Open_Grouped_Options",
          action === ACTIONS.PREV ? 14 : 12
        );
      }

      if (
        (type === EVENTS.STEP_AFTER &&
          action === ACTIONS.NEXT &&
          data?.step?.target === ".kanban_7_step" &&
          index === 15) ||
        (type === EVENTS.STEP_AFTER &&
          action === ACTIONS.PREV &&
          data?.step?.target === ".kanban_11_step" &&
          index === 17)
      ) {
        Emitter.emit("Event_Open_Chart_Options");
      }

      if (
        type === EVENTS.STEP_AFTER &&
        action === ACTIONS.NEXT &&
        data?.step?.target === ".kanban_8_step" &&
        index === 16
      ) {
        Emitter.emit("Event_Open_Help_Overlay");
      }
    }
  };

  const handleJoyrideCallback = async (data) => {
    const { action, index, type, status, lifecycle } = data;
    let { name } = tourState;
    if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      middleFunction(data);
      dispatch(setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1)));
    } else if (
      action === ACTIONS.CLOSE ||
      status === STATUS.SKIPPED ||
      status === STATUS.FINISHED
    ) {
      dispatch(stopTour());
    }
  };

  return (
    <ReactJoyride
      {...tourState}
      continuous={true}
      scrollToFirstStep={true}
      showProgress={true}
      showSkipButton={true}
      callback={handleJoyrideCallback}
      styles={{
        tooltipContainer: {
          textAlign: "left",
        },

        buttonBack: {
          marginRight: 10,
        },
        options: {
          primaryColor: alpha(Color.themeColor, 0.8),
          textColor: Color.greyText,
          zIndex: 9999,
        },
      }}
      locale={{
        last: t("Close"),
        back: t("Back"),
        skip: t("Skip"),
        next: t("Next"),
      }}
    />
  );
};
export default Tour;
