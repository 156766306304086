import {
  FormControlLabel,
  DialogActions,
  DialogContent,
  Typography,
  Checkbox,
  Divider,
  Dialog,
  Fade,
  Box,
} from "@mui/material";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { format } from "date-fns";

import TailwindButton from "../../../components/Overlay/TailwindButton";
import { addAccount, setAccounts } from "../../../store/slices/global";
import TitleInput from "../../../components/Overlay/TitleInput";
import AmountView from "../../../components/Overlay/AmountView";
import DateInput from "../../../components/Overlay/DateInput";
import { GlobalContext } from "../../../GlobalContextWrapper";
import DropDown from "../../../components/Overlay/DropDown";
import EndPoints from "../../../APICall/EndPoints";
import { Color, Constant } from "../../../Helper";
import initialData from "../../../Helper/data";
import APICall from "../../../APICall";
import DSImage from "./DSImage";

const ManualAccountDialogView = ({
  open,
  handleClickClose,
  cell,
  clickable,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const globalContext = useContext(GlobalContext);
  const dsRef = globalContext?.dsRef;
  const dSObj = useRef(cell);
  const isDataUpdated = useRef(false);
  const { enqueueSnackbar } = useSnackbar();
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);

  //state
  const [manualAccountItem, setManualAccountItem] = useState({
    currency: "EUR",
  });
  const [types, setTypes] = useState([]);
  const [error, setError] = useState(null);
  const [loader, setLoader] = useState(false);

  let tooltip = "";
  if (dSObj.current?.ds?.internal_dataset) {
    tooltip = t("Internal data source tooltip");
  } else if (dSObj.current?.acc?.bank_details?.title) {
    tooltip = `${dSObj.current?.acc?.bank_details?.title}${
      dSObj.current?.acc?.iban ? ` / ${dSObj.current?.acc?.iban}` : ""
    }`;
  } else {
    tooltip = dSObj.current?.ds?.title || t(dSObj.current?.card?.title);
  }
  const currencies = useMemo(
    () =>
      initialData?.currencies?.map((item) => {
        return {
          uuid: item?.uuid,
          code: item?.code,
          name: t(item?.name),
          symbol: item?.symbol,
        };
      }),
    [t]
  );
  //api
  const getAccountOptions = async () => {
    await APICall("OPTIONS", EndPoints.accounts).then((response) => {
      if (response.status === 200 && response.data) {
        let data = response.data.actions.POST.type.choices;
        setTypes(data);
      }
    });
  };

  const getAccountsApi = async () => {
    await APICall("get", EndPoints.accounts).then((response) => {
      if (response.status === 200 && response.data) {
        let data = response.data.results;
        dispatch(setAccounts(data));
      }
    });
  };

  const createNewDS = async () => {
    await dsRef.current?.checkDemoDsConnections();
    let manualAccountItem = dSObj.current?.manualAccountItem;
    let obj = {
      title: dSObj.current?.manualAccountItem?.title || t("Manual_data_source"),
      note: dSObj.current?.card?.name || "",
      alias: dSObj.current?.alias || "",
      state: 1,
      type: dSObj.current?.card?.type,
      datasets: [],
      transaction_system: null,
      currency: manualAccountItem?.currency,
    };
    await APICall("post", EndPoints.integrations, obj).then((response) => {
      if (response.status === 201 && response.data) {
        dSObj.current = {
          ...dSObj.current,
          data_source: response.data?.uuid,
          ds: response.data,
        };
        addBankApi({
          title: dSObj.current.manualAccountItem?.bank_details?.title,
        });
      }
    });
  };

  const addBankApi = async (obj, isEdit = false) => {
    await APICall("post", EndPoints.banks, obj).then(async (response) => {
      if (response.status === 201 && response.data) {
        if (isEdit) {
          let obj = {
            type: dSObj.current?.manualAccountItem?.type?.value,
            currency: dSObj.current?.manualAccountItem?.currency,
            iban: dSObj.current?.manualAccountItem?.iban,
            limit: dSObj.current?.manualAccountItem?.limit,
            show_limit: dSObj.current?.manualAccountItem?.show_limit,
            name: dSObj.current?.manualAccountItem?.title,
            account_number: dSObj.current?.manualAccountItem?.iban,
            starting_date: dSObj.current?.manualAccountItem?.starting_date,
            bank: response.data?.uuid,
          };

          await updateDSByID(dSObj.current?.ds?.uuid, {
            title: dSObj.current?.manualAccountItem?.title,
            note: dSObj.current?.card?.name,
          });
          await updateAccountByID(
            dSObj.current?.manualAccountItem?.uuid,
            obj,
            String(dSObj.current?.manualAccountItem?.balance || 0)
          );
        } else {
          let ele = {
            ...manualAccountItem,
            type: manualAccountItem?.type?.value,
            bank: response.data?.uuid,
            data_source: dSObj.current?.ds?.uuid,
            account_number: manualAccountItem?.iban,
            starting_date: manualAccountItem?.starting_date,
            limit: manualAccountItem?.limit || null,
            iban: manualAccountItem?.iban,
            show_limit: manualAccountItem?.show_limit,
            name: manualAccountItem?.title ?? t("Manual_Account"),
          };
          let balance = manualAccountItem?.balance || 0;

          if (clickable && dataSetData?.uuid) {
            let obj = {
              active: true,
              dataset: dataSetData?.uuid,
              data_source: dSObj.current?.ds?.uuid,
            };
            addDataSourceDataset(dataSetData?.uuid, obj);
          }
          addAccountApi(ele, balance);
        }
      }
    });
  };

  const addAccountApi = async (obj, balance = null) => {
    await APICall("post", EndPoints.accounts, obj).then((response) => {
      if (response.status === 201 && response.data) {
        enqueueSnackbar(t("New_Account_Added_Successfully"), {
          variant: "success",
          autoHideDuration: 2000,
        });

        if (balance) {
          let data = {
            account: response.data.uuid,
            name: response.data.type,
            currency: response.data.currency,
            amount: balance,
            starting_date: obj?.starting_date ?? null,
          };
          addBalanceToAccount(response.data, data, "new");
          handleClose(true, clickable);
          return;
        }
        // dispatch(addAccount(response.data));
        handleClose(true, clickable);
      }
    });
  };

  const updateAccountByID = async (id, obj, balance) => {
    await APICall("patch", EndPoints.accounts + `${id}/`, obj).then(
      (response) => {
        if (response.status === 200 && response.data) {
          if (response.data.balances?.length === 0) {
            if (balance) {
              let data = {
                account: response.data.uuid,
                name: response.data.type,
                currency: response.data.currency,
                amount: balance ?? 0,
                starting_date: obj?.starting_date ?? null,
              };
              addBalanceToAccount(response.data, data);
            }
          } else {
            updateBalanceToAccount(
              response.data,
              response.data.balances[0]?.uuid,
              {
                name: response.data.type,
                amount: balance?.trim() === "" ? 0 : balance,
                starting_date: obj?.starting_date ?? null,
              }
            );
          }
          setTimeout(() => {
            closePopup(true, clickable);
          }, 0);
        }
      }
    );
  };

  const updateBankByID = async (id, obj) => {
    await APICall("patch", EndPoints.banks + `${id}/`, obj).then((response) => {
      if (response.status === 200 && response.data) {
      }
    });
  };

  const updateDSByID = async (id, obj) => {
    await APICall("patch", EndPoints.integrations + `${id}/`, obj).then(
      (response) => {
        if (response.status === 200 && response.data) {
        }
      }
    );
  };

  const addBalanceToAccount = async (data, obj, type = "old") => {
    await APICall(
      "post",
      EndPoints.accounts + `${data.uuid}/balances/`,
      obj
    ).then((response) => {
      if (response.status === 201 && response.data) {
        if (obj.starting_date) {
          add_starting_date_balance(obj?.account, response.data?.uuid, {
            date: obj.starting_date,
            value: response.data?.amount,
          });
        }
        if (type === "new") {
          data = { ...data, balances: [response.data] };
          dispatch(addAccount(data));
        }
      }
    });
  };

  const add_starting_date_balance = async (accountID, balanceID, obj) => {
    let data = null;
    await APICall(
      "post",
      EndPoints.accounts + `${accountID}/balances/${balanceID}/values/`,
      obj
    ).then((response) => {
      if (response.status === 201 && response.data) {
      }
    });
    return data;
  };

  const updateBalanceToAccount = async (data, balanceId, obj) => {
    await APICall(
      "patch",
      EndPoints.accounts + `${data.uuid}/balances/${balanceId}/`,
      obj
    ).then((response) => {
      if (response.status === 200 && response.data) {
        get_starting_date_balance(data.uuid, balanceId, obj);
      }
    });
  };

  const get_starting_date_balance = async (accountID, balanceID, obj) => {
    await APICall(
      "get",
      EndPoints.accounts + `${accountID}/balances/${balanceID}/values/`
    ).then((response) => {
      if (response.status === 200 && response.data) {
        let data = response.data?.results[0];
        update_starting_date_balance(accountID, balanceID, data?.uuid, {
          value: obj.amount,
          date: obj?.starting_date,
        });
      }
    });
  };

  const update_starting_date_balance = async (
    accountID,
    balanceID,
    valueID,
    obj
  ) => {
    let data = null;
    await APICall(
      "patch",
      EndPoints.accounts +
        `${accountID}/balances/${balanceID}/values/${valueID}/`,
      obj
    ).then((response) => {
      if (response.status === 200 && response.data) {
        getAccountsApi();
      }
    });
    return data;
  };

  const addDataSourceDataset = async (dataset_id, obj, dsId) => {
    await APICall(
      "post",
      EndPoints.datasets + `${dataset_id}/data_sources/`,
      obj
    ).then((response) => {
      if (response.status === 201 && response.data) {
      }
      if (response.status === 400 && response.data) {
        if (response?.data?.data_source?.length > 0) {
          enqueueSnackbar(t("different_ds_not_allowed"), {
            variant: "warning",
            autoHideDuration: 5000,
          });
        }
      }
    });
  };

  //function
  const handleClose = (doUpdate = false, _clickable = false) => {
    if (dSObj.current?.modalType === "Edit" && isDataUpdated.current) {
      handleUpdate();
      setTimeout(() => {
        closePopup(doUpdate, _clickable);
      }, 1000);
    } else {
      closePopup(doUpdate, _clickable);
    }
  };

  const closePopup = (doUpdate, _clickable = false) => {
    // dSObj.current = null;
    isDataUpdated.current = false;
    setError(null);
    handleClickClose(doUpdate, _clickable);
  };

  const onClose = () => {
    closePopup();
  };

  const onClickOutside = () => {
    handleClose(isDataUpdated.current);
  };

  const isValid = () => {
    let errorText = "";
    if (!dSObj.current?.manualAccountItem?.title) {
      errorText = t("title_empty_error");
      setError({ ...error, title: errorText });
      return false;
    }
    if (!dSObj.current?.manualAccountItem?.bank_details?.title) {
      errorText = "please add bank title";
      setError({ ...error, bank_name: errorText });
      return false;
    }
    if (!dSObj.current?.manualAccountItem?.type) {
      errorText = "please select account type";
      setError({ ...error, type: errorText });
      return false;
    }
    if (!dSObj.current?.manualAccountItem?.currency) {
      errorText = "please select one currency";
      setError({ ...error, currency: errorText });
      return false;
    }
    if (!dSObj.current?.manualAccountItem?.starting_date) {
      errorText = "please add starting balance date";
      setError({ ...error, starting_date: errorText });
      return false;
    }

    return true;
  };

  const handleClickAdd = () => {
    if (isValid()) {
      if (dSObj.current?.modalType === "Edit") {
        setLoader(true);
        handleClose(isDataUpdated.current);
      } else {
        setLoader(true);
        createNewDS();
      }
    }
  };

  const handleUpdate = async () => {
    if (isValid()) {
      let balance = String(dSObj.current?.manualAccountItem?.balance || 0);
      if (dSObj.current?.manualAccountItem?.bank) {
        let obj = {
          type: dSObj.current?.manualAccountItem?.type?.value,
          currency: dSObj.current?.manualAccountItem?.currency,
          iban: dSObj.current?.manualAccountItem?.iban,
          limit: dSObj.current?.manualAccountItem?.limit,
          show_limit: dSObj.current?.manualAccountItem?.show_limit,
          name: dSObj.current?.manualAccountItem?.title,
          account_number: dSObj.current?.manualAccountItem?.iban,
          starting_date: dSObj.current?.manualAccountItem?.starting_date,
        };
        await updateBankByID(dSObj.current?.manualAccountItem?.bank, {
          title: dSObj.current?.manualAccountItem?.bank_details?.title,
        });
        await updateDSByID(dSObj.current?.ds?.uuid, {
          title: dSObj.current?.manualAccountItem?.title,
          note: dSObj.current?.card?.name,
        });
        await updateAccountByID(
          dSObj.current?.manualAccountItem?.uuid,
          obj,
          balance
        );
      } else {
        addBankApi(
          {
            title: dSObj.current?.manualAccountItem?.bank_details?.title,
          },
          true
        );
      }
    }
  };

  const updateState = (obj) => {
    dSObj.current = {
      ...dSObj.current,
      manualAccountItem: { ...dSObj.current?.manualAccountItem, ...obj },
    };
    isDataUpdated.current = true;
    setManualAccountItem({ ...manualAccountItem, ...obj });
  };

  const handleEditManualAccount = (e, nameDD = null, valueDD = null) => {
    const { name, value } = e.target;
    setError(null);
    let obj = {};
    if (nameDD) {
      if (nameDD === "currency") {
        valueDD = valueDD.code;
      }
      obj = { [nameDD]: valueDD };
      updateState(obj);
      return;
    }
    if (name === "starting_date") {
      if (value !== "Invalid Date") {
        let starting_date = value
          ? format(new Date(value), "yyyy-MM-dd")
          : null;
        obj = { [name]: starting_date };
        updateState(obj);
      }
    } else if (name === "bank_name") {
      obj = {
        bank_details: {
          ...dSObj.current?.manualAccountItem?.bank_details,
          title: value,
        },
      };
      updateState(obj);
    } else {
      obj = { [name]: value };
      updateState(obj);
    }
  };

  const handleEditManualAccountBalance = (originalValue) => {
    updateState({
      balance: originalValue,
    });
  };

  const handleEditManualAccountLimit = (originalValue) => {
    updateState({
      limit: originalValue,
    });
  };

  const handleChangeCheckBox = (event) => {
    setManualAccountItem({
      ...manualAccountItem,
      show_limit: event.target.checked,
    });
    dSObj.current = {
      ...dSObj.current,
      manualAccountItem: {
        ...dSObj.current?.manualAccountItem,
        show_limit: event.target.checked,
      },
    };
    isDataUpdated.current = true;
  };

  //life cycle method
  useEffect(() => {
    getAccountOptions();
  }, []);

  useEffect(() => {
    const card = Constant.integrations.IntegrationsByName?.["ManualDS"]?.[0];
    if (cell?.modalType === "Edit") {
      let type = types?.find(
        (o1) => o1.value === cell?.manualAccountItem?.type
      );
      let currency = currencies.find(
        (o1) => o1.code === cell?.manualAccountItem?.currency
      );
      let obj = {
        title: cell?.manualAccountItem?.name,
        type: type,
        currency: currency?.code ?? null,
        balance:
          cell?.manualAccountItem?.balances?.length > 0
            ? cell?.manualAccountItem?.balances[0].amount
            : 0,
        starting_date:
          cell?.manualAccountItem?.balances?.length > 0
            ? cell?.manualAccountItem?.balances[0]?.starting_date
            : null,
      };
      setManualAccountItem({
        ...cell?.manualAccountItem,
        ...obj,
      });
      dSObj.current = {
        ...dSObj.current,
        card,
        manualAccountItem: {
          ...cell?.manualAccountItem,
          ...obj,
        },
      };
    } else {
      dSObj.current = {
        ...dSObj.current,
        card,
        manualAccountItem: {
          ...dSObj.current?.manualAccountItem,
          currency: clickable ? dataSetData?.currency : null,
        },
      };

      setManualAccountItem(dSObj.current.manualAccountItem);
    }
  }, [cell, clickable, currencies, dataSetData?.currency, types]);

  return (
    <Dialog
      open={!!open}
      onClose={onClickOutside}
      TransitionComponent={Fade}
      PaperProps={{
        style: {
          minWidth: "50rem",
          maxWidth: "none",
          width: "auto",
        },
      }}
      sx={{
        zIndex: 1303,
        "& button": {
          textTransform: "capitalize",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          height: "4rem",
          // backgroundColor: Color.HeaderBG,
        }}
      >
        <DSImage
          ds_uuid={dSObj.current?.ds?.uuid}
          integration_title={"ManualDS"}
        />

        <Typography
          sx={{
            fontSize: "1rem",
            fontWeight: 500,
            ml: "1rem",
            color: Color.tailwind.purple[950],
          }}
        >
          {tooltip}
        </Typography>
        {isDataUpdated.current && dSObj.current?.modalType === "Edit" ? (
          <SaveText />
        ) : null}
      </Box>

      <DialogContent sx={{ backgroundColor: Color.BodyBG }}>
        <Box
          sx={{
            width: "75rem",
            flexDirection: "column",
            display: "flex",
          }}
        >
          <Box
            sx={{
              display: "inline-flex",
              justifyContent: "space-between",
              width: "100%",
              mt: "1rem",
            }}
          >
            <Box sx={{ width: "46%" }}>
              <TitleInput
                label={t("Title")}
                name="title"
                value={manualAccountItem?.title || ""}
                onChange={handleEditManualAccount}
                helperText={error?.title}
                error={Boolean(error?.title)}
                hideTitle
                likeGoogle
                variant="filled"
              />
            </Box>
            <Box sx={{ width: "46%" }}>
              <TitleInput
                label={t("Bank_Name")}
                name="bank_name"
                value={manualAccountItem?.bank_details?.title || ""}
                onChange={handleEditManualAccount}
                helperText={error?.bank_name}
                error={Boolean(error?.bank_name)}
                hideTitle
                likeGoogle
                variant="filled"
              />
            </Box>
          </Box>

          <Box
            sx={{
              display: "inline-flex",
              justifyContent: "space-between",
              width: "100%",
              mt: "1rem",
            }}
          >
            <Box sx={{ width: "46%" }}>
              <TitleInput
                name="iban"
                label={t("IBAN")}
                value={manualAccountItem?.iban || ""}
                onChange={handleEditManualAccount}
                helperText={error?.iban}
                error={Boolean(error?.iban)}
                hideTitle
                likeGoogle
                variant="filled"
              />
            </Box>
            <Box sx={{ width: "46%" }}>
              <DropDown
                label={t("Type")}
                hideTitle
                likeGoogle
                variant="filled"
                star
                value={manualAccountItem?.type || null}
                onChange={(e, value) =>
                  handleEditManualAccount(e, "type", value)
                }
                options={types}
                getOptionLabel={(option) => option?.display_name ?? ""}
                renderOption={(option) => option?.display_name ?? ""}
                helperText={error?.type}
                error={Boolean(error?.type)}
                mb={0}
              />
            </Box>
          </Box>

          <Divider variant="middle" sx={{ my: "2rem" }} />

          <Box
            sx={{
              display: "inline-flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Box sx={{ width: "22%" }}>
              <DropDown
                mb={0}
                disableClearable={true}
                label={t("Currency")}
                hideTitle
                likeGoogle
                variant="filled"
                star
                helperText={error?.currency}
                error={Boolean(error?.currency)}
                disabled={Boolean(
                  (manualAccountItem?.balance
                    ? manualAccountItem?.balance !== 0
                    : false) ||
                    (manualAccountItem?.limit
                      ? manualAccountItem?.limit !== 0
                      : false)
                )}
                value={
                  currencies.find(
                    (o1) => o1.code === manualAccountItem?.currency
                  ) ?? null
                }
                onChange={(e, value) =>
                  handleEditManualAccount(e, "currency", value)
                }
                options={currencies}
                getOptionLabel={(option) =>
                  option.symbol + " " + option.name ?? ""
                }
                renderOption={(option) =>
                  option.symbol + " " + option.name ?? ""
                }
              />
            </Box>
            <Box sx={{ width: "22%" }}>
              <AmountView
                hideTitle
                likeGoogle
                disabled={!manualAccountItem?.currency}
                defaultValue={manualAccountItem?.balance || 0}
                onBlur={handleEditManualAccountBalance}
                label={t("balance")}
                name="balance"
                variant="filled"
                currency={manualAccountItem?.currency}
              />
            </Box>
            <Box sx={{ width: "22%" }}>
              <DateInput
                label={t("Starting_balance_date")}
                name="starting_date"
                value={manualAccountItem?.starting_date || ""}
                onChange={handleEditManualAccount}
                helperText={error?.starting_date}
                error={Boolean(error?.starting_date)}
                hideTitle
                likeGoogle
                variant="filled"
                tooltip=""
              />
            </Box>
            <Box sx={{ width: "22%" }}>
              <AmountView
                hideTitle
                likeGoogle
                disabled={!manualAccountItem?.currency}
                defaultValue={manualAccountItem?.limit}
                onBlur={handleEditManualAccountLimit}
                label={t("Limit")}
                name="limit"
                variant="filled"
                currency={manualAccountItem?.currency}
              />
            </Box>
          </Box>
          <FormControlLabel
            disabled={!Boolean(manualAccountItem?.limit)}
            control={
              <Checkbox
                checked={Boolean(manualAccountItem?.show_limit)}
                onChange={handleChangeCheckBox}
                color="primary"
              />
            }
            label="Show Limit"
            sx={{
              width: "fit-content",
              ml: "0.1rem",
              "& .MuiTypography-root": {
                color: "color.description",
                fontSize: "0.9rem",
                fontWeight: 500,
              },
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ backgroundColor: Color.FooterBG, p: "1.5rem" }}>
        <TailwindButton type="cancel" text={t("Cancel")} onClick={onClose} />
        <TailwindButton
          text={t(dSObj.current?.modalType === "Edit" ? "Save" : "Add")}
          onClick={loader ? undefined : handleClickAdd}
          loading={loader}
          disable={
            dSObj.current?.modalType === "Edit" && !isDataUpdated.current
          }
        />
      </DialogActions>
    </Dialog>
  );
};

export default ManualAccountDialogView;
const SaveText = () => {
  const { t } = useTranslation();
  return (
    <Typography
      variant="subtitle1"
      component={"span"}
      fontWeight={"fontWeightBold"}
      sx={{
        ml: "1rem",
        display: "inline-flex",
        alignItems: "center",
        color: Color.theme.green[500],
      }}
    >
      {t("Saved")}
    </Typography>
  );
};
