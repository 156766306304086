import { Alert, Box, Divider, Typography, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import React, { useRef } from "react";
import { Outlet } from "react-router";

import TitleInput from "../../../components/Overlay/TitleInput";
import { setProfile } from "../../../store/slices/settings";
import EndPoints from "../../../APICall/EndPoints";
import initialData from "../../../Helper/data";
import { Color } from "../../../Helper";
import APICall from "../../../APICall";

const General = () => {
  const theme = useTheme();
  const uploadLogo = useRef(null);
  const isDataUpdated = useRef(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  //redux state
  const profile = useSelector((state) => state.settingsSlice.profile);

  //state
  // const [option, setOption] = useState([]);

  //lifeCycle
  // useEffect(() => {
  //   getOptions();
  // }, []);

  //api
  // const getOptions = async () => {
  //   await APICall("OPTIONS", EndPoints.profile).then((response) => {
  //     if (response.status === 200 && response.data) {
  //       let data = response.data.actions.PUT.market?.choices;
  //       setOption(data);
  //     }
  //   });
  // };

  const euro_countries = initialData.euro_countries;

  const updateProfile = async (obj) => {
    if (isDataUpdated.current) {
      await APICall("patch", EndPoints.profile, obj).then((response) => {
        if (response.status === 200 && response.data) {
          isDataUpdated.current = false;
        }
      });
    }
  };

  //function
  const handleChange = (e) => {
    let { name, value } = e.target;
    isDataUpdated.current = true;
    dispatch(setProfile({ ...profile, [name]: value }));
  };

  const onBlurChange = (e) => {
    let { name, value } = e.target;
    updateProfile({ [name]: value });
  };

  const handleChangeDropDown = (e, value, name) => {
    isDataUpdated.current = true;
    dispatch(setProfile({ ...profile, [name]: value.value }));
    updateProfile({ [name]: value?.value || null });
  };

  const onClickLogo = () => {
    uploadLogo.current.click();
  };

  const onUploadLogo = async (e) => {
    isDataUpdated.current = true;
    const { files } = e.target;
    let obj = {
      ...profile,
      company_logo: files?.[0] ? URL.createObjectURL(files?.[0]) : null,
    };
    let data = new FormData(); // creates a new FormData object
    data.append("company_logo", files[0]); // add your file to form data
    dispatch(setProfile(obj));
    updateProfile(data);
  };

  if (!profile?.is_owner) {
    return (
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Alert severity="info" sx={{ mt: "8rem", width: "fit-content" }}>
          {t("user_permission_area_restricted_info")}
        </Alert>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItem: "center",
        width: "100%",
        height: "100%",
        backgroundColor: Color.appThemeBg,
        position: "relative",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          ml: "92px",
          backgroundColor: Color.appThemeBg,
        }}
      >
        <Typography
          variant="h5"
          color="color.slate.700"
          fontWeight={"fontWeightMediumBold"}
          sx={{
            mt: "4rem",
          }}
        >
          {t("General")}
        </Typography>
        <Typography
          variant="subtitle2"
          color="color.description"
          fontWeight={"fontWeightMedium"}
          sx={{
            mt: "0.5rem",
            textTransform: "capitalize",
          }}
        >
          {t("Manage_Your_Company_Settings")}
        </Typography>
        <Divider sx={{ my: "1.5rem" }} />

        <Typography
          sx={{
            fontSize: "1rem",
            fontWeight: 800,
            color: Color.black,
            textTransform: "capitalize",
          }}
        >
          {t("Logo")}
        </Typography>
        <input
          ref={uploadLogo}
          type="file"
          accept="image/*"
          name="upload-media"
          style={{ display: "none" }}
          id="input"
          onChange={onUploadLogo}
        />
        <Box
          onClick={onClickLogo}
          sx={{
            width: "8rem",
            height: "8rem",
            borderRadius: "12px",
            cursor: "pointer",
            mt: "0.5rem",
            backgroundColor: theme.palette.primary[200],
            "&:hover": {
              backgroundColor: theme.palette.primary[300],
            },
          }}
        >
          {profile?.company_logo ? (
            <img
              src={profile?.company_logo}
              alt="logo"
              width={"8rem"}
              height={"8rem"}
              style={{
                borderRadius: 12,
                width: "100%",
                height: "100%",
              }}
            />
          ) : null}
        </Box>
        <Typography
          sx={{
            fontSize: "0.9rem",
            fontWeight: 400,
            color: "color.description",
            mt: "0.5rem",
          }}
        >
          {t("add_company_log")}
        </Typography>
        <Divider sx={{ my: "1.5rem" }} />
        <TitleInput
          value={profile?.company_name ?? ""}
          onChange={handleChange}
          onBlur={onBlurChange}
          label={t("Company_Name")}
          name="company_name"
          hideTitle
          likeGoogle
          variant="filled"
          sx={{ width: "29rem" }}
        />

        {/* <Divider sx={{ width: 500, my: 3 }} /> */}
        {/* <Typography
          sx={{
            fontSize: "1rem",
            fontWeight: 800,
            margin: "4px 32px",
            fontFamily: Fonts.Text,
            color: Color.black,
          }}
        >
          Currency
        </Typography> */}

        {/* <TextField
          id="outlined-select-currency"
          select
          size="small"
          value={settings?.currencyFormate?.prefix}
          onChange={handleChange}
          sx={{
            width: 400,
            fontSize: "1.1rem",
            fontWeight: 800,
            margin: "0px 32px",
            fontFamily: Fonts.Text,
            color: Color.black,
            backgroundColor: Color.white,
          }}
          helperText=""
        >
          {initialData.currencies?.map((option) => (
            <MenuItem key={option.code} value={option.symbol}>
              {option.symbol + " " + option.name}
            </MenuItem>
          ))}
        </TextField> */}

        {/* <Divider sx={{ my: "1.5rem" }} />
        <DropDown
          hideTitle
          likeGoogle
          variant="filled"
          disablePortal
          fontSize="0.8rem"
          value={
            euro_countries?.find((o1) => o1.market === profile?.market) || ""
          }
          onChange={(e, value) =>
            handleChangeDropDown(
              e,
              { ...value, value: value?.market },
              "market"
            )
          }
          renderOption={(option) => `${option?.language}` ?? ""}
          getOptionLabel={(option) => option?.language ?? ""}
          options={euro_countries}
          label={t("Market")}
          sx={{ width: "29rem" }}
        /> */}
        <Outlet />
      </Box>
    </Box>
  );
};

export default General;
