import { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";

import { Images } from "../../../Helper";
import RatingView from "../RatingView";

const LeftView = () => {
  //state
  const [bgImage, setBgImage] = useState(null);

  useEffect(() => {
    // const pictureArray = [
    //   Images.bgImage1,
    //   Images.bgImage2,
    //   Images.bgImage3,
    //   Images.bgImage4,
    // ];
    // const randomIndex = Math.floor(Math.random() * pictureArray.length);
    // const selectedPicture = pictureArray[randomIndex];
    setBgImage(Images.AuthPageImage);
  }, []);

  return (
    <Grid
      item
      xs={false}
      sm={false}
      md={6.5}
      sx={{
        backgroundColor: (t) => t.palette.color.slate[200],
        display: { xs: "none", md: "flex" },
        justifyContent: "end",
        alignItems: "center",
        position: "relative",
      }}
    >
      <Box
        sx={{
          width: "92%",
          height: "50%",
          position: "relative",
          border: (theme) => `1px solid ${theme.palette.color.slate[200]}`,
          boxShadow: `rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px`,
          overflow: "hidden",
          "&::before": {
            content: '""',
            display: "block",
            paddingTop: "56.25%", // 16:9 aspect ratio (100% / (16/9) = 56.25%)
          },
        }}
      >
        <img
          src={bgImage}
          alt="bgImage"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectPosition: "left top",
            objectFit: "cover",
          }}
        />
      </Box>
      <RatingView />
    </Grid>
  );
};

export default LeftView;
