import { useIsFetching } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Button } from "@mui/material";
import { memo } from "react";

import { getFinApiQueryKey, getTailwindColor } from "../../../Helper/data";
import useDebounce from "../../../hooks/3-useDebounce/useDebounce";
import { queryClient } from "../../../App";

const DsCountView = memo(({ setValue, value, setTab, tab, dsType }) => {
  const { t } = useTranslation();
  const accounts = useSelector((state) => state.globalSlice.accounts);
  const dataSource = useSelector((state) => state.globalSlice.dataSource);
  const dataSourceById = useSelector(
    (state) => state.globalSlice.dataSourceById
  );
  const isFetchingIntegrations = useIsFetching({ queryKey: ["integrations"] });

  const updateCount = () => {
    let connected = [];
    let disconnected = [];
    let archived = [];
    if (dsType?.includes(19)) {
      dataSource?.forEach((ds) => {
        if (!ds?.internal_dataset && ds?.uuid && dsType.includes(ds?.type)) {
          if (ds?.state === 2) {
            archived.push(ds?.uuid);
            return;
          }
          if (ds?.state === 3) {
            disconnected.push(ds?.uuid);
            return;
          }
          const FinApiQueryKey = getFinApiQueryKey({ data_source: ds?.uuid });

          const queryData = queryClient.getQueryData(
            ds?.type === 12
              ? FinApiQueryKey
              : [
                  "integrations",
                  {
                    data_source: ds?.uuid,
                    apiType: "data_source_external_connections",
                  },
                ]
          );
          if (
            queryData === "COMPLETED" ||
            queryData === "UPDATED" ||
            queryData === "AUTHENTICATING"
          ) {
            connected.push(ds?.uuid);
          } else {
            disconnected.push(ds?.uuid);
          }
        }
      });
    } else {
      accounts?.forEach((o1) => {
        const ds = dataSourceById?.[o1?.data_source]?.[0];
        if (
          o1?.name !== "DEMO" &&
          !ds?.internal_dataset &&
          ds?.uuid &&
          dsType.includes(ds?.type)
        ) {
          if (dataSourceById?.[o1?.data_source]?.[0]?.state === 2) {
            archived.push(o1.data_source);
            return;
          }
          if (dataSourceById?.[o1?.data_source]?.[0]?.state === 3) {
            disconnected.push(o1.data_source);
            return;
          }
          const FinApiQueryKey = getFinApiQueryKey({ data_source: ds?.uuid });

          const queryData = queryClient.getQueryData(
            ds?.type === 12
              ? FinApiQueryKey
              : [
                  "integrations",
                  {
                    data_source: ds?.uuid,
                    apiType: "data_source_external_connections",
                  },
                ]
          );
          if (
            queryData === "COMPLETED" ||
            queryData === "UPDATED" ||
            queryData === "AUTHENTICATING"
          ) {
            connected.push(o1.data_source);
          } else {
            disconnected.push(o1.data_source);
          }
        }
      });
    }

    setValue({ connected, disconnected, archived });
  };

  const onClick = (tabValue) => {
    setTab(tabValue);
  };

  useDebounce(
    () => {
      if (dataSourceById && accounts && isFetchingIntegrations === 0) {
        updateCount();
      }
    },
    300,
    [accounts, dataSourceById, isFetchingIntegrations],
    true
  );

  return (
    <div
      style={{
        display: "inline-flex",
        alignItems: "center",
        gap: "1rem",
        marginRight: "1rem",
      }}
    >
      <Button
        onClick={() => onClick("all")}
        style={{
          fontSize: "0.8rem",
          fontWeight: "500",
          color: getTailwindColor("slate", tab === "all" ? 800 : 500),
          backgroundColor:
            tab === "all" ? getTailwindColor("slate", 200) : "unset",
        }}
      >
        {t("All")}
      </Button>

      <Button
        onClick={() => onClick("connected")}
        style={{
          fontSize: "0.8rem",
          fontWeight: "500",
          color: getTailwindColor("slate", tab === "connected" ? 800 : 500),
          minWidth: "4rem",
          backgroundColor:
            tab === "connected" ? getTailwindColor("slate", 200) : "unset",
        }}
      >
        {t("connection_state_connected")} ({value?.connected?.length || 0})
      </Button>

      <Button
        onClick={() => onClick("disconnected")}
        style={{
          fontSize: "0.8rem",
          fontWeight: "500",
          color: getTailwindColor("slate", tab === "disconnected" ? 800 : 500),
          backgroundColor:
            tab === "disconnected" ? getTailwindColor("slate", 200) : "unset",
        }}
      >
        {t("connection_state_disconnected")} ({value?.disconnected?.length || 0}
        )
      </Button>
    </div>
  );
});

export default DsCountView;
