import { useTranslation } from "react-i18next";
import { Chip, Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import React, { useMemo } from "react";

import { getTailwindColor, truncate } from "../Helper/data";
import { Fonts } from "../Helper";

const CategoryChip = ({
  disabled,
  onClick,
  color = "slate",
  label,
  height = "1.7rem",
  width = "fit-content",
  fontSize = "0.7rem",
  backgroundShade = 900, //50
  borderShade = 600, //700
  colorShade = 600, //700
  fontWeight = 500,
  borderRadius = 0,
  borderType = "solid",
  borderWidth = "3px",
  style,
  labelStyle,
  tooltipPlacement = "top",
  showTooltip = false,
  categoryId,
  blackTitle = false,
  showDelete = false,
  onDelete,
  noCategoryLabel = "Set Category",
  count,
  detailed = false,
  hideBG = false,
  isBankCategory,
  isSuggestion,
}) => {
  const { t } = useTranslation();
  const selectionCategoriesByID = useSelector(
    (state) => state.categorySlice.selectionCategoriesByID
  );
  const selectionBankCategoriesByID = useSelector(
    (state) => state.categorySlice.selectionBankCategoriesByID
  );

  const _selectionsCategoryByID = isBankCategory
    ? selectionBankCategoriesByID
    : selectionCategoriesByID;

  const categoryLabel = useMemo(() => {
    let catLabel = "";
    if (detailed) {
      let cardCategoryParent = null;
      let rootCat = null;
      let cardCategory = _selectionsCategoryByID[categoryId]
        ? _selectionsCategoryByID[categoryId][0]
        : null;

      if (cardCategory?.parent) {
        cardCategoryParent = _selectionsCategoryByID[cardCategory?.parent]
          ? _selectionsCategoryByID[cardCategory?.parent][0]
          : null;
      }
      if (cardCategoryParent?.parent) {
        rootCat = _selectionsCategoryByID[cardCategoryParent?.parent]
          ? _selectionsCategoryByID[cardCategoryParent?.parent][0]
          : null;
      }

      catLabel = categoryId
        ? truncate(cardCategory?.label)
        : truncate("Uncategorized");
      if (cardCategoryParent) {
        catLabel =
          truncate(cardCategoryParent?.label, 12) +
          "/" +
          truncate(cardCategory?.label, 12);
      }
      if (rootCat) {
        catLabel =
          "../" +
          truncate(cardCategoryParent?.label, 12) +
          "/" +
          truncate(cardCategory?.label, 12);
      }
    }

    return catLabel;
  }, [categoryId, _selectionsCategoryByID, detailed]);

  const category = _selectionsCategoryByID?.[categoryId]?.[0];

  label = categoryId
    ? detailed
      ? categoryLabel
      : category?.immutable
        ? t(category?.label)
        : category?.label
    : t(noCategoryLabel);
  color = categoryId ? category?.color || "slate" : color;
  return (
    <Tooltip
      arrow
      title={
        showTooltip ? (categoryId ? category?.label : t(noCategoryLabel)) : ""
      }
      placement={tooltipPlacement}
      followCursor
    >
      <Chip
        disabled={disabled}
        onClick={onClick}
        onDelete={showDelete ? onDelete : undefined}
        label={count ? truncate(label, count) : label}
        sx={{
          height: height,
          width: width,
          borderRadius: borderRadius,
          border: isSuggestion
            ? `3px solid ${getTailwindColor("slate", 400)}`
            : categoryId
              ? 0
              : `${borderWidth} ${borderType} ${getTailwindColor(
                  categoryId ? color : "pink",
                  borderShade
                )}`,
          backgroundColor: hideBG
            ? "transparent"
            : getTailwindColor(categoryId ? color : "#fff", backgroundShade),
          "&:hover": {
            backgroundColor: hideBG
              ? "transparent"
              : getTailwindColor(
                  categoryId ? color : "#fff",
                  backgroundShade === 50
                    ? backgroundShade + 150
                    : backgroundShade + 100
                ),
          },
          "& span": {
            fontFamily: Fonts.Text,
            fontSize: fontSize,
            lineHeight: fontSize,
            fontWeight: fontWeight,
            color:
              blackTitle && categoryId
                ? `${getTailwindColor("slate", 600)} !important`
                : `${
                    categoryId
                      ? hideBG
                        ? getTailwindColor(
                            categoryId ? color : "pink",
                            colorShade
                          )
                        : "white"
                      : getTailwindColor("pink", colorShade)
                  } !important`,
            ...labelStyle,
          },
          "& .MuiChip-deleteIcon": {
            fontSize: "1rem",
            position: "absolute",
            right: "0",
          },
          ...style,
        }}
      />
    </Tooltip>
  );
};
export default React.memo(CategoryChip);
