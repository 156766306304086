const { useTheme, Tooltip } = require("@mui/material");

const CountView = ({ count, color, bg, style, tooltip }) => {
  const theme = useTheme();
  return (
    <Tooltip title={tooltip} arrow placement="top">
      <div
        style={{
          color,
          minWidth: "1.7rem",
          backgroundColor: bg,
          borderRadius: theme.borderRadius.main,
          fontSize: "0.75rem",
          fontWeight: theme.typography.fontWeightBold,
          fontFamily: theme.typography.fontFamily,
          padding: "0.35rem",
          marginLeft: "0.75rem",
          ...style,
        }}
      >
        {count}
      </div>
    </Tooltip>
  );
};

export default CountView;
