import { useTranslation } from "react-i18next";
import { Chip, Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import React from "react";

import { getTailwindColor } from "../Helper/data";
import { Fonts } from "../Helper";

const ScenarioChip = ({
  title = null,
  hideBorder = false,
  width = "8rem",
  height = "2rem",
  fontSize = "0.7rem",
  borderRadius = 1,
  isSelected = false,
  onClick,
  showClickCursor = false,
  disabled = false,
  shade = 50, //200
  sx,
}) => {
  const { t } = useTranslation();
  const scenarioByTitle = useSelector(
    (state) => state.globalSlice.scenarioByTitle
  );

  const item = scenarioByTitle?.[title]?.[0];

  const cursor = !!onClick || showClickCursor;
  const label = item?.title === "Base" ? t(item?.title) : item?.title;

  const onClickChip = () => {
    onClick && onClick(item);
  };

  return (
    <Tooltip key={item?.uuid} title={label} arrow placement="top">
      <span>
        <Chip
          onClick={onClickChip}
          size="small"
          label={label}
          disabled={disabled}
          sx={{
            width: width,
            height: height,
            fontWeight: 500,
            fontFamily: Fonts.Text,
            borderRadius: borderRadius,
            display: "inline-flex",
            alignItems: "center",
            color: getTailwindColor(item?.color, 700),
            backgroundColor: getTailwindColor(
              item?.color,
              isSelected ? (shade === 50 ? shade + 250 : shade + 200) : shade
            ),
            cursor: cursor ? "pointer" : "auto",
            border: !hideBorder
                ? `3px solid ${
                    isSelected
                      ? getTailwindColor(item?.color, 600)
                      : "transparent"
                  }`
                : "none",
            "& .MuiChip-label": {
              fontSize: fontSize,
              textAlign: "center",
              lineHeight: "normal",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            },
            "&.Mui-disabled": {
              opacity: 0.5,
              cursor: "disable",
            },
            "&:hover": {
              border: !hideBorder
                ? `3px "solid" ${getTailwindColor(item?.color, 600)}`
                : "none",
              backgroundColor: getTailwindColor(
                 item?.color,
                isSelected?shade === 50 ? shade + 250 : shade + 200:shade
              ),
            },
            ...sx,
          }}
        />
      </span>
    </Tooltip>
  );
};
export default React.memo(ScenarioChip);
