import { Box, Card, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Color, Fonts } from "../../../Helper";
import APICall from "../../../APICall";
import EndPoints from "../../../APICall/EndPoints";
import { useDispatch } from "react-redux";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { setProfile } from "../../../store/slices/settings";
import { updateLanguage } from "../../../Helper/data";
const SubscriptionPaymentSuccessful = () => {
  const dispatch = useDispatch();

  //state
  const [planName, setPlanName] = useState(null);

  //lifeCycleMethod
  useEffect(() => {
    getProfile();
  }, []);

  //api
  const getProfile = async () => {
    await APICall("get", EndPoints.profile).then((response) => {
      if (response.status === 200 && response.data) {
        let data = response.data;
        setPlanName(data?.subscription?.plan?.name ?? null);
        updateLanguage(data?.locale);
        dispatch(setProfile(data));
      }
    });
  };

  return (
    <>
    
      <Box
        sx={{
          display: "inline-flex",
          width: "100%",
          height: "100%",
          backgroundColor: Color.appThemeBg,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {planName && (
          <Card
            sx={{
              width: "20rem",
              height: "20rem",
              borderRadius: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-39%, -39%)",
            }}
          >
            <CheckCircleIcon
              sx={{
                fontSize: "5rem",
                color: Color.theme.green[400],
                position: "absolute",
                top: "25%",
              }}
            />

            {planName !== "Trial" && (
              <Typography
                component={"h3"}
                sx={{
                  position: "absolute",
                  bottom: "23%",
                  fontWeight: 600,
                  fontFamily: Fonts.Text,
                  fontSize: "1.2rem",
                }}
              >
                Payment Successfull!
              </Typography>
            )}
            <Typography
              component={"h2"}
              sx={{
                position: "absolute",
                bottom: "15%",
                fontFamily: Fonts.Text,
                color: Color?.theme.grey[600],
              }}
            >
              {" "}
              Enjoy your {planName} Plan!
            </Typography>
          </Card>
        )}
      </Box>
    </>
  );
};

export default SubscriptionPaymentSuccessful;
