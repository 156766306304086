import {
  useImperativeHandle,
  forwardRef,
  useContext,
  useEffect,
  useState,
  useMemo,
  useRef,
} from "react";
import { endOfQuarter, endOfMonth, endOfWeek, format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { useIsFetching } from "@tanstack/react-query";
import { Box, Stack, alpha } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { PropTypes } from "prop-types";
import { produce } from "immer";

import {
  calculatePercentageChange,
  getTailwindColor,
} from "../../../../../Helper/data";
import useDebounce from "../../../../../hooks/3-useDebounce/useDebounce";
import { setPopupStatus2 } from "../../../../../store/slices/datasets";
import RenderIfVisible from "../../../../../hooks/HOC/RenderIfVisible";
import useSubscriptions from "../../../../../hooks/useSubscriptions";
import { GlobalContext } from "../../../../../GlobalContextWrapper";
import { collectUUIDs } from "../../../../../Helper/functions";
import { Color } from "../../../../../Helper";
import theme from "../../../../../theme";
import Cell from "./Cell";

const RowWrapper = styled(Box)`
  display: flex;
  width: fit-content;
  &:hover {
    & ${(props) => `#${props?.$rowId}-row`} {
      z-index: 7;
      background-color: ${alpha(theme.palette.primary.main, 0.05)};
      background: ${alpha(theme.palette.primary.main, 0.05)};
    }
  }
`;

const Table = ({
  rows = [],
  columns = [],
  borderRadius = 0,
  name = "inflow",
  style,
  onCellChanged,
  position,
  selectedCell,
  setSelectedCell,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isSubscriptionValid] = useSubscriptions();
  const globalContext = useContext(GlobalContext);
  const seqFunctionRef = globalContext?.seqFunctionRef;
  const commonFunctionRef = useRef(null);
  const keys = useRef({
    row_keys: [],
    column_keys: [],
  });

  //redux
  const selectionCategoriesByID = useSelector(
    (state) => state.categorySlice.selectionCategoriesByID
  );
  const highlightedScenarios = useSelector(
    (state) => state.boardSlice?.highlightedScenarios
  );
  const scenarioById = useSelector((state) => state.globalSlice.scenarioById);

  //state
  const [data, setData] = useState({});
  const [expanded, setExpanded] = useState([name]);
  const isHeaderExpanded = expanded?.includes(name);

  const active_key_array = useMemo(() => {
    let array = [name];
    keys.current?.row_keys?.forEach((rowId) => {
      const rowData = data ? data[rowId] : null;
      const cell = rowData?.["column-start"];
      if (!rowData || !cell || !expanded?.includes(cell?.parentRowId)) {
        return null;
      }
      array.push(rowId);
    });

    if (!expanded.includes(name)) {
      array = [name];
    }
    return array;
  }, [data, expanded, name, selectionCategoriesByID]);

  //lifecycle
  useEffect(() => {
    const obj = {};
    keys.current = {
      row_keys: rows?.map((o1) => o1?.rowId),
      column_keys: columns?.map((o1) => o1?.columnId),
    };
    rows?.forEach((row, rowIndex) => {
      obj[row?.rowId] = {};

      columns?.forEach((column, index) => {
        if (obj[row?.rowId]) {
          obj[row?.rowId][column?.columnId] = {
            rowHeight: row?.rowHeight,
            rowId: row?.rowId,
            mergeCell: row?.mergeCell,
            backgroundColor: row?.rowBackgroundColor,
            color: row?.color,
            parentRowId: row?.parentRowId,
            hasChildren: row?.hasChildren,
            depth: row?.depth,
            isHeader: row?.isHeader,
            disableSelection: row?.disableSelection,
            pattern: row?.pattern,
            position: row?.position,
            isLastCategoryChildCell: row?.isLastCategoryChildCell,

            editable: row?.cells?.[index]?.editable,
            isSticky: column?.isSticky,
            gridWidth: column?.gridWidth,
            cellId: column?.item,
            isFirstCell: index === 0,
            isFirstRowCell: rowIndex === 0,
            isLastCell: index === columns.length - 1,
            ...row?.cells?.[index],
          };
        }
      });
    });
    setData(obj);
  }, [columns, rows]);

  //functions
  const onChangedValue = ({ rowId, columnId, cell }, value, uuid) => {
    setData((prevData) =>
      produce(prevData, (modifiedData) => {
        if (!modifiedData?.[rowId]) modifiedData[rowId] = {};

        // if (
        //   modifiedData?.[rowId]?.[columnId]?.item?.income_expense_type === 2 &&
        //   !value?.toString()?.includes("-")
        // ) {
        //   value = -value;
        // }

        const total_value =
          (modifiedData[rowId][columnId]?.item?.total?.planned || 0) -
          Number(
            modifiedData[rowId][columnId]?.item?.total?.scenario_planned || 0
          ) +
          Number(value);
        const compareValue =
          modifiedData[rowId][columnId]?.item?.compareValue || 0;
        const _value =
          Math.abs(compareValue) >= Math.abs(total_value) ||
          modifiedData[rowId][columnId]?.item?.isPastMonth
            ? compareValue
            : total_value;
        const _value_style =
          Math.abs(compareValue) >= Math.abs(total_value) ||
          modifiedData[rowId][columnId]?.item?.isPastMonth
            ? "normal"
            : "italic";

        modifiedData[rowId][columnId].fontStyle = _value_style;
        modifiedData[rowId][columnId].item.value = _value;

        if (highlightedScenarios?.length > 0 && value) {
          const _scenario = scenarioById?.[highlightedScenarios?.[0]]?.[0];
          modifiedData[rowId][columnId].backgroundColor = modifiedData[rowId][
            columnId
          ]?.item?.isPastMonth
            ? Color.white
            : modifiedData[rowId][columnId]?.item?.isCurrentMonth
              ? getTailwindColor(_scenario?.color || "slate", 100)
              : getTailwindColor(_scenario?.color || "slate", 50);
          modifiedData[rowId][columnId].item.scenarios = [_scenario?.uuid];
        } else {
          modifiedData[rowId][columnId].backgroundColor = modifiedData[rowId][
            columnId
          ]?.item?.isCurrentMonth
            ? getTailwindColor("slate", 50)
            : Color.white;
        }
        modifiedData[rowId][columnId].item.bookedValue = 0;
        modifiedData[rowId][columnId].item.openValue = 0;
        modifiedData[rowId][columnId].item.plannedValue = total_value;
        modifiedData[rowId][columnId].item.scenario_planned = value;
        modifiedData[rowId][columnId].item.total.planned = total_value;
        if (value) {
          modifiedData[rowId][columnId].item.count.plannedCount =
            modifiedData[rowId][columnId].item.count.plannedCount === 0
              ? 1
              : modifiedData[rowId][columnId].item.count.plannedCount;
        }
        if (modifiedData[rowId][columnId].item.count.plannedCount >= 1) {
          const percentageChange = calculatePercentageChange(
            value,
            modifiedData[rowId][columnId]?.item?.compareValue || 0
          );
          modifiedData[rowId][columnId].item.percentageChange =
            percentageChange;
        }
        if (uuid) {
          modifiedData[rowId][columnId].item.uuid = uuid;
        }
      })
    );
    if (onCellChanged) onCellChanged({ rowId, columnId, value, uuid, cell });
  };

  const onDoubleClick = ({ cellKey, cell }) => {
    if (!cell?.isColumnStartCell && !cell?.disableSelection) {
      setSelectedCell(cellKey);
    }
  };

  return (
    <Stack
      sx={{
        position: position ? position : "unset",
        top: "0px",
        zIndex: position ? 10 : 0,
        textAlign: "left",
        "& .sticky-class": {
          position: "sticky",
          zIndex: 17,
          left: 0,
        },
        ...style,
      }}
    >
      <CommonFunctions
        ref={commonFunctionRef}
        name={name}
        setSelectedCell={setSelectedCell}
        setExpanded={setExpanded}
        rows={rows}
      />
      <div
        key={name}
        style={{
          display: "flex",
          width: "fit-content",
        }}
      >
        {keys.current?.column_keys?.map((key, cellIndex) => {
          const cell = data?.[name]?.[key] || null;
          if (!cell) {
            return null;
          }
          return (
            <RenderIfVisible
              key={`${cell?.rowId}-${cell?.cellId}`}
              initialVisible={cellIndex<=10}
              defaultHeight={"2.5rem"}
              visibleOffset={500}
              stayRendered={cellIndex<=10}
              rootElement="section"
              placeholderElement="div"
              rootElementClass={cell?.isSticky ? "sticky-class" : ""}
            >
              <Cell
                name={name}
                cell={cell || null}
                t={t}
                expanded={expanded}
                onChangedValue={onChangedValue}
                selectedCell={selectedCell}
                borderRadius={borderRadius}
                onClick={commonFunctionRef.current?.onClick}
                onClickArrow={commonFunctionRef.current?.onClickArrow}
                onDoubleClick={onDoubleClick}
                commonFunctionRef={commonFunctionRef}
                seqFunctionRef={seqFunctionRef}
                isSubscriptionValid={isSubscriptionValid}
                navigate={navigate}
                isLastRowCell={
                  active_key_array?.[active_key_array?.length - 1] === name
                }
              />
            </RenderIfVisible>
          );
        })}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "fit-content",
          transition: `max-height 0.3s ease-in-out`,
          maxHeight: isHeaderExpanded ? "10000px" : "0px",
        }}
      >
        {keys?.current?.row_keys?.map((rowId) => {
          const rowData = data?.[rowId];
          if (!rowData || rowId === name) {
            return null;
          }

          return (
            <RowWrapper $rowId={rowId} key={`${rowId}-row`}>
              {keys.current?.column_keys?.map((key, cellIndex) => {
                const cell = rowData?.[key];
                if (!cell || !expanded?.includes(cell?.parentRowId)) {
                  return null;
                }

                return (
                  <RenderIfVisible
                    key={`${cell?.rowId}-${cell?.cellId}`}
                    initialVisible={cellIndex <= 10}
                    defaultHeight={"2.5rem"}
                    visibleOffset={500}
                    stayRendered={cellIndex <= 10}
                    rootElement="section"
                    placeholderElement="div"
                    rootElementClass={cell?.isSticky ? "sticky-class" : ""}
                  >
                    <Cell
                      cell={cell || null}
                      t={t}
                      name={name}
                      expanded={expanded}
                      onChangedValue={onChangedValue}
                      selectedCell={selectedCell}
                      borderRadius={borderRadius}
                      onClick={commonFunctionRef.current?.onClick}
                      onClickArrow={commonFunctionRef.current?.onClickArrow}
                      onDoubleClick={onDoubleClick}
                      commonFunctionRef={commonFunctionRef}
                      seqFunctionRef={seqFunctionRef}
                      isSubscriptionValid={isSubscriptionValid}
                      navigate={navigate}
                      isLastRowCell={
                        active_key_array[active_key_array.length - 1] === rowId
                      }
                    />
                  </RenderIfVisible>
                );
              })}
            </RowWrapper>
          );
        })}
      </div>
    </Stack>
  );
};

export default Table;
Table.propTypes = {
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
};

const CommonFunctions = forwardRef(
  ({ setSelectedCell, setExpanded, rows, name }, ref) => {
    const dispatch = useDispatch();

    //redux
    const searchText = useSelector((state) => state.boardSlice?.searchText);
    const tableType = useSelector((state) => state.boardSlice?.tableType);
    const selectionCategories = useSelector(
      (state) => state.categorySlice?.selectionCategories
    );
    const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);

    const selectionCategoriesByID = useSelector(
      (state) => state.categorySlice?.selectionCategoriesByID
    );

    const isTableFetching = useIsFetching({
      queryKey: [
        "transactions",
        {
          dataset: dataSetData?.uuid,
        },
      ],
    });
    //state

    //functions
    const onClick = ({ cellKey, cell, isEditing }) => {
      console.log("cell:", cell);
      if (!isEditing) {
        if (!cell?.isColumnStartCell && !cell?.disableSelection) {
          setSelectedCell(cellKey);
        }
        const isTableHeader = Boolean(
          cell?.rowId === "inflow" || cell?.rowId === "outflow"
        );
        if (
          (cell.isHeader ? isTableHeader && !cell?.isColumnStartCell : true) &&
          cell?.rowId !== "VAT_Imputation"
        ) {
          let openTab = null;
          let overlayTab = null;
          let listViewPath = false;
          let showToCategorizeViewFilter = false;
          let hideAccessInListViewFilter = false;
          let showDateRange = false;
          let multiCategory = [];
          let dates = {};
          if (cell?.hasChildren) {
            const category = selectionCategoriesByID?.[cell?.rowId]?.[0];
            if (category) {
              collectUUIDs(category, multiCategory);
            }
          }

          if (cell?.isColumnStartCell) {
            hideAccessInListViewFilter = true;
            showDateRange = true;
            let start_date = format(
              new Date(dataSetData?.start_date),
              "yyyy-MM-dd"
            );
            let end_date = format(
              new Date(dataSetData?.end_date),
              "yyyy-MM-dd"
            );

            overlayTab = "category";
            dates = {
              start_date,
              end_date,
            };
          } else {
            let start_date = format(new Date(cell?.item?.date), "yyyy-MM-dd");

            let end_date =
              tableType === "daily"
                ? start_date
                : tableType === "quarterly"
                  ? format(endOfQuarter(new Date(start_date)), "yyyy-MM-dd")
                  : tableType === "weekly"
                    ? format(
                        endOfWeek(new Date(start_date), { weekStartsOn: 1 }),
                        "yyyy-MM-dd"
                      )
                    : format(endOfMonth(new Date(start_date)), "yyyy-MM-dd");

            dates = {
              start_date,
              end_date,
            };
            const open = Math.abs(cell?.item?.total?.open);
            const booked = Math.abs(cell?.item?.total?.booked);
            const planned = Math.abs(cell?.item?.total?.planned);
            if (cell?.item?.isPastMonth) {
              listViewPath = booked >= open ? "bank" : "open";
              openTab = booked >= open ? "Booked" : "Open";
            }
            if (cell?.item?.isCurrentMonth) {
              listViewPath =
                (open !== 0 || booked !== 0) && open + booked >= planned
                  ? open > booked
                    ? "open"
                    : "bank"
                  : "budget";
              openTab =
                (open !== 0 || booked !== 0) && open + booked >= planned
                  ? open > booked
                    ? "Open"
                    : "Booked"
                  : cell?.item?.pastTotal && cell?.item?.pastTotal !== 0
                    ? "Open"
                    : "Planned";
            }
            if (cell?.item?.isFutureMonth) {
              listViewPath = "open";
              if (open === 0 && cell?.item?.total?.planned === 0) {
                openTab = "Planned";
                listViewPath = "budget";
              } else {
                openTab = open >= planned ? "Open" : "Planned";
                listViewPath = open >= planned ? "open" : "budget";
              }
            }
            if (cell?.hasChildren && !cell?.isHeader) {
              const category = selectionCategoriesByID?.[cell?.rowId]?.[0];
              if (category) {
                collectUUIDs(category, multiCategory);
              }
            }
          }
          if (
            cell?.rowId === "uncategorized" ||
            cell?.rowId === "uncategorizedInflow" ||
            cell?.rowId === "uncategorizedOutflow"
          ) {
            showToCategorizeViewFilter = true;
          }
          if (tableType === "weekly" || tableType === "quarterly") {
            showDateRange = true;
          }
          dispatch(
            setPopupStatus2({
              open: true,
              overlay_type: "drawer_modal",
              anchor: "right",
              payload: {
                hideAccessInListViewFilter,
                overlayTab: overlayTab,
                openTab: openTab,
                cell: {
                  date: cell?.item?.date,
                  isCurrentMonth: cell?.item?.isCurrentMonth,
                  isPastMonth: cell?.item?.isPastMonth,
                  isFutureMonth: cell?.item?.isFutureMonth,
                  pastTotal: cell?.item?.pastTotal,
                  rowId: cell?.hasChildren ? null : cell?.rowId,
                  income_expense_type: cell?.item?.income_expense_type,
                  start_date: dates?.start_date,
                  end_date: dates?.end_date,
                  showBudgetStatistics: !cell?.isColumnStartCell,
                  hasChildren: cell?.hasChildren,
                  fetchPastOpen: cell?.item?.isCurrentMonth,
                  withUrl: true,
                  dateFormat: tableType === "daily" ? "dd MMMM yyyy" : null,
                  showDateRange,
                  multiCategory,
                  listViewPath,
                  showToCategorizeViewFilter,
                },
              },
            })
          );
        }
      }
    };

    const onClickArrow = (e, { cellKey, cell, isEditing }) => {
      e.stopPropagation();
      if (cell?.isColumnStartCell && cell.hasChildren) {
        setExpanded((prev) => {
          if (prev.includes(cell?.rowId)) {
            let ids = [cell?.rowId];
            const getIds = (rowId) => {
              let filterData = rows.filter(
                (o1) => o1.hasChildren && o1?.parentRowId === rowId
              );
              ids = [...ids, ...filterData.map((o1) => o1.rowId)];
              filterData?.forEach((o1) => {
                getIds(o1?.rowId);
              });
            };
            getIds(cell?.rowId);

            return prev.filter((o1) => ids.every((o2) => o2 !== o1));
          }
          const data = [...prev, cell?.rowId];
          localStorage?.setItem(`expanded-${name}`, JSON.stringify(data));
          return data;
        });
      }
    };


    useImperativeHandle(
      ref,
      () => ({
        onClick,
        onClickArrow,
        isTableFetching,
      }),
      [isTableFetching, rows]
    );

    useDebounce(
      () => {
        if (searchText?.length) {
          let includedCategory = selectionCategories
            .filter((o1) => o1.hasChildren)
            .map((o1) => o1.value);
          const data = [name, ...includedCategory];
          setExpanded(data);
          localStorage?.setItem(`expanded-${name}`, JSON.stringify(data));
        } else {
          const defaultData = localStorage?.getItem(`expanded-${name}`);
          const data = defaultData ? JSON.parse(defaultData) : [name];
          setExpanded(data);
          localStorage?.setItem(`expanded-${name}`, JSON.stringify(data));
        }
      },
      500,
      [searchText, selectionCategories],
      true
    );
    return null;
  }
);
