import { Chip, Tooltip, LinearProgress, useMediaQuery } from "@mui/material";
import { addDays, endOfMonth, startOfMonth, subDays } from "date-fns";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import React, { useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";
import { alpha } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import format from "date-fns/format";
import _ from "underscore";

import {
  getAllTransactionsByParams,
  getTransactionByParams,
  calculateSimilarity,
  getRecurDateArray,
  formatAmount,
  remToPx,
} from "../../../Helper/data";
import ReconcileOverlay from "../../../components/TableEditModal/ReconcileOverlay";
import CombinedDataGridFooter from "./Components/CombinedDataGridFooter";
import { setStageLoadingText } from "../../../store/slices/appmain";
import useDebounce from "../../../hooks/3-useDebounce/useDebounce";
import { setRecurring_rules } from "../../../store/slices/global";
import StateChip from "../../../components/StateChip";
import EndPoints from "../../../APICall/EndPoints";
import initialData from "./../../../Helper/data";
import { Color, Fonts } from "../../../Helper";
import useWidth from "../../../hooks/useWidth";
import APICall from "../../../APICall";
import store from "../../../store";

const useStyles = makeStyles({
  root: {
    backgroundColor: Color.appThemeBg,
    height: "100%",
    paddingTop: "1.5rem",
    width: "100vw",
    overflow: "hidden",
    [`& .last-header-cell`]: {
      "& .MuiDataGrid-columnSeparator": {
        display: "none",
      },
    },
  },
  table: {
    "& .MuiDataGrid-columnHeaderTitle": {
      fontSize: "0.9375rem",
      fontWeight: 600,
    },
  },
});

const ReconcileListView = ({
  rowCountState,
  setRowCountState,
  type = "list",
  startDate,
  endDate,
  isDataUpdated,
}) => {
  const currentWidth = useWidth();
  let width = remToPx(currentWidth, 42);
  const classes = useStyles();
  let HEIGHT = 2.3;
  let itemRow = useRef(null);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const xlg = useMediaQuery("(min-width:1080px)");
  const s1280 = useMediaQuery("(min-width:1280px)");
  const mid = useMediaQuery("(min-width:1536px)");
  const s1920 = useMediaQuery("(min-width:1920px)");
  if (xlg) {
    HEIGHT = 2.3;
    width = remToPx(currentWidth, 45);
  }
  if (s1280) {
    HEIGHT = 2.75;
    width = remToPx(currentWidth, 83);
  }
  if (mid) {
    HEIGHT = 2.76;
    width = remToPx(currentWidth, 90);
  }
  if (s1920) {
    width = remToPx(currentWidth, 93);
    HEIGHT = 3.25;
  }
  //redux state
  const appliedFilterlist = useSelector(
    (state) => state.globalSlice.appliedFilterlist
  );
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);
  const isAllHeaderApiFetched = useSelector(
    (state) => state.commonSlice.isAllHeaderApiFetched
  );
  const recurring_rules = useSelector(
    (state) => state.globalSlice?.recurring_rules
  );
  const recurring_rulesById = useSelector(
    (state) => state.globalSlice.recurring_rulesById
  );
  const state = useSelector((state) => state.globalSlice?.state);
  const stateByTitle = useSelector((state) => state.globalSlice?.stateByTitle);
  const refreshData = useSelector((state) => state.appSlice?.refreshData);
  const profile = useSelector((state) => state.settingsSlice?.profile);

  //state
  const [rows, setRows] = useState([]);
  const [listLoading, setListLoading] = useState(false);
  const [dataUpdated, setDataUpdated] = useState(false);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [order_by, setOrder_by] = useState("");
  const [count, setCount] = useState(0);
  const reconcileColumn = useMemo(
    () => [
      {
        field: "date2",
        headerName: "Date",
        width: width * 0.12,
        editable: false,
        sortable: false,
        type: "date",
        cellClassName: "super-app-theme--cell",
        valueGetter: ({ value }) => {
          return value ? new Date(value) : null;
        },
        valueFormatter: ({ value }) => {
          return value
            ? format(
                new Date(value),
                profile?.locale === "de_DE" ? "dd.MM.yyyy" : "dd/MM/yyyy"
              )
            : null;
        },
      },
      {
        field: "title2",
        headerName: "Title",
        width: width * 0.13,
        editable: false,
        sortable: false,
        cellClassName: "super-app-theme--cell",
        valueGetter: ({ value }) => {
          return (
            value ||
            (listLoading
              ? ""
              : t("No matching posted transactions found for reconciliation"))
          );
        },
      },
      {
        field: "note2",
        headerName: "Note",
        cellClassName: "super-app-theme--cell",
        width: width * 0.12,
        editable: false,
        sortable: false,
        renderCell: (params) => {
          return (
            <Tooltip title={params?.value}>
              <p
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {params?.value}
              </p>
            </Tooltip>
          );
        },
      },
      {
        field: "value2",
        headerName: "Value",
        cellClassName: "super-app-theme--cell",
        width: width * 0.12,
        editable: false,
        sortable: false,
        type: "float",
        renderCell: (params) => {
          if (params.value) {
            return formatAmount({
              amount: params?.value,
            });
          } else {
            return null;
          }
        },
        valueGetter: ({ value }) => value && parseFloat(value),
      },
      {
        field: "state2",
        headerName: "State",
        cellClassName: "super-app-theme--cell",
        width: width * 0.12,
        editable: false,
        sortable: false,
        renderCell: (params) => {
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              {params?.value && (
                <StateChip
                  title={params?.value}
                  hideBorder
                  hideIcon
                  width="8rem"
                  height="1.6rem"
                  fontSize="0.7rem"
                />
              )}
            </div>
          );
        },
      },

      {
        field: "due_date",
        headerName: "Date",
        width: width * 0.12,
        editable: false,
        type: "date",
        cellClassName: "super-app-theme--cell",
        valueGetter: ({ value }) => {
          return value ? new Date(value) : null;
        },
        valueFormatter: ({ value }) => {
          return value
            ? format(
                new Date(value),
                profile?.locale === "de_DE" ? "dd.MM.yyyy" : "dd/MM/yyyy"
              )
            : null;
        },
      },
      {
        field: "title",
        headerName: "Title",
        width: width * 0.13,
        editable: false,
        cellClassName: "super-app-theme--cell",
      },
      {
        field: "note",
        headerName: "Note",
        width: width * 0.12,
        editable: false,
        sortable: false,
        cellClassName: "super-app-theme--cell",
        renderCell: (params) => {
          return (
            <Tooltip title={params?.value}>
              <p
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {params?.value}
              </p>
            </Tooltip>
          );
        },
      },
      {
        field: "gross_value",
        headerName: "Value",
        cellClassName: "super-app-theme--cell",
        width: width * 0.12,
        editable: false,
        type: "float",
        renderCell: (params) => {
          return formatAmount({
            amount: params?.value,
          });
        },
        valueGetter: ({ value }) =>
          value || value === 0 ? parseFloat(value) : "",
      },
      {
        field: "state",
        headerName: "State",
        cellClassName: "divider-cell",
        headerClassName: "hedear-divider-cell",
        width: width * 0.12,
        editable: false,
        sortable: false,
        renderCell: (params) => {
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              {params?.value && (
                <StateChip
                  title={params?.value}
                  hideBorder
                  hideIcon
                  width="8rem"
                  height="1.6rem"
                  fontSize="0.7rem"
                />
              )}
            </div>
          );
        },
      },
      {
        field: "reconcile",
        headerName: "Reconcile",
        headerClassName: "last-header-cell",
        width: width * 0.15,
        editable: false,
        sortable: false,
        renderCell: (params) => {
          if (params?.row?.title2 && params?.row?.similarity > 1) {
            let color = Color.theme.orange[500];
            let backgroundColor = Color.theme.orange[50];
            let tooltip = t("reconcile_button_match_issue_high");
            if (params?.row?.similarity === 3) {
              tooltip = t("reconcile_button_match_same");
              color = Color.white;
              backgroundColor = Color.tailwind.green[500];
            }
            if (params?.row?.similarity <= 2.7) {
              tooltip = t("reconcile_button_match_less");
              color = Color.tailwind.green[500];
              backgroundColor = Color.tailwind.green[50];
            }
            if (params?.row?.similarity <= 2.5) {
              tooltip = t("reconcile_button_match_issue_less");
              color = Color.tailwind.orange[500];
              backgroundColor = Color.tailwind.orange[50];
            }
            if (params?.row?.similarity < 1.5) {
              tooltip = t("reconcile_button_match_issue_high");
              color = Color.tailwind.red[500];
              backgroundColor = Color.tailwind.red[50];
            }
            if (
              params?.row?.recurring_rule &&
              !params?.row?.isFirstRecurrence
            ) {
              color = Color.theme.grey[400];
              backgroundColor = Color.theme.grey[200];
              tooltip = t("reconcile_button_mid_sep_tooltip");
            }
            return (
              <strong>
                <Tooltip arrow placement="top" title={tooltip}>
                  <Chip
                    icon={<CompareArrowsIcon />}
                    onClick={(e) => handleClickReconcile(e, params)}
                    label={t("Reconcile")}
                    sx={{
                      fontSize: "0.75rem",
                      fontWeight: 600,
                      height: "1.5625rem",
                      minWidth: "5.625rem",
                      borderRadius: 1,
                      fontFamily: Fonts.Text,
                      color: color,
                      backgroundColor: backgroundColor,
                      border: `1px solid ${color}`,
                      "& .MuiSvgIcon-root": {
                        fontSize: "0.9rem",
                        color: color,
                      },
                      "&:hover": {
                        backgroundColor: alpha(backgroundColor, 0.9),
                      },
                    }}
                  />
                </Tooltip>
              </strong>
            );
          } else {
            return (
              <Tooltip
                arrow
                placement="top"
                title={t("Find Booked Transactions")}
              >
                <Chip
                  icon={<SearchIcon />}
                  onClick={(e) => handleClickFindReconcile(e, params)}
                  label={t("Find Transactions")}
                  sx={{
                    fontSize: "0.75rem",
                    fontWeight: 600,
                    height: "1.5625rem",
                    minWidth: "5.625rem",
                    borderRadius: 1,
                    fontFamily: Fonts.Text,
                    color: Color.themeColor2,
                    backgroundColor: alpha(Color.themeColor2, 0.2),
                    border: `1px solid ${Color.themeColor2}`,
                    "& .MuiSvgIcon-root": {
                      fontSize: "0.9rem",
                      color: Color.themeColor2,
                    },
                    "&:hover": {
                      backgroundColor: alpha(Color.themeColor2, 0.3),
                    },
                  }}
                />
              </Tooltip>
            );
          }
        },
      },
    ],
    [width, profile?.locale, t]
  );

  //api
  const updateCardByID = async (id, obj) => {
    await APICall("patch", EndPoints.transactions + `${id}/`, obj).then(
      (response) => {
        if (response.status === 200 && response.data) {
          dispatch(setStageLoadingText(null));
          resetKanban();
        }
      }
    );
  };

  const updateRecurringRules = async (id, obj) => {
    await APICall("patch", EndPoints.recurring_rules + `${id}/`, obj).then(
      (response) => {
        if (response.status === 200 && response.data) {
          let data = [...recurring_rules];
          let index = recurring_rules?.findIndex((o1) => o1.uuid === id);
          if (index > -1) {
            data[index] = response.data;
          }
          resetKanban();
          dispatch(setRecurring_rules(data));
        }
      }
    );
  };

  //lifeCycleMethod
  useDebounce(
    () => {
      setPage(0);
    },
    100,
    [appliedFilterlist?.list]
  );

  useDebounce(
    () => {
      if (isAllHeaderApiFetched) {
        generateToDos();
      }
    },
    800,
    [
      refreshData,
      appliedFilterlist?.list,
      page,
      order_by,
      dataUpdated,
      isAllHeaderApiFetched,
    ],
    true
  );

  //functions
  const resetKanban = async () => {
    setPage(0);
    setDataUpdated(Date.now());
    if (isDataUpdated) {
      isDataUpdated.current = true;
    }
  };

  const onPageChange = (newPage) => {
    setPage(newPage);
  };

  const onSortModelChange = (params) => {
    if (params && params[0]) {
      let { field, sort } = params[0];
      if (field === "title") {
        if (sort === "asc") {
          setOrder_by("title");
        } else {
          setOrder_by("-title");
        }
      }
      if (field === "due_date") {
        if (sort === "asc") {
          setOrder_by("due_date");
        } else {
          setOrder_by("-due_date");
        }
      }

      if (field === "invoice_date") {
        if (sort === "asc") {
          setOrder_by("invoice_date");
        } else {
          setOrder_by("-invoice_date");
        }
      }
      if (field === "gross_value") {
        if (sort === "asc") {
          setOrder_by("gross_value");
        } else {
          setOrder_by("-gross_value");
        }
      }
    } else {
      setOrder_by("");
    }
  };

  const handleClickReconcile = (e, data) => {
    if (data.row?.uuid2) {
      if (data?.row?.recurring_rule && !data?.row?.isFirstRecurrence) {
        return;
      } else {
        if (data?.row?.recurring_rule && data?.row?.isFirstRecurrence) {
          let dateArray = getRecurDateArray(
            data?.row?.recurring_Obj?.repetition,
            data?.row?.recurring_Obj?.start_date,
            data?.row?.recurring_Obj?.end_date
          );
          updateRecurringRules(data?.row?.recurring_rule, {
            start_date: format(dateArray[1], "yyyy-MM-dd"),
          });
        }
        updateCardByID(data.row?.uuid2, {
          reconciled: data.row?.uuid,
          category: data.row?.category2
            ? data.row?.category2
            : data.row?.category,
        });
      }
    } else {
      enqueueSnackbar(t("no data to reconcile"), {
        variant: "warning",
        autoHideDuration: 4000,
      });
    }
  };

  const handleClickFindReconcile = (e, data) => {
    let _data = {
      category: data.row?.category2 || data.row?.category,
      due_date: data.row?.date2 || data.row?.due_date,
      note: data.row?.note2 || data.row?.note,
      title: data.row?.title2 || data.row?.title,
      uuid: data.row?.uuid2 || data.row?.uuid,
      state: data.row?.state2 || data.row?.state,
      scenario: data.row?.scenario2 || data.row?.scenario,
      gross_value: data.row?.value2 || data.row?.gross_value,
    };

    itemRow.current = _data;
    setOpen(true);
  };

  function findMostSimilarItems(array1, array2) {
    const result = [];
    let maxSimilarity = 0;
    let titleSimilarity = 0;
    let valueSimilarity = 0;
    let dateSimilarity = 0;
    let mostSimilarItem = null;
    let usedBookedIds = [];
    for (const item1 of array1) {
      mostSimilarItem = null;
      maxSimilarity = 0;
      titleSimilarity = 0;
      valueSimilarity = 0;
      dateSimilarity = 0;
      for (const item2 of array2) {
        const {
          similarity,
          title_similarity,
          value_similarity,
          date_similarity,
        } = calculateSimilarity(item1, item2);
        if (similarity > maxSimilarity) {
          maxSimilarity = similarity;
          titleSimilarity = title_similarity;
          valueSimilarity = value_similarity;
          dateSimilarity = date_similarity;

          if (
            date_similarity >= 0 &&
            (value_similarity === 1 || title_similarity > 0.6)
          ) {
            mostSimilarItem = item2;
            usedBookedIds.push(item2.uuid);
          }
        }
      }
      let recurring_Obj =
        item1?.recurring_rule && recurring_rulesById[item1?.recurring_rule]
          ? recurring_rulesById[item1?.recurring_rule][0]
          : null;
      let main = {
        uuid: item1?.uuid || null,
        due_date: item1?.due_date || null,
        title: item1?.title || null,
        note: item1?.note || null,
        gross_value: item1?.gross_value || null,
        state: item1?.state || null,
        source: item1?.source || null,
        scenario: item1?.scenario || null,
        category: item1?.category || null,
        recurring_rule: item1?.recurring_rule,
        recurring_Obj: recurring_Obj || null,
        isFirstRecurrence:
          recurring_Obj &&
          format(new Date(recurring_Obj?.start_date), "yyyy-MM") ===
            format(new Date(item1?.due_date), "yyyy-MM"),
      };
      let suggestion = {
        uuid2: mostSimilarItem?.uuid || null,
        date2: mostSimilarItem?.due_date || null,
        title2: mostSimilarItem?.title || null,
        note2: mostSimilarItem?.note || null,
        value2: mostSimilarItem?.gross_value || null,
        state2: mostSimilarItem?.state || null,
        source2: mostSimilarItem?.source || null,
        scenario2: mostSimilarItem?.scenario || null,
        category2: mostSimilarItem?.category || null,
      };

      result.push({
        ...main,
        ...suggestion,
        similarity: maxSimilarity,
        titleSimilarity,
        valueSimilarity,
        dateSimilarity,
      });
    }
    // for (const item2 of array2) {
    //   if (!usedBookedIds.includes(item2?.uuid)) {
    //     result.push({
    //       uuid: item2?.uuid || null,
    //       uuid2: item2?.uuid || null,
    //       date2: item2?.due_date || null,
    //       title2: item2?.title || null,
    //       note2: item2?.note || null,
    //       value2: item2?.gross_value || null,
    //       state2: item2?.state || null,
    //       scenario2: item2?.scenario || null,
    //       category2: item2?.category || null,
    //     });
    //   }
    // }
    return result;
  }

  const generateToDos = async () => {
    let _states = [];
    state?.forEach((o1) => {
      if (
        !initialData?.plannedState.includes(o1.title) &&
        !initialData?.calculationExcludeStates.includes(o1.title)
      ) {
        _states.push(o1.uuid);
      }
    });
    const use_global_categories =
      store.getState().boardSlice?.dataSetData?.use_global_categories;
    let params = {
      dataset: dataSetData?.uuid,
      is_reconciled: false,
      is_split: false,
      order_by: order_by,
      state: _states,
    };
    if (!use_global_categories) {
      params.category_dataset = dataSetData?.uuid;
    } else {
      params.global_category = true;
    }
    setListLoading(true);
    if (appliedFilterlist?.list && type === "list") {
      let { searchText, selectedCostUnits, typeName, start_date, end_date } =
        appliedFilterlist?.list;

      if (searchText && searchText?.length > 0) {
        params = {
          ...params,
          title: searchText,
        };
      }

      if (start_date) {
        params = {
          ...params,
          startDate: format(new Date(start_date), "yyyy-MM-dd"),
        };
      }
      if (end_date) {
        params = {
          ...params,
          endDate: format(new Date(end_date), "yyyy-MM-dd"),
        };
      }

      if (selectedCostUnits?.length > 0) {
        params = {
          ...params,
          cost_unit: selectedCostUnits[0] || "",
        };
      }

      if (typeName?.includes("Income") && !typeName?.includes("Expense")) {
        params = {
          ...params,
          min_gross_value: 0,
        };
      }
      if (typeName?.includes("Expense") && !typeName?.includes("Income")) {
        params = {
          ...params,
          max_gross_value: 0,
        };
      }
    }

    if (type === "reconcile_overlay" && startDate && endDate) {
      params = {
        ...params,
        startDate: format(startOfMonth(new Date(startDate)), "yyyy-MM-dd"),
        endDate: format(endOfMonth(new Date(endDate)), "yyyy-MM-dd"),
      };
    }
    setListLoading(true);
    const allData = await getTransactionByParams({
      ...params,
      page: page + 1,
      page_size: 15,
    });

    if (allData?.results?.length > 0) {
      const parsedDates = allData?.results?.map((o1) => new Date(o1.due_date));
      let highestDate = parsedDates[0];
      let lowestDate = parsedDates[0];
      parsedDates.forEach((date) => {
        if (date > highestDate) {
          highestDate = date;
        }
        if (date < lowestDate) {
          lowestDate = date;
        }
      });

      if (stateByTitle) {
        let bookedParams = {
          ...params,
          reconciled: false,
          state: [stateByTitle?.["Booked"]?.[0]?.uuid],
          startDate: format(subDays(lowestDate, 60), "yyyy-MM-dd"),
          endDate: format(addDays(highestDate, 60), "yyyy-MM-dd"),
        };

        let bookedData = await getAllTransactionsByParams(bookedParams);
        let array = findMostSimilarItems(allData?.results, bookedData);
        const isFoundBank = array?.find(
          (o1) => o1?.source === 2 || o1?.source2 === 2
        );
        const isFoundIntegrations = array?.find(
          (o1) => o1?.source === 4 || o1?.source2 === 4
        );
        const isMinOneBankOrIntegration = Boolean(
          isFoundBank || isFoundIntegrations
        );
        if (isMinOneBankOrIntegration) {
          if (order_by?.length === 0) {
            array.sort((a, b) => (a.similarity > b.similarity ? -1 : 1));
          }
          if (setRowCountState) setRowCountState(array?.length);
          setCount(allData?.count);
          setRows([...array]);
        } else {
          setRows([]);
          if (setRowCountState) setRowCountState(0);
          setCount(0);
        }
      }
    } else {
      setRows([]);
    }
    setListLoading(false);
  };

  return (
    <>
      {open && (
        <ReconcileOverlay
          setOpen={setOpen}
          open={open}
          itemToBeReconcile={itemRow.current}
          setDataUpdated={setDataUpdated}
        />
      )}
      <DataGrid
        sx={{
          border: 0,
          overflowY: "hidden",
        }}
        className={classes.table}
        rows={rows}
        columns={reconcileColumn}
        pageSize={15}
        autoPageSize
        disableColumnMenu
        disableSelectionOnClick
        rowHeight={remToPx(currentWidth, HEIGHT)}
        getRowId={(row) => row?.uuid}
        rowCount={rowCountState || count}
        page={page}
        onPageChange={onPageChange}
        onSortModelChange={onSortModelChange}
        pagination
        paginationMode={"server"}
        loading={listLoading}
        localeText={{
          noRowsLabel:
            !listLoading && rowCountState === 0
              ? ""
              : t("Nothing to reconcile"),
          noResultsOverlayLabel: "",
        }}
        componentsProps={{
          footer: {
            hideCustomFooter: true,
            rowCountState,
            listLoading,
            tab: "reconcileArray",
          },
        }}
        components={{
          LoadingOverlay: LinearProgress,
          Footer: CombinedDataGridFooter,
        }}
      />
    </>
  );
};

export default ReconcileListView;
