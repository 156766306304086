import {
  TableContainer,
  Typography,
  TableBody,
  TableCell,
  TableRow,
  useTheme,
  Radio,
  Table,
  Stack,
  Box,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as v4uuid } from "uuid";

import OverlayFooter from "../../../../../components/Overlay/OverlayFooter";

const SelectHeaderStep = ({ state, onNext, onBack }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [firstRow] = state?.data;
  const columns = Object.values(firstRow)?.map((value) => ({
    field: value,
    headerName: value,
  }));

  const rows = useMemo(
    () =>
      state?.data?.map((row) => {
        const rowObject = { uuid: v4uuid() };
        firstRow.forEach((columnName, index) => {
          rowObject[columnName] = row[index];
        });
        return rowObject;
      }),
    [state?.data, firstRow]
  );

  //state
  const [selectedRowIndex, setSelectedRows] = useState(state?.headerIndex || 0);

  //functions
  const handleClick = (id) => {
    setSelectedRows(id);
  };

  return (
    <Box sx={{ height: "100%" }}>
      <Typography
        variant="h5"
        fontWeight={"fontWeightBold"}
        color={theme.palette.color.slate[800]}
        sx={{
          mb: "1.5rem",
        }}
      >
        {t(state?.step?.title)}
      </Typography>
      <TableContainer
        sx={{
          height: "calc(100% - 5rem)",
          overflowY: "auto",
          ...theme.thinScrollBar,
          border: `1px solid ${theme.palette.color.slate[200]}`,
          borderRadius: theme.borderRadius.main,
        }}
      >
        <Table size={"small"}>
          <TableBody>
            {rows.slice(0, 20).map((row, index) => {
              const isItemSelected = selectedRowIndex === index;
              const labelId = `enhanced-table-checkbox-${index}`;

              return (
                <TableRow
                  hover
                  onClick={() => handleClick(index)}
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row?.uuid}
                  selected={isItemSelected}
                  sx={{ cursor: "pointer" }}
                >
                  <TableCell padding="checkbox">
                    <Radio
                      color="primary"
                      checked={isItemSelected}
                      inputProps={{
                        "aria-labelledby": labelId,
                      }}
                    />
                  </TableCell>
                  {columns?.map((column) => (
                    <TableCell
                      key={`${row?.uuid}-${column?.field}`}
                      align="right"
                      sx={{ fontWeight: isItemSelected ? "bold" : "normal" }}
                    >
                      {row[column.field]}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Stack
        sx={{
          width: "100%",
          position: "absolute",
          bottom: "0",
          left: "0",
          right: "0",
          px: "1.75rem",
          py: "1rem",
          backgroundColor: theme.palette.color.slate[100],
        }}
      >
        <OverlayFooter
          textAdd="Next"
          textCancel="Back"
          addIcon={null}
          cancelIcon={null}
          onCancel={onBack}
          onClickAction={() => onNext(selectedRowIndex)}
          addButtonSx={{
            py: "0.75rem",
          }}
          cancelButtonSx={{
            py: "0.75rem",
          }}
        />
      </Stack>
    </Box>
  );
};

export default SelectHeaderStep;
