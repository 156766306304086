import {
  eachMonthOfInterval,
  startOfMonth,
  endOfMonth,
  subMonths,
  isValid,
  format,
} from "date-fns";
import _ from "underscore";

import {
  calculateLoanHistory,
  calculateLoanTenure,
  calculateEndDate,
} from "../../../Helper/functions";
import {
  getStatisticsDataWithParams,
  getRecurDateArray,
  getBookedIds,
} from "../../../Helper/data";
import { Constant } from "../../../Helper";
import store from "../../../store";

const getExpectedList = async ({
  recurring_Obj,
  cardItem,
  setExpectedList,
  income_expense_type,
  advanceSeqData,
  totalPreviewCount,
  PausedDuration,
  VariableRatesItemList,
}) => {
  let {
    recurring_type,
    source,
    average,
    category,
    users,
    average_value,
    conversion,
    value,
    churn,
    growth,
    payment_default,
    discount,
    capacity,
    workers,
    working_hours,
    holiday,
    average_vacation_day,
    average_sick_day,
    period,
    repetition,
    end_date,
    start_date,
    multiplier,
    users_growth,
    new_user_per_month,
    value_sets,
    salary_type,
    loan_calculation_method,
    deposit_value,
    interest_rate,
    interest_rate_type,
  } = recurring_Obj;
  // console.log("🚀 / recurring_Obj:", recurring_Obj);
  if (!start_date || !recurring_type || !repetition) {
    setExpectedList([]);
    return null;
  }

  if (
    recurring_type === "loan" || recurring_type === "leasing"
      ? false
      : !period || !end_date
  ) {
    setExpectedList([]);
    return null;
  }
  if (
    recurring_type === "loan" &&
    (!value ||
      Number(value) === 0 ||
      (loan_calculation_method === 2
        ? !payment_default
        : !average_value || Number(average_value) === 0))
  ) {
    setExpectedList([]);
    return null;
  }
  if (recurring_type === "leasing" && (!value || Number(value) === 0)) {
    setExpectedList([]);
    return null;
  }
  if (
    recurring_type === "employee" &&
    (salary_type ? !value || Number(value) === 0 : false)
  ) {
    setExpectedList([]);
    return null;
  }
  if (recurring_type === "simple" && (!value || Number(value) === 0)) {
    setExpectedList([]);
    return null;
  }
  if (recurring_type === "advanced" && !average) {
    return null;
  }
  if (recurring_type === "subscription" && (!users || !average_value)) {
    setExpectedList([]);
    return null;
  }
  if (recurring_type === "shop" && (!users || !average_value)) {
    setExpectedList([]);
    return null;
  }
  if (
    (recurring_type === "client" || recurring_type === "products") &&
    (!average_value || !capacity)
  ) {
    setExpectedList([]);
    return null;
  }
  if (
    recurring_type === "time" &&
    (!average_value || !workers || !working_hours)
  ) {
    setExpectedList([]);
    return null;
  }

  if (recurring_type === "leasing" || recurring_type === "loan") {
    if (loan_calculation_method === 2 || recurring_type === "leasing") {
      const { emi } = calculateLoanHistory({
        principal: Math.abs(value || 0),
        downPayment: Math.abs(deposit_value || 0),
        interest_rate: Math.abs(interest_rate || 0),
        interest_rate_type,
        loanTenureInMonths: payment_default,
        VariableRatesItemList,
        start_date,
      });

      average_value = String(emi);
      start_date = start_date ? start_date : cardItem?.due_date;
      end_date = calculateEndDate({
        months: payment_default,
        start_date,
        extraMonth:
          recurring_type === "leasing" ? 0 : PausedDuration?.length || 0,
      });
    } else {
      start_date = start_date ? start_date : cardItem?.due_date;
      const LoanTenure = calculateLoanTenure({
        principal: Math.abs(value || 0),
        downPayment: Math.abs(deposit_value || 0),
        emi: Math.abs(average_value || 0),
        interest_rate: Math.abs(interest_rate || 0),
        interest_rate_type,
        VariableRatesItemList,
        start_date,
      });
      payment_default = String(LoanTenure);
      end_date = calculateEndDate({
        months: LoanTenure,
        start_date,
        extraMonth:
          recurring_type === "leasing" ? 0 : PausedDuration?.length || 0,
      });
    }
  }

  if (
    start_date &&
    end_date &&
    (!isValid(new Date(start_date)) || !isValid(new Date(end_date)))
  ) {
    setExpectedList([]);
    return null;
  }
  if (new Date(start_date)?.getTime() > new Date(end_date)?.getTime()) {
    setExpectedList([]);
    return null;
  }
  const stateByTitle = store.getState().globalSlice?.stateByTitle;
  const dataSetData = store.getState().boardSlice?.dataSetData;
  let gross_value =
    recurring_type === "simple" || recurring_type === "employee"
      ? Number(value)
      : Number(average_value);
  let payUsersNumber = users ? Number(users) : 0;

  if (recurring_type === "simple") {
    let disc = 0;
    if (discount && Number(discount) !== 0) {
      disc = (gross_value * discount) / 100;
    }
    gross_value = gross_value - disc;
  }
  if (recurring_type === "advanced") {
    let Divide = average === 1 ? average : average + 1;
    let startDate = new Date(startOfMonth(subMonths(new Date(), Divide)));
    let endDate = new Date(endOfMonth(subMonths(new Date(), 1)));
    let formattedStartDate = format(startDate, "yyyy-MM-dd");
    if (!advanceSeqData.current?.[formattedStartDate]) {
      const Booked_ids = getBookedIds();

      const response = await getStatisticsDataWithParams({
        type: "monthly",
        dataset: dataSetData?.uuid,
        from_payment_date: format(startDate, "yyyy-MM-dd"),
        to_payment_date: format(endDate, "yyyy-MM-dd"),
        multiStatesIds: Booked_ids,
      });
      advanceSeqData.current[formattedStartDate] = response?.results;
    }

    const groupedMonthlyTransactions = _.groupBy(
      advanceSeqData.current[formattedStartDate] ?? [],
      ({ month }) => month
    );
    let result = eachMonthOfInterval({
      start: startDate,
      end: endDate,
    });

    let total = 0;

    if (source === 2) {
      result?.forEach((element) => {
        let date = format(element, "yyyy-MM");
        let monthData = groupedMonthlyTransactions[date];

        total =
          total +
          Math.abs(
            monthData
              ? monthData
                  ?.filter((o1) =>
                    category ? o1.category === category : !o1.category
                  )
                  ?.reduce(
                    (total, item) =>
                      parseFloat(total) +
                      parseFloat(
                        income_expense_type === 1
                          ? item?.inflow ?? 0
                          : item?.outflow ?? 0
                      ),
                    0
                  )
              : 0
          );
      });
    }
    if (source === 3) {
      result?.forEach((element) => {
        let date = format(element, "yyyy-MM");
        let monthData = groupedMonthlyTransactions[date];
        total =
          total +
          Math.abs(
            monthData
              ? monthData?.reduce(
                  (total, item) =>
                    parseFloat(total) + parseFloat(item?.inflow ?? 0),
                  0
                )
              : 0
          );
      });
    }
    if (source === 4) {
      result?.forEach((element) => {
        let date = format(element, "yyyy-MM");
        let monthData = groupedMonthlyTransactions[date];
        total =
          total +
          Math.abs(
            monthData
              ? monthData?.reduce(
                  (total, item) =>
                    parseFloat(total) + parseFloat(item?.outflow ?? 0),
                  0
                )
              : 0
          );
      });
    }
    let multiply = 1;
    let finalValue = total / Divide;
    if (multiplier && Number(multiplier) !== 0) {
      multiply = multiplier / 100;
    }
    gross_value = Number(finalValue) * Number(multiply);
  }
  if (recurring_type === "subscription") {
    let user = payUsersNumber;
    let disc = 0;
    if (discount && Number(discount) !== 0) {
      disc = (average_value * discount) / 100;
    }
    gross_value = user * (average_value - disc);
  }
  if (recurring_type === "shop") {
    let conv = 1;
    let disc = 0;
    if (discount && Number(discount) !== 0) {
      disc = (average_value * discount) / 100;
    }
    if (conversion && Number(conversion) !== 0) {
      conv = conversion / 100;
    }
    gross_value = users * (average_value - disc) * conv;
  }
  if (recurring_type === "client" || recurring_type === "products") {
    let disc = 0;
    let dif = capacity;

    if (discount && Number(discount) !== 0) {
      disc = (average_value * discount) / 100;
    }
    if (payment_default && Number(payment_default) !== 0) {
      dif = capacity - (capacity * payment_default) / 100;
    }
    gross_value = (average_value - disc) * dif;
  }
  if (recurring_type === "time") {
    let disc = 0;
    let day = 365;
    if (discount && Number(discount) !== 0) {
      disc = (average_value * discount) / 100;
    }
    if (holiday && Number(holiday) !== 0) {
      day = day - holiday;
    }
    if (average_vacation_day && Number(average_vacation_day) !== 0) {
      day = day - average_vacation_day;
    }
    if (average_sick_day && Number(average_sick_day) !== 0) {
      day = day - average_sick_day;
    }
    let discountedValue = average_value - disc;
    let calculatedValue = day * working_hours;
    gross_value = (calculatedValue * discountedValue * workers) / 12;
    if (payment_default && Number(payment_default) !== 0) {
      gross_value = gross_value - (gross_value * payment_default) / 100;
    }
  }
  if (recurring_type === "employee" && salary_type === 2) {
    gross_value = Math.abs(gross_value) / 12;
  }

  let data = [];

  let dateArray = getRecurDateArray(repetition, start_date, end_date);
  if (recurring_type === "subscription") {
    const [first_date] = dateArray?.splice(0, 1);
    if (first_date) {
      data.push({
        due_date: format(first_date, "MMM yy"),
        gross_value: gross_value.toFixed(0),
      });
    }
  }
  let oneTimeCost = 0;
  let onGoingCost = 0;
  dateArray?.forEach((item, index) => {
    if (recurring_type === "loan") {
      const formattedDate = format(item, "yyyy-MM");
      if (PausedDuration?.includes(formattedDate)) {
        return;
      }
    }
    if (recurring_type === "employee") {
      if (index === 0) {
        gross_value = Math.abs(Number(gross_value || 0));

        value_sets?.[0]?.items?.forEach((o1) => {
          let share = 0;
          if (Number(o1?.type) === 2) {
            if (o1?.value && Number(o1?.value) !== 0) {
              share = (Number(gross_value) * Number(o1?.value)) / 100;
            }
          } else {
            share = o1?.value;
          }
          onGoingCost = Number(onGoingCost || 0) + Number(Math.abs(share || 0));
        });
        value_sets?.[1]?.items?.forEach((o1) => {
          let share = 0;

          if (Number(o1?.type) === 2) {
            if (o1?.value && Number(o1?.value) !== 0) {
              share = (Number(gross_value) * Number(o1?.value)) / 100;
            }
          } else {
            share = o1?.value;
          }
          oneTimeCost = oneTimeCost + Number(Math.abs(share || 0));
        });

        gross_value = Number(gross_value || 0) + Number(onGoingCost || 0);
        gross_value = Number(gross_value || 0) + Number(oneTimeCost || 0);
      } else {
        gross_value =
          Math.abs(Number(gross_value || 0)) - Number(oneTimeCost || 0);
        oneTimeCost = 0;
      }
    }
    if (recurring_type === "subscription") {
      let user = payUsersNumber;
      if (users_growth && Number(users_growth) !== 0) {
        new_user_per_month =
          Number(new_user_per_month) +
          Number(new_user_per_month * users_growth) / 100;
      }
      user = Number(payUsersNumber) + Number(new_user_per_month || 0);

      if (churn && Number(churn) !== 0) {
        user = user - (user * churn) / 100;
      }
      payUsersNumber = user;
      gross_value = user * average_value;
    }
    if (recurring_type === "shop") {
      let conv = 1;
      if (users_growth && Number(users_growth) !== 0) {
        payUsersNumber = payUsersNumber + (payUsersNumber * users_growth) / 100;
      }
      if (conversion && Number(conversion) !== 0) {
        conv = conversion / 100;
      }
      gross_value = payUsersNumber * average_value * conv;
    }
    if (
      recurring_type === "simple" ||
      recurring_type === "advanced" ||
      recurring_type === "client" ||
      recurring_type === "products" ||
      recurring_type === "time"
    ) {
      let grow = 0;
      if (growth && Number(growth) !== 0) {
        grow = (gross_value * growth) / 100;
      }
      gross_value = gross_value + grow;
    }
    if (income_expense_type === 2) {
      gross_value = String(gross_value)?.includes("-")
        ? gross_value
        : -gross_value;
    }
    data.push({
      due_date: format(item, "MMMM yyyy"),
      gross_value: gross_value?.toFixed(0),
    });
  });
  totalPreviewCount.current =
    dateArray?.length -
    (recurring_type === "loan" ? PausedDuration?.length || 0 : 0);
  setExpectedList(data);
};

export default getExpectedList;
