import React from "react";
import { Box, Tooltip } from "@mui/material";
import { Color, Fonts } from "../../../../Helper";
import { useSelector } from "react-redux";
import initialData, { getTailwindColor } from "../../../../Helper/data";
import { useTranslation } from "react-i18next";
import Icon from "../../../../components/Icon";

const StateDotView = ({
  count = null,
  title = "",
  styleObj = {},
  isHaveLate = false,
  hideCount = false,
  sum = null,
  onMouseOver = () => undefined,
  onMouseLeave = () => undefined,
}) => {
  const { t } = useTranslation();
  const indicatorType = useSelector(
    (state) => state.globalSlice.appliedFilterlist?.kanban?.indicatorType
  );
  const stateByTitle = useSelector((state) => state.globalSlice.stateByTitle);
  const scenarioByTitle = useSelector(
    (state) => state.globalSlice?.scenarioByTitle
  );
  let state = null;
  if (indicatorType === "scenarios") {
    state =
      scenarioByTitle[title] && scenarioByTitle[title][0]
        ? scenarioByTitle[title][0]
        : null;
  } else {
    state =
      stateByTitle[title] && stateByTitle[title][0]
        ? stateByTitle[title][0]
        : null;
  }

  return (
    <Tooltip
      arrow
      title={`${isHaveLate ? `${t(title)} (${t("Late")})` : t(title)} : ${
        count || 1
      }`}
      placement="top"
    >
      <Box
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
        sx={{
          borderRadius: 50,
          zIndex: 5,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          fontWeight: 500,
          lineHeight: "normal",
          fontFamily: Fonts.Text,
          backgroundColor: getTailwindColor(
            state?.color,
            title.includes("Invoice") &&title!=="Invoice paid"? 200 : 300
          ),
          color: state?.iconColor
            ? Color.white
            : getTailwindColor(state?.color, 600),
          border: isHaveLate
            ? `1px ${
                initialData.calculationExcludeStates?.includes(title)
                  ? "solid"
                  : "dashed"
              } red`
            : `1px ${
                initialData.calculationExcludeStates?.includes(title)
                  ? "solid"
                  : "dashed"
              } ${getTailwindColor(state?.color, 400)}`,
          ...styleObj,
        }}
      >
        {state?.icon ? (
          <Icon
            icon={state?.icon}
            fontSize={state?.fontSize}
            color={
              state?.iconColor ||
              (state?.color && getTailwindColor(state?.color, 600))
            }
            style={{
              "& svg": {
                margin: state?.margin || "2px",
                paddingRight: state?.paddingRight || 0,
                paddingLeft: state?.paddingLeft || 0,
                paddingBottom: state?.paddingBottom || 0,
                paddingTop: state?.paddingTop || 0,
              },
            }}
          ></Icon>
        ) : (
          <span
            style={{
              width: 16,
              height: 16,
            }}
          ></span>
        )}
      </Box>
    </Tooltip>
  );
};

export default React.memo(StateDotView);
