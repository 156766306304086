import { Popover } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setPopupStatus } from "../../../../store/slices/datasets";
import HeaderAddOverlay from "../../../../components/Header/DatasetHeader/HeaderAddOverlay";
import { Constant } from "../../../../Helper";

const AddTransactionModal = () => {
  const popupStatus = useSelector((state) => state.datasetSlice?.popupStatus);
  const dispatch = useDispatch();

  const handleClosePopOver = () => {
    dispatch(
      setPopupStatus({
        open: false,
        anchorEl: null,
      })
    );
  };
  
  const popupState = {
    close: handleClosePopOver,
  };

  return (
    <Popover
      id={popupStatus?.id}
      open={popupStatus?.open}
      anchorEl={popupStatus?.anchorEl}
      onClose={handleClosePopOver}
      anchorOrigin={{
        vertical: "center",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "left",
      }}
      slotProps={{
          paper: {
            sx: {
              borderRadius: 1.5,
              boxShadow: Constant.OverlayBoxShadow,
            },
          },
        }}
    >
      <HeaderAddOverlay
        popupState={popupState}
        overlayType={popupStatus?.payload?.type}
        date={popupStatus?.payload?.date}
      />
    </Popover>
  );
};

export default AddTransactionModal;
