import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Box, Tooltip, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";
import { SlExclamation } from "react-icons/sl";
import { enqueueSnackbar } from "notistack";
import Dialog from "@mui/material/Dialog";
import format from "date-fns/format";
import { isValid } from "date-fns";
import { useEffect } from "react";
import * as React from "react";

import DateInputPicker from "../../../components/Overlay/DateInputPicker";
import TailwindButton from "../../../components/Overlay/TailwindButton";
import { setPopupStatus4 } from "../../../store/slices/datasets";
import DropDown from "../../../components/Overlay/DropDown";
import { Color, Constant } from "../../../Helper";
import initialData from "../../../Helper/data";

export default function SettingForm({
  open,
  onSave,
  type = "",
  isRecurringRule = false,
  tab,
}) {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const scenario = useSelector((state) => state.globalSlice?.scenario);
  const state = useSelector((state) => state.globalSlice?.state);
  const costunits = useSelector((state) => state.globalSlice.costunits);
  const vat = useSelector((state) => state.globalSlice.vat);

  //state
  const [selectedValue, setSelectedValue] = React.useState(null);
  const [showError, setShowError] = React.useState(false);
  let title = "";
  if (type === "state") {
    title = "Select State";
  }
  if (type === "scenario") {
    title = "Select Scenario";
  }
  if (type === "due_date" || type === "clone_at_date") {
    title = "Select Due Date";
  }
  if (type === "invoice_date") {
    title = "Select Invoice Date";
  }
  if (type === "cost_unit") {
    title = "Select Cost Unit";
  }
  if (type === "date_range") {
    title = "Select Date Range";
  }
  if (type === "tax") {
    title = "Select Tax";
  }

  const optionsState = React.useMemo(() => {
    if (type === "state") {
      if (isRecurringRule) {
        return state
          .filter((o1) => Constant.staffState.includes(o1?.title))
          .map((o1) => {
            return {
              ...o1,
              title: `Employee-${o1?.title}`,
              _title: o1?.title,
            };
          });
      } else {
        return state.filter(
          (o1) => !initialData.bookedState.includes(o1?.title)
        );
      }
    }
    return [];
  }, [isRecurringRule, state, type]);

  useEffect(() => {
    if (open) {
      if (type === "date_range") {
        setSelectedValue({
          start_date: null,
          end_date: null,
        });
      } else {
        setSelectedValue(null);
      }
    }
  }, [open, type]);

  const onChangeDropDown = async (event, value) => {
    setSelectedValue({ ...value, title: value?.title });
  };

  const onChangeDate = (value) => {
    setSelectedValue({
      [type === "invoice_date" ? type : "due_date"]:
        value && value !== "Invalid Date"
          ? format(new Date(value), "yyyy-MM-dd")
          : null,
    });
  };

  const onChangeRangeDate = (value, name) => {
    if (isValid(new Date(value))) {
      setShowError(false);
      setSelectedValue({
        ...selectedValue,
        [name]: value ? format(value, "yyyy-MM-dd") : null,
      });
    }
  };

  const onClickSave = () => {
    if (type === "date_range") {
      if (
        (selectedValue?.start_date || selectedValue?.end_date) &&
        isValid(new Date(selectedValue?.start_date)) &&
        isValid(new Date(selectedValue?.end_date))
      ) {
        if (
          selectedValue?.start_date && selectedValue?.end_date
            ? new Date(selectedValue?.start_date) <=
              new Date(selectedValue?.end_date)
            : true
        ) {
          onSave(selectedValue);
        } else {
          enqueueSnackbar(t("Start date should be less than end date"), {
            variant: "error",
            autoHideDuration: 2000,
          });
        }
      } else {
        setShowError(true);
      }
    } else {
      onSave(
        isRecurringRule && type === "state"
          ? { ...selectedValue, title: selectedValue?._title }
          : selectedValue
      );
    }
  };

  const onClose = () => {
    dispatch(
      setPopupStatus4({
        open: false,
        anchorEl: false,
      })
    );
  };

  return (
    <div>
      <Dialog
        open={!!open}
        onClose={onClose}
        sx={{
          zIndex: 1303,
        }}
      >
        <DialogTitle
          sx={{
            textTransform: "capitalize",
            backgroundColor: Color.HeaderBG,
          }}
        >
          {t(title)}
        </DialogTitle>
        <DialogContent
          sx={{
            width: "25rem",
            minHeight: "9rem",
            height: "fit-content",
            pt: `2rem !important`,
            backgroundColor: Color.BodyBG,
            ...theme.thinScrollBar,
          }}
        >
          <Box sx={{ width: "100%", position: "relative" }}>
            {type === "tax" ? (
              <DropDown
                mb={0}
                hideTitle
                value={selectedValue || null}
                onChange={onChangeDropDown}
                options={vat}
                renderOption={(option) => `${option?.title}` ?? ""}
                getOptionLabel={(option) => `${option?.title}` ?? ""}
                placeholder={t("tax")}
              />
            ) : null}
            {type === "state" ? (
              <DropDown
                mb={0}
                hideTitle
                value={selectedValue || null}
                onChange={onChangeDropDown}
                options={optionsState}
                placeholder={t("State")}
                getOptionLabel={(option) => t(option?.title) ?? ""}
                renderOption={(option) => t(option?.title) ?? ""}
              />
            ) : null}

            {type === "scenario" ? (
              <DropDown
                mb={0}
                hideTitle
                value={
                  selectedValue
                    ? { ...selectedValue, title: t(selectedValue?.title) }
                    : null
                }
                onChange={onChangeDropDown}
                options={scenario}
                placeholder={t("Scenario")}
                getOptionLabel={(item) =>
                  (item?.title === "Base" ? t(item?.title) : item?.title) ?? ""
                }
                renderOption={(item) =>
                  (item?.title === "Base" ? t(item?.title) : item?.title) ?? ""
                }
              />
            ) : null}

            {type === "cost_unit" ? (
              <DropDown
                mb={0}
                hideTitle
                value={selectedValue || null}
                onChange={onChangeDropDown}
                options={costunits}
                placeholder={t("Cost_Unit")}
              />
            ) : null}

            {type === "invoice_date" ||
            type === "due_date" ||
            type === "clone_at_date" ? (
              <DateInputPicker
                name={type === "invoice_date" ? type : "due_date"}
                label={
                  type === "invoice_date" ? t("Invoice Date") : t("Due Date")
                }
                showFullDate
                error={
                  showError &&
                  !Boolean(
                    selectedValue[type === "invoice_date" ? type : "due_date"]
                  )
                }
                value={
                  selectedValue &&
                  selectedValue[type === "invoice_date" ? type : "due_date"]
                    ? new Date(
                        selectedValue[
                          type === "invoice_date" ? type : "due_date"
                        ]
                      )
                    : undefined
                }
                onChange={onChangeDate}
                variant="filled"
                likeGoogle
                hideTitle
              />
            ) : null}

            {type === "date_range" ? (
              <>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                  }}
                >
                  <DateInputPicker
                    name="start_date"
                    tooltip=""
                    label={
                      <>
                        {t("label_start_date")}
                        <Tooltip
                          title={t(
                            "staff_bulk_action_update_date_range_start_date_info"
                          )}
                          placement="top"
                          arrow
                        >
                          <span>
                            <SlExclamation
                              style={{
                                fontSize: "1.1em",
                                cursor: "pointer",
                                marginLeft: "0.25rem",
                                paddingTop: "0.25rem",
                              }}
                            />
                          </span>
                        </Tooltip>
                      </>
                    }
                    showFullDate
                    error={showError && !Boolean(selectedValue?.start_date)}
                    value={selectedValue?.start_date}
                    onChange={onChangeRangeDate}
                    variant="filled"
                    likeGoogle
                    hideTitle
                  />
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    mt: "0.5rem",
                    display: "flex",
                  }}
                >
                  <DateInputPicker
                    name="end_date"
                    tooltip=""
                    label={
                      <>
                        {t("label_end_date")}
                        <Tooltip
                          title={t(
                            "staff_bulk_action_update_date_range_end_date_info"
                          )}
                          placement="top"
                          arrow
                        >
                          <span>
                            <SlExclamation
                              style={{
                                fontSize: "1.1em",
                                cursor: "pointer",
                                marginLeft: "0.25rem",
                                paddingTop: "0.25rem",
                              }}
                            />
                          </span>
                        </Tooltip>
                      </>
                    }
                    value={selectedValue?.end_date}
                    error={showError && !Boolean(selectedValue?.end_date)}
                    onChange={onChangeRangeDate}
                    variant="filled"
                    likeGoogle
                    hideTitle
                  />
                </Box>
              </>
            ) : null}
          </Box>
        </DialogContent>
        <DialogActions sx={{ backgroundColor: Color.FooterBG, p: "1.5rem" }}>
          <TailwindButton type="cancel" text={t("Cancel")} onClick={onClose} />
          <TailwindButton text={t("save")} onClick={onClickSave} />
        </DialogActions>
      </Dialog>
    </div>
  );
}
