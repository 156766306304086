import {
  eachMonthOfInterval,
  differenceInMonths,
  startOfMonth,
  isSameMonth,
  endOfMonth,
  addMonths,
  subMonths,
  isValid,
  format,
} from "date-fns";
import {
  Typography,
  IconButton,
  Container,
  TextField,
  Divider,
  Tooltip,
  styled,
  Paper,
  Stack,
  Grid,
  Box,
} from "@mui/material";
import {
  useCallback,
  useEffect,
  useState,
  Fragment,
  useMemo,
  useRef,
} from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { useDispatch, useSelector } from "react-redux";
import { TbChartAreaLineFilled } from "react-icons/tb";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import { IoMdPersonAdd } from "react-icons/io";
import AddIcon from "@mui/icons-material/Add";
import { alpha } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";
import { IoPeople } from "react-icons/io5";
import { HiUsers } from "react-icons/hi";
import { useTheme } from "@mui/styles";
import { v4 as v4uuid } from "uuid";
import { useFormik } from "formik";
import { t } from "i18next";
import * as Yup from "yup";
import _ from "underscore";

import {
  getAllTransactionsByParams,
  deleteBatchTransactions,
  addBatchTransactions,
  getCategoryParentId,
  formatDateToLocal,
  getStaffQueryKey,
  formatAmount,
  truncate,
} from "../../../Helper/data";
import {
  setEmployeeDepartment,
  setEmployeeScenarios,
  setEmployeeStateTab,
  setStaffSearchText,
  setEmployeeType,
} from "../../../store/slices/staff";
import {
  setTransactionsOverlayStatus,
  setPopupStatus2,
  setPopupStatus3,
  setPopupStatus5,
} from "../../../store/slices/datasets";
import CategoryFilterView from "../../../components/Overlay/ThemeFilter/Component/CategoryFilterView";
import TransactionViewSettingPopOver from "../Transactions/Components/TransactionViewSettingPopOver";
import SearchFilterView from "../../../components/Overlay/ThemeFilter/Component/SearchFilterView";
import {
  setStageLoadingText,
  setRefreshData,
} from "../../../store/slices/appmain";
import AddFilterPopOver from "../../../components/Overlay/ThemeFilter/AddFilterPopover";
import AddFilterView from "../../../components/Overlay/ThemeFilter/AddFilterView";
import AddPresetCard from "../../../components/TableEditModal/AddPresetCard";
import FinbanInfoBulb from "../../../components/FInbanInfo/FinbanInfoBulb";
import { useDeferredTimeout } from "../../../hooks/useDeferredTimeout";
import SubscriptionBadge from "../../../components/SubscriptionBadge";
import useDebounce from "../../../hooks/3-useDebounce/useDebounce";
import { setRecurring_rules } from "../../../store/slices/global";
import ComponentLoader from "../../../components/ComponentLoader";
import ThemeFilter from "../../../components/Overlay/ThemeFilter";
import CustomModal from "../../../components/Model/CustomModal";
import useSubscriptions from "../../../hooks/useSubscriptions";
import ScenarioChip from "../../../components/ScenarioChip";
import MenuView from "../../../components/Overlay/MenuView";
import CustomPopover from "../../../components/PopOver";
import ThemeTabs from "../../../components/ThemeTabs";
import AddButton from "../../../components/AddButton";
import AreaChartView from "./Component/AreaChartView";
import EmployeesFunctions from "./EmployeesFunctions";
import Translate from "../../../hooks/HOC/Translate";
import EndPoints from "../../../APICall/EndPoints";
import CountView from "./Component/CountView";
import PieWidget from "./Component/PieWidget";
import { Constant } from "../../../Helper";
import { queryClient } from "../../../App";
import APICall from "../../../APICall";
import store from "../../../store";
import theme from "../../../theme";
import i18n from "../../../i18n";

const tabOptions = [
  {
    value: 1,
    label: "Overview",
  },
  {
    value: 2,
    label: "Capacity",
  },
];

const menuOptions = [
  {
    value: 4,
    label: "Edit name",
    tooltip: "Edit name Tooltip",
    icon: <ModeEditIcon />,
  },
  {
    value: 1,
    label: "Add another planning to employees",
    tooltip: "Add another planning to employees Tooltip",

    icon: <AddIcon />,
  },
  {
    value: 2,
    label: "Duplicate",
    tooltip: "Duplicate Staff Tooltip",
    icon: <HiUsers />,
  },
  {
    value: 3,
    label: "Delete",
    tooltip: "Delete Staff Tooltip",
    icon: <DeleteIcon />,
  },
];

const FilterOptions = [
  {
    uuid: "employee_type",
    title: "add_employee_type_filter",
    icon: true,
  },
  {
    uuid: "category",
    title: "add_category_filter",
    icon: true,
  },
  {
    uuid: "scenario",
    title: "add_scenario_filter",
    icon: true,
  },
];

const inputStyle = {
  "& .MuiInputBase-root": {
    fontSize: "1rem",
  },
  "& .MuiFormLabel-root": {
    fontSize: "1rem",
  },
  mb: 12,
};
const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .MuiDataGrid-columnHeaders, .MuiDataGrid-columnHeader, .MuiDataGrid-columnSeparator":
    {
      minHeight: `${theme.typography.pxToRem(46)} !important`,
      height: `${theme.typography.pxToRem(46)} !important`,
    },

  "& .MuiDataGrid-sortIcon": {
    color: theme.palette.primary.main,
  },
  "& .MuiDataGrid-columnHeader": {
    outline: "none !important",
  },
  "& .MuiDataGrid-columnHeader:focus": {
    outline: "none",
  },
  "& .MuiDataGrid-cell:focus-within": {
    outline: "none",
  },
  "& .MuiDataGrid-cell:focus": {
    outline: "none",
  },
  "& .MuiDataGrid-row": {
    cursor: "pointer",
  },
  "& .MuiDataGrid-row:hover": {
    backgroundColor: theme.palette.color.violet[50],
  },
  "& .MuiDataGrid-cell": {
    border: 0,
    padding: "0 6px",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    color: theme.palette.color.slate[700],
    fontWeight: theme.typography.fontWeightMediumBold,
  },
  "& .MuiDataGrid-virtualScroller": {
    marginTop: `${theme.typography.pxToRem(46)} !important`,
  },
}));

const Employees = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const totalPageCount = useRef(null);
  const isFirstTime = useRef(true);
  const rowData = useRef({
    All: [],
    Planned: [],
    Ignored: [],
    "Written-off": [],
  });

  const testLoading = useSelector((state) => state.appSlice.testLoading);
  const staffData = useSelector((state) => state.staffSlice?.staffData);

  const [tab, setTab] = useState(tabOptions?.[0]?.value);
  const [isStaffLoading, setIsStaffLoading] = useState(true);

  useEffect(() => {
    if (tab === 2) {
      setIsStaffLoading(true);
    }
  }, [tab]);

  useDebounce(
    () => {
      dispatch(
        setStageLoadingText(
          isFirstTime.current && (isStaffLoading || testLoading)
            ? "process_staff_view_is_being_generated"
            : null
        )
      );

      if (!isStaffLoading) {
        isFirstTime.current = false;
      }
    },
    300,
    [isStaffLoading, testLoading],
    true
  );

  return (
    <Container
      sx={{
        width: "100%",
        minHeight: "calc(100% - 6rem)",
        maxWidth: "100% !important",
        height: "100%",
        pb: "4rem",
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        ...theme.thinScrollBar,
      }}
    >
      <TabListView rowData={rowData} />
      <Paper
        sx={{
          width: "90%",
          minHeight: staffData?.length === 0 ? "calc(100% - 5rem)" : "none",
          height: "fit-content",
          backgroundColor: "color.white",
          py: 8,
          borderRadius: theme.borderRadius.main,
        }}
      >
        {/* <ThemeTabs options={tabOptions} tab={tab} setTab={setTab} /> */}

        {/* {tab === 1 ? ( */}
        <StaffListView
          totalPageCount={totalPageCount}
          rowData={rowData}
          isStaffLoading={isStaffLoading || testLoading}
          setIsStaffLoading={setIsStaffLoading}
        />
        {/* ) : (
            <ComingSoonView />
          )} */}
      </Paper>
    </Container>
  );
};

export default Employees;

const TabListView = ({ rowData }) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  //redux
  const employeeStateTab = useSelector(
    (state) => state.staffSlice?.employeeStateTab
  );
  const staffData = useSelector((state) => state.staffSlice?.staffData); // keep for refresh count in tab

  const count = useMemo(() => {
    let data = {
      Planned: 0,
      Ignored: 0,
      "Written-off": 0,
      All: 0,
    };
    if (staffData) {
      const Planned = rowData.current?.["Planned"]?.length || 0;
      const Ignored = rowData.current?.["Ignored"]?.length || 0;
      const Written = rowData.current?.["Written-off"]?.length || 0;
      const All = Planned + Ignored + Written;

      data = { Planned, Ignored, "Written-off": Written, All };
    }
    return data;
  }, [rowData, staffData]);

  //state
  const employeeStateOptions = [
    {
      value: "Planned",
      label: "Employee-Planned",
      iconPosition: "end",
      icon: (
        <CountView
          count={count?.["Planned"] || 0}
          color={theme.palette.color.green[500]}
          bg={theme.palette.color.green[200]}
        />
      ),
    },
    {
      value: "Ignored",
      label: "Employee-Ignored",
      iconPosition: "end",
      icon: (
        <CountView
          count={count?.["Ignored"] || 0}
          color={theme.palette.color.blue[500]}
          bg={theme.palette.color.blue[200]}
        />
      ),
    },
    {
      value: "Written-off",
      label: "Employee-Written-off",
      iconPosition: "end",
      icon: (
        <CountView
          count={count?.["Written-off"] || 0}
          color={theme.palette.color.slate[500]}
          bg={theme.palette.color.slate[200]}
        />
      ),
    },
    {
      value: "All",
      label: "All",
      iconPosition: "end",
      icon: (
        <CountView
          count={count?.["All"] || 0}
          color={theme.palette.color.white}
          bg={theme.palette.color.slate[700]}
        />
      ),
    },
  ];

  //functions
  const setTab = (value) => {
    dispatch(setEmployeeStateTab(value));
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems={"center"}
      sx={{ mt: "1.25rem", width: "90%" }}
    >
      <ThemeTabs
        options={employeeStateOptions}
        tab={employeeStateTab}
        setTab={setTab}
        sx={{
          mb: -2,
          ml: 2,
        }}
      />
      <Stack direction={"row"} sx={{ alignItems: "center" }}>
        <SubscriptionBadge type="employee" sx={{ mr: "1rem" }} />

        <AddOverlay />
        <FinbanInfoBulb
          url={"https://finban.io/documentation/personal/"}
          sx={{ ml: "1rem" }}
        />
      </Stack>
    </Stack>
  );
};

const AddOverlay = ({ disabled }) => {
  const [isSubscriptionValid] = useSubscriptions();
  const overlayRef = useRef(null);
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  const onClickPopOver = () => {
    if (isSubscriptionValid({ showMessage: true, type: "employee" })) {
      setAnchorEl(overlayRef.current);
    }
  };

  const onClosePopOver = () => {
    setAnchorEl(null);
  };

  return (
    <CustomPopover
      type="anchor"
      anchorEl={anchorEl}
      ref={overlayRef}
      onClose={onClosePopOver}
      button={
        <AddButton
          onClick={onClickPopOver}
          icon={<IoMdPersonAdd style={{ fontSize: "1rem" }} />}
          disabled={disabled}
          tooltipLabel={t("Add_Staff")}
          label={t("Add_Staff")}
          isNKey
          doNotListenInForms
          sx={{
            my: 2,
          }}
        />
      }
    >
      <AddOverlayView
        disabled={disabled}
        popup_state={{ close: onClosePopOver }}
      />
    </CustomPopover>
  );
};

const AddOverlayView = ({ disabled, popup_state }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const employeeStateTab = useSelector(
    (state) => state.staffSlice?.employeeStateTab
  );
  const updateOverlay = () => {
    dispatch(setRefreshData(Date.now()));
  };

  const _State =
    !employeeStateTab || employeeStateTab === "All"
      ? "Planned"
      : employeeStateTab;
  return (
    <Box
      sx={{
        p: "1.5rem",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "color.slate.50",
      }}
    >
      <AddPresetCard
        disabled={disabled}
        transaction_type={"expense"}
        preset_type="EmployeePlanning"
        popup_state={popup_state}
        updateOverlay={updateOverlay}
        hideEditFormTabBar
        state={_State}
        hideIcon
        highlightIcon={false}
        descriptionCount={162}
      />
      <Divider
        textAlign="left"
        sx={{
          width: "100%",
          mt: "1.5rem",
          mb: "0.5rem",
          fontFamily: theme.typography.fontFamily,
          color: theme.palette.color.slate[300],
          fontWeight: theme.typography.fontWeightMediumBold,
          fontSize: "0.8rem",
        }}
      >
        {t("Preset")}
      </Divider>
      <AddPresetCard
        disabled={disabled}
        transaction_type={"expense"}
        preset_type="EmployeePlanningA"
        popup_state={popup_state}
        updateOverlay={updateOverlay}
        hideEditFormTabBar
        state={_State}
        hideIcon
        highlightIcon={false}
        descriptionCount={162}
      />
      <AddPresetCard
        disabled={disabled}
        transaction_type={"expense"}
        preset_type="EmployeePlanningFreelancer"
        popup_state={popup_state}
        updateOverlay={updateOverlay}
        hideEditFormTabBar
        state={_State}
        hideIcon
        highlightIcon={false}
        descriptionCount={162}
      />
      <AddPresetCard
        disabled={disabled}
        transaction_type={"expense"}
        preset_type="EmployeePlanningWorkingStudent"
        popup_state={popup_state}
        updateOverlay={updateOverlay}
        hideEditFormTabBar
        state={_State}
        hideIcon
        highlightIcon={false}
        descriptionCount={162}
      />
      <AddPresetCard
        disabled={disabled}
        transaction_type={"expense"}
        preset_type="EmployeePlanningStudentInMarginalEmployment"
        popup_state={popup_state}
        updateOverlay={updateOverlay}
        hideEditFormTabBar
        state={_State}
        hideIcon
        highlightIcon={false}
        descriptionCount={162}
      />

      <AddPresetCard
        disabled={disabled}
        transaction_type={"expense"}
        preset_type="EmployeePlanningInternship"
        popup_state={popup_state}
        updateOverlay={updateOverlay}
        hideEditFormTabBar
        state={_State}
        hideIcon
        highlightIcon={false}
        descriptionCount={162}
      />

      <AddPresetCard
        disabled={disabled}
        transaction_type={"expense"}
        preset_type="EmployeePlanningMinijob"
        popup_state={popup_state}
        updateOverlay={updateOverlay}
        hideEditFormTabBar
        state={_State}
        hideIcon
        highlightIcon={false}
        descriptionCount={162}
      />
    </Box>
  );
};

const FilterView = ({
  totalPageCount,
  rowData,
  setIsStaffLoading,
  commonRef,
}) => {
  const dispatch = useDispatch();
  const isFilterOpen = useRef(false);

  const [allowToFetch, setAllowToFetch] = useState(true);
  const [isIncludedInFilterList, setIsIncludedInFilterList] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  const onClickFilter = useCallback((e) => {
    isFilterOpen.current = true;
    setAnchorEl(e.currentTarget);
  }, []);

  const removeFilter = useCallback((key) => {
    setIsIncludedInFilterList((prev) => prev?.filter((item) => item !== key));
  }, []);

  const onClose = (_selectedIds) => {
    isFilterOpen.current = false;
    setIsIncludedInFilterList(_selectedIds);
    setAnchorEl(null);
    const employeeDepartment =
      store?.getState()?.staffSlice?.employeeDepartment;
    const employeeType = store?.getState()?.staffSlice?.employeeType;
    const employeeScenarios = store?.getState()?.staffSlice?.employeeScenarios;

    if (employeeDepartment?.length > 0 && !_selectedIds?.includes("category")) {
      dispatch(setEmployeeDepartment([]));
    }
    if (employeeType?.length > 0 && !_selectedIds?.includes("employee_type")) {
      dispatch(setEmployeeType([]));
    }
    if (employeeScenarios?.length > 0 && !_selectedIds?.includes("scenario")) {
      dispatch(setEmployeeScenarios([]));
    }
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        width: "100%",
        px: 8,
        mb: "1.5rem",
      }}
    >
      <EmployeesFunctions
        ref={commonRef}
        totalPageCount={totalPageCount}
        rowData={rowData}
        setIsStaffLoading={setIsStaffLoading}
        allowToFetch={allowToFetch}
        setIsIncludedInFilterList={setIsIncludedInFilterList}
      />

      <SearchView />
      {isIncludedInFilterList?.length > 0 ? null : (
        <AddFilterView
          isIncludedInFilterList={isIncludedInFilterList}
          onClick={onClickFilter}
        />
      )}
      {isIncludedInFilterList?.map((item) => {
        if (item === "category") {
          return (
            <CategoryFilter
              setAllowToFetch={setAllowToFetch}
              isFilterOpen={isFilterOpen}
              filterKey="category"
              removeFilter={removeFilter}
            />
          );
        }
        if (item === "employee_type") {
          return (
            <TypeFilter
              isFilterOpen={isFilterOpen}
              filterKey="employee_type"
              removeFilter={removeFilter}
            />
          );
        }
        if (item === "scenario") {
          return (
            <ScenarioFilter
              isFilterOpen={isFilterOpen}
              filterKey="scenario"
              removeFilter={removeFilter}
            />
          );
        }

        return null;
      })}

      {isIncludedInFilterList?.length > 0 ? (
        <AddFilterView
          isIncludedInFilterList={isIncludedInFilterList}
          onClick={onClickFilter}
        />
      ) : null}

      <AddFilterPopOver
        anchorEl={anchorEl}
        onClose={onClose}
        selectedIds={isIncludedInFilterList}
        options={FilterOptions}
      />
    </Stack>
  );
};

const SearchView = () => {
  const dispatch = useDispatch();

  const staffSearchText = useSelector(
    (state) => state.staffSlice.staffSearchText
  );

  //functions
  const onChangeSearch = (e) => {
    dispatch(setStaffSearchText(e?.target?.value));
  };

  const onClickClear = (e) => {
    dispatch(setStaffSearchText(""));
  };

  return (
    <SearchFilterView
      active={false}
      value={staffSearchText}
      color={"slate"}
      onClickClear={onClickClear}
      onChange={onChangeSearch}
    />
  );
};

const TypeFilter = ({ isFilterOpen, removeFilter, filterKey }) => {
  const dispatch = useDispatch();

  const employeeType = useSelector((state) => state.staffSlice?.employeeType);

  const onChangeValue = (value) => {
    dispatch(setEmployeeType(value));
  };

  const onClickReset = () => {
    removeFilter(filterKey);
    onChangeValue([]);
  };

  return (
    <ThemeFilter
      heading="Type Filter"
      tooltip="tooltip_employee_type_filter"
      icon={<IoPeople style={{ fontSize: "1rem", marginTop: "1px" }} />}
      options={Constant.EmployeeType}
      translate
      selectedIds={employeeType}
      isActive={employeeType?.length > 0}
      onChangeValue={onChangeValue}
      onClickReset={onClickReset}
      onClear={onClickReset}
      isFilterOpen={isFilterOpen}
      maxWidth="auto"
    />
  );
};

const CategoryFilter = ({
  setAllowToFetch,
  isFilterOpen,
  removeFilter,
  filterKey,
}) => {
  const dispatch = useDispatch();
  //redux
  const employeeDepartment = useSelector(
    (state) => state.staffSlice?.employeeDepartment
  );

  const onChangeValue = (value) => {
    dispatch(setEmployeeDepartment(value));
  };

  const onClickReset = (e) => {
    e.stopPropagation();
    removeFilter(filterKey);
    onChangeValue([]);
  };

  const onOpen = () => {
    setAllowToFetch(false);
  };

  const onClose = () => {
    setAllowToFetch(true);
  };

  return (
    <CategoryFilterView
      heading="Employee Department"
      categoryType={2}
      selectedIds={employeeDepartment}
      onOpen={onOpen}
      onClose={onClose}
      onClickReset={onClickReset}
      onChangeValue={onChangeValue}
      isFilterOpen={isFilterOpen}
    />
  );
};

const ScenarioFilter = ({ isFilterOpen, filterKey, removeFilter }) => {
  const dispatch = useDispatch();

  const scenario = useSelector((state) => state.globalSlice?.scenario);
  const employeeScenarios = useSelector(
    (state) => state.staffSlice?.employeeScenarios
  );

  const options = useMemo(() => {
    let array = [];
    scenario?.forEach((o1) => {
      if (o1.title === "Base") {
        array.push({ ...o1, translate: true });
      } else {
        array.push(o1);
      }
    });

    return array;
  }, [scenario]);

  const onChangeValue = (value) => {
    dispatch(setEmployeeScenarios(value));
  };

  const onClickReset = () => {
    onChangeValue([]);
    removeFilter(filterKey);
  };

  return (
    <ThemeFilter
      heading="scenario_filter"
      tooltip="tooltip_scenario_filter"
      icon={
        <TbChartAreaLineFilled style={{ fontSize: "1rem", marginTop: "1px" }} />
      }
      options={options}
      selectedIds={employeeScenarios}
      isActive={employeeScenarios?.length > 0}
      onChangeValue={onChangeValue}
      onClickReset={onClickReset}
      onClear={onClickReset}
      isFilterOpen={isFilterOpen}
      maxWidth="fit-content"
    />
  );
};

const StaffListView = ({
  totalPageCount,
  rowData,
  isStaffLoading,
  setIsStaffLoading,
}) => {
  const commonRef = useRef(null);

  return (
    <div
      style={{
        width: "100%",
        // height: "100%",
      }}
    >
      <FilterView
        totalPageCount={totalPageCount}
        rowData={rowData}
        setIsStaffLoading={setIsStaffLoading}
        commonRef={commonRef}
      />
      <Grid
        container
        rowSpacing={5}
        columnSpacing={5}
        sx={{
          width: "100%",
          height: "fit-content",
          my: "2rem",
          px: "1.45rem",
        }}
      >
        <Grid item xs={12} isTablet={6} s1280={3}>
          <DepartmentWidget loading={isStaffLoading} commonRef={commonRef} />
        </Grid>
        <Grid item xs={12} isTablet={6} s1280={3}>
          <TypeWidget loading={isStaffLoading} commonRef={commonRef} />
        </Grid>
        <Grid item xs={12} isTablet={12} s1280={6}>
          <CostWidget loading={isStaffLoading} commonRef={commonRef} />
        </Grid>
      </Grid>
      <StaffGrid loading={isStaffLoading} totalPageCount={totalPageCount} />
    </div>
  );
};

const DepartmentWidget = ({ loading, commonRef }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  //redux
  const staffData = useSelector((state) => state.staffSlice?.staffData);

  const employeeStateTab = useSelector(
    (state) => state.staffSlice?.employeeStateTab
  );
  const employeeType = useSelector((state) => state.staffSlice?.employeeType);

  const staffSearchText = useSelector(
    (state) => state.staffSlice.staffSearchText
  );
  const employeeDepartment = useSelector(
    (state) => state.staffSlice?.employeeDepartment
  );
  const employeeScenarios = useSelector(
    (state) => state.staffSlice?.employeeScenarios
  );

  const DeferredSearchText = useDeferredTimeout({ value: staffSearchText });
  //state
  const [costType, setCostType] = useState("monthly");
  const [data, setData] = useState([]);

  //functions
  const onChange = (e, value) => {
    setCostType(value);
  };

  useDebounce(
    () => {
      if (!loading) {
        const selectionCategoriesByID =
          store.getState().categorySlice?.selectionCategoriesByID;
        const recurring_rulesById =
          store.getState().globalSlice?.recurring_rulesById;
        const filteredTransactionsByState =
          commonRef?.current?.filteredTransactionsByState;

        const filterData =
          employeeStateTab !== "All"
            ? filteredTransactionsByState?.[employeeStateTab]?.filter(
                (o1) => o1.state === employeeStateTab
              )
            : filteredTransactionsByState?.[employeeStateTab] || [];

        const dates = [];
        filterData?.forEach((item) => {
          dates.push(new Date(item?.due_date));
        });

        const minDate = dates?.reduce(
          (min, date) => (min < date ? min : date),
          new Date(Infinity)
        );
        const maxDate = dates?.reduce(
          (max, date) => (max > date ? max : date),
          new Date(-Infinity)
        );

        if (!isValid(minDate) || !isValid(maxDate)) {
          setData([]);
          return;
        }

        // const lastMonth = format(
        //   endOfMonth(addMonths(new Date(), 11)),
        //   "yyyy-MM"
        // );
        const currentMonth = format(startOfMonth(new Date()), "yyyy-MM");
        const yearlyData = filterData?.filter((item) => {
          const month = format(new Date(item.due_date), "yyyy-MM");
          return month === currentMonth;
        });
        const TransactionsByDepartment = _.groupBy(
          yearlyData,
          ({ recurring_rule }) =>
            recurring_rulesById?.[recurring_rule]?.[0]?.category ||
            "UnCategorized"
        );

        let array = [];
        Object.keys(TransactionsByDepartment).forEach((key) => {
          let total = TransactionsByDepartment[key].reduce(
            (a, b) => a + Number(b.gross_value),
            0
          );
          // if (costType === "monthly") {
          //   total = total / 12;
          // }
          const category = selectionCategoriesByID?.[key]?.[0];
          let title = category?.title;
          if (category?.immutable) {
            title = t(category?.title);
          } else if (!category?.title) {
            title = t(key);
          }
          array.push({
            uuid: key,
            title: title,
            value: Math.abs(parseFloat(total)?.toFixed(0)),
            color: category?.color || theme.palette.color.slate[500],
          });
        });

        array.sort((a, b) => b.value - a.value);
        setData(array);
      }
    },
    300,
    [
      loading,
      employeeStateTab,
      employeeType,
      employeeDepartment,
      employeeScenarios,
      DeferredSearchText,
      costType,
      staffData,
    ],
    true
  );
  if (!loading && staffData?.length === 0) return null;

  if (!loading && data?.length === 0) {
    return (
      <ComponentLoader
        height="18rem"
        key2="employee_department_widget_no_data_text01"
        placeHolderSx={{
          width: "100%",
        }}
      />
    );
  }
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <PieWidget
        switchTooltip="employee_department_widget_switch_tooltip"
        title="Employee Department"
        loading={loading}
        options={data}
        value={costType}
        onChange={onChange}
        sx={{
          flex: 1,
          width: "100%",
          minHeight: "18rem",
        }}
      />
      <Divider
        orientation="vertical"
        flexItem
        sx={{
          display: { xs: "none", md: "block" },
          opacity: 0.6,
          ml: "1.25rem",
        }}
      />
    </div>
  );
};

const TypeWidget = ({ loading, commonRef }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  //redux
  const employeeStateTab = useSelector(
    (state) => state.staffSlice?.employeeStateTab
  );
  const recurring_rulesById = useSelector(
    (state) => state.globalSlice?.recurring_rulesById
  );
  const employeeType = useSelector((state) => state.staffSlice?.employeeType);

  const staffSearchText = useSelector(
    (state) => state.staffSlice.staffSearchText
  );
  const employeeDepartment = useSelector(
    (state) => state.staffSlice?.employeeDepartment
  );
  const employeeScenarios = useSelector(
    (state) => state.staffSlice?.employeeScenarios
  );
  const staffData = useSelector((state) => state.staffSlice?.staffData);

  const DeferredSearchText = useDeferredTimeout({ value: staffSearchText });
  //state
  const [costType, setCostType] = useState("monthly");
  const [data, setData] = useState([]);

  //functions
  const onChange = (e, value) => {
    setCostType(value);
  };

  useDebounce(
    () => {
      if (!loading) {
        const filteredTransactionsByState =
          commonRef?.current?.filteredTransactionsByState;

        const filterData =
          employeeStateTab !== "All"
            ? filteredTransactionsByState?.[employeeStateTab]?.filter(
                (o1) => o1.state === employeeStateTab
              )
            : filteredTransactionsByState?.[employeeStateTab] || [];

        const dates = [];
        filterData?.forEach((item) => {
          dates.push(new Date(item?.due_date));
        });

        const minDate = dates?.reduce(
          (min, date) => (min < date ? min : date),
          new Date(Infinity)
        );
        const maxDate = dates?.reduce(
          (max, date) => (max > date ? max : date),
          new Date(-Infinity)
        );

        if (!isValid(minDate) || !isValid(maxDate)) {
          setData([]);
          return;
        }

        const currentMonth = format(startOfMonth(new Date()), "yyyy-MM");
        const yearlyData = filterData?.filter((item) => {
          const month = format(new Date(item.due_date), "yyyy-MM");
          return month === currentMonth;
        });
        const TransactionsByEmployeeType = _.groupBy(
          yearlyData,
          ({ recurring_rule }) =>
            recurring_rulesById?.[recurring_rule]?.[0]?.employee_type
        );

        let array = [];
        Object.keys(TransactionsByEmployeeType).forEach((key) => {
          let total = TransactionsByEmployeeType[key].reduce(
            (a, b) => a + Number(b.gross_value),
            0
          );
          // if (costType === "monthly") {
          //   total = total / 12;
          // }
          const type = Constant.EmployeeTypeByTitle?.[key]?.[0];

          array.push({
            uuid: key,
            title: t(key),
            position: type?.position || 100,
            value: Math.abs(parseFloat(total)?.toFixed(0)),
            color: type?.fullColor || theme.palette.color.cyan[600],
          });
        });

        array.sort((a, b) => b.value - a.value);
        setData(array);
      }
    },
    300,
    [
      loading,
      employeeStateTab,
      employeeType,
      DeferredSearchText,
      employeeDepartment,
      employeeScenarios,
      staffData,
      costType,
      t,
    ],
    true
  );
  if (!loading && staffData?.length === 0) return null;

  if (!loading && data?.length === 0) {
    return (
      <ComponentLoader
        height="18rem"
        key2="employee_type_widget_no_data_text01"
        placeHolderSx={{
          width: "100%",
        }}
      />
    );
  }
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <PieWidget
        switchTooltip="employee_type_widget_switch_tooltip"
        title="Employee Type"
        loading={loading}
        options={data}
        value={costType}
        onChange={onChange}
        sx={{
          width: "100%",
          minHeight: "18rem",
        }}
      />
      <Divider
        orientation="vertical"
        flexItem
        sx={{
          display: { xs: "none", md: "block" },
          opacity: 0.6,
          ml: "1.25rem",
        }}
      />
    </div>
  );
};

const CostWidget = ({ loading, commonRef }) => {
  const theme = useTheme();
  const employeeStateTab = useSelector(
    (state) => state.staffSlice?.employeeStateTab
  );
  const employeeType = useSelector((state) => state.staffSlice?.employeeType);

  const staffSearchText = useSelector(
    (state) => state.staffSlice.staffSearchText
  );
  const employeeDepartment = useSelector(
    (state) => state.staffSlice?.employeeDepartment
  );
  const employeeScenarios = useSelector(
    (state) => state.staffSlice?.employeeScenarios
  );
  const staffData = useSelector((state) => state.staffSlice?.staffData);

  const DeferredSearchText = useDeferredTimeout({ value: staffSearchText });

  const [costType, setCostType] = useState("department");
  const [chartData, setChartData] = useState([]);
  const [chartKeys, setChartKeys] = useState([]);

  //functions
  const onChange = (e, value) => {
    if (value && value !== costType) {
      setCostType(value);
    }
  };

  useDebounce(
    () => {
      if (!loading) {
        const selectionCategoriesByID =
          store.getState().categorySlice?.selectionCategoriesByID;

        const scenarioByTitle = store.getState().globalSlice?.scenarioByTitle;
        const recurring_rulesById =
          store.getState().globalSlice?.recurring_rulesById;
        const filteredTransactionsByState =
          commonRef?.current?.filteredTransactionsByState;

        const filterData =
          employeeStateTab !== "All"
            ? filteredTransactionsByState?.[employeeStateTab]?.filter(
                (o1) => o1.state === employeeStateTab
              )
            : filteredTransactionsByState?.[employeeStateTab] || [];

        const dates = [];
        filterData?.forEach((item) => {
          dates.push(new Date(item?.due_date));
        });

        const minDate = dates?.reduce(
          (min, date) => (min < date ? min : date),
          new Date(Infinity)
        );
        const maxDate = dates?.reduce(
          (max, date) => (max > date ? max : date),
          new Date(-Infinity)
        );

        if (!isValid(minDate) || !isValid(maxDate)) {
          setChartData([]);
          setChartKeys([]);
          return;
        }

        const TransactionsByDates = _.groupBy(filterData, ({ due_date }) =>
          format(new Date(due_date), "yyyy-MM")
        );
        let RangeDates = [];
        const monthsDiff = differenceInMonths(maxDate, minDate);
        if (monthsDiff < 11) {
          let count = Math.floor(12 - monthsDiff / 2);
          const startAdjustment = subMonths(minDate, count);
          const endAdjustment = addMonths(maxDate, count);

          // Create a new RangeDates array based on adjusted start and end dates
          const newStartDate =
            startAdjustment < minDate ? startAdjustment : minDate;
          const newEndDate = endAdjustment > maxDate ? endAdjustment : maxDate;

          RangeDates = eachMonthOfInterval({
            start: startOfMonth(newStartDate),
            end: endOfMonth(newEndDate),
          });
        } else {
          RangeDates = eachMonthOfInterval({
            start: minDate,
            end: maxDate,
          });
        }
        const total_key = "staff_total_key";
        let chartKeyArray = [];
        let monthArray = [];

        if (costType === "type") {
          const types = [
            ...new Set(
              filterData?.map(
                (item) =>
                  recurring_rulesById?.[item?.recurring_rule]?.[0]
                    ?.employee_type
              )
            ),
          ];

          RangeDates?.forEach((date) => {
            const formattedDate = format(date, "yyyy-MM");
            const monthData = TransactionsByDates?.[formattedDate] || [];
            const TransactionsByType = _.groupBy(
              monthData,
              (item) =>
                recurring_rulesById?.[item?.recurring_rule]?.[0]?.employee_type
            );

            let obj = { due_date: formattedDate };
            types?.forEach((key) => {
              const total = (TransactionsByType?.[key] || [])?.reduce(
                (a, b) => a + Number(b.gross_value),
                0
              );
              obj[key] = Math.abs(parseFloat(total)?.toFixed(0));
            });
            Object.keys(obj).forEach((key) => {
              if (key !== "due_date" && key !== total_key) {
                obj[total_key] = (obj?.[total_key] || 0) + (obj?.[key] || 0);
              }
            });

            monthArray.push(obj);
          });

          types?.forEach((key) => {
            const type = Constant.EmployeeTypeByTitle?.[key]?.[0];

            chartKeyArray.push({
              key: type?.title || key,
              color: type?.fullColor || theme.palette.color.slate[600],
              dataKey: key,
              stackId: "a",
              fill: type?.fullColor || theme.palette.color.slate[600],
              shade: 800,
            });
          });
        } else if (costType === "department") {
          const categoryParent = getCategoryParentId();

          // const categories = [
          //   ...new Set(
          //     filterData?.map(
          //       (item) =>
          //         categoryParent?.[
          //           recurring_rulesById?.[item?.recurring_rule]?.[0]?.category
          //         ]
          //     )
          //   ),
          // ];
          let categories = [];
          if (employeeDepartment?.length === 0) {
            categories.push("Uncategorized");
          }

          filterData?.forEach((item) => {
            const category =
              selectionCategoriesByID?.[
                recurring_rulesById?.[item?.recurring_rule]?.[0]?.category
              ]?.[0];
            if (category?.parent && employeeDepartment?.length > 0) {
              if (!categories.includes(category?.uuid)) {
                categories.push(category?.uuid);
              }
            } else {
              if (!categories.includes(categoryParent?.[category?.uuid])) {
                categories.push(categoryParent?.[category?.uuid]);
              }
            }
          });

          RangeDates?.forEach((date) => {
            const formattedDate = format(date, "yyyy-MM");
            const monthData = TransactionsByDates?.[formattedDate] || [];
            const TransactionsByCategory = _.groupBy(monthData, (item) =>
              recurring_rulesById?.[item?.recurring_rule]?.[0]?.category
                ? selectionCategoriesByID?.[
                    recurring_rulesById?.[item?.recurring_rule]?.[0]?.category
                  ]?.[0]?.parent && employeeDepartment?.length > 0
                  ? recurring_rulesById?.[item?.recurring_rule]?.[0]?.category
                  : categoryParent?.[
                      recurring_rulesById?.[item?.recurring_rule]?.[0]?.category
                    ]
                : "Uncategorized"
            );

            let obj = { due_date: formattedDate };
            categories?.forEach((key) => {
              const total = (TransactionsByCategory?.[key] || [])?.reduce(
                (a, b) => a + Number(b.gross_value),
                0
              );
              obj[key] = Math.abs(parseFloat(total)?.toFixed(0));
            });
            Object.keys(obj).forEach((key) => {
              if (key !== "due_date" && key !== total_key) {
                obj[total_key] = (obj?.[total_key] || 0) + (obj?.[key] || 0);
              }
            });
            monthArray.push(obj);
          });
          categories?.forEach((category_uuid) => {
            const category = selectionCategoriesByID?.[category_uuid]?.[0];

            chartKeyArray.push({
              key: category?.title || "UnCategorized",
              color: category?.color || theme.palette.color.slate[500],
              dataKey: category_uuid || "UnCategorized",
              stackId: "a",
              fill: category?.color || theme.palette.color.slate[500],
              shade: 800,
            });
          });
        } else if (costType === "scenario") {
          const Scenarios = [
            ...new Set(filterData?.map((item) => item?.scenario)),
          ];

          RangeDates?.forEach((date) => {
            const formattedDate = format(date, "yyyy-MM");
            const monthData = TransactionsByDates?.[formattedDate] || [];
            const TransactionsByScenarios = _.groupBy(
              monthData,
              (item) => item?.scenario
            );

            let obj = { due_date: formattedDate };
            Scenarios?.forEach((key) => {
              const total = (TransactionsByScenarios?.[key] || [])?.reduce(
                (a, b) => a + Number(b.gross_value),
                0
              );
              obj[key] = Math.abs(parseFloat(total)?.toFixed(0));
            });
            Object.keys(obj).forEach((key) => {
              if (key !== "due_date" && key !== total_key) {
                obj[total_key] = (obj?.[total_key] || 0) + (obj?.[key] || 0);
              }
            });

            monthArray.push(obj);
          });

          Scenarios?.forEach((key) => {
            const _scenario = scenarioByTitle?.[key]?.[0];

            chartKeyArray.push({
              key: _scenario?.title || key,
              dataKey: key,
              stackId: "a",
              color: _scenario?.color
                ? theme.palette.color[_scenario?.color][500]
                : theme.palette.color.slate[500],
              fill: _scenario?.color
                ? theme.palette.color[_scenario?.color][500]
                : theme.palette.color.slate[500],
              shade: 800,
            });
          });
        } else {
          const salary_key = "staff_salary_chart_key";

          const cost_type_not_defined_key =
            "staff_cost_type_not_defined_chart_key";
          const recurring_rule = [
            ...new Set(filterData?.map((item) => item?.recurring_rule)),
          ];
          let cost_types = [salary_key, cost_type_not_defined_key];
          recurring_rule?.forEach((id) => {
            const recurring_rule = recurring_rulesById?.[id]?.[0];
            recurring_rule?.value_sets?.forEach((set) => {
              set?.items?.forEach((set) => {
                if (!cost_types?.includes(set?.cost_type) && set?.cost_type) {
                  cost_types.push(set?.cost_type);
                }
              });
            });
          });

          RangeDates?.forEach((date) => {
            const formattedDate = format(date, "yyyy-MM");
            let obj = { due_date: formattedDate };
            const monthData = TransactionsByDates?.[formattedDate] || [];
            const recurring_rule = [
              ...new Set(monthData?.map((item) => item?.recurring_rule)),
            ];

            recurring_rule?.forEach((id) => {
              const rule = recurring_rulesById?.[id]?.[0];
              let gross_value = Number(rule?.value);
              if (Number(rule?.salary_type) === 2) {
                gross_value = Math.abs(gross_value) / 12;
              }
              obj[salary_key] =
                (obj?.[salary_key] || 0) +
                Math.abs(parseFloat(gross_value)?.toFixed(0));
              rule?.value_sets?.forEach((item) => {
                item?.items?.forEach((set) => {
                  const alias = set?.cost_type
                    ? set?.cost_type
                    : cost_type_not_defined_key;
                  let share = 0;
                  if (Number(set.type) === 2) {
                    if (set?.value && Number(set?.value) !== 0) {
                      share = (Number(gross_value) * Number(set?.value)) / 100;
                    }
                  } else {
                    share = set?.value;
                  }

                  if (
                    item?.title === "one_off_cost"
                      ? isSameMonth(new Date(date), new Date(rule?.start_date))
                      : true
                  ) {
                    obj[alias] =
                      (obj?.[alias] || 0) +
                      Math.abs(parseFloat(share)?.toFixed(0));
                  }
                });
              });
            });
            Object.keys(obj).forEach((key) => {
              if (key !== "due_date" && key !== total_key) {
                obj[total_key] = (obj?.[total_key] || 0) + (obj?.[key] || 0);
              }
            });
            monthArray.push(obj);
          });
          const themeColor = theme.palette.color;

          const costTypeColors = [
            themeColor.orange[400],
            themeColor.amber[400],
            themeColor.yellow[400],
            themeColor.lime[400],
            themeColor.green[400],
            themeColor.emerald[400],
            themeColor.teal[400],
            themeColor.cyan[400],
            themeColor.sky[400],
            themeColor.blue[400],
            themeColor.indigo[400],
            themeColor.purple[400],
            themeColor.fuchsia[400],
            themeColor.pink[400],
            themeColor.orange[600],
            themeColor.amber[600],
            themeColor.yellow[600],
            themeColor.lime[600],
            themeColor.green[600],
            themeColor.emerald[600],
            themeColor.teal[600],
            themeColor.cyan[600],
            themeColor.sky[600],
            themeColor.blue[600],
            themeColor.indigo[600],
            themeColor.purple[600],
            themeColor.fuchsia[600],
            themeColor.pink[600],
          ];
          cost_types?.forEach((key, index) => {
            const color =
              key === salary_key
                ? themeColor.violet[400]
                : costTypeColors?.[index] || theme.palette.color.slate[600];
            chartKeyArray.push({
              key: key,
              color: color,
              dataKey: key,
              stackId: "a",
              fill: color,
              shade: 800,
              position: cost_types?.length - index,
            });
          });
        }
        chartKeyArray.push({
          key: total_key,
          color: theme.palette.color.slate[500],
          dataKey: total_key,
          stackId: "a",
          fill: theme.palette.color.slate[500],
          shade: 800,
          hideBar: true,
          isBold: true,
          showTopSeparator: true,
        });
        setChartKeys(chartKeyArray);
        setChartData(monthArray);
      }
    },
    300,
    [
      loading,
      employeeStateTab,
      employeeType,
      employeeDepartment,
      employeeScenarios,
      DeferredSearchText,
      costType,
      staffData,
    ],
    true
  );
  if (!loading && staffData?.length === 0) return null;

  if (!loading && chartData?.length === 0) {
    return (
      <ComponentLoader
        height="18rem"
        key2="employee_cost_widget_no_data_text01"
        placeHolderSx={{
          width: "100%",
        }}
      />
    );
  }

  return (
    <AreaChartView
      loading={loading}
      chartKeys={chartKeys}
      chartData={chartData}
      commonRef={commonRef}
      heading={"Staff cost development"}
      showFilter
      value={costType}
      onChange={onChange}
      filterOptions={[
        {
          value: "department",
          label: "cost_label_department",
          tooltip: "chart_cost_label_department_tooltip",
        },
        {
          value: "scenario",
          label: "cost_label_scenario",
          tooltip: "chart_cost_label_scenario_tooltip",
        },
        {
          value: "type",
          label: "cost_label_type",
          tooltip: "chart_cost_label_type_tooltip",
        },
        {
          value: "cost_type",
          label: "cost_label_cost_type",
          tooltip: "chart_cost_label_cost_type_tooltip",
        },
      ]}
      style={{
        width: "100%",
        height: "18rem",
      }}
    />
  );
};

const StaffGrid = ({ loading }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const payload = useRef(null);
  const defaultData = useRef({ name: "" });
  const [isSubscriptionValid] = useSubscriptions();

  //redux
  const refreshData = useSelector((state) => state.appSlice?.refreshData);
  const staffData = useSelector((state) => state.staffSlice?.staffData);
  const employeeStateTab = useSelector(
    (state) => state.staffSlice?.employeeStateTab
  );

  //state
  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const [anchorElStaffForm, setAnchorElStaffForm] = useState(null);
  const [selectedRowData, setSelectedRowData] = useState([]);
  const [selectedRowIds, setSelectedRowIds] = useState([]);

  const getRowId = useCallback((row) => row?.uuid, []);

  //api
  const getRecurringRules = async () => {
    const results = await getAllTransactionsByParams({
      endpoint: "recurring_rules",
    });
    if (results) {
      dispatch(setRecurring_rules(results));
    }
  };

  const addBatchRecurringRules = async (array) => {
    await APICall("post", EndPoints.recurring_rules + `batch_add/`, array).then(
      async (response) => {
        if (response.status === 201 && response.data) {
          for (const o1 of array) {
            await batchAddValueSets(o1?.uuid, o1?.value_sets);
          }
        }
      }
    );
  };

  const batchAddValueSets = async (recurring_uuid, value_sets) => {
    await APICall(
      "post",
      EndPoints.recurring_rules + `${recurring_uuid}/value_sets/batch_add/`,
      value_sets
    ).then(async (response) => {
      if (response.status === 201 && response.data) {
        const groupData = _.groupBy(response.data, "title");
        for (const o1 of value_sets) {
          if (o1?.items?.length > 0) {
            const uuid = groupData?.[o1?.title]?.[0]?.uuid;
            await batchAddValueSetsItems(recurring_uuid, uuid, o1.items);
          }
        }
      }
    });
  };

  const batchAddValueSetsItems = async (
    recurring_uuid,
    value_set_uuid,
    items
  ) => {
    await APICall(
      "post",
      EndPoints.recurring_rules +
        `${recurring_uuid}/value_sets/${value_set_uuid}/items/batch_add/`,
      items
    ).then((response) => {
      if (response.status === 201 && response.data) {
      }
    });
  };

  const deleteContactById = async (id) => {
    await APICall("delete", EndPoints.customers + `${id}/`).then(
      async (response) => {
        if (response.status === 204 && response) {
          await queryClient.resetQueries({ queryKey: getStaffQueryKey() });
        }
      }
    );
  };

  const addContactApi = async (obj, item) => {
    await APICall("post", EndPoints.customers, obj).then((response) => {
      if (response.status === 201 && response.data) {
        let clonedRecurringRules = [];
        let clonedData = [];

        item?.recurringList?.forEach((o1) => {
          const recurring_rule = {
            ...o1,
            uuid: v4uuid(),
            contact: response.data?.uuid,
            name: response.data?.name,
          };
          clonedRecurringRules?.push(recurring_rule);
          const transactions = item?.TransactionsByRecurringRule?.[o1?.uuid];

          transactions?.forEach((element) => {
            clonedData?.push({
              ...element,
              title: `Copy of ${element?.title}`,
              scenario: element?.scenario,
              state: element?.state,
              note: element?.note,
              due_date: element?.due_date,
              invoice_date: element?.invoice_date,
              tax: element?.tax,
              category: element?.category,
              gross_value: element?.gross_value,
              data_source: element?.data_source,
              cost_unit: element?.cost_unit,
              source: 1,
              position: element?.position,
              currency: element?.currency,
              contact: response.data?.uuid,
              recurring_rule: recurring_rule?.uuid,
            });
          });
        });
        setTimeout(async () => {
          if (clonedRecurringRules?.length > 0) {
            await addBatchRecurringRules(clonedRecurringRules);
          }

          if (clonedData?.length > 0) {
            await addBatchTransactions(clonedData);
          }
          await getRecurringRules();
          await queryClient.invalidateQueries({
            queryKey: getStaffQueryKey(),
          });
          store?.dispatch(setStageLoadingText(null));
          store?.dispatch(setRefreshData(Date.now()));
        }, 500);
      } else {
        store?.dispatch(setStageLoadingText(null));
        store?.dispatch(setRefreshData(Date.now()));
      }
    });
  };

  //functions
  const handleClose = () => {
    setAnchorElMenu(null);
    setAnchorElStaffForm(null);
  };

  const onCloseDelete = () => {
    store?.dispatch(setPopupStatus3(null));
  };

  const onOkDelete = ({ payload }) => {
    const item = payload?.item;
    if (item) {
      store?.dispatch(setPopupStatus3(null));
      store?.dispatch(setStageLoadingText("Staff being Deleted"));
      let RecurringRules = [];
      let Data = [];

      item?.recurringList?.forEach((o1) => {
        RecurringRules?.push(o1?.uuid);
        const transactions = item?.TransactionsByRecurringRule?.[o1?.uuid];

        transactions?.forEach((element) => {
          Data?.push(element?.uuid);
        });
      });
      const recurring_rules = store.getState()?.globalSlice?.recurring_rules;
      const data = recurring_rules?.filter(
        (o1) => !RecurringRules?.includes(o1?.uuid)
      );
      setTimeout(async () => {
        await deleteContactById(item?.contact);
        if (Data?.length > 0) {
          const response = await deleteBatchTransactions(Data);
          if (response) {
            store?.dispatch(setRefreshData(Date.now()));
            store?.dispatch(setRecurring_rules(data));
          }
        }
        store?.dispatch(setStageLoadingText(null));
      }, 500);
    }
  };

  const getUniqName = (staffData, count, name, oName) => {
    const isFound = staffData?.find((o1) => o1?.name === name);
    if (isFound) {
      count = count + 1;
      return getUniqName(staffData, count, `${oName}(${count})`, oName);
    } else {
      return name;
    }
  };

  const onClickItem = async (e, value, item) => {
    handleClose();
    if (value === 1) {
      if (isSubscriptionValid({ showMessage: true, type: "employee" })) {
        const data_source =
          store?.getState()?.boardSlice?.dataSetData?.internal_data_source;
        const params = payload.current;
        const cardItem = {
          ...params.row?.item,
          recurring_rule: null,
          data_source,
          due_date: null,
          gross_value: "-0.00",
        };
        let recurringItem = params.row?.recurringList?.[0]
          ? { ...params.row?.recurringList?.[0] }
          : {
              contact: params?.row?.uuid,
              name: params?.row?.name,
              employee_role: "",
              salary_type: 2,
              recurring_type: "employee",
              period: 12,
              repetition: 1,
              start_date: format(new Date(), "yyyy-MM-dd"),
              end_date: format(addMonths(new Date(), 12), "yyyy-MM-dd"),
            };
        recurringItem.value = 0;
        recurringItem.value_sets = [
          {
            base_value: 0,
            base_value_name: "ongoing_cost",
            title: "ongoing_cost",
            items: [],
          },
          {
            base_value: 0,
            base_value_name: "one_off_cost",
            title: "one_off_cost",
            items: [],
          },
        ];

        store?.dispatch(
          setTransactionsOverlayStatus({
            open: true,
            overlay_type: "drawer_modal_edit_form",
            anchor: "right",
            payload: {
              modalType: "add",
              transaction_type: "expense",
              updateOverlay: updateOverlay,
              hideEditFormTabBar: true,
              item: cardItem,
              recurringItem,
            },
          })
        );
      }
    }
    if (value === 2) {
      if (isSubscriptionValid({ showMessage: true, type: "employee" })) {
        let count = 1;
        const staffData = await queryClient.getQueryData(getStaffQueryKey());
        let name = await getUniqName(
          staffData,
          count,
          `Copy of ${item?.name}`,
          `Copy of ${item?.name}`
        );
        const dataset = store?.getState()?.boardSlice?.dataSetData?.uuid;
        store?.dispatch(setStageLoadingText("Staff Transactions being Copied"));
        await addContactApi(
          {
            name,
            type: 3,
            state: 1,
            dataset,
          },
          item
        );
      }
    }
    if (value === 3) {
      store?.dispatch(
        setPopupStatus3({
          id: "simple-popper",
          open: true,
          overlay_type: "delete",
          onConfirm: onOkDelete,
          onClose: onCloseDelete,
          payload: {
            title: i18n?.t("Delete"),
            message: i18n?.t("Are you sure, you want to delete this staff?"),
            item: item,
          },
        })
      );
    }
    if (value === 4) {
      defaultData.current = { contact: item?.uuid, name: item?.name };
      setAnchorElStaffForm(true);
    }
  };

  const onClick = (e, params) => {
    payload.current = params;
    e.stopPropagation();
    setAnchorElMenu(e.currentTarget);
  };

  const updateOverlay = () => {
    dispatch(setRefreshData(Date.now()));
  };

  const onSelectionModelChange = (ids) => {
    const selectedIDs = new Set(ids);
    const updateData = staffData?.filter((row) =>
      selectedIDs.has(row?.uuid?.toString())
    );

    setSelectedRowIds(ids);
    let data = [];
    updateData?.forEach((item) => {
      item?.recurringList?.forEach((o1) => {
        data.push(o1);
      });
    });
    setSelectedRowData(data);
  };

  const onRowClick = async (params) => {
    if (params.row?.item && params?.row?.recurringList?.length === 1) {
      dispatch(
        setTransactionsOverlayStatus({
          open: true,
          overlay_type: "drawer_modal_edit_form",
          anchor: "right",
          payload: {
            modalType: "edit",
            transaction_type: "expense",
            updateOverlay: updateOverlay,
            hideEditFormTabBar: true,
            item: params.row?.item,
            isStaffView: true,
          },
        })
      );
    }

    if (params?.row?.recurringList?.length > 1) {
      dispatch(
        setPopupStatus2({
          open: true,
          overlay_type: "drawer_modal",
          anchor: "right",
          payload: {
            hideAccessInListViewFilter: true,
            overlayTab: "category",
            hideScenarioChain: true,
            cell: {
              contact: params?.row?.contact,
              transaction_type: "expense",
              withUrl: true,
              hideEditFormTabBar: true,
              isStaffView: true,
              staffName: params?.row?.name,
            },
          },
        })
      );
    }
  };

  const columns = useMemo(
    () => [
      {
        field: "title",
        headerName: t("Employee Name"),
        flex: 1.4,
        renderCell: TitleCell,
        valueGetter: (value, row) => {
          return value?.row?.name;
        },
      },
      {
        field: "role",
        headerName: t("Employee Role"),
        flex: 1.2,
        renderCell: JobCell,
      },
      {
        field: "type",
        headerName: t("Employee Type"),
        flex: 1.1,
        renderCell: TypeCell,
      },
      {
        field: "category",
        headerName: t("Employee Department"),
        flex: 1.1,
        renderCell: DepartmentCell,
      },
      {
        field: "state",
        headerName: t("Employee State"),
        flex: 1.1,
        renderCell: StateCell,
      },
      {
        field: "scenario",
        headerName: t("Scenario"),
        flex: 1.1,
        renderCell: ScenarioCell,
      },
      {
        field: "start_date",
        headerName: t("staff_list_lable_start_date"),
        flex: 0.5,
        renderCell: DateRangeCell,
      },
      {
        field: "end_date",
        headerName: t("staff_list_lable_end_date"),
        flex: 0.5,
        renderCell: DateRangeCell,
      },
      {
        field: "salaryThisMonth",
        headerName: t("staff_list_lable_gross_salary"),
        flex: 0.9,
        renderCell: CostCell,
      },
      {
        field: "costThisMonth",
        headerName: t("staff_list_lable_monthly_cost"),
        description: (
          <Translate i18nkey="staff_list_lable_monthly_cost_tooltip" />
        ),
        flex: 0.9,
        renderCell: CostCell,
      },
      {
        field: "costThisYear",
        headerName: t("staff_list_lable_all_cost"),
        description: <Translate i18nkey="staff_list_lable_all_cost_tooltip" />,
        flex: 0.9,
        renderCell: CostCell,
      },
      {
        field: "action",
        headerName: "",
        flex: 0.1,
        renderCell: (params) => {
          return (
            <IconButton
              aria-label="more"
              id="long-button"
              aria-haspopup="true"
              onClick={(e) => onClick(e, params)}
              sx={{ background: "transparent !important", mr: 1 }}
            >
              <MoreVertIcon
                sx={{
                  color: "color.slate.600",
                }}
              />
            </IconButton>
          );
        },
      },
    ],
    [t]
  );

  useDebounce(
    () => {
      setSelectedRowData([]);
      setSelectedRowIds([]);
    },
    300,
    [refreshData],
    true
  );

  useDebounce(
    () => {
      if (selectedRowData?.length > 0) {
        dispatch(
          setPopupStatus5({
            overlay_type: "all",
            payload: { selectedRowData },
          })
        );
      } else {
        dispatch(setPopupStatus5(null));
      }
    },
    500,
    [selectedRowData],
    true
  );

  if (loading || !staffData || staffData?.length === 0) {
    return (
      <ComponentLoader
        loading={loading}
        isSkeleton
        skeletonCount={3}
        placeHolderHeight="4.5rem"
        placeHolderWidth="35%"
        skeltonSx={{
          transform: "scale(1,0.9)",
        }}
        sx={{
          m: 8,
          width: loading ? "auto" : "42%",
          height: loading ? "10rem" : "65%",
        }}
        key1={`staff_${employeeStateTab}_list_no_data_text_01`}
        key2={`staff_${employeeStateTab}_list_no_data_text_02`}
      />
    );
  }

  return (
    <div
      style={{
        width: "100%",
        height: "fit-content",
        paddingInline: "2rem",
      }}
    >
      {Boolean(anchorElMenu) ? (
        <MenuView
          anchorEl={anchorElMenu}
          open={Boolean(anchorElMenu)}
          options={menuOptions}
          handleClose={handleClose}
          onClickItem={onClickItem}
          item={payload.current?.row}
        />
      ) : null}

      {Boolean(anchorElStaffForm) ? (
        <StaffEditForm
          open={Boolean(anchorElStaffForm)}
          handleClose={handleClose}
          defaultData={defaultData}
        />
      ) : null}
      <div style={{ display: "flex", width: "100%", marginBottom: "1rem" }}>
        <BulkActionView tab={"staff"} />
      </div>

      <StyledDataGrid
        rows={staffData || []}
        columns={columns}
        getRowId={getRowId}
        disableColumnFilter
        disableColumnMenu
        disableSelectionOnClick
        disableRowSelectionOnClick
        disableCellSelectionOnClick
        disableDensitySelector
        checkboxSelection
        selectionModel={selectedRowIds}
        onSelectionModelChange={onSelectionModelChange}
        autoHeight
        hideFooter
        onRowClick={onRowClick}
        sx={{ border: 0 }}
      />
    </div>
  );
};

//component
const TitleCell = (params) => {
  const item = params?.row;

  return (
    <div style={{ display: "flex", alignItems: "center", flex: 1 }}>
      <Tooltip
        title={
          item?.recurringList?.length === 0
            ? t("Please add first employee planning")
            : item?.name
        }
        arrow
        placement="top"
      >
        <Typography
          variant="subtitle1"
          fontWeight={"fontWeightMediumBold"}
          color="color.slate.700"
          sx={{
            maxWidth: item?.recurringList?.length > 1 ? "9rem" : "11rem",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            textAlign: "left",
          }}
        >
          {item?.name}
        </Typography>
      </Tooltip>

      {item?.recurringList?.length > 1 ? (
        <CountView
          tooltip={t("staff_seq_count", {
            count: item?.recurringList?.length,
          })}
          count={item?.recurringList?.length || 0}
          color={
            item?.recurringList?.length === 0
              ? theme.palette.color.red[400]
              : theme.palette.color.slate[400]
          }
          bg={
            item?.recurringList?.length === 0
              ? theme.palette.color.red[100]
              : theme.palette.color.slate[100]
          }
          style={{
            width: "1.7rem",
          }}
        />
      ) : null}
    </div>
  );
};

const JobCell = (params) => {
  const item = params?.row;
  const uniqRoles = item?.roleList;

  if (item?.count !== 0 && uniqRoles?.length === 0) {
    return <EmptyChip title={"no_role_defined"} tooltip={""} />;
  }
  if (uniqRoles?.length > 2) {
    const roleListTitle = uniqRoles
      ?.map((item) => Constant?.EmployeeRoleByTitle?.[item]?.[0]?.display_name)
      ?.join(", ");

    return <MultipleChip tooltip={roleListTitle} />;
  }

  return (
    <Stack direction="row">
      {uniqRoles?.map((key) => {
        const role = Constant?.EmployeeRoleByTitle?.[key]?.[0];
        const title = role ? t(role?.display_name) : key;
        return (
          <Fragment key={key}>
            <Tooltip title={title} arrow placement="top">
              <Box
                backgroundColor={`color.slate.100`}
                sx={{
                  px: 2,
                  py: 0.5,
                  mr: 2,
                  borderRadius: theme.borderRadius.borderRadiusMD,
                }}
              >
                <Typography
                  variant="caption"
                  fontWeight={"fontWeightMediumBold"}
                  color={`color.slate.600`}
                  sx={{
                    maxWidth: "11rem",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {truncate(title, uniqRoles?.length > 1 ? 8 : 20)}
                </Typography>
              </Box>
            </Tooltip>
          </Fragment>
        );
      })}
    </Stack>
  );
};

const TypeCell = (params) => {
  const item = params?.row;
  const uniqTypes = item?.typeList;

  if (uniqTypes?.length > 2) {
    const typeListTitle = uniqTypes
      ?.map((item) => Constant?.EmployeeTypeByTitle?.[item]?.[0]?.title)
      ?.join(", ");

    return <MultipleChip tooltip={typeListTitle} />;
  }

  return (
    <Stack direction="row">
      {uniqTypes?.map((key) => {
        const type = Constant?.EmployeeTypeByTitle?.[key]?.[0];
        const title = type ? t(type?.title) : key;
        return (
          <Fragment key={key}>
            <Tooltip title={title} arrow placement="top">
              <Box
                backgroundColor={type?.fullColor || `color.slate.200`}
                sx={{
                  px: 2,
                  py: 0.5,
                  mr: 2,
                  borderRadius: theme.borderRadius.borderRadiusMD,
                }}
              >
                <Typography
                  variant="caption"
                  fontWeight={"fontWeightMediumBold"}
                  color={type?.fullColor ? `color.white` : `color.slate.800`}
                  sx={{
                    maxWidth: "11rem",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {truncate(title, uniqTypes?.length > 1 ? 8 : 17)}
                </Typography>
              </Box>
            </Tooltip>
          </Fragment>
        );
      })}
    </Stack>
  );
};

const DepartmentCell = (params) => {
  const selectionCategoriesByID = useSelector(
    (state) => state.categorySlice.selectionCategoriesByID
  );
  const item = params?.row;
  const uniqCategory = item?.departmentList;

  if (uniqCategory?.length > 2) {
    const categoryListTitle = uniqCategory
      ?.map((item) => selectionCategoriesByID?.[item]?.[0]?.title)
      ?.join(", ");
    return <MultipleChip tooltip={categoryListTitle} />;
  }

  return (
    <Stack direction="row">
      {uniqCategory?.map((key, index) => {
        const category = selectionCategoriesByID?.[key]?.[0];
        const title = category?.immutable
          ? t(category?.title)
          : category?.title;
        return (
          <Fragment key={key}>
            <Tooltip title={title} arrow placement="top">
              <Box
                backgroundColor={
                  index < 1 && category?.color
                    ? alpha(category?.color, 0.1)
                    : `color.slate.100`
                }
                sx={{
                  px: 2,
                  py: 0.5,
                  mr: 2,
                  borderRadius: theme.borderRadius.borderRadiusMD,
                  border: `1px solid ${index < 1 && category?.color ? alpha(category?.color, 0.1) : theme.palette.color.slate[300]}`,
                }}
              >
                <Typography
                  variant="caption"
                  fontWeight={"fontWeightMediumBold"}
                  color={index < 1 ? category?.color : `color.slate.400`}
                  sx={{
                    maxWidth: "11rem",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {truncate(title, uniqCategory?.length > 1 ? 8 : 17)}
                </Typography>
              </Box>
            </Tooltip>
          </Fragment>
        );
      })}
    </Stack>
  );
};

const StateCell = (params) => {
  const item = params?.row;
  const uniqState = item?.stateList;

  if (uniqState?.length > 2) {
    const stateListTitle = uniqState
      ?.map((item) => t(`Employee-${item}`))
      ?.join(", ");
    return <MultipleChip tooltip={stateListTitle} />;
  }

  return (
    <Stack direction="row">
      {uniqState?.map((key, index) => {
        const color =
          key === "Planned" ? "emerald" : key === "Ignored" ? "blue" : "slate";
        const title = t(`Employee-${key}`);
        return (
          <Fragment key={key}>
            <Tooltip title={title} arrow placement="top">
              <Box
                backgroundColor={`color.${color}.100`}
                sx={{
                  px: 2,
                  py: 0.5,
                  mr: 2,
                  borderRadius: theme.borderRadius.borderRadiusMD,
                  border: `1px solid ${theme.palette.color?.[color]?.[100]}`,
                }}
              >
                <Typography
                  variant="caption"
                  fontWeight={"fontWeightMediumBold"}
                  color={`color.${color}.600`}
                  sx={{
                    maxWidth: "11rem",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {truncate(title, uniqState?.length > 1 ? 8 : 20)}
                </Typography>
              </Box>
            </Tooltip>
          </Fragment>
        );
      })}
    </Stack>
  );
};

const ScenarioCell = (params) => {
  const item = params?.row;
  const uniqScenarios = item?.scenarioList;
  if (uniqScenarios?.length > 2) {
    const scenarioListTitle = uniqScenarios
      ?.map((item) => (item === "Base" ? t("Base") : item))
      ?.join(", ");

    return <MultipleChip tooltip={scenarioListTitle} />;
  }
  return (
    <Stack direction="row">
      {uniqScenarios?.map((key, index) => {
        return (
          <Fragment key={key}>
            <ScenarioChip
              title={key}
              // hideBorder
              hideIcon
              highlightIcon={false}
              showClickCursor
              width={uniqScenarios?.length > 1 ? "3.75rem" : "7rem"}
              height="auto"
              fontSize="0.65rem"
              shade="100"
              sx={{
                py: 1,
                mr: 2,
                borderRadius: theme.borderRadius.borderRadiusMD,
                "& .MuiChip-label": {
                  fontWeight: "fontWeightMediumBold",
                },
              }}
            />
          </Fragment>
        );
      })}
    </Stack>
  );
};

const DateRangeCell = (params) => {
  const item = params?.row;

  return (
    <Tooltip
      title={formatDateToLocal(item?.[params?.field], "MMM yy")}
      arrow
      placement="top"
    >
      <Typography
        variant="subtitle2"
        fontWeight={"fontWeightMediumBold"}
        color="color.slate.600"
      >
        {formatDateToLocal(item?.[params?.field], "MMM yy")}
      </Typography>
    </Tooltip>
  );
};

const CostCell = (params) => {
  const item = params?.row;
  if (item?.count === 0) {
    return "";
  }
  return (
    <Typography
      variant="body2"
      fontWeight={"fontWeightMediumBold"}
      color="color.slate.600"
      sx={{
        maxWidth: "11rem",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      }}
    >
      {formatAmount({ amount: params?.value })}
    </Typography>
  );
};

const StaffEditForm = ({ defaultData, handleClose, open }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  //api
  const updateContactByID = async (id, obj) => {
    await APICall("patch", EndPoints.customers + `${id}/`, obj)
      .then((response) => {
        if (response.status === 200 && response.data) {
          queryClient.invalidateQueries({ queryKey: getStaffQueryKey() });
          handleClose();
        }
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      });
  };

  //functions
  const onSubmit = async (values) => {
    setLoading(true);
    updateContactByID(defaultData?.current?.contact, values);
  };

  const formik = useFormik({
    initialValues: {
      name: defaultData?.current?.name || "",
    },
    onSubmit: onSubmit,
    validationSchema: Yup.object({
      name: Yup?.string()?.trim()?.required(t("Name is required")),
    }),
  });

  return (
    <CustomModal
      heading={t("Edit Staff Name")}
      onClose={handleClose}
      onAdd={formik.handleSubmit}
      open={open}
      loadingAdd={loading}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "40rem",
          height: "fit-content",
          p: "2.5rem",
        }}
      >
        <TextField
          size={"small"}
          autoComplete="given-name"
          name="name"
          required
          fullWidth
          id="name"
          label={t("Name")}
          autoFocus
          value={formik.values?.name}
          onChange={formik.handleChange}
          error={formik.touched?.name && Boolean(formik.errors?.name)}
          helperText={formik.touched?.name && formik.errors?.name}
          sx={inputStyle}
        />
      </Box>
    </CustomModal>
  );
};

const MultipleChip = ({ tooltip }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Tooltip title={tooltip} arrow placement="top">
      <Box
        // backgroundColor={`color.slate.50`}
        sx={{
          px: 2,
          py: 0.5,
          mr: 2,
          borderRadius: theme.borderRadius.borderRadiusMD,
          border: `1px solid ${theme.palette.color.slate[200]}`,
        }}
      >
        <Typography
          variant="caption"
          fontWeight={"fontWeightMediumBold"}
          color={`color.slate.600`}
          sx={{
            maxWidth: "11rem",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {t("Multiple")}
        </Typography>
      </Box>
    </Tooltip>
  );
};

const EmptyChip = ({ tooltip, title }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Tooltip title={tooltip} arrow placement="top">
      <Box
        sx={{
          px: 2,
          py: 0.5,
          mr: 2,
          borderRadius: theme.borderRadius.borderRadiusMD,
          border: `1px solid ${theme.palette.color.slate[200]}`,
        }}
      >
        <Typography
          variant="caption"
          fontWeight={"fontWeightMediumBold"}
          color={`color.slate.600`}
          sx={{
            maxWidth: "11rem",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {t(title)}
        </Typography>
      </Box>
    </Tooltip>
  );
};

const BulkActionView = ({ tab }) => {
  const popupStatus5 = useSelector(
    (state) => state?.datasetSlice?.popupStatus5
  );

  if (!popupStatus5?.payload) {
    return null;
  }
  const { selectedRowData } = popupStatus5?.payload;
  return (
    <>
      {popupStatus5?.overlay_type === "all" &&
      popupStatus5?.payload?.selectedRowData?.length > 0 ? (
        <TransactionViewSettingPopOver
          selectedRowData={selectedRowData}
          tab={tab}
        />
      ) : null}
    </>
  );
};
