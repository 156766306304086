import {
  circularProgressClasses,
  CircularProgress,
  useMediaQuery,
  ListSubheader,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
  Divider,
  Button,
  Dialog,
  Stack,
  Grid,
  List,
  Box,
} from "@mui/material";
import {
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
  useRef,
} from "react";
import UnarchiveRoundedIcon from "@mui/icons-material/UnarchiveRounded";
import ModeEditRoundedIcon from "@mui/icons-material/ModeEditRounded";
import ArchiveRoundedIcon from "@mui/icons-material/ArchiveRounded";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DownloadingIcon from "@mui/icons-material/Downloading";
import { useLocation, useNavigate } from "react-router";
import CloudOffIcon from "@mui/icons-material/CloudOff";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import {
  deleteDataSource,
  setDataSource,
  setAccounts,
} from "../../../store/slices/global";
import { getIntegrationCard, getFinApiQueryKey } from "../../../Helper/data";
import FinbanInfoBulb from "../../../components/FInbanInfo/FinbanInfoBulb";
import SubscriptionBadge from "../../../components/SubscriptionBadge";
import { setPopupStatus3 } from "../../../store/slices/datasets";
import useSubscriptions from "../../../hooks/useSubscriptions";
import { GlobalContext } from "../../../GlobalContextWrapper";
import Android12Switch from "../../../components/A12Switch";
import MenuView from "../../../components/Overlay/MenuView";
import useStatusHook from "../../../hooks/useStatusHook";
import Translate from "../../../hooks/HOC/Translate";
import EndPoints from "../../../APICall/EndPoints";
import { Color, Constant } from "../../../Helper";
import DSConnections from "./DSConnections";
import { queryClient } from "../../../App";
import APICall from "../../../APICall";
import CardUi from "./CardUI";

const AvailableView = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();

  const dSObj = useRef(null);
  const dsRef = useRef(null);
  const itemId = useRef(null);
  const payload = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const [isSubscriptionValid] = useSubscriptions();
  const theme = useTheme();
  const s1440 = useMediaQuery(theme.breakpoints.up("s1440"));

  //redux
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);
  const dataSource = useSelector((state) => state.globalSlice.dataSource);
  const email = useSelector((state) => state.settingsSlice?.profile?.email);
  const isFinbanAccount = email?.includes("@finban.io");
  //state
  const [tab, setTab] = useState(props?.defaultSection || 0);
  const [ds_in_process, setDs_in_process] = useState(null);
  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const [open, setOpen] = useState(false);

  const isActiveCard = tab === 10;
  const ActiveData = useMemo(() => {
    const stateNumber = isActiveCard ? [1, 3] : [2];

    let filterData = [];
    dataSource?.forEach((o1) => {
      if (
        !o1.internal_dataset &&
        stateNumber.includes(o1.state) &&
        (props?.clickable && dataSetData?.uuid
          ? !o1.datasets?.find((o2) => o2.dataset === dataSetData?.uuid)
          : true)
      ) {
        const card = getIntegrationCard({ ds: o1 });
        filterData.push({ ...card, ds: o1 });
      }
    });

    return filterData;
  }, [dataSetData, dataSource, isActiveCard, props?.clickable]);

  const DATA =
    tab === 10 || tab === 11
      ? ActiveData || []
      : [...(Constant.IntegrationsSourceData[tab] || [])]
          ?.map((o1) => {
            return {
              ...o1,
              hide:
                isFinbanAccount && o1?.name === "Google Sheets API 2"
                  ? false
                  : o1.hide,
              // disabled: !beta_enabled
              //   ? o1?.name === "Excel import"
              //     ? true
              //     : o1?.disabled
              //   : o1?.disabled,
            };
          })
          ?.sort((a, b) => {
            if (a.disabled === undefined) return -1;
            if (b.disabled === undefined) return 1;
            return b.disabled - a.disabled;
          });

  useEffect(() => {
    getDataSource();
    getAccountsApi();
  }, []);

  useEffect(() => {
    if (location?.state?.isArchived) {
      setTab(11);
    }
    if (location?.state?.isActive) {
      setTab(10);
    }
  }, [location]);

  useEffect(() => {
    const gisLoadedScript = document.createElement("script");
    gisLoadedScript.src = "https://accounts.google.com/gsi/client";
    document.body.appendChild(gisLoadedScript);
  }, []);

  const getAccountsApi = async () => {
    await APICall("get", EndPoints.accounts).then((response) => {
      if (response.status === 200 && response.data) {
        let data = response.data.results;
        dispatch(setAccounts(data));
      }
    });
  };

  const getDataSource = async () => {
    await APICall("get", EndPoints.integrations).then((response) => {
      if (response.status === 200 && response.data) {
        dispatch(setDataSource(response.data.results));
      }
    });
  };

  const deleteDataSourceIdApi = async (id) => {
    await APICall("delete", EndPoints.integrations + `${id}/`).then(
      (response) => {
        if (response.status === 204 && response) {
          dispatch(deleteDataSource(id));
          getAccountsApi();
        }
      }
    );
  };

  const disconnectAccount = async (DS, obj) => {
    const type = DS.type === 12 ? "finapi" : "external";
    await APICall(
      "post",
      EndPoints.integrations + `${DS?.uuid}/${type}/disconnect/`,
      obj
    ).then((response) => {
      if (response.status === 200 && response.data) {
        getDataSource();
        resetDS(DS);
      }
    });
  };

  const addDataSourceDataset = async (dataset_id, obj, dsId) => {
    await APICall(
      "post",
      EndPoints.datasets + `${dataset_id}/data_sources/`,
      obj
    ).then((response) => {
      if (response.status === 201 && response.data) {
        resetkanban(dataset_id);
        if (dsId) {
          getDataSource();
          props.isDSChanged.current = true;
        }
      }
      if (response.status === 400 && response.data) {
        if (response?.data?.data_source?.length > 0) {
          enqueueSnackbar(t(response?.data?.data_source?.[0]), {
            variant: "warning",
            autoHideDuration: 5000,
            preventDuplicate: true,
          });
        }
      }
    });
  };

  const removeDataSourceToDataSet = async (dataset_id, integration_id) => {
    await APICall(
      "delete",
      EndPoints.datasets + `${dataset_id}/data_sources/${integration_id}/`
    ).then((response) => {
      if (response.status === 204) {
      }
    });
  };

  //functions
  const onClickMenu = (item) => {
    if (tab !== item?.value) {
      setTab(item?.value);
    }
  };

  const handleClickCard = (card) => {
    dsRef.current?.handleClickCard(card);
  };

  const resetkanban = () => {
    global.allowFetch = { Inflow: [], Outflow: [] };
    let options = {
      predicate: (query) =>
        query.queryKey[0] === "transactions" || query.queryKey[0] === "List",
    };
    queryClient.resetQueries(options, {
      cancelRefetch: true,
    });
  };

  const resetDS = (ds) => {
    if ([12, 19].includes(ds.type)) {
      let options = {
        predicate: (query) =>
          query.queryKey[0] === "integrations" &&
          query.queryKey[1]?.data_source === ds?.uuid &&
          query.queryKey[1]?.apiType ===
            (ds.type === 12
              ? "data_source_finapi_connections"
              : "data_source_external_connections"),
      };
      queryClient.resetQueries(options, { cancelRefetch: true });
    }
  };

  const onOk = () => {
    dispatch(setPopupStatus3(null));
    dispatch(deleteDataSource(itemId.current));
    enqueueSnackbar(t("Integration Deleted Successfully"));
    deleteDataSourceIdApi(itemId.current);
  };

  const onClickDataSet = (e, ds) => {
    dSObj.current = { ds: ds };
    setOpen("dataset_selection");
  };

  const onClickDelete = (e, acc) => {
    itemId.current = acc.uuid;
    dispatch(
      setPopupStatus3({
        open: true,
        overlay_type: "delete",
        onConfirm: onOk,
        showConfirmCheckBox: true,
        confirmCheckBoxLabel: "delete_integrations_confirm_check_text",
        payload: {
          title: t("Delete"),
          message: <Translate i18nkey={`delete_integrations_warn`} />,
          confirmText: t("Ok"),
        },
      })
    );
  };

  const onClickFetchNewAccount = (e, DS) => {
    dsRef.current?.fetchNewTransaction(DS);
  };

  const onClickReFetchNewAccount = (e, DS) => {
    dsRef.current?.fetchAllTransaction(DS);
  };

  const onClickArchive = async (e, DS, acc) => {
    dsRef.current?.archiveDS(DS);
  };

  const onClickDisconnect = async (e, DS, acc) => {
    await APICall("get", EndPoints.integrations + `${DS?.uuid}/`).then(
      async (response) => {
        if (response.status === 200 && response.data) {
          const ds = response.data;
          if (ds?.is_connected) {
            if (ds?.datasets?.length > 0) {
              ds?.datasets?.forEach(async (connected_data_source_obj) => {
                await removeDataSourceToDataSet(
                  connected_data_source_obj?.dataset,
                  connected_data_source_obj?.uuid
                );
              });
            }
            let dataSourceObj = dataSource?.find((o1) => o1.uuid === ds?.uuid);
            let obj = {
              title: dataSourceObj?.title,
              note: dataSourceObj?.note,
              alias: dataSourceObj?.alias,
              type: dataSourceObj?.type,
              state: 3,
            };
            await disconnectAccount(ds, obj);
          } else {
            await getDataSource();
          }
        }
      }
    );
  };

  const onClickEditAccount = (e, ds, acc, card) => {
    dsRef.current?.onClickEditIntegration(ds, acc, card);
  };

  const onClickLink = (item) => {
    setTab(item?.value);
  };

  const onClickCard = (e, ds) => {
    if (isSubscriptionValid({ showMessage: true })) {
      let obj = {
        active: true,
        dataset: dataSetData?.uuid,
        data_source: ds?.uuid,
      };
      addDataSourceDataset(dataSetData?.uuid, obj, ds?.uuid);
    }
  };

  const onClickShowMoreActions = useCallback(
    (e, ds, account, card) => {
      payload.current = {
        ds,
        account,
        card,
      };
      setAnchorElMenu(e.currentTarget);
    },
    [setAnchorElMenu]
  );

  const onClickErrorLogs = (ds, error) => {
    dsRef?.current?.onClickErrorLogs(ds, error);
  };

  const DATAVIEW = useMemo(
    () =>
      DATA?.map((card) => {
        // if (card?.hide) {
        //   return null;
        // }
        return (
          <CardUi
            key={card?.ds?.uuid || card?.name}
            isLoading={ds_in_process === card?.name}
            card={card}
            clickable={props?.clickable}
            onClickShowMoreActions={onClickShowMoreActions}
            handleClickCard={handleClickCard}
            onClickDelete={onClickDelete}
            onClickDataSet={onClickDataSet}
            onClickCard={onClickCard}
            onClickErrorLogs={onClickErrorLogs}
          />
        );
      }),
    [DATA, ds_in_process, email, props?.clickable]
  );

  return (
    <Stack sx={{ width: "fit-content" }}>
      <DSConnections
        ref={dsRef}
        setDs_in_process={setDs_in_process}
        setTab={setTab}
        {...props}
      />

      <DataSetSelectionModal
        open={open}
        setOpen={setOpen}
        dSObj={dSObj}
        resetkanban={resetkanban}
        resetDS={resetDS}
      />
      {Boolean(anchorElMenu) ? (
        <MenuWrapper
          anchorElMenu={anchorElMenu}
          setAnchorElMenu={setAnchorElMenu}
          isActiveCard={isActiveCard}
          payload={payload.current}
          onClickEditAccount={onClickEditAccount}
          onClickDisconnect={onClickDisconnect}
          onClickArchive={onClickArchive}
          onClickFetchNewAccount={onClickFetchNewAccount}
          onClickReFetchNewAccount={onClickReFetchNewAccount}
          onClickDelete={onClickDelete}
        />
      ) : null}
      <Box
        sx={{
          position: "relative",
          display: "inline-flex",
          justifyContent: "space-between",
          alignItems: "center",
          "& .MuiAlert-message": {
            display: "inline-flex",
            p: 0,
            ml: "0.5rem",
            fontSize: "1rem",
            alignItems: "center",
          },
        }}
      >
        <Typography
          variant="h5"
          color="color.slate.700"
          fontWeight={"fontWeightMediumBold"}
          sx={{ mr: "1.5rem" }}
        >
          {props?.clickable && props?.type === "assign"
            ? t("Click to assign the integration")
            : t("Integrations")}
        </Typography>

        <Stack
          direction="row"
          alignItems={"center"}
          sx={{ position: "relative" }}
        >
          {s1440 ? (
            <>
              <SubscriptionBadge
                type="data_source"
                ds_type={[1]}
                sx={{ mr: "1rem" }}
              />
              <SubscriptionBadge
                type="data_source"
                ds_type={[10, 11, 12, 13, 14, 15, 16, 17, 18]} //banks
                sx={{ mr: "1rem" }}
              />
              <SubscriptionBadge
                type="data_source"
                ds_type={[19]}
                sx={{ mr: "1rem" }}
              />
            </>
          ) : null}
          <Button
            href={"mailto:support@finban.io"}
            color="primary"
            variant="contained"
            disableRipple
            size="small"
            sx={{
              textTransform: "capitalize",
              fontWeight: theme.typography.fontWeightMediumBold,
              color: theme.palette.primary.main,
              boxShadow: theme.boxShadow,
              mr: "1rem",
              px: "0.5rem",
              py: "0.25rem",
              backgroundColor: theme.palette.primary[100],
              border: `1px solid ${theme.palette.primary[300]}`,
              "&:hover": {
                backgroundColor: theme.palette.primary.light,
              },
            }}
          >
            {t("Submit request")}
          </Button>
          <FinbanInfoBulb
            url={"https://finban.io/documentation/integrationen/"}
          />
        </Stack>
      </Box>
      <Typography
        variant="subtitle2"
        color="color.description"
        fontWeight={"fontWeightMedium"}
        sx={{
          my: "0.5rem",
          mb: "1.5rem",
        }}
      >
        {t("available_integrations_description")}
      </Typography>

      <Divider
        sx={{
          mb: "1rem",
        }}
      />

      <Stack
        direction="row"
        justifyContent={"space-between"}
        sx={{
          pb: "3rem",
          width: {
            xs: `${(props?.type === "assign" ? -1 : 16) + 33}rem`,
            s1440: `${(props?.type === "assign" ? -1 : 16) + 33 + 33}rem`,
            s2400: `${(props?.type === "assign" ? -1 : 16) + 33 + 33 + 33}rem`,
          }, //xs:16+33  and  s1440:16+33+33  and  s2400:16+33+33+33
        }}
      >
        <Stack sx={{ gap: "0.5rem" }}>
          {props?.clickable ? null : (
            <LinkView
              onClickLink={onClickLink}
              tab={tab}
              item={{ value: 10, label: "Active" }}
            />
          )}
          {props?.type === "assign" && props?.clickable
            ? null
            : Constant.IntegrationsTypeOptions?.map((item) => {
                return (
                  <Typography
                    key={item?.value}
                    onClick={() => onClickMenu(item)}
                    variant="subtitle2"
                    fontWeight={"fontWeightBold"}
                    sx={{
                      cursor: "pointer",
                      lineHeight: "normal",
                      width: "16rem",
                      borderRadius: theme.borderRadius.main,
                      backgroundColor:
                        tab === item?.value
                          ? theme.palette.color.slate[200]
                          : "transparent",
                      color:
                        theme.palette.color.slate[
                          tab === item?.value ? 700 : 500
                        ],
                      p: "0.75rem",
                      "&:hover": {
                        backgroundColor: theme.palette.color.slate[200],
                      },
                    }}
                  >
                    {t(item?.label)}
                  </Typography>
                );
              })}
          {props?.clickable ? null : (
            <LinkView
              onClickLink={onClickLink}
              tab={tab}
              item={{ value: 11, label: "Archived Integrations" }}
            />
          )}
        </Stack>
        <Grid
          container
          columnSpacing={4}
          rowSpacing={4}
          sx={{
            ml: props?.type === "assign" && props?.clickable ? "-1rem" : 0,
            // width: `calc(100% - ${props?.clickable ? 0 : "16rem"})`,
            height: "fit-content",
          }}
        >
          {DATAVIEW}
        </Grid>
      </Stack>
    </Stack>
  );
};

export default AvailableView;

const MenuWrapper = ({
  anchorElMenu,
  setAnchorElMenu,
  isActiveCard,
  payload,
  onClickEditAccount = () => undefined,
  onClickFetchNewAccount = () => undefined,
  onClickReFetchNewAccount = () => undefined,
  onClickArchive = () => undefined,
  onClickDisconnect = () => undefined,
  onClickDelete = () => undefined,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const globalContext = useContext(GlobalContext);

  const finbanInfoRef = globalContext?.finbanInfoRef;
  const transactionSystemByName = useSelector(
    (state) => state.globalSlice.transactionSystemByName
  );
  const { ds, account, card } = payload;
  const lexOfficeId = transactionSystemByName?.["Lexoffice"]?.[0].uuid;
  const FinApiQueryKey = getFinApiQueryKey({ data_source: ds?.uuid });

  const data_source_by_uuid_finapi_connections = useStatusHook(null, {
    queryKey: FinApiQueryKey,
  });
  const data_source_by_uuid_external_connections = useStatusHook(null, {
    queryKey: [
      "integrations",
      {
        data_source: ds?.uuid,
        apiType: "data_source_external_connections",
      },
    ],
  });

  const showFinApi =
    !ds?.error &&
    ds?.type === 12 &&
    ds.state !== 3 &&
    account &&
    (data_source_by_uuid_finapi_connections.data === "COMPLETED" ||
      data_source_by_uuid_finapi_connections.data === "UPDATED");
  const showIntegration =
    !ds?.error &&
    ds?.type === 19 &&
    ds?.last_sync_date &&
    data_source_by_uuid_external_connections.data === "UPDATED";

  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  const onClickItem = (e, value, item, option) => {
    handleCloseMenu();
    if (value === 1) {
      onClickEditAccount(e, ds, account, card);
    }
    if (value === 2) {
      onClickFetchNewAccount(e, ds, account, card);
    }
    if (value === 3) {
      onClickReFetchNewAccount(e, ds, account, card);
    }
    if (value === 4) {
      onClickArchive(e, ds, account, card);
    }
    if (value === 5) {
      onClickDisconnect(e, ds, account, card);
    }
    if (value === 6) {
      onClickDelete(e, ds, account, card);
    }
    if (value === 7 && option?.url) {
      finbanInfoRef.current?.open({
        url: option?.url,
      });
    }
  };

  const _options = useMemo(() => {
    let options = [];
    if (card?.infoLink) {
      options.push({
        value: 7,
        disableActions: true,
        view: (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              color: "color.slate.600",
              gap: "0.5rem",
              py: "0.5rem",
              width: "100%",
            }}
          >
            {t(card?.name)}
            <FinbanInfoBulb url={card?.infoLink} />
          </Box>
        ),
        sx: {
          borderBottom: `1px solid ${theme.palette.color.slate[200]}`,
          "&:hover": {
            backgroundColor: "transparent",
            cursor: "default",
          },
        },
      });
    }

    if (isActiveCard && [1, 10, 12, 19].includes(ds?.type)) {
      options.push({ value: 1, label: "Edit", icon: <ModeEditRoundedIcon /> });
    }
    if (ds.state === 1 && (showFinApi || showIntegration)) {
      options.push({
        value: 2,
        label: "Fetch new transactions",
        icon: <DownloadingIcon />,
      });
    }
    if (ds.state === 1 && (showFinApi || showIntegration)) {
      options.push({
        value: 3,
        label: "Refetch all transactions",
        icon: <CloudDownloadIcon />,
      });
    }
    if (ds?.state !== 3) {
      options.push({
        value: 4,
        label: ds?.state === 1 ? t("Archive") : t("UnArchive"),
        icon:
          ds?.state === 1 ? (
            <ArchiveRoundedIcon className={"actionBtnIcon"} />
          ) : (
            <UnarchiveRoundedIcon className={"actionBtnIcon"} />
          ),
      });
    }
    if (
      ds.state === 1 &&
      (showFinApi || (showIntegration && lexOfficeId === ds.transaction_system))
    ) {
      options.push({
        value: 5,
        label: "Disconnect",
        icon: <CloudOffIcon />,
      });
    }

    options.push({
      value: 6,
      label: "Delete",
      icon: <DeleteForeverIcon />,
      sx: {
        backgroundColor: theme.palette.color.red[50],
        "&:hover": {
          backgroundColor: theme.palette.color.red[100],
        },
        borderTop: `1px solid ${theme.palette.color.slate[200]}`,
        "& .MuiListItemIcon-root": {
          color: theme.palette.color.red[500],
        },
        "& .MuiTypography-root": {
          color: theme.palette.color.red[500],
          lineHeight: 1,
        },
      },
    });

    return options;
  }, [
    card?.infoLink,
    card?.name,
    ds.state,
    ds.transaction_system,
    ds?.type,
    isActiveCard,
    lexOfficeId,
    showFinApi,
    showIntegration,
    t,
    theme.palette.color.red,
    theme.palette.color.slate,
  ]);

  return (
    <MenuView
      anchorEl={anchorElMenu}
      open={Boolean(anchorElMenu)}
      options={_options}
      handleClose={handleCloseMenu}
      onClickItem={onClickItem}
      minWidth={"22rem"}
    />
  );
};

const LinkView = ({ onClickLink, item, tab }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const dataSource = useSelector((state) => state.globalSlice.dataSource);

  const DsCount = useMemo(() => {
    const stateNumber = item?.value === 11 ? [1, 3] : [2];
    const filter = dataSource?.filter(
      (o1) => !o1.internal_dataset && !stateNumber.includes(o1.state)
    );
    return filter?.length;
  }, [dataSource, item?.value]);

  return (
    <Typography
      onClick={() => onClickLink(item)}
      variant="subtitle2"
      fontWeight={"fontWeightBold"}
      sx={{
        cursor: "pointer",
        mb: "2rem",
        lineHeight: "normal",
        width: "16rem",
        borderRadius: theme.borderRadius.main,
        backgroundColor:
          tab === item?.value ? theme.palette.color.slate[200] : "transparent",
        color: theme.palette.color.slate[tab === item?.value ? 700 : 500],
        p: "0.75rem",
        "&:hover": {
          backgroundColor: theme.palette.color.slate[200],
        },
      }}
    >
      {t(item?.label)} ({DsCount})
    </Typography>
  );
};

const DataSetSelectionModal = ({ open, dSObj, setOpen, resetkanban }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [isSubscriptionValid] = useSubscriptions();
  const theme = useTheme();

  //redux
  const dataSetList = useSelector((state) => state.boardSlice?.dataSetList);
  const dataSource = useSelector((state) => state.globalSlice.dataSource);

  //state
  const [datasetsName, setDataSetsName] = useState([]);
  const [loader, setLoader] = useState(false);

  const isOpen = open === "dataset_selection";

  useEffect(() => {
    if (!isOpen) {
      setLoader(false);
    }
  }, [isOpen]);

  //api
  const getDataSource = async () => {
    await APICall("get", EndPoints.integrations).then((response) => {
      if (response.status === 200 && response.data) {
        dispatch(setDataSource(response.data.results));
      }
    });
  };

  const addDataSourceDataset = async (dataset_id, obj, array) => {
    await APICall(
      "post",
      EndPoints.datasets + `${dataset_id}/data_sources/`,
      obj
    ).then((response) => {
      if (response.status === 201 && response.data) {
        setDataSetsName(array);
      } else {
        setLoader(false);
      }
      if (response.status === 400 && response.data) {
        if (response?.data?.data_source?.length > 0) {
          // enqueueSnackbar(t("different_ds_not_allowed"), {
          enqueueSnackbar(t(response?.data?.data_source?.[0]), {
            variant: "warning",
            autoHideDuration: 5000,
            preventDuplicate: true,
          });
        }
      }
    });
  };

  const removeDataSourceToDataSet = async (dataset_id, integration_id) => {
    await APICall(
      "delete",
      EndPoints.datasets + `${dataset_id}/data_sources/${integration_id}/`
    ).then((response) => {
      if (response.status === 204) {
      } else {
        setLoader(false);
      }
    });
  };

  //functions
  const handleChangeDataSetSelection = async (uuid) => {
    if (isSubscriptionValid({ showMessage: true })) {
      let add = false;
      let remove = false;
      setLoader(true);
      let array = [];
      if (datasetsName.includes(uuid)) {
        remove = true;
        array = datasetsName.filter((o1) => o1 !== uuid);
      } else {
        add = true;
        array = [...datasetsName, uuid];
      }

      if (add) {
        let obj = {
          active: true,
          dataset: uuid,
          data_source: dSObj.current?.ds?.uuid,
        };
        await addDataSourceDataset(uuid, obj, array);
      }
      if (remove) {
        let dataSourceObj = dataSource?.find(
          (o1) => o1.uuid === dSObj.current?.ds?.uuid
        );
        let connected_data_source_obj = dataSourceObj?.datasets?.find(
          (o1) => o1 && o1.dataset === uuid
        );
        if (connected_data_source_obj) {
          await removeDataSourceToDataSet(
            connected_data_source_obj?.dataset,
            connected_data_source_obj?.uuid
          );
        }
        setDataSetsName(array);
      }
      setLoader(false);

      setTimeout(() => {
        getDataSource();
        resetkanban();
      }, 500);
    }
  };

  const navigateToDataset = (uuid) => {
    navigate(`/organization/${uuid}/table`);
  };

  const handleClose = () => {
    setOpen(null);
    setDataSetsName(null);
  };

  useEffect(() => {
    if (isOpen) {
      let array = dSObj.current?.ds?.datasets?.map((item) => item?.dataset);
      setDataSetsName(array);
    }
  }, [isOpen]);

  return (
    <Dialog
      disableEscapeKeyDown
      open={!!isOpen}
      onClose={handleClose}
      sx={{
        zIndex: 1303,
      }}
    >
      <List
        sx={{
          width: "40rem",
          pb: "2rem",

          background: Color.BodyBG,
        }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader
            component="div"
            id="nested-list-subheader"
            sx={{
              fontSize: "1.3rem",
              color: Color.black,
              fontWeight: 500,
              mb: "1rem",
              backgroundColor: Color.HeaderBG,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {t("Select_datasets")}
            {loader ? (
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <CircularProgress
                  variant="determinate"
                  sx={{
                    color: Color.tailwind.grey[200],
                  }}
                  size={25}
                  thickness={4}
                  value={100}
                />
                <CircularProgress
                  variant="indeterminate"
                  disableShrink
                  sx={{
                    color: theme.palette.primary.main,
                    animationDuration: "550ms",
                    position: "absolute",
                    left: 0,
                    [`& .${circularProgressClasses.circle}`]: {
                      strokeLinecap: "round",
                    },
                  }}
                  size={25}
                  thickness={4}
                />
              </div>
            ) : null}
          </ListSubheader>
        }
      >
        <Typography
          variant="body2"
          color="color.description"
          sx={{ px: "1.3rem", mb: "2rem" }}
        >
          {t("integration_org_assign_overlay_des")}
        </Typography>
        {dataSetList?.map((option) => {
          const selected = datasetsName && datasetsName?.includes(option?.uuid);
          if (option.state !== 1) {
            return null;
          }

          return (
            <Box
              key={option?.uuid}
              sx={{
                display: "flex",
                alignItems: "center",
                px: "0.75rem",
                "&: hover": {
                  "& #dataset-link": {
                    display: "flex",
                  },
                  "& #currency-warn-msg": {
                    display: "none",
                  },
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  py: 0,
                  height: "3rem",
                  width: "fit-content",
                  flexGrow: "unset",
                }}
              >
                <ListItemIcon>
                  <Android12Switch
                    disabled={
                      option.currency !== dSObj.current?.ds?.currency || loader
                    }
                    checked={selected}
                    onChange={() => handleChangeDataSetSelection(option?.uuid)}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={`${option?.title}`}
                  sx={{
                    flex: "unset",
                    mr: "1rem",
                    width: "fit-content",
                    "& span": {
                      color:
                        option.currency !== dSObj.current?.ds?.currency
                          ? "black"
                          : theme.palette.primary.main,
                      fontWeight: 500,
                    },
                  }}
                />
              </Box>
              <Button
                id="dataset-link"
                onClick={() => navigateToDataset(option?.uuid)}
                disableElevation
                color="primary"
                variant="outlined"
                size="small"
                sx={{
                  display: "none",
                  height: "1.6rem",
                  textTransform: "initial",
                  color: Color.theme.purple[500],
                  width: "max-content",
                  fontSize: "0.7rem",
                  border: "none",
                  backgroundColor: Color.theme.purple[50],
                  "&:hover": {
                    border: "none",
                    backgroundColor: Color.theme.purple[100],
                  },
                }}
              >
                {t("Go to this Organization")}
              </Button>

              {option.currency !== dSObj.current?.ds?.currency ? (
                <Typography
                  id="currency-warn-msg"
                  sx={{ color: Color.red, display: "flex" }}
                >
                  {t("Currency is not matching")}
                </Typography>
              ) : null}
            </Box>
          );
        })}
      </List>
    </Dialog>
  );
};
