import { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import { IoCloseCircleOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";

import { useDeferredTimeout } from "../../../hooks/useDeferredTimeout";
import EndPoints from "../../../APICall/EndPoints";
import { truncate } from "../../../Helper/data";
import FilterBaseView from "./FilterBaseView";
import FilterOverlay from "./FilterOverlay";
import CustomPopover from "../../PopOver";
import APICall from "../../../APICall";
import theme from "../../../theme";

const ThemeFilter = forwardRef(
  (
    {
      tooltip,
      color = "slate",
      showDefaultColor,
      isFilterOpen,
      icon,
      heading,
      translate = false,
      options = [],
      selectedIds = [],
      Placeholder,
      isActive,
      onChangeValue,
      onClear,
      onClickManage,
      onClickReset,
      isMultiple,
      maxWidth = "8rem",
      count = 15,
      end_point,
      searchType = "client",
      displayKey = "title",
      query_options_payload,
      notEditable,
    },
    _ref
  ) => {
    const overlayRef = useRef(null);
    //save selected options so if user search again then it will not remove selected options
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const [searchText, setSearchText] = useState("");
    const [selectedOptions, setSelectedOptions] = useState(
      options?.filter((o1) => selectedIds?.includes(o1?.uuid))
    );

    const deferredSearchText = useDeferredTimeout({
      value: searchText,
      timeoutMs: 500,
    });

    //Api
    const getOptions = async (params) => {
      let data = null;
      let url = "";

      if (params?.pageParam) {
        url = url + params?.pageParam;
      } else {
        url = url + EndPoints?.[end_point];

        if (params?.page) {
          url = url + `?page=${params?.page}`;
        }

        if (params?.page_size) {
          url = url + `&page_size=${params?.page_size}`;
        }
        if (params?.dataset) {
          url = url + `&dataset=${params?.dataset}`;
        }
        if (params?.title_contains) {
          url = url + `&title_contains=${params?.title_contains}`;
        }
        if (params?.type?.length > 0) {
          params?.type?.forEach((item) => {
            url = url + `&type=${item}`;
          });
        }
        if (params?.contact_list?.length > 0) {
          params?.contact_list?.forEach((item) => {
            url = url + `&contact=${item}`;
          });
        }
        if (params?.uuid?.length > 0) {
          url = url + `&uuid=`;
          params?.uuid?.forEach((id, index) => {
            url = url + `${index === 0 ? "" : ","}${id}`;
          });
        }
      }
      await APICall("get", url, null, params?.config).then((response) => {
        if (response.status === 200 && response) {
          data = response?.data;
        }
      });
      return data;
    };

    //query
    const query_options = useInfiniteQuery({
      queryKey: [
        "options",
        { end_point, deferredSearchText, ...query_options_payload },
      ],
      queryFn: ({ pageParam = null, signal }) => {
        const params = {
          config: {
            signal,
          },
          pageParam,
          page: 1,
          page_size: 50,
          title_contains: deferredSearchText,
          ...query_options_payload,
        };
        const result = getOptions(params);
        if (result) {
          return result;
        }
      },
      getNextPageParam: (lastPage) => {
        return lastPage?.next ?? undefined;
      },
      select: (data) => {
        const flatData = data?.pages?.map((o1) => o1?.results)?.flat();
        return flatData;
      },
      keepPreviousData: true,
      enabled: !!end_point && !!anchorEl,
    });

    const fetchInitialData = async () => {
      if (searchType === "server" && !_options && selectedIds?.length > 0) {
        const params = {
          page: 1,
          page_size: 100,
          uuid: selectedIds,
        };
        const result = await getOptions(params);
        setSelectedOptions(result?.results);
      }
    };

    const onClickPopOver = (event) => {
      if (isFilterOpen) {
        isFilterOpen.current = true;
      }
      setAnchorEl(event.currentTarget);
    };

    const onClosePopOver = () => {
      if (isFilterOpen) {
        isFilterOpen.current = false;
      }
      setAnchorEl(null);
    };

    const onDeleteIcon = (e, item) => {
      e?.stopPropagation();
      const isIncludedInFilter = selectedIds?.includes(item?.uuid);
      let changedIds = [];
      let isAddedNew = false;
      if (isIncludedInFilter) {
        isAddedNew = false;
        changedIds = selectedIds?.filter((o1) => o1 !== item?.uuid);
      } else {
        isAddedNew = true;
        changedIds = [...selectedIds, item?.uuid];
      }
      if (onChangeValue) {
        onChangeValue(changedIds, isAddedNew);
      }
    };

    const _options = searchType === "server" ? query_options?.data : options;

    const allView = useMemo(() => {
      if (selectedIds?.length > 0) {
        return selectedOptions?.map((item, index) => (
          <Box
            key={item?.uuid}
            sx={{
              marginRight:
                index !== selectedOptions?.length - 1 ? "0.25rem" : 0,
              display: "flex",
              alignItems: "center",
              borderRadius: theme.borderRadius.borderRadiusXL,
              paddingInline: "8px",
              paddingBlock: "0.35rem",
              marginTop: "1px",
              border: `1px solid ${theme.palette?.color?.[item?.color || "slate"]?.[200]}`,
              backgroundColor:
                theme.palette.color?.[item?.color || "slate"]?.[50],
              color: theme.palette.color?.[item?.color || "slate"]?.[600],
              position: "relative",
              "&:hover": {
                justifyContent: "flex-start",
                "& #close-icon": {
                  display: "block !important",
                },
                "& #item-title": {
                  width: notEditable ? "100%" : "90% !important",
                },
              },
            }}
          >
            <span
              id="item-title"
              style={{
                textAlign: "left",
                fontWeight: 500,
                fontSize: "0.8rem",
                lineHeight: "0.8rem",
                width: "100%",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                color: `${theme.palette.color?.[item?.color || "slate"]?.[600]} !important`,
              }}
            >
              {truncate(
                item?.translate || translate
                  ? t(item?.[displayKey])
                  : item?.[displayKey],
                count
              )}
            </span>

            {notEditable ? null : (
              <IoCloseCircleOutline
                id="close-icon"
                onClick={(e) => onDeleteIcon(e, item)}
                style={{
                  display: "none",
                  cursor: "pointer",
                  fontSize: "1rem",
                  position: "absolute",
                  right: "3px",
                  color: theme.palette.color?.[item?.color || "slate"]?.[600],
                }}
              />
            )}
          </Box>
        ));
      } else {
        return null;
      }
    }, [
      selectedIds,
      selectedOptions,
      notEditable,
      translate,
      t,
      displayKey,
      count,
    ]);

    const VALUE = useMemo(() => {
      if (isMultiple && selectedIds?.length > 1) {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: "4px",
              borderRadius: theme.borderRadius.borderRadiusXL,
              paddingInline: "8px",
              paddingBlock: "4px",
              backgroundColor: theme.palette.color.slate[200],
              color: theme.palette.color.slate[600],
            }}
          >
            {t("Multiple")}
            <Box
              sx={{
                fontSize: "0.8rem",
                borderRadius: theme.borderRadius.main,
                backgroundColor: theme.palette.color.slate[300],
                height: "1.25rem",
                width: "1.25rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                ml: "0.5rem",
              }}
            >
              {selectedIds?.length || 0}
            </Box>
          </div>
        );
      } else {
        return allView;
      }
    }, [allView, isMultiple, selectedIds?.length, t]);

    useEffect(() => {
      if (selectedIds?.length > 0 && _options) {
        if (searchType === "server") {
          setSelectedOptions((prev) => {
            const ids =
              prev?.length > 0
                ? [
                    ...new Set(
                      (prev || [])
                        ?.filter(
                          (o1) => o1?.uuid && selectedIds?.includes(o1?.uuid)
                        )
                        ?.map((item) => item?.uuid)
                    ),
                  ]
                : [];
            const uniqueById =
              ids?.length > 0
                ? ids?.map((id) => prev?.find((item) => item?.uuid === id))
                : [];

            return [
              ...uniqueById,
              ...(_options || [])?.filter(
                (o1) =>
                  !ids?.includes(o1?.uuid) && selectedIds?.includes(o1?.uuid)
              ),
            ];
          });
        } else {
          setSelectedOptions(
            _options?.filter((o1) => selectedIds?.includes(o1?.uuid))
          );
        }
      }
    }, [_options, searchType, selectedIds]);

    useEffect(() => {
      fetchInitialData();
    }, [selectedIds]);

    return (
      <CustomPopover
        type="anchor"
        anchorEl={anchorEl}
        onClose={onClosePopOver}
        showRight
        BaseButton={
          <FilterBaseView
            heading={heading}
            tooltip={tooltip}
            icon={icon}
            // isActive={isActive}
            isActive={false}
            Placeholder={Placeholder}
            value={VALUE}
            allValue={
              isMultiple && VALUE && selectedIds?.length > 1 ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    gap: "0.5rem",
                    padding: "0.5rem",
                  }}
                >
                  {allView}
                </div>
              ) : null
            }
            onClick={notEditable ? undefined : onClickPopOver}
            onClear={onClear}
            maxWidth={maxWidth}
            notEditable={notEditable}
          />
        }
      >
        <FilterOverlay
          ref={overlayRef}
          selectedIds={selectedIds}
          options={_options}
          anchorEl={anchorEl}
          translate={translate}
          color={color}
          showDefaultColor={showDefaultColor}
          onChangeValue={onChangeValue}
          onClickManage={onClickManage}
          onClickReset={onClickReset}
          end_point={end_point}
          searchType={searchType}
          displayKey={displayKey}
          query_options={query_options}
          searchText={searchText}
          setSearchText={setSearchText}
          isFetching={query_options?.isFetching}
          isFetchingNextPage={query_options?.isFetchingNextPage}
          hasNextPage={query_options?.hasNextPage}
          fetchNextPage={query_options?.fetchNextPage}
          query_options_payload={query_options_payload}
        />
      </CustomPopover>
    );
  }
);

export default ThemeFilter;
