import {
  FormControlLabel,
  Typography,
  Checkbox,
  useTheme,
  Dialog,
  Box,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import * as React from "react";

import { setPopupStatus3 } from "../../store/slices/datasets";
import TailwindButton from "../Overlay/TailwindButton";

export default function ConfirmationModal() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  //redux
  // const action_confirm = useSelector(
  //   (state) => state.globalSlice.appliedFilterlist?.kanban[popupStatus3?.from]
  // );
  // const [rememberChecked, setRememberChecked] = React.useState(
  //   popupStatus3?.from ? action_confirm : popupStatus3?.rememberChecked
  // );
  const popupStatus3 = useSelector((state) => state.datasetSlice?.popupStatus3);

  //state
  const [isConfirmed, setIsConfirmed] = React.useState(false);

  //functions
  const onClose = () => {
    dispatch(setPopupStatus3(null));
    if (popupStatus3?.onClose) popupStatus3?.onClose();
  };
  const onCloseViaBackdrop = () => {
    if (!popupStatus3?.disableBackdropClick) onClose();
  };

  const onCloseViaButton = () => {
    onClose();
  };

  // const handleRememberChange = (event) => {
  //   setRememberChecked(event.target.checked);
  //   popupStatus3?.onCheckMarkChange(event.target.checked);
  //   if (popupStatus3?.from) {
  //     dispatch(
  //       updateFilters({ parent_key:"kanban", obj:{[popupStatus3?.from]: event.target.checked} })
  //     );
  //   }
  // };

  const handleCheckChange = (event) => {
    setIsConfirmed(event.target.checked);
  };

  let color = "slate";
  if (popupStatus3?.overlay_type === "info") {
    color = "blue";
  }
  if (popupStatus3?.overlay_type === "warn") {
    color = "amber";
  }
  if (popupStatus3?.overlay_type === "delete") {
    color = "red";
  }
  if (popupStatus3?.overlay_type === "success") {
    color = "green";
  }

  return (
    <Dialog
      open={!!popupStatus3?.open}
      onClose={onCloseViaBackdrop}
      sx={{ zIndex: 1320 }}
      PaperProps={{
        style: {
          minWidth: "45rem",
          maxWidth: "50rem",
          padding: "2rem",
          boxShadow:
            "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
          borderRadius: 8,
        },
      }}
    >
      <Typography
        variant="h5"
        fontWeight={"fontWeightBold"}
        color="color.mainTitle"
      >
        {popupStatus3?.payload?.title}
      </Typography>
      <Box mt="1.5rem" mb="1.5rem">
        <Typography
          variant="body1"
          sx={{
            backgroundColor: theme.palette.color?.[color]?.[50],
            color: theme.palette.color?.[color]?.[500],
            p: "1rem",
            borderRadius: theme.borderRadius.main,
          }}
        >
          {popupStatus3?.payload?.message}
        </Typography>
      </Box>
      {popupStatus3?.showConfirmCheckBox ? (
        <FormControlLabel
          sx={{
            mb: "0.5rem",
            ml: "-9px",
            color: theme.palette.color?.description,
            alignItems: "start",
            "& .MuiCheckbox-root": {
              color: theme.palette.color.description,
              py: 0,
              "&:hover": {
                backgroundColor: "transparent",
              },
            },
          }}
          control={
            <Checkbox
              variant="outlined"
              value="remember"
              color="primary"
              checked={isConfirmed || false}
              onChange={handleCheckChange}
            />
          }
          label={t(popupStatus3?.confirmCheckBoxLabel)}
        />
      ) : null}
      <Box
        sx={{
          marginTop: "1rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          width: "100%",
          position: "relative",
        }}
      >
        {popupStatus3?.payload?.linkText ? (
          <Typography
            variant="caption"
            fontWeight={"fontWeightMedium"}
            component={"a"}
            color="color.slate.600"
            href={popupStatus3?.payload?.link}
            target={"_blank"}
            sx={{
              position: "absolute",
              left: 0,
              textDecoration: "underline",
              "&:hover": {
                color: theme.palette.color.slate[700],
              },
            }}
          >
            {popupStatus3?.payload?.linkText}
          </Typography>
        ) : null}
        <div>
          {!popupStatus3?.payload?.hideCancel && (
            <TailwindButton
              type="cancel"
              text={popupStatus3?.payload?.cancelText || t("Cancel")}
              onClick={onCloseViaButton}
              backgroundColorCancel={theme.palette.color.slate[100]}
              sx={{
                ml: 0,
              }}
            />
          )}
          <TailwindButton
            text={
              popupStatus3?.loading
                ? undefined
                : popupStatus3?.payload?.confirmText || t("Confirm")
            }
            loading={popupStatus3?.loading}
            disable={popupStatus3?.showConfirmCheckBox && !isConfirmed}
            onClick={
              popupStatus3?.onConfirm
                ? () =>
                    popupStatus3?.onConfirm({
                      payload: popupStatus3?.payload,
                    })
                : undefined
            }
            backgroundColor={theme.palette.color?.[color]?.[500]}
            sx={{
              py: popupStatus3?.loading ? "1.2rem" : "0.75rem",
              "& .MuiLoadingButton-loadingIndicator": {
                color: theme.palette.color?.[color]?.[500],
              },
              "&.MuiLoadingButton-root.Mui-disabled": {
                backgroundColor: `${theme.palette.color?.[color]?.[200]} !important`,
                color: `${theme.palette.color.white} !important`,
                fontWeight: theme.typography.fontWeightMediumBold,
              },
            }}
          />
        </div>

        {/* for kanban did used  */}
        {/* {popupStatus3?.showCheckBox ? (
          <FormControlLabel
            sx={{
              "& .MuiFormControlLabel-label": {
                fontSize: "0.9rem",
              },
            }}
            control={
              <Checkbox
                value="remember"
                color="primary"
                checked={rememberChecked || false}
                onChange={handleRememberChange}
              />
            }
            label={t("don't show this checkbox again")}
          />
        ) : null} */}
      </Box>
    </Dialog>
  );
}
