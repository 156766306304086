import {
  startOfQuarter,
  isSameQuarter,
  startOfMonth,
  isSameMonth,
  startOfWeek,
  subQuarters,
  isSameWeek,
  subMonths,
  isSameDay,
  subWeeks,
  getDate,
  subDays,
  format,
} from "date-fns";
import {
  useImperativeHandle,
  useDeferredValue,
  useCallback,
  forwardRef,
  useContext,
  useEffect,
  useState,
  useMemo,
  useRef,
} from "react";
import {
  LinearProgress,
  useMediaQuery,
  useTheme,
  Button,
  Stack,
  alpha,
  Box,
} from "@mui/material";
import { TbBuildingBank, TbPercentage } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { TfiExchangeVertical } from "react-icons/tfi";
import { MdOutlineShowChart } from "react-icons/md";
import SearchIcon from "@mui/icons-material/Search";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { FaArrowUp } from "react-icons/fa";
import { IoPeople } from "react-icons/io5";
import _ from "underscore";

import {
  getStatisticsDataWithParams,
  formatDateInQuarter,
  formatDateToLocal,
  getTailwindColor,
  formatDateInWeek,
  isBookedStates,
  getGridColumn,
  getCellInfo,
  getRowsCell,
} from "../../../../Helper/data";
import {
  getStartingLiquidityValue,
  getConnectedScenariosUuid,
  getMonthlyValue,
  collectUUIDs,
  getVatInfo,
} from "../../../../Helper/functions";
import useUpdateEffect from "../../../../hooks/4-useUpdateEffect/useUpdateEffect";
import { pastTableQueryKey, tableQueryKey } from "../../../../Helper/queryKeys";
import { setPlanningTableData } from "../../../../store/slices/global";
import useDebounce from "../../../../hooks/3-useDebounce/useDebounce";
import { setCategories } from "../../../../store/slices/category";
import { GlobalContext } from "../../../../GlobalContextWrapper";
import { setSearchText } from "../../../../store/slices/board";
import { Color, Constant, Fonts } from "../../../../Helper";
import useStatusHook from "../../../../hooks/useStatusHook";
import EndPoints from "../../../../APICall/EndPoints";
import Icon from "../../../../components/Icon";
import { queryClient } from "../../../../App";
import APICall from "../../../../APICall";
import Table from "./TableGrid/Table";
import store from "../../../../store";
import theme from "../../../../theme";

const TableView = () => {
  const commonRef = useRef(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const s2300 = useMediaQuery("(min-width:2300px)");

  let borderRadius = 1;
  let rowHeight = Constant.table_row_height;
  let gridWidth = Constant.column_width;
  let spacing = Constant.start_column_extra_width;
  if (s2300) {
    rowHeight = 34;
  }

  //redux
  const start_date = useSelector(
    (state) => state.boardSlice?.dataSetData?.start_date
  );
  const end_date = useSelector(
    (state) => state.boardSlice?.dataSetData?.end_date
  );
  const tableType = useSelector((state) => state.boardSlice?.tableType);

  //state
  const [data, setData] = useState(null);
  const [selectedCell, setSelectedCell] = useState(null);
  const [tableLoading, setTableLoading] = useState(true);

  //query
  const gridColumn = useMemo(() => {
    return getGridColumn({
      stickyColumn: ["column-start"],
      showColumnEnd: false,
      gridWidth,
      tableType,
      start_date,
      end_date,
    });
  }, [end_date, gridWidth, tableType, start_date]);

  useEffect(() => {
    dispatch(setPlanningTableData(data));
  }, [data]);

  //functions
  const onCellChanged = (cell) => {
    commonRef.current?.onCellChanged(cell);
  };

  const onClickRefresh = () => {
    commonRef.current?.getCategories();
  };

  const getRefreshText = useCallback(() => {
    setTimeout(() => {
      return t("Refresh");
    }, 500);
  }, [t]);

  return (
    <Box
      sx={{
        width: "fit-content",
        height: "fit-content",
        marginLeft: "28px",
        position: "relative",
        "& .renderIfVisible-placeholder": {
          width: Constant.column_width,
          height: "2.5rem",
        },
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          position: "relative",
        }}
      >
        <CommonFunctions
          ref={commonRef}
          data={data}
          setData={setData}
          gridColumn={gridColumn}
          gridWidth={gridWidth}
          rowHeight={rowHeight}
          spacing={spacing}
          borderRadius={borderRadius}
          selectedCell={selectedCell}
          setSelectedCell={setSelectedCell}
          setTableLoading={setTableLoading}
          tableLoading={tableLoading}
          tableType={tableType}
        />
        {/* {tableLoading ? (
          <Skeleton
            animation="wave"
            variant="rectangular"
            width={"100vw"}
            height={"40rem"}
          />
        ) : null} */}
        {!data && !tableLoading ? (
          <div style={{ textAlign: "center", width: "100vw", height: "40rem" }}>
            <Button
              onClick={onClickRefresh}
              variant="text"
              sx={{
                textTransform: "initial",
                fontSize: "1rem",
                mt: "3rem",
              }}
            >
              {getRefreshText()}
            </Button>
          </div>
        ) : null}

        <HeaderTable
          data={data}
          key="header"
          name="header"
          gridColumn={gridColumn}
          gridWidth={gridWidth}
          rowHeight={rowHeight}
          spacing={spacing}
          borderRadius={0}
          selectedCell={selectedCell}
          setSelectedCell={setSelectedCell}
          tableType={tableType}
          tableLoading={tableLoading}
        />
        <div style={{ height: "0.25rem" }}>
          {tableLoading ? (
            <LinearProgress sx={{ color: theme.palette.primary.main }} />
          ) : null}
        </div>
        {data ? (
          <Table
            rows={data?.["start_liquidity"] || []}
            columns={gridColumn}
            borderRadius={borderRadius}
            name={"start_liquidity"}
            key={"start_liquidity"}
            selectedCell={selectedCell}
            setSelectedCell={setSelectedCell}
            style={{
              marginTop: "1.75rem",
              marginBottom: "2rem",
              borderRadius: "1px",
              boxShadow:
                "rgba(0, 0, 0, 0.01) 0px 4px 6px -1px, rgba(0, 0, 0, 0.05) 0px 2px 4px -1px",
            }}
          />
        ) : null}
        {data ? (
          <Table
            rows={data?.["net_change"] || []}
            columns={gridColumn}
            borderRadius={borderRadius}
            name={"net_change"}
            key={"net_change"}
            selectedCell={selectedCell}
            setSelectedCell={setSelectedCell}
            style={{
              marginBottom: "2rem",
              borderRadius: "1px",
              boxShadow:
                "rgba(0, 0, 0, 0.01) 0px 4px 6px -1px, rgba(0, 0, 0, 0.05) 0px 2px 4px -1px",
            }}
          />
        ) : null}
        {data ? (
          <Table
            rows={data?.["inflow"] || []}
            columns={gridColumn}
            borderRadius={borderRadius}
            name={"inflow"}
            key={"inflow"}
            selectedCell={selectedCell}
            setSelectedCell={setSelectedCell}
            onCellChanged={onCellChanged}
            style={{
              marginBottom: "2rem",
              borderRadius: "1px",
              boxShadow:
                "rgba(0, 0, 0, 0.01) 0px 4px 6px -1px, rgba(0, 0, 0, 0.05) 0px 2px 4px -1px",
            }}
          />
        ) : null}
        {data ? (
          <Table
            rows={data?.["outflow"] || []}
            columns={gridColumn}
            borderRadius={borderRadius}
            name={"outflow"}
            key={"outflow"}
            selectedCell={selectedCell}
            setSelectedCell={setSelectedCell}
            onCellChanged={onCellChanged}
            style={{
              marginBottom: "2rem",
              borderRadius: "1px",
              boxShadow:
                "rgba(0, 0, 0, 0.01) 0px 4px 6px -1px, rgba(0, 0, 0, 0.05) 0px 2px 4px -1px",
            }}
          />
        ) : null}
        {data ? (
          <Table
            rows={data?.["VAT_Imputation"] || []}
            columns={gridColumn}
            borderRadius={borderRadius}
            name={"VAT_Imputation"}
            key={"VAT_Imputation"}
            selectedCell={selectedCell}
            setSelectedCell={setSelectedCell}
            style={{
              marginBottom: "0.5rem",
              borderRadius: "1px",
              boxShadow:
                "rgba(0, 0, 0, 0.01) 0px 4px 6px -1px, rgba(0, 0, 0, 0.05) 0px 2px 4px -1px",
            }}
          />
        ) : null}
        {data ? (
          <Table
            rows={data?.["vat"] || []}
            columns={gridColumn}
            borderRadius={borderRadius}
            name={"vat"}
            key={"vat"}
            selectedCell={selectedCell}
            setSelectedCell={setSelectedCell}
            style={{
              marginBottom: "1.75rem",
              borderRadius: "1px",
              boxShadow:
                "rgba(0, 0, 0, 0.01) 0px 4px 6px -1px, rgba(0, 0, 0, 0.05) 0px 2px 4px -1px",
            }}
          />
        ) : null}
        {data ? (
          <Table
            rows={data?.["end_liquidity"] || []}
            columns={gridColumn}
            borderRadius={borderRadius}
            name={"end_liquidity"}
            key={"end_liquidity"}
            selectedCell={selectedCell}
            setSelectedCell={setSelectedCell}
            style={{
              marginBottom: "4rem",
              borderRadius: "1px",
              boxShadow:
                "rgba(0, 0, 0, 0.01) 0px 4px 6px -1px, rgba(0, 0, 0, 0.05) 0px 2px 4px -1px",
            }}
          />
        ) : null}
      </div>
    </Box>
  );
};

export default TableView;

const HeaderSearchInput = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const searchText = useSelector((state) => state.boardSlice?.searchText);

  const onChange = (e) => {
    dispatch(setSearchText(e.target.value));
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        "& input::placeholder": {
          color: Color.tailwind.grey[400],
        },
      }}
    >
      <SearchIcon
        sx={{
          position: "absolute",
          left: "8px",
          zIndex: 11,
          color: Color.tailwind.slate[400],
        }}
      />
      <input
        type={"text"}
        placeholder={t("Category Search")}
        value={searchText}
        onChange={onChange}
        autoFocus
        style={{
          width: "calc(100% - 8px)",
          height: "calc(100% - 8px)",
          margin: "2px",
          paddingLeft: "28px",
          borderRadius: "4px",
          outline: `1px solid ${
            searchText?.length > 0
              ? Color.tailwind.purple[500]
              : Color.tailwind.slate[200]
          }`,
          fontFamily: Fonts.Text,
          fontSize: "12px",
          textAlign: "left",
        }}
      />
    </Box>
  );
};

const CustomIcon = ({
  backgroundColor = theme.palette.primary.main,
  iconColor = theme.palette.color.white,
  iconSize = "25px",
  icon,
  iconStyle,
}) => {
  return (
    <div
      style={{
        display: "inline-flex",
        alignItems: "center",
        borderRadius: "4px",
        backgroundColor: backgroundColor,
        position: "relative",
        width: "18px",
        height: "18px",
      }}
    >
      <Icon
        icon={icon}
        fontSize={iconSize}
        color={iconColor}
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: `translate(-50%, -50%)`,
          ...iconStyle,
        }}
      ></Icon>
    </div>
  );
};

const HeaderTable = ({
  gridColumn,
  data,
  gridWidth,
  rowHeight,
  spacing,
  tableType,
  name = "header",
  borderRadius,
  selectedCell,
  setSelectedCell,
  tableLoading,
}) => {
  const theme = useTheme();

  const [rows, setRows] = useState([]);

  const profile = useSelector((state) => state.settingsSlice?.profile);

  const transaction_monthly_chart = useStatusHook(
    `transaction_${tableType}_chart`
  );
  const past_table_data = useStatusHook("past_table_data");

  useDebounce(
    () => {
      if (gridColumn?.length && !tableLoading) {
        let array = [];
        let headerArray = [];

        gridColumn?.forEach((columnElement) => {
          if (columnElement?.item) {
            const value =
              tableType === "quarterly"
                ? formatDateInQuarter(new Date(columnElement?.item))
                : tableType === "weekly"
                  ? formatDateInWeek(new Date(columnElement?.item))
                  : tableType === "daily"
                    ? formatDateToLocal(
                        new Date(columnElement?.item),
                        "dd MMM yy"
                      )
                    : formatDateToLocal(
                        new Date(columnElement?.item),
                        "MMM yy"
                      );
            const isCurrentMonth =
              tableType === "quarterly"
                ? isSameQuarter(new Date(), new Date(columnElement?.item))
                : tableType === "weekly"
                  ? isSameWeek(new Date(), new Date(columnElement?.item), {
                      weekStartsOn: 1,
                    })
                  : tableType === "daily"
                    ? isSameDay(new Date(), new Date(columnElement?.item))
                    : isSameMonth(new Date(), new Date(columnElement?.item));

            let isOdd = false;

            if (tableType !== "monthly") {
              if (tableType === "quarterly") {
                const year = new Date(columnElement?.item)?.getFullYear();
                isOdd = year % 2 !== 0;
              } else {
                const month = new Date(columnElement?.item)?.getMonth() + 1;
                isOdd = month % 2 !== 0;
              }
            }

            headerArray.push({
              item: {
                isCurrentMonth,
                date: columnElement?.item,
                value,
              },
              backgroundColor:
                tableType !== "monthly"
                  ? isOdd
                    ? alpha(theme.palette.color.slate[200], 0.65)
                    : theme.palette.color.slate[100]
                  : theme.palette.color.appThemeBg,
              fontWeight: isCurrentMonth ? 800 : 500,
              fontSize: "13px",
              // textTransform: "uppercase",
              color: isCurrentMonth ? "violet" : undefined,
              borderColor: isCurrentMonth
                ? theme.palette.primary.dark
                : undefined,
              borderBottomWidth: isCurrentMonth ? "3px" : undefined,
              borderTopWidth: "0px",
              borderRightWidth: "0px",
            });
          }
        });

        array.push({
          rowId: "header",
          rowHeight,
          mergeCell: true,
          isHeader: true,
          parentRowId: null,
          disableSelection: true,
          rowBackgroundColor: theme.palette.color.appThemeBg,
          position: "fixed",
          cells: getRowsCell({
            cellStart: {
              item: {
                uuid: "header",
                value: "header",
              },
              customCellRender: (props) => <HeaderSearchInput {...props} />,
              gridWidth: gridWidth + spacing,
              fontWeight: 700,
              isColumnStartCell: true,
              borderTopWidth: "0px",
              borderLeftWidth: "0px",
              zIndex: 12,
            },
            data: headerArray,
          }),
        });

        setRows([...array]);
      }
    },
    1000,
    [
      gridColumn,
      gridWidth,
      rowHeight,
      spacing,
      tableType,
      tableLoading,
      profile,
      theme.palette.primary.dark,
      theme.palette.color.appThemeBg,
    ]
  );

  if (
    !data?.["inflow"] &&
    (transaction_monthly_chart?.isFetching || past_table_data?.isFetching)
  ) {
    return null;
  }

  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      sx={{ position: "sticky", top: "0px", zIndex: 15 }}
    >
      <Table
        rows={rows}
        columns={gridColumn}
        borderRadius={borderRadius}
        name={name}
        key={name}
        selectedCell={selectedCell}
        setSelectedCell={setSelectedCell}
      />
    </Stack>
  );
};

const CommonFunctions = forwardRef(
  (
    {
      gridColumn,
      gridWidth,
      rowHeight,
      spacing,
      setData,
      tableLoading,
      setTableLoading,
      tableType,
    },
    _ref
  ) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const globalContext = useContext(GlobalContext);
    const internalDsRef = globalContext?.internalDsRef;

    //redux
    const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);
    const selectionCategoriesByID = useSelector(
      (state) => state.categorySlice.selectionCategoriesByID
    );
    const todayLiq = useSelector((state) => state.boardSlice?.todayLiq);
    const highlightedScenarios = useSelector(
      (state) => state.boardSlice?.highlightedScenarios
    );
    const refreshData = useSelector((state) => state.appSlice?.refreshData);
    const state = useSelector((state) => state.globalSlice?.state);
    const scenario = useSelector((state) => state.globalSlice?.scenario);
    const scenarioById = useSelector((state) => state.globalSlice.scenarioById);
    const scenario_color =
      scenarioById?.[highlightedScenarios?.[0]]?.[0]?.color;
    const _scenario_ = scenarioById?.[highlightedScenarios?.[0]]?.[0];
    const advanceVat = useSelector((state) => state.globalSlice.advanceVat);
    const searchText = useSelector((state) => state.boardSlice?.searchText);

    const isFirstLoginOverlayOpen = useSelector(
      (state) => state.globalSlice.isFirstLoginOverlayOpen
    );
    const isAccountSelectOverlayOpen = useSelector(
      (state) => state.globalSlice.isAccountSelectOverlayOpen
    );
    const isAllHeaderApiFetched = useSelector(
      (state) => state.commonSlice.isAllHeaderApiFetched
    );
    const use_global_categories = useSelector(
      (state) => state.boardSlice?.dataSetData?.use_global_categories
    );
    const _selectionCategories = useSelector(
      (state) => state.categorySlice?.selectionCategories
    );

    const selectionCategories = useMemo(() => {
      return _selectionCategories?.filter((o1) =>
        use_global_categories ? !o1?.dataset : o1?.dataset === dataSetData?.uuid
      );
    }, [_selectionCategories, dataSetData?.uuid, use_global_categories]);

    const AllStaff = useStatusHook("staff");

    const Staff = useMemo(
      () =>
        AllStaff?.data?.results?.filter(
          (o1) => o1?.dataset === dataSetData?.uuid
        ),
      [AllStaff?.data, dataSetData?.uuid]
    );

    const selected_scenario_uuid = useMemo(() => {
      if (highlightedScenarios?.[0]) {
        const _scenario = scenarioById?.[highlightedScenarios?.[0]]?.[0];
        return getConnectedScenariosUuid({ scenarioById, _scenario });
      } else {
        return [];
      }
    }, [highlightedScenarios, scenarioById]);

    const selectionsCategoryUuidCollections = useMemo(() => {
      let data = {};
      selectionCategories?.forEach((o1) => {
        let uuids = [];
        const category = selectionCategoriesByID?.[o1?.uuid]?.[0];
        collectUUIDs(category, uuids);
        data[o1?.uuid] = uuids;
      });
      return data;
    }, [selectionCategories, selectionCategoriesByID]);

    //state
    const [isCategoryLoading, setIsCategoryLoading] = useState(false);

    const [isTableProcessing, setTableProcessing] = useState(false);
    const table_query_key_obj = useDeferredValue({
      tableType,
      multiScenarioIds: selected_scenario_uuid,
      from_date: dataSetData?.start_date,
      to_date: dataSetData?.end_date,
      dataset: dataSetData?.uuid,
    });
    const past_table_query_key_obj = useDeferredValue({
      tableType,
      dataset: dataSetData?.uuid,
      from_date: dataSetData?.start_date,
      to_date: dataSetData?.end_date,
    });
    const _isAllHeaderApiFetched = useDeferredValue({
      isAllHeaderApiFetched,
    });

    //query
    const past_table_query_key = pastTableQueryKey(past_table_query_key_obj);
    const table_query_key = tableQueryKey(table_query_key_obj);

    const past_table_data = useQuery({
      queryKey: past_table_query_key,
      queryFn: () => {
        const result = getStatisticsDataWithParams({
          type: past_table_query_key?.[1]?.type,
          dataset: past_table_query_key?.[1]?.dataset,
          multiStatesIds: past_table_query_key?.[1]?.multiStatesIds,
          from_payment_date: past_table_query_key?.[1]?.from_date,
          to_payment_date: past_table_query_key?.[1]?.to_date,
        });
        if (result) {
          return result;
        }
      },
      backgroundFetch: true,
      enabled:
        !!_isAllHeaderApiFetched &&
        !isFirstLoginOverlayOpen &&
        !isAccountSelectOverlayOpen &&
        !!dataSetData?.uuid &&
        !!past_table_query_key?.[1]?.from_date &&
        !!past_table_query_key?.[1]?.to_date &&
        past_table_query_key?.[1]?.from_date <=
          past_table_query_key?.[1]?.to_date,
      priority: 3,
    });

    const table_data = useQuery({
      queryKey: table_query_key,
      queryFn: async () => {
        let param = {
          type: table_query_key?.[1]?.type,
          dataset: table_query_key?.[1]?.dataset,
          from_payment_date: table_query_key?.[1]?.from_date,
          to_payment_date: table_query_key?.[1]?.to_date,
          multiScenarioIds: table_query_key?.[1]?.multiScenarioIds,
        };
        if (!dataSetData.use_global_categories) {
          param.category_dataset = dataSetData?.uuid;
        } else {
          param.global_category = true;
        }
        return await getStatisticsDataWithParams(param);
      },
      backgroundFetch: true,
      enabled:
        !!_isAllHeaderApiFetched &&
        !isFirstLoginOverlayOpen &&
        !isAccountSelectOverlayOpen &&
        table_query_key?.[1]?.multiScenarioIds?.length > 0 &&
        !!table_query_key?.[1]?.multiScenarioIds?.[0] &&
        !!table_query_key?.[1]?.dataset,
      priority: 5,
    });

    if (
      past_table_query_key?.[1]?.from_date >
        past_table_query_key?.[1]?.to_date &&
      past_table_data?.data
    ) {
      queryClient.removeQueries({
        queryKey: past_table_query_key,
      });
    }

    const transaction_monthly_chart = useStatusHook(
      `transaction_${tableType}_chart`
    );

    const isTableFetching =
      isCategoryLoading ||
      table_data?.isFetching ||
      transaction_monthly_chart?.isFetching ||
      past_table_data?.isFetching;

    //lifecycle
    useImperativeHandle(_ref, () => ({
      onCellChanged,
      getCategories,
    }));

    useUpdateEffect(() => {
      global.allowFetch = { Inflow: [], Outflow: [] };
      let options = {
        predicate: (query) => query.queryKey[0] === "transactions",
      };
      queryClient.resetQueries(options);
    }, [refreshData]);

    useEffect(() => {
      setTableLoading(isTableFetching);
    }, [isTableFetching]);

    useDebounce(
      () => {
        let startLiqArray = [];
        let netChangeArray = [];
        let endLiqArray = [];
        let vatArray = [];
        let vatChargeArray = [];

        let startLiqNumberArray = [];
        let netChangeNumberArray = [];
        let endLiqNumberArray = [];
        let vatNumberArray = [];
        let vatChargeNumberArray = [];
        let LiquidityTotal = 0;

        if (
          !AllStaff?.isFetching &&
          !!selected_scenario_uuid?.[0] &&
          !tableLoading &&
          gridColumn?.length &&
          selectionCategories
        ) {
          const recurring_rules =
            store.getState()?.globalSlice?.recurring_rules;
          const isBase = _scenario_?.title === "Base";
          const key =
            tableType === "quarterly"
              ? "quarter"
              : tableType === "weekly"
                ? "week"
                : tableType === "daily"
                  ? "day"
                  : "month";
          setTableProcessing(true);

          const monthlyTransactions = table_data?.data?.results
            ? [
                ...(past_table_data?.data?.results || []),
                ...(table_data?.data?.results || []),
              ]
            : [];
          const groupedMonthlyTransactions = _.groupBy(
            monthlyTransactions,
            (item) => item?.[key]
          );

          const staffListContacts = Staff?.map((o1) => o1?.uuid);
          const staffData = recurring_rules?.filter(
            (o1) =>
              o1.recurring_type === "employee" &&
              o1.contact &&
              staffListContacts?.includes(o1.contact)
          );
          const loanData = recurring_rules?.filter(
            (o1) =>
              o1.recurring_type === "loan" || o1.recurring_type === "leasing"
          );
          const groupByCategoryStaffData = _.groupBy(
            staffData,
            (item) => item?.transaction_category || "unCategorized"
          );
          const groupByCategoryLoanData = _.groupBy(
            loanData,
            (item) => item?.transaction_category || "unCategorized"
          );

          const currentMonth =
            tableType === "quarterly"
              ? format(startOfQuarter(new Date()), "yyyy-MM-dd")
              : tableType === "weekly"
                ? format(
                    startOfWeek(new Date(), {
                      weekStartsOn: 1,
                    }),
                    "yyyy-MM-dd"
                  )
                : tableType === "daily"
                  ? format(new Date(), "yyyy-MM-dd")
                  : format(new Date(), "yyyy-MM");

          let booked_balances =
            transaction_monthly_chart?.data?.booked_balances;

          const groupByMonthBookedBalances = _.groupBy(
            booked_balances,
            (item) => item?.[key]
          );
          const business_start_date = dataSetData?.business_start_date;

          let inflow = buildTable({
            income_expense_type: 1,
            categoryType: 1,
            business_start_date,
            name: "inflow",
            title: t("table_inflow"),
            total_key: "inflow",
            count_key: "inflow_count",
            searchText,
            unCategorizedRowId: "uncategorizedInflow",
            groupedMonthlyTransactions,
            monthlyTransactions,
            tableType,
            key,
            isBase,
            currentMonth,
          });
          let outflow = buildTable({
            income_expense_type: 2,
            business_start_date,
            categoryType: 2,
            name: "outflow",
            title: t("table_outflow"),
            total_key: "outflow",
            count_key: "outflow_count",
            searchText,
            unCategorizedRowId: "uncategorizedOutflow",
            groupByCategoryStaffData,
            groupByCategoryLoanData,
            groupedMonthlyTransactions,
            monthlyTransactions,
            tableType,
            key,
            isBase,
            currentMonth,
          });

          let prev_booked_balance = 0;
          let booked_balance = 0;
          let vatTotal = 0;
          gridColumn?.forEach((columnElement, index) => {
            if (index !== 0) {
              let _inflow = inflow?.[0]?.cells?.[index];
              let _outflow = outflow?.[0]?.cells?.[index];

              let monthEndTotal = 0;
              let vat_scenarios = _outflow?.item?.vat_scenarios;
              let monthEndVatTotal = _inflow?.item?.vat + _outflow?.item?.vat;
              let monthEndVatChargeTotal = _outflow?.item?.vat_charge || 0;
              let netChange = _inflow?.item?.value + _outflow?.item?.value;
              let relNetChange =
                _inflow?.item?.relValue + _outflow?.item?.relValue;

              const monthDate =
                tableType === "quarterly"
                  ? format(
                      startOfQuarter(new Date(columnElement?.item)),
                      "yyyy-MM-dd"
                    )
                  : tableType === "weekly"
                    ? format(
                        startOfWeek(new Date(columnElement?.item), {
                          weekStartsOn: 1,
                        }),
                        "yyyy-MM-dd"
                      )
                    : tableType === "daily"
                      ? format(new Date(columnElement?.item), "yyyy-MM-dd")
                      : format(new Date(columnElement?.item), "yyyy-MM");
              const currentDate =
                tableType === "quarterly"
                  ? format(startOfQuarter(new Date()), "yyyy-MM-dd")
                  : tableType === "weekly"
                    ? format(
                        startOfWeek(new Date(), {
                          weekStartsOn: 1,
                        }),
                        "yyyy-MM-dd"
                      )
                    : tableType === "daily"
                      ? format(new Date(), "yyyy-MM-dd")
                      : format(new Date(), "yyyy-MM");
              const prevMonthDate =
                tableType === "quarterly"
                  ? format(
                      subQuarters(new Date(columnElement?.item), 1),
                      "yyyy-MM-dd"
                    )
                  : tableType === "weekly"
                    ? format(
                        subWeeks(new Date(columnElement?.item), 1),
                        "yyyy-MM-dd"
                      )
                    : tableType === "daily"
                      ? format(
                          subDays(new Date(columnElement?.item), 1),
                          "yyyy-MM-dd"
                        )
                      : format(
                          subMonths(new Date(columnElement?.item), 1),
                          "yyyy-MM"
                        );

              const isPastMonth = monthDate < currentMonth;
              const isCurrentMonth = monthDate === currentMonth;
              const isFutureMonth = monthDate > currentMonth;
              const subtractVat =
                advanceVat?.enabled &&
                (monthDate > currentMonth ||
                  (monthDate === currentMonth &&
                    getDate(new Date()) <= Number(advanceVat?.due_day ?? 1)));
              const prev_balance =
                groupByMonthBookedBalances?.[
                  prevMonthDate > currentMonth ? currentDate : prevMonthDate
                ]?.[0];
              const balance =
                groupByMonthBookedBalances?.[
                  isFutureMonth ? currentDate : monthDate
                ]?.[0];

              prev_booked_balance =
                (prev_balance?.booked_balance || 0) +
                  (prev_balance?.ignored_balance || 0) || prev_booked_balance;

              booked_balance =
                (balance?.booked_balance || 0) +
                  (balance?.ignored_balance || 0) || booked_balance;
              if (index === 1) {
                let startValue = getStartingLiquidityValue({
                  past_table_data: monthlyTransactions,
                  selected_scenario_uuid,
                  business_start_date,
                  date: monthDate,
                  selectionCategories,
                  selectionCategoriesByID,
                  advanceVat,
                  isTable: true,
                  key,
                });
                LiquidityTotal = startValue?.LiquidityTotal;
                vatTotal = startValue?.VatChargeTotal;
                // console.log(
                //   "🚀  startValue:",
                //   monthDate,
                //   startValue,
                //   booked_balance
                // );

                if (isFutureMonth) {
                  LiquidityTotal =
                    (LiquidityTotal || 0) +
                    (booked_balance || 0) +
                    (transaction_monthly_chart?.data?.booked_balances
                      ?.length === 0
                      ? Number(todayLiq || 0)
                      : 0);
                }
                if (subtractVat) {
                  LiquidityTotal = (LiquidityTotal || 0) - (vatTotal || 0);
                }
              }
              let startTotal =
                (LiquidityTotal ?? 0) +
                (isFutureMonth ? 0 : prev_booked_balance ?? 0);
              startLiqNumberArray.push({
                item: {
                  isCurrentMonth,
                  date: monthDate,
                  type: "numeric",
                  value: startTotal,
                },
                fontWeight: isCurrentMonth ? 700 : 500,
                customCell: true,
                showFontColor: true,
                backgroundColor: getTailwindColor(
                  isBase ? "grey" : _scenario_?.color || "slate",
                  isCurrentMonth ? 100 : 50
                ),
                color: _scenario_?.color,
                borderColor: getTailwindColor(_scenario_?.color, 200),
              });

              // if (isPastMonth) {
              //   relNetChange = booked_balance - prev_booked_balance;
              //   // netChange = booked_balance - prev_booked_balance;
              // }

              if (!isPastMonth) {
                monthEndTotal = Number(relNetChange || 0);
              }

              let obj = {
                isCurrentMonth,
                date: columnElement?.item,
                value: 0,
                type: "numeric",
              };
              LiquidityTotal =
                (isCurrentMonth ? booked_balance ?? 0 : 0) +
                (LiquidityTotal ?? 0) +
                (monthEndTotal ?? 0);

              let endTotal =
                (LiquidityTotal ?? 0) + (isPastMonth ? booked_balance ?? 0 : 0);

              // if (isCurrentMonth) {
              //   console.log(
              //     "🚀 /table isCurrentMonth:",
              //     monthDate,
              //     startTotal,
              //     monthEndTotal,
              //     LiquidityTotal,
              //     endTotal
              //   );
              // }

              netChangeNumberArray.push({
                ..._inflow,
                borderColor: getTailwindColor(_scenario_?.color, 200),
                backgroundColor: getTailwindColor(
                  isBase ? "grey" : _scenario_?.color || "slate",
                  isCurrentMonth ? 100 : 50
                ),
                color: netChange < 0 ? "red" : "green",
                clickable: false,
                showCursor: false,
                item: {
                  ..._inflow?.item,
                  value: netChange,
                  value2: `${t("absolute_net_change_tooltip")}: ${parseFloat((booked_balance || 0) - (prev_booked_balance || 0))?.toFixed(2)}`,
                  //relative_net_change_tooltip
                },
              });
              monthEndVatTotal = -monthEndVatTotal;
              // console.log(
              //   "🚀 monthEndVatChargeTotal:",
              //   monthDate,
              //   subtractVat,
              //   monthEndVatChargeTotal
              // );

              vatChargeNumberArray.push({
                item: {
                  ...obj,
                  value: monthEndVatChargeTotal,
                  scenarios: vat_scenarios,
                  type: "numeric",
                },
                isExcludedFromCalculation: !subtractVat,
                showFontColor: subtractVat,
                fontStyle: !subtractVat ? "italic" : "inherit",
                subtractVat,
                hidePercentage: true,
                fontWeight: isCurrentMonth ? 700 : 500,
                customCell: true,
                color: _scenario_?.color,
                borderColor: getTailwindColor(_scenario_?.color, 200),
                backgroundColor: getTailwindColor(
                  isBase ? "zinc" : _scenario_?.color || "slate",
                  isCurrentMonth ? 100 : 50
                ),
              });
              endLiqNumberArray.push({
                item: {
                  ...obj,
                  value: endTotal,
                },
                fontWeight: isCurrentMonth ? 700 : 500,
                customCell: true,
                showFontColor: true,
                color: _scenario_?.color,
                borderColor: getTailwindColor(_scenario_?.color, 200),
                backgroundColor: getTailwindColor(
                  isBase ? "grey" : _scenario_?.color || "slate",
                  isCurrentMonth ? 100 : 50
                ),
              });
              vatNumberArray.push({
                item: {
                  ...obj,
                  value: monthEndVatTotal,
                },
                fontWeight: isCurrentMonth ? 700 : 500,
                customCell: true,
                color: _scenario_?.color,
                borderColor: getTailwindColor(_scenario_?.color, 200),
                backgroundColor: getTailwindColor(
                  isBase ? "zinc" : _scenario_?.color || "slate",
                  isCurrentMonth ? 100 : 50
                ),
              });
              // console.log(
              //   "🚀 / / LiquidityTotal:",
              //   monthDate,
              //   monthEndTotal,
              //   netChange,
              //   subtractVat,
              //   monthEndVatTotal,
              //   monthEndVatChargeTotal
              // );
            }
          });

          netChangeArray.push({
            rowId: "net_change",
            rowHeight,
            mergeCell: true,
            isHeader: true,
            parentRowId: null,
            color: "grey",
            cells: getRowsCell({
              cellStart: {
                item: {
                  uuid: "net_change",
                  value: t("Net Change"),
                  icon: (
                    <CustomIcon
                      icon={<TfiExchangeVertical />}
                      iconSize="14px"
                      backgroundColor={
                        scenario_color
                          ? getTailwindColor(scenario_color, 500)
                          : null
                      }
                      iconStyle={{
                        "& svg": {
                          strokeWidth: "1px",
                        },
                      }}
                    />
                  ),
                },
                gridWidth: gridWidth + spacing,
                fontWeight: 600,
                isColumnStartCell: true,
                clickable: false,
                backgroundColor: scenario_color
                  ? getTailwindColor(isBase ? "grey" : scenario_color, 50)
                  : "inherit",
                color: isBase ? "slate" : scenario_color,
                borderColor: scenario_color
                  ? getTailwindColor(scenario_color, 200)
                  : "slate",
              },
              data: netChangeNumberArray,
            }),
          });
          endLiqArray.push({
            rowId: "end_liquidity",
            rowHeight,
            mergeCell: true,
            isHeader: true,
            parentRowId: null,
            color: "grey",
            cells: getRowsCell({
              cellStart: {
                item: {
                  uuid: "end_liquidity",
                  value: t("Liquidity End"),
                  icon: (
                    <CustomIcon
                      icon={<MdOutlineShowChart />}
                      backgroundColor={
                        scenario_color
                          ? getTailwindColor(scenario_color, 500)
                          : null
                      }
                      iconSize="22px"
                      iconStyle={{
                        transform: `translate(-50%, -50%) rotate(10deg)`,
                      }}
                    />
                  ),
                },
                gridWidth: gridWidth + spacing,
                fontWeight: 600,
                isColumnStartCell: true,
                backgroundColor: getTailwindColor(
                  isBase ? "grey" : _scenario_?.color,
                  50
                ),
                color: isBase ? "slate" : _scenario_?.color,
                borderColor: getTailwindColor(_scenario_?.color, 200),
              },
              data: endLiqNumberArray,
            }),
          });
          vatArray.push({
            rowId: "vat",
            rowHeight,
            mergeCell: true,
            isHeader: true,
            parentRowId: null,
            color: "grey",
            cells: getRowsCell({
              cellStart: {
                item: {
                  uuid: "vat",
                  value: t("VAT"),
                  value2: t("VAT_tooltip"),
                  icon: (
                    <CustomIcon
                      icon={<TbPercentage />}
                      iconSize="14px"
                      backgroundColor={
                        scenario_color
                          ? getTailwindColor(scenario_color, 500)
                          : null
                      }
                      iconStyle={{
                        "& svg": {
                          strokeWidth: "3.5px",
                        },
                      }}
                    />
                  ),
                },
                gridWidth: gridWidth + spacing,
                fontWeight: 600,
                isColumnStartCell: true,
                backgroundColor: getTailwindColor(
                  isBase ? "zinc" : _scenario_?.color || "slate",
                  50
                ),
                color: _scenario_?.color,
                borderColor: getTailwindColor(_scenario_?.color, 200),
              },
              data: vatNumberArray,
            }),
          });
          startLiqArray.push({
            rowId: "start_liquidity",
            rowHeight,
            mergeCell: true,
            isHeader: true,
            parentRowId: null,
            color: "grey",
            cells: getRowsCell({
              cellStart: {
                item: {
                  uuid: "start_liquidity",
                  value: t("Liquidity Start"),
                  icon: (
                    <CustomIcon
                      icon={<MdOutlineShowChart />}
                      iconSize="22px"
                      backgroundColor={
                        scenario_color
                          ? getTailwindColor(scenario_color, 500)
                          : null
                      }
                      iconStyle={{
                        transform: `translate(-50%, -50%) rotate(10deg)`,
                      }}
                    />
                  ),
                },
                gridWidth: gridWidth + spacing,
                fontWeight: 600,
                isColumnStartCell: true,
                backgroundColor: getTailwindColor(
                  isBase ? "grey" : _scenario_?.color,
                  50
                ),
                color: isBase ? "slate" : _scenario_?.color,
                borderColor: getTailwindColor(_scenario_?.color, 200),
              },
              data: startLiqNumberArray,
            }),
          });
          vatChargeArray.push({
            rowId: "VAT_Imputation",
            rowHeight,
            mergeCell: true,
            isHeader: true,
            parentRowId: null,
            color: "grey",
            cells: getRowsCell({
              cellStart: {
                item: {
                  uuid: "VAT_Imputation",
                  value: t("VAT_Imputation"),
                  value2: t("VAT_Imputation_tooltip"),
                  link: "/settings/vats",
                  icon: (
                    <CustomIcon
                      icon={<TbPercentage />}
                      iconSize="14px"
                      backgroundColor={
                        scenario_color
                          ? getTailwindColor(scenario_color, 500)
                          : null
                      }
                      iconStyle={{
                        "& svg": {
                          strokeWidth: "3.5px",
                        },
                      }}
                    />
                  ),
                },
                gridWidth: gridWidth + spacing,
                fontWeight: 600,
                isColumnStartCell: true,
                backgroundColor: getTailwindColor(
                  isBase ? "zinc" : _scenario_?.color || "slate",
                  50
                ),
                color: _scenario_?.color,
                borderColor: getTailwindColor(_scenario_?.color, 200),
              },
              data: vatChargeNumberArray,
            }),
          });

          setData((prev) => ({
            ...prev,
            vat: [...vatArray],
            start_liquidity: [...startLiqArray],
            end_liquidity: [...endLiqArray],
            VAT_Imputation: [...vatChargeArray],
            inflow: [...inflow],
            outflow: [...outflow],
            net_change: [...netChangeArray],
          }));
          setTableProcessing(false);
        }
      },
      1000,
      [
        todayLiq,
        tableType,
        gridColumn,
        advanceVat,
        scenario,
        state,
        refreshData,
        tableLoading,
        AllStaff?.isFetching,
        highlightedScenarios,
        searchText,
        dataSetData?.business_start_date,
        selectionsCategoryUuidCollections,
        Staff,
        t,
      ],
      true
    );

    //api
    const getCategories = async () => {
      // let endUrl = "";
      // if (!use_global_categories) {
      //   endUrl = `?dataset=${dataset}`;
      // } else {
      //   endUrl = `?is_global=true`;
      // }
      setIsCategoryLoading(true);
      await APICall("get", EndPoints.category)
        .then((response) => {
          if (response.status === 200 && response.data) {
            dispatch(
              setCategories({ data: response.data.results, type: "all" })
            );
            setIsCategoryLoading(false);
            table_data?.refetch();
          }
        })
        .finally(() => {
          setIsCategoryLoading(false);
        });
    };

    const updateCardByID = async (id, obj) => {
      await APICall("patch", EndPoints.transactions + `${id}/`, obj).then(
        (response) => {
          if (response.status === 200 && response.data) {
            resetKanban();
          }
        }
      );
    };

    const addCard = async (obj) => {
      await APICall("post", EndPoints.transactions, obj).then((response) => {
        if (response.status === 201 && response.data) {
          resetKanban();
        }
      });
    };

    //functions
    const onCellChanged = (cell) => {
      if (cell) {
        const scenario_title =
          scenarioById?.[highlightedScenarios?.[0]]?.[0]?.title;
        let value = cell?.value;
        if (value) {
          if (cell?.cell?.item?.income_expense_type === 2) {
            if (!cell?.value?.toString()?.includes("-")) {
              value = -cell?.value;
            }
          } else {
            if (cell?.value?.toString()?.includes("-")) {
              value = Math.abs(cell?.value);
            }
          }

          if (cell?.uuid) {
            let obj = {
              gross_value: value || 0,
            };
            if (scenario_title) {
              obj.scenario = scenario_title;
            }

            updateCardByID(cell.uuid, obj);
          } else {
            let category = null;
            if (
              cell?.rowId === "uncategorized" ||
              cell?.rowId === "uncategorizedInflow" ||
              cell?.rowId === "uncategorizedOutflow"
            ) {
              category = null;
            } else {
              category = cell.rowId;
            }
            let categoryTitle = "unCategorized";
            let tax = null;
            if (category) {
              categoryTitle =
                selectionCategoriesByID && selectionCategoriesByID[category]
                  ? selectionCategoriesByID[category][0].label
                  : "unCategorized";
              tax = selectionCategoriesByID?.[category]?.[0].tax;
            }
            const title = `Planning Value - ${format(
              new Date(cell?.cell?.item?.date),
              "MMMM yy"
            )} - ${categoryTitle}`;
            let obj = {
              title: title,
              dataset: dataSetData?.uuid,
              state: "Planned",
              scenario: scenario_title || "Base",
              note: "",
              due_date: format(new Date(cell?.cell?.item?.date), "yyyy-MM-dd"),
              gross_value: value || 0,
              category: category,
              tax: tax,
              invoice_date: null,
              source: 1,
              data_source: dataSetData?.internal_data_source,
              position: 1001,
            };
            internalDsRef.current?.enableInternalDS();
            addCard(obj);
          }
        }
      }
    };

    const resetKanban = async () => {
      global.allowFetch = { Inflow: [], Outflow: [] };
      let options = {
        predicate: (query) =>
          query.queryKey[0] === "transactions" &&
          query.queryKey[1]?.dataset === dataSetData?.uuid,
      };
      queryClient.invalidateQueries(options);
    };

    const buildTable = ({
      income_expense_type,
      business_start_date,
      categoryType,
      name,
      title,
      total_key,
      count_key,
      searchText,
      unCategorizedRowId,
      groupByCategoryStaffData,
      groupByCategoryLoanData,
      groupedMonthlyTransactions,
      monthlyTransactions,
      tableType,
      key,
      isBase,
      currentMonth,
    }) => {
      let array = [];
      let inflowArray = [];
      let inflowUnCatArray = [];
      let categoryNumberObj = {};
      const color = income_expense_type === 1 ? "green" : "red";

      const categoriesToCheck = selectionCategories.filter(
        (element) =>
          element.visible &&
          element.type === categoryType &&
          (searchText
            ? element.label.toLowerCase().includes(searchText.toLowerCase())
            : true)
      );
      gridColumn?.forEach((columnElement) => {
        if (columnElement?.item) {
          const monthDate =
            tableType === "quarterly"
              ? format(
                  startOfQuarter(new Date(columnElement?.item)),
                  "yyyy-MM-dd"
                )
              : tableType === "weekly"
                ? format(
                    startOfWeek(new Date(columnElement?.item), {
                      weekStartsOn: 1,
                    }),
                    "yyyy-MM-dd"
                  )
                : tableType === "daily"
                  ? format(new Date(columnElement?.item), "yyyy-MM-dd")
                  : format(
                      startOfMonth(new Date(columnElement?.item)),
                      "yyyy-MM"
                    );
          const statsData = groupedMonthlyTransactions?.[monthDate] ?? [];
          const monthData = statsData?.filter(
            (o1) =>
              !Constant?.calculationExcludeStates2.includes(o1?.state) &&
              selected_scenario_uuid?.includes(o1?.scenario_uuid)
          );

          let monthEndTotal = 0;
          let relBookedTotal = 0;
          let monthEndVatTotal = 0;
          let monthEndVatChargeTotal = 0;
          let categoryWisePastTotal = {};
          let pastTotal = null;
          let vatData = [];

          const isPastMonth = monthDate < currentMonth;
          const isCurrentMonth = monthDate === currentMonth;
          const isFutureMonth = monthDate > currentMonth;

          const {
            subtractVat = false,
            isVatEnabled = false,
            isSameVatDay = false,
            startVatDate,
            endVatDate,
            isVatPast = false,
            isVatCurrent = false,
            isVatFuture = false,
          } = getVatInfo({
            monthDate,
            isCurrentMonth,
            isFutureMonth,
            advanceVat,
            business_start_date,
            key,
          });

          if (isPastMonth) {
            const past_booked_data = monthData?.filter(
              (item) =>
                !Constant?.excludeCategoryTypes?.includes(
                  selectionCategoriesByID?.[item?.category]?.[0]?.category_type
                ) && Constant?.bookedStates?.includes(item.state)
            );
            const past_booked_total = past_booked_data?.reduce(
              (total, item) =>
                parseFloat(total) + parseFloat(item?.[name] ?? 0),
              0
            );
            const past_booked_vat_total = past_booked_data?.reduce(
              (total, item) =>
                parseFloat(total) + parseFloat(item?.[`vat_${name}`] ?? 0),
              0
            );
            monthEndTotal = past_booked_total;
            monthEndVatTotal = past_booked_vat_total;
          }
          if (isCurrentMonth) {
            const allData = monthlyTransactions.filter(
              (item) =>
                item?.[key] < currentMonth &&
                (item?.category
                  ? !Constant?.excludeCategoryTypes?.includes(
                      selectionCategoriesByID?.[item?.category]?.[0]
                        ?.category_type
                    )
                  : true) &&
                selected_scenario_uuid?.includes(item.scenario_uuid) &&
                !Constant.plannedState.includes(item.state) &&
                !Constant?.calculationExcludeStates.includes(item?.state)
            );
            const data = monthData?.filter((item) =>
              item?.category
                ? !Constant?.excludeCategoryTypes?.includes(
                    selectionCategoriesByID?.[item?.category]?.[0]
                      ?.category_type
                  )
                : true
            );

            const monthTotal = getMonthlyValue({
              selectionCategories,
              selectionCategoriesByID,
              data,
              type: categoryType,
              time: "current",
              allData,
              isTable: true,
              isCheck: true,
              key,
            });

            relBookedTotal = monthTotal?.currentBookedTotal;
            monthEndTotal = monthTotal?.total || 0;
            monthEndVatTotal = monthTotal?.vat || 0;
            categoryWisePastTotal = monthTotal?.categoryWisePastTotal;
            pastTotal = categoryWisePastTotal?.["unCategorized"] || 0;
            // console.log(
            //   "🚀/---> monthTotal:",
            //   currentMonth,
            //   subtractVat,
            //   monthTotal,
            //   data,
            //   allData,
            //   monthlyTransactions
            // );
          }
          if (isFutureMonth) {
            const data = monthData?.filter((item) =>
              item?.category
                ? !Constant?.excludeCategoryTypes?.includes(
                    selectionCategoriesByID?.[item?.category]?.[0]
                      ?.category_type
                  )
                : true
            );
            const monthTotal = getMonthlyValue({
              selectionCategories,
              selectionCategoriesByID,
              data,
              type: categoryType,
              time: "future",
              key,
            });
            monthEndTotal = monthTotal?.total || 0;
            monthEndVatTotal = monthTotal?.vat || 0;
          }
          let vat_scenarios = [];
          if (total_key === "outflow") {
            if (isVatEnabled && isSameVatDay) {
              vatData = monthlyTransactions?.filter(
                (item) =>
                  format(new Date(item?.[key]), "yyyy-MM-dd") >= startVatDate &&
                  format(new Date(item?.[key]), "yyyy-MM-dd") <= endVatDate &&
                  (item?.category
                    ? !Constant?.excludeCategoryTypes?.includes(
                        selectionCategoriesByID?.[item?.category]?.[0]
                          ?.category_type
                      )
                    : true) &&
                  selected_scenario_uuid?.includes(item.scenario_uuid) &&
                  !Constant?.calculationExcludeStates2.includes(item?.state)
              );
            }
            if (isVatPast && isSameVatDay) {
              const Vdata = vatData?.filter((item) =>
                isBookedStates({ state: item?.state })
              );
              vat_scenarios = [
                ...new Set(
                  vatData?.map(
                    (o1) => o1.scenario !== "Base" && o1.scenario_uuid
                  )
                ),
              ];

              monthEndVatChargeTotal = Vdata?.reduce(
                (total, item) =>
                  parseFloat(total) +
                  parseFloat(item?.vat_outflow ?? 0) +
                  parseFloat(item?.vat_inflow ?? 0),
                0
              );
              // console.log("🚀  Vdata:", Vdata, monthEndVatChargeTotal);
            }
            if (isVatCurrent && isSameVatDay) {
              vat_scenarios = [
                ...new Set(
                  vatData?.map(
                    (o1) => o1.scenario !== "Base" && o1.scenario_uuid
                  )
                ),
              ];
              const allData = monthlyTransactions.filter(
                (item) =>
                  item?.[key] < startVatDate &&
                  selected_scenario_uuid?.includes(item.scenario_uuid) &&
                  !Constant.plannedState.includes(item.state) &&
                  !Constant?.calculationExcludeStates.includes(item?.state)
              );
              const currentInflowVat = getMonthlyValue({
                selectionCategories,
                selectionCategoriesByID,
                data: vatData,
                type: 1,
                time: "current",
                calculation_type: "vat",
                allData,
                isCheck: true,
                key,
              });

              const currentOutflowVat = getMonthlyValue({
                selectionCategories,
                selectionCategoriesByID,
                data: vatData,
                type: 2,
                time: "current",
                calculation_type: "vat",
                allData,
                key,
              });
              // console.log(
              //   "🚀 / currentInflowVat:",
              //   currentInflowVat,
              //   currentOutflowVat
              // );
              // (currentInflowVat?.past?.vat || 0) +
              // (currentOutflowVat?.past?.vat || 0);
              monthEndVatChargeTotal =
                (currentInflowVat?.vat || 0) + (currentOutflowVat?.vat || 0);
            }
            if (isVatFuture && isSameVatDay) {
              if (income_expense_type === 1) {
                vat_scenarios = [
                  ...new Set(
                    vatData?.map(
                      (o1) => o1.scenario !== "Base" && o1.scenario_uuid
                    )
                  ),
                ];
              }

              const futureInflowVat = getMonthlyValue({
                selectionCategories,
                selectionCategoriesByID,
                data: vatData,
                type: 1,
                time: "future",
                calculation_type: "vat",
                key,
              });
              const futureOutflowVat = getMonthlyValue({
                selectionCategories,
                selectionCategoriesByID,
                data: vatData,
                type: 2,
                time: "future",
                calculation_type: "vat",
                key,
              });
              monthEndVatChargeTotal =
                (futureInflowVat?.vat || 0) + (futureOutflowVat?.vat || 0);
            }
            monthEndVatChargeTotal = -monthEndVatChargeTotal;
            // console.log(
            //   "🚀/ isVatEnabled:",
            //   monthDate,
            //   startVatDate,
            //   endVatDate,
            //   isSameVatDay,
            //   vat_pay_date,
            //   vatData,
            //   isVatPast,
            //   isVatCurrent,
            //   isVatFuture
            // );
          }

          const obj = {
            isPastMonth,
            isFutureMonth,
            isCurrentMonth,
            date: columnElement?.item,
            count: 0,
            value: 0,
            type: "numeric",
            income_expense_type,
            startLiqCurrentMonth: isCurrentMonth,
          };
          const _monthEndVatChargeTotal =
            (income_expense_type === 1 &&
            monthEndVatChargeTotal > 0 &&
            subtractVat
              ? monthEndVatChargeTotal
              : 0) +
            (income_expense_type === 2 &&
            monthEndVatChargeTotal <= 0 &&
            subtractVat
              ? monthEndVatChargeTotal
              : 0);
          const inflow = monthEndTotal + _monthEndVatChargeTotal;
          // if (isCurrentMonth) {
          //   console.log(
          //     "🚀/---> isCurrentMonth:",
          //     monthDate,
          //     income_expense_type,
          //     // monthEndTotal,
          //     // monthEndVatChargeTotal,
          //     inflow,
          //     relBookedTotal,
          //     inflow - relBookedTotal
          //   );
          // }
          let { total: inflowArrayTotal } = getCellInfo({
            data: monthData,
            total_key: total_key,
            count_key: count_key,
            isPastMonth,
            isFutureMonth,
            isCurrentMonth,
            highlightedScenarios,
            pastTotal,
            category: null,
            doNotIncludeCalculationExcludeCategory: true,
          });
          // if (
          //   isPastMonth &&
          //   monthData?.length > 0 &&
          //   income_expense_type === 1
          // ) {
          //   console.log(
          //     "🚀  monthData:",
          //     monthDate,
          //     inflowArrayTotal,
          //     inflow
          //   );
          // }
          inflowArray.push({
            item: {
              ...obj,
              total: inflowArrayTotal,
              value: inflow,
              // relValue: inflow,
              relValue: inflow - relBookedTotal, //booked will come from balance , so remove real booked value
              vat: monthEndVatTotal,
              vat_charge: monthEndVatChargeTotal,
              vat_scenarios,
            },
            fontWeight: isCurrentMonth ? 700 : 500,
            customCell: true,
            editable: false,
            showCursor: true,
            color: inflow === 0 ? color : inflow > 0 ? "green" : "red",
            borderColor: getTailwindColor(color, 200),
            backgroundColor: getTailwindColor(
              isBase ? color : _scenario_?.color || "slate",
              isCurrentMonth ? 100 : 50
            ),
          });

          const unCategoryData = monthData.filter((o1) => !o1.category);

          let {
            total,
            count,
            value,
            isPlanned,
            percentageChange,
            bookedValue,
            openValue,
            plannedValue,
            compareValue,
            compareCount,
            compareTotal,
            plannedUuid,
            scenarios,
            isEditable,
            is_note_user_edited,
            is_note_available,
            is_note_highlighted,
          } = getCellInfo({
            data: unCategoryData,
            total_key: total_key,
            count_key: count_key,
            isPastMonth,
            isFutureMonth,
            isCurrentMonth,
            highlightedScenarios,
            pastTotal,
            category: null,
          });

          inflowUnCatArray.push({
            item: {
              ...obj,
              count: count ?? 0,
              value: !value || value === 0 ? "" : value,
              pastTotal,
              total: total,
              percentageChange:
                count.plannedCount >= 1 ? percentageChange : null,
              bookedValue,
              openValue,
              plannedValue,
              compareValue,
              compareCount,
              compareTotal,
              scenarios,
              uuid: plannedUuid?.[total_key],
            },
            isPlanned,
            is_note_user_edited,
            is_note_available,
            is_note_highlighted,
            editable: isEditable,
            // clickable: !isSinglePlanned,
            clickable: true,
            fontWeight:
              (isPlanned && count.plannedCount > 1) || isCurrentMonth
                ? 700
                : 500,
            customCell: true,
            fontStyle:
              isPlanned && count.plannedCount >= 1 && !isPastMonth
                ? "italic"
                : "normal",
            backgroundColor: isPastMonth
              ? theme.palette.color.white
              : scenarios?.some((o1) => highlightedScenarios?.includes(o1))
                ? getTailwindColor(
                    _scenario_?.color || "slate",
                    isCurrentMonth ? 100 : 50
                  )
                : isCurrentMonth
                  ? getTailwindColor("slate", 50)
                  : theme.palette.color.white,
          });

          categoriesToCheck?.forEach((element) => {
            const categories =
              selectionsCategoryUuidCollections?.[element.value];
            const categoryData = monthData.filter((o1) =>
              categories.includes(o1.category)
            );
            if (isCurrentMonth) {
              let all_past_total = 0;
              categories.forEach((o1) => {
                all_past_total =
                  all_past_total + categoryWisePastTotal?.[o1] || 0;
              });
              pastTotal = all_past_total;
            }

            let {
              total,
              count,
              value,
              isPlanned,
              percentageChange,
              bookedValue,
              openValue,
              plannedValue,
              compareValue,
              compareCount,
              compareTotal,
              plannedUuid,
              scenarios,
              isEditable,
              is_note_user_edited,
              is_note_available,
              is_note_highlighted,
              isExcludedFromCalculation,
            } = getCellInfo({
              data: categoryData,
              total_key: total_key,
              count_key: count_key,
              isPastMonth,
              isFutureMonth,
              isCurrentMonth,
              highlightedScenarios,
              pastTotal,
              category: element,
            });

            if (!categoryNumberObj[element?.value]) {
              categoryNumberObj[element?.value] = [];
            }
            categoryNumberObj[element?.value]?.push({
              item: {
                ...obj,
                value,
                pastTotal,
                count: count,
                total: total,
                percentageChange:
                  count.plannedCount >= 1 ? percentageChange : null,
                bookedValue,
                openValue,
                plannedValue,
                compareValue,
                compareCount,
                compareTotal,
                uuid: plannedUuid?.[total_key],
                scenarios,
              },
              isPlanned,
              isExcludedFromCalculation,
              is_note_user_edited,
              is_note_available,
              is_note_highlighted,
              editable: isEditable,
              // clickable: !isSinglePlanned,
              clickable: true,
              fontWeight:
                (isPlanned && count.plannedCount > 1) || isCurrentMonth
                  ? 700
                  : 500,
              customCell: true,
              fontStyle:
                isPlanned && count.plannedCount >= 1 && !isPastMonth
                  ? "italic"
                  : "normal",
              color:
                value > 0 && income_expense_type === 2
                  ? "green"
                  : value < 0 && income_expense_type === 1
                    ? "red"
                    : "slate",
              backgroundColor: isPastMonth
                ? theme.palette.color.white
                : scenarios?.some((o1) => highlightedScenarios?.includes(o1))
                  ? getTailwindColor(
                      _scenario_?.color || "slate",
                      isCurrentMonth ? 100 : 50
                    )
                  : isCurrentMonth
                    ? getTailwindColor("slate", 50)
                    : theme.palette.color.white,
            });
          });
        }
      });

      array.push({
        rowId: name,
        rowHeight,
        mergeCell: true,
        isHeader: true,
        hasChildren: true,
        parentRowId: null,
        color: color,
        cells: getRowsCell({
          cellStart: {
            item: {
              uuid: name,
              value: t(title),
              action: "edit_category",
              icon: (
                <CustomIcon
                  icon={<FaArrowUp />}
                  iconSize="14px"
                  backgroundColor={
                    name === "inflow"
                      ? theme?.palette?.color?.green[500]
                      : theme?.palette?.color?.red[500]
                  }
                  iconStyle={{
                    transform: `translate(-50%, -50%) rotate(${
                      name === "inflow" ? 45 : 135
                    }deg)`,
                  }}
                />
              ),
            },
            gridWidth: gridWidth + spacing,
            fontWeight: 600,
            clickable: true,
            isColumnStartCell: true,
            backgroundColor: getTailwindColor(color, 100),
            borderColor: getTailwindColor(color, 200),
          },
          data: inflowArray,
        }),
      });
      if (
        searchText
          ? "UnCategorized".toLowerCase().includes(searchText?.toLowerCase())
          : true
      ) {
        array.push({
          rowId: unCategorizedRowId,
          rowHeight,
          mergeCell: true,
          hasChildren: false,
          parentRowId: name,
          cells: getRowsCell({
            cellStart: {
              item: {
                uuid: unCategorizedRowId,
                value: t("Uncategorized"),
                income_expense_type,
              },
              backgroundColor: getTailwindColor(color, 50),
              clickable: true,
              isSticky: true,
              isHover: true,
              gridWidth: gridWidth + spacing,
              isColumnStartCell: true,
              borderColor: getTailwindColor(color, 200),
            },
            data: inflowUnCatArray,
          }),
        });
      }

      categoriesToCheck?.forEach((element) => {
        const parentCategory = selectionCategoriesByID?.[element?.parent]?.[0];

        const isStaff = foundIcon({
          data: groupByCategoryStaffData,
          category: element,
        });
        const isLoan = foundIcon({
          data: groupByCategoryLoanData,
          category: element,
        });
        let postIcon = [];
        if (Boolean(isStaff)) {
          postIcon.push({
            icon: <IoPeople />,
            tooltip: "table_cell_staff_icon_tooltip",
          });
        }
        if (Boolean(isLoan)) {
          postIcon.push({
            icon: <TbBuildingBank />,
            tooltip: "table_cell_loan_icon_tooltip",
          });
        }
        array.push({
          rowId: element?.value,
          rowHeight,
          mergeCell: true,
          parentRowId: element?.parent ? element?.parent : name,
          hasChildren: element?.hasChildren,
          depth: element?.depth,
          isLastCategoryChildCell:
            parentCategory?.children?.[parentCategory?.children?.length - 1]
              ?.value === element?.value,
          cells: getRowsCell({
            cellStart: {
              item: {
                uuid: element?.value,
                searchText,
                value: element?.immutable ? t(element?.label) : element?.label,
                value2: Constant.CategoryType?.[element?.category_type]
                  ? `${t("category_type")}: ${t(Constant.CategoryType?.[element?.category_type])}`
                  : "",
                income_expense_type,
                postIcon,
              },
              backgroundColor: getTailwindColor(color, 50),
              isSticky: true,
              isHover: true,
              isColumnStartCell: true,
              gridWidth: gridWidth + spacing,
              clickable: true,
              borderColor: getTailwindColor(color, 200),
              patternColor: color,
            },
            data: categoryNumberObj[element?.value],
          }),
        });
      });

      return array;
    };

    return null;
  }
);

const foundIcon = ({ category, data }) => {
  let isFound = data?.[category?.value]?.[0];

  if (!isFound && category?.children?.length > 0) {
    for (let i = 0; i < category?.children?.length; i++) {
      const ele = category?.children?.[i];
      isFound = data?.[ele?.value]?.[0];

      if (isFound) {
        break;
      }
      if (!isFound && ele?.children?.length > 0) {
        for (let j = 0; j < ele?.children?.length; j++) {
          const sub = ele?.children?.[j];
          isFound = data?.[sub?.value]?.[0];

          if (isFound) {
            break;
          }
        }
      }

      if (isFound) {
        break;
      }
    }
  }
  return isFound;
};
