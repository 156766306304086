import { styled } from "@mui/material/styles";
import { Fonts } from "../../Helper";
import { Tab, Tabs } from "@mui/material";
import { forwardRef } from "react";
export const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  fontFamily: Fonts.Text,
  alignItems: "center",
  overflow: "auto",
  minHeight: 0,
  width: "max-contents",
  height: "100%",
  "&.MuiTabs-root": {
    width: "100%",
    [theme.breakpoints.up("s1280")]: {
      width: "auto",
    },
  },
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    height: "0.3rem",
    backgroundColor: theme.palette.primary.dark,
  },
  "&.MuiTabs-indicatorSpan": {},
}));

export const StyledTab = styled(
  forwardRef((props, ref) => <Tab ref={ref} {...props} />)
)(({ theme }) => ({
  textTransform: "none",
  fontWeight: 600,
  fontSize: "0.9rem",
  minHeight: 0,
  height: "4rem", //header height
  lineHeight: 0,
  fontFamily: Fonts.Text,
  justifyContent: "flex-start",
  overflow: "visible",
  padding: "1rem 0.8rem",
  minWidth: "100%",
  [theme.breakpoints.up("s1280")]: {
    minWidth: "7rem",
  },
  marginRight: "0.5rem",
  color: theme.palette.color.slate[700],
  border: `4px solid transparent`,
  borderBottom: `2px solid transparent`,
  "&:hover": {
    opacity: 1,
    backgroundColor: theme.palette.color.slate[200],
    color: theme.palette.primary.dark,
    "& svg,p": {
      color: `${theme.palette.primary.dark} !important`,
    },
  },

  "&.Mui-selected": {
    padding: "1rem 0.8rem",
    color: theme.palette.primary.dark,
    "& svg": {
      color: `${theme.palette.primary.dark} !important`,
    },
  },
  "&.Mui-focusVisible": {
    backgroundColor: theme.palette.color.slate[200],
  },
  "& svg": {
    marginRight: "0.5rem !important",
    fontSize: "1.3rem",
    color: theme.palette.color.slate[700],
  },
}));
