import {
  ListItemText,
  Typography,
  IconButton,
  Container,
  Divider,
  Tooltip,
  styled,
  Paper,
  Stack,
  Grid,
  Box,
} from "@mui/material";
import {
  eachMonthOfInterval,
  differenceInMonths,
  startOfMonth,
  endOfMonth,
  addMonths,
  subMonths,
  isValid,
  format,
} from "date-fns";
import React, { useCallback, useMemo, useRef, useState } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import { MdOutlineCategory } from "react-icons/md";
import { TbBuildingBank } from "react-icons/tb";
import { useTranslation } from "react-i18next";
import { alpha } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";
import { useTheme } from "@mui/styles";
import { v4 as v4uuid } from "uuid";
import { t } from "i18next";
import _ from "underscore";

import {
  deleteBatchTransactions,
  addBatchTransactions,
  getCategoryParentId,
  formatDateToLocal,
  formatAmount,
  truncate,
  sleep,
} from "../../../../Helper/data";
import {
  setLoanSearchText,
  setLoanCategory,
  setLoanStateTab,
  setLoanType,
  setLoanTab,
} from "../../../../store/slices/loan";
import {
  setTransactionsOverlayStatus,
  setPopupStatus3,
} from "../../../../store/slices/datasets";
import CategoryFilterView from "../../../../components/Overlay/ThemeFilter/Component/CategoryFilterView";
import SearchFilterView from "../../../../components/Overlay/ThemeFilter/Component/SearchFilterView";
import {
  setStageLoadingText,
  setRefreshData,
} from "../../../../store/slices/appmain";
import AddFilterPopOver from "../../../../components/Overlay/ThemeFilter/AddFilterPopover";
import AddFilterView from "../../../../components/Overlay/ThemeFilter/AddFilterView";
import AddPresetCard from "../../../../components/TableEditModal/AddPresetCard";
import ThemeToggle from "../../../../components/ToggleGroupeView/ThemeToggle";
import { useDeferredTimeout } from "../../../../hooks/useDeferredTimeout";
import useDebounce from "../../../../hooks/3-useDebounce/useDebounce";
import ComponentLoader from "../../../../components/ComponentLoader";
import { setRecurring_rules } from "../../../../store/slices/global";
import ThemeFilter from "../../../../components/Overlay/ThemeFilter";
import useSubscriptions from "../../../../hooks/useSubscriptions";
import ScenarioChip from "../../../../components/ScenarioChip";
import MenuView from "../../../../components/Overlay/MenuView";
import CustomPopover from "../../../../components/PopOver";
import ThemeTabs from "../../../../components/ThemeTabs";
import AddButton from "../../../../components/AddButton";
import LoanCommonFunctions from "./LoanCommonFunctions";
import AreaChartView from "../Component/AreaChartView";
import EndPoints from "../../../../APICall/EndPoints";
import CountView from "../Component/CountView";
import PieWidget from "../Component/PieWidget";
import { Constant } from "../../../../Helper";
import APICall from "../../../../APICall";
import store from "../../../../store";
import theme from "../../../../theme";
import i18n from "../../../../i18n";

const tabOptions = [
  {
    value: "loan",
    label: "loan_tab_label_loan",
  },
  {
    value: "leasing",
    label: "loan_tab_label_leasing",
  },
];

const menuOptions = [
  {
    value: 2,
    label: "Duplicate",
    tooltip: "loan_action_duplicate_tooltip",
    icon: <ContentCopyIcon />,
  },
  {
    value: 3,
    label: "Delete",
    tooltip: "loan_action_delete_tooltip",
    icon: <DeleteIcon />,
  },
];

const FilterOptions = [
  {
    uuid: "loan_type",
    title: "add_loan_type_filter",
    icon: true,
  },
  {
    uuid: "category",
    title: "add_category_filter",
    icon: true,
  },
];

const StyledDataGrid = styled(DataGrid)(({ theme, rows }) => ({
  "& .MuiDataGrid-columnHeaders, .MuiDataGrid-columnHeader, .MuiDataGrid-columnSeparator":
    {
      minHeight: `${theme.typography.pxToRem(46)} !important`,
      height: `${theme.typography.pxToRem(46)} !important`,
    },

  "& .MuiDataGrid-sortIcon": {
    color: theme.palette.primary.main,
  },
  "& .MuiDataGrid-columnHeader": {
    outline: "none !important",
  },
  "& .MuiDataGrid-columnHeader:focus": {
    outline: "none",
  },
  "& .MuiDataGrid-cell:focus-within": {
    outline: "none",
  },
  "& .MuiDataGrid-cell:focus": {
    outline: "none",
  },
  "& .MuiDataGrid-row": {
    cursor: "pointer",
  },
  "& .MuiDataGrid-row:hover": {
    backgroundColor: theme.palette.color.violet[50],
  },
  ...(rows?.length > 1 && {
    "& .MuiDataGrid-row--lastVisible:hover": {
      cursor: "default",
      backgroundColor: "transparent",
    },
  }),

  "& .MuiDataGrid-cell": {
    border: 0,
    padding: "0 6px",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    color: theme.palette.color.slate[700],
    fontWeight: theme.typography.fontWeightMediumBold,
  },
  "& .MuiDataGrid-virtualScroller": {
    marginTop: `${theme.typography.pxToRem(46)} !important`,
  },
}));

const Loan = () => {
  const theme = useTheme();
  const rowData = useRef({
    All: [],
    Planned: [],
    Ignored: [],
    "Written-off": [],
  });

  const isAllHeaderApiFetched = useSelector(
    (state) => state.commonSlice.isAllHeaderApiFetched
  );
  const testLoading = useSelector((state) => state.appSlice.testLoading);
  const loanData = useSelector((state) => state.loanSlice?.loanData);

  const [isLoading, setIsLoading] = useState(true);

  if (!isAllHeaderApiFetched) {
    return null;
  }
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Container
        sx={{
          width: "100%",
          minHeight: "calc(100% - 6rem)",
          maxWidth: "100% !important",
          height: "100%",
          pb: "4rem",
          overflow: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          ...theme.thinScrollBar,
        }}
      >
        <TabListView rowData={rowData} />
        <Paper
          sx={{
            width: "90%",
            minHeight: loanData?.length === 0 ? "calc(100% - 5rem)" : "none",
            height: "fit-content",
            backgroundColor: "color.white",
            pt: 8,
            pb: 4,
            borderRadius: theme.borderRadius.main,
          }}
        >
          <ListView
            rowData={rowData}
            isLoading={isLoading || testLoading}
            setIsLoading={setIsLoading}
          />
        </Paper>
      </Container>
    </div>
  );
};

export default Loan;

const TabListView = ({ rowData }) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  //redux
  const loanTab = useSelector((state) => state.loanSlice?.loanTab);
  const loanStateTab = useSelector((state) => state.loanSlice?.loanStateTab);
  const loanData = useSelector((state) => state.loanSlice?.loanData); // keep for refresh count in tab

  const count = useMemo(() => {
    let data = {
      Planned: 0,
      Ignored: 0,
      "Written-off": 0,
      All: 0,
    };
    if (loanData) {
      const Planned = rowData.current?.["Planned"]?.length || 0;
      const Ignored = rowData.current?.["Ignored"]?.length || 0;
      const Written = rowData.current?.["Written-off"]?.length || 0;
      const All = Planned + Ignored + Written;

      data = { Planned, Ignored, "Written-off": Written, All };
    }
    return data;
  }, [rowData, loanData]);

  //state
  const stateOptions = [
    {
      value: "Planned",
      label: "Employee-Planned",
      iconPosition: "end",
      icon: (
        <CountView
          count={count?.["Planned"] || 0}
          color={theme.palette.color.green[500]}
          bg={theme.palette.color.green[200]}
        />
      ),
    },
    {
      value: "Ignored",
      label: "Employee-Ignored",
      iconPosition: "end",
      icon: (
        <CountView
          count={count?.["Ignored"] || 0}
          color={theme.palette.color.blue[500]}
          bg={theme.palette.color.blue[200]}
        />
      ),
    },
    {
      value: "Written-off",
      label: "Employee-Written-off",
      iconPosition: "end",
      icon: (
        <CountView
          count={count?.["Written-off"] || 0}
          color={theme.palette.color.slate[500]}
          bg={theme.palette.color.slate[200]}
        />
      ),
    },
    {
      value: "All",
      label: "All",
      iconPosition: "end",
      icon: (
        <CountView
          count={count?.["All"] || 0}
          color={theme.palette.color.white}
          bg={theme.palette.color.slate[700]}
        />
      ),
    },
  ];

  //functions
  const setTab = (value) => {
    dispatch(setLoanStateTab(value));
  };

  const onChange = (e, value) => {
    if (value && value !== loanTab) {
      dispatch(setLoanTab(value));
    }
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems={"center"}
      sx={{ mt: "1.25rem", width: "90%" }}
    >
      <Stack direction="row" alignItems={"center"}>
        <ThemeToggle
          value={loanTab}
          onChange={onChange}
          options={tabOptions}
          sx={{
            mb: -2.5,
          }}
        />
        <ThemeTabs
          options={stateOptions}
          tab={loanStateTab}
          setTab={setTab}
          sx={{
            mb: -2,
            ml: 15,
          }}
        />
      </Stack>
      <AddOverlay />
    </Stack>
  );
};

const AddOverlay = ({ disabled }) => {
  const [isSubscriptionValid] = useSubscriptions();
  const overlayRef = useRef(null);
  const { t } = useTranslation();

  const loanTab = useSelector((state) => state.loanSlice?.loanTab);
  const popupStatus2 = useSelector((state) => state.datasetSlice?.popupStatus2);
  const transactionsOverlayStatus = useSelector(
    (state) => state.datasetSlice?.transactionsOverlayStatus
  );
  const [anchorEl, setAnchorEl] = useState(null);

  const onClickPopOver = () => {
    if (isSubscriptionValid({ showMessage: true })) {
      setAnchorEl(overlayRef.current);
    }
  };

  const onClosePopOver = () => {
    setAnchorEl(null);
  };

  return (
    <CustomPopover
      type="anchor"
      anchorEl={anchorEl}
      ref={overlayRef}
      onClose={onClosePopOver}
      button={
        <AddButton
          onClick={onClickPopOver}
          icon={<TbBuildingBank style={{ fontSize: "1rem" }} />}
          disabled={disabled}
          tooltipLabel={t(`Add_${loanTab}`)}
          label={t(`Add_${loanTab}`)}
          isNKey
          doNotListenKey={
            !!anchorEl ||
            (popupStatus2?.open &&
              popupStatus2?.overlay_type === "drawer_modal") ||
            (transactionsOverlayStatus?.open &&
              transactionsOverlayStatus?.overlay_type ===
                "drawer_modal_edit_form")
          }
          sx={{
            my: 2,
          }}
        />
      }
    >
      <AddOverlayView
        disabled={disabled}
        popup_state={{ close: onClosePopOver }}
      />
    </CustomPopover>
  );
};

const AddOverlayView = ({ disabled, popup_state }) => {
  const dispatch = useDispatch();
  const loanStateTab = useSelector((state) => state.loanSlice?.loanStateTab);
  const loanTab = useSelector((state) => state.loanSlice?.loanTab);

  const updateOverlay = () => {
    dispatch(setRefreshData(Date.now()));
  };

  const _State =
    !loanStateTab || loanStateTab === "All" ? "Planned" : loanStateTab;

  if (loanTab === "leasing") {
    return (
      <Box
        sx={{
          p: "1.5rem",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "color.slate.50",
        }}
      >
        <AddPresetCard
          disabled={disabled}
          transaction_type={"expense"}
          preset_type="LeasingPlanning"
          popup_state={popup_state}
          updateOverlay={updateOverlay}
          hideEditFormTabBar
          state={_State}
          hideIcon
          highlightIcon={false}
          descriptionCount={162}
        />
      </Box>
    );
  }

  if (loanTab === "loan") {
    return (
      <Box
        sx={{
          p: "1.5rem",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "color.slate.50",
        }}
      >
        <AddPresetCard
          disabled={disabled}
          transaction_type={"expense"}
          preset_type="LoanPlanning"
          popup_state={popup_state}
          updateOverlay={updateOverlay}
          hideEditFormTabBar
          state={_State}
          hideIcon
          highlightIcon={false}
          descriptionCount={162}
        />
      </Box>
    );
  }
  return null;
};

const FilterView = ({ rowData, setIsLoading, commonRef }) => {
  const dispatch = useDispatch();
  const isFilterOpen = useRef(false);
  const [allowToFetch, setAllowToFetch] = useState(true);
  const [isIncludedInFilterList, setIsIncludedInFilterList] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  const onClickFilter = useCallback((e) => {
    isFilterOpen.current = true;
    setAnchorEl(e.currentTarget);
  }, []);

  const onClose = (_selectedIds) => {
    isFilterOpen.current = false;
    setIsIncludedInFilterList(_selectedIds);
    setAnchorEl(null);
    const loanCategory = store?.getState()?.loanSlice?.loanCategory;
    const loanType = store?.getState()?.loanSlice?.loanType;
    if (loanCategory?.length > 0 && !_selectedIds?.includes("category")) {
      dispatch(setLoanCategory([]));
    }
    if (loanType?.length > 0 && !_selectedIds?.includes("loan_type")) {
      dispatch(setLoanType([]));
    }
  };

  const removeFilter = useCallback((key) => {
    setIsIncludedInFilterList((prev) => prev?.filter((item) => item !== key));
  }, []);

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        width: "100%",
        px: 8,
        mb: "1.5rem",
      }}
    >
      <LoanCommonFunctions
        ref={commonRef}
        rowData={rowData}
        setIsLoading={setIsLoading}
        allowToFetch={allowToFetch}
        setIsIncludedInFilterList={setIsIncludedInFilterList}
      />

      <SearchView />
      {isIncludedInFilterList?.length > 0 ? null : (
        <AddFilterView
          isIncludedInFilterList={isIncludedInFilterList}
          onClick={onClickFilter}
        />
      )}
      {isIncludedInFilterList?.map((item) => {
        if (item === "category") {
          return (
            <CategoryFilter
              setAllowToFetch={setAllowToFetch}
              isFilterOpen={isFilterOpen}
              filterKey="category"
              removeFilter={removeFilter}
            />
          );
        }
        if (item === "loan_type") {
          return (
            <TypeFilter
              isFilterOpen={isFilterOpen}
              filterKey="loan_type"
              removeFilter={removeFilter}
            />
          );
        }

        return null;
      })}

      {isIncludedInFilterList?.length > 0 ? (
        <AddFilterView
          isIncludedInFilterList={isIncludedInFilterList}
          onClick={onClickFilter}
        />
      ) : null}

      <AddFilterPopOver
        anchorEl={anchorEl}
        onClose={onClose}
        selectedIds={isIncludedInFilterList}
        options={FilterOptions}
      />
    </Stack>
  );
};

const SearchView = () => {
  const dispatch = useDispatch();

  const loanSearchText = useSelector(
    (state) => state.loanSlice?.loanSearchText
  );

  //functions
  const onChangeSearch = (e) => {
    dispatch(setLoanSearchText(e?.target?.value));
  };

  const onClickClear = (e) => {
    dispatch(setLoanSearchText(""));
  };

  return (
    <SearchFilterView
      active={loanSearchText?.length > 0}
      value={loanSearchText}
      color={"slate"}
      onClickClear={onClickClear}
      onChange={onChangeSearch}
    />
  );
};

const TypeFilter = ({ isFilterOpen, removeFilter, filterKey }) => {
  const dispatch = useDispatch();
  const loanType = useSelector((state) => state.loanSlice?.loanType);

  const onChangeValue = (value) => {
    dispatch(setLoanType(value));
  };

  const onClickReset = () => {
    removeFilter(filterKey);
    onChangeValue([]);
  };

  return (
    <ThemeFilter
      heading="loan_type_filter"
      tooltip="tooltip_loan_type_filter"
      icon={
        <MdOutlineCategory style={{ fontSize: "1rem", marginTop: "1px" }} />
      }
      options={Constant.LoanType}
      translate
      selectedIds={loanType}
      isActive={loanType?.length > 0}
      onChangeValue={onChangeValue}
      onClickReset={onClickReset}
      onClear={onClickReset}
      isFilterOpen={isFilterOpen}
      maxWidth="auto"
    />
  );
};

const CategoryFilter = ({
  setAllowToFetch,
  isFilterOpen,
  removeFilter,
  filterKey,
}) => {
  const dispatch = useDispatch();

  //redux
  const loanCategory = useSelector((state) => state.loanSlice?.loanCategory);

  const onChangeValue = (value) => {
    dispatch(setLoanCategory(value));
  };

  const onClickReset = (e) => {
    e.stopPropagation();
    removeFilter(filterKey);
    dispatch(setLoanCategory([]));
  };

  const onOpen = () => {
    setAllowToFetch(false);
  };

  const onClose = () => {
    setAllowToFetch(true);
  };

  return (
    <CategoryFilterView
      heading="Loan Category"
      categoryType={2}
      selectedIds={loanCategory}
      onOpen={onOpen}
      onClose={onClose}
      onClickReset={onClickReset}
      onChangeValue={onChangeValue}
      isFilterOpen={isFilterOpen}
    />
  );
};

const ListView = ({ rowData, isLoading, setIsLoading }) => {
  const commonRef = useRef(null);

  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <FilterView
        rowData={rowData}
        setIsLoading={setIsLoading}
        commonRef={commonRef}
      />
      <Grid
        container
        rowSpacing={5}
        columnSpacing={5}
        sx={{
          width: "100%",
          height: "fit-content",
          my: "2rem",
          px: "1.45rem",
        }}
      >
        <Grid item xs={12} isTablet={6} s1280={3}>
          <CategoryWidget loading={isLoading} commonRef={commonRef} />
        </Grid>
        <Grid item xs={12} isTablet={6} s1280={3}>
          <TypeWidget loading={isLoading} commonRef={commonRef} />
        </Grid>
        <Grid item xs={12} isTablet={12} s1280={6}>
          <CostWidget loading={isLoading} commonRef={commonRef} />
        </Grid>
      </Grid>
      <GridView loading={isLoading} />
    </div>
  );
};

const CategoryWidget = ({ loading, commonRef }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  //redux
  const loanData = useSelector((state) => state.loanSlice?.loanData); //keep for render updates
  const loanStateTab = useSelector((state) => state.loanSlice?.loanStateTab);
  const loanCategory = useSelector((state) => state.loanSlice?.loanCategory);
  const loanSearchText = useSelector(
    (state) => state.loanSlice?.loanSearchText
  );
  const DeferredSearchText = useDeferredTimeout({ value: loanSearchText });

  //state
  const [data, setData] = useState([]);

  useDebounce(
    () => {
      if (!loading) {
        if (loanData?.length > 0) {
          const selectionCategoriesByID =
            store.getState().categorySlice?.selectionCategoriesByID;
          const recurring_rulesById =
            store.getState().globalSlice?.recurring_rulesById;
          const filteredTransactionsByState =
            commonRef?.current?.filteredTransactionsByState;

          const filterData = filteredTransactionsByState?.[loanStateTab] || [];
          const filtered_recurring_rules_ids = [
            ...new Set(filterData?.map((item) => item?.recurring_rule)),
          ];
          let filtered_recurring_rules = [];
          filtered_recurring_rules_ids?.forEach((id) => {
            const recurring_rule = recurring_rulesById?.[id]?.[0];
            filtered_recurring_rules.push(recurring_rule);
          });
          const RecurringRuleByCategory = _.groupBy(
            filtered_recurring_rules,
            (item) => item?.transaction_category || "UnCategorized"
          );

          let array = [];
          Object.keys(RecurringRuleByCategory)?.forEach((key) => {
            const recurring_rules = RecurringRuleByCategory?.[key];
            const category = selectionCategoriesByID?.[key]?.[0];

            let total = 0;
            recurring_rules?.forEach((recurring_rule) => {
              total = total + Math.abs(recurring_rule?.value);
            });

            let title = category?.title;
            if (category?.immutable) {
              title = t(category?.title);
            } else if (!category?.title) {
              title = t(key);
            }
            array.push({
              uuid: key,
              title: title,
              value: Math.abs(parseFloat(total)?.toFixed(0)),
              color: category?.color || theme.palette.color.slate[500],
            });
          });

          array.sort((a, b) => b.value - a.value);
          setData(array);
        } else {
          setData([]);
        }
      }
    },
    300,
    [loading, loanStateTab, loanCategory, DeferredSearchText, loanData],
    true
  );

  if (!loading && loanData?.length === 0) return null;

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <PieWidget
        title={`loan_widget_heading_category`}
        loading={loading}
        options={data}
        sx={{
          flex: 1,
          width: "100%",
          minHeight: "18rem",
        }}
      />
      <Divider
        orientation="vertical"
        flexItem
        sx={{
          display: { xs: "none", md: "block" },
          opacity: 0.6,
          mx: "0.6rem",
        }}
      />
    </div>
  );
};

const TypeWidget = ({ loading, commonRef }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  //redux
  const loanData = useSelector((state) => state.loanSlice?.loanData); //keep for render updates
  const loanStateTab = useSelector((state) => state.loanSlice?.loanStateTab);
  const loanCategory = useSelector((state) => state.loanSlice?.loanCategory);
  const loanSearchText = useSelector(
    (state) => state.loanSlice?.loanSearchText
  );
  const DeferredSearchText = useDeferredTimeout({ value: loanSearchText });

  //state
  const [data, setData] = useState([]);

  useDebounce(
    () => {
      if (!loading) {
        if (loanData?.length > 0) {
          const recurring_rulesById =
            store.getState().globalSlice?.recurring_rulesById;
          const filteredTransactionsByState =
            commonRef?.current?.filteredTransactionsByState;

          const filterData = filteredTransactionsByState?.[loanStateTab] || [];
          const filtered_recurring_rules_ids = [
            ...new Set(filterData?.map((item) => item?.recurring_rule)),
          ];
          let filtered_recurring_rules = [];
          filtered_recurring_rules_ids?.forEach((id) => {
            const recurring_rule = recurring_rulesById?.[id]?.[0];
            filtered_recurring_rules.push(recurring_rule);
          });
          const RecurringRuleByLoanType = _.groupBy(
            filtered_recurring_rules,
            (item) => item?.employee_type || "No type defined"
          );

          let array = [];
          Object.keys(RecurringRuleByLoanType)?.forEach((key) => {
            const recurring_rules = RecurringRuleByLoanType?.[key];
            const LoanType = Constant?.LoanTypeByTitle[key]?.[0];

            let total = 0;
            recurring_rules?.forEach((recurring_rule) => {
              total = total + Math.abs(recurring_rule?.value);
            });

            const title = LoanType ? t(LoanType?.title) : t(key);

            array.push({
              uuid: key,
              title: title,
              value: Math.abs(parseFloat(total)?.toFixed(0)),
              color: LoanType?.fullColor || theme.palette.color.slate[500],
            });
          });

          array.sort((a, b) => b.value - a.value);
          setData(array);
        } else {
          setData([]);
        }
      }
    },
    300,
    [loading, loanStateTab, loanCategory, DeferredSearchText, loanData, t],
    true
  );

  if (!loading && loanData?.length === 0) return null;

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <PieWidget
        title={`loan_widget_heading_type`}
        loading={loading}
        options={data}
        sx={{
          flex: 1,
          width: "100%",
          minHeight: "18rem",
        }}
      />
      <Divider
        orientation="vertical"
        flexItem
        sx={{
          display: { xs: "none", md: "block" },
          opacity: 0.6,
          mx: "0.6rem",
        }}
      />
    </div>
  );
};

const CostWidget = ({ loading, commonRef }) => {
  const theme = useTheme();

  const loanSearchText = useSelector(
    (state) => state.loanSlice?.loanSearchText
  );
  const loanStateTab = useSelector((state) => state.loanSlice?.loanStateTab);
  const loanCategory = useSelector((state) => state.loanSlice?.loanCategory);
  const loanData = useSelector((state) => state.loanSlice?.loanData); //keep for render updates

  const DeferredSearchText = useDeferredTimeout({ value: loanSearchText });

  const [chartData, setChartData] = useState([]);
  const [chartKeys, setChartKeys] = useState([]);

  useDebounce(
    () => {
      if (!loading) {
        const selectionCategoriesByID =
          store.getState().categorySlice?.selectionCategoriesByID;

        const recurring_rulesById =
          store.getState().globalSlice?.recurring_rulesById;
        const filteredTransactionsByState =
          commonRef?.current?.filteredTransactionsByState;

        const filterData =
          loanStateTab !== "All"
            ? filteredTransactionsByState?.[loanStateTab]?.filter(
                (o1) => o1.state === loanStateTab
              )
            : filteredTransactionsByState?.[loanStateTab] || [];

        const dates = [];
        filterData?.forEach((item) => {
          dates.push(new Date(item?.due_date));
        });

        const minDate = dates?.reduce(
          (min, date) => (min < date ? min : date),
          new Date(Infinity)
        );
        const maxDate = dates?.reduce(
          (max, date) => (max > date ? max : date),
          new Date(-Infinity)
        );

        if (!isValid(minDate) || !isValid(maxDate)) {
          setChartData([]);
          setChartKeys([]);
          return;
        }

        const TransactionsByDates = _.groupBy(filterData, ({ due_date }) =>
          format(new Date(due_date), "yyyy-MM")
        );
        let RangeDates = [];
        const monthsDiff = differenceInMonths(maxDate, minDate);
        if (monthsDiff < 11) {
          let count = Math.floor(12 - monthsDiff / 2);
          const startAdjustment = subMonths(minDate, count);
          const endAdjustment = addMonths(maxDate, count);

          // Create a new RangeDates array based on adjusted start and end dates
          const newStartDate =
            startAdjustment < minDate ? startAdjustment : minDate;
          const newEndDate = endAdjustment > maxDate ? endAdjustment : maxDate;

          RangeDates = eachMonthOfInterval({
            start: startOfMonth(newStartDate),
            end: endOfMonth(newEndDate),
          });
        } else {
          RangeDates = eachMonthOfInterval({
            start: minDate,
            end: maxDate,
          });
        }
        const total_key = "total_key";
        let chartKeyArray = [];
        let monthArray = [];

        const categoryParent = getCategoryParentId();

        const categories = [
          ...new Set(
            filterData?.map(
              (item) =>
                categoryParent?.[
                  recurring_rulesById?.[item?.recurring_rule]?.[0]
                    ?.transaction_category
                ] || "UnCategorized"
            )
          ),
        ];

        RangeDates?.forEach((date) => {
          const formattedDate = format(date, "yyyy-MM");
          const monthData = TransactionsByDates?.[formattedDate] || [];
          const TransactionsByCategory = _.groupBy(
            monthData,
            (item) =>
              categoryParent?.[
                recurring_rulesById?.[item?.recurring_rule]?.[0]
                  ?.transaction_category
              ] || "UnCategorized"
          );

          let obj = { due_date: formattedDate };
          categories?.forEach((key) => {
            const total = (TransactionsByCategory?.[key] || [])?.reduce(
              (a, b) => a + Number(b.gross_value),
              0
            );
            obj[key] = Math.abs(parseFloat(total)?.toFixed(0));
          });
          Object.keys(obj).forEach((key) => {
            if (key !== "due_date" && key !== total_key) {
              obj[total_key] = (obj?.[total_key] || 0) + (obj?.[key] || 0);
            }
          });
          monthArray.push(obj);
        });
        categories?.forEach((category_uuid) => {
          const category = selectionCategoriesByID?.[category_uuid]?.[0];

          chartKeyArray.push({
            key: category?.title || "UnCategorized",
            color: category?.color || theme.palette.color.slate[500],
            dataKey: category_uuid || "UnCategorized",
            stackId: "a",
            fill: category?.color || theme.palette.color.slate[500],
            shade: 800,
          });
        });
        chartKeyArray.push({
          key: total_key,
          color: theme.palette.color.slate[500],
          dataKey: total_key,
          stackId: "a",
          fill: theme.palette.color.slate[500],
          shade: 800,
          hideBar: true,
          isBold: true,
          showTopSeparator: true,
        });
        setChartKeys(chartKeyArray);
        setChartData(monthArray);
      }
    },
    300,
    [loading, loanStateTab, loanCategory, DeferredSearchText, loanData],
    true
  );

  if (!loading && chartData?.length === 0) {
    return null;
  }
  return (
    <AreaChartView
      loading={loading}
      chartKeys={chartKeys}
      chartData={chartData}
      heading={"loan_widget_heading_cost_development"}
      style={{
        width: "100%",
        height: "18rem",
      }}
    />
  );
};

const GridView = ({ loading }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const payload = useRef(null);
  const [isSubscriptionValid] = useSubscriptions();

  //redux
  const loanData = useSelector((state) => state.loanSlice?.loanData);
  const loanTab = useSelector((state) => state.loanSlice?.loanTab);

  //state
  const [anchorElMenu, setAnchorElMenu] = useState(null);

  const getRowId = useCallback((row) => row?.recurring_rule?.uuid, []);

  //api
  const addBatchRecurringRules = async (array) => {
    await APICall("post", EndPoints.recurring_rules + `batch_add/`, array).then(
      (response) => {
        if (response.status === 201 && response.data) {
          const recurring_rules =
            store.getState()?.globalSlice?.recurring_rules;
          const data = [...recurring_rules, ...response.data];
          store.dispatch(setRecurring_rules(data));
        }
      }
    );
  };

  //functions
  const handleClose = () => {
    setAnchorElMenu(null);
  };

  const onCloseDelete = () => {
    store?.dispatch(setPopupStatus3(null));
  };

  const onOkDelete = async ({ payload }) => {
    const item = payload?.item;
    if (item) {
      store?.dispatch(setPopupStatus3(null));
      store?.dispatch(setStageLoadingText("Loan being Deleted"));

      const recurring_rules = store.getState()?.globalSlice?.recurring_rules;
      const RecurringRules = recurring_rules?.filter(
        (o1) => o1?.uuid !== item?.recurring_rule?.uuid
      );

      let Data = item?.AllLoanData?.filter(
        (o1) => o1.recurring_rule === item?.recurring_rule?.uuid
      )?.map((o1) => o1?.uuid);

      if (Data?.length > 0) {
        store?.dispatch(setRecurring_rules(RecurringRules));
        const response = await deleteBatchTransactions(Data);
        if (response) {
          store?.dispatch(setRefreshData(Date.now()));
        }
      }
      store?.dispatch(setStageLoadingText(null));
    }
  };

  const onClickItem = async (e, value, item) => {
    handleClose();

    if (value === 2) {
      if (isSubscriptionValid({ showMessage: true })) {
        store?.dispatch(setStageLoadingText("Transactions being Copied"));
        let clonedTransactions = [];
        const clonedRecurringRule = v4uuid();
        const clonedRecurringRules = [
          {
            ...item?.recurring_rule,
            uuid: clonedRecurringRule,
          },
        ];
        item?.TransactionsByRecurringRule?.[
          item?.recurring_rule?.uuid
        ]?.forEach((o1) => {
          clonedTransactions?.push({
            ...o1,
            title: `${t("Copy of")} ${o1?.title}`,
            recurring_rule: clonedRecurringRule,
          });
        });
        try {
          await addBatchRecurringRules(clonedRecurringRules);
          sleep(500);
          await addBatchTransactions(clonedTransactions);
          store?.dispatch(setStageLoadingText(null));
          store?.dispatch(setRefreshData(Date.now()));
        } catch (e) {
          store?.dispatch(setStageLoadingText(null));
        }
      }
    }
    if (value === 3) {
      store?.dispatch(
        setPopupStatus3({
          id: "simple-popper",
          open: true,
          overlay_type: "delete",
          onConfirm: onOkDelete,
          onClose: onCloseDelete,
          payload: {
            title: i18n?.t("Delete"),
            message: i18n?.t(
              `Are you sure, you want to delete this ${loanTab} Planing?`
            ),
            item: item,
          },
        })
      );
    }
  };

  const onClick = (e, params) => {
    payload.current = params;
    e.stopPropagation();
    setAnchorElMenu(e.currentTarget);
  };

  const updateOverlay = () => {
    dispatch(setRefreshData(Date.now()));
  };

  const onRowClick = async (params) => {
    if (params.row?.item && !params.row?.item?.isTotal) {
      dispatch(
        setTransactionsOverlayStatus({
          open: true,
          overlay_type: "drawer_modal_edit_form",
          anchor: "right",
          payload: {
            modalType: "edit",
            transaction_type: "expense",
            updateOverlay: updateOverlay,
            hideEditFormTabBar: true,
            item: params.row?.item,
            recurringItem: params.row?.recurring_rule,
            // isStaffView: true,
          },
        })
      );
    }
  };

  const columns = useMemo(
    () => [
      {
        field: "title",
        headerName: t("Title"),
        flex: 1.5,
        renderCell: TitleCell,
      },
      {
        field: "employee_type",
        headerName: t("loan_type"),
        flex: 0.7,
        renderCell: TypeCell,
      },
      {
        field: "category",
        headerName: t("Category"),
        flex: 0.7,
        renderCell: CategoryCell,
      },
      {
        field: "state",
        headerName: t("State"),
        flex: 0.5,
        renderCell: StateCell,
      },
      {
        field: "scenario",
        headerName: t("Scenario"),
        flex: 0.6,
        renderCell: ScenarioCell,
      },
      {
        field: "value",
        headerName: t(`${loanTab} amount`),
        flex: 0.8,
        renderCell: ValueCell,
      },
      {
        field: "average_value",
        headerName: t("loan_open_amount"),
        flex: 0.8,
        renderCell: EMICell,
      },
      {
        field: "interest_rate",
        headerName: t("loan_form_interest_rate"),
        flex: 0.8,
        renderCell: InterestRateCell,
      },
      {
        field: "interest_rate_paid",
        headerName: t("loan_interest_rate_amount"),
        flex: 0.8,
        renderCell: InterestRatePaidCell,
      },
      {
        field: "start_date",
        headerName: t("loan_form_repayment_start_date"),
        flex: 0.4,
        renderCell: DateRangeCell,
      },
      {
        field: "end_date",
        headerName: t("loan_form_end_date"),
        flex: 0.4,
        renderCell: DateRangeCell,
      },
      {
        field: "repetition",
        headerName: t("loan_form_installments"),
        flex: 0.5,
        renderCell: InstallmentCell,
      },

      {
        field: "action",
        headerName: "",
        flex: 0.1,
        renderCell: (params) => {
          if (!params?.row?.item?.hasOwnProperty("category")) {
            return null;
          }
          return (
            <IconButton
              aria-label="more"
              id="long-button"
              onClick={(e) => onClick(e, params)}
              sx={{ background: "transparent !important", mr: 1 }}
            >
              <MoreVertIcon
                sx={{
                  color: "color.slate.600",
                }}
              />
            </IconButton>
          );
        },
      },
    ],
    [t, loanTab]
  );

  if (loading || !loanData || loanData?.length === 0) {
    return (
      <ComponentLoader
        loading={loading}
        isSkeleton
        skeletonCount={3}
        placeHolderHeight="4.5rem"
        placeHolderWidth="35%"
        skeltonSx={{
          transform: "scale(1,0.9)",
        }}
        sx={{
          m: 8,
          width: loading ? "auto" : "42%",
          height: loading ? "10rem" : "65%",
        }}
        key1={`${loanTab}_list_no_data_text_01`}
        key2={`${loanTab}_list_no_data_text_02`}
      />
    );
  }

  return (
    <div
      style={{
        width: "100%",
        height: "fit-content",
        paddingInline: "2rem",
      }}
    >
      {Boolean(anchorElMenu) ? (
        <MenuView
          anchorEl={anchorElMenu}
          open={Boolean(anchorElMenu)}
          options={menuOptions}
          handleClose={handleClose}
          onClickItem={onClickItem}
          item={payload.current?.row}
        />
      ) : null}

      <StyledDataGrid
        rows={loanData || []}
        columns={columns}
        getRowId={getRowId}
        disableColumnFilter
        disableColumnMenu
        disableSelectionOnClick
        disableRowSelectionOnClick
        disableCellSelectionOnClick
        disableDensitySelector
        autoHeight
        hideFooter
        onRowClick={onRowClick}
        sx={{ border: 0 }}
      />
    </div>
  );
};

//component
const TitleCell = (params) => {
  const item = params?.row?.item;

  return (
    <Tooltip title={item?.title} arrow placement="top">
      <Typography
        variant="subtitle1"
        fontWeight={"fontWeightMediumBold"}
        color={`color.slate.${item?.isTotal ? 400 : 700}`}
        sx={{
          maxWidth: "11rem",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          textAlign: "left",
        }}
      >
        {item?.title}
      </Typography>
    </Tooltip>
  );
};

const TypeCell = (params) => {
  const item = params?.row;
  const type =
    Constant?.LoanTypeByTitle?.[item?.recurring_rule?.employee_type]?.[0];
  const title = type ? t(type?.title) : t("No type defined");
  if (item?.item?.isTotal) {
    return null;
  }
  return (
    <Stack direction="row">
      <Tooltip title={title} arrow placement="top">
        <Box
          backgroundColor={type?.fullColor || `color.slate.200`}
          sx={{
            px: 2,
            py: 0.5,
            mr: 2,
            borderRadius: theme.borderRadius.borderRadiusMD,
          }}
        >
          <Typography
            variant="caption"
            fontWeight={"fontWeightMediumBold"}
            color={type?.fullColor ? `color.white` : `color.slate.800`}
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {truncate(title, 14)}
          </Typography>
        </Box>
      </Tooltip>
    </Stack>
  );
};

const CategoryCell = (params) => {
  const selectionCategoriesByID = useSelector(
    (state) => state.categorySlice.selectionCategoriesByID
  );

  const item = params?.row.item;
  if (!item?.hasOwnProperty("category")) {
    return null;
  }
  const category = selectionCategoriesByID?.[item?.category]?.[0];
  const title = category
    ? category?.immutable
      ? t(category?.title)
      : category?.title
    : t("UnCategorized");

  return (
    <Tooltip title={title} arrow placement="top">
      <Typography
        variant="caption"
        fontWeight={"fontWeightMediumBold"}
        color={category?.color || `color.slate.400`}
        sx={{
          maxWidth: "11rem",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          px: 2,
          py: 0.5,
          mr: 2,
          borderRadius: theme.borderRadius.borderRadiusMD,
          backgroundColor: category?.color
            ? alpha(category?.color, 0.1)
            : `color.slate.100`,
          border: `1px solid ${category?.color ? alpha(category?.color, 0.1) : theme.palette.color.slate[300]}`,
        }}
      >
        {truncate(title, 17)}
      </Typography>
    </Tooltip>
  );
};

const StateCell = (params) => {
  const item = params?.row?.item;
  if (!item?.hasOwnProperty("state")) {
    return null;
  }
  const stateByTitle = store?.getState()?.globalSlice?.stateByTitle;
  const state = stateByTitle?.[item?.state]?.[0];
  const title = t(`Employee-${state?.title}`);
  const color = t(state?.color);

  return (
    <Tooltip title={title} arrow placement="top">
      <Typography
        variant="caption"
        fontWeight={"fontWeightMediumBold"}
        color={`color.${color}.600`}
        sx={{
          maxWidth: "11rem",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          px: 2,
          py: 0.5,
          mr: 2,
          borderRadius: theme.borderRadius.borderRadiusMD,
          backgroundColor: theme.palette.color?.[color || "slate"]?.[100],
          border: `1px solid ${theme.palette.color?.[color || "slate"]?.[100]}`,
        }}
      >
        {truncate(title, 20)}
      </Typography>
    </Tooltip>
  );
};

const ScenarioCell = (params) => {
  const item = params?.row?.item;
  if (!item?.hasOwnProperty("scenario")) {
    return null;
  }
  return (
    <ScenarioChip
      title={item?.scenario}
      hideBorder
      hideIcon
      highlightIcon={false}
      showClickCursor
      height="auto"
      fontSize="0.65rem"
      shade="100"
      sx={{
        py: 1,
        maxWidth: "5.75rem",
        borderRadius: theme.borderRadius.borderRadiusMD,
        "& .MuiChip-label": {
          fontWeight: "fontWeightMediumBold",
        },
      }}
    />
  );
};

const ValueCell = (params) => {
  const recurring_rule = params?.row?.recurring_rule;
  if (recurring_rule?.isTotal) {
    return (
      <ListItemText
        variant="caption"
        primary={formatAmount({
          amount: recurring_rule?.value || 0,
        })}
        secondary={t("total_key")}
        sx={{
          "& .MuiListItemText-primary": {
            color: `color.slate.600`,
            textAlign: "left",
            fontWeight: "fontWeightMediumBold",
            fontSize: "0.8rem",
          },
          "& .MuiListItemText-secondary": {
            color: `color.description`,
            fontWeight: "fontWeightMedium",
            textAlign: "left",
            fontSize: "0.7rem",
          },
        }}
      />
    );
  }
  return (
    <Typography
      variant={"body2"}
      fontWeight={"fontWeightMediumBold"}
      color={`color.slate.600`}
      sx={{
        maxWidth: "11rem",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      }}
    >
      {formatAmount({ amount: recurring_rule?.value })}
    </Typography>
  );
};

const EMICell = (params) => {
  const recurring_rule = params?.row?.recurring_rule;
  if (recurring_rule?.isTotal) {
    return (
      <ListItemText
        variant="caption"
        primary={formatAmount({
          amount: recurring_rule?.average_value || 0,
        })}
        secondary={t("total_key")}
        sx={{
          "& .MuiListItemText-primary": {
            color: `color.slate.600`,
            textAlign: "left",
            fontWeight: "fontWeightMediumBold",
            fontSize: "0.8rem",
          },
          "& .MuiListItemText-secondary": {
            color: `color.description`,
            fontWeight: "fontWeightMedium",
            textAlign: "left",
            fontSize: "0.7rem",
          },
        }}
      />
    );
  }
  return (
    <Typography
      variant={"subtitle2"}
      fontWeight={"fontWeightMediumBold"}
      color={`color.slate.600`}
    >
      {formatAmount({ amount: recurring_rule?.average_value })}
    </Typography>
  );
};

const InterestRateCell = (params) => {
  const recurring_rule = params?.row?.recurring_rule;
  if (!recurring_rule?.[params?.field]) {
    return null;
  }
  if (recurring_rule?.isTotal) {
    return (
      <ListItemText
        variant="caption"
        primary={`${recurring_rule?.[params?.field]} %`}
        secondary={t("average_key")}
        sx={{
          "& .MuiListItemText-primary": {
            color: `color.slate.600`,
            textAlign: "left",
            fontWeight: "fontWeightMediumBold",
            fontSize: "0.8rem",
          },
          "& .MuiListItemText-secondary": {
            color: `color.description`,
            fontWeight: "fontWeightMedium",
            textAlign: "left",
            fontSize: "0.7rem",
          },
        }}
      />
    );
  }
  return (
    <Typography
      variant={"subtitle2"}
      fontWeight={"fontWeightMediumBold"}
      color={`color.slate.600`}
    >
      {recurring_rule?.[params?.field]} %
    </Typography>
  );
};

const InterestRatePaidCell = (params) => {
  const recurring_rule = params?.row?.recurring_rule;
  if (!recurring_rule?.[params?.field]) {
    return null;
  }

  if (recurring_rule?.isTotal) {
    return (
      <ListItemText
        variant="caption"
        primary={formatAmount({
          amount: recurring_rule?.[params?.field] || 0,
        })}
        secondary={t("total_key")}
        sx={{
          "& .MuiListItemText-primary": {
            color: `color.slate.600`,
            textAlign: "left",
            fontWeight: "fontWeightMediumBold",
            fontSize: "0.8rem",
          },
          "& .MuiListItemText-secondary": {
            color: `color.description`,
            fontWeight: "fontWeightMedium",
            textAlign: "left",
            fontSize: "0.7rem",
          },
        }}
      />
    );
  }
  return (
    <Typography
      variant={"subtitle2"}
      fontWeight={"fontWeightMediumBold"}
      color={`color.slate.600`}
    >
      {formatAmount({
        amount: recurring_rule?.[params?.field] || 0,
      })}
    </Typography>
  );
};

const DateRangeCell = (params) => {
  const recurring_rule = params?.row?.recurring_rule;
  if (!recurring_rule?.[params?.field]) {
    return null;
  }
  return (
    <Tooltip
      title={formatDateToLocal(recurring_rule?.[params?.field], "MMM yy")}
      arrow
      placement="top"
    >
      <Typography
        variant="subtitle2"
        fontWeight={"fontWeightMediumBold"}
        color="color.slate.600"
      >
        {formatDateToLocal(recurring_rule?.[params?.field], "MMM yy")}
      </Typography>
    </Tooltip>
  );
};

const InstallmentCell = (params) => {
  const recurring_rule = params?.row?.recurring_rule;
  if (!recurring_rule?.[params?.field]) {
    return null;
  }
  const title =
    recurring_rule?.[params?.field] === 1
      ? t("label_monthly")
      : t("label_quarterly");
  return (
    <Tooltip title={title} arrow placement="top">
      <Typography
        variant="subtitle2"
        fontWeight={"fontWeightMediumBold"}
        color="color.slate.600"
      >
        {title}
      </Typography>
    </Tooltip>
  );
};
