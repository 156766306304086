import { Box, Link, ListItemText, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FaArrowUp } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { useTheme } from "@mui/styles";

import { setIsUpdatesOverlayOpen } from "../../store/slices/global";
import { thinScrollbarStyle } from "../../Helper/data";
import Translate from "../../hooks/HOC/Translate";
import CustomModal from "../Model/CustomModal";
import TailwindButton from "./TailwindButton";
import { Images } from "../../Helper";

const UpdatesOverlay = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();

  //function
  const onClose = () => {
    dispatch(setIsUpdatesOverlayOpen(false));
  };

  const onClickRoadMap = () => {
    window.open("https://finban.io/finban-roadmap/", "_blank");
  };

  const onClickAcknowledge = () => {
    dispatch(setIsUpdatesOverlayOpen(false));
  };

  //render function
  const Footer = () => {
    return (
      <div
        style={{
          display: "inherit",
          justifyContent: "space-between",
          alignItems: "center",
          position: "absolute",
          bottom: "2rem",
          left: "2.5rem",
          right: "2.5rem",
        }}
      >
        <Link
          variant="body1"
          href="https://finban.io/hard-reload/"
          target="_blank"
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {t("We recommend a hard reload after each Update")}
          <FaArrowUp
            strokeWidth={0.5}
            style={{
              transform: `rotate(45deg)`,
              color: theme.palette.primary.main,
              marginLeft: "0.5rem",
            }}
          />
        </Link>
        <Stack direction={"row"} alignItems={"center"}>
          <TailwindButton
            variant="outlined"
            text={t("Checkout Roadmap")}
            onClick={onClickRoadMap}
            color={theme.palette.primary.main}
          />
          <TailwindButton
            text={t("Acknowledge")}
            onClick={onClickAcknowledge}
            backgroundColor={theme.palette.primary.main}
          />
        </Stack>
      </div>
    );
  };

  return (
    <CustomModal
      onClose={onClose}
      hideClose
      backdropClose={false}
      hideAction
      open={true}
      restProps={{
        slotProps: {
          backdrop: {
            sx: {
              backdropFilter: "blur(6px)",
              WebkitBackdropFilter: "blur(6px)",
            },
          },
        },
      }}
    >
      <Box
        sx={{
          width: "68rem",
          height: "48rem",
          display: "inline-flex",
          flexDirection: "column",
          backgroundColor: `${theme.palette.color.white} !important`,
          borderRadius: 1.5,
          overflow: "hidden",
          position: "relative",
        }}
      >
        <img
          component="img"
          style={{
            objectFit: "contain",
          }}
          src={Images.latest_updates_banner}
          alt="mailing_banner"
        />

        <div
          style={{
            padding: "2.5rem",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <ListItemText
            primary={<Translate i18nkey={"updates_overlay_title"} />}
            sx={{
              "& span": {
                fontSize: "2rem",
                fontWeight: 700,
              },
            }}
          />{" "}
          <Box
            sx={{
              display: "flex",
              textAlign: "left",
              height: "18rem",
              overflow: "auto",
              ...thinScrollbarStyle,
            }}
          >
            <ListItemText
              primary={""}
              secondary={<Translate i18nkey={"updates_overlay_description"} />}
              sx={{
                "& .MuiTypography-root": {
                  fontSize: "1rem",
                  lineHeight: "1.5rem",
                  fontWeight: 500,
                  "& b": {
                    lineHeight: "1.5rem",
                  },
                },
              }}
            />
          </Box>
        </div>

        <Footer />
      </Box>
    </CustomModal>
  );
};

export default UpdatesOverlay;
