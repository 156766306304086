import {
  LinearProgress,
  useTheme,
  Tooltip,
  styled,
  Paper,
} from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { DataGrid } from "@mui/x-data-grid";

import {
  getTransactionByParams,
  getIntegrationCard,
  formatDateToLocal,
  formatAmount,
  remToPx,
} from "../../../../Helper/data";
import { setTransactionsOverlayStatus } from "../../../../store/slices/datasets";
import useDebounce from "../../../../hooks/3-useDebounce/useDebounce";
import ScenarioChip from "../../../../components/ScenarioChip";
import CategoryChip from "../../../../components/CategoryChip";
import StateChip from "../../../../components/StateChip";
import useWidth from "../../../../hooks/useWidth";
import store from "../../../../store";

const StyledPaper = styled(Paper)(({ theme, width = "100%" }) => ({
  boxShadow:
    "0px 3px 1px -2px rgb(0 0 0 / 16%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
  width: width,
  borderRadius: "5px",
  backgroundColor: theme.palette.color.white,
  height: "calc(100% - 10rem)",

  position: "relative",
  "& .MuiDataGrid-overlay": {
    zIndex: 1,
  },
  "& .MuiDataGrid-columnHeaders": {
    border: 0,
  },
  "& .MuiDataGrid-columnHeader": {
    outline: "none",
  },
  "& .MuiDataGrid-columnHeader:focus": {
    outline: "none",
  },
  "& .MuiDataGrid-row": {
    cursor: "pointer",
  },
  "& .MuiDataGrid-cell:focus-within": {
    outline: "none",
  },
  "& .MuiDataGrid-cell:focus": {
    outline: "none",
  },
  "& .MuiDataGrid-virtualScroller": {
    width: width,
    position: "absolute",
    left: 0,
  },
  "& .MuiDataGrid-cell": {
    borderColor: theme.palette.color.slate[100],
    padding: "0 6px",
  },
  "& .divider-cell": {
    backgroundColor: theme.palette.color.green[300],
  },
}));

const ClientTransactionsList = ({
  type = 1,
  width,
  HEIGHT,
  hideState,
  transaction_system,
}) => {
  const currentWidth = useWidth();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const timer = useRef(0);
  const prevent = useRef(false);
  const delay = useRef(200);

  //redux
  const state = useSelector((state) => state.globalSlice?.state);
  const scenario = useSelector((state) => state.globalSlice?.scenario);
  const datasetAccountList = useSelector(
    (state) => state.boardSlice.datasetAccountList
  );

  //state
  const [rows, setRows] = useState([]);
  const [rowCountState, setRowCountState] = useState(0);
  const [page, setPage] = useState(0);
  const [page_size, setPageSize] = useState(20);
  const [listLoading, setListLoading] = useState(false);
  const [order_by, setOrder_by] = useState("-due_date");

  const onPageChange = (newPage) => {
    setPage(newPage);
  };

  const onPageSizeChange = (size) => {
    setPageSize(size);
  };

  const onSortModelChange = (params) => {
    if (params && params[0]) {
      let { field, sort } = params[0];
      if (field === "title") {
        if (sort === "asc") {
          setOrder_by("title");
        } else {
          setOrder_by("-title");
        }
      }
      if (field === "due_date") {
        if (sort === "asc") {
          setOrder_by("due_date");
        } else {
          setOrder_by("-due_date");
        }
      }
      if (field === "invoice_date") {
        if (sort === "asc") {
          setOrder_by("invoice_date");
        } else {
          setOrder_by("-invoice_date");
        }
      }
      if (field === "gross_value") {
        if (sort === "asc") {
          setOrder_by("gross_value");
        } else {
          setOrder_by("-gross_value");
        }
      }
    } else {
      setOrder_by("");
    }
  };

  const handleClickCategory = (id) => {};

  const onCellDoubleClick = () => {
    clearTimeout(timer.current);
    prevent.current = true;
  };

  const onRowClick = (params) => {
    timer.current = setTimeout(async () => {
      if (!prevent.current) {
        let item = params.row;

        if (item) {
          dispatch(
            setTransactionsOverlayStatus({
              open: true,
              overlay_type: "drawer_modal_edit_form",
              anchor: "right",
              payload: {
                modalType: "edit",
                item,
              },
            })
          );
        }
      }
      prevent.current = false;
    }, delay.current);
  };

  const isCellEditable = (params) => {
    return false;
  };

  const bankName = useMemo(() => {
    let data = {};
    datasetAccountList?.forEach((element) => {
      if (element?.type === 10 || element?.type === 12) {
        data[element?.account?.uuid] =
          element?.title ||
          element?.account?.bank_details?.title ||
          t(element?.card?.title);
      }
    });
    return data;
  }, [datasetAccountList, t]);

  const columns = [
    {
      field: "due_date",
      headerName: t("Date"),
      flex: 0.65,
      editable: true,
      type: "date",
      cellClassName: "date-color",
      valueGetter: ({ value }) => {
        return value ? new Date(value) : null;
      },
      valueFormatter: ({ value }) => {
        return formatDateToLocal(value, "dd/MM/yyyy");
      },
    },
    {
      field: "title",
      headerName: t("Title"),
      flex: 1.7,
      editable: true,
    },
    {
      field: "note",
      headerName: t("Note"),
      flex: 1.7,
      editable: true,
      sortable: false,
    },
    {
      field: "data_source",
      headerName: t("Data_Source"),
      flex: 0.7,
      editable: false,
      renderCell: (params) => {
        const dataSourceById = store?.getState()?.globalSlice?.dataSourceById;
        const ds = dataSourceById?.[params?.row?.data_source]?.[0];
        let card = getIntegrationCard({ ds });
        if (ds?.internal_dataset) {
          card = { title: "Internal data source" };
        }
        if (params.row?.source === 2) {
          card = { title: bankName?.[params.row?.account] };
        }

        return (
          <Tooltip title={t(card?.title)}>
            <p
              style={{
                fontFamily: theme.typography.fontFamily,
                fontSize: "0.875rem",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {t(card?.title)}
            </p>
          </Tooltip>
        );
      },
    },

    {
      field: "gross_value",
      headerName: t("Value"),
      flex: 0.75,
      editable: true,
      type: "float",
      renderCell: (params) => {
        return (
          <Tooltip
            title={formatAmount({
              amount: params?.value,
            })}
          >
            <p
              key={params?.row?.uuid}
              style={{
                color:
                  parseFloat(params.row?.gross_value) >= 0
                    ? theme.palette.color.green[500]
                    : theme.palette.color.red[500],
                fontFamily: theme.typography.fontFamily,
                fontSize: "0.875rem",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {formatAmount({
                amount: params?.value,
              })}
            </p>
          </Tooltip>
        );
      },
    },

    {
      field: "state",
      headerName: t("State"),
      flex: 0.8,
      editable: true,
      sortable: false,
      type: "singleSelect",
      valueOptions: state,
      renderCell: (params) => {
        return (
          <StateChip
            title={params?.value}
            income_expense_type={params?.row?.income_expense_type}
            hideBorder
            hideIcon
            showClickCursor
            defaultShade={100}
            width="8rem"
            height="1.6rem"
            fontSize="0.7rem"
          />
        );
      },
    },
    {
      field: "scenario",
      headerName: t("Scenario"),
      flex: 0.75,
      editable: true,
      sortable: false,
      type: "singleSelect",
      valueOptions: scenario,
      renderCell: (params) => {
        return (
          <ScenarioChip
            title={params?.value}
            hideBorder
            hideIcon
            showClickCursor
            width="7rem"
            height="1.6rem"
            fontSize="0.7rem"
          />
        );
      },
    },
    {
      field: "category",
      headerName: t("category"),
      headerClassName: "last-header-cell",
      flex: 1,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <CategoryChip
            onClick={
              params?.row?.recurring_rule
                ? undefined
                : (e) => handleClickCategory(e, params)
            }
            categoryId={params.value}
            showTooltip
            width={`${16 * 0.5}rem`}
            noCategoryLabel={"Click to set category"}
          />
        );
      },
    },
  ];

  return (
    <StyledPaper width={width}>
      <FunctionsView
        transaction_system={transaction_system}
        setRows={setRows}
        setListLoading={setListLoading}
        setRowCountState={setRowCountState}
        setPage={setPage}
        type={type}
        hideState={hideState}
        page={page}
        page_size={page_size}
        order_by={order_by}
      />
      <DataGrid
        rows={rows}
        showColumnRightBorder={false}
        columns={columns}
        disableColumnMenu
        disableSelectionOnClick
        rowHeight={remToPx(currentWidth, HEIGHT)}
        checkboxSelection
        getRowId={(row) => row?.uuid}
        // onCellEditCommit={onCellEditCommit}
        // selectionModel={selectedRowIds}
        // onSelectionModelChange={onSelectionModelChange}
        // getCellClassName={getCellClassName}
        isCellEditable={isCellEditable}
        onCellDoubleClick={onCellDoubleClick}
        onRowClick={onRowClick}
        rowCount={rowCountState}
        onSortModelChange={onSortModelChange}
        page={page}
        onPageChange={onPageChange}
        pagination
        pageSize={page_size}
        onPageSizeChange={onPageSizeChange}
        rowsPerPageOptions={[20, 50, 100]}
        paginationMode={"server"}
        loading={listLoading}
        localeText={{
          noRowsLabel: "",
          noResultsOverlayLabel: "",
        }}
        components={{
          LoadingOverlay: LinearProgress,
        }}
        sx={{
          border: 0,
        }}
      />
    </StyledPaper>
  );
};

export default ClientTransactionsList;

const FunctionsView = ({
  type,
  transaction_system,
  page,
  page_size,
  order_by,
  hideState,
  setPage,
  setListLoading,
  setRowCountState,
  setRows,
}) => {
  //redux
  const dataset = useSelector((state) => state.boardSlice?.dataSetData?.uuid);
  const searchText = useSelector(
    (state) => state.staffSlice?.["contactSearchText"]
  );
  const contactStates = useSelector(
    (state) => state.staffSlice?.["contactStates"]
  );
  const contacts = useSelector((state) => state.globalSlice?.contacts);

  const client = useMemo(
    () =>
      contacts
        ?.filter(
          (o1) =>
            (o1?.dataset === dataset ||
              transaction_system?.includes(o1?.transaction_system)) &&
            o1.type === type
        )
        ?.map((o1) => o1.uuid),
    [contacts, dataset, transaction_system, type]
  );

  //functions
  const getFilterListUrl = () => {
    let url = "&is_split=false";
    const use_global_categories =
      store.getState().boardSlice?.dataSetData?.use_global_categories;
    if (!use_global_categories) {
      url = url + `&category_dataset=${dataset}`;
    } else {
      url = url + `&global_category=true`;
    }
    if (searchText && searchText?.length > 0) {
      url = url + `&title_or_note=${searchText}`;
    }

    if (!hideState && contactStates?.length > 0) {
      const stateById = store?.getState()?.globalSlice?.stateById;
      contactStates.forEach((item) => {
        const _state = stateById?.[item]?.[0]?.uuid;
        if (_state) {
          url = url + `&state=${_state}`;
        }
      });
    }

    return url;
  };

  //api
  const getData = async (page, endUrl = "") => {
    setListLoading(true);
    if (client?.length === 0) {
      setRowCountState(0);
      setRows([]);
    } else {
      const response = await getTransactionByParams({
        dataset,
        page: page + 1,
        page_size,
        order_by,
        is_reconciled: false,
        detailed: 2,
        contact: client,
        url: endUrl,
      });
      if (response) {
        setRowCountState(response?.count);
        setRows(response?.results || []);
      }
    }

    setListLoading(false);
  };

  useEffect(() => {
    setPage(0);
  }, [type, client]);

  useDebounce(
    () => {
      let url = getFilterListUrl();
      getData(page, url);
    },
    500,
    [page, page_size, searchText, contactStates],
    true
  );

  return null;
};
