import TipsAndUpdatesTwoToneIcon from "@mui/icons-material/TipsAndUpdatesTwoTone";
import { Button, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useContext } from "react";

import { GlobalContext } from "../../GlobalContextWrapper";

const FinbanInfoBulb = ({
  url,
  text = "Help & Instructions",
  variant = "outlined",
  hideIcon,
  icon,
  isDirect,
  sx,
}) => {
  const theme = useTheme();
  const globalContext = useContext(GlobalContext);
  const { t } = useTranslation();
  const md = useMediaQuery("(min-width:960px)");

  const finbanInfoRef = globalContext?.finbanInfoRef;

  const onClickLink = () => {
    if (url) {
      if (isDirect) {
        window.open(url, "_blank");
      } else {
        finbanInfoRef.current?.open({
          url,
        });
      }
    }
  };

  return (
    <Button
      onClick={onClickLink}
      size="small"
      variant={variant}
      sx={{
        gap: "0.25rem",
        height: "2.2rem",
        paddingInline: "0.5rem",
        lineHeight: "1rem",
        color: theme.palette.color.orange[600],
        backgroundColor: theme.palette.color.orange[100],
        borderColor: theme.palette.color.orange[300],
        minWidth: "fit-content",
        "&:hover": {
          backgroundColor: theme.palette.color.orange[200],
          borderColor: theme.palette.color.orange[300],
        },
        ...sx,
      }}
    >
      {!hideIcon ? (
        <TipsAndUpdatesTwoToneIcon
          sx={{
            fontSize: "1.5em",
            color: theme.palette.color.orange[400],
            cursor: "pointer",
          }}
        />
      ) : null}
      {icon}
      {text && md ? t(text) : ""}
    </Button>
  );
};
export default FinbanInfoBulb;
