import { Button, useMediaQuery, useTheme } from "@mui/material";
import { IoPeopleCircle } from "react-icons/io5";

import Translate from "../../hooks/HOC/Translate";

const FinbanGoToButton = ({
  text = "Help & Instructions",
  i18nkey,
  values,
  variant = "outlined",
  hideIcon,
  icon,
  onClickLink,
  sx,
}) => {
  const theme = useTheme();
  const md = useMediaQuery("(min-width:960px)");

  return (
    <Button
      onClick={onClickLink}
      size="small"
      variant={variant}
      sx={{
        gap: "0.25rem",
        height: "2.2rem",
        paddingInline: "0.5rem",
        lineHeight: "1rem",
        color: theme.palette.color.slate[700],
        backgroundColor: theme.palette.color.slate[50],
        borderColor: theme.palette.color.slate[300],
        minWidth: "fit-content",
        "&:hover": {
          backgroundColor: theme.palette.color.slate[200],
          borderColor: theme.palette.color.slate[300],
        },
        ...sx,
      }}
    >
      {!hideIcon ? (
        <IoPeopleCircle
          style={{
            fontSize: "1.5em",
            color: theme.palette.color.slate[700],
            cursor: "pointer",
          }}
        />
      ) : null}
      {icon}
      {i18nkey && md ? <Translate i18nkey={i18nkey} values={values} /> : ""}
    </Button>
  );
};
export default FinbanGoToButton;
