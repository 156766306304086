import { Box, Tooltip, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

const HeaderIconView = ({ onClick, tooltip, icon, className, sx, ...rest }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Tooltip title={t(tooltip)}>
      <Box
        onClick={onClick}
        className={className}
        sx={{
          display: "flex",
          alignItems: "center",
          p: { xs: "0.25rem", small: "0.5rem" },
          cursor: "pointer",
          borderRadius: 16,
          backgroundColor: theme.palette.color.slate[200],
          color: theme.palette.color.slate[700],
          "& svg": {
            fontSize: {
              xs: "1.35rem",
              small: "1.6rem",
            },
          },

          "&:hover": {
            color: theme.palette.primary.main,
            "& svg": {
              color: theme.palette.primary.main,
            },
          },
          ...sx,
        }}
        {...rest}
      >
        {icon}
      </Box>
    </Tooltip>
  );
};

export default HeaderIconView;
