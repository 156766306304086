import {
  LinearProgress,
  Typography,
  useTheme,
  styled,
  Button,
  Paper,
  Chip,
  Box,
} from "@mui/material";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { DataGrid } from "@mui/x-data-grid";
import { enqueueSnackbar } from "notistack";
import { v4 as v4uuid } from "uuid";
import _ from "underscore";

import {
  updateBatchTransactions,
  buildUrlFromParams,
  formatAmount,
  remToPx,
} from "../../../../Helper/data";
import {
  setStageLoadingText,
  setRefreshData,
} from "../../../../store/slices/appmain";
import useDebounce from "../../../../hooks/3-useDebounce/useDebounce";
import { GrossValueCell } from "../../../../components/MuiTableCells";
import { setPopupStatus2 } from "../../../../store/slices/datasets";
import useStatusHook from "../../../../hooks/useStatusHook";
import EndPoints from "../../../../APICall/EndPoints";
import useWidth from "../../../../hooks/useWidth";
import { queryClient } from "../../../../App";
import APICall from "../../../../APICall";

const StyledPaper = styled(Paper)(({ theme, height, width = "100%" }) => ({
  boxShadow:
    "0px 3px 1px -2px rgb(0 0 0 / 16%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
  width: width,
  borderRadius: "5px",
  backgroundColor: theme.palette.color.white,
  height: height,
  maxHeight: "80%",
  position: "relative",
  "& .MuiDataGrid-overlay": {
    zIndex: 1,
  },
  "& .MuiDataGrid-columnHeaders": {
    border: 0,
  },
  "& .MuiDataGrid-columnHeader": {
    outline: "none",
  },
  "& .MuiDataGrid-columnHeader:focus": {
    outline: "none",
  },
  "& .MuiDataGrid-row": {
    cursor: "pointer",
  },
  "& .MuiDataGrid-cell:focus-within": {
    outline: "none",
  },
  "& .MuiDataGrid-cell:focus": {
    outline: "none",
  },
  "& .MuiDataGrid-virtualScroller": {
    width: width,
    position: "absolute",
    left: 0,
  },
  "& .MuiDataGrid-cell": {
    borderColor: theme.palette.color.slate[100],
    padding: "0 6px",
  },
  "& .divider-cell": {
    backgroundColor: theme.palette.color.green[300],
  },
}));

const ClientMerge = ({ width, HEIGHT, spacing }) => {
  const currentWidth = useWidth();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const page = useRef(200);
  const selectedItems = useRef(null);

  //redux
  const contactsByName = useSelector(
    (state) => state.globalSlice?.contactsByName
  );

  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);

  //state
  const [rows, setRows] = useState([]);
  const [isFetching, setFetching] = useState(false);

  const onRowClick = (params) => {
    const item = params.row;

    if (item) {
      dispatch(
        setPopupStatus2({
          open: true,
          overlay_type: "drawer_modal",
          anchor: "right",
          payload: {
            hideAccessInListViewFilter: true,
            hideScenarioChain: true,
            modalType: "edit",
            income_expense_type:
              item?.key?.transaction_type === "inflow" ? 1 : 2,
            cell: {
              contact: item?.items?.[0]?.contact,
              withUrl: true,
              title: item?.title,
              isClientView: true,
              clientType: item?.key?.transaction_type === "inflow" ? 1 : 2,
              clientName: item?.clientName,
            },
          },
        })
      );
    }
  };

  const handleClickIgnore = (e, param) => {
    e?.stopPropagation();
    selectedItems.current = param?.row;
    const already_exist_client = contactsByName?.[param?.row?.title]?.[0];
    if (already_exist_client) {
      updateContactByID(already_exist_client?.uuid, {
        state: already_exist_client?.state === 2 ? 1 : 2,
      });
    } else {
      addClientApi({
        dataset: dataSetData?.uuid,
        name: param?.row?.title,
        note: "",
        state: 2,
        type: param?.row?.key?.transaction_type === "inflow" ? 1 : 2,
      });
    }
  };

  const handleClickAdd = async (e, params) => {
    e?.stopPropagation();
    selectedItems.current = params?.row;
    const already_exist_client = contactsByName?.[params?.row?.title]?.[0];
    if (already_exist_client) {
      let data = already_exist_client;
      dispatch(setStageLoadingText("common_process_loader_text"));
      let array = [];
      selectedItems.current?.items?.forEach((element) => {
        array.push({ uuid: element?.uuid, contact: data?.uuid });
      });
      await updateBatchTransactions(array);
      dispatch(setStageLoadingText(null));
      dispatch(setRefreshData(Date.now()));
    } else {
      addClientApi({
        dataset: dataSetData?.uuid,
        name: params?.row?.title,
        note: "",
        state: 1,
        type: params?.row?.key?.transaction_type === "inflow" ? 1 : 2,
      });
    }
  };

  const handleClickDelete = async (e, params) => {
    e?.stopPropagation();
    dispatch(setStageLoadingText("common_process_loader_text"));
    let array = [];
    params?.row?.items?.forEach((element) => {
      array.push({ uuid: element?.uuid, contact: null });
    });
    await updateBatchTransactions(array);
    dispatch(setStageLoadingText(null));
    dispatch(setRefreshData(Date.now()));
  };

  //api
  const addClientApi = async (obj) => {
    dispatch(setStageLoadingText("common_process_loader_text"));
    await APICall("post", EndPoints.customers, obj).then(async (response) => {
      if (response.status === 201 && response.data) {
        let array = [];
        selectedItems.current?.items?.forEach((element) => {
          array.push({ uuid: element?.uuid, contact: response.data?.uuid });
        });
        await updateBatchTransactions(array);
        dispatch(setStageLoadingText(null));
        dispatch(setRefreshData(Date.now()));
        enqueueSnackbar(`new_contact_added_successfully`, {
          variant: "success",
          autoHideDuration: 2000,
        });
      }
    });
  };

  const updateContactByID = async (id, obj) => {
    await APICall("patch", EndPoints.customers + `${id}/`, obj).then(
      (response) => {
        if (response.status === 200 && response.data) {
          dispatch(setRefreshData(Date.now()));
        }
      }
    );
  };

  const name = "customer";
  const columns = [
    {
      field: "title",
      headerName: t("Title"),
      flex: 1.5,
      editable: false,
    },
    {
      field: "sum",
      headerName: t(`sum`),
      flex: 0.75,
      editable: false,
      type: "float",
      valueGetter: ({ value }) => {
        return value ? Number(value || 0) : null;
      },
      renderCell: (params) => <GrossValueCell {...params} theme={theme} />,
    },
    {
      field: "occurrence",
      headerName: t("Occurrence"),
      flex: 0.5,
      editable: false,
    },
    {
      field: "share",
      headerName: t(`merge_client_header_share`),
      flex: 0.75,
      editable: false,
      valueFormatter: ({ value }) => `${value || 0} %`,
      valueGetter: ({ value }) => parseFloat(value || 0),
    },
    {
      field: "change",
      headerName: t(`merge_client_header_change_compare_to_prev_year`),
      flex: 0.75,
      editable: false,
      valueFormatter: ({ value }) => `${value || 0} %`,
      valueGetter: ({ value }) => parseFloat(value || 0),
    },
    {
      field: "isIgnored",
      headerName: t(`merge_client_header_ignore`),
      flex: 0.75,
      editable: false,
      renderCell: (params) => (
        <IsIgnoredCell
          params={params}
          theme={theme}
          t={t}
          handleClickIgnore={handleClickIgnore}
        />
      ),
    },
    {
      field: "add",
      headerName: t(`merge_client_header_add_to_contact`),
      flex: 1,
      editable: false,
      renderCell: (params) => (
        <AddToClientCell
          params={params}
          theme={theme}
          t={t}
          handleClickAdd={handleClickAdd}
        />
      ),
    },
    {
      field: "delete",
      headerName: t(`merge_client_header_remove_from_contact`),
      flex: 1,
      editable: false,
      renderCell: (params) => (
        <DeleteCell
          params={params}
          theme={theme}
          t={t}
          handleClickDelete={handleClickDelete}
        />
      ),
    },
  ];
  const height = 20 * remToPx(currentWidth, HEIGHT) + spacing;

  return (
    <StyledPaper width={width} height={height}>
      <DataGrid
        rows={rows?.results || []}
        showColumnRightBorder={false}
        columns={columns}
        disableColumnMenu
        disableSelectionOnClick
        rowHeight={remToPx(currentWidth, HEIGHT)}
        getRowId={(row) => row?.uuid}
        onRowClick={onRowClick}
        page={page.current}
        pagination
        pageSize={15}
        paginationMode={"client"}
        loading={isFetching}
        localeText={{
          noRowsLabel: "",
          noResultsOverlayLabel: "",
        }}
        componentsProps={{
          footer: {
            listLoading: !!isFetching,
            page,
            theme,
            setFetching,
            setRows,
            rows,
          },
        }}
        components={{
          LoadingOverlay: LinearProgress,
          Footer: Footer,
        }}
        sx={{
          border: 0,
        }}
      />
    </StyledPaper>
  );
};

export default ClientMerge;

const IsIgnoredCell = ({ handleClickIgnore, params, theme, t }) => {
  if (params?.row?.isIgnored) {
    return (
      <VisibilityOffIcon
        onClick={(e) => handleClickIgnore(e, params)}
        sx={{ color: theme.palette.color.slate[400], cursor: "pointer" }}
      />
    );
  } else {
    return (
      <Chip
        onClick={(e) => handleClickIgnore(e, params)}
        label={t(`contact_merge_action_ignore`)}
        sx={{
          fontSize: "0.75rem",
          fontWeight: theme.typography.fontWeightBold,
          minWidth: "5.625rem",
          minHeight: "auto",
          height: "fit-content",
          borderRadius: theme.borderRadius.main,
          px: "0.5rem",
          py: "0.25rem",
          color: theme.palette.color.slate[500],
          backgroundColor: theme.palette.color.slate[50],
          border: `1px solid ${theme.palette.color.slate[500]}`,
        }}
      />
    );
  }
};

const AddToClientCell = ({ params, theme, handleClickAdd, t }) => {
  if (params?.row?.clientName) {
    return (
      <Typography
        variant="h6"
        component={"h6"}
        sx={{
          fontSize: "0.875rem",
          cursor: "pointer",
        }}
      >
        {params?.row?.clientName}
      </Typography>
    );
  } else {
    return (
      <Chip
        onClick={(e) => handleClickAdd(e, params)}
        label={t(`contact_merge_action_add_to_contact`)}
        sx={{
          fontSize: "0.75rem",
          fontWeight: theme.typography.fontWeightBold,
          minHeight: "auto",
          minWidth: "5.625rem",
          height: "fit-content",
          borderRadius: theme.borderRadius.main,
          px: "0.5rem",
          py: "0.25rem",
          color: theme.palette.primary.main,
          backgroundColor: theme.palette.primary[50],
          border: `1px solid ${theme.palette.primary.main}`,
        }}
      />
    );
  }
};

const DeleteCell = ({ params, theme, handleClickDelete, t }) => {
  if (params?.row?.clientName) {
    return (
      <Chip
        onClick={(e) => handleClickDelete(e, params)}
        label={t(`contact_merge_action_remove_from_contact`)}
        sx={{
          fontSize: "0.75rem",
          fontWeight: theme.typography.fontWeightBold,
          minHeight: "auto",
          minWidth: "5.625rem",
          height: "fit-content",
          borderRadius: theme.borderRadius.main,
          px: "0.5rem",
          py: "0.25rem",
          color: theme.palette.color.red[500],
          backgroundColor: theme.palette.color.red[50],
          border: `1px solid ${theme.palette.color.red[500]}`,
        }}
      />
    );
  } else {
    return null;
  }
};

const Footer = ({ page, rows, theme, setRows, setFetching }) => {
  let savedData = useRef({});
  let next = useRef(null);
  const contactQueryData = useStatusHook("contacts");

  const searchText = useSelector(
    (state) => state.staffSlice?.["contactSearchText"]
  );
  const contactTypes = useSelector(
    (state) => state.staffSlice?.["contactTypes"]
  );
  const refreshData = useSelector((state) => state.appSlice?.refreshData);
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);
  const contactsById = useSelector((state) => state.globalSlice?.contactsById);
  const fetchBulkList = async () => {
    let buildListParams = null;

    if (!next.current) {
      buildListParams = {
        dataset: dataSetData?.uuid,
        // transaction_type: "same",
        is_reconciled: false,
        is_split: false,
        title_contains: searchText || "",
        title: "similar",
        gross_value: "different",
        order_by: "title",
      };
      if (!dataSetData?.use_global_categories) {
        buildListParams.category_dataset = dataSetData?.uuid;
      } else {
        buildListParams.global_category = true;
      }
      if (contactTypes?.length === 1 && contactTypes?.[0]) {
        buildListParams = {
          ...buildListParams,
          income_expense_type: contactTypes?.[0],
        };
      }
    }
    let end_url = "";

    let result = null;
    await APICall(
      "get",
      next.current
        ? next.current?.replace("/api/", "")
        : EndPoints.transactionBulkList +
            buildUrlFromParams(buildListParams) +
            end_url,
      undefined,
      { doNotHandleError: true }
    ).then((response) => {
      if (response.status === 200 && response.data) {
        result = response.data;
      }
    });
    return result;
  };

  const BulkList = useQuery({
    queryKey: ["client_merge"],
    queryFn: () => {
      const response = fetchBulkList();
      if (response) {
        return response;
      }
    },
    retry: false,
    refetchOnMount: true,
    enabled: false,
    keepPreviousData: true,
  });

  useDebounce(
    () => {
      queryClient.removeQueries({ queryKey: ["client_merge"] });
      savedData.current = {};
      next.current = null;
      page.current = 0;
      BulkList.refetch();
    },
    500,
    [searchText, contactTypes, dataSetData?.uuid, refreshData],
    true
  );

  useEffect(() => {
    if (
      !contactQueryData?.isFetching &&
      !BulkList?.isFetching &&
      BulkList?.data &&
      BulkList?.isSuccess
    ) {
      next.current =
        BulkList?.data?.next && BulkList?.data?.results?.length === 15
          ? BulkList?.data?.next
          : null;
      let data = [];

      BulkList?.data?.results?.forEach((o1) => {
        const contact = contactsById?.[o1?.items?.[0]?.contact]?.[0];

        data.push({
          ...o1,
          title: o1?.key?.title,
          occurrence: o1?.items?.length,
          sum: o1?.items?.reduce((a, b) => a + Number(b?.gross_value || 0), 0),
          uuid: v4uuid(),
          clientName: contact?.name,
          isIgnored: contact?.state !== 1,
        });
      });
      const updatedData = {
        ...BulkList?.data,
        results: data,
        next: next.current,
      };

      savedData.current[page.current] = updatedData;
      setRows(updatedData);
      setFetching(false);
    } else {
      setFetching(true);
    }
  }, [
    BulkList?.data,
    BulkList?.isFetching,
    contactQueryData?.isFetching,
    BulkList?.isSuccess,
    page,
    setFetching,
    setRows,
    contactsById,
  ]);

  const onClickLeft = () => {
    page.current = page.current - 1;
    setRows(savedData.current[page.current]);
  };

  const onClickRight = () => {
    if (savedData.current?.[page.current + 1]?.next) {
      next.current = savedData.current?.[page.current + 1]?.next?.replace(
        "api/",
        ""
      );
      setRows(savedData.current[page.current + 1]);
    } else {
      next.current = BulkList?.data?.next?.replace("api/", "");
      BulkList.refetch();
    }
    page.current = page.current + 1;
  };
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        height: "3rem",
        borderTop: `1px solid ${theme.palette.color.slate[50]}`,
        backgroundColor: theme.palette.color.appThemeBg,
      }}
    >
      <Button
        onClick={onClickLeft}
        disabled={page.current === 0}
        disableRipple
        disableTouchRipple
        disableElevation
        disableFocusRipple
        sx={{
          width: "2rem",
          minWidth: "auto",
          color: theme.palette.color.black,
          "&:hover": {
            backgroundColor: "transparent",
          },
        }}
      >
        <ChevronLeftIcon />
      </Button>
      <Button
        onClick={onClickRight}
        disabled={!rows?.next || rows?.length <= 14}
        disableRipple
        disableTouchRipple
        disableElevation
        disableFocusRipple
        sx={{
          width: "2rem",
          mr: "0.9rem",
          ml: "1.3rem",
          minWidth: "auto",
          color: theme.palette.color.black,
          "&:hover": {
            backgroundColor: "transparent",
          },
        }}
      >
        <ChevronRightIcon />
      </Button>
    </Box>
  );
};
