import { Alert, Box, Typography } from "@mui/material";
import React from "react";
import { Color, Fonts } from "../../Helper";
import { useTranslation } from "react-i18next";

const SettingView = () => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "inline-flex",
        width: "100%",
        height: "100%",
        alignItems: "center",
        flexDirection: "column",
        pt: "2rem",
        pl: "1rem",
        backgroundColor: Color.appThemeBg,
        position: "relative",
      }}
    >
      <Typography
        sx={{
          color: Color.black,
          fontSize: "2.5rem",
          fontWeight: 500,
          fontFamily: Fonts.Text,
          mt: "2rem",
        }}
      >
        {t("Coming_Soon")}
      </Typography>
      <Alert severity="info"> {t("select_left_item")}</Alert>
    </Box>
  );
};

export default SettingView;
