import { Box } from "@mui/material";
import { Color } from "../Helper";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import { useEffect, useState } from "react";

const ColorPicker = ({
  color = "",
  options = [],
  size = 20,
  width = "100%",
  onChangeColor,
}) => {
  const [currentOptions, setCurrentOptions] = useState([]);
  const [page, setPage] = useState(1);

  let max_page = Math.ceil(options?.length / 10);

  useEffect(() => {
    if (page >= 1 && page <= max_page) {
      const start_index = 10 * (page - 1);
      const end_index = 10 * page - 1;
      let data = [];
      options.forEach((o1, index) => {
        if (index >= start_index && index <= end_index) {
          data.push(o1);
        }
      });
      setCurrentOptions(data);
    }
  }, [options, page, max_page]);

  const prevPage = () => {
    setPage((prev) => {
      if (prev > 1) {
        return prev - 1;
      }
      return prev;
    });
  };

  const nextPage = () => {
    setPage((prev) => {
      if (prev < max_page) {
        return prev + 1;
      }
      return prev;
    });
  };

  return (
    <Box
      sx={{
        width: width,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <KeyboardArrowLeftRoundedIcon
        onClick={prevPage}
        sx={{
          cursor: "pointer",
          fontSize: "1.8rem",
          opacity: page <= 1 ? 0.3 : 1,
        }}
      />
      <Box
        sx={{
          display: "flex",
          width: (size + 4) * 5,
          flexWrap: "wrap",
        }}
      >
        {currentOptions?.map((_color, index) => {
          return (
            <ColorSquare
              key={`${_color + index}`}
              color={_color}
              selected={color === _color}
              size={size}
              onClick={onChangeColor}
            />
          );
        })}
      </Box>

      <KeyboardArrowRightRoundedIcon
        onClick={nextPage}
        sx={{
          cursor: "pointer",
          fontSize: "1.8rem",
          opacity: page >= max_page ? 0.3 : 1,
        }}
      />
    </Box>
  );
};

export default ColorPicker;
const ColorSquare = ({
  color = "slate",
  size = 20,
  selected = false,
  onClick,
}) => {
  return (
    <div
      onClick={() => onClick(color)}
      style={{
        border: selected ? `2px solid ${color}` : 0,
        borderRadius: 4,
        width: size + 4,
        height: size + 4,
        position: "relative",
        cursor: "pointer",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%) scale(1)",
          backgroundColor: color,
          borderRadius: 0.5,
          width: size - 2,
          height: size - 2,
          transition: "transform 300ms ease",
          "&:hover": {
            transform: "translate(-50%, -50%) scale(1.2)",
          },
        }}
      ></Box>
      {selected ? (
        <DoneRoundedIcon
          sx={{
            color: Color.white,
            fontSize: size - 2,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
      ) : null}
    </div>
  );
};
