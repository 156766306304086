import { Box, CircularProgress, useTheme } from "@mui/material";
import React from "react";

import TipsWhileLoading from "./TipsWhileLoading";
import { Images } from "../Helper";

var Spinner = require("react-spinkit");

const SpinLoader = ({
  loading,
  showTips,
  isFirstLoading,
  children,
  name = "folding-cube",
}) => {
  const theme = useTheme();

  const [open, setOpen] = React.useState(false);
  const [showLogo, setShowLogo] = React.useState(false);

  React.useEffect(() => {
    if (loading) {
      setOpen(true);
      setShowLogo(true);
      setTimeout(() => {
        setShowLogo(false);
      }, 1000);
    } else {
      setOpen(false);
    }
  }, [loading]);

  return (
    open && (
      <Box
        sx={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)", // Centering horizontally and vertically
          zIndex: 9999,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "max-content",
        }}
      >
        <div
          style={{
            position: "relative",
          }}
        >
          {children || isFirstLoading ? (
            <div
              style={{
                backgroundColor: theme?.palette?.primary?.main,
                borderRadius: theme?.borderRadius?.main,
                color: theme?.palette?.color?.white,
                width: "2.4rem",
                height: "2.4rem",
                boxShadow: theme?.boxShadow,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {showLogo ? (
                <img
                  src={Images?.logo_finban}
                  alt="finban_logo"
                  style={{
                    width: "2.4rem",
                    height: "2.4rem",
                    objectFit: "cover",
                  }}
                />
              ) : null}

              {!showLogo ? (
                <CircularProgress
                  size="1.6rem"
                  sx={{
                    zIndex: 1,
                    m: "auto",
                    position: "absolute",
                    color: `${theme?.palette?.color?.white} !important`,
                  }}
                />
              ) : null}
            </div>
          ) : (
            <Spinner name={name} color={theme?.palette?.primary?.main} />
          )}
        </div>
        {showTips ? <TipsWhileLoading /> : null}
      </Box>
    )
  );
};

export default SpinLoader;
