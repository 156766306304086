import { forwardRef, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { truncate } from "../../../Helper/data";
import FilterBaseView from "./FilterBaseView";
import FilterOverlay from "./FilterOverlay";
import CustomPopover from "../../PopOver";
import theme from "../../../theme";

const ThemeFilter = forwardRef(
  (
    {
      tooltip,
      color = "slate",
      isFilterOpen,
      icon,
      heading,
      translate = false,
      options,
      selectedIds = [],
      Placeholder,
      isActive,
      onChangeValue,
      onClear,
      onClickManage,
      onClickReset,
      isMultiple,
      maxWidth = "7rem",
    },
    _ref
  ) => {
    const { t } = useTranslation();

    const [anchorEl, setAnchorEl] = useState(null);

    const onClickPopOver = (event) => {
      if (isFilterOpen) {
        isFilterOpen.current = true;
      }
      setAnchorEl(event.currentTarget);
    };

    const onClosePopOver = () => {
      if (isFilterOpen) {
        isFilterOpen.current = false;
      }
      setAnchorEl(null);
    };

    const VALUE = useMemo(() => {
      const Filtered = options?.filter((o1) => selectedIds?.includes(o1?.uuid));
      const obj = {
        allValue:
          selectedIds?.length > 0
            ? Filtered?.map(
                (o1, index) =>
                  `${index === 0 ? "" : ", "}${o1?.translate || translate ? t(o1?.title) : o1?.title}`
              )
            : "",
        value:
          isMultiple && selectedIds?.length > 1 ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: "4px",
                borderRadius: theme.borderRadius.borderRadiusXL,
                paddingInline: "8px",
                paddingBlock: "4px",
                backgroundColor: theme.palette.color.slate[200],
                color: theme.palette.color.slate[600],
              }}
            >
              {t("Multiple")}
            </div>
          ) : selectedIds?.length > 0 ? (
            Filtered?.map((item, index) => (
              <div
                key={item?.uuid}
                style={{
                  marginRight: index !== Filtered.length - 1 ? "0.25rem" : 0,
                  maxWidth: "8rem",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  display: "block",
                  borderRadius: theme.borderRadius.borderRadiusXL,
                  paddingInline: "8px",
                  fontSize: "0.8rem",
                  lineHeight: "0.8rem",
                  paddingBlock: "0.35rem",
                  marginTop: "1px",
                  border: `1px solid ${theme.palette?.color?.[item?.color || "slate"]?.[200]}`,
                  backgroundColor:
                    theme.palette.color?.[item?.color || "slate"]?.[50],
                  color: theme.palette.color?.[item?.color || "slate"]?.[600],
                }}
              >
                {truncate(
                  item?.translate || translate ? t(item?.title) : item?.title,
                  8
                )}
              </div>
            ))
          ) : (
            ""
          ),
      };
      return obj;
    }, [isMultiple, options, selectedIds, t, translate]);

    return (
      <CustomPopover
        type="anchor"
        anchorEl={anchorEl}
        onClose={onClosePopOver}
        width={"fit-content"}
        border="none"
        showRight
        icon
        iconStyle={{ p: 0 }}
        iconView={
          <FilterBaseView
            heading={heading}
            tooltip={tooltip}
            icon={icon}
            // isActive={isActive}
            isActive={false}
            Placeholder={Placeholder}
            value={VALUE?.value}
            allValue={VALUE?.allValue}
            onClick={onClickPopOver}
            onClear={onClear}
            maxWidth={maxWidth}
          />
        }
        hoverBG={"transparent"}
        justifyContent="flex-start"
        paperStyle={{
          border: 0,
        }}
      >
        <FilterOverlay
          selectedIds={selectedIds}
          options={options}
          anchorEl={anchorEl}
          translate={translate}
          color={color}
          onChangeValue={onChangeValue}
          onClickManage={onClickManage}
          onClickReset={onClickReset}
        />
      </CustomPopover>
    );
  }
);

export default ThemeFilter;
