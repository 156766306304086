import { styled } from "@mui/material/styles";

import Switch from "@mui/material/Switch";
import { Color } from "../../Helper";

const svg_code_1 = `<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
  Color.white
)}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>`;

const svg_code_2 = `<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
  Color.white
)}" d="M19,13H5V11H19V13Z" /></svg>`;
// const Android12Switch = styled(Switch)((props) => {
//   return {
//     padding: "8px !important",
//     width: 58,
//     height: 36,
//     color: Color.tailwind.slate[700],
//     "& .MuiSwitch-track": {
//       backgroundColor: `${getTailwindColor(
//         "slate",
//         props?.checked ? 700 : 500
//       )} !important`,

//       borderRadius: 36 / 2,
//       "&:before, &:after": {
//         content: '""',
//         position: "absolute",
//         top: "50%",
//         transform: "translateY(-50%)",
//         width: 12,
//         height: 12,
//       },
//       "&:before": {
//         backgroundImage: `url('data:image/svg+xml;utf8,${svg_code_1}')`,
//         left: 10,
//         top: "45%",
//       },
//       "&:after": {
//         backgroundImage: `url('data:image/svg+xml;utf8,${svg_code_2}')`,
//         right: 13,
//         top: "45%",
//       },
//     },

//     "& .MuiButtonBase-root": {},
//     "& .MuiSwitch-switchBase": {
//       // top: "-1.7px",
//       padding: "10.25px",
//     },

//     "& .MuiSwitch-thumb": {
//       boxShadow: "none",
//       width: 14,
//       height: 14,
//       margin: 1,
//     },
//   };
// });

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&::before, &::after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&::before": {
      backgroundImage: `url('data:image/svg+xml;utf8,${svg_code_1}')`,
      left: 12,
    },
    "&::after": {
      backgroundImage: `url('data:image/svg+xml;utf8,${svg_code_2}')`,
      right: 12,
    },

  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));
export default Android12Switch;
