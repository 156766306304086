import { Box } from "@mui/material";
import ComponentLoader from "../../../../../components/ComponentLoader";

const EmptyView = ({ type }) => {
  const isHalf = [
    "burn-rate-12-month",
    "profit-12-month",
    "income-12-month",
    "expenses-12-month",
    "eur",
  ].includes(type);
  const NoSpacing = ["over-budget-expense", "over-budget-income"].includes(
    type
  );
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        justifyContent: "start",
        mt: NoSpacing ? 0 : "2rem",
        "& p": {
          my: 0,
          textAlign: "left",
        },
      }}
    >
      <ComponentLoader
        // loading={loading}
        placeHolderHeight="4.5rem"
        placeHolderWidth={isHalf ? "45%" : "30%"}
        showBankButton
        skeltonSx={{
          transform: "scale(1,0.9)",
        }}
        sx={{
          mx: "auto",
          width: isHalf ? "60%" : "100%",
        }}
        key1={`widget_empty_01`}
        key2={`widget_empty_02`}
        // key1={`contract_list_no_data_text_01`}
      />
      {/* <Translate i18nkey={"widget_empty"} /> */}
    </Box>
  );
};

export default EmptyView;
