import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import { RiPresentationFill } from "react-icons/ri";
import { FaPlugCirclePlus } from "react-icons/fa6";
import { HiOutlineSearch } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import React, { useMemo, useRef } from "react";
import { MdOutlineAdd } from "react-icons/md";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/styles";
import Box from "@mui/material/Box";

import { setJoyRidePayload } from "../../../store/slices/global";
import CustomPopoverScratch from "../../PopOver/CustomPopover";
import { GlobalContext } from "../../../GlobalContextWrapper";
import HeaderAddOverlay from "./HeaderAddOverlay";
import initialData from "../../../Helper/data";
import DSSelectorView from "./DSSelectorView";
import BalanceView from "./BalanceView";
import CommonView from "./CommonView";
import TabView from "./TabView";
import Icon from "../../Icon";

const DatasetHeader = () => {
  const commonRef = useRef(null);
  const s1280 = useMediaQuery("(min-width:1280px)");
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flex: 1,
      }}
    >
      <CommonView ref={commonRef} />
      {s1280 ? <TabView /> : <span></span>}

      <ButtonView />
    </Box>
  );
};

export default React.memo(DatasetHeader);

const ButtonView = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);

  if (!dataSetData?.uuid)
    return (
      <span
        style={{
          width: "1rem",
        }}
      ></span>
    );

  const onClickSearch = () => {
    navigate(
      `/${initialData?.path?.organization}/${dataSetData?.uuid}/list/all`,
      {
        state: {
          highlightSearch: true,
        },
      }
    );
  };

  return (
    <Box
      display="inline-flex"
      alignItems="center"
      sx={{
        "& .MuiBadge-badge": {
          minWidth: "1.5rem",
          width: "1.5rem",
          height: "1.5rem",
          fontSize: "0.75rem",
          lineHeight: "0.75rem",
        },
      }}
    >
      <DemoData />
      <BalanceView />
      <DSSelectorView />
      <TransactionsAddOverlay />
      <Icon
        onClick={onClickSearch}
        icon={<HiOutlineSearch />}
        fontSize={{ xs: "1.15rem", small: "1.2rem" }}
        color={theme.palette.color.slate[700]}
        style={{
          cursor: "pointer",
          marginLeft: "1rem",
          "&:hover svg": {
            color: `${theme.palette.primary.main} !important`,
          },
        }}
      />
      <Divider
        orientation="vertical"
        sx={{
          opacity: 0.7,
          borderWidth: 1,
          height: "1rem",
          mx: { xs: "0.5rem", s1280: "1.5rem" },
        }}
      />
    </Box>
  );
};

const TransactionsAddOverlay = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <CustomPopoverScratch
      button={
        <Tooltip arrow title={t("Add")}>
          <div
            style={{
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: theme.palette.color.slate[200],
              borderRadius: theme.borderRadius.main,
              marginLeft: "1rem",
            }}
          >
            <Icon
              icon={<MdOutlineAdd />}
              fontSize={"1.3rem"}
              color={theme.palette.color.slate[700]}
              style={{
                cursor: "pointer",
                p: 0.5,
                "&:hover svg": {
                  color: `${theme.palette.primary.main} !important`,
                },
              }}
            ></Icon>
          </div>
        </Tooltip>
      }
    >
      <HeaderAddOverlay />
    </CustomPopoverScratch>
  );
};

const DemoData = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const s1920 = useMediaQuery("(min-width:1920px)");

  const theme = useTheme();
  const globalContext = React.useContext(GlobalContext);
  const dsRef = globalContext?.dsRef;
  const isAddAccount = useRef(null);
  let title = "";
  let icon = null;
  //redux
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);
  const datasetAccountList = useSelector(
    (state) => state.boardSlice.datasetAccountList
  );

  const DEMO = useMemo(() => {
    return datasetAccountList?.find((o1) => o1.title?.toLowerCase() === "demo");
  }, [datasetAccountList]);
  const accountDsList = useMemo(() => {
    return datasetAccountList?.filter((o1) => !o1.internal_dataset);
  }, [datasetAccountList]);

  //functions
  const onClickLink = async () => {
    if (isAddAccount.current) {
      dispatch(setJoyRidePayload({ key: "open_integration_overlay" }));
    } else {
      await dsRef.current?.checkDemoDsConnections();
    }
  };

  if (dataSetData?.title?.toLowerCase() === "demo" || DEMO) {
    title = t("Attention! Demo Data. Click here to remove");
    icon = (
      <Icon
        icon={<RiPresentationFill />}
        fontSize={"1.2rem"}
        color={theme.palette.color.yellow[700]}
      ></Icon>
    );
    isAddAccount.current = false;
  }
  if (accountDsList?.length === 0) {
    title = t("No Bank account / Integration assigned");
    icon = (
      <Icon
        icon={<FaPlugCirclePlus />}
        fontSize={"1.2rem"}
        color={theme.palette.color.yellow[700]}
      ></Icon>
    );
    isAddAccount.current = true;
  }
  if (
    accountDsList?.length !== 0 &&
    (dataSetData?.title?.toLowerCase() !== "demo" || !DEMO)
  ) {
    return null;
  }
  return (
    <Tooltip arrow title={title}>
      <Button
        onClick={onClickLink}
        variant="outlined"
        size="small"
        startIcon={icon}
        sx={{
          mr: "0.5rem",
          display: "flex",
          width: "fit-content",
          minWidth: "auto",
          backgroundColor: theme.palette.color.yellow[300],
          color: theme.palette.color.yellow[700],
          borderColor: theme.palette.color.yellow[400],
          borderRadius: theme.borderRadius.main,
          p: "0.5rem 0.75rem",
          textTransform: "initial",
          fontSize: "0.7rem",
          zIndex: 1,
          "&:hover": {
            borderColor: theme.palette.color.yellow[400],
            backgroundColor: theme.palette.color.yellow[400],
          },
          "& .MuiButton-startIcon": {
            ml: 0,
            mr: s1920 ? "0.25rem" : "0 !important",
            fontSize: "0.8rem",
          },
        }}
      >
        {s1920 ? title : ""}
      </Button>
    </Tooltip>
  );
};
