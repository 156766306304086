import {
  DialogContent,
  Typography,
  StepLabel,
  useTheme,
  Stepper,
  Dialog,
  Fade,
  Step,
  Box,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import React, { useState } from "react";
import { utils } from "xlsx";

import SelectHeaderStep from "./SelectHeaderStep";
import MatchColumnsStep from "./MatchColumnsStep";
import SelectSheetStep from "./SelectSheetStep";
import UploadStep from "./UploadStep";

const steps = [
  {
    title: "excel_integration_step_1",
    des: "excel_integration_step_1_des",
  },
  {
    title: "excel_integration_step_2",
    des: "excel_integration_step_2_des",
  },
  {
    title: "excel_integration_step_3",
    des: "excel_integration_step_3_des",
  },
  {
    title: "excel_integration_step_4",
    des: "excel_integration_step_4_des",
  },
];

const SheetComponent = ({ open, onClose, handleFinalSubmit }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  //state
  const [state, setState] = useState({ active: 0, step: steps[0] });

  //functions
  const handleOnNext = (stepState) => {
    setState((prev) => ({
      ...prev,
      ...stepState,
      active: prev?.active + 1,
      step: steps[prev?.active + 1],
    }));
    if (state.active === steps?.length - 1) {
      const finalObject = {
        headers: stepState.headers,
        uploaded_file: state.uploaded_file,
        sheet_name: state.sheet_name,
      };
      handleFinalSubmit(finalObject);
    }
  };

  const handleOnBack = () => {
    setState((prev) => ({
      ...prev,
      active: prev?.active - 1,
      step: steps[prev?.active - 1],
    }));
  };

  return (
    <Dialog
      open={!!open}
      onClose={onClose}
      TransitionComponent={Fade}
      PaperProps={{
        style: {
          width: "80vw",
          height: "85vh",
          minWidth: "none",
          maxWidth: "none",
        },
      }}
      sx={{
        zIndex: 1303,
      }}
    >
      <DialogContent
        sx={{
          height: "100%",
          p: "2rem",
          backgroundColor: theme.palette.color.white,
        }}
      >
        <Stepper
          activeStep={state?.active}
          alternativeLabel
          sx={{
            mb: "1rem",
            display: "flex",
            justifyContent: "center",

            "& .MuiStepLabel-iconContainer": {
              pr: "0.5px !important",

              "&.Mui-completed": {
                "& .MuiSvgIcon-root": {
                  color: `${theme.palette.color.green[500]} !important`,
                },
              },
              "&.Mui-active": {
                pr: "0.25px !important",
                borderRadius: "50rem",
                border: `1px solid ${theme.palette.color.green[500]}`,
                "& .MuiSvgIcon-root": {
                  color: "transparent",
                  "& .MuiStepIcon-text": {
                    fill: theme.palette.color.green[500],
                  },
                },
              },
              "&.Mui-disabled": {
                pr: "0.25px !important",
                borderRadius: "50rem",
                border: `1px solid ${theme.palette.color.slate[300]}`,
                "& .MuiSvgIcon-root": {
                  color: "transparent",
                  "& .MuiStepIcon-text": {
                    fill: theme.palette.color.slate[300],
                  },
                },
              },
            },
          }}
        >
          {steps.map((item) => (
            <Step key={item?.title}>
              <StepLabel
                optional={
                  <Typography
                    variant="caption"
                    color="inherit"
                    sx={{ display: "block" }}
                  >
                    {t(item?.des)}
                  </Typography>
                }
              >
                {t(item?.title)}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box
          sx={{
            height: "calc(100% - 10rem)",
            overflowY: "auto",
            ...theme.thinScrollBar,
          }}
        >
          <StepContent
            state={state}
            onNext={handleOnNext}
            onBack={handleOnBack}
            onCancel={onClose}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default SheetComponent;

const StepContent = ({ state, onNext, onBack,onCancel }) => {
  const { t } = useTranslation();

  //functions
  const mapWorkbook = (workbook, sheet_name = "") => {
    const worksheet = workbook?.Sheets[sheet_name || workbook.SheetNames[0]];
    const data = utils.sheet_to_json(worksheet, {
      header: 1,
      blankrows: false,
      raw: false,
    });
    return data;
  };

  const onContinueUpload = async (workbook, uploaded_file) => {
    onNext({ workbook, uploaded_file });
  };

  const onContinueSelectSheet = async (sheet_name) => {
    try {
      const mappedWorkbook = mapWorkbook(state?.workbook, sheet_name);
      if (mappedWorkbook?.length === 0) {
        enqueueSnackbar(t("excel_sheet_is_empty"), {
          variant: "error",
          autoHideDuration: 5000,
          preventDuplicate: true,
        });
        return;
      }
      onNext({
        data: mappedWorkbook,
        sheet_name,
      });
    } catch (e) {
      console.log("error at sheet selection", e.message);
    }
  };

  const onContinueSelectSheetHeaders = async (headerIndex) => {
    try {
      onNext({
        headerValues: state?.data[headerIndex],
        headerIndex,
      });
    } catch (e) {
      console.log("error at header selection", e);
    }
  };

  const onContinueMatchColumns = async (headers) => {
    try {
      onNext({
        headers,
      });
    } catch (e) {
      console.log("error at match columns", e);
    }
  };

  switch (state.active) {
    case 0:
      return (
        <UploadStep
          state={state}
          onContinue={onContinueUpload}
          onCancel={onCancel}
        />
      );
    case 1:
      return (
        <SelectSheetStep
          state={state}
          onNext={onContinueSelectSheet}
          onBack={onBack}
        />
      );
    case 2:
      return (
        <SelectHeaderStep
          state={state}
          onNext={onContinueSelectSheetHeaders}
          onBack={onBack}
        />
      );
    case 3:
      return (
        <MatchColumnsStep
          state={state}
          onSave={onContinueMatchColumns}
          onBack={onBack}
        />
      );
    default:
      return null;
  }
};
