import {
  ListItemText,
  Typography,
  useTheme,
  Tooltip,
  Box,
} from "@mui/material";
import { useIsFetching, useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import Button from "@mui/lab/LoadingButton";
import { useSelector } from "react-redux";
import { useRef, useState } from "react";

import {
  getTransactionByParams,
  getIntegrationCard,
  getFinApiQueryKey,
  formatAmount,
  truncate,
} from "../../../Helper/data";
import useDebounce from "../../../hooks/3-useDebounce/useDebounce";
import ComponentLoader from "../../../components/ComponentLoader";
import StatusView from "../../Settings/DataSource/StatusView";
import ActionButton from "../../../components/ActionButton";
import useStatusHook from "../../../hooks/useStatusHook";
import { Color, Constant } from "../../../Helper";
import Icon from "../../../components/Icon";
import { queryClient } from "../../../App";

const AccountView = ({ dsType, tab, currency, onClickAddBank, sx }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const loadingRef = useRef(null);

  const accounts = useSelector((state) => state.globalSlice.accounts);
  const accountByDS = useSelector((state) => state.globalSlice.accountByDS);
  const dataSource = useSelector((state) => state.globalSlice.dataSource);
  const testLoading = useSelector((state) => state.appSlice.testLoading);

  const isFetchingIntegrations = useIsFetching({ queryKey: ["integrations"] });

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  //functions
  const updateCount = () => {
    setLoading(true);
    clearTimeout(loadingRef.current);

    let filterData = [];
    let state = [];
    if (tab === "archived") {
      state = [2];
    } else if (tab !== "all") {
      state = [1, 3];
    }

    dataSource?.forEach((ds) => {
      if (
        (currency ? ds?.currency === currency : true) &&
        ds?.title !== "DEMO" &&
        dsType?.includes(ds?.type) &&
        !ds?.internal_dataset &&
        (state?.length > 0 ? state?.includes(ds?.state) : true)
      ) {
        if (tab === "connected" || tab === "disconnected") {
          const FinApiQueryKey = getFinApiQueryKey({ data_source: ds?.uuid });

          const queryData = queryClient.getQueryData(
            ds?.type === 12
              ? FinApiQueryKey
              : [
                  "integrations",
                  {
                    data_source: ds?.uuid,
                    apiType: "data_source_external_connections",
                  },
                ]
          );

          if (
            tab === "connected" &&
            (queryData === "COMPLETED" ||
              queryData === "UPDATED" ||
              queryData === "AUTHENTICATING" ||
              ds?.note === "ManualDS")
          ) {
            filterData.push(ds);
          }
          if (
            tab === "disconnected" &&
            queryData !== "COMPLETED" &&
            queryData !== "UPDATED" &&
            queryData !== "AUTHENTICATING"
          ) {
            filterData.push(ds);
          }
        } else {
          filterData.push(ds);
        }
      }
    });
    setData(filterData);
    loadingRef.current = setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  useDebounce(
    () => {
      if (accounts && isFetchingIntegrations === 0) {
        updateCount();
      }
    },
    100,
    [tab, isFetchingIntegrations, accounts, currency],
    true
  );

  if (dataSource?.length === 0 || testLoading || loading) {
    return (
      <ComponentLoader
        loading={true}
        isSkeleton
        height="18rem"
        skeletonCount={3}
        placeHolderHeight="6rem"
        skeltonSx={{
          transform: "scale(1,0.8)",
        }}
        sx={{
          "& .MuiSkeleton-root": {
            backgroundColor: theme.palette.color.slate[200],
          },
        }}
      />
    );
  }

  return (
    <Box
      sx={{
        "& .MuiPaper-root:before": {
          backgroundColor: "transparent",
        },
        ...sx,
      }}
    >
      {data?.length > 0 ? (
        data?.map((ds, index) => {
          const account = accountByDS?.[ds?.uuid]?.[0];
          const card = getIntegrationCard({ ds });

          return (
            <Box
              key={ds?.uuid || index}
              sx={{
                display: "flex",
                height: "fit-content",
                alignItems: "center",
                justifyContent: "space-between",
                borderRadius: theme.borderRadius.main,
                backgroundColor: theme.palette.color.white,
                boxShadow: theme.boxShadow,
                p: "2.2rem !important",
                position: "relative",
                mb: "1rem",
              }}
            >
              <StatusView uuid={ds?.uuid} type="dot" />

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  position: "absolute",
                  left: "2rem",
                  top: "1.1rem",
                }}
              >
                {account?.bank_details?.logo_url ? (
                  <img
                    component="img"
                    style={{
                      maxWidth: "4rem",
                      height: "1.9rem",
                      objectFit: "contain",
                      p: "1rem",
                    }}
                    src={account?.bank_details?.logo_url}
                    alt="Logo"
                  />
                ) : card?.icon ? (
                  <Icon
                    icon={card?.icon}
                    color={Color.black}
                    fontSize={"2.1rem"}
                  ></Icon>
                ) : (
                  <img
                    component="img"
                    style={{
                      maxWidth: "4rem",
                      height: "1.9rem",
                      objectFit: "contain",
                      p: "1rem",
                    }}
                    src={card?.img}
                    alt="Logo"
                  />
                )}
                {/* <img
                  component="img"
                  style={{
                    maxWidth: "4rem",
                    // height: "1.9rem",
                    objectFit: "contain",
                    p: "1rem",
                  }}
                  src={Images.transparent.SevDeskTransparentLogo}
                  alt="Logo"
                /> */}
              </div>

              <IBANView ds={ds} card={card} account={account} />
              <AccountBalance ds={ds} account={account} />
            </Box>
          );
        })
      ) : (
        <ActionButton
          buttontype="manage2"
          onClick={onClickAddBank}
          label={t("Add new Integration")}
          sx={{ backgroundColor: theme.palette.color.slate[200] }}
        />
      )}
    </Box>
  );
};

export default AccountView;

const IBANView = ({ account, ds, card }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        textAlign: "left",
        position: "absolute",
        left: "7rem",
        top: "1.25rem",
        width: "65%",
      }}
    >
      <div
        style={{
          textAlign: "left",
          width: "45%",
          position: "relative",
        }}
      >
        <Tooltip
          title={
            ds.internal_dataset
              ? t("Internal data source")
              : ds?.title || account?.bank_details?.title || account?.iban
          }
          followCursor
        >
          <ListItemText
            primary={
              ds.internal_dataset
                ? t("Internal data source")
                : ds?.title || account?.bank_details?.title || account?.iban
            }
            sx={{
              my: 0,
              "& span": {
                fontSize: "0.9rem",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                color: Color.tailwind.slate[700],
                fontWeight: 700,
              },
            }}
          />
        </Tooltip>
        <Typography
          sx={{
            position: "absolute",
            bottom: "-1.2",
            fontSize: "0.65rem",
            fontWeight: 500,
            color: Color.tailwind.slate[400],
            display: "block",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {ds.type === 19
            ? card?.title
            : account?.name
              ? `${truncate(account?.name, 15)} / ${truncate(account?.iban, 15)}`
              : ""}
        </Typography>
      </div>
      {account?.type &&
      (ds?.type === 10 ||
        ds?.type === 12 ||
        (ds?.type === 1 && !ds.internal_dataset)) ? (
        <Button
          disableElevation
          color="primary"
          variant="outlined"
          size="small"
          sx={{
            display: { xs: "none", sm: "flex" },
            ml: "1rem",
            fontSize: "0.55rem",
            textTransform: "capitalize",
            lineHeight: "normal",
            color: theme.palette.color.slate[500],
            borderColor: theme.palette.color.slate[300],
            width: "fit-content",
            minWidth: "unset",
            px: "0.4rem",
            cursor: "default",
            "&:hover": {
              color: theme.palette.color.slate[500],
            },
          }}
        >
          {account?.type}
        </Button>
      ) : null}
      <IntegrationsType ds={ds} />
    </Box>
  );
};

const AccountBalance = ({ account: item, ds }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isAllHeaderApiFetched = useSelector(
    (state) => state.commonSlice.isAllHeaderApiFetched
  );
  const isFirstLoginOverlayOpen = useSelector(
    (state) => state.globalSlice.isFirstLoginOverlayOpen
  );

  const getBalance = (balances) => {
    const total = balances?.reduce(
      (total, item) => parseFloat(total) + parseFloat(item?.amount || 0),
      0
    );
    return parseFloat(total).toFixed(2);
  };

  const data_source_by_uuid_external_connections = useStatusHook(null, {
    queryKey: [
      "integrations",
      {
        data_source: ds?.uuid,
        apiType: "data_source_external_connections",
      },
    ],
  });
  const Integration_Transactions = useQuery({
    queryKey: [
      "transactions",
      {
        page: 1,
        page_size: 1,
        data_source: [ds?.uuid],
        apiType: "Integration_Transactions",
      },
    ],
    queryFn: ({ signal }) => {
      let params = {
        config: {
          signal: signal,
        },
        page: 1,
        page_size: 1,
        data_source: [ds?.uuid],
      };

      const result = getTransactionByParams(params);
      if (result) {
        return result;
      }
    },
    cacheTime: 30000,
    backgroundFetch: true,
    enabled:
      !!isAllHeaderApiFetched &&
      !isFirstLoginOverlayOpen &&
      !!ds?.uuid &&
      !!ds?.is_connected &&
      !!ds?.last_sync_date &&
      ds?.type === 19 &&
      !data_source_by_uuid_external_connections?.isFetching &&
      data_source_by_uuid_external_connections?.data === "UPDATED",
    priority: 1,
  });

  let value = null;
  let AvailableBalance = null;
  let AvailableAmount = null;
  let limit = null;
  let color = theme.palette.color.green[700];
  let bg = theme.palette.color.green[50];
  if (
    ds?.type === 19 &&
    ds?.state === 1 &&
    ds?.is_connected &&
    ds?.last_sync_date &&
    data_source_by_uuid_external_connections?.data === "UPDATED"
  ) {
    value = (Integration_Transactions?.data?.count || 0)?.toString();
  }
  if (ds?.type === 1 && item && item?.balances) {
    let balance = getBalance(item?.balances);
    color =
      balance >= 0
        ? theme.palette.color.green[700]
        : theme.palette.color.red[700];
    bg =
      balance >= 0
        ? theme.palette.color.green[50]
        : theme.palette.color.red[50];

    value = formatAmount({
      amount: balance,
      dataset: { currency: item?.currency },
    });
  }
  if ((ds?.type === 10 || ds?.type === 12) && item && item?.balances) {
    if (item?.show_limit) {
      limit = formatAmount({
        amount: Math.abs(item?.limit),
        dataset: { currency: item?.currency },
      });
    }
    item?.balances?.forEach((element) => {
      if (element?.name === "booked") {
        color =
          Number(element?.amount || 0) + Number(element?.ignored_value || 0) >=
          0
            ? theme.palette.color.green[700]
            : theme.palette.color.red[700];
        bg =
          Number(element?.amount || 0) + Number(element?.ignored_value || 0) >=
          0
            ? theme.palette.color.green[50]
            : theme.palette.color.red[50];
        value = formatAmount({
          amount:
            Number(element?.amount || 0) + Number(element?.ignored_value || 0),
          dataset: { currency: item?.currency },
        });
      }
      if (element?.name === "available") {
        AvailableAmount = element?.amount || 0;
        AvailableBalance = formatAmount({
          amount: element?.amount,
          dataset: { currency: item?.currency },
        });
      }
    });
  }
  if (!value) return null;
  return (
    <Tooltip
      title={`${t("booked_balance_tooltip", {
        BookedBalance: value,
      })} 
          ${
            AvailableAmount > 0
              ? t("available_balance_tooltip", {
                  AvailableBalance: AvailableBalance,
                })
              : ""
          }${
            limit
              ? t("limit_tooltip", {
                  limit: limit,
                })
              : ""
          }`}
    >
      <span>
        <Button
          loading={!!Integration_Transactions?.isFetching}
          disableFocusRipple
          disableElevation
          color="primary"
          variant="outlined"
          size="small"
          sx={{
            position: "absolute",
            right: "1rem",
            textTransform: "initial",
            display: "flow",
            alignItems: "center",
            justifyContent: "flex-end",
            fontSize: "0.85rem",
            fontWeight: theme.typography.fontWeightMedium,
            px: "0.5rem",
            maxWidth: "16%",
            color: color,
            border: "none!important",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            backgroundColor: bg,
            top: "1.35rem",
            "& .MuiCircularProgress-root": {
              width: "1rem !important",
              height: "1rem !important",
              color: color,
            },
            "&:hover": {
              border: "none",
              backgroundColor: bg,
            },
          }}
        >
          {truncate(value, 18)}
        </Button>
      </span>
    </Tooltip>
  );
};

const IntegrationsType = ({ ds }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const type = Constant?.IntegrationsSource?.[ds?.type];
  if (!type) return null;
  return (
    <Button
      disableElevation
      disableRipple
      color="primary"
      variant="text"
      size="small"
      sx={{
        display: { xs: "none", sm: "flex" },
        ml: "0.5rem",
        fontSize: "0.6rem",
        lineHeight: "normal",
        borderRadius: theme.borderRadius.main,
        color: theme.palette.color.slate[500],
        backgroundColor: theme.palette.color.slate[100],
        width: "fit-content",
        minWidth: "unset",
        cursor: "default",
        px: "0.4rem",
        "&:hover": {
          backgroundColor: theme.palette.color.slate[100],
        },
      }}
    >
      {t(type)}
    </Button>
  );
};
