import { useEffect, useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector } from "react-redux";
import { alpha } from "@mui/material";
import _ from "underscore";

import {
  getStatisticsDataWithParams,
  getAllSubCategoryIds,
  getCategoryParentId,
  formatAmount,
  remToPx,
} from "../../../../Helper/data";
import useDebounce from "../../../../hooks/3-useDebounce/useDebounce";
import ComponentLoader from "../../../../components/ComponentLoader";
import PieCharts from "../../../../components/Charts/PieCharts";
import useWidth from "../../../../hooks/useWidth";
import EmptyView from "./Component/EmptyView";
import { Color } from "../../../../Helper";
import theme from "../../../../theme";

const CostWidget = ({ chartType, widgetType, widget }) => {
  const currentWidth = useWidth();
  const { t } = useTranslation();

  //state
  const [data, setData] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(false);

  const columns = [
    {
      field: "categoryName",
      headerName: t("Category"),
      width: remToPx(currentWidth, 18),
    },
    {
      field: "gross_value",
      headerName: t("Amount"),
      width: remToPx(currentWidth, 11),
      valueFormatter: ({ value }) => {
        return formatAmount({
          amount: parseFloat(value || 0).toFixed(2),
        });
      },
    },
    { field: "share", headerName: t("Share"), width: remToPx(currentWidth, 7) },
  ];

  return (
    <div
      style={{
        width: "100%",
        height: "90%",
        paddingTop: "0.5rem",
      }}
    >
      <LogicFunctions
        widget={widget}
        setData={setData}
        widgetType={widgetType}
        setIsFetching={setIsFetching}
        setLoading={setLoading}
      />
      {isLoading || isFetching ? (
        <ComponentLoader
          loading
          hideNoDataPlaceholder
          height={"100%"}
          size={60}
        />
      ) : data?.length > 0 ? (
        <div style={{ height: "100%", width: "100%" }}>
          {chartType === "Pie" ? (
            <PieCharts
              data={data}
              chartProps={{
                outerRadius: remToPx(currentWidth, 9.5),
              }}
            />
          ) : null}
          {chartType === "Table" ? (
            <DataGrid
              disableColumnMenu
              disableSelectionOnClick
              density="compact"
              rows={data}
              columns={columns}
              autoHeight
              pageSize={7}
              // autoPageSize
            />
          ) : null}
        </div>
      ) : (
        <EmptyView type={widgetType} />
      )}
    </div>
  );
};

export default CostWidget;

const LogicFunctions = ({
  setData,
  setIsFetching,
  setLoading,
  widget,
  widgetType,
  type,
}) => {
  //redux
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);
  const use_global_categories = useSelector(
    (state) => state.boardSlice?.dataSetData?.use_global_categories
  );
  const _selectionCategories = useSelector(
    (state) => state.categorySlice?.selectionCategories
  );
  const selectionCategories = useMemo(() => {
    return _selectionCategories?.filter((o1) =>
      use_global_categories ? !o1?.dataset : o1?.dataset === dataSetData?.uuid
    );
  }, [_selectionCategories, dataSetData?.uuid, use_global_categories]);
  const isIncome = widgetType === "income-pie";
  const isExpense = widgetType === "expense-pie";
  const isBoth = widgetType === "cost";
  const WidgetData = useQuery({
    queryKey: [
      "transactions",
      {
        dataset: dataSetData?.uuid,
        widget_uuid: widget?.uuid,
        widgetType,
        widget: {
          start_date: widget?.start_date,
          end_date: widget?.end_date,
        },
      },
    ],
    queryFn: ({ signal }) => {
      let param = {
        config: {
          signal,
        },
        type: "monthly",
        dataset: dataSetData?.uuid,
        from_payment_date: widget?.start_date,
        to_payment_date: widget?.end_date,
        group_by: ["category"],
      };
      if (!dataSetData.use_global_categories) {
        param.category_dataset = dataSetData?.uuid;
      } else {
        param.global_category = true;
      }
      const result = getStatisticsDataWithParams(param);
      if (result) {
        return result;
      }
    },
    enabled: !!widget?.start_date && !!widget?.end_date && !!dataSetData?.uuid,
  });

  const FilteredWidgetCategories = useMemo(() => {
    let array = [];
    widget?.categories?.forEach((cat) => {
      getAllSubCategoryIds({ array, id: cat });
    });
    return array;
  }, [widget?.categories]);

  useEffect(() => {
    setIsFetching(WidgetData?.isFetching);
    if (WidgetData?.isFetching) {
      setLoading(true);
    }
  }, [WidgetData?.isFetching]);

  useDebounce(
    () => {
      if (!WidgetData?.isFetching && WidgetData?.data) {
        const periodTranData = WidgetData?.data?.results || [];
        let array = [];
        if (periodTranData?.length > 0) {
          const widgetCategory =
            FilteredWidgetCategories?.length > 0
              ? selectionCategories?.filter((o1) =>
                  FilteredWidgetCategories.includes(o1?.uuid)
                )
              : selectionCategories?.filter((o1) => !o1.parent);
          const filteredCategory = isBoth
            ? widgetCategory
            : widgetCategory?.filter((o1) =>
                isIncome ? o1?.type === 1 : o1?.type === 2
              );
          const total = periodTranData
            ?.filter((o1) =>
              FilteredWidgetCategories?.length > 0
                ? FilteredWidgetCategories?.includes(o1?.category)
                : true
            )
            ?.reduce(
              (total, item) =>
                parseFloat(total) +
                parseFloat(isIncome || isBoth ? item?.inflow ?? 0 : 0) +
                parseFloat(
                  isExpense || isBoth ? Math.abs(item?.outflow ?? 0) : 0
                ),
              0
            );
          let newCat = [...filteredCategory];
          if (FilteredWidgetCategories?.length === 0) {
            newCat.unshift({
              value: "Uncategorized",
              label: "Uncategorized",
              color: theme.palette.color.slate[400],
            });
          }
          const categoryParent = getCategoryParentId();

          const transactionsByCategory = _.groupBy(
            periodTranData,
            ({ category }) =>
              FilteredWidgetCategories?.length === 0
                ? categoryParent?.[category] || "Uncategorized"
                : category || "Uncategorized"
          );

          newCat?.forEach((element, index) => {
            const categoryId =
              FilteredWidgetCategories?.length === 0
                ? categoryParent?.[element?.value] || "Uncategorized"
                : element?.value || "Uncategorized";
            const categoryData = transactionsByCategory?.[categoryId];

            if (categoryData?.length > 0) {
              const gross_value = categoryData?.reduce(
                (total, item) =>
                  parseFloat(total) +
                  parseFloat(isIncome || isBoth ? item?.inflow ?? 0 : 0) +
                  parseFloat(
                    isExpense || isBoth ? Math.abs(item?.outflow ?? 0) : 0
                  ),
                0
              );

              if (gross_value > 0) {
                const share = ((gross_value * 100.0) / total).toFixed(2);

                array.push({
                  id: index,
                  categoryName: element.label,
                  gross_value: Math.abs(gross_value),
                  color: element.color ?? theme.palette.primary.main,
                  share: `${share}%`,
                });
              }
            }
          });
        }
        setData(array);
        setLoading(false);
      }
    },
    300,
    [
      WidgetData?.data,
      FilteredWidgetCategories,
      WidgetData?.isFetching,
      type,
      selectionCategories,
    ],
    true
  );

  return null;
};
