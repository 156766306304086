import { useImperativeHandle, forwardRef, useState, useRef } from "react";
import { alpha, Box, Tooltip, Typography } from "@mui/material";
import { unstable_runWithPriority } from "scheduler";
import { useTranslation } from "react-i18next";
import { PiStackBold } from "react-icons/pi";
import { useSelector } from "react-redux";
import React from "react";

import GroupTypeToggle from "../../../../components/ToggleGroupeView/GroupTypeToggle";
import ScenariosToggle from "../../../../components/ToggleGroupeView/ScenariosToggle";
import IndicatorType from "../../../../components/ToggleGroupeView/IndicatorType";
import StateToggle from "../../../../components/ToggleGroupeView/StateToggle";
import ToggleGroupeView from "../../../../components/ToggleGroupeView";
import CustomPopover from "../../../../components/PopOver";
import Icon from "../../../../components/Icon";
import theme from "../../../../theme";

const commonStyle = {
  fontSize: "1rem",
  width: "8.5rem",
  color: theme.palette.primary.main,
};

const CardOptionsPopOver = forwardRef((props, _ref) => {
  const chartOptionRef = useRef(null);
  const { t } = useTranslation();

  const appliedFilterlist = useSelector(
    (state) => state.globalSlice.appliedFilterlist
  );
  //state
  const [anchorElChart, setAnchorElChart] = useState(null);

  //function
  const onClickChartPopOver = () => {
    unstable_runWithPriority(2, () => {
      setAnchorElChart(chartOptionRef.current);
    });
  };

  const onCloseChartPopOver = () => {
    unstable_runWithPriority(2, () => {
      setAnchorElChart(null);
    });
  };

  useImperativeHandle(_ref, () => ({
    handleClose() {
      onCloseChartPopOver();
    },
    openManualy() {
      onClickChartPopOver();
    },
  }));

  return (
    <CustomPopover
      type="anchor"
      anchorEl={anchorElChart}
      ref={chartOptionRef}
      onClose={onCloseChartPopOver}
      onClick={onClickChartPopOver}
      title={t("group_options")}
      tooltip={t("tooltip_group_options")}
      width={"fit-content"}
      border="none"
      justifyContent="flex-start"
      iconSize={"1.3rem"}
      icon
      iconStyle={{ p: 0 }}
      paperStyle={{
        border: 0,
        cursorCol: "pointer",
      }}
      iconView={
        <Box
          className={"kanban_5_step"}
          component="span"
          variant="span"
          sx={{
            mx: "1rem",
            color: theme.palette.primary.main,
            border: `1px solid ${
              Boolean(anchorElChart)
                ? theme.palette.primary.main
                : "transparent"
            }`,
            cursor: "pointer",
            backgroundColor: theme.palette.color.white,
            borderRadius: "50%",
            p: "0.25rem",
            "&:hover": {
              border: `1px solid ${theme.palette.primary.main}`,
            },
          }}
        >
          <Icon
            icon={<PiStackBold />}
            fontSize={"1.5rem"}
            color={"inherit"}
          ></Icon>
        </Box>
      }
      hoverBG={"transparent"}
    >
      {" "}
      <Box
        // className={"kanban_6_step"}
        sx={{
          p: "1rem",
          width: "52rem",
          height: "fit-content",
          display: "inline-flex",
          flexDirection: "column",
          justifyContent: "flex-start",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <GroupTypeToggle />
          <IndicatorType />
        </Box>
        <Box sx={{ display: "flex", p: "0.75rem", alignItems: "center" }}>
          <Typography sx={commonStyle}>{t("Inflow")}</Typography>

          {appliedFilterlist?.kanban?.groupType === "scenarios" ? (
            <Tooltip
              title={t("Group_Scenarios")}
              disableFocusListener
              placement="top"
              arrow
            >
              <ScenariosToggle
                className={"kanban_6_step_2"}
                type={"Inflow"}
                width={"20rem"}
                wrapperStyle={{
                  ".MuiInputBase-root": {
                    height: "2.375rem",
                    fontSize: "0.8rem",
                  },
                  ".MuiOutlinedInput-notchedOutline": {
                    border: 0,
                    backgroundColor: alpha(theme.palette.primary.main, 0.04),
                    borderRadius: "0.75rem",
                    height: "2.5625rem",
                  },
                }}
              />
            </Tooltip>
          ) : (
            <Tooltip
              title={t("Group_States")}
              disableFocusListener
              placement="top"
              arrow
            >
              <StateToggle
                className={"kanban_6_step_2"}
                type={"Inflow"}
                width={"20rem"}
                wrapperStyle={{
                  ".MuiInputBase-root": {
                    height: "2.375rem",
                    fontSize: "0.8rem",
                  },
                  ".MuiOutlinedInput-notchedOutline": {
                    border: 0,
                    backgroundColor: alpha(theme.palette.primary.main, 0.04),
                    borderRadius: "0.75rem",
                    height: "2.5625rem",
                  },
                }}
              />
            </Tooltip>
          )}

          <ToggleGroupeView
            className={"kanban_6_step_1"}
            type={"Inflow"}
            wrapperStyle={{ ml: "1rem", mr: "2rem" }}
          />
          {/* <Tooltip
            title={t("Kanban_Card_Size")}
            disableFocusListener
            arrow
            placement="top"
          >
            <SmallViewToggle className={"kanban_6_step_3"} type={"Inflow"} />
          </Tooltip> */}
          {/* <Tooltip
          title={t("Kanban_Group_By")}
          disableFocusListener
          arrow
          placement="top"
        >
          <GroupToggle className={"kanban_6_step_4"} type={"Inflow"} />
        </Tooltip> */}
        </Box>
        <Box sx={{ display: "flex", p: "0.75rem", alignItems: "center" }}>
          <Typography sx={commonStyle}>{t("Outflow")}</Typography>

          {appliedFilterlist?.kanban?.groupType === "scenarios" ? (
            <Tooltip
              title={t("Group_Scenarios")}
              disableFocusListener
              placement="top"
              arrow
            >
              <ScenariosToggle
                type={"Outflow"}
                width={"20rem"}
                wrapperStyle={{
                  ".MuiInputBase-root": {
                    height: "2.375rem",
                    fontSize: "0.8rem",
                  },
                  ".MuiOutlinedInput-notchedOutline": {
                    border: 0,
                    backgroundColor: alpha(theme.palette.primary.main, 0.04),
                    borderRadius: "0.75rem",
                    height: "2.5625rem",
                  },
                }}
              />
            </Tooltip>
          ) : (
            <Tooltip
              title={t("Group_States")}
              disableFocusListener
              placement="top"
              arrow
            >
              <StateToggle
                type={"Outflow"}
                width={"20rem"}
                wrapperStyle={{
                  ".MuiInputBase-root": {
                    height: "2.375rem",
                    fontSize: "0.8rem",
                  },
                  ".MuiOutlinedInput-notchedOutline": {
                    border: 0,
                    backgroundColor: alpha(theme.palette.primary.main, 0.04),
                    borderRadius: "0.75rem",
                    height: "2.5625rem",
                  },
                }}
              />
            </Tooltip>
          )}

          <ToggleGroupeView
            type={"Outflow"}
            wrapperStyle={{ ml: "1rem", mr: "2rem" }}
          />

          {/* <Tooltip
            title={t("Kanban_Card_Size")}
            disableFocusListener
            arrow
            placement="top"
          >
            <SmallViewToggle type={"Outflow"} />
          </Tooltip> */}
          {/* <Tooltip
          title={t("Kanban_Group_By")}
          disableFocusListener
          arrow
          placement="top"
        >
          <GroupToggle className={"kanban_6_step_4"} type={"Outflow"} />
        </Tooltip> */}
        </Box>
      </Box>
    </CustomPopover>
  );
});
export default React.memo(CardOptionsPopOver);
