import { Box, Divider, Stack, Tooltip, useTheme } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { useTranslation } from "react-i18next";
import { forwardRef } from "react";

import ToolTipWrapper from "../../TooltipWrapper";
import { Constant } from "../../../Helper";

const FilterBaseView = forwardRef(
  (
    {
      tooltip,
      color = "slate",
      icon,
      heading,
      allValue,
      value,
      Placeholder = "Select option",
      isActive,
      onClick,
      onClear,
      maxWidth = "7rem",
    },
    _ref
  ) => {
    const { t } = useTranslation();
    const theme = useTheme();
    return (
      <ToolTipWrapper
        title={allValue || t(tooltip)}
        arrow
        placement="top"
        slotProps={{
          tooltip: {
            sx: {
              borderRadius: theme.borderRadius.main,
              padding:  "0.5rem",
            },
          },
        }}
      >
        <Box
          ref={_ref}
          sx={{
            color: theme.palette.color[color][600],
            border: `1px solid ${theme.palette.color[color][isActive ? 400 : 200]}`,
            backgroundColor: theme.palette.color[color][200],
            fontWeight: theme.typography.fontWeightMediumBold,
            borderRadius: theme.borderRadius.borderRadiusXL,
            fontSize: "0.9rem",
            lineHeight: "0.9rem",
            pl: "0.5rem",
            height: Constant.FILTER_HEIGHT,
            ml: "0.75rem",
            display: "flex",
            alignItems: "center",
            "& svg": {
              color: theme.palette.color[color][600],
            },
          }}
        >
          <span style={{ marginRight: "0.25rem" }}>{icon}</span>
          {heading ? t(heading) : ""}
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              ml: "0.5rem",
              borderColor: theme.palette.color[color][isActive ? 400 : 200],
            }}
          />
          <Stack
            onClick={onClick}
            sx={{
              display: "flex",
              px: "0.5rem",
              py: "auto",
              alignItems: "center",
              flexDirection: "row",
              cursor: "pointer",
              color: theme.palette.color[color][!value ? 400 : 500],
              fontWeight: theme.typography.fontWeightMedium,
              fontSize: "0.9rem",
              lineHeight: "normal",
              height: "100%",
              maxWidth: maxWidth,
              "&:hover": {
                backgroundColor: theme.palette.color[color][200],
              },
            }}
          >
            <span
              style={{
                display: "flex",
                alignItems: "center",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                paddingBottom: "1px",
              }}
            >
              {value || t(Placeholder)}
            </span>
          </Stack>
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              borderColor: theme.palette.color[color][isActive ? 400 : 200],
            }}
          />
          <Box
            onClick={onClear}
            sx={{
              display: "flex",
              alignItems: "center",
              height: "100%",
              cursor: "pointer",
              borderTopRightRadius: theme.borderRadius.borderRadiusXL,
              borderBottomRightRadius: theme.borderRadius.borderRadiusXL,
              "&:hover": {
                backgroundColor: theme.palette.color[color][200],
              },
            }}
          >
            <ClearIcon
              sx={{
                mx: "0.25rem",
                color: theme.palette.color[color][!value ? 400 : 500],
                fontSize: "1.1rem",
              }}
            />
          </Box>
        </Box>
      </ToolTipWrapper>
    );
  }
);

export default FilterBaseView;
