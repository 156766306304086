import CryptoJS from "crypto-js";
export const setUser = (userObj) => {
  // userObj = {
  //     email: '',
  //     password: '',
  //     isLogin: true
  // }
  localStorage.setItem("user", JSON.stringify(userObj));
};

export const getUser = () => {
  let user = JSON.parse(localStorage.getItem("user"));
  return user;
};

export const setData = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
};

export const getData = (key) => {
  let data = JSON.parse(localStorage.getItem(key));
  return data;
};

export const UserLogin = "UL";
export const ReportLayout = "ReportLayout";

// Encryption and decryption functions using AES
export const encrypt = (value, encryptionKey) => {
  var encryptedValue = CryptoJS.AES.encrypt(value, encryptionKey).toString();
  return encryptedValue;
};

export const decrypt = (encryptedValue, encryptionKey) => {
  var decryptedValue = CryptoJS.AES.decrypt(
    encryptedValue,
    encryptionKey
  ).toString(CryptoJS.enc.Utf8);
  return decryptedValue;
};

export const setEncryptedItem = (key, value, encryptionKey) => {
  // Encrypt the value using encryptionKey
  const encryptedValue = encrypt(JSON.stringify(value), encryptionKey);

  // Store the encrypted value in localStorage
  localStorage.setItem(key, encryptedValue);
};

export const getDecryptedItem = (key, encryptionKey) => {
  // Retrieve the encrypted value from localStorage
  const encryptedValue = localStorage.getItem(key);

  if (encryptedValue) {
    // Decrypt the value using encryptionKey
    const decryptedValue = JSON.parse(decrypt(encryptedValue, encryptionKey));
    return decryptedValue;
  }

  return null;
};

// setEncryptedItem('secretKey', 'mySecretValue', EncryptionKey);
// const decryptedValue = getDecryptedItem('secretKey', EncryptionKey);
