import { useTranslation } from "react-i18next";
import { Chip, Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import React from "react";

import StateDotView from "../Pages/Datasets/Kanban/Card/StateDotView";
import { getTailwindColor } from "../Helper/data";

const StateChip = ({
  income_expense_type = 1,
  source,
  title = null,
  hideIcon = false,
  hideBorder = false,
  width = "8rem",
  height = "2rem",
  fontSize = "0.7rem",
  defaultShade = 200,
  borderRadius = 2,
  isSelected = false,
  disabled = false,
  showClickCursor = false,
  isEmployeeState = false,
  onClick,
  sx,
}) => {
  const { t } = useTranslation();
  const stateByTitle = useSelector((state) => state.globalSlice.stateByTitle);
  const item = stateByTitle?.[title]?.[0];
  const cursor = !!onClick || showClickCursor;
  let shade = defaultShade;
  let color = item?.color || "slate";
  let tooltip = `${item?.title}_tooltip`;
  if (item?.title === "Booked") {
    // shade = 300;
    // selected_shade = 400;
    if (income_expense_type === 2) {
      color = "red";
    }
  }
  if (item?.isLight) {
    shade = 100;
  }
  if (item?.title === "Ignored") {
    tooltip = `${item?.title}_${source === 4 ? "integration_source" : source === 2 ? "bank_source" : "manual_source"}_tooltip`;
  }
  let selected_shade = shade === 50 ? 100 : shade + 100;

  const onClickChip = () => {
    onClick && onClick(item);
  };

  return (
    <Tooltip key={item?.uuid} title={t(tooltip)} arrow placement="top">
      <Chip
        onClick={onClickChip}
        size="small"
        label={isEmployeeState ? t(`Employee-${item?.title}`) : t(item?.title)}
        disabled={disabled}
        icon={
          !hideIcon ? (
            <StateDotView source={source} title={item?.title} color={color} />
          ) : undefined
        }
        sx={{
          width,
          height,
          borderRadius,
          fontWeight: 500,
          display: "inline-flex",
          alignItems: "center",
          color: getTailwindColor(color, 700),
          backgroundColor: getTailwindColor(
            color,
            isSelected ? selected_shade : shade
          ),
          cursor: cursor ? "pointer" : "auto",
          border: !hideBorder
            ? `3px solid ${getTailwindColor(color, isSelected ? 600 : shade)}`
            : "none",
          "& .MuiChip-label": {
            width: "75%",
            fontSize,
            textAlign: "center",
            lineHeight: "normal",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            px: hideIcon ? 0 : "0.5rem",
          },
          "&.Mui-disabled": {
            opacity: 0.5,
            cursor: "disable",
          },
          "&:hover": {
            backgroundColor: getTailwindColor(
              color,
              shade === 50 ? 100 : shade + 100
            ),
            borderColor: getTailwindColor(
              color,
              isSelected ? 700 : shade === 50 ? 100 : shade + 100
            ),
          },
          ...sx,
        }}
      />
    </Tooltip>
  );
};
export default React.memo(StateChip);
