import {
  eachMonthOfInterval,
  getDaysInMonth,
  startOfQuarter,
  lastDayOfYear,
  isSameQuarter,
  startOfMonth,
  endOfQuarter,
  isSameMonth,
  startOfYear,
  subQuarters,
  addQuarters,
  startOfWeek,
  endOfMonth,
  isSameWeek,
  addMonths,
  subMonths,
  endOfWeek,
  isSameDay,
  subWeeks,
  addWeeks,
  isValid,
  setDate,
  getDate,
  subDays,
  addDays,
  format,
} from "date-fns";
import _ from "underscore";

import { Constant } from "./Constant";

const isBookedStates = ({ state }) => {
  return Constant.bookedPositionsStates
    ?.filter((o1) => !Constant.calculationExcludeStates2.includes(o1))
    ?.includes(state);
};

export const getVatInfo = ({
  isFutureMonth,
  isCurrentMonth,
  monthDate,
  advanceVat,
  business_start_date,
  key,
}) => {
  const subtractVatDate =
    advanceVat?.enabled &&
    (isFutureMonth ||
      (isCurrentMonth &&
        getDate(new Date()) <= Number(advanceVat?.due_day ?? 1)));
  const isVatEnabled = advanceVat && advanceVat?.enabled;
  let startVatDate = null;
  let endVatDate = null;
  let vat_pay_date = 0;
  let isVatPast = false;
  let isVatCurrent = false;
  let isVatFuture = false;
  let isSameVatDay = false;
  let subtractVat = false;

  if (isVatEnabled) {
    const isMonthly =
      String(advanceVat?.frequency) === "1" && !advanceVat?.permanent_extension;
    const isMonthlyExtended =
      String(advanceVat?.frequency) === "1" && advanceVat?.permanent_extension;
    const isQuarterly =
      String(advanceVat?.frequency) === "2" && !advanceVat?.permanent_extension;
    const isQuarterlyExtended =
      String(advanceVat?.frequency) === "2" && advanceVat?.permanent_extension;
    if (isMonthly || isMonthlyExtended) {
      const currentStartDate = format(startOfMonth(new Date()), "yyyy-MM-dd");
      const currentEndDate = format(endOfMonth(new Date()), "yyyy-MM-dd");
      const prevMonth = subMonths(
        startOfMonth(new Date(monthDate)),
        isMonthly ? 1 : 2
      );
      startVatDate = format(startOfMonth(prevMonth), "yyyy-MM-dd");
      endVatDate = format(endOfMonth(prevMonth), "yyyy-MM-dd");
      vat_pay_date = format(
        setDate(new Date(monthDate), Number(advanceVat?.due_day)),
        "yyyy-MM-dd"
      );
      isVatPast = endVatDate < currentStartDate;
      isVatFuture = startVatDate > currentEndDate;
      isVatCurrent = isSameMonth(new Date(startVatDate), new Date());
      if (key === "week") {
        isSameVatDay = isSameWeek(new Date(vat_pay_date), new Date(monthDate), {
          weekStartsOn: 1,
        });
      } else if (key === "quarter") {
        isSameVatDay = isSameQuarter(
          new Date(vat_pay_date),
          new Date(monthDate)
        );
      } else if (key === "day") {
        isSameVatDay = isSameDay(new Date(vat_pay_date), new Date(monthDate));
      } else {
        isSameVatDay = isSameMonth(new Date(vat_pay_date), new Date(monthDate));
      }
      subtractVat =
        subtractVatDate &&
        isSameVatDay &&
        (business_start_date
          ? startVatDate >= format(new Date(business_start_date), "yyyy-MM-dd")
          : true);
    }

    if (isQuarterly || isQuarterlyExtended) {
      const currentStartDate = format(
        getStartOfCustomQuarter(new Date(), business_start_date),
        "yyyy-MM-dd"
      );
      const currentEndDate = format(
        getEndOfCustomQuarter(currentStartDate),
        "yyyy-MM-dd"
      );

      const currentQuarterStartDate = format(
        getStartOfCustomQuarter(new Date(monthDate), business_start_date),
        "yyyy-MM-dd"
      );

      const prevQuarterStart = subMonths(new Date(currentQuarterStartDate), 3);
      const prevQuarterEnd = getEndOfCustomQuarter(prevQuarterStart);

      // Q1: January - March
      // Q2: April - June
      // Q3: July - September
      // Q4: October - December

      startVatDate = format(new Date(prevQuarterStart), "yyyy-MM-dd");
      endVatDate = format(new Date(prevQuarterEnd), "yyyy-MM-dd");

      vat_pay_date = format(
        setDate(
          addMonths(startOfMonth(new Date(endVatDate)), isQuarterly ? 1 : 2),
          Number(advanceVat?.due_day)
        ),
        "yyyy-MM-dd"
      );
      isVatPast = endVatDate < currentStartDate;
      isVatFuture = startVatDate > currentEndDate;
      isVatCurrent = isSameCustomQuarter(
        new Date(startVatDate),
        new Date(),
        business_start_date
      );
      if (key === "week") {
        isSameVatDay = isSameWeek(new Date(vat_pay_date), new Date(monthDate), {
          weekStartsOn: 1,
        });
      } else if (key === "quarter") {
        isSameVatDay = isSameCustomQuarter(
          new Date(vat_pay_date),
          new Date(monthDate)
        );
      } else if (key === "day") {
        isSameVatDay = isSameDay(new Date(vat_pay_date), new Date(monthDate));
      } else {
        isSameVatDay = isSameMonth(new Date(vat_pay_date), new Date(monthDate));
      }
      subtractVat =
        subtractVatDate &&
        isSameVatDay &&
        (business_start_date
          ? startVatDate >= format(new Date(business_start_date), "yyyy-MM-dd")
          : true);
    }
  }

  return {
    subtractVat,
    isVatEnabled,
    startVatDate,
    endVatDate,
    vat_pay_date,
    isVatPast,
    isVatCurrent,
    isVatFuture,
    isSameVatDay,
  };
};

export const getStartingLiquidityValue = ({
  past_table_data,
  selected_scenario_uuid,
  date,
  selectionCategories,
  selectionCategoriesByID,
  business_start_date,
  advanceVat,
  isChart,
  isTable,
  key = "month",
  tableType,
}) => {
  const groupedMonthlyTransactions = _.groupBy(
    past_table_data,
    (item) => item?.[key]
  );
  let LiquidityTotal = 0;
  let VatChargeTotal = 0;
  let currentMonth =
    key === "quarter"
      ? format(startOfQuarter(new Date()), "yyyy-MM-dd")
      : key === "week"
        ? format(
            startOfWeek(new Date(), {
              weekStartsOn: 1,
            }),
            "yyyy-MM-dd"
          )
        : format(new Date(), "yyyy-MM");
  if (date > currentMonth) {
    let dateArray = eachMonthOfInterval({
      start: subMonths(startOfMonth(new Date()), 6),
      end: subMonths(startOfMonth(new Date(date)), 1),
    });

    if (dateArray?.length && selectionCategories) {
      dateArray?.forEach((columnElement) => {
        let monthDate =
          key === "quarter"
            ? format(startOfQuarter(new Date(columnElement)), "yyyy-MM-dd")
            : key === "week"
              ? format(
                  startOfWeek(new Date(columnElement), {
                    weekStartsOn: 1,
                  }),
                  "yyyy-MM-dd"
                )
              : format(new Date(columnElement), "yyyy-MM");
        let statsData = groupedMonthlyTransactions[monthDate] ?? [];
        let monthData = statsData?.filter(
          (o1) =>
            (o1?.category
              ? !Constant?.excludeCategoryTypes?.includes(
                  selectionCategoriesByID?.[o1?.category]?.[0]?.category_type
                )
              : true) &&
            !Constant?.calculationExcludeStates2.includes(o1?.state) &&
            selected_scenario_uuid?.includes(o1?.scenario_uuid)
        );

        let monthEndTotal = 0;
        let monthEndVatChargeTotal = 0;
        let vatData = [];

        const isPastMonth = monthDate < currentMonth;
        const isCurrentMonth = monthDate === currentMonth;
        const isFutureMonth = monthDate > currentMonth;

        const {
          isVatEnabled = false,
          subtractVat = false,
          startVatDate,
          endVatDate,
          isVatPast = false,
          isVatCurrent = false,
          isVatFuture = false,
        } = getVatInfo({
          monthDate,
          isCurrentMonth,
          isFutureMonth,
          advanceVat,
          business_start_date,
          key,
        });

        if (isVatEnabled && subtractVat) {
          vatData = past_table_data?.filter(
            (item) =>
              format(new Date(item?.[key]), "yyyy-MM-dd") >= startVatDate &&
              format(new Date(item?.[key]), "yyyy-MM-dd") <= endVatDate &&
              (item?.category
                ? !Constant?.excludeCategoryTypes?.includes(
                    selectionCategoriesByID?.[item?.category]?.[0]
                      ?.category_type
                  )
                : true) &&
              selected_scenario_uuid?.includes(item.scenario_uuid) &&
              !Constant?.calculationExcludeStates2.includes(item?.state)
          );
        }
        // if (isPastMonth) {
        //   let future_booked_data = monthData?.filter((item) =>
        //     Constant?.bookedPositionsStates?.includes(item.state)
        //   );
        //   let future_booked_total = future_booked_data?.reduce(
        //     (total, item) =>
        //       parseFloat(total) +
        //       parseFloat(item?.inflow ?? 0) +
        //       parseFloat(item?.outflow ?? 0),
        //     0
        //   );
        //   monthEndTotal = future_booked_total;
        // }
        if (isCurrentMonth) {
          let allData = past_table_data?.filter(
            (item) =>
              item?.[key] < currentMonth &&
              Constant.openPositionsStates.includes(item.state) &&
              (item?.category
                ? !Constant?.excludeCategoryTypes?.includes(
                    selectionCategoriesByID?.[item?.category]?.[0]
                      ?.category_type
                  )
                : true) &&
              !Constant?.calculationExcludeStates.includes(item?.state) &&
              selected_scenario_uuid?.includes(item.scenario_uuid)
          );

          let InflowMonthTotal = getMonthlyValue({
            selectionCategories,
            data: monthData,
            type: 1,
            time: "current",
            allData,
            isChart,
            isTable,
            key,
          });
          let OutflowMonthTotal = getMonthlyValue({
            selectionCategories,
            data: monthData,
            type: 2,
            time: "current",
            allData,
            key,
          });

          monthEndTotal =
            (InflowMonthTotal?.total || 0) -
            (InflowMonthTotal?.currentBookedTotal || 0) +
            (OutflowMonthTotal?.total || 0) -
            (OutflowMonthTotal?.currentBookedTotal || 0);
          // if (isTable) {
          //   console.log(
          //     "🚀 current:",
          //     monthDate,
          //     monthData,
          //     InflowMonthTotal,
          //     OutflowMonthTotal,
          //     monthEndTotal
          //   );
          // }
        }
        if (isFutureMonth) {
          let InflowMonthTotal = getMonthlyValue({
            selectionCategories,
            data: monthData,
            type: 1,
            time: "future",
            key,
          });
          let OutflowMonthTotal = getMonthlyValue({
            selectionCategories,
            data: monthData,
            type: 2,
            time: "future",
            key,
          });

          monthEndTotal =
            (InflowMonthTotal?.total || 0) + (OutflowMonthTotal?.total || 0);
          // if (isTable) {
          //   console.log(
          //     "🚀 isFutureMonth:",
          //     monthDate,
          //     monthData,
          //     InflowMonthTotal,
          //     OutflowMonthTotal,
          //     monthEndTotal
          //   );
          // }
        }
        if (!isPastMonth && isVatPast && subtractVat) {
          let Vdata = vatData?.filter((item) =>
            isBookedStates({ state: item?.state })
          );
          monthEndVatChargeTotal = Vdata?.reduce(
            (total, item) =>
              parseFloat(total) +
              parseFloat(item?.vat_outflow ?? 0) +
              parseFloat(item?.vat_inflow ?? 0),
            0
          );
        }
        if (isVatCurrent && subtractVat) {
          let allData = past_table_data?.filter(
            (item) =>
              item?.[key] < startVatDate &&
              selected_scenario_uuid?.includes(item.scenario_uuid) &&
              Constant.openPositionsStates.includes(item.state) &&
              !Constant?.calculationExcludeStates.includes(item?.state)
          );
          let currentInflowVat = getMonthlyValue({
            selectionCategories,
            data: vatData,
            type: 1,
            time: "current",
            calculation_type: "vat",
            allData,
            key,
          });

          let currentOutflowVat = getMonthlyValue({
            selectionCategories,
            data: vatData,
            type: 2,
            time: "current",
            calculation_type: "vat",
            allData,
            key,
          });
          // if (isChart || isTable) {
          //   console.log(
          //     "🚀 current:",
          //     isChart,
          //     isTable,
          //     statsData,
          //     currentInflowVat,
          //     currentOutflowVat
          //   );
          // }
          // console.log("🚀 current vat :", currentInflowVat, currentOutflowVat);
          monthEndVatChargeTotal =
            (currentInflowVat?.vat || 0) + (currentOutflowVat?.vat || 0);
        }
        if (isVatFuture && subtractVat) {
          let futureInflowVat = getMonthlyValue({
            selectionCategories,
            data: vatData,
            type: 1,
            time: "future",
            calculation_type: "vat",
            key,
          });
          let futureOutflowVat = getMonthlyValue({
            selectionCategories,
            data: vatData,
            type: 2,
            time: "future",
            calculation_type: "vat",
            key,
          });
          // console.log(
          //   "🚀  futureInflowVat:",
          //   futureInflowVat,
          //   futureOutflowVat
          // );

          monthEndVatChargeTotal =
            (futureInflowVat?.vat || 0) + (futureOutflowVat?.vat || 0);
        }
        // if (isChart || isTable) {
        //   console.log(
        //     "🚀 / month:",
        //     // isChart,
        //     // isTable,
        //     monthDate,
        //     // isPastMonth,
        //     isCurrentMonth,
        //     isFutureMonth,

        //     startVatDate,
        //     vat_pay_date,
        //     "vat",
        //     isVatPast,
        //     isVatCurrent,
        //     isVatFuture,
        //     monthEndTotal,
        //     monthEndVatChargeTotal
        //   );
        // }

        LiquidityTotal = (LiquidityTotal || 0) + (monthEndTotal || 0);
        VatChargeTotal = (VatChargeTotal || 0) + (monthEndVatChargeTotal || 0);
      });
    }
  }

  return {
    LiquidityTotal,
    VatChargeTotal,
  };
};

export const getMonthlyValue = ({
  selectionCategories,
  data,
  type = 1,
  time = "current",
  calculation_type = "amount",
  allData,
  isCheck,
  isChart,
  isTable,
  key = "month",
}) => {
  let currentMonth =
    key === "quarter"
      ? format(startOfQuarter(new Date()), "yyyy-MM-dd")
      : key === "week"
        ? format(
            startOfWeek(new Date(), {
              weekStartsOn: 1,
            }),
            "yyyy-MM-dd"
          )
        : key === "day"
          ? format(new Date(), "yyyy-MM-dd")
          : format(new Date(), "yyyy-MM");
  let bookedTotal = 0;
  let finalTotal = 0;
  let finalVatTotal = 0;
  let pastData = null;
  let pastTotalSum = 0;
  let pastVatSum = 0;
  let categoryWisePastTotal = {};
  let categoryWisePastVatTotal = {};
  let categoryWiseTotal = {};
  let categoryWiseVatTotal = {};
  let categoryWiseData = {};
  let categoryWisePastData = {};

  let pastStartMonthDate = subMonths(
    startOfMonth(new Date()),
    Constant.Past_Month
  );

  let pastStartMonth =
    key === "quarter"
      ? format(startOfQuarter(pastStartMonthDate), "yyyy-MM-dd")
      : key === "week"
        ? format(
            startOfWeek(pastStartMonthDate, {
              weekStartsOn: 1,
            }),
            "yyyy-MM-dd"
          )
        : key === "day"
          ? format(pastStartMonthDate, "yyyy-MM-dd")
          : format(pastStartMonthDate, "yyyy-MM");
  let unCategorizedData = null;
  if (calculation_type === "amount") {
    unCategorizedData = data?.filter(
      (item) =>
        (time === "current"
          ? item?.[key] === currentMonth
          : item?.[key] > currentMonth) && !item?.category
    );
  } else {
    unCategorizedData = data?.filter((item) => !item?.category);
  }
  if (time === "current") {
    if (calculation_type === "amount") {
      pastData = allData?.filter(
        (item) =>
          Constant?.openPositionsStates?.includes(item?.state) &&
          item?.[key] >= pastStartMonth &&
          item?.[key] < currentMonth
      );
    } else {
      pastData = allData?.filter(
        (item) =>
          Constant?.openPositionsStates?.includes(item?.state) &&
          item?.[key] >= pastStartMonth
      );
    }
  }
  const _unCategorizedPastData = pastData?.filter((item) => !item?.category);
  categoryWiseData["unCategorized"] = unCategorizedData;
  categoryWisePastData["unCategorized"] = _unCategorizedPastData;
  const unCategorizedValue = getTotal({
    data: unCategorizedData,
    pastData: _unCategorizedPastData,
    time,
    type,
    currentMonth,
    uuid: "unCategorized",
    title: "unCategorized",
    calculation_type,
    isCheck: true,
    key,
  });

  if (time === "current") {
    categoryWisePastTotal[unCategorizedValue?.uuid] =
      unCategorizedValue?.past?.total;
    categoryWisePastVatTotal[unCategorizedValue?.uuid] =
      unCategorizedValue?.past?.vat;
    categoryWiseTotal[unCategorizedValue?.uuid] = unCategorizedValue?.total;
    categoryWiseVatTotal[unCategorizedValue?.uuid] =
      unCategorizedValue?.vatTotal;
    // if (isChart && unCategorizedValue?.total > 0) {
    //   console.log("isChart ~", "unCategorized", unCategorizedValue);
    // }
    // if (isTable && unCategorizedValue?.total > 0) {
    // console.log("isTable ~", "unCategorized", unCategorizedValue);
    // }
    bookedTotal = bookedTotal + (unCategorizedValue?.currentBookedTotal || 0);
  }
  pastTotalSum = pastTotalSum + (unCategorizedValue?.past?.total || 0);
  pastVatSum = pastVatSum + (unCategorizedValue?.past?.vat || 0);
  finalTotal = finalTotal + (unCategorizedValue?.total || 0);
  finalVatTotal = finalVatTotal + (unCategorizedValue?.vatTotal || 0);

  selectionCategories?.forEach((category) => {
    if (
      category?.type === type &&
      category?.visible &&
      !Constant?.excludeCategoryTypes?.includes(category?.category_type)
    ) {
      let categorizedData = null;
      if (calculation_type === "amount") {
        categorizedData = data?.filter(
          (item) =>
            (time === "current"
              ? item?.[key] === currentMonth
              : item?.[key] > currentMonth) && item?.category === category?.uuid
        );
      } else {
        categorizedData = data?.filter(
          (item) => item?.category === category?.uuid
        );
      }
      // pastData: pastData?.filter((item) =>
      //     selectionsCategoryUuidCollections?.[category?.uuid]?.includes(
      //       item?.category
      //     )
      //   ),

      const _pastData = pastData?.filter(
        (item) => item?.category === category?.uuid
      );
      const categorizedValue = getTotal({
        data: categorizedData,
        pastData: _pastData,
        time,
        type,
        currentMonth,
        uuid: category?.uuid,
        title: category?.title,
        calculation_type,
        isCheck,
        key,
      });

      categoryWiseData[category?.uuid] = categorizedData;
      categoryWisePastData[category?.uuid] = _pastData;

      if (time === "current") {
        categoryWisePastTotal[categorizedValue?.uuid] =
          categorizedValue?.past?.total;
        categoryWisePastVatTotal[categorizedValue?.uuid] =
          categorizedValue?.past?.vat;
        categoryWiseTotal[categorizedValue?.uuid] = categorizedValue?.total;
        categoryWiseVatTotal[categorizedValue?.uuid] =
          categorizedValue?.vatTotal;
        // if (isChart && categorizedValue?.total > 0) {
        //   console.log("isChart ~", category?.title, categorizedValue);
        // }
        // if (isTable && Math.abs(categorizedValue?.total) > 0) {
        //   console.log("isTable ~", category?.title, categorizedValue);
        // }
        bookedTotal = bookedTotal + (categorizedValue?.currentBookedTotal || 0);
      }
      pastTotalSum = pastTotalSum + (categorizedValue?.past?.total || 0);
      pastVatSum = pastVatSum + (categorizedValue?.past?.vat || 0);
      finalTotal = finalTotal + (categorizedValue?.total || 0);
      finalVatTotal = finalVatTotal + (categorizedValue?.vatTotal || 0);
    }
  });

  return {
    currentBookedTotal: bookedTotal,
    total: finalTotal,
    vat: finalVatTotal,
    past: { total: pastTotalSum, vat: pastVatSum },
    categoryWisePastTotal,
    categoryWisePastVatTotal,
    categoryWiseTotal,
    categoryWiseVatTotal,
    categoryWiseData,
    categoryWisePastData,
    time,
    calculation_type,
  };
};

const getTotal = ({
  data,
  pastData,
  time,
  type,
  uuid,
  title,
  currentMonth,
  calculation_type,
  isCheck,
  key = "month",
}) => {
  if (time === "current") {
    let pastTotal = 0;
    let pastVatTotal = 0;
    let currentBookedTotal = 0;
    let currentTotal = 0;
    let currentVatTotal = 0;
    const filterData =
      calculation_type === "amount"
        ? data?.filter((item) => item?.[key] === currentMonth)
        : data;

    if (pastData) {
      pastTotal = pastData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(type === 1 ? item?.inflow ?? 0 : item?.outflow ?? 0),
        0
      );
      pastVatTotal = pastData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(
            type === 1 ? item?.vat_inflow ?? 0 : item?.vat_outflow ?? 0
          ),
        0
      );
    }

    if (calculation_type === "amount") {
      const filterBookedData = filterData?.filter(
        (item) =>
          !Constant.calculationExcludeStates2.includes(item?.state) &&
          (Constant.bookedPositionsStates.includes(item?.state) ||
            Constant.openPositionsStates.includes(item?.state))
      );
      const filterPlannedData = filterData?.filter((item) =>
        Constant.plannedState.includes(item.state)
      );

      let currentBookedOpenTotal = filterBookedData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(type === 1 ? item?.inflow ?? 0 : item?.outflow ?? 0),
        0
      );
      let currentPlannedTotal = filterPlannedData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(type === 1 ? item?.inflow ?? 0 : item?.outflow ?? 0),
        0
      );

      const isBooked =
        Math.abs(currentBookedOpenTotal) >= Math.abs(currentPlannedTotal);
      const filterRelBookedData = filterData?.filter((item) =>
        isBookedStates({ state: item?.state })
      );
      currentBookedTotal = filterRelBookedData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(type === 1 ? item?.inflow ?? 0 : item?.outflow ?? 0),
        0
      );
      // if (isCheck) {
      //   console.log(
      //     "🚀 / isCheck:",
      //     currentBookedOpenTotal,
      //     currentPlannedTotal,
      //     isBooked,
      //     filterRelBookedData,
      //     currentBookedTotal
      //   );
      // }
      if (isBooked) {
        const currentBookedOpenVatTotal = filterBookedData?.reduce(
          (total, item) =>
            parseFloat(total) +
            parseFloat(
              type === 1 ? item?.vat_inflow ?? 0 : item?.vat_outflow ?? 0
            ),
          0
        );

        currentTotal = currentBookedOpenTotal;
        currentVatTotal = currentBookedOpenVatTotal;
      } else {
        let currentPlannedVatTotal = filterPlannedData?.reduce(
          (total, item) =>
            parseFloat(total) +
            parseFloat(
              type === 1 ? item?.vat_inflow ?? 0 : item?.vat_outflow ?? 0
            ),
          0
        );

        currentTotal = currentPlannedTotal;
        currentVatTotal = currentPlannedVatTotal;
      }
      currentTotal = currentTotal + pastTotal;
      currentVatTotal = currentVatTotal + pastVatTotal;
    } else {
      const dates = _.groupBy(filterData, (item) => item?.[key]);

      Object.keys(dates).forEach((_date) => {
        const isPastMonth = _date < currentMonth;
        const isCurrentMonth = _date === currentMonth;
        const isFutureMonth = _date > currentMonth;
        const monthData = dates?.[_date] || [];
        // if (isCheck) {
        //   console.log(
        //     "🚀datesdatesdates:",
        //     _date,
        //     filterData,
        //     monthData,
        //     isPastMonth,
        //     isCurrentMonth,
        //     isFutureMonth,
        //     currentTotal,
        //     currentVatTotal,
        //     pastTotal,
        //     pastVatTotal
        //   );
        // }
        if (isPastMonth) {
          const pastBookedData = monthData?.filter((item) =>
            isBookedStates({ state: item?.state })
          );
          const pastBookedVatTotal = pastBookedData?.reduce(
            (total, item) =>
              parseFloat(total) +
              parseFloat(
                type === 1 ? item?.vat_inflow ?? 0 : item?.vat_outflow ?? 0
              ),
            0
          );
          currentVatTotal = (currentVatTotal || 0) + pastBookedVatTotal;
          // currentVatTotal = currentPlannedVatTotal;
        }
        if (isCurrentMonth) {
          const filterBookedData = monthData?.filter(
            (item) =>
              !Constant.calculationExcludeStates2.includes(item?.state) &&
              (Constant.bookedPositionsStates.includes(item?.state) ||
                Constant.openPositionsStates.includes(item?.state))
          );
          const filterPlannedData = monthData?.filter((item) =>
            Constant.plannedState.includes(item.state)
          );

          let currentBookedOpenTotal = filterBookedData?.reduce(
            (total, item) =>
              parseFloat(total) +
              parseFloat(type === 1 ? item?.inflow ?? 0 : item?.outflow ?? 0),
            0
          );
          let currentPlannedTotal = filterPlannedData?.reduce(
            (total, item) =>
              parseFloat(total) +
              parseFloat(type === 1 ? item?.inflow ?? 0 : item?.outflow ?? 0),
            0
          );

          const isBooked =
            Math.abs(currentBookedOpenTotal) >= Math.abs(currentPlannedTotal);
          const filterRelBookedData = monthData?.filter((item) =>
            isBookedStates({ state: item?.state })
          );
          currentBookedTotal = filterRelBookedData?.reduce(
            (total, item) =>
              parseFloat(total) +
              parseFloat(type === 1 ? item?.inflow ?? 0 : item?.outflow ?? 0),
            0
          );

          if (isBooked) {
            let currentBookedOpenVatTotal = filterBookedData?.reduce(
              (total, item) =>
                parseFloat(total) +
                parseFloat(
                  type === 1 ? item?.vat_inflow ?? 0 : item?.vat_outflow ?? 0
                ),
              0
            );

            currentTotal = currentTotal + currentBookedOpenTotal;
            currentVatTotal = currentVatTotal + currentBookedOpenVatTotal;
          } else {
            let currentPlannedVatTotal = filterPlannedData?.reduce(
              (total, item) =>
                parseFloat(total) +
                parseFloat(
                  type === 1 ? item?.vat_inflow ?? 0 : item?.vat_outflow ?? 0
                ),
              0
            );

            currentTotal = currentTotal + currentPlannedTotal;
            currentVatTotal = currentVatTotal + currentPlannedVatTotal;
          }
          // if (isCheck) {
          //   console.log(
          //     "🚀 / dates:",
          //     currentMonth,
          //     _date,
          //     isBooked,
          //     currentTotal,
          //     currentVatTotal,
          //     pastTotal,
          //     pastVatTotal,
          //     filterBookedData,
          //     filterRelBookedData,
          //     filterPlannedData
          //   );
          // }
        }
        if (isFutureMonth) {
          const filterOpenData = monthData?.filter(
            (item) =>
              Constant.openPositionsStates.includes(item.state) &&
              !Constant.calculationExcludeStates.includes(item.state)
          );
          const filterPlannedData = monthData?.filter((item) =>
            Constant.plannedState.includes(item.state)
          );

          let futureOpenTotal = filterOpenData?.reduce(
            (total, item) =>
              parseFloat(total) +
              parseFloat(type === 1 ? item?.inflow ?? 0 : item?.outflow ?? 0),
            0
          );
          let futurePlannedTotal = filterPlannedData?.reduce(
            (total, item) =>
              parseFloat(total) +
              parseFloat(type === 1 ? item?.inflow ?? 0 : item?.outflow ?? 0),
            0
          );

          if (Math.abs(futureOpenTotal) >= Math.abs(futurePlannedTotal)) {
            let futureOpenVatTotal = filterOpenData?.reduce(
              (total, item) =>
                parseFloat(total) +
                parseFloat(
                  type === 1 ? item?.vat_inflow ?? 0 : item?.vat_outflow ?? 0
                ),
              0
            );

            currentTotal = currentTotal + futureOpenTotal;
            currentVatTotal = currentVatTotal + futureOpenVatTotal;
          } else {
            let futurePlannedVatTotal = filterPlannedData?.reduce(
              (total, item) =>
                parseFloat(total) +
                parseFloat(
                  type === 1 ? item?.vat_inflow ?? 0 : item?.vat_outflow ?? 0
                ),
              0
            );
            currentTotal = currentTotal + futurePlannedTotal;
            currentVatTotal = currentVatTotal + futurePlannedVatTotal;
          }
        }
      });
      currentTotal = currentTotal + pastTotal;
      currentVatTotal = currentVatTotal + pastVatTotal;
    }

    return {
      currentBookedTotal,
      total: currentTotal,
      vatTotal: currentVatTotal,
      past: {
        total: pastTotal,
        vat: pastVatTotal,
      },
      uuid: uuid,
    };
  }
  if (time === "future") {
    let futureTotal = 0;
    let futureVatTotal = 0;
    const filterData =
      calculation_type === "amount"
        ? data?.filter((item) => item?.[key] > currentMonth)
        : data;

    const filterOpenData = filterData?.filter(
      (item) =>
        Constant.openPositionsStates.includes(item.state) &&
        !Constant.calculationExcludeStates.includes(item.state)
    );
    const filterPlannedData = filterData?.filter((item) =>
      Constant.plannedState.includes(item.state)
    );

    let futureOpenTotal = filterOpenData?.reduce(
      (total, item) =>
        parseFloat(total) +
        parseFloat(type === 1 ? item?.inflow ?? 0 : item?.outflow ?? 0),
      0
    );
    let futurePlannedTotal = filterPlannedData?.reduce(
      (total, item) =>
        parseFloat(total) +
        parseFloat(type === 1 ? item?.inflow ?? 0 : item?.outflow ?? 0),
      0
    );

    if (Math.abs(futureOpenTotal) >= Math.abs(futurePlannedTotal)) {
      let futureOpenVatTotal = filterOpenData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(
            type === 1 ? item?.vat_inflow ?? 0 : item?.vat_outflow ?? 0
          ),
        0
      );

      futureTotal = futureOpenTotal;
      futureVatTotal = futureOpenVatTotal;
    } else {
      let futurePlannedVatTotal = filterPlannedData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(
            type === 1 ? item?.vat_inflow ?? 0 : item?.vat_outflow ?? 0
          ),
        0
      );
      futureTotal = futurePlannedTotal;
      futureVatTotal = futurePlannedVatTotal;
    }
    return {
      total: futureTotal,
      vatTotal: futureVatTotal,
      uuid: uuid,
    };
  }
};

export function collectUUIDs(node, uuids) {
  if (!node) return;

  if (node.uuid) {
    uuids.push(node.uuid);
  }

  if (node.children && node.children.length > 0) {
    node.children.forEach((child) => collectUUIDs(child, uuids));
  }
}

const getLastDate = (id, date) => {
  let result = null;
  if (id === -1) {
    result = lastDayOfYear(new Date(date));
  } else {
    result = endOfMonth(addMonths(new Date(date), id));
  }
  return result;
};

export function calculateEndDate({
  months = 0,
  start_date = null,
  extraMonth = 0,
}) {
  if (!months || Number(months) === 0) return null;
  if (!start_date || !isValid(new Date(start_date))) return null;
  const id =
    months === -1
      ? -1
      : months === -2
        ? 12
        : Math.abs(Number(months) + Number(extraMonth)) - 1;
  const end_date = format(new Date(getLastDate(id, start_date)), "yyyy-MM-dd");

  return end_date;
}

export function calculateLoanTenure({
  principal = 0,
  downPayment = 0,
  emi = 0,
  interest_rate = 0,
  interest_rate_type = 3, // Default to yearly (3)
  VariableRatesItemList = [],
  start_date,
}) {
  if (!principal || Number(principal) === 0) return 0;
  if (!emi || Number(emi) === 0) return 0;
  const _principal = principal - downPayment;
  if (VariableRatesItemList?.length > 0 && start_date) {
    let remainingPrincipal = _principal;
    let tenureInMonths = 0;
    let previousDate = start_date;

    for (let i = 0; i < VariableRatesItemList.length; i++) {
      const { interest_rate, start_date: vstart_date } =
        VariableRatesItemList[i];
      const monthlyInterestRate = interest_rate / (12 * 100);

      const duration = Math.round(
        (vstart_date - previousDate) / (1000 * 60 * 60 * 24 * 30)
      );

      // Calculate the number of months to repay the loan with the current interest rate and EMI
      for (let j = 0; j < duration; j++) {
        const interestForMonth = remainingPrincipal * monthlyInterestRate;
        const principalRepayment = emi - interestForMonth;
        remainingPrincipal -= principalRepayment;
        tenureInMonths++;

        if (remainingPrincipal <= 0) {
          return tenureInMonths;
        }
      }

      previousDate = vstart_date;
    }

    // Handle the remaining principal after the last rate change period
    while (remainingPrincipal > 0) {
      const { interest_rate } =
        VariableRatesItemList[VariableRatesItemList.length - 1];
      const monthlyInterestRate = interest_rate / (12 * 100);
      const interestForMonth = remainingPrincipal * monthlyInterestRate;
      const principalRepayment = emi - interestForMonth;
      remainingPrincipal -= principalRepayment;
      tenureInMonths++;
    }

    return tenureInMonths;
  } else {
    let monthlyInterestRate = 0;
    switch (interest_rate_type) {
      case 1: // Daily
        monthlyInterestRate = (interest_rate / (365 * 100)) * 30;
        break;
      case 2: // Monthly
        monthlyInterestRate = interest_rate / 100;
        break;
      case 3: // Yearly (default)
      default:
        monthlyInterestRate = interest_rate / (12 * 100);
        break;
    }

    // Calculate loan tenure
    const tenureInMonths = Math.ceil(
      Number(monthlyInterestRate) === 0
        ? _principal / emi
        : Math.log(emi / (emi - _principal * monthlyInterestRate)) /
            Math.log(1 + monthlyInterestRate)
    );
    return tenureInMonths;
  }
}

export function calculateEMI({
  principal = 0,
  monthlyInterestRate = 0,
  loanTenureInMonths = 0,
}) {
  if (!principal || Number(principal) === 0) return 0;
  if (!loanTenureInMonths || Number(loanTenureInMonths) === 0) return 0;

  // Calculate EMI
  const emi = parseFloat(
    Number(monthlyInterestRate) === 0
      ? principal / loanTenureInMonths
      : (principal *
          monthlyInterestRate *
          Math.pow(1 + monthlyInterestRate, loanTenureInMonths)) /
          (Math.pow(1 + monthlyInterestRate, loanTenureInMonths) - 1)
  ).toFixed(1);

  return emi;
}

export function calculateLoanHistory({
  principal = 0,
  downPayment = 0,
  loanTenureInMonths = 0,
  interest_rate = 0,
  interest_rate_type = 3, // Default to yearly (3)
  VariableRatesItemList = [],
  start_date,
}) {
  const defaultData = {
    emi: 0,
    totalInterestPaid: 0,
    schedule: [],
  };

  if (!principal || Number(principal) === 0) return defaultData;
  if (!loanTenureInMonths || Number(loanTenureInMonths) === 0)
    return defaultData;
  if (!start_date || !isValid(new Date(start_date))) return defaultData;

  //calculate monthly interest rate
  let monthlyInterestRate = 0;
  switch (interest_rate_type) {
    case 1: // Daily
      monthlyInterestRate = (interest_rate / (365 * 100)) * 30;
      break;
    case 2: // Monthly
      monthlyInterestRate = interest_rate / 100;
      break;
    case 3: // Yearly (default)
    default:
      monthlyInterestRate = interest_rate / (12 * 100);
      break;
  }

  if (monthlyInterestRate === 0) {
    return defaultData;
  }

  //remove initial down payment
  let _principal = principal - downPayment;

  // if (VariableRatesItemList?.length > 0 && start_date) {
  //   let remainingTermMonths = loanTenureInMonths;
  //   let nextDate = null;

  //   let finalInterestRate = 0;
  //   for (let i = 0; i < VariableRatesItemList.length; i++) {
  //     const { interest_rate, start_date } = VariableRatesItemList[i];
  //     nextDate = VariableRatesItemList?.[i + 1]?.start_date
  //       ? new Date(VariableRatesItemList?.[i + 1]?.start_date)
  //       : null;
  //     const durationMonths = nextDate
  //       ? Math.round(
  //           (nextDate - new Date(start_date)) / (1000 * 60 * 60 * 24 * 30)
  //         )
  //       : remainingTermMonths;
  //     const totalRate = durationMonths * interest_rate;
  //     finalInterestRate += totalRate;
  //     remainingTermMonths -= durationMonths;
  //     nextDate = new Date(start_date);
  //   }
  //   _annualInterestRate = parseFloat(
  //     finalInterestRate / loanTenureInMonths
  //   )?.toFixed(0);
  // }
  const emi = calculateEMI({
    principal: _principal,
    downPayment,
    monthlyInterestRate,
    interest_rate_type,
    loanTenureInMonths,
  });
  const months = eachMonthOfInterval({
    start: new Date(start_date),
    end: addMonths(new Date(start_date), loanTenureInMonths),
  });

  const schedule = [];
  months?.forEach((month) => {
    const interest = _principal * monthlyInterestRate; // Interest for the month
    const amount_paid = emi - interest; // Principal repaid in the month
    const closingBalance = _principal - amount_paid; //remaining balance to pay

    schedule.push({
      date: format(month, "yyyy-MM-dd"),
      emi,
      interest: parseFloat(interest)?.toFixed(1),
      opening_balance: parseFloat(_principal)?.toFixed(1),
      amount_paid: parseFloat(amount_paid)?.toFixed(1),
      closing_balance: parseFloat(closingBalance)?.toFixed(1),
    });
    _principal = closingBalance; // Update balance for the next month
  });

  const totalInterestPaid = schedule?.reduce(
    (total, item) => total + parseFloat(item.interest),
    0
  );

  return {
    totalInterestPaid: parseFloat(totalInterestPaid).toFixed(2),
    emi,
    schedule,
  };
}

export const getValidDateInMonth = (date, day) => {
  const daysInMonth = getDaysInMonth(date);
  const validDay = Math.min(day, daysInMonth);
  return setDate(date, validDay);
};

export const calculateDueDate = (start_date, value) => {
  if (!start_date) {
    return null;
  }

  const startDate = new Date(start_date);
  const day = startDate.getDate();
  const dateValue = new Date(value);

  const dueDate = getValidDateInMonth(dateValue, day);

  return format(dueDate, "yyyy-MM-dd");
};

export const addMonthWithForecast = (existingData, forecastMonth) => {
  const dampingFactor = 0.001; // Adjust the damping factor as needed

  const forecastData = { due_date: forecastMonth };

  existingData.forEach((obj) => {
    for (const key in obj) {
      if (key !== "due_date" && key !== "month" && key !== "total_key") {
        if (!forecastData[key]) {
          forecastData[key] = obj[key];
        } else {
          forecastData[key] =
            obj[key] * dampingFactor + forecastData[key] * (1 - dampingFactor);
        }
      }
    }
  });
  return forecastData;
};

export const hexToArgb = (hex) => {
  // Ensure hex color code starts with '#' and has the correct length
  if (hex.startsWith("#") && (hex.length === 7 || hex.length === 4)) {
    // Remove '#' and pad short hex codes
    hex = hex.substring(1);
    if (hex.length === 4) {
      hex = hex
        .split("")
        .map((x) => x + x)
        .join("");
    }
    return `FF${hex}`.toUpperCase(); // Add alpha (opacity) and convert to ARGB
  } else {
    return "FFFFFF"; // Return white if invalid hex code
  }
};

export const hexToRgb = (hex) => {
  let bigint = parseInt(hex.replace("#", ""), 16);
  let r = (bigint >> 16) & 255;
  let g = (bigint >> 8) & 255;
  let b = bigint & 255;
  return [r, g, b];
};

export const formatTimestamp = (timestamp, hideTime) => {
  const logDate = new Date(timestamp);
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  const isToday = logDate.toDateString() === today.toDateString();
  const isYesterday = logDate.toDateString() === yesterday.toDateString();

  if (isToday) {
    return `Today ${
      hideTime
        ? ""
        : logDate.toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          })
    }`;
  } else if (isYesterday) {
    return `Yesterday ${hideTime ? "" : logDate.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })} `;
  } else {
    return (
      logDate.toLocaleDateString([], {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      }) +
      ` ${hideTime ? "" : logDate.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })} `
    );
  }
};

export function getStartOfCustomQuarter(
  date,
  startDate = startOfYear(new Date())
) {
  const startMonth = new Date(startDate).getMonth();
  const year = date.getFullYear();
  const month = date.getMonth();

  const monthDiff = (month - startMonth + 12) % 12; // Difference in months, adjusted for wrapping
  const quarterOffset = Math.floor(monthDiff / 3) * 3; // Offset to the nearest quarter start
  const customQuarterStart = new Date(year, startMonth + quarterOffset, 1);

  // If the custom quarter start date is in the future, subtract 1 year
  if (customQuarterStart > date) {
    customQuarterStart.setFullYear(customQuarterStart.getFullYear() - 1);
  }

  return customQuarterStart;
}

export function getEndOfCustomQuarter(startOfCustomQuarter) {
  return endOfMonth(addMonths(new Date(startOfCustomQuarter), 2));
}

export function isSameCustomQuarter(date1, date2, businessStartDate) {
  const start1 = getStartOfCustomQuarter(date1, businessStartDate);
  const start2 = getStartOfCustomQuarter(date2, businessStartDate);

  return start1.getTime() === start2.getTime();
}

export const getDataSetDateRange = ({
  tableType,
  start_date = new Date(),
  sub_count = 0,
  column_count = Constant.default_column_count,
}) => {
  let dataset_start_date = null;
  let dataset_end_date = null;

  if (tableType === "quarterly") {
    dataset_start_date = format(
      subQuarters(startOfQuarter(new Date(start_date)), sub_count),
      "yyyy-MM-dd"
    );

    dataset_end_date = format(
      endOfQuarter(addQuarters(new Date(dataset_start_date), column_count)),
      "yyyy-MM-dd"
    );
  } else if (tableType === "weekly") {
    dataset_start_date = format(
      subWeeks(
        startOfWeek(new Date(start_date), {
          weekStartsOn: 1,
        }),
        sub_count
      ),
      "yyyy-MM-dd"
    );
    dataset_end_date = format(
      endOfWeek(addWeeks(new Date(dataset_start_date), column_count), {
        weekStartsOn: 1,
      }),
      "yyyy-MM-dd"
    );
  } else if (tableType === "daily") {
    dataset_start_date = format(
      subDays(new Date(start_date), sub_count),
      "yyyy-MM-dd"
    );
    dataset_end_date = format(
      addDays(new Date(dataset_start_date), column_count),
      "yyyy-MM-dd"
    );
  } else {
    dataset_start_date = format(
      subMonths(startOfMonth(new Date(start_date)), sub_count),
      "yyyy-MM-dd"
    );
    dataset_end_date = format(
      endOfMonth(addMonths(new Date(dataset_start_date), column_count)),
      "yyyy-MM-dd"
    );
  }
  return { start_date: dataset_start_date, end_date: dataset_end_date };
};

export const getConnectedScenariosUuid = ({ scenarioById, _scenario }) => {
  let array = [];
  array.push(_scenario?.uuid);
  function checkScenario(ref_scenario) {
    if (ref_scenario) {
      const _ref_scenario = scenarioById?.[ref_scenario]?.[0];
      array.push(_ref_scenario?.uuid);
      checkScenario(_ref_scenario?.reference);
    }
  }
  checkScenario(_scenario?.reference);
  return array;
};

export const getConnectedScenarios = ({ scenarioById, _scenario }) => {
  let array = [];
  array.push(_scenario?.title);
  function checkScenario(ref_scenario) {
    if (ref_scenario) {
      const _ref_scenario = scenarioById?.[ref_scenario]?.[0];
      array.push(_ref_scenario?.title);
      checkScenario(_ref_scenario?.reference);
    }
  }
  checkScenario(_scenario?.reference);
  return array;
};
