import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { alpha, Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Color, Fonts } from "../../Helper";
import { getTailwindColor } from "../../Helper/data";
import { setAppliedFilterslist } from "../../store/slices/global";
import { useTranslation } from "react-i18next";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 8 + ITEM_PADDING_TOP,
      // width: 160,
    },
    sx: {
      "& .MuiList-root": {
        py: 0,
      },
    },
  },
};

export default React.forwardRef(function StateToggle(
  { onClose, onOpen, width, wrapperStyle, type, active, ...otherProps },
  ref
) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  //redux
  const state = useSelector((state) => state.globalSlice.state);
  const appliedFilterlist = useSelector(
    (state) => state.globalSlice.appliedFilterlist
  );

  //state
  const key = ["Booked", "Open"];
  let dataValue = appliedFilterlist?.kanban?.stateValue
    ? appliedFilterlist?.kanban?.stateValue[type] ?? key
    : key;

  //function
  const onChange = (event) => {
    const {
      target: { value },
    } = event;
    let data = {
      ...appliedFilterlist,
      kanban: {
        ...appliedFilterlist?.kanban,
        stateValue: {
          ...appliedFilterlist?.kanban?.stateValue,
          [type]: typeof value === "string" ? value?.split(",") : value,
        },
      },
    };
    dispatch(setAppliedFilterslist(data));
  };

  const onClickLink = () => {
    let data = {
      ...appliedFilterlist,
      kanban: {
        ...appliedFilterlist?.kanban,
        stateValue: {
          ...appliedFilterlist?.kanban?.stateValue,
          [type]:
            state?.length === dataValue?.length
              ? ["Booked"]
              : state?.map((o1) => o1.title),
        },
      },
    };

    dispatch(setAppliedFilterslist(data));
  };

  //render function
  return (
    <Box
      {...otherProps}
      ref={ref}
      sx={{
        display: "flex",
        flexWrap: "wrap",
        mr: "2rem",
        "& .MuiOutlinedInput-root": {
          borderRadius: 6,
          p: "0.4rem",
          backgroundColor: active
            ? alpha(Color.themeColor, 0.1)
            : "transaparent",
          pl: "0.5rem",
          fontSize: "0.95rem",
          color: alpha(Color.themeColor, active ? 1 : 0.6),
          "&:hover": {
            backgroundColor: alpha(Color.themeColor, 0.1),
          },
        },
        ".MuiInputBase-root": {
          height: "2rem",
          fontSize: "0.8rem",
        },
        ".MuiOutlinedInput-notchedOutline": {
          border: "0 !important",
          backgroundColor: Color.hederEleBg,
          borderRadius: 6,
          height: "2.33rem",
        },
        ...wrapperStyle,
      }}
    >
      <FormControl sx={{ width: width ?? "100%", pl: "1.5rem" }}>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          displayEmpty
          value={dataValue}
          onChange={onChange}
          onOpen={onOpen}
          onClose={onClose}
          input={<OutlinedInput />}
          renderValue={(selected) => {
            if (!selected || selected?.length === 0) {
              return <em>{t("Select States")}</em>;
            }
            const translatedSelected = selected.map((item) => t(item));
            return translatedSelected.join(", ");
          }}
          MenuProps={MenuProps}
          inputProps={{ "aria-label": "Without label" }}
        >
          {state?.map((item) => (
            <MenuItem
              key={item?.uuid}
              value={item?.title}
              disabled={item?.title === "Booked"}
              sx={{
                backgroundColor: getTailwindColor(item?.color, 100),
                color: getTailwindColor(item?.color, 600),
                p: 0,
                "&:hover": {
                  backgroundColor: getTailwindColor(item?.color, 200),
                },
                ".Mui-selected": {
                  backgroundColor: getTailwindColor(item?.color, 200),
                },
              }}
            >
              <Checkbox
                sx={{
                  "& .MuiSvgIcon-root": { width: "0.9em", height: "0.9em" },
                }}
                checked={dataValue?.indexOf(item?.title) > -1}
              />
              <ListItemText
                primary={t(item?.title)}
                sx={{ "& span": { fontSize: "0.9rem" } }}
              />
            </MenuItem>
          ))}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              mt: "1rem",
              mb: "0.5rem",
              mx: "1rem",
            }}
          >
            <Typography
              onClick={onClickLink}
              sx={{
                fontSize: "0.8rem !important",
                fontFamily: Fonts.Text,
                fontWeight: 600,
                zIndex: 9999,
                cursor: "pointer",
                color: Color.themeColor2,
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              {state?.length === dataValue?.length
                ? "Un-group  all"
                : "Group all"}
            </Typography>
          </Box>
        </Select>
      </FormControl>
    </Box>
  );
});
