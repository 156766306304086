import {
  IconButton,
  Typography,
  useTheme,
  Stack,
  Grid,
  Box,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArchiveRoundedIcon from "@mui/icons-material/ArchiveRounded";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useDispatch, useSelector } from "react-redux";
import { TbChartAreaLineFilled } from "react-icons/tb";
import { useEffect, useRef, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { RiFolderChartLine } from "react-icons/ri";
import EditIcon from "@mui/icons-material/Edit";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";

import SearchFilterView from "./ThemeFilter/Component/SearchFilterView";
import { setHighlightedCostUnit } from "../../store/slices/board";
import { setPopupStatus3 } from "../../store/slices/datasets";
import CustomPopoverScratch from "../PopOver/CustomPopover";
import useSubscriptions from "../../hooks/useSubscriptions";
import { setCostUnits } from "../../store/slices/global";
import Translate from "../../hooks/HOC/Translate";
import EndPoints from "../../APICall/EndPoints";
import CustomModal from "../Model/CustomModal";
import RadioCircle from "../RadioCircle";
import TitleInput from "./TitleInput";
import AddButton from "../AddButton";
import APICall from "../../APICall";
import MenuView from "./MenuView";
import theme from "../../theme";

const options = [
  {
    value: 1,
    label: "Edit",
    icon: <EditIcon />,
  },
  {
    value: 2,
    label: "Duplicate",
    icon: <ContentCopyIcon />,
  },
  {
    value: 3,
    label: "Archive",
    icon: <ArchiveRoundedIcon />,
  },
  {
    value: 4,
    label: "Delete",
    icon: <DeleteIcon />,
  },
];

const CostUnitDropdown = ({ width = "12rem", color = "slate", hideIcon }) => {
  return (
    <CustomPopoverScratch
      button={<View width={width} color={color} hideIcon={hideIcon} />}
    >
      <OverlayView color={color} />
    </CustomPopoverScratch>
  );
};

export default CostUnitDropdown;

const View = ({ width, color, popup_state, hideIcon }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isOpen = popup_state?.isOpen;
  let title = t("General Planning");
  let icon = <TbChartAreaLineFilled style={{ fontSize: "1.15rem" }} />;

  const highlightedCostUnit = useSelector(
    (state) => state.boardSlice?.highlightedCostUnit
  );
  const costUnitsById = useSelector(
    (state) => state.globalSlice?.costunitsById
  );

  if (highlightedCostUnit?.length > 0) {
    title = costUnitsById?.[highlightedCostUnit?.[0]]?.[0]?.title;
    icon = <RiFolderChartLine style={{ fontSize: "1.15rem" }} />;
  }
  if (hideIcon) {
    icon = null;
  }
  return (
    <Box
      sx={{
        borderRadius: theme.borderRadius.main,
        backgroundColor: theme.palette.color.white,
        border: `1px solid ${theme.palette.color?.[color]?.[300]}`,
        px: "0.6rem",
        py: "0.5rem",
        cursor: "pointer",
        width: width,
        mr: "1rem",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        "&:hover": {
          "& svg, p": {
            color: `${theme.palette.primary.main} !important`,
            borderColor: `${theme.palette.primary.main} !important`,
          },
        },
      }}
    >
      {icon}
      <Typography
        variant={"body2"}
        fontWeight={"fontWeightMediumBold"}
        sx={{
          width: "80%",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          color: theme.palette.color?.[color]?.[600],
          textAlign: "center",
        }}
      >
        {title}
      </Typography>
      <KeyboardArrowDownIcon
        sx={{
          color: theme.palette.color?.[color]?.[600],
          transform: isOpen ? "rotate(-180deg)" : "rotate(0deg)",
          transition: "transform 0.3s ease-in-out",
        }}
      />
    </Box>
  );
};

const ItemView = ({ item, isActive, color, onClick, handleClickMore }) => {
  return (
    <Stack
      key={item?.uuid}
      onClick={onClick}
      direction={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
      sx={{
        color: theme.palette.color?.[color]?.[600],
        borderRadius: 1,
        px: "2rem",
        py: "0.5rem",
        cursor: "pointer",
        width: "100%",
        backgroundColor: isActive
          ? theme.palette.color?.[color]?.[100]
          : "transparent",
        "&:hover": {
          backgroundColor: theme.palette.color?.[color]?.[isActive ? 100 : 50],
        },
      }}
    >
      <Stack direction={"row"} alignItems={"center"}>
        <RadioCircle checked={isActive} size={18} color={color} />
        <Typography
          variant={"subtitle2"}
          fontWeight={"fontWeightMediumBold"}
          sx={{ ml: "1.5rem" }}
        >
          {item?.title}
        </Typography>
      </Stack>
      {handleClickMore ? (
        <Stack direction={"row"} alignItems={"center"}>
          <Box
            backgroundColor={`color.green.50`}
            sx={{
              px: 2,
              py: 0.5,
              mr: 2,
              borderRadius: theme.borderRadius.borderRadiusMD,
            }}
          >
            <Typography
              variant="caption"
              fontWeight={"fontWeightMediumBold"}
              color={`color.green.500`}
              sx={{
                maxWidth: "11rem",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {"Active"}
            </Typography>
          </Box>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-haspopup="true"
            onClick={handleClickMore}
            sx={{ background: "transparent !important", p: 0 }}
          >
            <MoreVertIcon
              sx={{
                background: "transparent !important",
                color: theme.palette.color.slate[600],
              }}
            />
          </IconButton>
        </Stack>
      ) : null}
    </Stack>
  );
};

const OverlayView = ({ color }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const itemRef = useRef({});

  //redux state
  const costUnits = useSelector((state) => state.globalSlice.costunits);
  const highlightedCostUnit = useSelector(
    (state) => state.boardSlice?.highlightedCostUnit
  );

  //state
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const [searchText, setSearchText] = useState("");

  //life cycle method
  useEffect(() => {
    getCostUnitsApi(false);
  }, []);

  //Api
  const deleteCostUnitByIdApi = async (id) => {
    await APICall("delete", EndPoints.costunits + `${id}/`).then((response) => {
      if (response.status === 204 && response) {
        const data = costUnits?.filter((o1) => o1.uuid !== id);
        dispatch(setCostUnits(data));
        dispatch(setPopupStatus3(null));
        if (highlightedCostUnit?.includes(id)) {
          dispatch(setHighlightedCostUnit([]));
        }
        enqueueSnackbar(t("CostUnit_Deleted_Successfully"), {
          variant: "success",
          autoHideDuration: 2000,
        });
      }
    });
  };

  const getCostUnitsApi = async () => {
    await APICall("get", EndPoints.costunits).then((response) => {
      if (response.status === 200 && response.data) {
        let data = response.data.results;
        dispatch(setCostUnits(data));
      }
    });
  };

  const cloneCostUnitApi = async (obj) => {
    await APICall("post", EndPoints.costunits, obj).then((response) => {
      if (response.status === 201 && response.data) {
        const data = [...costUnits, response.data];
        dispatch(setCostUnits(data));
        enqueueSnackbar(t("New_Costunit_Added_Successfully"), {
          variant: "success",
          autoHideDuration: 2000,
        });
      }
      if (response.status === 400 && response.data?.title?.[0]) {
        enqueueSnackbar(response.data?.title?.[0], {
          variant: "error",
          autoHideDuration: 4000,
          preventDuplicates: true,
        });
      }
    });
  };

  //function
  const onClick = (value) => {
    dispatch(setHighlightedCostUnit(value));
  };

  const openAddForm = (e) => {
    itemRef.current = {
      modalType: "add",
      payload: null,
    };
    setAnchorEl(e.currentTarget);
  };

  const onClickItem = (e, value) => {
    if (value === 1) {
      setAnchorEl(e.currentTarget);
    }
    if (value === 2) {
      setAnchorElMenu(null);
      cloneCostUnitApi({
        ...itemRef.current?.payload,
        title: `Copy of ${itemRef.current?.payload?.title}`,
      });
    }
    if (value === 4) {
      setAnchorElMenu(null);
      onClickDelete();
    }
  };

  const handleClickMore = (e, item) => {
    itemRef.current = {
      modalType: "edit",
      payload: item,
    };
    setAnchorElMenu(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  const onClickDelete = () => {
    dispatch(
      setPopupStatus3({
        open: true,
        overlay_type: "delete",
        onConfirm: onOkDelete,
        payload: {
          title: t("Attention"),
          message: <Translate i18nkey={`costunit_delete_message`} />,
          confirmText: t("Ok"),
          hideCancel: true,
        },
      })
    );
  };

  const onOkDelete = () => {
    deleteCostUnitByIdApi(itemRef.current?.payload?.uuid);
  };

  const onClickClearSearch = () => {
    setSearchText("");
  };

  const onChangeSearch = (e) => {
    setSearchText(e.target?.value);
  };

  return (
    <Grid
      container
      spacing={2}
      sx={{
        width: "40rem",
        pt: "1rem",
        display: "flex",
        flexDirection: "column",
        backgroundColor: theme.palette.color.white,
      }}
    >
      <SearchFilterView
        a
        active={false}
        value={searchText}
        color={color}
        onClickClear={onClickClearSearch}
        onChange={onChangeSearch}
        sx={{ ml: "2rem", my: "1rem", width: "fit-content" }}
      />
      <Stack
        sx={{
          width: "100%",
          maxHeight: "21rem",
          overflow: "auto",

          ...theme.thinScrollBar,
        }}
      >
        <ItemView
          key={"general_planning"}
          color={color}
          item={{ item: "general_planning", title: t("General Planning") }}
          isActive={highlightedCostUnit?.length === 0}
          onClick={() => onClick([])}
        />
        {costUnits?.map((item) => {
          const isActive = highlightedCostUnit?.includes(item?.uuid);
          if (
            searchText &&
            !item?.title?.toLowerCase()?.includes(searchText?.toLowerCase())
          ) {
            return null;
          }
          return (
            <ItemView
              key={item?.uuid}
              item={item}
              isActive={isActive}
              color={color}
              onClick={() => onClick([item?.uuid])}
              handleClickMore={(e) => handleClickMore(e, item)}
            />
          );
        })}
      </Stack>
      <AddButton
        onClick={openAddForm}
        label={t("add New Cost Unit")}
        isSecondary
        hideKey
        sx={{
          width: "fit-content",
          mt: "3rem",
          ml: "2rem",
          mb: "1.5rem",
        }}
      />

      <InnerOverlay
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        modalType={itemRef.current?.modalType}
        payload={itemRef.current?.payload}
      />
      {Boolean(anchorElMenu) ? (
        <MenuView
          anchorEl={anchorElMenu}
          open={Boolean(anchorElMenu)}
          options={options}
          handleClose={handleCloseMenu}
          onClickItem={onClickItem}
        />
      ) : null}
    </Grid>
  );
};

const InnerOverlay = ({
  anchorEl,
  setAnchorEl,
  modalType = "add",
  payload,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isSubscriptionValid] = useSubscriptions();
  const isDataUpdated = useRef(false);

  //redux state
  const costUnits = useSelector((state) => state.globalSlice.costunits);

  //state
  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState({
    title: "",
    alias: "",
    note: null,
  });
  const [error, setError] = useState({
    title: "",
    reference: "",
  });

  //api
  const addCostUnitApi = async (obj) => {
    setLoading(true);
    await APICall("post", EndPoints.costunits, obj)
      .then((response) => {
        if (response.status === 201 && response.data) {
          const data = [...costUnits, response.data];
          dispatch(setCostUnits(data));
          enqueueSnackbar(t("New_Costunit_Added_Successfully"), {
            variant: "success",
            autoHideDuration: 2000,
          });
          handleClose();
        }
        if (response.status === 400 && response.data?.title?.[0]) {
          enqueueSnackbar(response.data?.title?.[0], {
            variant: "error",
            autoHideDuration: 4000,
            preventDuplicates: true,
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateCostUnitByID = async (id, obj) => {
    setLoading(true);

    await APICall("patch", EndPoints.costunits + `${id}/`, obj)
      .then((response) => {
        if (response.status === 200 && response.data) {
          const data = [...costUnits];
          const index = data?.findIndex((o1) => o1.uuid === id);
          data[index] = response.data;
          dispatch(setCostUnits(data));
          handleClose();
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (Boolean(anchorEl)) {
      isDataUpdated.current = false;
      if (modalType === "add") {
        setItem({
          title: "",
          reference: "",
          color: null,
        });
      } else {
        setItem(payload);
      }
    }
  }, [anchorEl, modalType, payload]);

  //functions
  const onChange = (event) => {
    isDataUpdated.current = true;
    if (error?.[event.target.name]) {
      setError((prev) => ({ ...prev, [event.target.name]: false }));
    }
    setItem((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const onConfirm = () => {
    if (isSubscriptionValid({ showMessage: true })) {
      if (item?.title?.trim("") === "") {
        setError((prev) => ({
          ...prev,
          title: true,
        }));
        return;
      }

      if (modalType === "add") {
        const maxPosition = costUnits?.reduce(
          (max, { position }) => Math.max(max, position),
          0
        );
        const obj = {
          title: item?.title,
          alias: "",
          note: "",
          position: (Number(maxPosition) || 0) + 1,
        };
        addCostUnitApi(obj);
      } else {
        if (isDataUpdated.current) {
          setLoading(true);
          updateCostUnitByID(item?.uuid, item);
        }
      }
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <CustomModal
      textAdd={modalType === "add" ? t("Create") : t("save")}
      heading={t("Create CostUnit")}
      onClose={handleClose}
      onAdd={onConfirm}
      loadingAdd={loading}
      modalType={modalType}
      open={open}
    >
      <Box
        sx={{
          backgroundColor: theme.palette.color.white,
          p: "2rem",
          width: "fit-content",
          maxHeight: "50rem",
          minWidth: "45rem",
          height: "max-content",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          border: 0,
          borderRadius: 4,
          position: "relative",
        }}
      >
        <TitleInput
          value={item?.title}
          error={Boolean(error?.title)}
          helperText={error?.title}
          onChange={onChange}
          name="title"
          label={t("COSTUNIT TITLE")}
          placeholder={t("costunit_title_placeholder")}
          hideTitle
          likeGoogle
          variant="filled"
          disabled={loading}
          size="small"
          sx={{
            width: "100%",
          }}
        />
      </Box>
    </CustomModal>
  );
};
