import { createSlice } from "@reduxjs/toolkit";

import { resetAllState } from "./reset";

const initialState = {
  staffSearchText: "",
  staffData: [],
  employeeType: [],
  employeeDepartment: [],
  employeeScenarios: [],
  employeeStateTab: "Planned",

  //contract
  contractData: [],

  //contact
  contactSearchText: "",
  contactStates: [],
  contactTypes: [],
  // contactScenarios: [],
  // contactCategory: [],
};

const staffSlice = createSlice({
  name: "staffSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(resetAllState, () => initialState);
  },
  reducers: {
    setStaffData: (state, action) => {
      state.staffData = action.payload;
    },
    setEmployeeStateTab: (state, action) => {
      state.employeeStateTab = action.payload;
    },
    setEmployeeScenarios: (state, action) => {
      state.employeeScenarios = action.payload;
    },
    setEmployeeType: (state, action) => {
      state.employeeType = action.payload;
    },
    setEmployeeDepartment: (state, action) => {
      state.employeeDepartment = action.payload;
    },
    setStaffSearchText: (state, action) => {
      state.staffSearchText = action.payload;
    },

    //contract functions
    setContractData: (state, action) => {
      state.contractData = action.payload;
    },

    //customer functions
    setContactSearchText: (state, action) => {
      state.contactSearchText = action.payload;
    },
    setContactStates: (state, action) => {
      state.contactStates = action.payload;
    },
    setContactTypes: (state, action) => {
      state.contactTypes = action.payload;
    },
    resetCustomer: (state, action) => {
      if (action.payload) {
        Object.keys(action.payload).forEach((key) => {
          state[key] = action.payload[key];
        });
      } else {
        state.contactSearchText = "";
        state.contactStates = [];
        state.contactTypes = [];
      }
    },
  },
});
export const {
  setStaffData,
  setEmployeeStateTab,
  setEmployeeScenarios,
  setEmployeeType,
  setEmployeeDepartment,
  setStaffSearchText,

  //contract functions
  setContractData,

  // customer functions
  setContactSearchText,
  setContactStates,
  setContactTypes,
  resetCustomer,
} = staffSlice.actions;
export default staffSlice;
