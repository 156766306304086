import { Dialog, Box, Typography, useTheme } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useTranslation } from "react-i18next";
import * as React from "react";

import TailwindButton from "../../../components/Overlay/TailwindButton";
import Translate from "../../../hooks/HOC/Translate";

const CategoryLevelAttentionOverlay = ({
  open,
  setOpen,
  title,
  message,
  onCloseCallback,
  onAdd,
  loading,
  addText,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  //functions
  const onClose = () => {
    setOpen(false);
    if (onCloseCallback) onCloseCallback();
  };

  return (
    <div>
      <Dialog
        open={!!open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ zIndex: 1303 }}
        PaperProps={{
          style: {
            minWidth: "65rem",
            maxWidth: "70rem",
            height: "15rem",
            padding: "2rem",
            boxShadow:
              "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
            borderRadius: 8,
          },
        }}
      >
        <Typography
          variant="div"
          sx={{
            display: "flex",
            alignItems: "center",
            fontSize: "1.2rem",
            lineHeight: "1.5rem",
            fontWeight: "600",
            color: "color.slate.500",
          }}
        >
          <InfoOutlinedIcon
            sx={{
              color: "color.slate.500",
              mr: "1rem",
            }}
          />

          {t(title)}
        </Typography>
        <Typography
          my="1.5rem"
          variant="body1"
          sx={{
            fontSize: "1rem",
            color: "color.description",
          }}
        >
          <Translate i18nkey={message} />
        </Typography>

        <Box
          sx={{
            bottom: "1.5rem",
            position: "absolute",
            mt: "2rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "95%",
          }}
        >
          <TailwindButton
            type="cancel"
            text={t("Cancel")}
            onClick={onClose}
            backgroundColorCancel={theme.palette.color.slate[200]}
            sx={{
              ml: 0,
              borderRadius: 50,
            }}
          />
          <TailwindButton
            text={addText ? t(addText) : t("Ok")}
            onClick={onAdd}
            loading={loading}
            backgroundColorCancel={theme.palette.color.slate[200]}
            sx={{
              ml: 0,
              borderRadius: 50,
            }}
          />
        </Box>
      </Dialog>
    </div>
  );
};

export default CategoryLevelAttentionOverlay;
