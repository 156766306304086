import { forwardRef, useImperativeHandle, useRef, useState } from "react";

import RulesListViewOverlay from "./RulesListViewOverlay";
import AttentionOverlay from "./AttentionOverlay";
import RuleModel from "./RuleModel";

const RulesCommonView = forwardRef((props, _ref) => {
  const data = useRef(null);
  const payload = useRef({});

  //state
  const [open, setOpen] = useState(false);
  const [openRule, setOpenRule] = useState(false);
  const [openRuleList, setOpenRuleList] = useState(false);

  //functions
  const onOpen = (_data) => {
    data.current = _data;
    setOpen(true);
  };

  const onOpenRule = (_payload) => {
    payload.current = _payload;
    setOpenRule(true);
  };

  const openRulesListOverlay = () => {
    setOpenRuleList(true);
  };

  const closeRulesListOverlay = () => {
    setOpenRuleList(null);
  };

  useImperativeHandle(_ref, () => ({
    onOpen,
    onOpenRule,
    openRulesListOverlay,
    closeRulesListOverlay,
  }));

  return (
    <>
      {openRule && (
        <RuleModel
          setOpen={setOpenRule}
          open={openRule}
          // transactionsRow
          // preventClose
          // extraPayload
          // rule
          // modalType
          // notEditable
          {...payload.current}
        />
      )}
      {open ? (
        <AttentionOverlay
          open={open}
          setOpen={setOpen}
          data={data}
          title="Attention"
          message="category_rule_already_available"
        />
      ) : null}
      {openRuleList ? (
        <RulesListViewOverlay open={openRuleList} setOpen={setOpenRuleList} />
      ) : null}
    </>
  );
});

export default RulesCommonView;
