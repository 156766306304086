import { Alert, Box, Divider, Typography, useTheme } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Color, Fonts } from "../../../Helper";
import Android12Switch from "../../../components/A12Switch";
import APICall from "../../../APICall";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import EndPoints from "../../../APICall/EndPoints";
import Button from "@mui/lab/LoadingButton";

const Security = () => {
  const { t } = useTranslation();
  const is_owner = useSelector(
    (state) => state.settingsSlice?.profile?.is_owner
  );
  if (!is_owner) {
    return (
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Alert severity="info" sx={{ mt: "8rem", width: "fit-content" }}>
          {t("user_permission_area_restricted_info")}
        </Alert>
      </Box>
    );
  }
  return (
    <Box
      sx={{
        display: "inline-flex",
        width: "100%",
        height: "100%",
        flexDirection: "column",
        backgroundColor: Color.appThemeBg,
        position: "relative",
      }}
    >
      <Box
        sx={{
          height: "45rem",
          width: "60%",
          mt: "4rem",
          ml: "75px",
        }}
      >
        <Typography
          variant="h5"
          color="color.slate.700"
          fontWeight={"fontWeightMediumBold"}
        >
          {t("Security")}
        </Typography>
        <Typography
          variant="subtitle2"
          color="color.description"
          fontWeight={"fontWeightMedium"}
          sx={{
            my: "0.5rem",
            mb: "1.5rem",
          }}
        >
          {t("security_description")}
        </Typography>
        <Divider sx={{ my: "1.5rem" }} />

        <SettingsOption title="MFA_title" description="MFA_description" />
        <Divider />
        <ChangePasswordOption
          title="ChangePassword_title"
          description="ChangePassword_description"
        />
        <Divider />
      </Box>
    </Box>
  );
};

export default Security;

const SettingsOption = ({ title, description }) => {
  const { t } = useTranslation();
  const [isSwitchOn, setIsSwitchOn] = useState(true);

  const handleSwitchChange = (event) => {
    setIsSwitchOn(event.target.checked);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-start",
        mb: "5rem",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "40%",
          marginTop: "0.5rem",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontSize: "1.2rem",
            fontFamily: Fonts.Text,
            color: Color.tailwind.grey[500],
            fontWeight: 600,
            textTransform: "uppercase",
          }}
        >
          {t(title)}
        </Typography>

        <Typography
          variant="body2"
          sx={{
            color: Color.tailwind.green[500],
            fontFamily: Fonts.Text,
            fontWeight: 600,
            textTransform: "uppercase",
            ml: "0.1rem",
            mt: "0.4rem",
          }}
        >
          {t("recommended")}
        </Typography>
      </div>

      <div style={{ display: "flex", flexDirection: "column", width: "58%" }}>
        <Android12Switch
          disabled={true}
          checked={isSwitchOn}
          onChange={handleSwitchChange}
        />

        <Typography
          variant="body"
          sx={{
            color: "color.description",
            fontFamily: Fonts.Text,
            fontWeight: 500,
            ml: "0.8rem",
          }}
        >
          {t(description)}
        </Typography>
      </div>
    </Box>
  );
};

const ChangePasswordOption = ({ title, description }) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-start",
        mb: "5rem",
        mt: "2rem",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "40%",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontSize: "1.2rem",
            fontFamily: Fonts.Text,
            color: Color.tailwind.grey[500],
            fontWeight: 600,
            textTransform: "uppercase",
          }}
        >
          {t(title)}
        </Typography>
      </div>

      <div style={{ display: "flex", flexDirection: "column", width: "58%" }}>
        <ChangePasswordView />
        <Typography
          variant="body"
          sx={{
            color: "color.description",
            fontFamily: Fonts.Text,
            fontWeight: 500,
            ml: "0.8rem",
          }}
        >
          {t(description)}
        </Typography>
      </div>
    </Box>
  );
};

const ChangePasswordView = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const profile = useSelector((state) => state.settingsSlice?.profile);
  const [loader, setLoader] = useState(false);
  const [resendTimer, setResendTimer] = useState(60);
  const [isResendDisabled, setIsResendDisabled] = useState(false);

  //lifeCycle
  useEffect(() => {
    let interval;
    if (isResendDisabled) {
      interval = setInterval(() => {
        setResendTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [isResendDisabled]);

  useEffect(() => {
    if (resendTimer <= 0) {
      setIsResendDisabled(false);
      setResendTimer(60);
    }
  }, [resendTimer]);

  //api
  const initiatePassResetApi = async (obj) => {
    setLoader(true);
    setIsResendDisabled(true);
    await APICall("post", EndPoints.password_reset, obj)
      .then((response) => {
        if (response.status === 200 && response.data.status === "OK") {
          enqueueSnackbar(t("Password reset link sent to your email"), {
            variant: "success",
            autoHideDuration: 4000,
          });
        }
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const handlePasswordReset = () => {
    if (profile?.email) {
      initiatePassResetApi({ email: profile?.email });
    } else {
      enqueueSnackbar(t("No email found"), {
        variant: "success",
        autoHideDuration: 4000,
      });
    }
  };

  return (
    <Button
      disabled={isResendDisabled}
      loading={loader}
      onClick={handlePasswordReset}
      fontWeight={"fontWeightMedium"}
      sx={{
        ml: "0.8rem",
        width: "fit-content",
        textTransform: "initial",
        color: theme.palette.primary.dark,
        backgroundColor: theme.palette.primary[100],
        mb: "0.5rem",
        height: "2.4rem",
        "&:hover": {
          backgroundColor: theme.palette.primary[200],
        },
        "&.Mui-disabled": {
          backgroundColor: theme.palette.color.slate[200],
        },
      }}
    >
      {loader ? "" : t("Reset Password")}
    </Button>
  );
};
