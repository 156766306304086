import {
  circularProgressClasses,
  CircularProgress,
  FormControlLabel,
  InputAdornment,
  IconButton,
  RadioGroup,
  Typography,
  useTheme,
  Tooltip,
  Radio,
  Stack,
  Box,
} from "@mui/material";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as v4uuid } from "uuid";
import _ from "underscore";

import TailwindButton from "../../Overlay/TailwindButton";
import { formatAmount } from "../../../Helper/data";
import EndPoints from "../../../APICall/EndPoints";
import { Color, Constant } from "../../../Helper";
import TitleInput from "../../Overlay/TitleInput";
import AmountView from "../../Overlay/AmountView";
import DropDown from "../../Overlay/DropDown";
import APICall from "../../../APICall";

export const CostAddOverlay = ({
  recurring_Obj,
  setRecurring_Obj,
  onClosePopOver,
  type = "ongoing_cost",
  formType = "Add",
  defaultData,
  modalType,
  updateRecurrenceFlag,
  error,
  setError,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [title, setTitle] = useState(defaultData?.label || "");
  const [cost_type, set_cost_type] = useState(defaultData?.cost_type || "");

  //api
  const addItemToValueSet = async (recurring_uuid, value_set_uuid, obj) => {
    let data = null;
    await APICall(
      "post",
      EndPoints.recurring_rules +
        `${recurring_uuid}/value_sets/${value_set_uuid}/items/`,
      obj
    ).then((response) => {
      if (response.status === 201 && response.data) {
        data = response.data;
      }
    });

    return data;
  };
  const updateItemToValueSet = async (recurring_uuid, value_set_uuid, obj) => {
    let data = null;
    await APICall(
      "patch",
      EndPoints.recurring_rules +
        `${recurring_uuid}/value_sets/${value_set_uuid}/items/${obj?.uuid}/`,
      obj
    ).then((response) => {
      if (response.status === 200 && response.data) {
        data = response.data;
      }
    });

    return data;
  };

  //functions
  const onChange = (e) => {
    const { value } = e.target;
    setTitle(value);
    setError({});
  };

  const onClickSave = async () => {
    if (cost_type?.trim("") === "") {
      setError({ ...error, cost_type: t("cost type is required") });
      return;
    }
    if (title?.trim("") === "") {
      setError({ ...error, title: t("title is required") });
      return;
    }
    updateRecurrenceFlag();

    const ongoing_cost = recurring_Obj?.value_sets?.[0];
    const one_off_cost = recurring_Obj?.value_sets?.[1];
    if (type === "ongoing_cost") {
      let items = _.clone(recurring_Obj?.value_sets?.[0]?.items || []);
      const oldItem = items?.find((item) => item?.uuid === defaultData?.uuid);
      const obj = {
        title,
        cost_type,
        type: oldItem?.type || 1,
        value: oldItem?.value || "0",
        repetition: oldItem?.repetition || 1,
        uuid: defaultData?.uuid || v4uuid(),
      };

      if (formType === "Add") {
        if (modalType === "edit") {
          if (recurring_Obj?.value_sets?.[0]?.uuid) {
            const newItem = await addItemToValueSet(
              recurring_Obj?.uuid,
              recurring_Obj?.value_sets?.[0]?.uuid,
              obj
            );
            items.push(newItem);
          }
        } else {
          items.push(obj);
        }
      } else {
        if (modalType === "edit") {
          if (recurring_Obj?.value_sets?.[0]?.uuid) {
            const newItem = await updateItemToValueSet(
              recurring_Obj?.uuid,
              recurring_Obj?.value_sets?.[0]?.uuid,
              obj
            );
            const index = items?.findIndex(
              (item) => item?.uuid === defaultData?.uuid
            );
            items[index] = newItem;
          }
        } else {
          const index = items?.findIndex(
            (item) => item?.uuid === defaultData?.uuid
          );
          items[index] = obj;
        }
      }

      setRecurring_Obj({
        ...recurring_Obj,
        value_sets: [{ ...ongoing_cost, items }, one_off_cost],
      });
    } else {
      let items = _.clone(recurring_Obj?.value_sets?.[1]?.items || []);
      const oldItem = items?.find((item) => item?.uuid === defaultData?.uuid);
      const obj = {
        title,
        cost_type,
        type: oldItem?.type || 1,
        value: oldItem?.value || "0",
        repetition: oldItem?.repetition || null,
        uuid: defaultData?.uuid || v4uuid(),
      };
      if (formType === "Add") {
        if (modalType === "edit") {
          if (recurring_Obj?.value_sets?.[1]?.uuid) {
            const newItem = await addItemToValueSet(
              recurring_Obj?.uuid,
              recurring_Obj?.value_sets?.[1]?.uuid,
              obj
            );
            items.push(newItem);
          }
        } else {
          items.push(obj);
        }
      } else {
        if (modalType === "edit") {
          if (recurring_Obj?.value_sets?.[1]?.uuid) {
            const newItem = await updateItemToValueSet(
              recurring_Obj?.uuid,
              recurring_Obj?.value_sets?.[1]?.uuid,
              obj
            );
            const index = items?.findIndex(
              (item) => item?.uuid === defaultData?.uuid
            );
            items[index] = newItem;
          }
        } else {
          const index = items?.findIndex(
            (item) => item?.uuid === defaultData?.uuid
          );
          items[index] = obj;
        }
      }
      setRecurring_Obj({
        ...recurring_Obj,
        value_sets: [ongoing_cost, { ...one_off_cost, items }],
      });
    }
    onClosePopOver();
    if (defaultData?.handleCloseMenu) {
      defaultData?.handleCloseMenu();
    }
  };

  useEffect(() => {
    setTimeout(() => {
      const element = document.getElementById("ongoing-cost-input");
      element?.focus();
    }, 500);
  }, []);

  return (
    <Box
      sx={{
        width: "30rem",
        height: "17rem",
        position: "relative",
        p: "1.5rem",
        borderRadius: theme.borderRadius.main,
        backgroundColor: theme.palette.color.white,
      }}
    >
      <Box sx={{ width: "100%", display: "flex" }}>
        <DropDown
          star
          name="cost_type"
          value={t(cost_type) || ""}
          options={Constant?.CostType}
          hideTitle
          likeGoogle
          label={t("label_employee_cost_type")}
          helperText={error?.["cost_type"]}
          error={Boolean(error?.["cost_type"])}
          tooltip={""}
          variant="filled"
          freeSolo
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          renderOption={(option) => t(option?.display_name) ?? ""}
          onChange={(event, newValue) => {
            setError({});
            if (typeof newValue === "string") {
              set_cost_type(newValue);
              if (title?.trim("") === "") {
                setTitle(newValue);
              }
            } else if (newValue && newValue?.inputValue) {
              if (title?.trim("") === "") {
                setTitle(newValue?.inputValue);
              }
              set_cost_type(newValue?.inputValue);
            } else {
              if (title?.trim("") === "") {
                setTitle(t(newValue?.value));
              }
              set_cost_type(newValue?.value);
            }
          }}
          createDynamicText
          sx={{
            "& .MuiInputBase-root": {
              backgroundColor: theme.palette.color.slate[50],
            },
          }}
        />
        <Tooltip title={t("tooltip_employee_cost_type")} placement="top">
          <HelpOutlineOutlinedIcon
            sx={{
              fontSize: "1rem",
              cursor: "pointer",
              ml: "0.5rem",
              mt: "0.5rem",
              color: theme.palette.primary.main,
            }}
          />
        </Tooltip>
      </Box>
      <TitleInput
        id="ongoing-cost-input"
        value={title || ""}
        label={t("Title")}
        tooltip={t("Title")}
        name="title"
        onChange={onChange}
        helperText={error?.["title"]}
        error={Boolean(error?.["title"])}
        hideTitle
        likeGoogle
        variant="filled"
        sx={{
          "& .MuiInputBase-root": {
            backgroundColor: theme.palette.color.slate[50],
          },
        }}
      />

      <Stack
        direction="row"
        sx={{ width: "100%", justifyContent: "flex-end", mt: "2rem" }}
      >
        <TailwindButton
          text={formType === "Add" ? t("Add") : t("Update")}
          onClick={onClickSave}
        />
      </Stack>
    </Box>
  );
};

export const CostTotalView = ({ recurring_Obj, type }) => {
  let total = 0;
  let grossValue = recurring_Obj?.value;

  if (grossValue && Number(recurring_Obj?.salary_type) === 2) {
    grossValue = grossValue / 12;
  }
  if (type === "ongoing_cost") {
    recurring_Obj?.value_sets?.[0]?.items?.forEach((o1) => {
      let share = 0;
      if (Number(o1?.type) === 2) {
        if (o1?.value && Number(o1?.value) !== 0) {
          share = (Number(grossValue) * Number(o1?.value)) / 100;
        }
      } else {
        share = o1?.value;
      }
      total = total + Number(Math.abs(share));
    });
  } else {
    recurring_Obj?.value_sets?.[1]?.items?.forEach((o1) => {
      let share = 0;

      if (Number(o1?.type) === 2) {
        if (o1?.value && Number(o1?.value) !== 0) {
          share = (Number(grossValue || 0) * Number(o1?.value)) / 100;
        }
      } else {
        share = o1?.value;
      }
      total = total + Number(Math.abs(share));
    });
  }

  return (
    <Typography
      variant="subTitle2"
      fontWeight={"fontWeightMediumBold"}
      fontSize={"0.9rem"}
    >
      {formatAmount({
        amount: Math.abs(total),
      })}
    </Typography>
  );
};

export const RadioInput = ({
  defaultValue,
  disabled,
  name,
  uuid,
  error,
  label,
  cost_type,
  tooltip,
  radioValue,
  handleRadioChange,
  onBlurValue,
  value,
  type,
  dataSetData,
  onClickMenu,
  theme,
  valueSetId,
  sx,
  t,
  index,
}) => {
  const RadioView = (
    <InputAdornment position="start">
      <RadioGroup
        row
        size="small"
        name={name}
        value={radioValue}
        onChange={(e) => handleRadioChange(e, type)}
        sx={{
          py: "0.4rem",
          px: "0.75rem",
          borderRight: `1px solid ${theme.palette.color.slate[400]}`,
          backgroundColor: theme.palette.color.slate[100],
          borderTopLeftRadius: theme.borderRadius.main,
          borderBottomLeftRadius: theme.borderRadius.main,
          "& .MuiFormControlLabel-label": {
            fontWeight: theme.typography.fontWeightMedium,
          },
        }}
      >
        <FormControlLabel
          value={1}
          control={<Radio />}
          label={Constant?.CurrencyPrefix?.[dataSetData?.currency]}
        />
        <FormControlLabel value={2} control={<Radio />} label="%" />
      </RadioGroup>
    </InputAdornment>
  );

  return (
    <Box
      key={label + index}
      sx={{
        width: "100%",
        mb: "1rem",
        display: "flex",
        alignItems: "center",
        ml: "1.5rem",
        "& .MuiFormControlLabel-root": {
          ml: 0,
          mr: 1,
        },
        "& .MuiRadio-root": {
          p: 1,
        },
        "& .MuiInputBase-input": {
          height: "2.6rem",
          p: "0 0 3px",
        },
        "& .MuiInputBase-root": {
          borderRadius: `${theme.borderRadius.main} !important`,
          border: `1px solid ${theme.palette.color.inputTitle}`,
          mt: "0px",
        },

        "& .MuiInputAdornment-root": {
          width: "95%",
        },
        "& input": {
          pr: `0.5rem !important`,
          textAlign: "right",
        },
        "& .MuiFormLabel-root": {
          color: theme.palette.color.mainTitle,
          fontStyle: "italic",
          fontWeight: theme.typography.fontWeightMedium,
        },
        "&:hover": {
          "& #cost-trash-icon": {
            visibility: "visible",
          },
        },
        ...sx,
      }}
    >
      <Stack sx={{ width: "calc(100% - 2rem)" }}>
        <Stack direction="row" sx={{ width: "100%" }}>
          <Tooltip title={label} placement="top">
            <Typography
              variant="subtitle2"
              color="color.mainTitle"
              sx={{
                fontSize: "0.85rem",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                width: "65%",
                pr: 2,
              }}
            >
              {label}
            </Typography>
          </Tooltip>
          <Tooltip title={`${t("Cost Type")}: ${t(cost_type)}`} placement="top">
            <Typography
              variant="subtitle2"
              color="color.mainTitle"
              sx={{
                fontSize: "0.85rem",
                fontStyle: "italic",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                width: "35%",
                textAlign: "right",
                pr: 1,
              }}
            >
              {cost_type ? `${t(cost_type)}` : ""}
            </Typography>
          </Tooltip>
        </Stack>

        <AmountView
          defaultValue={defaultValue}
          name={name}
          onBlur={(value) => onBlurValue(value, name, type)}
          disabled={disabled}
          error={error}
          likeGoogle
          label={""}
          tooltip={tooltip}
          variant="standard"
          currency={dataSetData?.currency}
          mb={0}
          InputProps={{
            startAdornment: RadioView,
          }}
        />
      </Stack>

      <div
        style={{
          width: "2rem",
        }}
      >
        <MenuViewWrapper
          uuid={uuid}
          type={type}
          label={label}
          value={value || defaultValue}
          cost_type={cost_type}
          valueSetId={valueSetId}
          onClickMenu={onClickMenu}
          // onDelete={() => onClickDeleteCost(type, valueSetId, uuid)}
        />
      </div>
    </Box>
  );
};

export const MenuViewWrapper = ({
  onClickMenu,
  type,
  valueSetId,
  cost_type,
  label,
  value,
  uuid,
}) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);

  const onClick = (e) => {
    // setLoading(uuid);
    onClickMenu({
      e,
      setLoading,
      type,
      valueSetId,
      cost_type,
      label,
      value,
      uuid,
    });
  };

  if (loading === uuid) {
    return (
      <div
        style={{
          marginTop: "0.5rem",
          marginLeft: "0.5rem",
          position: "relative",
          display: "flex",
          alignItems: "center",
        }}
      >
        <CircularProgress
          variant="determinate"
          sx={{
            color: Color.tailwind.grey[200],
          }}
          size={20}
          thickness={4}
          value={100}
        />
        <CircularProgress
          variant="indeterminate"
          disableShrink
          sx={{
            color: theme.palette.primary.main,
            animationDuration: "550ms",
            position: "absolute",
            left: 0,
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: "round",
            },
          }}
          size={20}
          thickness={4}
        />
      </div>
    );
  }

  return (
    <IconButton
      aria-label="more"
      id="cost-trash-icon"
      aria-haspopup="true"
      onClick={onClick}
      sx={{
        background: "transparent !important",
        mt: "0.5rem",
        ml: "0.25rem",
        visibility: "hidden",
        cursor: "pointer",
      }}
    >
      <MoreVertIcon
        sx={{
          color: theme.palette.color.slate[600],
        }}
      />
    </IconButton>
  );
};
