/* eslint-disable import/no-webpack-loader-syntax */
import { addMonths, endOfMonth, startOfMonth, subMonths } from "date-fns";
import { useDeferredValue, useEffect, useMemo, useState } from "react";
import Worker from "worker-loader!../../../../workers/worker.js";
import { useIsFetching, useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { unstable_runWithPriority } from "scheduler";
import Spinner from "react-spinkit";

import {
  setAccountBalanceUpdateDate,
  setMonthlyChartData,
  setDailyChartData,
  setChartLineKeys,
} from "../../../../store/slices/board";
import {
  setIsDailyChartLoading,
  setIsMonthChartLoading,
} from "../../../../store/slices/appmain";
import { getStatisticsDataWithParams } from "../../../../Helper/data";
import useDebounce from "../../../../hooks/3-useDebounce/useDebounce";
import { updateFilters } from "../../../../store/slices/global";
import useStatusHook from "../../../../hooks/useStatusHook";
import { Color, Constant } from "../../../../Helper";
import theme from "../../../../theme";

const CommonView = () => {
  const dispatch = useDispatch();

  //redux
  const advanceVat = useSelector((state) => state.globalSlice.advanceVat);
  const scenario = useSelector((state) => state.globalSlice.scenario);
  const scenarioByTitle = useSelector(
    (state) => state.globalSlice.scenarioByTitle
  );
  const state = useSelector((state) => state.globalSlice?.state);
  const datasetAccountList = useSelector(
    (state) => state.boardSlice.datasetAccountList
  );
  const isAllHeaderApiFetched = useSelector(
    (state) => state.commonSlice.isAllHeaderApiFetched
  );
  const uuid = useSelector((state) => state.boardSlice?.dataSetData?.uuid);
  const chartData = useSelector((state) => state.boardSlice?.chartData);
  const isChartExpanded = useSelector(
    (state) => state.chartSlice?.isChartExpanded
  );
  const isFooterExpanded = useSelector(
    (state) => state.chartSlice?.isFooterExpanded
  );
  const end_date = useSelector(
    (state) => state.boardSlice?.dataSetData?.end_date
  );

  const start_date = useSelector(
    (state) => state.boardSlice?.dataSetData?.start_date
  );
  const business_start_date = useSelector(
    (state) => state.boardSlice?.dataSetData?.business_start_date
  );
  const selectedScenarios = useSelector(
    (state) => state.globalSlice.appliedFilterlist?.kanban?.selectedScenarios
  );
  const precisionValue = useSelector(
    (state) => state.globalSlice.appliedFilterlist?.kanban?.precisionValue
  );
  const scenarioValue = useSelector(
    (state) => state.globalSlice.appliedFilterlist?.kanban?.scenarioValue
  );
  const selectedStates = useSelector(
    (state) => state.globalSlice.appliedFilterlist?.kanban?.selectedStates
  );
  const chart_keys = useSelector(
    (state) => state.globalSlice?.appliedFilterlist?.kanban?.chart_keys
  );

  const selectionCategoriesByID = useSelector(
    (state) => state.categorySlice?.selectionCategoriesByID
  );
  const subscription = useSelector(
    (state) => state.settingsSlice?.subscription
  );
  const isDailyChartLoading = useSelector(
    (state) => state.appSlice.isDailyChartLoading
  );
  const isMonthChartLoading = useSelector(
    (state) => state.appSlice.isMonthChartLoading
  );
  const isFirstLoginOverlayOpen = useSelector(
    (state) => state.globalSlice.isFirstLoginOverlayOpen
  );
  const isAccountSelectOverlayOpen = useSelector(
    (state) => state.globalSlice.isAccountSelectOverlayOpen
  );

  const updateChart = useSelector((state) => state.boardSlice.updateChart);
  const use_global_categories = useSelector(
    (state) => state.boardSlice?.dataSetData?.use_global_categories
  );
  const _selectionCategories = useSelector(
    (state) => state.categorySlice?.selectionCategories
  );
  const selectionCategories = useMemo(() => {
    return _selectionCategories?.filter((o1) =>
      use_global_categories ? !o1?.dataset : o1?.dataset === uuid
    );
  }, [_selectionCategories, uuid, use_global_categories]);
  const appliedFilterlist = {
    kanban: {
      scenarioValue: scenarioValue,
      precisionValue: precisionValue,
      selectedScenarios: selectedScenarios,
      selectedStates: selectedStates,
      chart_keys: chart_keys,
    },
  };

  const dataSetData = {
    uuid: uuid,
    start_date: start_date,
    end_date: end_date,
    business_start_date: business_start_date,
    use_global_categories: use_global_categories,
  };

  const isKanbanGroupFetching = useIsFetching({
    queryKey: [
      "transactions",
      {
        dataset: dataSetData?.uuid,
        apiType: "monthly",
        start_date: null,
        end_date: null,
      },
    ],
    exect: true,
  });

  //state
  const [webWorkerMonthly, setWebWorker] = useState(null);
  const [webWorkerDaily, setWebWorkerDaily] = useState(null);
  const allowMonthFetch = isFooterExpanded || isChartExpanded;
  const _to_payment_date = endOfMonth(
    addMonths(new Date(dataSetData?.start_date), Constant.column_count)
  );
  let _start_date = new Date(dataSetData?.start_date);

  const _last_month_date = new Date(startOfMonth(subMonths(new Date(), 1)));

  if (_start_date > _last_month_date) {
    _start_date = _last_month_date;
  }
  const from_payment_date = useDeferredValue(_start_date);
  const to_payment_date = useDeferredValue(_to_payment_date);
  const dataset = useDeferredValue(dataSetData?.uuid);
  let vat_pay_months = 0;
  if (advanceVat && advanceVat?.enabled) {
    if (
      String(advanceVat?.frequency) === "1" &&
      !advanceVat?.permanent_extension
    ) {
      vat_pay_months = 1;
    }

    if (
      String(advanceVat?.frequency) === "1" &&
      advanceVat?.permanent_extension
    ) {
      vat_pay_months = 2;
    }

    if (
      String(advanceVat?.frequency) === "2" &&
      !advanceVat?.permanent_extension
    ) {
      vat_pay_months = 3;
    }
    if (
      String(advanceVat?.frequency) === "2" &&
      advanceVat?.permanent_extension
    ) {
      vat_pay_months = 4;
    }
  } else {
    vat_pay_months = 1;
  }

  //query
  const transaction_monthly_chart = useQuery({
    queryKey: [
      "transactions",
      {
        dataset: dataset,
        apiType: "transaction_monthly_chart",
        from_date: from_payment_date,
        to_date: to_payment_date,
        subMonthNumber: vat_pay_months,
      },
    ],
    queryFn: () => {
      let param = {
        type: "transaction_monthly_chart",
        dataset: dataset,
        from_date: from_payment_date,
        to_date: to_payment_date,
        subMonthNumber: vat_pay_months,
      };
      if (!dataSetData.use_global_categories) {
        param.category_dataset = dataSetData?.uuid;
      } else {
        param.global_category = true;
      }
      const result = getStatisticsDataWithParams(param);

      if (result) {
        return result;
      }
    },
    backgroundFetch: true,
    serialize: (data) => {
      const serializedChartData = JSON.stringify(data);
      return serializedChartData;
    },
    deserialize: (data) => {
      const deserializedChartData = JSON.parse(data);
      return deserializedChartData;
    },
    priority: 1,
    enabled:
      !!allowMonthFetch &&
      !!dataset &&
      !!from_payment_date &&
      !!to_payment_date &&
      !isAccountSelectOverlayOpen &&
      !isFirstLoginOverlayOpen,
  });
  let fetching_payment_monthly_statistic =
    transaction_monthly_chart.isFetching ||
    transaction_monthly_chart.isLoading ||
    !transaction_monthly_chart.isSuccess;

  const transaction_daily_chart = useQuery({
    queryKey: [
      "transactions",
      {
        dataset: dataset,
        apiType: "transaction_daily_chart",
        from_payment_date,
        to_payment_date,
      },
    ],
    queryFn: () => {
      let param = {
        type: "transaction_daily_chart",
        dataset: dataset,
        from_payment_date,
        to_payment_date,
      };
      if (!dataSetData.use_global_categories) {
        param.category_dataset = dataSetData?.uuid;
      } else {
        param.global_category = true;
      }
      const result = getStatisticsDataWithParams(param);

      if (result) {
        return result;
      }
    },

    serialize: (data) => {
      const serializedChartData = JSON.stringify(data);
      return serializedChartData;
    },
    deserialize: (data) => {
      const deserializedChartData = JSON.parse(data);
      return deserializedChartData;
    },
    backgroundFetch: true,
    priority: 2,
    enabled:
      isChartExpanded &&
      appliedFilterlist?.kanban?.precisionValue === 2 &&
      !fetching_payment_monthly_statistic &&
      !!dataset &&
      !!from_payment_date &&
      !!to_payment_date &&
      !isAccountSelectOverlayOpen &&
      !isFirstLoginOverlayOpen,
  });

  let fetching_payment_daily_statistic =
    transaction_daily_chart.isFetching ||
    transaction_daily_chart.isLoading ||
    !transaction_daily_chart.isSuccess;

  const isChartInProgress = useMemo(() => {
    return (
      fetching_payment_monthly_statistic ||
      (appliedFilterlist?.kanban?.precisionValue === 2 &&
        fetching_payment_daily_statistic) ||
      isMonthChartLoading ||
      isDailyChartLoading
    );
  }, [
    appliedFilterlist?.kanban?.precisionValue,
    fetching_payment_daily_statistic,
    fetching_payment_monthly_statistic,
    isDailyChartLoading,
    isMonthChartLoading,
  ]);

  const Transaction_monthly_statistic = useStatusHook(
    "Transaction_monthly_statistic"
  );

  const Limit = useMemo(() => {
    const array = datasetAccountList?.filter(
      (o1) =>
        o1.account?.show_limit &&
        o1.account?.datasets?.includes(dataSetData?.uuid) &&
        o1.active
    );
    return array?.map((o1) => o1.account);
  }, [datasetAccountList, dataSetData?.uuid]);

  //life cycle method
  useEffect(() => {
    if (!webWorkerMonthly) setWebWorker(new Worker());
    if (!webWorkerDaily) setWebWorkerDaily(new Worker());
    return () => {
      webWorkerMonthly?.terminate();
      webWorkerDaily?.terminate();
    };
  }, []);

  //updateComposedMonthlyChart
  useDebounce(
    () => {
      if (
        allowMonthFetch &&
        webWorkerMonthly &&
        !isFirstLoginOverlayOpen &&
        !isKanbanGroupFetching &&
        isAllHeaderApiFetched &&
        !isAccountSelectOverlayOpen &&
        !fetching_payment_monthly_statistic &&
        !Transaction_monthly_statistic?.isFetching &&
        transaction_monthly_chart?.data &&
        dataSetData?.uuid
      ) {
        unstable_runWithPriority(4, () => {
          updateComposedMonthlyChart();
        });
      }
    },
    500,
    [
      isKanbanGroupFetching,
      isAccountSelectOverlayOpen,
      fetching_payment_monthly_statistic,
      isAllHeaderApiFetched,
      transaction_monthly_chart?.data,
      Transaction_monthly_statistic?.isFetching,
      Transaction_monthly_statistic?.data,
      appliedFilterlist?.kanban?.selectedScenarios,
      appliedFilterlist?.kanban?.scenarioValue,
      Limit,
      allowMonthFetch,
      webWorkerMonthly,
      isFooterExpanded,
      isChartExpanded,
      selectionCategories,
      scenario?.length,
      Constant.column_count,
      dataSetData?.start_date,
      dataSetData?.business_start_date,
    ],
    true
  );

  //updateComposedDailyChart
  useDebounce(
    () => {
      if (
        isChartExpanded &&
        webWorkerMonthly &&
        webWorkerDaily &&
        !isFirstLoginOverlayOpen &&
        !isAccountSelectOverlayOpen &&
        !isKanbanGroupFetching &&
        isAllHeaderApiFetched &&
        !fetching_payment_monthly_statistic &&
        !fetching_payment_daily_statistic &&
        !Transaction_monthly_statistic?.isFetching &&
        subscription?.kanban_daily_chart &&
        appliedFilterlist?.kanban?.precisionValue === 2 &&
        transaction_daily_chart?.data &&
        transaction_monthly_chart?.data
      ) {
        unstable_runWithPriority(5, () => {
          updateComposedDailyChart();
        });
      }
    },
    500,
    [
      isChartExpanded,
      webWorkerMonthly,
      isAccountSelectOverlayOpen,
      webWorkerDaily,
      isKanbanGroupFetching,
      isAllHeaderApiFetched,
      fetching_payment_daily_statistic,
      appliedFilterlist?.kanban?.precisionValue,
      appliedFilterlist?.kanban?.scenarioValue,
      appliedFilterlist?.kanban?.selectedScenarios,
      transaction_monthly_chart?.data,
      Transaction_monthly_statistic?.data,
      Transaction_monthly_statistic?.isFetching,
      transaction_daily_chart?.data,
      subscription?.kanban_daily_chart,
      Limit,
      scenario?.length,
      selectionCategories,
      Constant.column_count,
      dataSetData?.start_date,
      dataSetData?.business_start_date,
    ],
    true
  );

  useDebounce(
    () => {
      if (isAllHeaderApiFetched && appliedFilterlist) {
        dispatch(setChartLineKeys(getChartKeys()));
      }
    },
    200,
    [isMonthChartLoading, scenario, state, Limit, chartData, updateChart],
    true
  );

  const updateComposedMonthlyChart = () => {
    let includedScenarios = scenario?.map((o1) => o1.title);
    let includedStates = state?.map((o1) => o1.title);
    let obj = JSON.stringify({
      Limit,
      advanceVat,
      selectionCategoriesByID,
      scenario,
      business_start_date: dataSetData?.business_start_date,
      start_date: dataSetData?.start_date,
      end_date: dataSetData?.end_date,
      booked_balances: transaction_monthly_chart?.data?.booked_balances,
      Transaction_monthly_statistic:
        Transaction_monthly_statistic?.data?.results?.filter(
          (item) => !Constant?.calculationExcludeStates2?.includes(item.state)
        ),
      transaction_monthly_chart: transaction_monthly_chart?.data?.results,
      includedScenarios,
      includedStates,
      scenarioByTitle,
      selectionCategories,
      isDataset: true,
    });
    dispatch(setAccountBalanceUpdateDate());
    dispatch(setIsMonthChartLoading(true));

    ////
    // const data = generateMonthlyChart({ data: obj });
    // const result = JSON.parse(data);
    //   dispatch(setMonthlyChartData(result));
    //   dispatch(setIsMonthChartLoading(false));
    ////

    webWorkerMonthly?.postMessage({
      type: "updateComposedMonthlyChart",
      data: obj,
    });

    webWorkerMonthly.onmessage = (e) => {
      if (e.data) {
        let { data, type } = e.data;
        if (type === "updateComposedMonthlyChart") {
          let result = JSON.parse(data);
          // console.log("🚀 ~  monthly ", result);
          dispatch(setMonthlyChartData(result));
          dispatch(setIsMonthChartLoading(false));
          if (appliedFilterlist?.kanban?.precisionValue === 1) {
            // const chart1 = performance.now();
            // console.log(`<<-- Chart Process took --> ${chart1 - chart0} ms.`);
          }
        }
      } else {
        console.log("worker client Error");
        dispatch(setIsMonthChartLoading(false));
      }
    };
  };

  const updateComposedDailyChart = () => {
    let includedScenarios = scenario?.map((o1) => o1.title);
    let includedStates = state?.map((o1) => o1.title);
    let obj = JSON.stringify({
      booked_balances: transaction_daily_chart?.data?.booked_balances,
      transaction_daily_chart: transaction_daily_chart?.data?.results,
      transaction_monthly_chart: transaction_monthly_chart?.data?.results,
      Transaction_monthly_statistic:
        Transaction_monthly_statistic?.data?.results?.filter(
          (item) => !Constant?.calculationExcludeStates2?.includes(item.state)
        ),
      selectionCategoriesByID,
      scenario,
      business_start_date: dataSetData?.business_start_date,
      start_date: dataSetData?.start_date,
      end_date: dataSetData?.end_date,
      Limit,
      advanceVat,
      includedScenarios,
      includedStates,
      scenarioByTitle,
      selectionCategories,
      isDataset: true,
    });
    dispatch(setIsDailyChartLoading(true));
    webWorkerDaily?.postMessage({
      type: "updateComposedDailyChart",
      data: obj,
    });
    webWorkerDaily.onmessage = (e) => {
      if (e.data) {
        let { data, type } = e.data;
        if (type === "updateComposedDailyChart") {
          let result = JSON.parse(data);
          // const chart1 = performance.now();
          // console.log(`<<-- Chart Process took --> ${chart1 - chart0} ms.`);
          dispatch(setDailyChartData(result));
          dispatch(setIsDailyChartLoading(false));
        }
      } else {
        console.log("worker client Error");
        dispatch(setIsDailyChartLoading(false));
      }
    };
  };

  const getChartKeys = () => {
    if (appliedFilterlist?.kanban && chartData) {
      let chart_keys = appliedFilterlist?.kanban?.chart_keys;

      let vat_array = [];
      if (advanceVat?.enabled) {
        vat_array = [
          {
            uuid: "VAT",
            name: "VAT",
            title: "VAT",
            color: Color.theme.pink["A400"],
            fill: Color.theme.pink["A400"],
            stroke: Color.theme.pink["A400"],
            isSelected:
              !chart_keys || !chart_keys?.hasOwnProperty("VAT")
                ? true
                : chart_keys["VAT"],
            position: 2,
          },
        ];
      }
      let array = [
        ...vat_array,
        Limit && {
          uuid: "Limit",
          name: "Limit",
          stroke: Color.theme.red[500],
          color: Color.theme.red[500],
          fill: Color.theme.red[500],
          title: "Limit",
          can_be_deleted: true,
          isSelected:
            !chart_keys || !chart_keys?.hasOwnProperty("Limit")
              ? true
              : chart_keys?.["Limit"],
          position: 5,
          type: "account",
        },
        ...scenario?.map((o1, index) => {
          return {
            uuid: o1.uuid,
            name: o1.title,
            stroke: o1.color,
            color: o1.color,
            title: o1.title,
            style: o1.style,
            can_be_deleted: o1.can_be_deleted,
            fill: o1.color,
            isSelected: o1.title === "Base" || chart_keys?.[o1.uuid],
            position: 5 + o1.position,
            isScenario: true,
            isLast: index === scenario?.length - 1,
          };
        }),
        // ...state?.map((o1) => {
        //   return {
        //     uuid: o1.uuid,
        //     name: o1.title,
        //     stroke: o1.color,
        //     color: o1.color,
        //     title: o1.title,
        //     can_be_deleted: o1.can_be_deleted,
        //     fill: o1.color,
        //     position: 5 + scenario?.length + o1.position,
        //     isState: true,
        //   };
        // }),
      ];

      const keys = array?.map((o1) => o1?.uuid);
      let update_chart_keys = {};
      if (chart_keys && keys?.length !== Object.keys(chart_keys)?.length) {
        Object.keys(chart_keys)?.forEach((key) => {
          update_chart_keys[key] = chart_keys?.[key] || false;
        });
        updateFilter("chart_keys", update_chart_keys);
      }
      return array?.sort((a, b) => (a?.position > b?.position ? 1 : -1));
    }
  };

  const updateFilter = (key, value) => {
    dispatch(updateFilters({ parent_key: "kanban", obj: { [key]: value } }));
  };

  return (
    <>
      {isChartInProgress && isChartExpanded ? (
        <span
          style={{
            position: "absolute",
            top: "15%",
            zIndex: 4,
            left: "45%",
            fontSize: "1.5rem",
            fontWeight: 600,
            color: theme.palette.primary.main,
          }}
        >
          <Spinner name="line-scale" color={theme.palette.primary.main} />
        </span>
      ) : null}
    </>
  );
};
export default CommonView;
