import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import i18n from "i18next";

// import en from "./Helper/translations/en/translations.json";
// import de from "./Helper/translations/de/translations.json";

// const options = {
//   order: ["navigator"],
//   lookupQuerystring: "lng",
// };

// i18n
//   .use(
//     new Backend(null, {
//       loadPath: "/translations/{{lng}}/{{ns}}.json",
//     })
//   )
//   .use(LanguageDetector)
//   .use(initReactI18next)
//   .init({
//     detection: options,
//     supportedLngs: ["en", "de"],

//     // we init with resources
//     fallbackLng: "en",
//     resources: {
//       en: { translations: en },
//       de: { translations: de },
//     },
//     debug: true,

//     // have a common namespace used around the full app
//     ns: ["translations"],
//     defaultNS: "translations",

//     keySeparator: false, // we use content as keys

//     interpolation: {
//       escapeValue: false,
//     },
//   });

// i18n.fallbacks = true;

// i18n.translations = {
//   en,
//   de,
//   // fr,
//   // nl,
// };

const loadPath = `https://api.i18nexus.com/project_resources/translations/{{lng}}/{{ns}}.json?api_key=${process.env.REACT_APP_I18N_API_KEY}`;
i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "de",
    ns: ["default"],
    defaultNS: "default",
    supportedLngs: ["en", "de"],
    interpolation: { escapeValue: false },
    backend: {
      loadPath: loadPath,
    },
  });
export default i18n;
