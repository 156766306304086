import {
  Typography,
  useTheme,
  Divider,
  Avatar,
  Stack,
  Box,
} from "@mui/material";
import React, { useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router";
import _ from "underscore";

import initialData, { updateLanguage } from "../../../Helper/data";
import TitleInput from "../../../components/Overlay/TitleInput";
import { setProfile } from "../../../store/slices/settings";
import DropDown from "../../../components/Overlay/DropDown";
import EndPoints from "../../../APICall/EndPoints";
import { Color, Fonts } from "../../../Helper";
import APICall from "../../../APICall";

const localeByValue = _.groupBy(
  initialData.euro_countries,
  ({ locale }) => locale
);

const Profile = () => {
  const theme = useTheme();
  const avatar = useRef(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isDataUpdated = useRef(false);
  const euro_countries = useMemo(() => {
    return initialData.euro_countries?.filter(
      (o1) => o1.market === "DE" || o1.market === "GB"
    );
  }, []);
  //redux state
  const profile = useSelector((state) => state.settingsSlice?.profile);

  //api
  const updateProfile = async (obj) => {
    if (isDataUpdated.current) {
      await APICall("patch", EndPoints.profile, obj).then((response) => {
        if (response.status === 200 && response.data) {
          isDataUpdated.current = false;
        }
      });
    }
  };

  useEffect(() => {
    if (profile?.locale && !localeByValue?.[profile?.locale]?.[0]) {
      handleChangeDropDown(null, "locale", {
        value: profile?.locale.includes("en_") ? "en_GB" : "en_DE",
      });
    }
  }, [profile?.locale]);

  //function
  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const onClickPhoto = () => {
    avatar.current.click();
  };

  const onUploadPhoto = async (e) => {
    isDataUpdated.current = true;
    const { files } = e.target;
    let obj = {
      ...profile,
      photo: files?.[0] ? URL.createObjectURL(files?.[0]) : null,
    };
    let data = new FormData(); // creates a new FormData object
    data.append("photo", files[0]); // add your file to form data
    updateProfile(data);
    dispatch(setProfile(obj));
  };

  const onClickRemovePhoto = async (e) => {
    isDataUpdated.current = true;
    const obj = {
      photo: null,
    };
    updateProfile(obj);
    dispatch(setProfile({ ...profile, ...obj }));
  };

  const handleChange = (event) => {
    isDataUpdated.current = true;
    let { name, value } = event.target;
    dispatch(setProfile({ ...profile, [name]: value }));
  };

  const onBlurChange = (e) => {
    let { name, value } = e.target;
    updateProfile({ [name]: value });
  };

  const handleChangeDropDown = (e, NAME, VALUE) => {
    isDataUpdated.current = true;
    let value = VALUE.value;
    let obj = {};
    if (NAME === "locale") {
      obj = {
        locale: value,
      };
      updateLanguage(value);
    }
    dispatch(setProfile({ ...profile, ...obj }));
    updateProfile(obj);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItem: "center",
        width: "100%",
        height: "100%",
        backgroundColor: Color.appThemeBg,
        position: "relative",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: Color.appThemeBg,
          ml: "92px",
        }}
      >
        <Typography
          variant="h5"
          color="color.slate.700"
          fontWeight={"fontWeightMediumBold"}
          sx={{
            mt: "4rem",
          }}
        >
          {t("Profile")}
        </Typography>
        <Typography
          variant="subtitle2"
          color="color.description"
          fontWeight={"fontWeightMedium"}
          sx={{ mt: "0.5rem" }}
        >
          {t("ManageProfile")}
        </Typography>
        <Divider sx={{ my: "1.5rem" }} />

        <input
          ref={avatar}
          type="file"
          // accept="image/*"
          accept=".png, .jpg, .jpeg"
          name="upload-media"
          style={{ display: "none" }}
          id="input"
          onChange={onUploadPhoto}
        />
        <Stack direction={"row"} alignItems={"center"}>
          <Avatar
            onClick={onClickPhoto}
            sx={{
              width: "3.8rem",
              height: "3.8rem",
              color: theme.palette.primary.dark,
              backgroundColor: theme.palette.primary[100],
              border: `1px solid ${theme.palette.primary.dark}`,
              cursor: "pointer",
            }}
            src={profile?.photo}
          />

          {!!profile?.photo ? (
            <Typography
              onClick={onClickRemovePhoto}
              variant="caption"
              color="color.description"
              fontWeight={"fontWeightMedium"}
              sx={{
                ml: "1rem",
                mt: "0.5rem",
                cursor: "pointer",
                "&:hover": { textDecoration: "underline" },
              }}
            >
              {t("Remove")}
            </Typography>
          ) : null}
        </Stack>

        <Divider sx={{ width: "31.25rem", my: "1.5rem" }} />

        <TitleInput
          title={t("FirstName")}
          fontSize={"1rem"}
          name="first_name"
          value={profile?.first_name ?? ""}
          onChange={handleChange}
          onBlur={onBlurChange}
          sx={{ width: "29rem" }}
        />

        <TitleInput
          title={t("LastName")}
          fontSize={"1rem"}
          name="last_name"
          value={profile?.last_name ?? ""}
          onChange={handleChange}
          onBlur={onBlurChange}
          sx={{ width: "29rem" }}
        />

        <TitleInput
          title={t("Email")}
          fontSize={"1rem"}
          name="email"
          disabled={true}
          value={profile?.email ?? ""}
          onChange={handleChange}
          sx={{ width: "29rem" }}
        />

        <Divider sx={{ my: "1.5rem" }} />

        <DropDown
          mb={0}
          fontSize="1rem"
          disableClearable={true}
          title={t("Language")}
          value={localeByValue?.[profile?.locale]?.[0] || null}
          onChange={(e, value) =>
            handleChangeDropDown(e, "locale", {
              ...value,
              value: value?.locale,
            })
          }
          options={euro_countries}
          getOptionLabel={(option) => option.language}
          renderOption={(option) => option.language}
          sx={{ width: "29rem" }}
        />

        <Divider sx={{ my: "1.5rem" }} />

        <Typography
          component="div"
          sx={{
            display: "inline-flex",
            fontSize: "0.9rem",
            fontWeight: 400,
            fontFamily: Fonts.Text,
            color: "color.description",
            mt: "1rem",
          }}
        >
          {t("LookingDelete")}
          <Typography
            component={"span"}
            sx={{
              fontSize: "0.9rem",
              fontWeight: 400,
              ml: "0.25rem",
              fontFamily: Fonts.Text,
              color: "color.description",
              cursor: "pointer",
              textDecorationLine: "underline",
              "&:hover": {
                color: Color.tailwind.violet[600],
              },
            }}
          >
            {t("ClickHere")}
          </Typography>
        </Typography>
        <Outlet />
      </Box>
    </Box>
  );
};

export default Profile;
