import { createSlice } from "@reduxjs/toolkit";
import _ from "underscore";

import { resetAllState } from "./reset";

const initialState = {
  monthlyTransactionsByCategory: [],
  groupedMonthlyTransactionsByCategory: [],
  monthlyTransactions: [],
  groupedMonthlyTransactions: [],
  dailyTransactions: [],
  groupedDailyTransactions: [],
  transactionsOverlayStatus: {},
  transactionsOverlayUpdateData: null,
  popupStatus: { open: false, anchorEl: null },
  popupStatus2: { open: false, anchorEl: null },
  popupStatus3: { open: false, anchorEl: null },
  popupStatus4: { open: false, anchorEl: null },
  popupStatus5: { open: false, anchorEl: null },
};

const datasetSlice = createSlice({
  name: "datasetSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(resetAllState, () => initialState);
  },
  reducers: {
    setMonthlyTransactionsByCategory(state, action) {
      state.monthlyTransactionsByCategory = action.payload;
      state.groupedMonthlyTransactionsByCategory = _.groupBy(
        action.payload,
        ({ month }) => month
      );
    },

    setTransactionsOverlayStatus(state, action) {
      state.transactionsOverlayStatus = action.payload;
    },
    setTransactionsOverlayUpdateData(state, action) {
      state.transactionsOverlayUpdateData = action.payload;
    },
    setPopupStatus(state, action) {
      state.popupStatus = action.payload;
    },
    setPopupStatus2(state, action) {
      state.popupStatus2 = action.payload;
    },
    setPopupStatus3(state, action) {
      if (action.payload?.key) {
        state.popupStatus3[action.payload?.key] = action.payload?.value;
      } else {
        state.popupStatus3 = action.payload;
      }
    },
    setPopupStatus5(state, action) {
      if (action.payload?.key) {
        state.popupStatus5[action.payload?.key] = action.payload?.value;
      } else {
        state.popupStatus5 = action.payload;
      }
    },
    setPopupStatus4(state, action) {
      state.popupStatus4 = action.payload;
    },

    setMonthlyTransactions(state, action) {
      state.monthlyTransactions = action.payload;
      state.groupedMonthlyTransactions = _.groupBy(
        action.payload,
        ({ month }) => month
      );
    },
    setDailyTransactions(state, action) {
      state.dailyTransactions = action.payload;
      state.groupedDailyTransactions = _.groupBy(
        [...action.payload],
        ({ day }) => day
      );
    },
  },
});
export const {
  setTransactionsOverlayStatus,
  setMonthlyTransactions,
  setDailyTransactions,
  setMonthlyTransactionsByCategory,
  setPopupStatus,
  setPopupStatus2,
  setPopupStatus3,
  setPopupStatus4,
  setPopupStatus5,
  setTransactionsOverlayUpdateData,
} = datasetSlice.actions;
export default datasetSlice;
