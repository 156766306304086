import { useDispatch, useSelector } from "react-redux";
import { useRef } from "react";

import { setPopupStatus } from "../../../../store/slices/datasets";
import CustomModal from "../../../../components/Model/CustomModal";
import ReconcileListView from "../ReconcileListView";

const ReconcileListViewModal = () => {
  const dispatch = useDispatch();
  let isDataUpdated = useRef(false);

  //redux
  const popupStatus = useSelector((state) => state.datasetSlice?.popupStatus);

  //functions
  const onCloseReconcile = () => {
    dispatch(setPopupStatus(null));
    if (isDataUpdated.current) {
      // try to update only list if use refreshData it will update whole planning view
      // popupStatus?.payload?.updateOverlay({ from_reconcile_overlay: true });
    }
  };

  return (
    <CustomModal
      hideAction={true}
      width="100%"
      open={!!popupStatus?.open}
      onClose={onCloseReconcile}
      PaperPropsSx={{
        maxWidth: "none",
        width: "88vw",
        height: "70vh",
      }}
    >
      <ReconcileListView
        isDataUpdated={isDataUpdated}
        startDate={popupStatus?.payload?.startDate}
        endDate={popupStatus?.payload?.endDate}
        type={popupStatus?.payload?.type}
      />
    </CustomModal>
  );
};

export default ReconcileListViewModal;
