import { PaletteColor, createTheme } from "@mui/material/styles";
import { Color } from "@mui/material";

interface MyColor {
  50?: string;
  100?: string;
  200?: string;
  300?: string;
  400?: string;
  500?: string;
  600?: string;
  700?: string;
  800?: string;
  900?: string;
  950?: string;
  darkHover?: string;
}

type MyPaletteColorOptions = MyColor | Color | PaletteColor;

declare module "@mui/material/styles/createTypography" {
  interface TypographyOptions {
    fontWeightMediumBold?: React.CSSProperties["fontWeight"];
  }

  interface Typography {
    fontWeightMediumBold?: React.CSSProperties["fontWeight"];
  }
}

declare module "@mui/material/styles/createPalette" {
  interface PaletteColor extends MyColor {}
  interface SimplePaletteColorOptions extends MyColor {}
  interface PaletteOptions {
    color: {
      black: string;
      white: string;
      appThemeBg: string;
      FooterBG: string;
      mainTitle: string;
      inputTitle: string;
      description: string;
      description2: string;
      overlayBorderShade: string;
      primary: MyPaletteColorOptions;
      blueGrey: MyPaletteColorOptions;
      brown: MyPaletteColorOptions;
      deepOrange: MyPaletteColorOptions;
      deepPurple: MyPaletteColorOptions;
      lightBlue: MyPaletteColorOptions;
      slate: MyPaletteColorOptions;
      orange: MyPaletteColorOptions;
      zinc: MyPaletteColorOptions;
      neutral: MyPaletteColorOptions;
      stone: MyPaletteColorOptions;
      grey: MyPaletteColorOptions;
      red: MyPaletteColorOptions;
      yellow: MyPaletteColorOptions;
      green: MyPaletteColorOptions;
      lightGreen: MyPaletteColorOptions;
      blue: MyPaletteColorOptions;
      indigo: MyPaletteColorOptions;
      amber: MyPaletteColorOptions;
      lime: MyPaletteColorOptions;
      emerald: MyPaletteColorOptions;
      teal: MyPaletteColorOptions;
      cyan: MyPaletteColorOptions;
      sky: MyPaletteColorOptions;
      violet: MyPaletteColorOptions;
      purple: MyPaletteColorOptions;
      fuchsia: MyPaletteColorOptions;
      pink: MyPaletteColorOptions;
      rose: MyPaletteColorOptions;
    };
  }

  interface Palette {
    color: PaletteOptions["color"];
  }
}

declare module "@mui/material/styles" {
  interface Theme {
    borderRadius: {
      borderRadiusSM?: string;
      borderRadiusMD?: string;
      main?: string;
      borderRadiusLG?: string;
      borderRadiusXL?: string;
      borderRadiusXL2?: string;
      borderRadiusXXL?: string;
      full?: string;
    };
    boxShadow: string;
    thinScrollBar: {
      scrollbarWidth: string;
      "&::-webkit-scrollbar": {
        width: string;
      };
    };
  }
  interface ThemeOptions {
    shape: {
      borderRadiusSM?: number;
      borderRadiusMD?: number;
      borderRadius?: number;
      borderRadiusLG?: number;
      borderRadiusXL?: number;
    };
    boxShadow: string;
    borderRadius: {
      borderRadiusSM?: string;
      borderRadiusMD?: string;
      main?: string;
      borderRadiusLG?: string;
      borderRadiusXL?: string;
      borderRadiusXL2?: string;
      borderRadiusXXL?: string;
      full?: string;
    };
    thinScrollBar: {
      scrollbarWidth: string;
      "&::-webkit-scrollbar": {
        width: string;
      };
    };
  }
}

const BREAKPOINTS = {
  // xs: 0,
  // sm: 600,
  // md: 900,
  // lg: 1200,
  // xl: 1536,
  xs: 0, //mobile
  small: 600,
  sm: 768, //tablet
  md: 960,
  lg: 1280,
  mid: 1536,
  isMobile: 425,
  isTablet: 768,
  s1080: 1080,
  s1280: 1280,
  s1366: 1366,
  s1440: 1440,
  s1536: 1536,
  s1650: 1650,
  s1745: 1745,
  s1920: 1920,
  s2133: 2133,
  s2400: 2400,
  s2560: 2560,
  s2880: 2880,
  s3840: 3840,
};

const defaultTheme = createTheme();
const fontFamily = `'Inter', sans-serif`;

const primary = {
  main: "#6101D1",
  light: "#F1E5FF",
  dark: "#6101D1",
  darkHover: "#5701BC",
  50: "#F5F3FF",
  100: "#EDE9FE",
  200: "#DDD6FE",
  300: "#C4B5FD",
  400: "#A78BFA",
  500: "#8B5CF6",
  600: "#7C3AED",
  700: "#6D28D9",
  800: "#5B21B6",
  900: "#4C1D95",
  950: "#2E1065",
};
const color = {
  black: "#000",
  white: "#fff",
  appThemeBg: "#f8fafc",
  FooterBG: "#f1f5f9",
  mainTitle: "#364152", //slate.600
  inputTitle: "#94a3b8", //slate.400
  description: "#64748b",
  description2: "rgba(0, 0, 0, 0.5)",
  overlayBorderShade: "#ffffff4f",
  primary,
  slate: {
    "50": "#f8fafc",
    "100": "#f1f5f9",
    "200": "#e2e8f0",
    "300": "#cbd5e1",
    "400": "#94a3b8",
    "500": "#64748b",
    "600": "#475569",
    "700": "#334155",
    "800": "#1e293b",
    "900": "#0f172a",
    "950": "#020617",
  },
  gray: {
    "50": "#f9fafb",
    "100": "#f3f4f6",
    "200": "#e5e7eb",
    "300": "#d1d5db",
    "400": "#9ca3af",
    "500": "#6b7280",
    "600": "#4b5563",
    "700": "#374151",
    "800": "#1f2937",
    "900": "#111827",
    "950": "#030712",
  },
  zinc: {
    "50": "#fafafa",
    "100": "#f4f4f5",
    "200": "#e4e4e7",
    "300": "#d4d4d8",
    "400": "#a1a1aa",
    "500": "#71717a",
    "600": "#52525b",
    "700": "#3f3f46",
    "800": "#27272a",
    "900": "#18181b",
    "950": "#09090b",
  },
  neutral: {
    "50": "#fafafa",
    "100": "#f5f5f5",
    "200": "#e5e5e5",
    "300": "#d4d4d4",
    "400": "#a3a3a3",
    "500": "#737373",
    "600": "#525252",
    "700": "#404040",
    "800": "#262626",
    "900": "#171717",
    "950": "#0a0a0a",
  },
  stone: {
    "50": "#fafaf9",
    "100": "#f5f5f4",
    "200": "#e7e5e4",
    "300": "#d6d3d1",
    "400": "#a8a29e",
    "500": "#78716c",
    "600": "#57534e",
    "700": "#44403c",
    "800": "#292524",
    "900": "#1c1917",
    "950": "#0c0a09",
  },
  red: {
    "50": "#fef2f2",
    "100": "#fee2e2",
    "200": "#fecaca",
    "300": "#fca5a5",
    "400": "#f87171",
    "500": "#ef4444",
    "600": "#dc2626",
    "700": "#b91c1c",
    "800": "#991b1b",
    "900": "#7f1d1d",
    "950": "#450a0a",
  },
  orange: {
    "50": "#fff7ed",
    "100": "#ffedd5",
    "200": "#fed7aa",
    "300": "#fdba74",
    "400": "#fb923c",
    "500": "#f97316",
    "600": "#ea580c",
    "700": "#c2410c",
    "800": "#9a3412",
    "900": "#7c2d12",
    "950": "#431407",
  },
  amber: {
    "50": "#fffbeb",
    "100": "#fef3c7",
    "200": "#fde68a",
    "300": "#fcd34d",
    "400": "#fbbf24",
    "500": "#f59e0b",
    "600": "#d97706",
    "700": "#b45309",
    "800": "#92400e",
    "900": "#78350f",
    "950": "#451a03",
  },
  yellow: {
    "50": "#fefce8",
    "100": "#fef9c3",
    "200": "#fef08a",
    "300": "#fde047",
    "400": "#facc15",
    "500": "#eab308",
    "600": "#ca8a04",
    "700": "#a16207",
    "800": "#854d0e",
    "900": "#713f12",
    "950": "#422006",
  },
  lime: {
    "50": "#f7fee7",
    "100": "#ecfccb",
    "200": "#d9f99d",
    "300": "#bef264",
    "400": "#a3e635",
    "500": "#84cc16",
    "600": "#65a30d",
    "700": "#4d7c0f",
    "800": "#3f6212",
    "900": "#365314",
    "950": "#1a2e05",
  },
  green: {
    "50": "#f0fdf4",
    "100": "#dcfce7",
    "200": "#bbf7d0",
    "300": "#86efac",
    "400": "#4ade80",
    "500": "#22c55e",
    "600": "#16a34a",
    "700": "#15803d",
    "800": "#166534",
    "900": "#14532d",
    "950": "#052e16",
  },
  emerald: {
    "50": "#ecfdf5",
    "100": "#d1fae5",
    "200": "#a7f3d0",
    "300": "#6ee7b7",
    "400": "#34d399",
    "500": "#10b981",
    "600": "#059669",
    "700": "#047857",
    "800": "#065f46",
    "900": "#064e3b",
    "950": "#022c22",
  },
  teal: {
    "50": "#f0fdfa",
    "100": "#ccfbf1",
    "200": "#99f6e4",
    "300": "#5eead4",
    "400": "#2dd4bf",
    "500": "#14b8a6",
    "600": "#0d9488",
    "700": "#0f766e",
    "800": "#115e59",
    "900": "#134e4a",
    "950": "#042f2e",
  },
  cyan: {
    "50": "#ecfeff",
    "100": "#cffafe",
    "200": "#a5f3fc",
    "300": "#67e8f9",
    "400": "#22d3ee",
    "500": "#06b6d4",
    "600": "#0891b2",
    "700": "#0e7490",
    "800": "#155e75",
    "900": "#164e63",
    "950": "#083344",
  },
  sky: {
    "50": "#f0f9ff",
    "100": "#e0f2fe",
    "200": "#bae6fd",
    "300": "#7dd3fc",
    "400": "#38bdf8",
    "500": "#0ea5e9",
    "600": "#0284c7",
    "700": "#0369a1",
    "800": "#075985",
    "900": "#0c4a6e",
    "950": "#082f49",
  },
  blue: {
    "50": "#eff6ff",
    "100": "#dbeafe",
    "200": "#bfdbfe",
    "300": "#93c5fd",
    "400": "#60a5fa",
    "500": "#3b82f6",
    "600": "#2563eb",
    "700": "#1d4ed8",
    "800": "#1e40af",
    "900": "#1e3a8a",
    "950": "#172554",
  },
  indigo: {
    "50": "#eef2ff",
    "100": "#e0e7ff",
    "200": "#c7d2fe",
    "300": "#a5b4fc",
    "400": "#818cf8",
    "500": "#6366f1",
    "600": "#4f46e5",
    "700": "#4338ca",
    "800": "#3730a3",
    "900": "#312e81",
    "950": "#1e1b4b",
  },
  violet: {
    "50": "#f5f3ff",
    "100": "#ede9fe",
    "200": "#ddd6fe",
    "300": "#c4b5fd",
    "400": "#a78bfa",
    "500": "#8b5cf6",
    "600": "#7c3aed",
    "700": "#6d28d9",
    "800": "#5b21b6",
    "900": "#4c1d95",
    "950": "#2e1065",
  },
  purple: {
    "50": "#faf5ff",
    "100": "#f3e8ff",
    "200": "#e9d5ff",
    "300": "#d8b4fe",
    "400": "#c084fc",
    "500": "#a855f7",
    "600": "#9333ea",
    "700": "#7e22ce",
    "800": "#6b21a8",
    "900": "#581c87",
    "950": "#3b0764",
  },
  fuchsia: {
    "50": "#fdf4ff",
    "100": "#fae8ff",
    "200": "#f5d0fe",
    "300": "#f0abfc",
    "400": "#e879f9",
    "500": "#d946ef",
    "600": "#c026d3",
    "700": "#a21caf",
    "800": "#86198f",
    "900": "#701a75",
    "950": "#4a044e",
  },
  pink: {
    "50": "#fdf2f8",
    "100": "#fce7f3",
    "200": "#fbcfe8",
    "300": "#f9a8d4",
    "400": "#f472b6",
    "500": "#ec4899",
    "600": "#db2777",
    "700": "#be185d",
    "800": "#9d174d",
    "900": "#831843",
    "950": "#500724",
  },
  rose: {
    "50": "#fff1f2",
    "100": "#ffe4e6",
    "200": "#fecdd3",
    "300": "#fda4af",
    "400": "#fb7185",
    "500": "#f43f5e",
    "600": "#e11d48",
    "700": "#be123c",
    "800": "#9f1239",
    "900": "#881337",
    "950": "#4c0519",
  },
  //mui
  blueGrey: {
    50: "#eceff1",
    100: "#cfd8dc",
    200: "#b0bec5",
    300: "#90a4ae",
    400: "#78909c",
    500: "#607d8b",
    600: "#546e7a",
    700: "#455a64",
    800: "#37474f",
    900: "#263238",
    A100: "#cfd8dc",
    A200: "#b0bec5",
    A400: "#78909c",
    A700: "#455a64",
  },
  brown: {
    50: "#efebe9",
    100: "#d7ccc8",
    200: "#bcaaa4",
    300: "#a1887f",
    400: "#8d6e63",
    500: "#795548",
    600: "#6d4c41",
    700: "#5d4037",
    800: "#4e342e",
    900: "#3e2723",
    A100: "#d7ccc8",
    A200: "#bcaaa4",
    A400: "#8d6e63",
    A700: "#5d4037",
  },
  deepOrange: {
    50: "#fbe9e7",
    100: "#ffccbc",
    200: "#ffab91",
    300: "#ff8a65",
    400: "#ff7043",
    500: "#ff5722",
    600: "#f4511e",
    700: "#e64a19",
    800: "#d84315",
    900: "#bf360c",
    A100: "#ff9e80",
    A200: "#ff6e40",
    A400: "#ff3d00",
    A700: "#dd2c00",
  },
  deepPurple: {
    50: "#ede7f6",
    100: "#d1c4e9",
    200: "#b39ddb",
    300: "#9575cd",
    400: "#7e57c2",
    500: "#673ab7",
    600: "#5e35b1",
    700: "#512da8",
    800: "#4527a0",
    900: "#311b92",
    A100: "#b388ff",
    A200: "#7c4dff",
    A400: "#651fff",
    A700: "#6200ea",
  },
  lightBlue: {
    50: "#e1f5fe",
    100: "#b3e5fc",
    200: "#81d4fa",
    300: "#4fc3f7",
    400: "#29b6f6",
    500: "#03a9f4",
    600: "#039be5",
    700: "#0288d1",
    800: "#0277bd",
    900: "#01579b",
    A100: "#80d8ff",
    A200: "#40c4ff",
    A400: "#00b0ff",
    A700: "#0091ea",
  },
  lightGreen: {
    50: "#f1f8e9",
    100: "#dcedc8",
    200: "#c5e1a5",
    300: "#aed581",
    400: "#9ccc65",
    500: "#8bc34a",
    600: "#7cb342",
    700: "#689f38",
    800: "#558b2f",
    900: "#33691e",
    950: "#000000",
    A100: "#ccff90",
    A200: "#b2ff59",
    A400: "#76ff03",
    A700: "#64dd17",
  },
  grey: {
    50: "#f9fafb",
    100: "#f4f5f7",
    200: "#e5e7eb",
    300: "#d2d6dc",
    400: "#9fa6b2",
    500: "#6b7280",
    600: "#4b5563",
    700: "#374151",
    800: "#252f3f",
    900: "#161e2e",
    950: "#000000",
  },
};
const boxShadow =
  "rgba(0, 0, 0, 0.1) 0px 0px 6px -1px, rgba(0, 0, 0, 0.05) 0px 0px 0px 0px";
const borderRadius = {
  borderRadiusSM: "2px",
  borderRadiusMD: "4px",
  main: `6px`,
  borderRadiusLG: "8px",
  borderRadiusXL: "10px",
  borderRadiusXL2: "13px",
  borderRadiusXXL: "16px",
  full: "100%",
};
const theme = createTheme({
  spacing: (factor: number) => `${0.25 * factor}rem`, // (Bootstrap strategy)
  shape: {
    borderRadiusSM: 0.5,
    borderRadiusMD: 1,
    borderRadius: 1.5,
    borderRadiusLG: 2,
    borderRadiusXL: 2.5,
  },
  borderRadius,
  thinScrollBar: {
    scrollbarWidth: "thin",
    "&::-webkit-scrollbar": {
      width: "0.3rem",
    },
  },
  breakpoints: {
    values: {
      ...defaultTheme.breakpoints.values,
      ...BREAKPOINTS,
    },
  },
  palette: {
    primary,
    color,
    tonalOffset: {
      light: 0.2,
      dark: 0.2,
    },
  },
  typography: {
    fontFamily,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightMediumBold: 600,
    fontWeightBold: 700,
    allVariants: {
      fontFamily,
      textTransform: "initial",
    },
  },
  boxShadow,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily,
          borderRadius: borderRadius.main,
          textTransform: "initial",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: borderRadius.main,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: color.white,
          color: color.slate[700],
          fontSize: "0.75rem",
          borderRadius: borderRadius.main,
          fontFamily,
          boxShadow,
          border: `1px solid ${color.slate[300]}`,
          maxWidth: "400px",
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        root: {
          zIndex: 1303, // Custom zIndex for Popovers
          boxShadow,
          borderRadius: borderRadius.borderRadiusXL2,
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          zIndex: 1303, // Custom zIndex for Modals
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          zIndex: 1303, // Custom zIndex for Dialogs
        },
      },
    },
  },
});

export default theme;
