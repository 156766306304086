import { useTranslation } from "react-i18next";
import { Box, Divider } from "@mui/material";
import { useDispatch } from "react-redux";

import AddPresetCard from "../../TableEditModal/AddPresetCard";
import { setRefreshData } from "../../../store/slices/appmain";
import { getTailwindColor } from "../../../Helper/data";
import { queryClient } from "../../../App";
import { Fonts } from "../../../Helper";

const HeaderAddOverlay = ({ date = new Date(), popup_state }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const resetKanban = async () => {
    global.allowFetch = { Inflow: [], Outflow: [] };
    let options = {
      predicate: (query) => query.queryKey[0] === "transactions",
    };
    queryClient.resetQueries(options);
    dispatch(setRefreshData(Date.now()));
  };
  return (
    <Box
      sx={{
        position: "relative",
        p: "1.5rem",
        width: "60rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: "color.slate.50",
      }}
    >
      <Box
        sx={{
          display: "inline-flex",
          flexDirection: "column",
          width: "48%",
        }}
      >
        <AddPresetCard
          width={"100%"}
          income_expense_type={1}
          preset_type="AddTransaction"
          popup_state={popup_state}
          updateOverlay={resetKanban}
          highlightIcon
          height="9rem"
        />
        <Divider
          textAlign="left"
          sx={{
            width: "100%",
            mt: "1.5rem",
            mb: "0.5rem",
            fontFamily: Fonts.Text,
            fontWeight: 500,
            fontSize: "0.9rem",
            color: getTailwindColor("slate", 300),
          }}
        >
          {t("Preset")}
        </Divider>
        <AddPresetCard
          width={"100%"}
          income_expense_type={1}
          preset_type="RecurringPlanning"
          popup_state={popup_state}
          updateOverlay={resetKanban}
          highlightIcon
          height="9rem"
        />
        <AddPresetCard
          width={"100%"}
          income_expense_type={1}
          preset_type="CategoryRecurringPlanning"
          popup_state={popup_state}
          updateOverlay={resetKanban}
          highlightIcon
          height="9rem"
        />
        <AddPresetCard
          width={"100%"}
          income_expense_type={1}
          preset_type="RevenueRecurringPlanning"
          popup_state={popup_state}
          updateOverlay={resetKanban}
          highlightIcon
          height="9rem"
        />
        <AddPresetCard
          width={"100%"}
          income_expense_type={1}
          preset_type="SubscriptionPlanning"
          popup_state={popup_state}
          updateOverlay={resetKanban}
          highlightIcon
          height="9rem"
        />
      </Box>
      <Box
        sx={{
          display: "inline-flex",
          flexDirection: "column",
          width: "48%",
        }}
      >
        <AddPresetCard
          width={"100%"}
          income_expense_type={2}
          preset_type="AddTransaction"
          popup_state={popup_state}
          updateOverlay={resetKanban}
          highlightIcon
          height="9rem"
        />{" "}
        <Divider
          textAlign="left"
          sx={{
            width: "100%",
            mt: "1.5rem",
            mb: "0.5rem",
            fontFamily: Fonts.Text,
            fontWeight: 500,
            fontSize: "0.9rem",
            color: getTailwindColor("slate", 300),
          }}
        >
          {t("Preset")}
        </Divider>
        <AddPresetCard
          width={"100%"}
          income_expense_type={2}
          preset_type="RecurringPlanning"
          popup_state={popup_state}
          updateOverlay={resetKanban}
          highlightIcon
          height="9rem"
        />
        <AddPresetCard
          width={"100%"}
          income_expense_type={2}
          preset_type="EmployeePlanning"
          popup_state={popup_state}
          updateOverlay={resetKanban}
          highlightIcon
          hideEditFormTabBar
          height="9rem"
        />
        <AddPresetCard
          width={"100%"}
          income_expense_type={2}
          preset_type="CategoryRecurringPlanning"
          popup_state={popup_state}
          updateOverlay={resetKanban}
          highlightIcon
          height="9rem"
        />
        <AddPresetCard
          width={"100%"}
          income_expense_type={2}
          preset_type="RevenueRecurringPlanning"
          popup_state={popup_state}
          updateOverlay={resetKanban}
          highlightIcon
          height="9rem"
        />
      </Box>
    </Box>
  );
};

export default HeaderAddOverlay;
