import { useTranslation } from "react-i18next";
import Snackbar from "@mui/material/Snackbar";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import * as Sentry from "@sentry/react";
import { Alert } from "@mui/material";
import { useEffect } from "react";
import * as React from "react";

import {
  setIsFatching,
  setIsLoading,
  setLoading,
} from "../../store/slices/appmain";
import { setErrorMessage, setIsOpenSnackbar } from "../../store/slices/common";
import { Emitter } from "../../Helper/data";
import { Constant } from "../../Helper";

export default function MuiSnackbar() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const isOpenSnackbar = useSelector(
    (state) => state.commonSlice.isOpenSnackbar
  );
  const errorMessage = useSelector((state) => state.commonSlice.errorMessage);

  useEffect(() => {
    const listener = (error) => {
      const user = localStorage.getItem("LoggedInUser");

      Sentry.setExtra("Manually Handled Error  ==> ", {
        message: error?.message,
        response: error?.response,
        status: error?.status || error?.response?.status,
        user,
      });
      Sentry.captureException(error);

      if (Constant.isDev) {
        console.log("Manually Handled Error  ==> ", error?.response);
      }
        dispatch(setLoading(false));
        dispatch(setIsLoading(false));
        dispatch(setIsFatching(false));

      if (error && error?.response) {
        // resolve(error.response);
        if (error.response?.status === 401) {
          dispatch(setErrorMessage("Token expired"));
        }
        if (error.response?.status === 400) {
          if (error?.response?.data?.category?.[0]) {
            enqueueSnackbar(t(error?.response?.data?.category?.[0]), {
              variant: "error",
              autoHideDuration: 5000,
            });
          }
          if (error?.response?.data?.visible?.[0]) {
            enqueueSnackbar(t(error?.response?.data?.visible?.[0]), {
              variant: "error",
              autoHideDuration: 5000,
            });
          }
          dispatch(setErrorMessage("Bad Request"));
        }
        if (error.response?.status === 404) {
          dispatch(setErrorMessage("Oops.. data not found"));
          dispatch(setIsOpenSnackbar(true));
        }
        if (error.response?.status === 403) {
          dispatch(setErrorMessage(error.response?.data?.detail));
          dispatch(setIsOpenSnackbar(true));
        }
        if (error.response?.status === 500) {
          dispatch(setErrorMessage("Internal server error"));
          dispatch(setIsOpenSnackbar(true));
        }
        if (error.response?.status === 504) {
          dispatch(setErrorMessage(error.message));
          dispatch(setIsOpenSnackbar(true));
        }
      } else {
        // reject(error);
        if (error.message === "Network Error") {
          dispatch(setErrorMessage("Network Error"));
          dispatch(setIsOpenSnackbar(true));
        }

        // setTimeout(() => {
        //   let newInitStandardProviders = makeFunctionCalledOnce(() => {
        //     window.location.reload();
        //   })();
        // }, 1500);
      }

      setTimeout(
        () => {
          dispatch(setIsOpenSnackbar(false));
        },
        error.response?.status === 403 ? 5000 : 5000
      );
    };
    Emitter.on("errorStatus", listener);
    return () => {
      Emitter.off("errorStatus", listener);
    };
  }, []);

  const handleClose = () => {
    dispatch(setIsOpenSnackbar(false));
  };

  return (
    <Snackbar open={isOpenSnackbar} onClose={handleClose}>
      <Alert severity="error" onClose={handleClose}>
        {errorMessage}
      </Alert>
    </Snackbar>
  );
}
