import {
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
  useRef,
  memo,
} from "react";
import { Outlet, useLocation, useNavigate } from "react-router";
import { useMediaQuery, Box, Stack } from "@mui/material";
import { BsFillTagsFill } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";

import { getContactQueryKey } from "../../../../Helper/queryKeys";
import { GlobalContext } from "../../../../GlobalContextWrapper";
import ClientTransactionsList from "./ClientTransactionsList";
import ThemeTabs from "../../../../components/ThemeTabs";
import AddButton from "../../../../components/AddButton";
import { isValidTitle } from "../../../../Helper/data";
import { queryClient } from "../../../../App";
import ClientHeader from "./ClientHeader";
import ClientMerge from "./ClientMerge";
import ClientList from "./ClientList";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      backgroundColor: theme?.palette?.color?.appThemeBg,
      height: "100%",
      paddingTop: "1rem",
      width: "100%",
      overflow: "hidden",
    },
  };
});

const Clients = ({ type = 1 }) => {
  const classes = useStyles();
  const viewRef = useRef();
  const timerRef = useRef();
  const s2133 = useMediaQuery("(min-width:2133px)");
  const s2400 = useMediaQuery("(min-width:2400px)");

  let HEIGHT = 2.5;
  let spacing = 110;
  const _width = document.body.clientWidth;
  const width = (_width * 85) / 100;
  if (s2133) {
    HEIGHT = 3;
  }
  if (s2400) {
    HEIGHT = 5;
  }
  // //redux
  // const datasetAccountList = useSelector(
  //   (state) => state.boardSlice.datasetAccountList
  // );
  // const transactionSystemById = useSelector(
  //   (state) => state.globalSlice?.transactionSystemById
  // );

  //states
  const [tab, setTab] = useState(false);
  const [refreshUI, setRefreshUI] = useState(false);

  const onResize = useCallback(() => {
    if (viewRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => {
        setRefreshUI((prev) => !prev);
      }, 100);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, [onResize]);

  return (
    <Box className={classes.root} ref={viewRef}>
      <ClientHeader width={width} tab={1} hideState={tab !== 2} />
      <TabView width={width} tab={tab} setTab={setTab} />
      {tab === 1 ? <ClientList width={width} HEIGHT={HEIGHT} /> : null}
      {tab === 2 ? (
        <ClientTransactionsList
          type={type}
          width={width}
          spacing={spacing}
          HEIGHT={HEIGHT}
        />
      ) : null}
      {tab === 4 ? (
        <ClientMerge
          type={type}
          width={width}
          spacing={spacing}
          HEIGHT={HEIGHT}
        />
      ) : null}
      <Outlet />
    </Box>
  );
};

export default memo(Clients);

const TabView = ({ width, tab, setTab }) => {
  const globalContext = useContext(GlobalContext);

  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  //data
  const clientFunctionRef = globalContext?.clientFunctionRef;
  const key = "customer";
  const url_keys = "customers";

  const tabOptions = useMemo(
    () => [
      {
        id: 1,
        url_path: `${url_keys}-list`,
        value: 1,
        iconPosition: "start",
        label: `${key}_tab_01`,
        icon: <BsFillTagsFill />,
      },
      // {
      //   id: 2,
      //   url_path: `${url_keys}-portfolio-analysis`,
      //   value: 2,
      //   iconPosition: "start",
      //   label: `${key}_tab_02`,
      //   icon: <BsFillTagsFill />,
      // },
      // {
      //   id: 3,
      //   url_path: `top-${url_keys}`,
      //   value: 3,
      //   iconPosition: "start",
      //   label: `${key}_tab_03`,
      //   icon: <BsFillTagsFill />,
      // },
      {
        id: 4,
        url_path: `merge-${url_keys}`,
        value: 4,
        iconPosition: "start",
        label: `${key}_tab_04`,
        icon: <BsFillTagsFill />,
      },
    ],
    [key, url_keys]
  );

  //redux
  const dataset = useSelector((state) => state.boardSlice?.dataSetData?.uuid);
  const contactTypes = useSelector(
    (state) => state.staffSlice?.["contactTypes"]
  );
  const searchText = useSelector(
    (state) => state.staffSlice?.["contactSearchText"]
  );

  const handleChangeTab = (event, newValue) => {
    if (tab !== newValue) {
      setTab(newValue);

      const item = tabOptions?.find((o1) => o1?.value === newValue);
      let array = location.pathname.split("/");
      array.pop();
      array.push(item?.url_path);
      let url = array.join("/");
      navigate(url);
    }
  };

  const onClickAddNewClient = ({ type = 1 }) => {
    const data = queryClient.getQueryData(
      getContactQueryKey({
        dataset,
        type: contactTypes,
        search: searchText,
      })
    );
    const alreadyAddedTitles = data?.results?.map((o1) => o1?.name);

    let count = data?.results?.length + 1;
    const _title_ = type === 1 ? "Customer" : "Supplier";
    let title = `${t(_title_)} ${count}`;
    title = isValidTitle({
      alreadyAddedTitles,
      title,
      count,
      node: { title: t(_title_) },
    });
    clientFunctionRef?.current?.addClientApi({
      name: title,
      state: 1,
      type,
      dataset,
    });
  };

  useEffect(() => {
    if (dataset) {
      const array = location.pathname.split("/");
      const url_path = array[array.length - 1];
      const filter = tabOptions?.find((o1) => o1?.url_path === url_path);
      if (filter) {
        setTab(filter?.value);
      }
    }
  }, [location.pathname, dataset, tabOptions, setTab]);

  return (
    <Stack
      direction="row"
      alignItems={"center"}
      justifyContent={"space-between"}
      sx={{
        width,
        mt: "1rem",
        maxWidth: "85%",
      }}
    >
      <ThemeTabs options={tabOptions} tab={tab} onChange={handleChangeTab} />
      {tab === 1 ? (
        <Stack direction="row" alignItems={"center"} sx={{ gap: "1rem" }}>
          <AddButton
            tooltipLabel={t(`add_new_customer`)}
            label={t(`add_new_customer`)}
            onClick={() => onClickAddNewClient({ type: 1 })}
            isNKey
          />
          <AddButton
            tooltipLabel={t(`add_new_supplier`)}
            label={t(`add_new_supplier`)}
            onClick={() => onClickAddNewClient({ type: 2 })}
            isNKey
          />
        </Stack>
      ) : null}
    </Stack>
  );
};
