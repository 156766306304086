import {
  ListItemButton,
  ListItemText,
  useTheme,
  List,
  Box,
} from "@mui/material";
import { TbArrowsRightLeft } from "react-icons/tb";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

import CustomPopoverScratch from "../../PopOver/CustomPopover";
import TailwindButton from "../../Overlay/TailwindButton";
import initialData from "../../../Helper/data";
import { queryClient } from "../../../App";
import { Color } from "../../../Helper";
import Icon from "../../Icon";

const DataSetDropDown = () => {
  const { t } = useTranslation();
  //redux
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);

  if (!dataSetData?.uuid)
    return (
      <span
        style={{
          width: "1rem",
        }}
      ></span>
    );

  return (
    <CustomPopoverScratch
      button={
        <div>
          <TailwindButton
            icon={
              <Icon
                icon={<TbArrowsRightLeft />}
                fontSize={"1rem"}
                color="inherit"
                style={{ marginRight: "0.5rem" }}
              ></Icon>
            }
            text={t("Change Organization")}
            tooltip={t("Change Organization Tooltip")}
            color={Color.tailwind.slate[700]}
            backgroundColor={Color.tailwind.slate[50]}
            sx={{
              border: `1px solid ${Color.tailwind.slate[700]}`,
              fontSize: "0.8rem",
              lineHeight: "0.8rem",
            }}
          />
        </div>
      }
    >
      <ListView />
    </CustomPopoverScratch>
  );
};
export default DataSetDropDown;

const ListView = ({ popup_state }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  //redux
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);
  const dataSetList = useSelector((state) => state.boardSlice?.dataSetList);

  //functions
  const onChangeDataSet = (e, value) => {
    popup_state?.close();
    let url = window?.location.pathname;
    const updatedUrl = url.replace(
      /\/organization\/([a-f0-9\-]+)\//,
      `/organization/${value?.uuid}/`
    );
    navigate(updatedUrl);

    const options = {
      predicate: (query) =>
        initialData.ResetAllDataOptions.includes(query.queryKey[0]) &&
        query?.queryKey?.[1]?.dataset === value?.uuid,
    };
    queryClient.invalidateQueries(options, { cancelRefetch: true });
  };

  return (
    <Box
      sx={{
        position: "relative",
        p: "0.5rem",
        minWidth: "24rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "max-content",
      }}
    >
      <List
        component="nav"
        aria-label="main mailbox folders"
        sx={{ width: "100%" }}
      >
        {dataSetList?.map((dataset) => {
          const isSelected = dataSetData?.uuid === dataset?.uuid;
          return (
            <ListItemButton
              disableRipple
              disableTouchRipple
              key={dataset?.uuid}
              selected={isSelected}
              onClick={(event) => onChangeDataSet(event, dataset)}
              color="primary"
              sx={{
                backgroundColor: isSelected
                  ? `${theme.palette.primary[100]} !important`
                  : "transparent",
                "&:hover": {
                  backgroundColor: theme.palette.primary[50],
                },
              }}
            >
              <ListItemText
                primary={dataset?.title}
                color="primary"
                sx={{
                  "& span": {
                    color: theme.palette.primary.main,
                    fontSize: "1rem",
                    fontWeight: isSelected ? 800 : 400,
                  },
                }}
              />
            </ListItemButton>
          );
        })}
      </List>
    </Box>
  );
};
