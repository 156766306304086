import useMediaQuery from "@mui/material/useMediaQuery";
import { FaEye, FaGlobeAmericas } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { differenceInMonths } from "date-fns";
import React, { useEffect } from "react";
import Box from "@mui/material/Box";

import {
  setToggleTableViewOption,
  toggleView,
} from "../../../../store/slices/board";
import ThemeToggle from "./../../../../components/ToggleGroupeView/ThemeToggle";
import CostUnitDropdown from "../../../../components/Overlay/CostUnitDropdown";
import ScenarioDropdown from "../../../../components/Overlay/ScenarioDropdown";
import FinbanInfoBulb from "../../../../components/FInbanInfo/FinbanInfoBulb";
import { setChartExapanded } from "../../../../store/slices/chart";
import { updateFilters } from "../../../../store/slices/global";
// import ChartOptionsPopOver from "./ChartOptionsPopOver";
// import CardOptionsPopOver from "./CardOptionsPopOver";
import { Color, Constant } from "../../../../Helper";
import { remToPx } from "../../../../Helper/data";
import ExportDialogView from "./ExportDialogView";
import useWidth from "../../../../hooks/useWidth";
import MonthSkipView from "./MonthSkipView";

const TableViewFilterOptions = [
  {
    value: "false",
    label: "table_view_option_monthly",
    tooltip: "table_view_option_monthly_tooltip",
  },
  {
    value: "true",
    label: "table_view_option_weekly",
    tooltip: "table_view_option_weekly_tooltip",
  },
];
const BoardHeader = () => {
  const currentWidth = useWidth();
  // const childRef = useRef(null);
  // const chartOptionRef = useRef(null);
  // const cardOptionRef = useRef(null);
  let width = remToPx(currentWidth, 58.5);

  //redux
  const isKanbanView = useSelector((state) => state.boardSlice.isKanbanView);
  const beta_enabled = useSelector(
    (state) => state.settingsSlice?.profile?.beta_enabled
  );

  //state
  const xlg = useMediaQuery("(min-width:1080px)");
  const s1280 = useMediaQuery("(min-width:1280px)");
  const mid = useMediaQuery("(min-width:1536px)");
  const s1920 = useMediaQuery("(min-width:1920px)");

  if (xlg) {
    width = remToPx(currentWidth, 65);
  }
  if (s1280) {
    width = remToPx(currentWidth, 118);
  }
  if (mid) {
    width = remToPx(currentWidth, 125);
  }
  if (s1920) {
    width = remToPx(currentWidth, 130);
  }

  //lifecycle
  useEffect(() => {
    // Emitter.on("Event_Close_Grouped_Options", () => {
    //   cardOptionRef.current.handleClose();
    // });
    // Emitter.on("Event_Open_Grouped_Options", (index = 11) => {
    //   cardOptionRef.current.openManualy();
    //   chartOptionRef.current.handleClose();
    //   childRef.current.handleClose();
    //   if (index) {
    //     setTimeout(() => {
    //       dispatch(setSteps({ key: "kanban", t }));
    //       dispatch(setStepIndex(index));
    //       dispatch(startTour());
    //     }, 500);
    //   }
    // });
    // Emitter.on("Event_Open_Chart_Options", () => {
    //   cardOptionRef.current.handleClose();
    //   childRef.current.handleClose();
    //   chartOptionRef.current.openManualy();
    //   setTimeout(() => {
    //     dispatch(setSteps({ key: "kanban", t }));
    //     dispatch(setStepIndex(16));
    //     dispatch(startTour());
    //   }, 500);
    // });
    // Emitter.on("Event_Open_Help_Overlay", () => {
    //   cardOptionRef.current.handleClose();
    //   chartOptionRef.current.handleClose();
    //   childRef.current.openManualy(true);
    // });
    // return () => {
    //   Emitter.removeAllListeners();
    // };
  }, []);

  return (
    <Box
      display="flex"
      alignItems={"center"}
      position="relative"
      zIndex={100}
      sx={{ mx: { xs: "2rem", md: "178px", s2133: "170px" } }}
    >
      {Constant?.isProd || !beta_enabled ? null : <CostUnitDropdown />}
      <MonthSkipView />

      <div
        style={{
          display: "inline-flex",
          alignItems: "center",
          width: "80%",
        }}
      >
        <ScenarioDropdown />
        {Constant?.isProd || !beta_enabled ? null : <TableViewOptions />}

        <div
          style={{
            display: "inline-flex",
            position: "relative",
            alignItems: "center",
          }}
        >
          <Box
            display="inline-flex"
            alignItems="center"
            justifyContent={"flex-start"}
            backgroundColor={Color.tailwind.purple[200]}
            borderRadius={14}
            pr={isKanbanView ? "4px" : 0}
            sx={{
              position: "absolute",
              left: 0,
              top: "-1.5rem",
              zIndex: 2,
            }}
          >
            <TableSwitch />

            {/* {isKanbanView ? <CardOptionsPopOver ref={cardOptionRef} /> : null} */}
          </Box>
        </div>
      </div>

      <Box
        display="inline-flex"
        alignItems="center"
        justifyContent={"flex-end"}
        minWidth={"50%"}
        width={"fit-content"}
      >
        <ExportDialogView />

        {/* <ChartOptionsPopOver ref={chartOptionRef} /> */}

        <FinbanInfoBulb
          url="https://finban.io/documentation/planungsansicht/"
          sx={{ ml: "1rem" }}
        />
      </Box>
    </Box>
  );
};
export default React.memo(BoardHeader);

const TableSwitch = () => {
  const dispatch = useDispatch();
  const ToggleOptions = [
    {
      value: 2,
      label: "Table-View", //overview //default
      icon: <FaGlobeAmericas />,
    },
    {
      value: 1,
      label: "Card-View", //focus
      icon: <FaEye />,
    },
  ];

  const isKanbanView = useSelector((state) => state.boardSlice.isKanbanView);

  const onChange = () => {
    dispatch(toggleView());
  };

  useEffect(() => {
    if (Constant.isProd || Constant.isTest || Constant.isStag) {
      if (isKanbanView) {
        dispatch(toggleView());
      }
    }
  }, [dispatch, isKanbanView]);

  useEffect(() => {
    dispatch(
      updateFilters({ parent_key: "kanban", obj: { precisionValue: 1 } })
    );
    dispatch(setChartExapanded(true));
  }, []);

  return null;
  // return (
  //   <ToggleTheme
  //     options={ToggleOptions}
  //     value={isKanbanView ? 1 : 2}
  //     onChange={onChange}
  //   />
  // );
};

const TableViewOptions = () => {
  const dispatch = useDispatch();
  const isWeeklyTable = useSelector((state) => state.boardSlice?.isWeeklyTable);
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);

  const onChange = () => {
    dispatch(setToggleTableViewOption());
  };

  useEffect(() => {
    if (dataSetData?.uuid && !Constant.isProd) {
      const difference = differenceInMonths(
        new Date(dataSetData?.start_date),
        new Date(dataSetData?.end_date)
      );

      if (Math.abs(difference) < 6 && !isWeeklyTable) {
        dispatch(setToggleTableViewOption());
      }
    }
  }, [dataSetData?.uuid]);

  return (
    <ThemeToggle
      value={String(!!isWeeklyTable)}
      onChange={onChange}
      options={TableViewFilterOptions}
    />
  );
};
