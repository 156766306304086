import { createSlice } from "@reduxjs/toolkit";

import { getTourStep } from "../../Helper/TourStep";
import { resetAllState } from "./reset";

const initialState = {
  run: false,
  stepIndex: 0,
  steps: [],
  loading: false,
  key: Date.now(),
  name: "",
  tourStatus: null,
};

const tourSlice = createSlice({
  name: "tourSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(resetAllState, () => initialState);
  },
  reducers: {
    startTour: (state) => {
      state.run = true;
    },
    stopTour: (state) => {
      state.run = false;
    },
    resetTour: (state) => {
      state.stepIndex = 0;
    },
    restartTour: (state) => {
      state.stepIndex = 0;
      state.run = true;
      state.loading = false;
      state.key = Date.now();
    },
    setRun: (state, action) => {
      state.run = action.payload;
    },
    setSteps: (state, action) => {
      state.steps = getTourStep(action.payload);
      state.stepIndex = 0;
      state.name = action.payload?.key ?? "";
    },
    setStepIndex: (state, action) => {
      state.stepIndex = action.payload;
    },
    setTourStatus: (state, action) => {
      state.tourStatus = action.payload;
    },
  },
});
export const {
  setRun,
  setTourStatus,
  setSteps,
  setStepIndex,
  startTour,
  stopTour,
  resetTour,
  restartTour,
} = tourSlice.actions;
export default tourSlice;
