import {
  CircularProgress,
  InputAdornment,
  useMediaQuery,
  AlertTitle,
  IconButton,
  TextField,
  Alert,
  Card,
  Link,
  Box,
} from "@mui/material";
import { Link as NavigateLink, useLocation } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useState, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useTheme } from "@mui/styles";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";

import { setIsOpenSnackbar } from "../../store/slices/common";
import AddButton from "../../components/AddButton";
import EndPoints from "../../APICall/EndPoints";
import { Color, Images } from "../../Helper";

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}
const inputStyle = {
  mx: "auto",
  "& .MuiInputBase-root": {
    fontSize: "1rem",
  },
  "& .MuiFormLabel-root": {
    fontSize: "1rem",
  },
};

const AccountActivateScreen = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  let query = useQuery();
  let activation_key = query.get("activation_key");
  let invited = query.get("invited");
  const dispatch = useDispatch();
  const mdSize = useMediaQuery("(max-width:1745px)");

  //state
  const [status, setStatus] = useState(null);
  const [values, setValues] = useState({
    showPassword: false,
    showConfirmPassword: false,
  });

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      confirmPassword: "",
    },
    onSubmit: (values) => {
      activateAccountApi({ activation_key, password: values.password });
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .trim()
        .min(8, t("Password should be of minimum 8 characters length"))
        .required(t("Password is required")),
      confirmPassword: Yup.string()
        .trim()
        .oneOf([Yup.ref("password")], t("Confirm Password must match"))
        .required(t("Confirm Password is required")),
    }),
  });

  //api
  const activateAccountApi = (obj) => {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}${EndPoints.activateAccount}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      timeout: 60000 * 15,
      data: obj,
    })
      .then((response) => {
        if (response.status === 200 && response.data?.success) {
          setStatus("success");
        } else {
          dispatch(setIsOpenSnackbar(false));
          setStatus("error");
        }
      })
      .catch((error) => {
        window.location.reload();
      })
      .finally(() => {
        dispatch(setIsOpenSnackbar(false));
      });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowConfirmPassword = () => {
    setValues({
      ...values,
      showConfirmPassword: !values.showConfirmPassword,
    });
  };

  //lifecycle method
  useEffect(() => {
    if (activation_key && !invited) {
      activateAccountApi({ activation_key });
    }
  }, [activation_key, invited]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        backgroundColor: theme?.color?.appThemeBg,
      }}
    >
      <Card
        sx={{
          width: "50rem",
          height: "26rem",
          borderRadius: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <img
          src={Images.finban_logo_full}
          height={80}
          alt="App_Logo"
          style={{ position: "absolute", top: "1rem" }}
        />
        <Box
          style={{
            position: "absolute",
            bottom: invited && !status ? "2rem" : "9rem",
            display: "flex",
            flexDirection: "column",
            minWidth: "70%",
          }}
        >
          {!status && invited && (
            <Alert
              severity="info"
              sx={{
                mt: "4rem",
                width: "100%",
              }}
            >
              {t("reset_pass_form_info")}
            </Alert>
          )}
          {status === "success" && (
            <Alert severity="success">
              <AlertTitle>{t("Success")}</AlertTitle>
              {t("registred_successfully_message")}
              <NavigateLink
                to="/login"
                style={{
                  color: "inherit",
                  marginLeft: 8,
                  textDecorationLine: "underline",
                }}
                replace
              >
                {t("To log in follow this link")}
              </NavigateLink>{" "}
            </Alert>
          )}
          {status === "error" && (
            <Alert severity="error">
              <AlertTitle>{t("Error")}</AlertTitle>
              {t("registration_failed")}
              <Link
                color="inherit"
                href="https://www.support@finban.io"
                sx={{ ml: 0.5 }}
              >
                support@finban.io
              </Link>
            </Alert>
          )}
          {!status && !invited && (
            <>
              <Alert
                severity="info"
                icon={
                  <CircularProgress
                    color="inherit"
                    size={23}
                    sx={{
                      color: theme.palette.primary.main,
                    }}
                  />
                }
                sx={{
                  color: theme.palette.primary.main,
                  backgroundColor: theme.palette.primary[50],
                }}
              >
                {t("account_preparation_in_progress")}
              </Alert>
            </>
          )}
          {!status && invited && (
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label={t("Password")}
              type="password"
              autoComplete="new-password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              sx={inputStyle}
              size={mdSize ? "small" : "medium"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          )}
          {!status && invited && (
            <TextField
              margin="normal"
              required
              fullWidth
              name="confirmPassword"
              label={t("ConfirmPassword")}
              type="password"
              autoComplete="confirm-password"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              error={
                formik.touched.confirmPassword &&
                Boolean(formik.errors.confirmPassword)
              }
              helperText={
                formik.touched.confirmPassword && formik.errors.confirmPassword
              }
              sx={inputStyle}
              size={mdSize ? "small" : "medium"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowConfirmPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showConfirmPassword ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          )}

          {!status && invited && (
            <AddButton
              onClick={formik.handleSubmit}
              showIcon={false}
              tooltipLabel={t("Create")}
              label={t("Create")}
              sx={{ width: "7rem", mx: "auto", mt: "1rem" }}
            />
          )}
        </Box>
      </Card>
    </Box>
  );
};
export default AccountActivateScreen;
