import { styled } from "@mui/material/styles";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { Color, Fonts } from "../../Helper";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
  backgroundColor: Color.tailwind.slate[100],
  borderRadius: 14,
  "& .MuiToggleButtonGroup-grouped": {
    border: 0,
    textTransform: "capitalize",
    fontSize: "0.8rem",
    fontWeight: 500,
    fontFamily: Fonts.Text,
    margin: 0,
    height: "2rem",
    width: "auto",
    minWidth: "6.5rem",
    color: Color.tailwind.slate[400],
    "&.Mui-disabled": {
      border: `1px solid ${Color.tailwind.slate[300]} !important`,
      color: Color.tailwind.slate[300],
    },
    "&:hover": {
      backgroundColor: Color.tailwind.slate[100],
    },
    "&:not(:first-of-type)": {
      borderRadius: "0.875rem",
      marginInline: "0.5rem",
    },
    "&:first-of-type": {
      borderRadius: "0.875rem",
      marginRight: "0.5rem",
    },
    "&:last-of-type": {
      marginLeft: "0.5rem",
      borderRadius: "0.875rem",
    },
  },
  "& .Mui-selected": {
    fontWeight: 600,
    color: `${Color.white} !important`,
    backgroundColor: `${Color.tailwind.slate[400]} !important`,
    "&:hover": {
      backgroundColor: Color.tailwind.slate[400],
    },
  },
}));
export default StyledToggleButtonGroup;
