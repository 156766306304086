import * as React from "react";
import Box from "@mui/material/Box";
import { styled, alpha } from "@mui/material/styles";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { Color, Fonts } from "../../Helper";
import { useDispatch, useSelector } from "react-redux";
import { setAppliedFilterslist } from "../../store/slices/global";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
const marks = [
  {
    value: 1,
    label: "All",
    tooltip: "kanban_all_group_tooltip",
  },
  {
    value: 2,
    label: "Medium",
    tooltip: "kanban_medium_group_tooltip",
  },
  {
    value: 3,
    label: "Low",
    tooltip: "kanban_low_group_tooltip",
  },
];

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
  backgroundColor: alpha(Color.purple, 0.025),
  borderRadius: 14,
  "& .MuiToggleButtonGroup-grouped": {
    border: 0,
    textTransform: "capitalize",
    fontSize: "0.8rem",
    fontWeight: 500,
    fontFamily: Fonts.Text,
    margin: 0,
    height: "2rem",
    width: "5rem",
    color: alpha(Color.themeColor, 0.6),
    "&.Mui-disabled": {
      border: `1px solid ${Color.grey300} !important`,
      color: Color.grey300,
    },
    "&:hover": {
      backgroundColor: alpha(Color.themeColor, 0.2),
    },
    "&:not(:first-of-type)": {
      borderRadius: "0.875rem",
      marginInline: "0.5rem",
    },
    "&:first-of-type": {
      borderRadius: "0.875rem",
      marginRight: "0.5rem",
    },
    "&:last-of-type": {
      marginLeft: "0.5rem",
      borderRadius: "0.875rem",
    },
  },
  "& .Mui-selected": {
    fontWeight: 600,
    color: `${Color.white} !important`,
    backgroundColor: `${alpha(Color.themeColor, 0.75)} !important`,
    "&:hover": {
      backgroundColor: alpha(Color.themeColor, 0.75),
    },
  },
}));

export default React.forwardRef(function ToggleGroupeView(
  { wrapperStyle, type, ...otherProps },
  ref
) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  //redux
  const appliedFilterlist = useSelector(
    (state) => state.globalSlice.appliedFilterlist
  );

  //function
  const onChange = (e, value) => {
    if (value) {
      let data = {
        ...appliedFilterlist,
        kanban: {
          ...appliedFilterlist?.kanban,
          groupValue: {
            ...appliedFilterlist?.kanban?.groupValue,
            [type]: value,
          },
        },
      };
      dispatch(setAppliedFilterslist(data));
    }
  };

  if (
    appliedFilterlist?.kanban?.groupValue &&
    ![1, 2, 3].includes(appliedFilterlist?.kanban?.groupValue[type])
  ) {
    onChange(null, 2);
  }
  //render function
  return (
    <Box {...otherProps} ref={ref} sx={{ ...wrapperStyle }}>
      <Box
        sx={{
          display: "flex",
          width: "fit-content",
          flexWrap: "wrap",
        }}
      >
        <StyledToggleButtonGroup
          size="small"
          value={
            appliedFilterlist?.kanban?.groupValue
              ? appliedFilterlist?.kanban?.groupValue[type] ?? 2
              : 2
          }
          exclusive
          onChange={onChange}
          aria-label="text alignment"
        >
          {marks?.map((o1) => (
            <ToggleButton key={o1.value} value={o1.value}>
              <Tooltip arrow title={t(o1.tooltip)} placement="top">
                <p>{t(o1.label)}</p>
              </Tooltip>
            </ToggleButton>
          ))}

          {/* <ToggleButton
            value={0}   3->1    ,1 -> 2   , 0-> 3
            aria-label="right aligned1"
            disabled
          >
            OFF
          </ToggleButton> */}
        </StyledToggleButtonGroup>
      </Box>
    </Box>
  );
});
