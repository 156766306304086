import * as React from "react";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";

const Search = styled("div")(({ theme, width }) => {
  return {
    position: "relative",
    padding: theme.spacing(1),
    borderRadius: theme.borderRadius.main,
    backgroundColor: theme.palette.color.slate[50],
    "&:hover": {
      backgroundColor: theme.palette.color.slate[100],
    },
    marginLeft: 0,
    width: width || "100%",
  };
});

const SearchIconWrapper = styled("div")(({ theme }) => ({
  height: "100%",
  position: "absolute",
  left: theme.spacing(4),
  top: theme.spacing(0.5),
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.color.slate[500],
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 12),
    color: theme.palette.color.slate[600],
    fontWeight: theme.typography.fontWeightMedium,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const ThemeSearchInput = ({ placeholder, inputProps, ...props }) => {
  const { t } = useTranslation();
  return (
    <Search {...props}>
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
      <StyledInputBase
        placeholder={placeholder || t("Search...")}
        inputProps={{ "aria-label": "search" }}
        {...inputProps}
      />
    </Search>
  );
};

export default ThemeSearchInput;
