import {
  InputAdornment,
  useMediaQuery,
  AlertTitle,
  IconButton,
  Alert,
} from "@mui/material";
// import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import RadioGroup, { useRadioGroup } from "@mui/material/RadioGroup";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import FormControlLabel from "@mui/material/FormControlLabel";
// import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Link as NavigateLink } from "react-router-dom";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { Grid, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import Button from "@mui/lab/LoadingButton";
import { useDispatch } from "react-redux";
import React, { useState } from "react";
import { useSnackbar } from "notistack";
import Radio from "@mui/material/Radio";
import Link from "@mui/material/Link";
import format from "date-fns/format";
import Box from "@mui/material/Box";
import { useFormik } from "formik";
import * as Yup from "yup";

import { setIsOpenSnackbar } from "../../store/slices/common";
// import ImageSlider from "../../components/ImageSlider";
import EndPoints from "../../APICall/EndPoints";
import { Color, Images } from "../../Helper";
import RatingView from "./RatingView";
import APICall from "../../APICall";

// const starting_data = [
//   {
//     value: 1,
//     display_name: "Start with sample data",
//   },
//   {
//     value: 2,
//     display_name: "Start with own data",
//   },
// ];

const StyledFormControlLabel = styled((props) => (
  <FormControlLabel {...props} />
))(({ theme, checked }) => ({
  ".MuiFormControlLabel-label": {
    fontSize: "0.8rem",
    color: checked && theme.palette.primary.main,
  },
}));
export default function RegisterScreen() {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const mdSize = useMediaQuery("(max-width:1745px)");
  const allow = useMediaQuery("(min-width:960px)");
  const inputStyle = {
    "& .MuiInputBase-root": {
      fontSize: "1rem",
    },
    "& .MuiFormLabel-root": {
      fontSize: "1rem",
    },
  };
  //state
  const [values, setValues] = useState({
    showPassword: false,
  });
  const [isSucceed, setIsSucceed] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [radioValue, setRadioValue] = useState("");
  const [selectedStartData, setSelectedStartData] = useState({
    value: 1,
    display_name: "Start with sample data",
  });

  //Api
  const createUserApi = async (obj) => {
    setIsProcessing(true);
    await APICall("post", EndPoints.register, obj, {
      doNotCatchRespond: true,
    }).then((response) => {
      if (response.status === 201 && response.data) {
        setIsSucceed(true);
      }
      if (response.status === 400 && response.data.email.length > 0) {
        dispatch(setIsOpenSnackbar(false));
        enqueueSnackbar(t(response.data.email[0]), {
          variant: "error",
          autoHideDuration: 4000,
        });
      }
      setIsProcessing(false);
    });
  };

  //function
  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
    },
    onSubmit: (values) => {
      if (radioValue && radioValue !== "") {
        let data = {
          ...values,
          starting_data: selectedStartData?.value,
          subscribed_to_newsletter: radioValue === "yes",
        };
        createUserApi(data);
      } else {
        enqueueSnackbar(t("Please_select_newsletter"), {
          variant: "warning",
          autoHideDuration: 4000,
        });
      }
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(t("Enter a valid email"))
        .required(t("Email is required")),
      password: Yup.string()
        .trim()
        .min(8, t("Password should be of minimum 8 characters length"))
        .required(t("Password is required")),
      first_name: Yup.string().trim().required(t("First Name is required")),
      last_name: Yup.string().trim().required(t("Last Name is required")),
    }),
  });

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // const onChangeStartData = (e, value) => {
  //   setSelectedStartData(value);
  // };

  //rebder function
  function Copyright() {
    return (
      <Typography
        component="div"
        variant="body2"
        color="color.description"
        align="center"
        sx={{
          mt: "1rem",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", small: "row" },
          position: "absolute",
          bottom: "1rem",
          left: "50%",
          width: { xs: "85%", small: "60%" },
          transform: "translate(-50%, -50%)",
        }}
      >
        <Box>
          <Link color="inherit" href="//finban.io/imprint/">
            {t("Imprint")}
          </Link>{" "}
          <span>&#8226;</span>{" "}
          <Link color="inherit" href="//finban.io/datenschutzerklaerung/">
            {t("Privacy_Policy")}
          </Link>{" "}
          <span>&#8226;</span>{" "}
          <Link color="inherit" href="//finban.io/agbs/">
            {t("Terms_Conditions")}
          </Link>
        </Box>
        <Box>finban.io - {format(new Date(), "yyyy")} </Box>
      </Typography>
    );
  }

  // const Highlighted = ({ text = "", highlight = "" }) => {
  //   if (!highlight.trim()) {
  //     return <span>{text}</span>;
  //   }
  //   const regex = new RegExp(`(${highlight})`, "gi");
  //   const parts = text.split(regex);

  //   return (
  //     <span>
  //       {parts.filter(String).map((part, i) => {
  //         return regex.test(part) ? (
  //           <b key={i}>{part}</b>
  //         ) : (
  //           <span key={i}>{part}</span>
  //         );
  //       })}
  //     </span>
  //   );
  // };

  // const Quotecard = ({ item }) => {
  //   return (
  //     <Box
  //       sx={{
  //         width: 464,
  //         height: 375,
  //         pr: 1,
  //         backgroundColor: "transparent",
  //         display: "flex",
  //         flexDirection: "row",
  //         justifyContent: "space-between",
  //       }}
  //     >
  //       <Avatar
  //         alt={item?.name}
  //         src={item?.profile}
  //         sx={{ width: 60, height: 60 }}
  //       />
  //       <Card
  //         sx={{
  //           p: 3,
  //           backgroundColor: `${Color?.white} !important`,
  //           borderRadius: 2,
  //           maxWidth: "370px !important",
  //           maxHeight: "none !important",
  //           height: 250,
  //           position: "relative",
  //         }}
  //       >
  //         <Typography
  //           sx={{
  //             fontSize: "0.9rem",
  //             fontFamily: Fonts?.Text,
  //             fontWeight: 500,
  //             color: Color.theme.grey[600],
  //             whiteSpace: "break-spaces",
  //           }}
  //         >
  //           <Highlighted
  //             text={`${t("quotation_start")}${item?.review}${t(
  //               "quotation_end"
  //             )}`}
  //             highlight="finban"
  //           />
  //         </Typography>
  //         <Box
  //           sx={{
  //             display: "inline-flex",
  //             flexDirection: "row",
  //             mt: 2,
  //             position: "absolute",
  //             bottom: 24,
  //           }}
  //         >
  //           <Icon
  //             icon={<RiDoubleQuotesL />}
  //             fontSize={"40px"}
  //             color={Color.theme.grey[600]}
  //           ></Icon>
  //           <Box display="inline-flex" flexDirection={"column"} ml={1}>
  //             <Box
  //               sx={{
  //                 fontFamily: Fonts?.Text,
  //                 fontWeight: 500,
  //                 color: Color.black,
  //               }}
  //             >
  //               {item?.name}
  //             </Box>
  //             <Box
  //               sx={{
  //                 display: "inline-flex",
  //                 flexDirection: "row",
  //                 fontSize: "0.8rem",
  //                 fontFamily: Fonts?.Text,
  //                 fontWeight: 500,
  //                 color: Color.theme.grey[600],
  //               }}
  //             >
  //               {item?.position},{" "}
  //               <Link
  //                 color="inherit"
  //                 href={item?.link}
  //                 sx={{
  //                   mx: 0.8,
  //                   fontWeight: 600,
  //                   color: theme.palette.primary.main,
  //                   whiteSpace: "break-spaces",
  //                 }}
  //               >
  //                 {item?.linkTitle}
  //               </Link>
  //             </Box>
  //           </Box>
  //         </Box>
  //       </Card>
  //     </Box>
  //   );
  // };

  function MyFormControlLabel(props) {
    const radioGroup = useRadioGroup();

    let checked = false;

    if (radioGroup) {
      checked = radioGroup.value === props.value;
    }

    return <StyledFormControlLabel checked={checked} {...props} />;
  }

  return (
    <Grid
      container
      component="main"
      sx={{ height: "100%", backgroundColor: Color.appThemeBg }}
    >
      <Grid
        item
        xs={12}
        sm={12}
        md={5.5}
        component={Box}
        disableElevation
        square
        sx={{
          position: "relative",
          backgroundColor: Color.white,
        }}
      >
        {isSucceed ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "90%",
            }}
          >
            <Alert
              severity="success"
              sx={{ width: "90%", height: "fit-content" }}
            >
              <AlertTitle>{t("Success")}</AlertTitle>
              {t("registration_link_sent_to_email")}
            </Alert>
          </Box>
        ) : (
          <Box
            sx={{
              pt: "8rem",
              mx: { xs: 0, sm: "4rem" },
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100%",
              position: "relative",
            }}
          >
            <Link
              href="/"
              sx={{
                width: "10rem",
              }}
            >
              <img
                src={Images.finban_logo_full}
                width={"100%"}
                height={"100%"}
                alt="App_Logo"
              />
            </Link>

            <Box
              component="form"
              noValidate
              onSubmit={formik.handleSubmit}
              sx={{
                width: { xs: "70%", sm: "60%" },
                maxHeight: { xs: "68%", isMobile: "75%" },
                overflow: "auto",
                pt: "1rem",
                mt: "2rem",
                ...theme.thinScrollBar,
              }}
            >
              <Grid container spacing={1}>
                <Grid item xs={6} sm={6}>
                  <TextField
                    size={mdSize ? "small" : "medium"}
                    autoComplete="given-name"
                    name="first_name"
                    required
                    fullWidth
                    id="first_name"
                    label={t("First_Name")}
                    autoFocus
                    value={formik.values.first_name}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.first_name &&
                      Boolean(formik.errors.first_name)
                    }
                    helperText={
                      formik.touched.first_name && formik.errors.first_name
                    }
                    sx={inputStyle}
                  />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <TextField
                    required
                    fullWidth
                    id="last_name"
                    label={t("Last_Name")}
                    name="last_name"
                    autoComplete="family-name"
                    value={formik.values.last_name}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.last_name &&
                      Boolean(formik.errors.last_name)
                    }
                    helperText={
                      formik.touched.last_name && formik.errors.last_name
                    }
                    sx={inputStyle}
                    size={mdSize ? "small" : "medium"}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="sign_up_email"
                    label={t("Email_Address")}
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    sx={inputStyle}
                    size={mdSize ? "small" : "medium"}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label={t("Password")}
                    type="password"
                    autoComplete="new-password"
                    id="sign_up_password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                    sx={inputStyle}
                    size={mdSize ? "small" : "medium"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.showPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </Grid>
                {/* <Grid item xs={12}>
                  <Autocomplete
                    disableClearable={true}
                    id="combo-box-demo"
                    options={starting_data}
                    getOptionLabel={(option) => option?.display_name}
                    value={selectedStartData}
                    onChange={onChangeStartData}
                    sx={{
                      width: "100%",
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Start With" />
                    )}
                  />
                </Grid> */}

                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    mt: "1rem",
                  }}
                >
                  <RadioGroup
                    name="use-radio-group"
                    defaultValue="no"
                    value={radioValue}
                    onChange={(e) => setRadioValue(e.target.value)}
                  >
                    <MyFormControlLabel
                      value={"yes"}
                      label={t("register_option_1")}
                      control={<Radio sx={{ alignSelf: "center", py: 0.5 }} />}
                      sx={{ mb: "1rem", fontSize: "0.8rem", ml: 0 }}
                    />
                    <MyFormControlLabel
                      value="no"
                      label={t("register_option_2")}
                      control={
                        <Radio sx={{ alignSelf: "center", py: "0.5rem" }} />
                      }
                      sx={{ ml: 0 }}
                    />
                  </RadioGroup>
                </Grid>
              </Grid>

              <Button
                loading={isProcessing}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: "1.5rem" }}
              >
                {t("Sign_up")}
              </Button>
              <Typography
                variant="body2"
                color="color.description"
                sx={{
                  textAlign: "left",
                  ml: "1rem",
                  my: "1rem",
                  fontSize: {
                    s1920: "0.8rem",
                    s1280: "0.7rem",
                  },
                }}
              >
                {t("term_message")}{" "}
                <Link color="inherit" href="//finban.io/agbs/">
                  {t("Terms_Conditions")}
                </Link>{" "}
                and{" "}
                <Link color="inherit" href="//finban.io/datenschutzerklaerung/">
                  {t("Privacy_Policy")}.
                </Link>{" "}
              </Typography>
              {radioValue === "yes" && (
                <Typography
                  variant="body2"
                  color="color.description"
                  sx={{
                    textAlign: "left",
                    ml: "1rem",
                    my: "1.5rem",
                    fontSize: {
                      s1920: "0.8rem",
                      s1280: "0.7rem",
                    },
                  }}
                >
                  {t("register_radio_1_accept_message")}{" "}
                  <Link
                    color="inherit"
                    href="//finban.io/datenschutzerklaerung/"
                  >
                    {t("Privacy_Policy")}.
                  </Link>{" "}
                </Typography>
              )}

              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Link
                    variant="div"
                    component={"div"}
                    sx={{
                      fontSize: "0.9rem",
                      color: theme.palette.primary.main,
                      "&:hover": {
                        color: theme.palette.primary.dark,
                      },
                    }}
                  >
                    <NavigateLink
                      to="/login"
                      style={{ color: "inherit" }}
                      replace
                    >
                      {t("already_have_account")}
                    </NavigateLink>
                  </Link>
                </Grid>
              </Grid>

              <Copyright />
            </Box>
          </Box>
        )}
      </Grid>
      <Grid
        item
        xs={false}
        sm={false}
        md={6.5}
        sx={{
          position: "relative",
          display: { xs: "none", md: "flex" },
          justifyContent: "end",
          alignItems: "center",
          backgroundColor: (t) => t.palette.color.slate[200],
        }}
      >
        <Box
          sx={{
            backgroundImage: `url(${Images.AuthPageImage})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: Color.appThemeBg,
            backgroundSize: "100% 100%",
            backgroundPosition: "center",
            height: "50%",
            width: "92%",
            border: (theme) => `1px solid ${theme.palette.color.slate[200]}`,
            boxShadow: `rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px`,

            // filter: "blur(2px)",
            // width: "90%",
            // height: "100%",
            // right: 0,
            // backgroundSize: "contain",
            // backgroundPosition: "center",
            // position: "relative",
            // backgroundImage: `url(${bgImage})`,
            // backgroundRepeat: "no-repeat",
          }}
        ></Box>
        <RatingView />
        {/* {allow && (
          <Box
            sx={{
              zIndex: 2,
              transform: "translate(-50%, -50%)",
              position: "absolute",
              top: "50%",
              left: "50%",
            }}
          >
            <ImageSlider data={initialData?.reviews}>
              {initialData?.reviews?.map((item, index) => (
                <Quotecard key={index} item={item} />
              ))}
            </ImageSlider>
          </Box>
        )} */}
      </Grid>
    </Grid>
  );
}
