import { useTranslation } from "react-i18next";
import { Box, Tooltip } from "@mui/material";
import { IconContext } from "react-icons";
import PropTypes from "prop-types";
import React from "react";

import theme from "../../theme";

const Icon = ({
  icon,
  fontSize,
  color,
  stroke,
  fill,
  style,
  id,
  path,
  onClick,
  tooltip,
}) => {
  const { t } = useTranslation();
  return (
    <IconContext.Provider value={{ style: { fontSize, color } }}>
      <Tooltip arrow title={t(tooltip)} placement="top" followCursor>
        <Box
          onClick={onClick}
          variant="span"
          id={id}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: fontSize,
            color: color,
            "& path": {
              stroke: stroke,
              fill: fill,
              ...path,
            },
            ...style,
          }}
        >
          {icon?.type ? icon : null}
        </Box>
      </Tooltip>
    </IconContext.Provider>
  );
};

Icon.propTypes = {
  icon: PropTypes.any,
  fontSize: PropTypes.any,
  color: PropTypes.string,
};

Icon.defaultProps = {
  fontSize: "20px",
  color: theme.palette.primary.main,
};

export default Icon;
