import {
  Typography,
  useTheme,
  Divider,
  Stack,
  alpha,
  Box,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { addMonths, format } from "date-fns";
import { useNavigate } from "react-router";
import { useContext } from "react";

import {
  setTransactionsOverlayStatus,
  setPopupStatus2,
} from "../../store/slices/datasets";
import { GlobalContext } from "../../GlobalContextWrapper";
import { updateFilters } from "../../store/slices/global";
import { getDefaultFilters } from "../../Helper/data";
import Translate from "../../hooks/HOC/Translate";
import { Color, Images } from "../../Helper";
import ActionButton from "../ActionButton";

const NoDataInfoView = ({
  tab,
  backgroundColor = Color.white,
  disabled,
  wrapperStyle,
  hideButton,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const globalContext = useContext(GlobalContext);
  const dsRef = globalContext?.dsRef;
  //redux
  const popupStatus2 = useSelector((state) => state.datasetSlice?.popupStatus2);
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);

  const auto_categorization = useSelector(
    (state) => state.settingsSlice?.profile?.auto_categorization
  );

  const cell = popupStatus2?.payload?.cell;
  const hidePreset = !cell?.income_expense_type;
  let showResetButton = false;
  let showBankButton = false;
  let showOpenButton = false;
  let showBudgetButton = false;
  //functions
  // if (tab === "Planned" && !hidePreset) {
  //   let categoryId = null;
  //   if (
  //     cell?.rowId !== "uncategorized" &&
  //     cell?.rowId !== "uncategorizedInflow" &&
  //     cell?.rowId !== "uncategorizedOutflow"
  //   ) {
  //     categoryId = cell.rowId;
  //   }

  //   return (
  //     <Box
  //       sx={{
  //         display: "flex",
  //         flexDirection: "column",
  //         alignItems: "center",
  //         justifyContent: "center",
  //         paddingBlock: "1.5rem",
  //         borderRadius: 8,
  //         overflow: "auto",
  //         backgroundColor: "color.slate.50",
  //         ...thinScrollbarStyle,
  //         ...wrapperStyle,
  //       }}
  //     >
  //       <Typography
  //         sx={{
  //           color: Color.black,
  //           fontSize: "1rem",
  //           fontWeight: 700,
  //           width: "80%",
  //           my: "0.25rem",
  //         }}
  //       >
  //         {t("Create planning")}
  //       </Typography>{" "}
  //       <div
  //         style={{
  //           display: "flex",
  //           flexDirection: "column",
  //           alignItems: "center",
  //           padding: "1.5rem",
  //           borderRadius: 8,
  //           width: "100%`",
  //         }}
  //       >
  //         <AddPresetCard
  //           disabled={disabled}
  //           tab={tab}
  //           income_expense_type={cell?.income_expense_type}
  //           preset_type="AddTransaction"
  //           due_date={cell?.date}
  //           categoryId={categoryId}
  //           minCardHeight={"7rem"}
  //         />
  //         <Divider
  //           textAlign="left"
  //           sx={{
  //             width: "100%",
  //             mt: "1.5rem",
  //             mb: "0.5rem",
  //             fontWeight: 500,
  //             fontSize: "0.8rem",
  //             color: "color.slate.300",
  //           }}
  //         >
  //           {t("Preset")}
  //         </Divider>
  //         <AddPresetCard
  //           disabled={disabled}
  //           tab={tab}
  //           income_expense_type={cell?.income_expense_type}
  //           preset_type="RecurringPlanning"
  //           due_date={cell?.date}
  //           categoryId={categoryId}
  //         />
  //         {cell?.income_expense_type === 1 ? null : (
  //           <AddPresetCard
  //             disabled={disabled}
  //             tab={tab}
  //             income_expense_type={cell?.income_expense_type}
  //             preset_type="EmployeePlanning"
  //             due_date={cell?.date}
  //             categoryId={categoryId}
  //           />
  //         )}
  //         <AddPresetCard
  //           disabled={disabled}
  //           tab={tab}
  //           income_expense_type={cell?.income_expense_type}
  //           preset_type="CategoryRecurringPlanning"
  //           due_date={cell?.date}
  //           categoryId={categoryId}
  //         />
  //         <AddPresetCard
  //           disabled={disabled}
  //           tab={tab}
  //           income_expense_type={cell?.income_expense_type}
  //           preset_type="RevenueRecurringPlanning"
  //           due_date={cell?.date}
  //           categoryId={categoryId}
  //         />
  //         {cell?.income_expense_type !== 2 ? (
  //           <AddPresetCard
  //             disabled={disabled}
  //             tab={tab}
  //             income_expense_type={cell?.income_expense_type}
  //             preset_type="SubscriptionPlanning"
  //             due_date={cell?.date}
  //             categoryId={categoryId}
  //           />
  //         ) : null}
  //       </div>
  //     </Box>
  //   );
  // }

  if (
    hidePreset ||
    tab === "Bulk" ||
    tab === "Budget" ||
    tab === "Planned" ||
    tab === "Suggestion" ||
    tab === "Booked" ||
    tab === "Open" ||
    tab === "Reconcile" ||
    tab === "All" ||
    tab === "Late"
  ) {
    showResetButton = true;

    const onClickUserGuide = () => {
      window.open(
        "https://finban.io/documentation/ist-zahlen-offene-posten-und-planwerte/",
        "_blank"
      );
    };

    const onClickIntegration = () => {
      dispatch(setPopupStatus2(null));
      navigate("/settings/Integrations");
    };

    const onClickResetFilters = () => {
      const defaultFilter = getDefaultFilters();

      const obj = {
        parent_key: "list",
        obj: defaultFilter?.list,
      };
      dispatch(updateFilters(obj));
    };

    const onClickAddBank = () => {
      dsRef?.current?.openIntegrationOverlay("add");
    };

    const onClickAddOpen = () => {
      const item = {
        title: "",
        note: "",
        source: 1,
        invoice_date: null,
        category: null,
        tax: null,
        recurring_rule: null,
        scenario: "Base",
        state: "Open",
        dataset: dataSetData.uuid,
        due_date: format(new Date(), "yyyy-MM-dd"),
        data_source: dataSetData?.internal_data_source,
        gross_value: "0.00",
        contact: null,
        income_expense_type: 1,
      };
      dispatch(
        setTransactionsOverlayStatus({
          open: true,
          overlay_type: "drawer_modal_edit_form",
          anchor: "right",
          payload: {
            modalType: "add",
            item,
          },
        })
      );
    };

    const onClickAddBudget = () => {
      const item = {
        title: "",
        note: "",
        source: 1,
        invoice_date: null,
        category: null,
        tax: null,
        recurring_rule: null,
        scenario: "Base",
        state: "Planned",
        dataset: dataSetData.uuid,
        due_date: format(new Date(), "yyyy-MM-dd"),
        data_source: dataSetData?.internal_data_source,
        gross_value: "0.00",
        contact: null,
        income_expense_type: 1,
      };

      const recurringItem = {
        name: "",
        recurring_type: "simple",
        period: 12,
        repetition: 1,
        start_date: format(new Date(), "yyyy-MM-dd"),
        end_date: format(addMonths(new Date(), 12), "yyyy-MM-dd"),
        tax: null,
        value: "0.00",
      };
      dispatch(
        setTransactionsOverlayStatus({
          open: true,
          overlay_type: "drawer_modal_edit_form",
          anchor: "right",
          payload: {
            modalType: "add",
            item,
            recurringItem,
          },
        })
      );
    };

    if (tab === "Booked") {
      showBankButton = true;
    }
    let title1 = "empty_table_list_view_booked_01";
    let title2 = "empty_table_list_view_booked_02";
    let title3 = "empty_table_list_view_booked_03";
    if (tab === "Open") {
      showBankButton = true;
      showOpenButton = true;
      title1 = "empty_table_list_view_open_01";
      title2 = "empty_table_list_view_open_02";
      title3 = "empty_table_list_view_open_03";
    }
    if (tab === "Planned") {
      title1 = "empty_table_list_view_planned_01";
      title3 = "empty_table_list_view_planned_02";
    }
    if (tab === "Bulk") {
      title1 = "empty_table_list_view_bulk_01";
      title3 = "empty_table_list_view_bulk_02";
    }
    if (tab === "Reconcile") {
      title1 = "empty_table_list_view_reconcile_01";
      title3 = "empty_table_list_view_reconcile_02";
    }
    if (tab === "Late") {
      title1 = "empty_table_list_view_late_01";
      title3 = "empty_table_list_view_late_02";
    }
    if (tab === "Budget") {
      showBudgetButton = true;
      title1 = "empty_table_list_view_Budget_01";
      title3 = "empty_table_list_view_Budget_02";
    }
    if (tab === "All") {
      title1 = "empty_table_list_view_All_01";
      title3 = "empty_table_list_view_All_02";
    }
    if (tab === "Suggestion") {
      if (Number(auto_categorization) === 0) {
        title1 = "empty_table_list_view_off_suggestion_01";
        title3 = "empty_table_list_view_off_suggestion_02";
      }
      if (Number(auto_categorization) === 1) {
        title1 = "empty_table_list_view_suggestion_01";
        title3 = "empty_table_list_view_suggestion_02";
      }
      if (Number(auto_categorization) === 2) {
        title1 = "empty_table_list_view_on_suggestion_01";
        title3 = "empty_table_list_view_on_suggestion_02";
      }
    }

    const hide =
      tab === "Planned" ||
      tab === "Suggestion" ||
      tab === "Bulk" ||
      tab === "Budget" ||
      tab === "Reconcile" ||
      tab === "All" ||
      tab === "Late";

    return (
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          width: "50rem",
          padding: "1.5rem",
          margin: "2rem",
          backgroundColor: alpha(backgroundColor, 0.5),
          borderRadius: 8,
          ...wrapperStyle,
        }}
      >
        <img
          src={Images.empty_placeholder}
          alt="empty_placeholder"
          style={{
            width: "33%",
            objectFit: "contain",
          }}
        />

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "70%",
            marginInline: "1rem",
          }}
        >
          <Typography
            color="color.mainTitle"
            sx={{
              fontSize: "1.4rem",
              textAlign: "left",
              mt: "0.5rem",
              fontWeight: 700,
            }}
          >
            <Translate i18nkey={title1} />
          </Typography>
          {hide ? null : (
            <Typography
              component={"div"}
              color="color.description"
              sx={{
                fontSize: "0.9rem",
                fontWeight: 500,
                mt: "1rem",
                textAlign: "left",
              }}
            >
              <Translate i18nkey={title2} />{" "}
              <Typography
                component={"span"}
                color="color.description"
                onClick={onClickIntegration}
                sx={{
                  fontSize: "0.9rem",
                  fontWeight: 700,
                  display: "inline",
                  textDecoration: "underline",
                  cursor: "pointer",
                  "&:hover": {
                    color: theme.palette.primary.main,
                  },
                }}
              >
                {t("Integrations")}
              </Typography>
            </Typography>
          )}
          <Typography
            component={"div"}
            color="color.description"
            sx={{
              fontSize: "0.9rem",
              fontWeight: 500,
              mt: "1rem",
              textAlign: "left",
            }}
          >
            <Translate i18nkey={title3} />{" "}
            {hide ? null : (
              <Typography
                component={"span"}
                color="color.description"
                onClick={onClickUserGuide}
                sx={{
                  fontSize: "1rem",
                  fontWeight: 700,
                  display: "inline",
                  textDecoration: "underline",
                  cursor: "pointer",
                  "&:hover": {
                    color: theme.palette.primary.main,
                  },
                }}
              >
                {t("User-Guide")}
              </Typography>
            )}
          </Typography>

          <Stack
            direction={"row"}
            alignItems={"center"}
            sx={{ width: "100%", mt: "2rem" }}
          >
            {!hideButton && showResetButton ? (
              <ActionButton
                buttontype="manage2"
                onClick={onClickResetFilters}
                label={t("Reset Filters")}
              />
            ) : null}
            {!hideButton && showBankButton ? (
              <ActionButton
                buttontype="manage2"
                onClick={onClickAddBank}
                label={t("Add new Integration")}
                sx={{ ml: "0.5rem" }}
              />
            ) : null}
            {!hideButton && showOpenButton ? (
              <ActionButton
                buttontype="manage2"
                onClick={onClickAddOpen}
                label={t("Add Open position")}
                sx={{ ml: "0.5rem" }}
              />
            ) : null}
            {!hideButton && showBudgetButton ? (
              <ActionButton
                buttontype="manage2"
                onClick={onClickAddBudget}
                label={t("Add Budget")}
                sx={{ ml: "0.5rem" }}
              />
            ) : null}
          </Stack>
        </div>
      </div>
    );
  }
  return null;
};

export default NoDataInfoView;
