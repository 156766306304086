import {
  ClickAwayListener,
  MenuItem,
  Tooltip,
  Popper,
  Paper,
  Fade,
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useEffect, useMemo, useRef, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import _ from "underscore";

import {
  getStatisticsDataWithParams,
  getTailwindColor,
  isBookedStates,
  formatAmount,
} from "../../../../Helper/data";
import useDebounce from "../../../../hooks/3-useDebounce/useDebounce";
import ComponentLoader from "../../../../components/ComponentLoader";
import { Color, Constant, Fonts } from "../../../../Helper";
import Translate from "../../../../hooks/HOC/Translate";
import EmptyView from "./Component/EmptyView";

const ProfitabilityWidget = ({ widgetType, widget }) => {
  //state
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [profitType, setProfitType] = useState("All Expenses");

  return (
    <div
      style={{
        width: "100%",
        height: "90%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <LogicFunctions
        widget={widget}
        setData={setData}
        setLoading={setLoading}
        setIsFetching={setIsFetching}
        widgetType={widgetType}
        profitType={profitType}
      />
      {isLoading || isFetching ? (
        <ComponentLoader
          loading
          hideNoDataPlaceholder
          height={"100%"}
          size={60}
        />
      ) : data ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "70%",
              justifyContent: "space-between",
              display: "flex",
              alignItems: "center",
            }}
          >
            <ItemView value={data?.Revenue} title={"Revenue"} />
            <ItemView value={data?.Expense} title={"Expense"} />
            <ItemView
              value={data?.Profit}
              title={"Profit"}
              highlight
              showDropDown
              profitType={profitType}
              setProfitType={setProfitType}
            />
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <ItemView2 value={data?.billableAmount} title={"Billable amount"} />
            <ItemView2 value={data?.plannedIncome} title={"Budgets"} />
            <ItemView2 value={data?.overhead} title={"Overhead"} />
            <div style={{ width: "18%" }}></div>
            <ItemView2
              value={data?.netProfitMargin}
              title={"Net Profit Margin"}
              highlight
              isPercentage
              chipType="net"
            />
            <ItemView2
              value={data?.grossProfitMargin}
              title={"Gross Profit Margin"}
              highlight
              isPercentage
              chipType="gross"
            />
          </div>
        </div>
      ) : (
        <EmptyView type={widgetType} />
      )}
    </div>
  );
};

export default ProfitabilityWidget;

const ItemView = (props) => {
  const { t } = useTranslation();
  const value = String(parseFloat(props?.value ?? 0).toFixed(2));
  let color = "slate";

  if (props?.highlight) {
    if (props?.value >= 0) {
      color = "green";
    } else {
      color = "red";
    }
  }
  return (
    <div
      style={{
        display: "inline-flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "center",
        width: "32%",
        padding: "1.5rem",
        paddingBlock: "2.5rem",
        borderRadius: 10,
        position: "relative",
        backgroundColor: getTailwindColor(color, 50),
      }}
    >
      <Tooltip
        title={t(`Profitability_${props?.title}_Tooltip`)}
        placement="top"
      >
        <HelpOutlineIcon
          sx={{
            position: "absolute",
            top: "1rem",
            right: "1rem",
            color: getTailwindColor(color, 400),
            cursor: "pointer",
            fontSize: "1.4rem",
          }}
        />
      </Tooltip>
      <span
        style={{
          fontFamily: Fonts.Text,
          fontWeight: 600,
          fontSize: "1rem",
          color: getTailwindColor(color, 800),
        }}
      >
        {t(props?.title)}
      </span>
      <span
        style={{
          fontFamily: Fonts.Text,
          fontWeight: 700,
          fontSize: "1.3rem",
          alignSelf: "flex-start",
          color: getTailwindColor(color, 700),
        }}
      >
        {formatAmount({
          amount: value,
        })}
      </span>
      {props?.showDropDown ? (
        <DropDownView
          value={props?.profitType}
          setValue={props?.setProfitType}
        />
      ) : null}
    </div>
  );
};

const DropDownView = ({ value, setValue }) => {
  const { t } = useTranslation();
  const overlayRef = useRef(null);

  //state
  const [anchorEl, setAnchorEl] = useState(null);

  //functions
  const onClick = () => setAnchorEl(overlayRef.current);

  const onClickAway = () => setAnchorEl(null);

  const onClickItem = (item) => {
    setValue(item);
    setAnchorEl(null);
  };

  return (
    <>
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement={"top-end"}
        transition
        disablePortal
        sx={{
          zIndex: 2,
        }}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={onClickAway}>
            <Fade {...TransitionProps} timeout={350}>
              <Paper
                elevation={1}
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  flexDirection: "column",
                  alignItems: "start",
                  minWidth: "20rem",
                  pt: "1.5rem",
                  pb: ".5rem",
                  boxShadow:
                    "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                }}
              >
                {["All Expenses", "EBT", "EBIT", "EBITA/EBITDA"].map((item) => {
                  const isSelected = item === value;
                  return (
                    <MenuItem
                      onClick={() => onClickItem(item)}
                      key={item}
                      sx={{
                        width: "100%",
                        fontFamily: Fonts.Text,
                        fontWeight: isSelected ? 700 : 500,
                        fontSize: "1rem",
                        color: isSelected
                          ? Color.tailwind.purple[800]
                          : Color.tailwind.slate[500],
                        textTransform: "initial",
                      }}
                    >
                      {`Profitability_profit_type_${t(item)}_Title`}
                    </MenuItem>
                  );
                })}
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>

      <Tooltip
        title={`Profitability_profit_type_${value}_Tooltip`}
        placement="top"
      >
        <div
          ref={overlayRef}
          onClick={onClick}
          style={{
            position: "absolute",
            bottom: "0.5rem",
            right: "0.75rem",
            width: "7rem",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            backgroundColor: Color.tailwind.slate[200],
            borderRadius: 3,
            paddingInline: "0.75rem",
            paddingBlock: "0.15rem",
            fontFamily: Fonts.Text,
            fontWeight: 500,
            fontSize: "0.85rem",
            cursor: "pointer",
          }}
        >
          {`Profitability_profit_type_${t(value)}_Title`}
        </div>
      </Tooltip>
    </>
  );
};

const ItemView2 = (props) => {
  const { t } = useTranslation();

  const value = String(parseFloat(props?.value ?? 0).toFixed(2));
  return (
    <div
      style={{
        display: "inline-flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "center",
        minWidth: "16%",
        padding: "1rem",
        borderRadius: 6,
        position: "relative",
      }}
    >
      <Tooltip
        title={t(`Profitability_${props?.title}_Tooltip`)}
        placement="top"
      >
        <HelpOutlineIcon
          sx={{
            position: "absolute",
            top: "0.5rem",
            right: "0.5rem",
            color: getTailwindColor("slate", 300),
            cursor: "pointer",
            fontSize: "1rem",
          }}
        />
      </Tooltip>
      <span
        style={{
          fontFamily: Fonts.Text,
          fontWeight: 600,
          fontSize: "0.7rem",
          color: getTailwindColor("slate", 400),
        }}
      >
        <Translate i18nkey={props?.title} />
      </span>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "0.5rem",
        }}
      >
        <span
          style={{
            fontFamily: Fonts.Text,
            fontWeight: 600,
            fontSize: "0.8rem",

            alignSelf: "flex-start",
            color: props?.highlight
              ? getTailwindColor(value >= 0 ? "green" : "red", 500)
              : getTailwindColor("slate", 700),
          }}
        >
          {props?.isPercentage
            ? `${parseFloat(value)?.toFixed(0)}%`
            : formatAmount({
                amount: value,
              })}
        </span>
        {props?.isPercentage ? (
          <ChipView value={props?.value} type={props?.chipType} />
        ) : null}
      </div>
    </div>
  );
};

const ChipView = ({ value, type = "net" }) => {
  const { t } = useTranslation();
  let status = null;
  let color = "slate";

  if (type === "net") {
    if (value >= 80) {
      status = "Margin_Status_Excellent";
      color = "purple";
    }
    if (value >= 45 && value < 80) {
      status = "Margin_Status_Good";
      color = "green";
    }
    if (value >= 25 && value < 45) {
      status = "Margin_Status_Average";
      color = "slate";
    }
    if (value < 25) {
      status = "Margin_Status_Low";
      color = "rose";
    }
  } else {
    if (value >= 10) {
      status = "Margin_Status_Excellent";
      color = "purple";
    }
    if (value >= 6 && value < 10) {
      status = "Margin_Status_Good";
      color = "green";
    }
    if (value >= 4 && value < 6) {
      status = "Margin_Status_Average";
      color = "slate";
    }
    if (value < 4) {
      status = "Margin_Status_Low";
      color = "rose";
    }
  }

  if (!status || !value) return null;

  return (
    <span
      style={{
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "0.6rem",
        lineHeight: "0.5rem",
        fontWeight: 500,
        fontFamily: Fonts.Text,
        borderRadius: 16,
        paddingBlock: "0.2rem",
        paddingInline: "0.25rem",
        marginLeft: "0.25rem",
        color: getTailwindColor(color, 500),
        backgroundColor: getTailwindColor(color, 50),
        border: `1px solid ${getTailwindColor(color, 500)}`,
      }}
    >
      {t(status)}
    </span>
  );
};

const LogicFunctions = ({
  setData,
  setLoading,
  setIsFetching,
  widgetType,
  widget,
  profitType,
}) => {
  //redux
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);
  const use_global_categories = useSelector(
    (state) => state.boardSlice?.dataSetData?.use_global_categories
  );
  const _selectionCategories = useSelector(
    (state) => state.categorySlice?.selectionCategories
  );
  const selectionCategories = useMemo(() => {
    return _selectionCategories?.filter((o1) =>
      use_global_categories ? !o1?.dataset : o1?.dataset === dataSetData?.uuid
    );
  }, [_selectionCategories, dataSetData?.uuid, use_global_categories]);
  const scenarioByTitle = useSelector(
    (state) => state.globalSlice.scenarioByTitle
  );
  const start_date = widget?.start_date;
  const end_date = widget?.end_date;
  const BaseId = scenarioByTitle?.["Base"]?.[0]?.uuid;

  const WidgetData = useQuery({
    queryKey: [
      "transactions",
      {
        dataset: dataSetData?.uuid,
        apiType: "period_data",
        widget: {
          start_date: widget?.start_date,
          end_date: widget?.end_date,
        },
        widgetType,
      },
    ],
    queryFn: ({ signal }) => {
      let param = {
        config: {
          signal,
        },
        type: "monthly",
        dataset: dataSetData?.uuid,
        from_payment_date: start_date,
        to_payment_date: end_date,
        multiScenarioIds: [BaseId],
      };
      if (!dataSetData?.use_global_categories) {
        param.category_dataset = dataSetData?.uuid;
      } else {
        param.global_category = true;
      }
      const result = getStatisticsDataWithParams(param);

      if (result) {
        return result;
      }
    },
    backgroundFetch: true,
    enabled: !!start_date && !!end_date && !!dataSetData?.uuid && !!BaseId,
    priority: 3,
  });

  const selectionCategoriesByType = useMemo(
    () => _.groupBy(selectionCategories, "category_type"),
    [selectionCategories]
  );

  const getCategoryUuid = (data) => {
    let uuids = [];
    data?.forEach((key) => {
      selectionCategoriesByType?.[key]?.forEach((_category) => {
        uuids.push(_category?.uuid);
      });
    });
    return uuids;
  };

  useEffect(() => {
    setIsFetching(WidgetData?.isFetching);
    if (WidgetData?.isFetching) {
      setLoading(true);
    }
  }, [WidgetData?.isFetching]);

  useDebounce(
    () => {
      const periodTranData = WidgetData?.data?.results || [];
      if (WidgetData?.data && !WidgetData?.isFetching) {
        setLoading(true);
        if (periodTranData?.length > 0) {
          const BookedData = periodTranData?.filter((o) =>
            isBookedStates({ state: o.state })
          );
          const Revenue = BookedData?.reduce(
            (a, b) => a + parseFloat(b?.inflow || 0),
            0
          );
          const Expense = BookedData?.reduce(
            (a, b) => a + parseFloat(Math.abs(b?.outflow || 0)),
            0
          );
          let ExcludeExpenseCategory = [];
          if (profitType === "EBT") {
            ExcludeExpenseCategory = getCategoryUuid(["taxes"]);
          }
          if (profitType === "EBIT") {
            ExcludeExpenseCategory = getCategoryUuid(["taxes", "interest"]);
          }
          if (profitType === "EBITA/EBITDA") {
            ExcludeExpenseCategory = getCategoryUuid([
              "taxes",
              "interest",
              "depreciation & amortization",
            ]);
          }

          const ProfitExpense = BookedData?.filter(
            (o1) => !ExcludeExpenseCategory?.includes(o1?.category)
          )?.reduce((a, b) => a + parseFloat(Math.abs(b?.outflow || 0)), 0);

          const incomeCategory = getCategoryUuid([
            "Product",
            "service",
            "revenue stream",
          ]);
          const billableAmount = periodTranData
            ?.filter(
              (o1) =>
                Constant?.openPositionsStates.includes(o1.state) &&
                incomeCategory.includes(o1.category)
            )
            ?.reduce((a, b) => a + parseFloat(Math.abs(b?.inflow || 0)), 0);

          const plannedIncome = periodTranData
            ?.filter(
              (o1) =>
                Constant?.plannedState.includes(o1.state) &&
                incomeCategory.includes(o1.category)
            )
            ?.reduce((a, b) => a + parseFloat(Math.abs(b?.inflow || 0)), 0);

          const COGSIDS = getCategoryUuid(["cost of goods sold (cogs)"]);
          const CogsTotal = periodTranData
            ?.filter((o1) => COGSIDS.includes(o1.category))
            ?.reduce((a, b) => a + parseFloat(Math.abs(b?.outflow || 0)), 0);

          const overhead = Expense - CogsTotal;
          const netProfitMargin = Revenue - ProfitExpense / Revenue;
          const grossProfitMargin = Revenue - CogsTotal / Revenue;
          setData({
            Revenue,
            Expense,
            Profit: Revenue - ProfitExpense,
            billableAmount,
            plannedIncome,
            overhead,
            grossProfitMargin:
              grossProfitMargin >= 100
                ? 100
                : grossProfitMargin < -100
                  ? -100
                  : grossProfitMargin || 0,
            netProfitMargin:
              netProfitMargin >= 100
                ? 100
                : netProfitMargin < -100
                  ? -100
                  : netProfitMargin || 0,
          });
        } else {
          setData(null);
        }
        setTimeout(() => {
          setLoading(false);
        }, 250);
      }
    },
    200,
    [
      WidgetData?.data,
      WidgetData?.isFetching,
      start_date,
      end_date,
      selectionCategories,
      profitType,
    ],
    true
  );
};
