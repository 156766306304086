import {
  FormControlLabel,
  FormControl,
  RadioGroup,
  Typography,
  Radio,
  Stack,
  Box,
} from "@mui/material";
import {
  useImperativeHandle,
  forwardRef,
  useEffect,
  useState,
  useRef,
} from "react";
import { Link as NavigateLink, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { TwitterPicker } from "react-color";
import { useSnackbar } from "notistack";
import { v4 as v4uuid } from "uuid";
import { produce } from "immer";

import {
  getTransactionByParams,
  isValidTitle,
  cloneDeep,
} from "../../../Helper/data";
import {
  setPopupStatus3,
  setPopupStatus4,
} from "../../../store/slices/datasets";
import useUpdateEffect from "../../../hooks/4-useUpdateEffect/useUpdateEffect";
import DescriptionInput from "../../../components/Overlay/DescriptionInput";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import CustomModal from "../../../components/Model/CustomModal";
import TitleInput from "../../../components/Overlay/TitleInput";
import { setCategories } from "../../../store/slices/category";
import { setRefreshData } from "../../../store/slices/appmain";
import useSubscriptions from "../../../hooks/useSubscriptions";
import DropDown from "../../../components/Overlay/DropDown";
import { collectUUIDs } from "../../../Helper/functions";
import ThemeTabs from "../../../components/ThemeTabs";
import Translate from "../../../hooks/HOC/Translate";
import EndPoints from "../../../APICall/EndPoints";
import { queryClient } from "../../../App";
import RuleModel from "../Rules/RuleModel";
import { Color } from "../../../Helper";
import APICall from "../../../APICall";
import { tree } from "./Tree/data";
import theme from "../../../theme";

const overlayTabOptions = [
  {
    value: 1,
    label: "General_Category_Settings",
  },
  {
    value: 2,
    label: "Advanced_Category_Settings",
  },
];

const CategoryCommonFunctions = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [isSubscriptionValid] = useSubscriptions();
  const location = useLocation();
  let abortController = new AbortController();
  let aborted = abortController.signal.aborted;
  const sourceCategory = useRef(null);
  const overlayPayload = useRef(null);
  const isCategoryUpdated = useRef(false);

  //redux
  const categories = useSelector((state) => state.categorySlice?.categories);
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);
  const use_global_categories = useSelector(
    (state) => state.boardSlice?.dataSetData?.use_global_categories
  );
  const dataset = useSelector((state) => state.boardSlice?.dataSetData?.uuid);

  //state
  const [isSettingOpen, setIsSettingOpen] = useState(false);
  const [openRule, setOpenRule] = useState(false);

  const sourceCategoryTransactions = useQuery({
    queryKey: ["category", { category: sourceCategory.current }],
    queryFn: ({ signal }) => {
      let params = {
        config: {
          signal: signal,
        },
        dataset: dataSetData?.uuid,
        page: 1,
        page_size: 5,
        order_by: "-due_date",
        category: [sourceCategory.current],
      };

      const result = getTransactionByParams(params);
      if (result) {
        return result;
      }
    },
    backgroundFetch: true,
    enabled: false,
  });

  useEffect(() => {
    return () => {
      if (isCategoryUpdated.current) {
        getCategories();
      }
      abortController.abort();
    };
  }, []);

  useUpdateEffect(() => {
    if (props?.value && dataset && isCategoryUpdated.current) {
      if (props?.setLoading) props?.setLoading(true);
      getCategories();
    }
  }, [props?.value]);

  useImperativeHandle(
    ref,
    () => ({
      openOverlay,
    
      onClickDeleteCategory,
      onClickAddNewCategory,
      onClickAddSubCategory,
      onClickCloneCategory,
      onClickVisibleCategory,
      createNewRules,
      batchUpdateCategory,
      getSourceCategoryTransactions: () => {
        return sourceCategoryTransactions;
      },
      fetchCategoryTransactions: (_category) => {
        sourceCategory.current = _category;
        sourceCategoryTransactions.refetch();
      },
    }),
    [sourceCategoryTransactions]
  );

  //Api
  const deleteCategoryByIdApi = async (item) => {
    await APICall("delete", EndPoints.category + `${item?.uuid}/`, null, {
      doNotCatchRespond: true,
    }).then((response) => {
      if (response.status === 204 && response) {
        getCategoryByIdToCheckDelete(item);
      } else {
        if (response?.data?.detail) {
          dispatch(setPopupStatus3(null));
          enqueueSnackbar(t(response?.data?.detail), {
            variant: "error",
            autoHideDuration: 5000,
          });
        }
      }
    });
  };

  const getCategoryByIdToCheckDelete = async (item) => {
    await APICall("get", EndPoints.category + `${item?.uuid}/`, null, {
      doNotCatchRespond: true,
    }).then(async (response) => {
      if (aborted === false) {
        if (response.status === 404) {
          await getCategories();
          dispatch(setPopupStatus3(null));
          dispatch(setPopupStatus4(null));
          enqueueSnackbar(t("Category_Deleted_Successfully"), {
            variant: "success",
            autoHideDuration: 2000,
          });
          resetkanban();
        } else {
          setTimeout(() => {
            getCategoryByIdToCheckDelete(item);
          }, 2000);
        }
      }
    });
  };

  const addCategoryApi = async (obj, type = "add", isAssigned, targetId) => {
    if (props?.isCategorySaving) {
      props.isCategorySaving.current = true;
    }

    await APICall("post", EndPoints.category, obj, {
      doNotCatchRespond: true,
    }).then((response) => {
      if (response.status === 201 && response.data) {
        let updatedFlattenData = null;
        if (type === "clone") {
          const updatedData = tree.insertAfter(
            props?.data,
            targetId,
            response?.data
          );
          props?.setData(updatedData);
          updatedFlattenData = tree.flattenTree(updatedData);
          // dispatch(setCategories({ type: type, newItem: response.data }));
          enqueueSnackbar(t("Category_Duplicated_Successfully"));
          if (props?.isCategorySaving) {
            props.isCategorySaving.current = false;
          }
        } else if (type === "sub") {
          if (isAssigned) {
            getCategoryByIdToCheckAdd(response?.data?.uuid);
          } else {
            const updatedData = tree.insertChild(
              props?.data,
              response?.data?.parent,
              response?.data
            );
            props?.setData(updatedData);
            updatedFlattenData = tree.flattenTree(updatedData);
            enqueueSnackbar(t("Sub_Category_Added_Successfully"));
          }
          if (updatedFlattenData) {
            let batchArray = [];
            updatedFlattenData?.forEach((item) => {
              batchArray.push({
                uuid: item?.uuid,
                expanded: item?.expanded,
                position: item.position,
                parent: item.parent,
                // title: item.title,
              });
            });
            batchUpdateCategory({
              payload: batchArray,
              doNotUpdate: true,
            });
          }
        }
      }
      if (response.status === 400 && response.data) {
        if (response?.data?.title?.[0]) {
          enqueueSnackbar(t(response?.data?.title?.[0]), {
            variant: "error",
            autoHideDuration: 4000,
          });
        }
      }
    });
  };

  const getCategoryByIdToCheckAdd = async (UUID) => {
    await APICall("get", EndPoints.category + `${UUID}/`, null, {
      doNotCatchRespond: true,
    }).then(async (response) => {
      if (aborted === false) {
        if (response.data && !response.data?.is_being_created) {
          await getCategories();
          resetkanban();
          enqueueSnackbar(t("Sub_Category_Added_Successfully"));
          dispatch(setPopupStatus3(null));

          if (props?.isCategorySaving) {
            props.isCategorySaving.current = false;
          }
        } else {
          setTimeout(() => {
            getCategoryByIdToCheckAdd(UUID);
          }, 2000);
        }
      }
    });
  };

  const getCategories = async () => {
    // let endUrl = "";
    // if (!use_global_categories) {
    //   endUrl = `?dataset=${dataset}`;
    // } else {
    //   endUrl = `?is_global=true`;
    // }
    await APICall("get", EndPoints.category).then((response) => {
      if (response.status === 200 && response.data) {
        isCategoryUpdated.current = false;
        if (props?.setIsSaving) {
          props?.setIsSaving(false);
        }
        if (props?.isCategorySaving) {
          props.isCategorySaving.current = false;
        }
        dispatch(setCategories({ data: response.data.results, type: "all" }));
      }
    });
  };

  const batchUpdateCategory = async ({
    payload,
    doNotUpdate,
    preventClose,
  }) => {
    if (props?.setIsSaving) {
      props?.setIsSaving(true);
    }
    if (props?.isCategorySaving) {
      props.isCategorySaving.current = true;
    }
    await APICall("put", EndPoints.category + `batch_update/`, payload).then(
      async (response) => {
        if (response.status === 200 && response.data) {
          if (preventClose) {
            preventClose.current = false;
          }

          if (!doNotUpdate) {
            await getCategories();
          } else {
            if (props?.setIsSaving) {
              props?.setIsSaving(false);
            }
            if (props?.isCategorySaving) {
              props.isCategorySaving.current = false;
            }
            isCategoryUpdated.current = true;
          }
        }
      }
    );
  };

  const updateCategoryByID = async (id, obj) => {
    await APICall("patch", EndPoints.category + `${id}/`, obj, {
      doNotCatchRespond: true,
    }).then((response) => {
      if (response.status === 200 && response.data) {
        dispatch(setCategories({ type: "update", newItem: response.data }));
      }
      if (response.status === 400 && response?.data?.title?.[0]) {
        enqueueSnackbar(t(response.data?.title?.[0]), {
          variant: "error",
        });
      }
      if (response.status === 400 && response?.data?.visible?.[0]) {
        enqueueSnackbar(t(response.data?.visible?.[0]), {
          variant: "error",
        });
      }
    });
  };

  //functions
  const resetkanban = () => {
    global.allowFetch = { Inflow: [], Outflow: [] };
    if (location?.pathname?.includes("/list/bulk")) {
      dispatch(setRefreshData(Date.now()));
    } else {
      const options = {
        predicate: (query) =>
          query.queryKey[0] === "transactions" ||
          query.queryKey[0] === "staff" ||
          query.queryKey[0] === "List" ||
          query.queryKey[0] === "rules",
      };
      queryClient.resetQueries(options, {
        cancelRefetch: true,
      });
      dispatch(setRefreshData(Date.now()));
    }
  };

  const onClickDeleteCategory = async (e, item) => {
    e.stopPropagation();
    if (!props?.isCategorySaving?.current) {
      dispatch(
        setPopupStatus3({
          open: true,
          overlay_type: "delete",
          onConfirm: onOkDelete,
          loading: false,
          disableBackdropClick: true,
          payload: {
            title: t("Attention"),
            message: item?.parent ? (
              <Translate i18nkey={`delete_sub_category_warn`} />
            ) : (
              <Translate i18nkey={`delete_category_warn`} />
            ),
            confirmText: t("Ok"),
            // hideCancel: true,
            item: item,
          },
        })
      );
    }
  };

  const onOkDelete = ({ payload }) => {
    dispatch(setPopupStatus3({ key: "loading", value: true }));
    deleteCategoryByIdApi(payload?.item);
  };

  const onClickAddNewCategory = (e, payload) => {
    e.stopPropagation();
    const { value, setValue, categoryData } = payload;
    if (
      isSubscriptionValid({ showMessage: true }) &&
      categoryData &&
      !props?.isCategorySaving?.current
    ) {
      const maxPosition = categoryData?.reduce(
        (max, { position }) => Math.max(max, position),
        0
      );
      const alreadyAddedTitles = categoryData?.map((o1) => o1.title);
      let count = categoryData?.length + 1;
      let title = `${t("Category")} ${count}`;
      title = isValidTitle({
        alreadyAddedTitles,
        title,
        count,
        node: { title: t("Category") },
      });
      const obj = {
        title: title,
        type: Number(value),
        parent: "",
        tax: null,
        position: maxPosition + 1,
        color: theme.palette.primary.main,
        children: [],
        expanded: false,
        dataset: use_global_categories ? null : dataset,
      };
      openOverlay({
        selectedItem: obj,
        type: value,
        modalType: "add",
        setValue,
      });
    }
  };

  const onClickAddSubCategory = async (e, item) => {
    e.stopPropagation();
    if (
      isSubscriptionValid({ showMessage: true }) &&
      !props?.isCategorySaving?.current
    ) {
      const id = v4uuid();
      const alreadyAddedTitles = item?.children?.map((o1) => o1.title);
      let count = item?.children?.length + 1;
      let title = `${item.title} ${count}`;
      title = isValidTitle({ alreadyAddedTitles, title, count, node: item });
      const obj = {
        uuid: id,
        title: title,
        type: item.type,
        parent: item.uuid,
        tax: null,
        position: 0,
        color: item.color,
        dataset: use_global_categories ? null : dataset,
        children: [],
      };

      if (item?.has_transactions) {
        dispatch(
          setPopupStatus3({
            open: true,
            overlay_type: "delete",
            onConfirm: onConfirmMove,
            payload: {
              title: t("Attention"),
              message: t(`sub_add_category_warn_message`),
              confirmText: t("Move"),
              obj,
            },
          })
        );
      } else {
        addCategoryApi(obj, "sub");
      }
    }
  };

  const onClickCloneCategory = (e, item) => {
    e.stopPropagation();
    if (
      isSubscriptionValid({ showMessage: true }) &&
      !props?.isCategorySaving?.current
    ) {
      let obj = {
        title: `Copy_of_${item?.title}`,
        position: Number(item?.position) + 1,
        color: item?.color,
        type: item?.type,
        parent: item?.parent,
        tax: item?.tax,
        dataset: use_global_categories ? null : dataset,
        children: [],
      };
      addCategoryApi(obj, "clone", null, item?.uuid);
      e.stopPropagation();
    }
  };

  const onClickVisibleCategory = async (e, item) => {
    e.stopPropagation();
    if (!props?.isCategorySaving?.current) {
      if (item?.visible) {
        let batchArray = [];
        let uuids = [];
        collectUUIDs(item, uuids);

        if (item?.has_transactions || item?.children?.length > 0) {
          enqueueSnackbar(
            t(
              "Cannot hide this category because it has associated transactions."
            ),
            {
              variant: "error",
              autoHideDuration: 4000,
            }
          );
          return;
        } else {
          const updatedData = produce(categories, (draft) => {
            draft.forEach((element) => {
              if (uuids.includes(element.uuid)) {
                batchArray.push({
                  uuid: element.uuid,
                  visible: false,
                });
                element.visible = false;
              }

              if (element?.children?.length > 0) {
                element.children.forEach((childElement) => {
                  if (uuids.includes(childElement.uuid)) {
                    batchArray.push({
                      uuid: childElement.uuid,
                      visible: false,
                    });
                    childElement.visible = false;
                  }

                  if (childElement?.children?.length > 0) {
                    childElement.children.forEach((subChildElement) => {
                      if (uuids.includes(subChildElement.uuid)) {
                        batchArray.push({
                          uuid: subChildElement.uuid,
                          visible: false,
                        });
                        subChildElement.visible = false;
                      }
                    });
                  }
                });
              }
            });
          });
          dispatch(
            setCategories({
              data: cloneDeep(updatedData),
              type: "all",
            })
          );
          batchUpdateCategory({ payload: batchArray, doNotUpdate: true });
        }
      } else {
        const obj = { visible: !item.visible };
        updateCategoryByID(item?.uuid, obj);
      }
    }
  };

  const onConfirmMove = async ({ payload }) => {
    dispatch(setPopupStatus3({ key: "loading", value: true }));
    await addCategoryApi(payload?.obj, "sub", true);
  };

  const openOverlay = (obj) => {
    overlayPayload.current = obj;
    setIsSettingOpen(true);
  };



  const closeOverlay = () => {
    setIsSettingOpen(false);
  };

  const createNewRules = async (payload) => {
    if (payload && !props?.isCategorySaving?.current) {
      const { transactionsRow, preventClose } = payload;
      overlayPayload.current = {
        transactionsRow,
        preventClose,
      };
      if (preventClose) {
        preventClose.current = true;
      }
      setOpenRule(true);
    }
  };

  return (
    <>
      {openRule && (
        <RuleModel
          setOpen={setOpenRule}
          open={openRule}
          transactionsRow={overlayPayload?.current?.transactionsRow}
          preventClose={overlayPayload?.current?.preventClose}
        />
      )}
      {isSettingOpen && (
        <CategoriesEditModal
          onClose={closeOverlay}
          batchUpdateCategory={batchUpdateCategory}
          data={props?.data}
          setData={props?.setData}
          {...overlayPayload?.current}
        />
      )}
      
    </>
  );
});

export default CategoryCommonFunctions;

const CategoriesEditModal = ({
  data,
  setData,
  onClose,
  modalType,
  selectedItem,
  type,
  setValue,
  preventClose,
  allowUpdateCategory,
  batchUpdateCategory,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  let category_type = useRef([]);
  let updatedItem = useRef({});

  //state
  const [loading, setLoading] = useState(false);
  const [settingTab, setSettingTab] = useState(1);
  const [error, setError] = useState(null);
  const [payment_term, setPaymentTerm] = useState([]);
  const [categoryItem, setCategoryItem] = useState(selectedItem);

  //redux
  // const dataSetList = useSelector((state) => state.boardSlice?.dataSetList);

  //lifecycle
  useEffect(() => {
    if (settingTab === 2 && payment_term?.length === 0) {
      getCategoriesOptions();
    }
  }, [settingTab]);

  //api
  const getCategoriesOptions = async () => {
    await APICall("OPTIONS", EndPoints.category).then((response) => {
      if (response.status === 200 && response.data) {
        category_type.current = {
          1: response.data.actions.POST.category_type.choices?.filter(
            (o1, index) => index <= 4
          ),
          2: response.data.actions.POST.category_type.choices?.filter(
            (o1, index) => index > 4
          ),
        };
        setPaymentTerm(response.data.actions.POST.payment_term.choices);
      }
    });
  };

  const updateCategoryByID = async (id, obj) => {
    await APICall("patch", EndPoints.category + `${id}/`, obj).then(
      (response) => {
        if (response.status === 200 && response.data) {
          getCategories();
          setTimeout(() => {
            handleClose();
          }, 1000);
          if (allowUpdateCategory) allowUpdateCategory.current = true;
        }
        if (response.status === 400 && response?.data?.title?.[0]) {
          enqueueSnackbar(t(response.data?.title?.[0]), {
            variant: "error",
          });
        }
        if (response.status === 400 && response?.data?.visible?.[0]) {
          enqueueSnackbar(t(response.data?.visible?.[0]), {
            variant: "error",
          });
        }
      }
    );
  };

  const addCategoryApi = async (obj) => {
    await APICall("post", EndPoints.category, obj, {
      doNotCatchRespond: true,
    }).then(async (response) => {
      if (response.status === 201 && response.data) {
        enqueueSnackbar(t("New_Category_Added_Successfully"));
        const updatedData = tree.insertBefore(
          data,
          data?.[0]?.uuid,
          response?.data
        );
        setData(updatedData);
        const updatedFlattenData = tree.flattenTree(updatedData);
        if (updatedFlattenData) {
          let batchArray = [];
          updatedFlattenData?.forEach((item) => {
            batchArray.push({
              uuid: item?.uuid,
              expanded: item?.expanded,
              position: item.position,
              parent: item.parent,
              // title: item.title,
            });
          });
          batchUpdateCategory({
            payload: batchArray,
          });
        }
        setTimeout(() => {
          onClose();
        }, 0);
        // if (allowUpdateCategory) allowUpdateCategory.current = true;
      }
      if (response.status === 400 && response.data) {
        if (response?.data?.title?.[0]) {
          enqueueSnackbar(t(response?.data?.title[0]), {
            variant: "error",
            autoHideDuration: 4000,
          });
        }
      }
    });
  };

  const getCategories = async () => {
    // let endUrl = "";
    // if (!use_global_categories) {
    //   endUrl = `?dataset=${dataset}`;
    // } else {
    //   endUrl = `?is_global=true`;
    // }
    await APICall("get", EndPoints.category)
      .then((response) => {
        if (response.status === 200 && response.data) {
          dispatch(setCategories({ data: response.data.results, type: "all" }));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  //functions
  const onClickAction = async () => {
    if (modalType === "edit") {
      if (!error?.title && JSON.stringify(updatedItem.current) !== "{}") {
        setLoading(true);
        await updateCategoryByID(selectedItem?.uuid, updatedItem.current);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } else {
        handleClose();
      }
      if (preventClose) {
        setTimeout(() => {
          preventClose.current = false;
        }, 1000);
      }
    } else {
      if (!error?.title) {
        setLoading(true);
        await addCategoryApi({ ...categoryItem, position: 0 });

        setLoading(false);
        if (preventClose) {
          setTimeout(() => {
            preventClose.current = false;
          }, 1000);
        }
      }
    }
  };

  const handleClose = () => {
    if (error) setError(null);
    if (onClose) onClose();
    if (preventClose) {
      setTimeout(() => {
        preventClose.current = false;
      }, 1000);
    }
  };

  const handleChangeDropDown = (event, value, name) => {
    const obj = {
      [name]: value?.value || value?.uuid || null,
    };
    updatedItem.current[name] = obj[name];
    setCategoryItem({ ...categoryItem, ...obj });
  };

  const handleChangeRadio = (event) => {
    let value = event.target.value;
    setCategoryItem({
      ...categoryItem,
      sale_tax_declaration_date: value || null,
    });
    updatedItem.current["sale_tax_declaration_date"] = value || null;
  };

  //render function
  const advanceSettingView = () => {
    const categoryTypeOptions = category_type.current[type] || [];

    const categoryTypeValue =
      categoryTypeOptions?.find(
        (o1) => o1.value === categoryItem?.category_type
      ) || null;
    // const categoryDataValue =
    //   dataSetList?.find((o1) => o1.uuid === categoryItem?.dataset) || null;

    return (
      <>
        <DropDown
          hideTitle
          likeGoogle
          variant="filled"
          label={t("category_type")}
          options={categoryTypeOptions}
          value={categoryTypeValue}
          onChange={(e, value) =>
            handleChangeDropDown(e, value, "category_type")
          }
          getOptionLabel={(option) => t(option?.display_name) ?? ""}
          renderOption={(option) => t(option?.display_name) ?? ""}
          sx={{ width: "50%" }}
        />
        <Typography
          fontWeight={"fontWeightMediumBold"}
          color="color.mainTitle"
          sx={{
            display: "inline-flex",
            fontSize: "0.9rem",
            ml: "0.75rem",
          }}
        >
          {t("Date_of_sales_tax_return")}
        </Typography>
        <FormControl sx={{ ml: "0.75rem" }}>
          <RadioGroup
            row
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={categoryItem?.sale_tax_declaration_date}
            onChange={handleChangeRadio}
          >
            <FormControlLabel
              value="at payment"
              control={<Radio />}
              label={t("At_payment")}
            />
            <FormControlLabel
              value="at invoice"
              control={<Radio />}
              label={t("At_invoice")}
            />
          </RadioGroup>
        </FormControl>
        <Typography
          fontWeight={"fontWeightMediumBold"}
          color="color.slate.400"
          sx={{
            display: "block",
            fontSize: "0.9rem",
            mt: "0.5rem",
            ml: "0.75rem",
          }}
        >
          {t("payment_frequency")}
          <NavigateLink
            to={{
              pathname: "/settings/vats",
              search: "",
            }}
            state={{ fromCategories: true }}
            style={{ color: theme.palette.primary.main, marginLeft: "0.5rem" }}
            replace
          >
            {t("Advanced_settings")}
          </NavigateLink>
        </Typography>
        <Stack direction={"row"} alignItems={"center"} sx={{ mt: "1.75rem" }}>
          <DropDown
            hideTitle
            likeGoogle
            variant="filled"
            label={t("Paymentterm")}
            disableClearable
            options={payment_term || []}
            value={
              payment_term?.find(
                (o1) => o1.value === categoryItem?.payment_term
              ) || null
            }
            onChange={(e, value) =>
              handleChangeDropDown(e, value, "payment_term")
            }
            getOptionLabel={(option) => option?.display_name ?? ""}
            renderOption={(option) => option?.display_name ?? ""}
            sx={{ width: "50%" }}
          />
          {/* <DropDown
            hideTitle
            likeGoogle
            variant="filled"
            label={t("dataset")}
            options={dataSetList}
            value={categoryDataValue}
            onChange={(e, value) => handleChangeDropDown(e, value, "dataset")}
            getOptionLabel={(option) => t(option?.title) ?? ""}
            renderOption={(option) => t(option?.title) ?? ""}
            sx={{ width: "50%" }}
          /> */}
        </Stack>

        <Typography
          fontWeight={"fontWeightMediumBold"}
          color="color.slate.400"
          sx={{
            display: "block",
            fontSize: "0.9rem",
            mt: "0.5rem",
            ml: "0.75rem",
          }}
        >
          {t("invoice_note")}
        </Typography>
      </>
    );
  };

  return (
    <CustomModal
      open
      hideClose
      textAdd="Save"
      loadingAdd={loading}
      modalType={modalType}
      onAdd={onClickAction}
      onClose={handleClose}
      heading={
        settingTab === 1
          ? t("General_Category_Settings")
          : t("Advanced_Category_Settings")
      }
    >
      <ThemeTabs
        tab={settingTab}
        setTab={setSettingTab}
        options={overlayTabOptions}
        sx={{
          ml: "2rem",
          mt: "1rem",
        }}
      />

      <Box
        sx={{
          display: "flex",
          backgroundColor: theme.palette.color.white,
          flexDirection: "column",
          width: "45rem",
          height: "fit-content",
          py: "2rem",
          px: "1.25rem",
        }}
      >
        {settingTab === 1 ? (
          <GeneralSettingView
            error={error}
            setError={setError}
            categoryItem={categoryItem}
            setCategoryItem={setCategoryItem}
            handleChangeDropDown={handleChangeDropDown}
            updatedItem={updatedItem}
          />
        ) : (
          advanceSettingView()
        )}
      </Box>
    </CustomModal>
  );
};

const GeneralSettingView = ({
  categoryItem,
  setCategoryItem,
  error,
  setError,
  updatedItem,
  handleChangeDropDown,
}) => {
  const { t } = useTranslation();
  const wrapperRef = useRef(null);

  const vat = useSelector((state) => state.globalSlice.vat);
  const [showColorPallets, setShowColorPallets] = useState(false);

  //function
  const handleEditChange = (e) => {
    const { name, value } = e.target;
    let errorText = null;
    if (name === "title" && value.trim() === "") {
      errorText = "empty_title_error";
    }
    setError({ ...error, [name]: t(errorText) });
    setCategoryItem({ ...categoryItem, [name]: value });
    updatedItem.current[name] = value;
  };

  const onClickColor = () => {
    setShowColorPallets(!showColorPallets);
  };

  const handleColorChangeComplete = (color) => {
    setCategoryItem({ ...categoryItem, color: color.hex });
    updatedItem.current["color"] = color.hex;
  };

  useOnClickOutside(wrapperRef, () => {
    setShowColorPallets(false);
  });

  return (
    <>
      <TitleInput
        value={
          categoryItem?.immutable
            ? t(categoryItem?.title)
            : categoryItem?.title || ""
        }
        name="title"
        onChange={handleEditChange}
        helperText={error?.title}
        error={Boolean(error?.title)}
        disabled={categoryItem?.immutable}
        label={t("Title")}
        hideTitle
        likeGoogle
        variant="filled"
        sx={{ width: "100%" }}
      />
      <DropDown
        hideTitle
        likeGoogle
        variant="filled"
        label={t("Value_Added_Tax")}
        value={vat?.find((o1) => o1.uuid === categoryItem?.tax) || null}
        onChange={(e, value) =>
          handleChangeDropDown(e, { value: value?.uuid }, "tax")
        }
        options={vat}
        getOptionLabel={(option) =>
          `${option?.title} (${option?.value}%)` ?? ""
        }
        renderOption={(option) => `${option?.title} ` ?? ""}
        sx={{ width: "50%" }}
        mb={0}
      />
      <Typography
        variant="h6"
        component={"h6"}
        fontWeight={"fontWeightMediumBold"}
        color="color.mainTitle"
        sx={{
          display: "flex",
          width: "100%",
          textAlign: "left",
          mt: "1rem",
          mb: "0.25rem",
          ml: "0.75rem",
          fontSize: "0.8rem",
        }}
      >
        {t("Colour")}
      </Typography>
      <Box
        onClick={onClickColor}
        sx={{
          height: "2rem",
          width: "48.5%",
          backgroundColor:
            categoryItem?.color ?? theme.palette.color.slate[500],
          borderRadius: theme.borderRadius.main,
          mb: "3rem",
          cursor: "pointer",
          ml: "0.75rem",
        }}
      />
      {showColorPallets ? (
        <Box
          ref={wrapperRef}
          sx={{
            position: "absolute",
            right: "12%",
            bottom: "16%",
            "& .twitter-picker": {
              zIndex: 1,
            },
          }}
        >
          <TwitterPicker
            triangle="hide"
            colors={Color?.categoryTheme}
            color={categoryItem?.color ?? Color.categoryTheme[0]}
            onChangeComplete={handleColorChangeComplete}
            styles={{ hash: { display: "none", color: "red" } }}
          />
        </Box>
      ) : null}
      <DescriptionInput
        value={categoryItem?.note || ""}
        onChange={handleEditChange}
        name="note"
        likeGoogle
        variant="filled"
        hideTitle
        fontSize={"0.9rem"}
        tooltip={t("Note_Optional")}
        label={t("Note_Optional")}
        sx={{ width: "100%" }}
      />
    </>
  );
};
