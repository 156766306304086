import React, { useEffect, useState } from "react";

import FilterOverlay from "./FilterOverlay";
import CustomPopover from "../../PopOver";

const AddFilterPopOver = ({
  anchorEl,
  onClose,
  selectedIds,
  color = "slate",
  options,
}) => {
  const [_selectedIds, setSelectedIds] = useState(selectedIds);
  const [searchText, setSearchText] = useState("");

  const open = Boolean(anchorEl);
  const onChangeValue = (selectedIds, isNewAdded) => {
    setSelectedIds(selectedIds);
    if (isNewAdded) {
      onClose(selectedIds);
    }
  };

  const onClosePopOver = () => {
    onClose(_selectedIds);
  };

  useEffect(() => {
    if (open) {
      setSelectedIds(selectedIds);
    }
  }, [selectedIds, open]);

  return (
    <CustomPopover
      type="anchor"
      anchorEl={anchorEl}
      onClose={onClosePopOver}
      showRight
    >
      <FilterOverlay
        selectedIds={_selectedIds}
        options={options}
        anchorEl={anchorEl}
        color={color}
        translate
        onChangeValue={onChangeValue}
        searchText={searchText}
        setSearchText={setSearchText}
        searchType="client"
      />
    </CustomPopover>
  );
};

export default AddFilterPopOver;
