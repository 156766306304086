import {
  eachMonthOfInterval,
  differenceInMonths,
  startOfMonth,
  endOfMonth,
  addMonths,
  subMonths,
  isValid,
  format,
} from "date-fns";
import {
  LinearProgress,
  IconButton,
  Typography,
  useTheme,
  Divider,
  styled,
  Paper,
  Stack,
  Grid,
} from "@mui/material";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import { useQuery } from "@tanstack/react-query";
import EditIcon from "@mui/icons-material/Edit";
import { useTranslation } from "react-i18next";
import { DataGrid } from "@mui/x-data-grid";
import _ from "underscore";

import {
  getStatisticsDataWithParams,
  getCategoryParentId,
  remToPx,
} from "../../../../Helper/data";
import {
  setContactOverlayStatus,
  setPopupStatus2,
} from "../../../../store/slices/datasets";
import {
  ContactTypeCell,
  DateCell,
} from "../../../../components/MuiTableCells";
import useDebounce from "../../../../hooks/3-useDebounce/useDebounce";
import ComponentLoader from "../../../../components/ComponentLoader";
import { GlobalContext } from "../../../../GlobalContextWrapper";
import MenuView from "../../../../components/Overlay/MenuView";
import useStatusHook from "../../../../hooks/useStatusHook";
import AreaChartView from "../Component/AreaChartView";
import useWidth from "../../../../hooks/useWidth";
import PieWidget from "../Component/PieWidget";
import store from "../../../../store";

const StyledPaper = styled(Paper)(
  ({ theme, filteredContacts, width = "100%" }) => ({
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 16%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    width: width,
    borderRadius: theme.borderRadius.main,
    backgroundColor: theme.palette.color.white,
    minHeight: "inherit",
    height: filteredContacts?.length <= 0 ? "inherit" : "fit-content",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: filteredContacts?.length > 0 ? "flex-start" : "center",
    justifyContent: filteredContacts?.length > 0 ? "flex-start" : "center",
    ...theme.thinScrollBar,
    "& .MuiDataGrid-root": {
      height: "inherit",
    },
    "& .MuiDataGrid-overlay": {
      zIndex: 1,
    },
    "& .MuiDataGrid-columnHeaders": {
      border: 0,
    },
    "& .MuiDataGrid-columnHeader": {
      outline: "none",
    },
    "& .MuiDataGrid-columnHeader:focus": {
      outline: "none",
    },
    "& .MuiDataGrid-row": {
      cursor: "pointer",
    },
    "& .MuiDataGrid-cell:focus-within": {
      outline: "none",
    },
    "& .MuiDataGrid-cell:focus": {
      outline: "none",
    },
    "& .MuiDataGrid-virtualScroller": {
      width: width,
      position: "absolute",
      left: 0,
      ...theme.thinScrollBar,
    },
    "& .MuiDataGrid-cell": {
      borderColor: theme.palette.color.slate[100],
      padding: "0 6px",
    },
    "& .divider-cell": {
      backgroundColor: theme.palette.color.green[300],
    },
  })
);

const ClientList = ({ width, HEIGHT }) => {
  const globalContext = useContext(GlobalContext);
  const currentWidth = useWidth();
  const listHeight = 18 * remToPx(currentWidth, HEIGHT);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const timer = useRef(0);
  const prevent = useRef(false);
  const delay = useRef(200);
  const itemRef = useRef(null);
  const clientFunctionRef = globalContext?.clientFunctionRef;

  //state
  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const [filteredContacts, setContactsRows] = useState([]);
  const [isFetching, setFetching] = useState(false);

  //functions
  const onCellDoubleClick = () => {
    clearTimeout(timer.current);
    prevent.current = true;
  };

  const onRowClick = (params) => {
    timer.current = setTimeout(async () => {
      if (!prevent.current) {
        const item = params.row;

        dispatch(
          setPopupStatus2({
            open: true,
            overlay_type: "drawer_modal",
            anchor: "right",
            payload: {
              hideAccessInListViewFilter: true,
              hideHeaderActions: true,
              // overlayTab: "category",
              hideScenarioChain: true,
              cell: {
                contact: params?.row?.uuid,
                withUrl: true,
                hideEditFormTabBar: true,
                clientType: params?.row?.type,
                isClientView: true,
                clientName: item?.name,
              },
            },
          })
        );
      }
      prevent.current = false;
    }, delay.current);
  };

  const handleClickMore = (e, params) => {
    e?.stopPropagation();
    itemRef.current = params.row;
    setAnchorElMenu(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  const onCellEditCommit = (params) => {
    clientFunctionRef?.current?.updateClientByID({
      ...params?.row,
      [params?.field]: params?.value,
    });
  };

  const onClickItem = (e, value) => {
    if (value === 1) {
      const updatedValue = itemRef.current?.state === 1 ? 2 : 1;
      clientFunctionRef?.current?.updateClientByID({
        uuid: itemRef.current?.uuid,
        type: itemRef.current?.type,
        state: updatedValue,
      });
      setAnchorElMenu(null);
    }
    if (value === 2) {
      setAnchorElMenu(null);
      clientFunctionRef?.current?.deleteClientApi({
        uuid: itemRef.current?.uuid,
        type: itemRef.current?.type,
      });
    }
    if (value === 3) {
      setAnchorElMenu(null);
      dispatch(
        setContactOverlayStatus({
          open: true,
          overlay_type: "drawer_modal_contact_edit_form",
          anchor: "right",
          payload: {
            modalType: "edit",
            item: itemRef.current,
          },
        })
      );
    }
  };

  const columns = [
    {
      field: "name",
      headerName: t("Title"),
      flex: 1,
      editable: true,
    },
    {
      field: "type",
      headerName: t("type"),
      flex: 1,
      editable: false,
      renderCell: (params) => (
        <ContactTypeCell {...params} theme={theme} t={t} />
      ),
    },
    {
      field: "note",
      headerName: t("Note"),
      flex: 1,
      editable: true,
      sortable: false,
    },
    {
      field: "state",
      headerName: t("State"),
      flex: 1,
      editable: false,
      sortable: false,
      renderCell: (params) => <StateView params={params} theme={theme} t={t} />,
    },
    {
      field: "creation_date",
      headerName: t("creation_date"),
      flex: 1,
      editable: false,
      renderCell: (params) => (
        <DateCell
          {...params}
          theme={theme}
          formatString="dd MMM yyyy, hh:mm a"
        />
      ),
    },
    {
      field: "last_modified_date",
      headerName: t("last_modified_date"),
      flex: 1,
      editable: false,
      renderCell: (params) => (
        <DateCell
          {...params}
          theme={theme}
          formatString="dd MMM yyyy, hh:mm a"
        />
      ),
    },
    {
      field: "actions",
      headerName: t("Actions"),
      flex: 0.3,
      editable: false,
      renderCell: (params) => (
        <ActionView
          params={params}
          theme={theme}
          handleClickMore={handleClickMore}
        />
      ),
    },
  ];

  const options = [
    {
      value: 1,
      label: itemRef.current?.state === 1 ? "Hide" : "Show",
      icon:
        itemRef.current?.state === 1 ? (
          <VisibilityOffIcon />
        ) : (
          <VisibilityIcon />
        ),
    },
    {
      value: 3,
      label: "Edit",
      icon: <EditIcon />,
    },
    {
      value: 2,
      label: "Delete",
      icon: <DeleteIcon />,
    },
  ];

  return (
    <Stack
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        overflowY: "auto",
        height: "calc(100% - 6rem)",
        pb: "1rem",
        ...theme.thinScrollBar,
      }}
    >
      <LogicFunction
        setContactsRows={setContactsRows}
        setFetching={setFetching}
      />
      <StyledPaper width={width} filteredContacts={filteredContacts}>
        {Boolean(anchorElMenu) ? (
          <MenuView
            anchorEl={anchorElMenu}
            open={Boolean(anchorElMenu)}
            options={options}
            handleClose={handleCloseMenu}
            onClickItem={onClickItem}
          />
        ) : null}
        {filteredContacts?.length > 0 ? (
          <>
            <ChartView
              filteredContacts={filteredContacts}
              isFetching={isFetching}
            />
            <div
              style={{
                height: listHeight,
                width: "100%",
                paddingInline: "1.5rem",
              }}
            >
              <DataGrid
                rows={filteredContacts || []}
                showColumnRightBorder={false}
                columns={columns}
                disableColumnMenu
                disableSelectionOnClick
                rowHeight={remToPx(currentWidth, HEIGHT)}
                getRowId={(row) => row?.uuid}
                onCellEditCommit={onCellEditCommit}
                onCellDoubleClick={onCellDoubleClick}
                onRowClick={onRowClick}
                rowCount={filteredContacts?.length || 0}
                pagination
                rowsPerPageOptions={[20, 50, 100]}
                paginationMode={"client"}
                localeText={{
                  noRowsLabel: "",
                  noResultsOverlayLabel: "",
                }}
                components={{
                  LoadingOverlay: LinearProgress,
                }}
                sx={{
                  border: 0,
                }}
              />
            </div>
          </>
        ) : (
          <ComponentLoader
            loading={isFetching}
            placeHolderHeight="4.5rem"
            placeHolderWidth="35%"
            skeltonSx={{
              transform: "scale(1,0.9)",
            }}
            sx={{
              width: "42%",
            }}
            key1={`customer_list_no_data_text_01`}
            key2={`customer_list_no_data_text_02`}
          />
        )}
      </StyledPaper>
    </Stack>
  );
};

export default ClientList;

const StateView = ({ params, theme, t }) => {
  return (
    <Typography
      variant="caption"
      fontWeight={"fontWeightMediumBold"}
      color={params?.row?.state === 1 ? "color.green.600" : "color.red.600"}
      sx={{
        backgroundColor:
          params?.row?.state === 1 ? "color.green.100" : "color.red.100",
        px: "0.5rem",
        py: "0.2rem",
        borderRadius: theme.borderRadius.main,
      }}
    >
      {params?.row?.state === 1
        ? t("client_state_visible")
        : t("client_state_hidden")}
    </Typography>
  );
};

const ActionView = ({ params, theme, handleClickMore }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "90%",
      }}
    >
      <IconButton
        onClick={(e) => handleClickMore(e, params)}
        aria-label="more"
        id="long-button"
        aria-haspopup="true"
        sx={{ background: "transparent !important", p: 0 }}
      >
        <MoreVertIcon
          sx={{
            background: "transparent !important",
            color: theme.palette.color.slate[600],
          }}
        />
      </IconButton>
    </div>
  );
};

const ChartView = ({ filteredContacts, isFetching, type = 1 }) => {
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);
  const refreshData = useSelector((state) => state.appSlice?.refreshData);

  const multiContactsIds = useMemo(() => {
    return filteredContacts?.map((item) => item?.uuid);
  }, [filteredContacts]);

  const AllContactsData = useQuery({
    queryKey: [
      "transactions",
      {
        apiType: "filteredContacts",
        type,
        dataset: dataSetData?.uuid,
      },
    ],
    queryFn: ({ signal }) => {
      let param = {
        config: {
          signal,
        },
        type: "monthly",
        dataset: dataSetData?.uuid,
        multiContactsIds,
        group_by: ["category"],
      };

      if (!dataSetData.use_global_categories) {
        param.category_dataset = dataSetData?.uuid;
      } else {
        param.global_category = true;
      }
      const result = getStatisticsDataWithParams(param);
      if (result) {
        return result;
      }
    },
    enabled: !!dataSetData?.uuid && !isFetching && multiContactsIds?.length > 0,
  });

  useDebounce(
    () => {
      AllContactsData.refetch();
    },
    500,
    [refreshData, multiContactsIds]
  );

  return (
    <Grid
      container
      rowSpacing={5}
      columnSpacing={5}
      sx={{
        width: "100%",
        height: "fit-content",
        mt: "1rem",
        mb: "2rem",
        px: "1.45rem",
      }}
    >
      <Grid item xs={12} isTablet={6}>
        <CategoryWidget
          filteredContacts={filteredContacts}
          type={type}
          loading={AllContactsData?.isFetching}
          contactData={AllContactsData?.data?.results || []}
        />
      </Grid>

      <Grid item xs={12} isTablet={6}>
        <CostWidget
          filteredContacts={filteredContacts}
          type={type}
          loading={AllContactsData?.isFetching}
          contactData={AllContactsData?.data?.results || []}
        />
      </Grid>
    </Grid>
  );
};

const CategoryWidget = ({
  loading,
  type = 1,
  contactData,
  filteredContacts,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  //state
  const [data, setData] = useState([]);

  useEffect(() => {
    if (!loading) {
      if (filteredContacts?.length > 0) {
        const selectionCategoriesByID =
          store.getState().categorySlice?.selectionCategoriesByID;
        const filterData = contactData;
        const ContactsDataByCategory = _.groupBy(
          filterData,
          (item) => item?.category || "UnCategorized"
        );

        let array = [];
        Object.keys(ContactsDataByCategory)?.forEach((key) => {
          const data = ContactsDataByCategory?.[key];
          const category = selectionCategoriesByID?.[key]?.[0];

          let total = 0;
          data?.forEach((item) => {
            total =
              total +
              Math.abs(type === 1 ? item?.inflow || 0 : item?.outflow || 0);
          });

          let title = category?.title;
          if (category?.immutable) {
            title = t(category?.title);
          } else if (!category?.title) {
            title = t(key);
          }
          array.push({
            uuid: key,
            title: title,
            value: Math.abs(parseFloat(total)?.toFixed(0)),
            color: category?.color || theme.palette.color.slate[500],
          });
        });

        array.sort((a, b) => b.value - a.value);
        setData(array);
      } else {
        setData([]);
      }
    }
  }, [
    loading,
    contactData,
    filteredContacts,
    theme.palette.color.slate,
    type,
    t,
  ]);

  if (!loading && filteredContacts?.length === 0) return null;
  if (!loading && (contactData?.length === 0 || data?.length === 0)) {
    return (
      <ComponentLoader
        height="18rem"
        key2={`customer_category_widget_no_data_text01`}
        placeHolderSx={{
          width: "100%",
        }}
      />
    );
  }
  const widget_title = "customer_widget_heading_category";

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <PieWidget
        title={widget_title}
        loading={loading}
        options={data}
        sx={{
          flex: 1,
          width: "100%",
          minHeight: "18rem",
        }}
      />
      <Divider
        orientation="vertical"
        flexItem
        sx={{
          display: { xs: "none", md: "block" },
          opacity: 0.6,
          mx: "0.6rem",
        }}
      />
    </div>
  );
};

const CostWidget = ({ loading, type = 1, contactData, filteredContacts }) => {
  const theme = useTheme();

  const [chartData, setChartData] = useState([]);
  const [chartKeys, setChartKeys] = useState([]);

  useEffect(() => {
    if (!loading) {
      const selectionCategoriesByID =
        store.getState().categorySlice?.selectionCategoriesByID;

      const filterData = contactData;

      const dates = [];
      filterData?.forEach((item) => {
        dates.push(new Date(item?.month));
      });

      const minDate = dates?.reduce(
        (min, date) => (min < date ? min : date),
        new Date(Infinity)
      );
      const maxDate = dates?.reduce(
        (max, date) => (max > date ? max : date),
        new Date(-Infinity)
      );

      if (!isValid(minDate) || !isValid(maxDate)) {
        setChartData([]);
        setChartKeys([]);
        return;
      }

      const TransactionsByDates = _.groupBy(filterData, "month");
      let RangeDates = [];
      const monthsDiff = differenceInMonths(maxDate, minDate);
      if (monthsDiff < 11) {
        let count = Math.floor(12 - monthsDiff / 2);
        const startAdjustment = subMonths(minDate, count);
        const endAdjustment = addMonths(maxDate, count);

        // Create a new RangeDates array based on adjusted start and end dates
        const newStartDate =
          startAdjustment < minDate ? startAdjustment : minDate;
        const newEndDate = endAdjustment > maxDate ? endAdjustment : maxDate;

        RangeDates = eachMonthOfInterval({
          start: startOfMonth(newStartDate),
          end: endOfMonth(newEndDate),
        });
      } else {
        RangeDates = eachMonthOfInterval({
          start: minDate,
          end: maxDate,
        });
      }
      const total_key = "total_key";
      let chartKeyArray = [];
      let monthArray = [];

      const categoryParent = getCategoryParentId();

      const categories = [
        ...new Set(
          filterData?.map(
            (item) => categoryParent?.[item?.category] || "UnCategorized"
          )
        ),
      ];

      RangeDates?.forEach((date) => {
        const formattedDate = format(date, "yyyy-MM");
        const monthData = TransactionsByDates?.[formattedDate] || [];
        const TransactionsByCategory = _.groupBy(
          monthData,
          (item) => categoryParent?.[item?.category] || "UnCategorized"
        );

        let obj = { due_date: formattedDate };
        categories?.forEach((key) => {
          const total = (TransactionsByCategory?.[key] || [])?.reduce(
            (a, b) =>
              a + Math.abs(type === 1 ? b?.inflow || 0 : b?.outflow || 0),
            0
          );
          obj[key] = Math.abs(parseFloat(total)?.toFixed(0));
        });
        Object.keys(obj).forEach((key) => {
          if (key !== "due_date" && key !== total_key) {
            obj[total_key] = (obj?.[total_key] || 0) + (obj?.[key] || 0);
          }
        });
        monthArray.push(obj);
      });
      categories?.forEach((category_uuid) => {
        const category = selectionCategoriesByID?.[category_uuid]?.[0];

        chartKeyArray.push({
          key: category?.title || "UnCategorized",
          color: category?.color || theme.palette.color.slate[500],
          dataKey: category_uuid || "UnCategorized",
          stackId: "a",
          fill: category?.color || theme.palette.color.slate[500],
          shade: 800,
        });
      });
      chartKeyArray.push({
        key: total_key,
        color: theme.palette.color.slate[500],
        dataKey: total_key,
        stackId: "a",
        fill: theme.palette.color.slate[500],
        shade: 800,
        hideBar: true,
        isBold: true,
        showTopSeparator: true,
      });
      setChartKeys(chartKeyArray);
      setChartData(monthArray);
    }
  }, [loading, contactData, type, filteredContacts, theme.palette.color.slate]);

  if (!loading && filteredContacts?.length === 0) return null;

  if (!loading && (contactData?.length === 0 || chartData?.length === 0)) {
    return (
      <ComponentLoader
        height="18rem"
        key2={`customer_cost_development_widget_no_data_text01`}
        placeHolderSx={{
          width: "100%",
        }}
      />
    );
  }

  const widget_title = "customer_widget_heading_cost_development";

  return (
    <AreaChartView
      loading={loading}
      chartKeys={chartKeys}
      chartData={chartData}
      heading={widget_title}
      style={{
        width: "100%",
        height: "18rem",
      }}
    />
  );
};

const LogicFunction = ({ setContactsRows, setFetching }) => {
  const contactQueryData = useStatusHook("contacts");

  //redux
  const searchText = useSelector(
    (state) => state.staffSlice?.["contactSearchText"]
  );
  const contactTypes = useSelector(
    (state) => state.staffSlice?.["contactTypes"]
  );

  //life cycle
  useDebounce(
    () => {
      if (!contactQueryData?.isFetching) {
        const data = contactQueryData?.data?.filter(
          (item) =>
            (contactTypes?.length > 0
              ? contactTypes.includes(item?.type)
              : true) &&
            (searchText?.length > 0
              ? item?.name?.toLowerCase()?.includes(searchText?.toLowerCase())
              : true)
        );
        setContactsRows(data || []);
        setFetching(false);
      } else {
        setContactsRows([]);
        setFetching(true);
      }
    },
    500,
    [searchText, contactTypes, contactQueryData?.isFetching],
    true
  );
};
