import { createSlice } from '@reduxjs/toolkit';

const resetSlice = createSlice({
  name: 'resetSlice',
  initialState: {},
  reducers: {
    resetAllState: (state) => state,
  },
});

export const { resetAllState } = resetSlice.actions;
export default resetSlice;

