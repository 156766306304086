import { GridPagination } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useState } from "react";

import NoDataInfoView from "../../../../components/TableEditModal/NoDataInfoView";
import useDebounce from "../../../../hooks/3-useDebounce/useDebounce";
import { formatAmount } from "../../../../Helper/data";
import { Color } from "../../../../Helper";

const CombinedDataGridFooter = (props) => {
  const {
    paginationProps,
    selectedRowData,
    tab,
    rowCountState,
    listLoading,
    hideCustomFooter,
  } = props;

  const profile = useSelector((state) => state.settingsSlice?.profile);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      {(tab === "suggestionArray"
        ? Number(profile?.auto_categorization) !== 2
        : true) &&
      rowCountState === 0 &&
      !listLoading ? (
        <div
          style={{
            position: "absolute",
            top: "25%",
            left: "0%",
            width: "100%",
            zIndex: 4,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <NoDataInfoView
            tab={
              tab === "bankArray"
                ? "Booked"
                : tab === "openArray"
                  ? "Open"
                  : tab === "bulkUnCatArray"
                    ? "Bulk"
                    : tab === "reconcileArray"
                      ? "Reconcile"
                      : tab === "lateArray"
                        ? "Late"
                        : tab === "plannedArray"
                          ? "Budget"
                          : tab === "allArray"
                            ? "All"
                            : "Suggestion"
            }
            backgroundColor={Color.tailwind.slate[50]}
          />
        </div>
      ) : null}
      {hideCustomFooter ? (
        <div></div>
      ) : (
        <CustomFooter selectedRowData={selectedRowData} />
      )}

      <GridPagination {...paginationProps} />
    </div>
  );
};

export default CombinedDataGridFooter;

const CustomFooter = ({ selectedRowData }) => {
  const { t } = useTranslation();

  //state
  const [value, setValue] = useState({
    averageValue: 0,
    minValue: 0,
    maxValue: 0,
    netValue: 0,
    grossValue: 0,
    vatValue: 0,
  });

  //life cycle
  useDebounce(
    () => {
      if (selectedRowData?.length > 0) {
        let grossValue = selectedRowData?.reduce(
          (total, item) =>
            parseFloat(total) + parseFloat(item?.gross_value || 0),
          0
        );
        let value = selectedRowData?.reduce(
          (total, item) => {
            const grossValue = parseFloat(item?.gross_value || 0);
            return {
              grossValue: total.grossValue + grossValue,
              minValue: Math.min(total.minValue, grossValue),
              maxValue: Math.max(total.maxValue, grossValue),
            };
          },
          {
            grossValue: 0,
            minValue: Number.POSITIVE_INFINITY,
            maxValue: Number.NEGATIVE_INFINITY,
          }
        );
        setValue({
          grossValue: value?.grossValue,
          minValue: value?.minValue,
          maxValue: value?.maxValue,
          averageValue: grossValue / selectedRowData?.length,
          netValue: selectedRowData?.reduce(
            (total, item) =>
              parseFloat(total) + parseFloat(item?.calculated_net_value || 0),
            0
          ),
          vatValue: selectedRowData?.reduce(
            (total, item) =>
              parseFloat(total) + parseFloat(item?.calculated_vat_value || 0),
            0
          ),
        });
      } else {
        setValue({
          grossValue: 0,
          netValue: 0,
          vatValue: 0,
        });
      }
    },
    1000,
    [selectedRowData],
    true
  );

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        gap: "0.75rem",
        padding: "1rem",
      }}
    >
      <ItemView t={t} title={"Amount"} count={selectedRowData?.length || 0} />
      <ItemView t={t} title={"Average Value"} value={value?.averageValue} />
      <ItemView t={t} title={"Min Value"} value={value?.minValue} />
      <ItemView t={t} title={"Max Value"} value={value?.maxValue} />
      <ItemView t={t} title={"Gross Value"} value={value?.grossValue} />
      <ItemView t={t} title={"Net Value"} value={value?.netValue} />
      <ItemView t={t} title={"Vat Value"} value={value?.vatValue} hideDash />
    </div>
  );
};

const ItemView = ({ t, title, value = 0, count = false, hideDash = false }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "0.75rem",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: "0.8rem",
          }}
        >
          {t(title)}:&nbsp;
        </Typography>
        <Typography
          sx={{
            fontSize: "0.8rem",
            fontWeight: 600,
          }}
        >
          {count || count === 0
            ? count
            : formatAmount({
                amount: value,
              })}
        </Typography>
      </div>

      {hideDash ? null : (
        <Typography
          sx={{
            fontSize: "0.8rem",
            fontWeight: 600,
          }}
        >
          -
        </Typography>
      )}
    </div>
  );
};
