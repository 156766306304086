import { createSlice } from "@reduxjs/toolkit";

import { resetAllState } from "./reset";

const initialState = { linkText: "", path: [] };

const pathHeaderSlice = createSlice({
  name: "pathHeaderSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(resetAllState, () => initialState);
  },
  reducers: {
    setPath(state, action) {
      state.path = action.payload;
    },
  },
});
export const { setPath } = pathHeaderSlice.actions;
export default pathHeaderSlice;
