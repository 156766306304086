import * as React from "react";
import Box from "@mui/material/Box";

import { Color } from "../../Helper";
import { useDispatch, useSelector } from "react-redux";
import { setAppliedFilterslist } from "../../store/slices/global";
import { Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Toggle from "./Toggle";
import { queryClient } from "../../App";
const marks = [
  {
    value: "states",
    label: "States",
  },
  {
    value: "scenarios",
    label: "Scenarios",
  },
];

export default React.forwardRef(function GroupTypeToggle(props, ref) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  //redux
  const appliedFilterlist = useSelector(
    (state) => state.globalSlice.appliedFilterlist
  );
  const uuid = useSelector((state) => state.boardSlice?.dataSetData?.uuid);

  //function
  const onChange = (e, value) => {
    if (value) {
      global.allowFetch = { Inflow: [], Outflow: [] };
      let options1 = {
        predicate: (query) =>
          query.queryKey[0] === "transactions" &&
          query.queryKey[1]?.apiType === "Kanban_Transactions" &&
          query.queryKey[1]?.dataset === uuid,
      };
      queryClient.removeQueries(options1);
      let data = {
        ...appliedFilterlist,
        kanban: {
          ...appliedFilterlist?.kanban,
          groupType: value,
        },
      };
      dispatch(setAppliedFilterslist(data));
    }
  };

  //render function
  return (
    <Box ref={ref} sx={{ display: "flex", p: "0.75rem", alignItems: "center" }}>
      <Typography
        sx={{ fontSize: "1rem", width: "8.5rem", color: Color.themeColor2 }}
      >
        {t("Group")}
      </Typography>

      <Tooltip
        title={t("Group_Type")}
        disableFocusListener
        placement="top"
        arrow
      >
        <Box
          sx={{
            display: "flex",
            p: "0.75rem",
            alignItems: "center",
            pl: "1.5rem",
          }}
        >
          <Toggle
            value={appliedFilterlist?.kanban?.groupType || "scenarios"}
            onChange={onChange}
            options={marks}
          />
        </Box>
      </Tooltip>
    </Box>
  );
});
