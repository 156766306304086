import { Link as NavigateLink } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/styles";

import { Color, Fonts } from "../../Helper";

const Placeholder = ({
  image,
  height = "100%",
  width = "98.5%",
  insightText = "",
  fontSize = "1.5rem",
  showText = true,
  description = "",
  style = null,
}) => {
  const subscription = useSelector(
    (state) => state.settingsSlice?.subscription
  );
  const theme = useTheme();

  return (
    <Box
      sx={{ position: "relative", display: "flex", height, width, ...style }}
    >
      <Box
        sx={{
          backgroundImage: `url(${image})`,
          backgroundRepeat: "no-repeat",
          backgroundColor: Color.appThemeBg,
          backgroundSize: "100% 100%",
          backgroundPosition: "center",
          filter: "blur(10px)",
          height,
          width,
        }}
      />
      {showText && (
        <>
          {description ? (
            <Typography
              sx={{
                color: theme.palette.primary.main,
                fontSize,
                fontWeight: 400,
                fontFamily: Fonts.Text,
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              {description}
            </Typography>
          ) : subscription?.expired ? (
            <Typography
              sx={{
                color: theme.palette.primary.main,
                fontSize,
                fontWeight: 400,
                fontFamily: Fonts.Text,
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              Your {subscription?.name} plan is expired. Please{" "}
              <NavigateLink
                to="/settings/subscription"
                style={{ color: "inherit", fontWeight: 500 }}
                replace
              >
                Upgrade
              </NavigateLink>{" "}
              to get more insights about your {insightText}.
            </Typography>
          ) : (
            <Typography
              sx={{
                color: theme.palette.primary.main,
                fontSize,
                fontWeight: 400,
                fontFamily: Fonts.Text,
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              This Chart is not available in {subscription?.name} plan. Please{" "}
              <NavigateLink
                to="/settings/subscription"
                style={{ color: "inherit", fontWeight: 500 }}
                replace
              >
                Upgrade
              </NavigateLink>{" "}
              to get more insights about your {insightText}.
            </Typography>
          )}
        </>
      )}
    </Box>
  );
};
export default Placeholder;
