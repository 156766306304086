import { PieChart, Pie, Tooltip, Cell, ResponsiveContainer } from "recharts";
import React, { useRef } from "react";

import CategoryTooltip from "../../components/Charts/CategoryTooltip";

const CustomLabel = (props) => {
  const {
    cx,
    cy,
    midAngle,
    outerRadius,
    categoryName,
    share,
    color,
    index,
    previousLabels,
  } = props;

  const RADIAN = Math.PI / 180;

  // Position labels slightly outside the pie chart
  const labelRadius = outerRadius + 40;
  const x = cx + labelRadius * Math.cos(-midAngle * RADIAN);
  const y = cy + labelRadius * Math.sin(-midAngle * RADIAN);

  // Prevent overlap across different pie slices
  let verticalOffset = 0;
  if (index === 0 && previousLabels?.current) {
    previousLabels.current = []; // Reset stored positions for new render
  }

  // Adjust label position dynamically to avoid overlap
  let adjustedX = x;
  let adjustedY = y + verticalOffset;
  
  previousLabels?.current?.forEach(({ prevX, prevY }) => {
    // Check if labels are too close in both X and Y directions
    if (Math.abs(prevY - adjustedY) < 15 && Math.abs(prevX - adjustedX) < 30) {
      // Adjust vertically if there's overlap with another slice
      verticalOffset += 15;
      adjustedY = y + verticalOffset;
    }
  });

  // Store the current label's X and Y positions for future slices
  if (previousLabels) {
    previousLabels.current = [...previousLabels?.current, { prevX: adjustedX, prevY: adjustedY }];
  }

  // Start and end points for the label line
  const startX = cx + outerRadius * Math.cos(-midAngle * RADIAN);
  const startY = cy + outerRadius * Math.sin(-midAngle * RADIAN);
  const endX = adjustedX;
  const endY = adjustedY;

  return (
    <g>
      {/* Draw a straight line from the pie slice to the label */}
      <path
        d={`M${startX},${startY} L${endX},${endY}`}
        stroke={color}
        fill="none"
      />
      {/* Display label at the adjusted position */}
      <text
        x={endX + (adjustedX > cx ? 6 : -6)} // Adjust for left/right side
        y={endY}
        fill={color}
        textAnchor={adjustedX > cx ? "start" : "end"} // Adjust alignment based on pie side
        dominantBaseline="central"
        fontSize={10}
        fontWeight="bold"
      >
        {`${categoryName}: ${share}`}
      </text>
    </g>
  );
};


const PieCharts = React.memo(
  ({ data, chartProps }) => {
    const previousLabels = useRef([]);
    const previousLabelsX = useRef([]);

    return (
      <ResponsiveContainer id="recharts-surface" width="100%" height="100%">
        <PieChart>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={
              <CustomLabel
                previousLabels={previousLabels}
                previousLabelsX={previousLabelsX}
              />
            }
            outerRadius={100}
            dataKey="gross_value"
            {...chartProps}
          >
            {data?.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
          <Tooltip content={<CategoryTooltip data={data} />} />
        </PieChart>
      </ResponsiveContainer>
    );
  },
  (prev, next) => prev.data === next.data
);
export default PieCharts;
