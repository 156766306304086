import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { Typography, Collapse, useTheme, Tooltip, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TbCircles } from "react-icons/tb";
import { useMemo, useState } from "react";

import {
  isStatesNotCorrect,
  getFilteredStates,
  getTailwindColor,
  isStatesDisabled,
} from "../../Helper/data";
import ToggleButton from "./ToggleButton";
import { Constant } from "../../Helper";
import StateChip from "../StateChip";
import theme from "../../theme";
import Icon from "../Icon";

const StateView = ({
  cardItem,
  income_expense_type,
  onClick,
  width = "36.35rem",
  chipWidth = "11rem",
  chipHeight = "2.2rem",
  tab,
}) => {
  const isStaffTab = tab === "employee" || tab === "loan" || tab === "leasing";
  const isRecurringSeq = !isStaffTab && tab !== "single";
  const filterStates = useMemo(() => {
    return getFilteredStates({
      income_expense_type: cardItem?.income_expense_type,
      gross_value: cardItem?.gross_value,
      source: cardItem?.source,
    });
  }, [cardItem?.gross_value, cardItem?.income_expense_type, cardItem?.source]);

  //state
  const [isExpanded, setIsExpanded] = useState(false);

  //functions
  const handleClickBtn = () => {
    setIsExpanded((prev) => !prev);
  };

  const onClickItem = () => {
    setIsExpanded(true);
  };

  const onClickState = (item) => {
    setIsExpanded(false);
    if (onClick) onClick(item);
  };

  const sections = useMemo(() => {
    if (isRecurringSeq) {
      const staff_states =
        cardItem?.source === 1
          ? filterStates?.filter((item) =>
              Constant.staffState.includes(item.title)
            )
          : [];
      return [
        {
          id: 1,
          section_title: "",
          states: staff_states,
        },
      ];
    } else if (isStaffTab) {
      return [
        {
          id: 1,
          section_title: "",
          states: filterStates?.filter((item) =>
            cardItem?.state === "Booked"
              ? item?.title === "Booked"
              : Constant.staffState.includes(item.title)
          ),
        },
      ];
    } else {
      const planned_states =
        cardItem?.source === 1
          ? filterStates?.filter((item) =>
              Constant.plannedState.includes(item.title)
            )
          : [];
      const lead_states = [1, 4].includes(cardItem?.source)
        ? filterStates?.filter((item) =>
            Constant.leadState.includes(item.title)
          )
        : [];
      const offer_states = [1, 4].includes(cardItem?.source)
        ? filterStates?.filter((item) =>
            Constant.offerState.includes(item.title)
          )
        : [];

      const invoice_states = [1, 4].includes(cardItem?.source)
        ? filterStates?.filter(
            (item) =>
              item?.title === "Booked" ||
              item?.title === "Sales credit note" ||
              item?.title === "Purchase credit note" ||
              Constant.invoiceState.includes(item.title)
          )
        : [];
      const misc_states = [1, 4].includes(cardItem?.source)
        ? filterStates?.filter((item) =>
            Constant.miscState.includes(item.title)
          )
        : [];
      const booked_states =
        cardItem?.source === 2
          ? filterStates?.filter((item) =>
              Constant.bookedPositionsStates.includes(item.title)
            )
          : [];
      return [
        {
          id: 1,
          section_title: "planned_state_separator_title",
          states: planned_states,
          isEmpty: planned_states?.length === 0,
        },
        {
          id: 2,
          section_title: "open_state_separator_title",
          states: lead_states,
          states1: offer_states,
          states2: invoice_states,
          states3: misc_states,
          isEmpty:
            lead_states?.length === 0 &&
            offer_states?.length === 0 &&
            invoice_states?.length === 0 &&
            misc_states?.length === 0,
        },
        {
          id: 3,
          section_title: "booked_state_separator_title",
          states: booked_states,
          isEmpty: booked_states?.length === 0,
        },
      ];
    }
  }, [
    isRecurringSeq,
    isStaffTab,
    cardItem?.source,
    cardItem?.state,
    filterStates,
  ]);

  return (
    <>
      <Header cardItem={cardItem} />
      <Box
        sx={{
          ml: isStaffTab || isRecurringSeq ? "3.1rem" : "2.1rem",
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "column",
          height: "max-content",
          width,
        }}
      >
        {isStaffTab || isRecurringSeq ? (
          <StatesSectionView
            width={width}
            states={sections?.[0]?.states}
            onClick={onClickState}
            cardItem={cardItem}
            chipWidth={chipWidth}
            chipHeight={chipHeight}
            isEmployeeState={!isRecurringSeq && cardItem?.state !== "Booked"}
          />
        ) : (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "column",
              height: "max-content",
              backgroundColor: isExpanded
                ? theme.palette.color.slate[50]
                : "transparent",
              borderRadius: theme.borderRadius.main,
              border: `2px dashed ${isExpanded ? theme.palette.color.slate[200] : "transparent"}`,
              p: "1rem",
              width,
            }}
          >
            <Collapse
              orientation="vertical"
              in={isExpanded}
              collapsedSize={"2.3rem"}
            >
              {!isExpanded ? (
                <StateChip
                  title={cardItem?.state}
                  source={cardItem?.source}
                  width={chipWidth}
                  height={chipHeight}
                  isSelected
                  onClick={onClickItem}
                  income_expense_type={income_expense_type}
                />
              ) : (
                sections?.map((section, index) => (
                  <SectionView
                    key={section?.id}
                    is_last_section={
                      !sections?.[index + 1] || sections?.[index + 1]?.isEmpty
                    }
                    section={section}
                    width={width}
                    chipWidth={chipWidth}
                    chipHeight={chipHeight}
                    cardItem={cardItem}
                    income_expense_type={income_expense_type}
                    onClick={onClickState}
                  />
                ))
              )}
            </Collapse>
            <ToggleButton
              isExpanded={isExpanded}
              onClick={handleClickBtn}
              style={{ mt: "0.5rem" }}
            />
          </Box>
        )}
      </Box>
    </>
  );
};

export default StateView;

const Header = ({ cardItem }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  let isStateNotMatching = useMemo(() => {
    return isStatesNotCorrect({
      state: cardItem?.state,
      income_expense_type: cardItem?.income_expense_type,
      gross_value: cardItem?.gross_value,
    });
  }, [cardItem?.state, cardItem?.income_expense_type, cardItem?.gross_value]);

  const handleClickInfo = () => {
    var win = window.open("https://finban.io/documentation/states", "_blank");
    win.focus();
  };

  return (
    <Box
      sx={{
        display: "inline-flex",
        alignItems: "center",
        pr: "1.5rem",
        mt: "2rem",
      }}
    >
      <Icon
        icon={<TbCircles />}
        fontSize={"1.3rem"}
        color={theme.palette.color.blueGrey[700]}
        style={{
          marginRight: "1.7rem",
          marginLeft: "0.2rem",
        }}
      ></Icon>
      <Typography
        variant="h6"
        component={"h6"}
        fontSize={"0.9rem"}
        color="color.mainTitle"
        fontWeight={"fontWeightMediumBold"}
      >
        {t("State")}
      </Typography>
      <Tooltip
        title={t("Click here to access our guide")}
        placement="top"
        arrow
      >
        <HelpOutlineOutlinedIcon
          onClick={handleClickInfo}
          sx={{
            fontSize: "1rem",
            cursor: "pointer",
            ml: "0.5rem",
            color: theme.palette.primary.main,
          }}
        />
      </Tooltip>
      {isStateNotMatching && (
        <Typography
          variant="h6"
          component={"h6"}
          fontSize={"0.9rem"}
          color="error"
          fontWeight={"fontWeightMediumBold"}
          sx={{ ml: "0.5rem" }}
        >
          {t("transaction_form_issue_state_not_matching")}
        </Typography>
      )}
    </Box>
  );
};

const SectionView = ({
  is_last_section,
  section,
  width,
  chipWidth,
  chipHeight,
  onClick,
  cardItem,
  income_expense_type,
}) => {
  if (
    (section?.states ? section?.states?.length === 0 : true) &&
    (section?.states1 ? section?.states1?.length === 0 : true) &&
    (section?.states2 ? section?.states2?.length === 0 : true) &&
    (section?.states3 ? section?.states3?.length === 0 : true)
  ) {
    return null;
  }
  return (
    <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
      <StatesSectionView
        income_expense_type={income_expense_type}
        width={width}
        states={section?.states}
        onClick={onClick}
        cardItem={cardItem}
        chipWidth={chipWidth}
        chipHeight={chipHeight}
      />
      <StatesSectionView
        income_expense_type={income_expense_type}
        width={width}
        states={section?.states1}
        onClick={onClick}
        cardItem={cardItem}
        chipWidth={chipWidth}
        chipHeight={chipHeight}
      />
      <StatesSectionView
        income_expense_type={income_expense_type}
        width={width}
        states={section?.states2}
        onClick={onClick}
        cardItem={cardItem}
        chipWidth={chipWidth}
        chipHeight={chipHeight}
      />
      <StatesSectionView
        income_expense_type={income_expense_type}
        width={width}
        states={section?.states3}
        onClick={onClick}
        cardItem={cardItem}
        chipWidth={chipWidth}
        chipHeight={chipHeight}
      />
      {!is_last_section ? (
        <div
          textAlign="left"
          style={{
            // width: "93.5%",
            // mt: "0.5rem",
            mb: "1.5rem",
            fontWeight: 500,
            fontSize: "0.75rem",
            color: getTailwindColor("slate", 700),
            // "&::before, &::after": {
            //   borderTop: `thin solid ${getTailwindColor("slate", 50)}`,
            // },
          }}
        >
          {/* {t(section?.section_title)} */}
        </div>
      ) : null}
    </div>
  );
};

const StatesSectionView = ({
  income_expense_type,
  width,
  chipWidth,
  chipHeight,
  isEmployeeState,
  cardItem,
  states,
  onClick,
}) => {
  if (!states || states?.length === 0) return null;
  return (
    <Box
      sx={{
        display: "inline-flex",
        height: "max-content",
        alignItems: "center",
        flexWrap: "wrap",
        mb: "2rem",
        gap: "0.5rem",
        width,
      }}
    >
      {states?.map((item) => (
        <StateChip
          income_expense_type={income_expense_type}
          key={item?.uuid}
          title={item?.title}
          disabled={isStatesDisabled({
            state: cardItem?.state,
            destination_state: item?.title,
            source: cardItem?.source,
          })}
          source={cardItem?.source}
          width={chipWidth}
          height={chipHeight}
          isEmployeeState={isEmployeeState}
          isSelected={cardItem?.state === item?.title}
          onClick={onClick}
        />
      ))}
    </Box>
  );
};
