import { FaCaretSquareUp, FaExternalLinkSquareAlt } from "react-icons/fa";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Button, Tooltip, Typography } from "@mui/material";
import { format, startOfMonth, subDays } from "date-fns";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { getTailwindColor, formatAmount } from "../../../../../Helper/data";
import { setPopupStatus2 } from "../../../../../store/slices/datasets";
import initialData from "./../../../../../Helper/data";
import Icon from "../../../../../components/Icon";
import { Constant } from "../../../../../Helper";
import store from "../../../../../store";

const QuickLinkView = ({
  title,
  tooltip,
  moreState = [],
  data = [],
  start_date,
  end_date,
  page_size,
  source,
  state_color,
  onClickOthers,
  onClickClose,
  isDetailedTransactions,
  sx,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  //redux

  let color = "slate";
  const hideNumber = title === "Close" || title === "Others";
  const isState = title !== "Late" && title !== "Others" && title !== "All";

  //state
  const [value, setValue] = useState({
    gross_value: 0,
    count: 0,
  });

  if (title === "Late") {
    color = "red";
  } else if (initialData.defaultState?.[title]?.color) {
    color = initialData.defaultState?.[title]?.color;
  } else if (title === "All") {
    color = state_color;
  } else {
    color = "slate";
  }
  const inActive = value?.count === 0 && !hideNumber;
  if (inActive) {
    color = "slate";
  }

  //lifecycle
  useEffect(() => {
    if (!hideNumber && data && title) {
      let filterData = [];

      if (isDetailedTransactions) {
        if (isState) {
          filterData = data?.filter((o1) => o1?.state === title);
        } else if (title === "All") {
          filterData = data;
        } else {
          filterData = data?.filter(
            (o1) =>
              Constant.lateState.includes(o1?.state) &&
              o1?.due_date &&
              format(new Date(o1?.due_date), "yyyy-MM") <
                format(new Date(), "yyyy-MM")
          );
        }
        const count = filterData?.length || 0;
        const gross_value = filterData?.reduce(
          (total, item) =>
            parseFloat(total) + +parseFloat(item?.gross_value ?? 0),
          0
        );
        setValue({
          gross_value,
          count,
        });
      } else {
        if (isState) {
          filterData = data?.filter((o1) => o1?.state === title);
        } else if (title === "All") {
          filterData = data;
        } else {
          filterData = data?.filter(
            (o1) =>
              Constant.lateState.includes(o1?.state) &&
              o1?.month &&
              format(new Date(o1?.month), "yyyy-MM") <
                format(new Date(), "yyyy-MM")
          );
        }
        const count = filterData?.reduce(
          (total, item) =>
            parseFloat(total) +
            parseFloat(item?.inflow_count ?? 0) +
            parseFloat(item?.outflow_count ?? 0),
          0
        );
        const gross_value = filterData?.reduce(
          (total, item) =>
            parseFloat(total) +
            parseFloat(item?.inflow ?? 0) +
            parseFloat(item?.outflow ?? 0),
          0
        );
        setValue({
          gross_value,
          count,
        });
      }
    }
  }, [title, isState, data, hideNumber, isDetailedTransactions]);

  //functions
  const onClickLink = () => {
    const stateByTitle = store?.getState()?.globalSlice?.stateByTitle;

    let _states = [];
    let listViewPath = "bulk";
    let obj = {};
    if (start_date) {
      obj.start_date = start_date;
    }
    if (end_date) {
      obj.end_date = end_date;
    }
    if (page_size) {
      obj.page_size = page_size;
    }
    if (source) {
      obj.source = source;
    }
    if (title === "Late") {
      Constant.lateState?.forEach((o1) => {
        _states.push(stateByTitle?.[o1]?.[0]?.uuid);
      });
      obj.end_date = subDays(startOfMonth(new Date()), 1);
      listViewPath = "late";
    } else if (title === "All") {
      moreState?.forEach((o1) => {
        _states.push(stateByTitle?.[o1]?.[0]?.uuid);
      });
      if (moreState?.includes("Booked")) {
        listViewPath = "bank";
      } else {
        listViewPath = "open";
      }
    } else if (title === "Others") {
      if (onClickOthers) onClickOthers();
      return;
    } else if (title === "Close") {
      if (onClickClose) onClickClose();
      return;
    } else {
      _states = [stateByTitle?.[title]?.[0]?.uuid];
      if (title === "Booked") {
        listViewPath = "bank";
      } else {
        listViewPath = "open";
      }
    }

    dispatch(
      setPopupStatus2({
        open: true,
        overlay_type: "drawer_modal",
        anchor: "right",
        payload: {
          overlayTab: "category",
          cell: {
            multiState: _states,
            withUrl: true,
            listViewPath: listViewPath,
            calculateAllState: true,
            ...obj,
          },
        },
      })
    );
  };

  return (
    <Button
      disabled={inActive}
      onClick={inActive ? undefined : onClickLink}
      sx={{
        display: "inline-flex",
        flexDirection: "column",
        alignItems: "flex-start",
        pl: "1rem",
        pr: "1.5rem",
        py: "0.5rem",
        borderRadius: "0.3rem",
        height: "3.5rem",
        color: "white",
        fontSize: "0.8rem",
        fontWeight: 500,
        mx: "0.5rem",
        position: "relative",
        width: "9rem",
        cursor: inActive ? "not-allowed" : "pointer",
        backgroundColor: title
          ? getTailwindColor(color, inActive ? 50 : 100)
          : "transparent",
        border:
          value?.count > 0 || hideNumber
            ? `1px solid ${getTailwindColor(color, 600)}`
            : 0,
        "&:hover": {
          "#widget_quick_link_icon svg": {
            color: `${getTailwindColor(
              color,
              value?.count > 0 || hideNumber ? 700 : 300
            )} !important`,
          },

          backgroundColor: getTailwindColor(
            color,
            inActive ? 50 : hideNumber ? 100 : 200
          ),
        },
        ...sx,
      }}
    >
      {tooltip ? (
        <Tooltip arrow title={t(tooltip)} placement="top">
          <HelpOutlineIcon
            sx={{
              color: getTailwindColor(color, 400),
              cursor: "pointer",
              fontSize: "1rem",
              position: "absolute",
              right: "0.25rem",
              top: "0.25rem",
            }}
          />
        </Tooltip>
      ) : null}
      <Typography
        sx={{
          width: "100%",
          color: getTailwindColor(color, inActive ? 300 : 700),
          fontSize: "0.7rem",
          fontWeight: 500,
          textTransform: "initial",
          textAlign: "left",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {t(title)}
      </Typography>

      <Typography
        sx={{
          color: getTailwindColor(color, inActive ? 300 : 700),
          fontSize: "0.8rem",
          fontWeight: 600,
          textTransform: "initial",
          textAlign: "left",
          width: "100%",
          height: "1.3rem",
          // overflow: "hidden",
          // textOverflow: "ellipsis",
          // whiteSpace: "nowrap",
        }}
      >
        {!title || hideNumber
          ? null
          : `${formatAmount({
              amount: value?.gross_value,
            })} (${value?.count || 0})`}
      </Typography>

      {title ? (
        <Icon
          id="widget_quick_link_icon"
          icon={
            hideNumber ? (
              <FaCaretSquareUp
                style={{
                  transform:
                    title === "Close" ? "rotate(180deg)" : "rotate(0deg)",
                  transition: "all 0.3s ease",
                }}
              />
            ) : (
              <FaExternalLinkSquareAlt />
            )
          }
          color={getTailwindColor(color, !hideNumber ? 300 : 700)}
          fontSize={"0.85rem"}
          style={{
            position: "absolute",
            right: "0.25rem",
            bottom: "0.25rem",
          }}
        />
      ) : null}
    </Button>
  );
};
export default QuickLinkView;
