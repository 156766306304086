import {
  ResponsiveContainer,
  CartesianGrid,
  ReferenceLine,
  LineChart,
  Tooltip,
  Legend,
  XAxis,
  YAxis,
  Line,
} from "recharts";
import { format } from "date-fns";
import React from "react";

import CustomizedAxisTick from "../../components/Charts/CustomizedAxisTick";
import CustomTooltip from "../../components/Charts/CustomTooltip";
import { remToPx } from "../../Helper/data";
import useWidth from "../../hooks/useWidth";
import theme from "../../theme";

const LineCharts = React.memo(
  ({
    data,
    type,
    barsData,
    dynamicColor,
    currentMonth = format(new Date(), "yyyy-MM"),
  }) => {
    const currentWidth = useWidth();
    let off = null;
    if (dynamicColor) {
      const gradientOffset = () => {
        const dataMax = Math.max(...data.map((i) => i.gross_value));
        const dataMin = Math.min(...data.map((i) => i.gross_value));

        if (dataMax <= 0) {
          return 0;
        }
        if (dataMin >= 0) {
          return 1;
        }

        return dataMax / (dataMax - dataMin);
      };

      off = gradientOffset();
    }

    return (
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={remToPx(currentWidth, 31.25)}
          height={remToPx(currentWidth, 18.75)}
          data={data}
          margin={{
            top: remToPx(currentWidth, 1.25),
            right: remToPx(currentWidth, 1.875),
            left: remToPx(currentWidth, 1.25),
            bottom: remToPx(currentWidth, 0.3125),
          }}
        >
          <CartesianGrid
            strokeDasharray="3 3"
            vertical={false}
            horizontal={false}
          />
          <XAxis
            dataKey="due_date"
            tick={<CustomizedAxisTick dy={remToPx(currentWidth, 1)} dx={0} />}
            tickCount={12}
            minTickGap={2}
            type="category"
            allowDataOverflow={false}
          />
          <YAxis
            tick={
              <CustomizedAxisTick
                dy={remToPx(currentWidth, 0.2)}
                dx={-remToPx(currentWidth, 1)}
                formatted
              />
            }
            type="number"
            tickCount={12}
            dataKey={(v) => parseInt(v.gross_value)}
            interval={"preserveStartEnd"}
          />
          <Tooltip
            cursor={{ fill: "transparent" }}
            content={
              <CustomTooltip barsData={barsData} dynamicColor={dynamicColor} />
            }
          />
          <Legend wrapperStyle={{ display: "none" }} />
          <ReferenceLine
            x={currentMonth}
            stroke={theme.palette.color.slate[200]}
          />

          {dynamicColor ? (
            <defs>
              <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
                <stop offset={off} stopColor="green" stopOpacity={1} />
                <stop offset={off} stopColor="red" stopOpacity={1} />
              </linearGradient>
            </defs>
          ) : null}
          {barsData?.map((item) => (
            <Line {...item} />
          ))}
        </LineChart>
      </ResponsiveContainer>
    );
  },
  (prev, next) => {
    return (
      prev.data === next.data &&
      prev.multiLines === next.multiLines &&
      prev.RangeType === next.RangeType
    );
  }
);
export default LineCharts;
