import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AccountResponse } from "../../types";
import { resetAllState } from "./reset";

type State = {
  token: string | null;
  refreshToken: string | null;
  account: AccountResponse | null;
  loginTime: any | null;
};

const initialState: State = {
  token: null,
  refreshToken: null,
  account: null,
  loginTime: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(resetAllState, () => initialState);
  },
  reducers: {
    setAuthTokens(
      state: State,
      action: PayloadAction<{ token: string; refreshToken: string }>
    ) {
      state.refreshToken = action.payload.refreshToken;
      state.token = action.payload.token;
    },
    setLoginTime(state: State, action: PayloadAction<{ loginTime: any }>) {
      state.loginTime = action.payload.loginTime;
    },
    setAccount(state: State, action: PayloadAction<AccountResponse>) {
      state.account = action.payload;
    },
    logout(state: State) {
      localStorage.setItem("token", "");
      state.account = null;
      state.refreshToken = null;
      state.token = null;
      state.loginTime = null;
    },
  },
});

export default authSlice;
