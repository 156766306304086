import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { Box } from "@mui/material";
import { useEffect } from "react";

import { setAccounts } from "../../../store/slices/global";
import EndPoints from "../../../APICall/EndPoints";
import DatasetsGridView from "./DatasetsGridView";
import APICall from "../../../APICall";
import HeaderView from "../HeaderView";

const Organizations = ({ orgWidth, spacing, chart_data }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //functions
  const onClickEdit = () => {
    navigate("/settings/organizations");
  };

  const getAccountsApi = async () => {
    await APICall("get", EndPoints.accounts).then((response) => {
      if (response.status === 200 && response.data) {
        let data = response.data.results;
        dispatch(setAccounts(data));
      }
    });
  };

  useEffect(() => {
    getAccountsApi();
  }, []);

  return (
    <Box
      sx={{
        height: "fit-content",
        display: "flex",
        flexDirection: "column",
        width: `${orgWidth}rem`,
      }}
    >
      <HeaderView
        title="datasets"
        sx={{
          zIndex: 5,
          maxWidth: "100%",
          margin: "auto",
          mb: "1.5rem",
        }}
        onClickEdit={onClickEdit}
      />
      <DatasetsGridView spacing={spacing} chart_data={chart_data} />
    </Box>
  );
};

export default Organizations;
