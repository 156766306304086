import {
  getDaysInMonth,
  startOfQuarter,
  startOfMonth,
  endOfQuarter,
  startOfYear,
  startOfWeek,
  endOfMonth,
  startOfDay,
  endOfYear,
  subMonths,
  endOfWeek,
  subYears,
  endOfDay,
  subWeeks,
  getYear,
  subDays,
  format,
  set,
} from "date-fns";
import {
  ButtonGroup,
  Typography,
  useTheme,
  Divider,
  Stack,
  Box,
} from "@mui/material";
import { MdOutlineDateRange } from "react-icons/md";
import EastIcon from "@mui/icons-material/East";
import WestIcon from "@mui/icons-material/West";
import { useTranslation } from "react-i18next";
import Button from "@mui/lab/LoadingButton";
import { useMemo, useState } from "react";

import useDebounce from "../../../../hooks/3-useDebounce/useDebounce";
import { formatDateToLocal } from "../../../../Helper/data";
import FilterBaseView from "../FilterBaseView";
import CustomPopover from "../../../PopOver";

const MonthOptions = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const WeeksOptions = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
const YearOptions = Array.from({ length: 50 }, (_, i) => 2001 + i);
const QuarterOptions = ["Q1", "Q2", "Q3", "Q4"];
const QuarterToStartMonthIndex = [0, 3, 6, 9];
const QuarterToEndMonthIndex = [2, 5, 8, 11];
const PRESETS = [
  "Today",
  "Yesterday",
  "This Week",
  "Last Week",
  "This Month",
  "Last Month",
  "Last 3 Months",
  "Last 6 Months",
  "Last 12 Months",
  "This Quarter",
  "Last Quarter",
  "This Year",
  "Last Year",
];

const DateRangePicker = ({
  start_date,
  end_date,
  Placeholder = "Select Date Range",
  RangeType = "Monthly",
  onChangeDate,
  onClickReset,
  onOk,
  autoClose = true,
  showClear,
  hideHeading,
  defaultStart = startOfYear(new Date()),
  defaultEnd = endOfYear(new Date()),
  headerView,
}) => {
  const [anchorElDate, setAnchorElDate] = useState(null);

  const onCloseDatePopOver = () => {
    setAnchorElDate(null);
  };

  const onClickDatePopOver = (e) => {
    setAnchorElDate(e.currentTarget);
  };

  let formatString = "MMM yy";
  if (RangeType === "Monthly") {
    formatString = "dd MMM yy";
  }
  if (RangeType === "Annually") {
    formatString = "yyyy";
  }
  if (RangeType === "Quarterly") {
    formatString = "QQQ, yyyy";
  }

  const _startDate =
    start_date && start_date !== "Invalid Date"
      ? formatDateToLocal(new Date(start_date), formatString)
      : "";
  const _endDate =
    end_date && end_date !== "Invalid Date"
      ? formatDateToLocal(new Date(end_date), formatString)
      : "";

  return (
    <>
      <CustomPopover
        type="anchor"
        anchorEl={anchorElDate}
        onClose={onCloseDatePopOver}
        paperStyle={{
          border: 0,
        }}
        iconStyle={{ p: 0 }}
        showRight
        icon
        hoverBG={"transparent"}
        justifyContent="flex-start"
        iconView={
          <FilterBaseView
            icon={
              <MdOutlineDateRange
                style={{ fontSize: "1.1rem", marginTop: "1px" }}
              />
            }
            heading={hideHeading ? null : "Date_Range"}
            tooltip={"tooltip_Date_Range"}
            Placeholder={Placeholder}
            maxWidth="fit-content"
            // isActive={isActive}
            isActive={false}
            value={
              _startDate || _endDate ? `${_startDate} ~ ${_endDate}` : null
            }
            onClick={onClickDatePopOver}
            onClear={onClickReset}
          />
        }
      >
        <DateView
          start_date={start_date || defaultStart}
          end_date={end_date || defaultEnd}
          onChangeDate={onChangeDate}
          RangeType={RangeType}
          onOk={onOk}
          autoClose={autoClose}
          showClear={showClear}
          headerView={headerView}
          onCloseDatePopOver={onCloseDatePopOver}
        />
      </CustomPopover>
    </>
  );
};

export default DateRangePicker;

const DateView = ({
  onCloseDatePopOver,
  start_date,
  end_date,
  onChangeDate,
  onOk,
  RangeType,
  autoClose,
  showClear,
  headerView,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState({
    start_date: start_date,
    end_date: end_date,
  });

  useDebounce(
    () => {
      if (onChangeDate) {
        onChangeDate(selectedDate);
      }
    },
    500,
    [selectedDate]
  );

  const onClickOk = () => {
    if (onOk) {
      setLoading(true);
      onOk(selectedDate, onCloseDatePopOver);
      if (autoClose) {
        onCloseDatePopOver();
      }
    }
  };

  const onClickReset = () => {
    if (onOk) {
      const obj = {
        start_date: null,
        end_date: null,
      };
      setSelectedDate(obj);
      onOk(obj, onCloseDatePopOver);
      if (autoClose) {
        onCloseDatePopOver();
      }
    }
  };

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        pr: "0.5rem",
      }}
    >
      <div style={{ display: "flex" }}>
        {RangeType === "Monthly" ? (
          <PresetView setSelectedDate={setSelectedDate} />
        ) : null}
        <div>
          <div
            style={{
              display: "flex",
              height: "fit-content",
            }}
          >
            <OptionView
              date={selectedDate?.start_date}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              RangeType={RangeType}
              type="start_date"
            />
            <Divider
              flexItem
              orientation="vertical"
              sx={{
                color: theme.palette.color.slate[700],
                mr: "0.4rem",
              }}
            />
            <OptionView
              date={selectedDate?.end_date}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              RangeType={RangeType}
              type="end_date"
            />
          </div>
          <div
            style={{
              display: "flex",
              height: "fit-content",
            }}
          >
            <OptionView
              date={selectedDate?.start_date}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              RangeType={"Daily"}
              type="start_date"
              hideYear
            />
            <Divider
              flexItem
              orientation="vertical"
              sx={{
                color: theme.palette.color.slate[700],
                mr: "0.4rem",
              }}
            />
            <OptionView
              date={selectedDate?.end_date}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              RangeType={"Daily"}
              type="end_date"
              hideYear
            />
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {headerView || <span></span>}
        {showClear ? (
          <Button
            onClick={onClickReset}
            sx={{
              width: "fit-content",
              height: "2rem",
              my: "0.8rem",
              mr: "1rem",
              color: theme.palette.color.slate[700],
              backgroundColor: theme.palette.color.slate[100],
              fontSize: "1rem",
              fontWeight: 600,
              lineHeight: "normal",
              padding: "0.5rem 1rem",
              textTransform: "initial",
              "&:hover": {
                backgroundColor: theme.palette.color.slate[200],
              },
            }}
          >
            {t("Reset Filter")}
          </Button>
        ) : null}
        <Button
          loading={loading}
          onClick={onClickOk}
          sx={{
            width: "fit-content",
            // height: "2rem",
            my: "0.8rem",
            mr: "0.75rem",
            color: theme.palette.color.white,
            backgroundColor: theme.palette.color.slate[700],
            fontSize: "0.9rem",
            fontWeight: 600,
            lineHeight: "normal",
            padding: "0.5rem 1rem",
            textTransform: "initial",
            "&:hover": {
              backgroundColor: theme.palette.color.slate[800],
            },
          }}
        >
          {loading ? "" : t("Apply")}
        </Button>
      </div>
    </Box>
  );
};

const PresetView = ({ setSelectedDate }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const handleRangeSelection = (range) => {
    const today = new Date();
    if (range === "Today") {
      setSelectedDate({
        start_date: format(startOfDay(today), "yyyy-MM-dd"),
        end_date: format(endOfDay(today), "yyyy-MM-dd"),
      });
    }
    if (range === "Yesterday") {
      setSelectedDate({
        start_date: format(subDays(today, 1), "yyyy-MM-dd"),
        end_date: format(subDays(today, 1), "yyyy-MM-dd"),
      });
    }
    if (range === "This Week") {
      setSelectedDate({
        start_date: format(startOfWeek(today), "yyyy-MM-dd"),
        end_date: format(endOfWeek(today), "yyyy-MM-dd"),
      });
    }
    if (range === "Last Week") {
      setSelectedDate({
        start_date: format(startOfWeek(subWeeks(today, 1)), "yyyy-MM-dd"),
        end_date: format(endOfWeek(subWeeks(today, 1)), "yyyy-MM-dd"),
      });
    }
    if (range === "This Month") {
      setSelectedDate({
        start_date: format(startOfMonth(today), "yyyy-MM-dd"),
        end_date: format(endOfMonth(today), "yyyy-MM-dd"),
      });
    }
    if (range === "Last Month") {
      setSelectedDate({
        start_date: format(startOfMonth(subMonths(today, 1)), "yyyy-MM-dd"),
        end_date: format(endOfMonth(today), "yyyy-MM-dd"),
      });
    }
    if (range === "Last 3 Months") {
      setSelectedDate({
        start_date: format(startOfMonth(subMonths(today, 2)), "yyyy-MM-dd"),
        end_date: format(endOfMonth(today), "yyyy-MM-dd"),
      });
    }
    if (range === "Last 6 Months") {
      setSelectedDate({
        start_date: format(startOfMonth(subMonths(today, 5)), "yyyy-MM-dd"),
        end_date: format(endOfMonth(today), "yyyy-MM-dd"),
      });
    }
    if (range === "Last 12 Months") {
      setSelectedDate({
        start_date: format(startOfMonth(subMonths(today, 11)), "yyyy-MM-dd"),
        end_date: format(endOfMonth(today), "yyyy-MM-dd"),
      });
    }
    if (range === "This Quarter") {
      setSelectedDate({
        start_date: format(startOfQuarter(today), "yyyy-MM-dd"),
        end_date: format(endOfQuarter(today), "yyyy-MM-dd"),
      });
    }
    if (range === "Last Quarter") {
      setSelectedDate({
        start_date: format(startOfQuarter(subMonths(today, 3)), "yyyy-MM-dd"),
        end_date: format(endOfQuarter(subMonths(today, 3)), "yyyy-MM-dd"),
      });
    }
    if (range === "Last Year") {
      const lastYearDate = subYears(today, 1);
      setSelectedDate({
        start_date: format(startOfYear(lastYearDate), "yyyy-MM-dd"),
        end_date: format(endOfYear(lastYearDate), "yyyy-MM-dd"),
      });
    }
    if (range === "This Year") {
      setSelectedDate({
        start_date: format(startOfYear(today), "yyyy-MM-dd"),
        end_date: format(endOfYear(today), "yyyy-MM-dd"),
      });
    }
  };
  return (
    <ButtonGroup
      sx={{
        display: "flex",
        flexDirection: "column",
        mt: "1.5rem",
        ml: "0.8rem",
        "& .MuiButton-root": {
          borderRadius: "8px !important",
          color: theme.palette.color.slate[700],
          fontWeight: 600,
          border: "1px solid transparent",
          "&:hover": {
            backgroundColor: theme.palette.primary[50],
            borderColor: theme.palette.primary[500],
          },
        },
      }}
    >
      {PRESETS?.map((item) => (
        <Button
          key={item}
          onClick={() => handleRangeSelection(item)}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          {t(item)}
        </Button>
      ))}
    </ButtonGroup>
  );
};

const OptionView = ({
  date = new Date(),
  selectedDate,
  setSelectedDate,
  type,
  RangeType,
  hideYear,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  //state
  const [viewType, setViewType] = useState(RangeType);

  //data
  const QuarterToMonthIndex =
    type === "start_date" ? QuarterToStartMonthIndex : QuarterToEndMonthIndex;

  const selectedMonth = useMemo(() => {
    return new Date(date).getMonth();
  }, [date]);

  const selectedYear = useMemo(() => {
    return getYear(new Date(date));
  }, [date]);

  const daysInMonth = useMemo(() => {
    if (viewType !== "Daily") return 0;
    return getDaysInMonth(new Date(selectedYear, selectedMonth));
  }, [viewType, selectedYear, selectedMonth]);

  const previousMonthDays = useMemo(() => {
    if (viewType !== "Daily") return [];
    const _date = new Date(date);
    const month = _date.getMonth();
    const year = _date.getFullYear();
    // Get the first day of the current month
    const firstDayOfMonth = new Date(year, month, 1);
    const firstDayWeekday = firstDayOfMonth.getDay(); // Sunday = 0, Monday = 1, ..., Saturday = 6

    // Adjust to make Monday = 0, Sunday = 6
    const adjustedWeekday = (firstDayWeekday + 6) % 7;

    // Get the previous month
    const previousMonth = month === 0 ? 11 : month - 1;
    const previousYear = month === 0 ? year - 1 : year;

    // Get the number of days in the previous month
    const daysInPreviousMonth = getDaysInMonth(
      new Date(previousYear, previousMonth)
    );

    // Calculate the dates we need from the previous month
    const previousMonthDays = Array.from(
      { length: adjustedWeekday },
      (_, i) => {
        return daysInPreviousMonth - adjustedWeekday + i + 1;
      }
    );

    return previousMonthDays;
  }, [date, viewType]);

  const nextMonthDays = useMemo(() => {
    if (viewType !== "Daily") return [];
    const _date = new Date(date);
    const month = _date.getMonth();
    const year = _date.getFullYear();
    // Get the last day of the current month
    const lastDayOfMonth = new Date(year, month + 1, 0); // Last day of the current month
    const lastDayWeekday = lastDayOfMonth.getDay(); // Sunday = 0, Monday = 1, ..., Saturday = 6

    // Adjust to make Monday = 0, Sunday = 6
    const adjustedWeekday = (lastDayWeekday + 6) % 7;

    // Number of days needed to fill the week (next month's days)
    const daysToAdd = 6 - adjustedWeekday;

    // Calculate the days for the next month (starting from 1)
    const nextMonthDays = Array.from({ length: daysToAdd }, (_, i) => i + 1);

    return nextMonthDays;
  }, [date, viewType]);

  // Adjust DayOptions to include the previous month's days, current month's days, and next month's days
  const DayOptions = useMemo(() => {
    if (viewType !== "Daily") return [];
    return Array.from({ length: daysInMonth }, (_, i) => i + 1);
  }, [daysInMonth, viewType]);

  const ListData = useMemo(() => {
    if (viewType === "Daily") {
      return DayOptions;
    }
    if (viewType === "Monthly") {
      return MonthOptions;
    }
    if (viewType === "Quarterly") {
      return QuarterOptions;
    }
    if (viewType === "Annually") {
      return YearOptions;
    }
  }, [DayOptions, viewType]);

  //functions
  const setDate = (option) => {
    const _date = set(new Date(date), option);
    setSelectedDate((prev) => ({
      ...prev,
      [type]: format(_date, "yyyy-MM-dd"),
    }));
  };

  const handleMonthClick = (item, monthIndex) => {
    if (viewType === "Daily") {
      setDate({ date: item });
    }
    if (viewType === "Monthly") {
      setDate({ month: monthIndex });
    }
    if (viewType === "Quarterly") {
      const _quarter = Number(item?.replace("Q", ""));
      const _monthIndex = QuarterToMonthIndex?.[_quarter - 1] || 0;
      setDate({ month: _monthIndex });
    }
    if (viewType === "Annually") {
      setDate({ year: item });
      setViewType(RangeType);
    }
  };

  const clickRight = () => {
    setDate({ year: selectedYear + 1 });
  };

  const clickLeft = () => {
    setDate({ year: selectedYear - 1 });
  };

  const onClickYear = () => {
    setViewType((viewType) =>
      viewType === "Annually" ? RangeType : "Annually"
    );
  };

  //life cycle
  useDebounce(
    () => {
      if (
        selectedDate?.start_date &&
        new Date(selectedDate?.start_date) > new Date(selectedDate?.end_date)
      ) {
        setSelectedDate((prev) => ({
          ...prev,
          end_date: format(
            endOfMonth(new Date(selectedDate?.start_date)),
            "yyyy-MM-dd"
          ),
        }));
      }
    },
    300,
    [selectedDate?.start_date],
    true
  );

  return (
    <Box
      sx={{
        py: "0.5rem",
        pl: "0.5rem",
      }}
    >
      {hideYear ? null : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBlock: "1rem",
            }}
          >
            <WestIcon
              onClick={clickLeft}
              sx={{
                color: "#64748B",
                cursor: "pointer",
                mr: "3rem",
                transition: "transform 0.2s ease",
                "&:hover": {
                  transform: "scale(1.2)",
                },
              }}
            />
            <p
              onClick={onClickYear}
              style={{
                fontSize: "1rem",
                fontWeight: 600,
                lineHeight: "normal",
                fontFamily: "Inter",
                color: "#334155",
                cursor: "pointer",
                transition: "color 0.3s ease",
                "&:hover": {
                  color: "#1E293B",
                },
              }}
            >
              {selectedYear}
            </p>
            <EastIcon
              onClick={clickRight}
              sx={{
                color: "#64748B",
                cursor: "pointer",
                ml: "3rem",
                transition: "transform 0.2s ease",
                "&:hover": {
                  transform: "scale(1.2)",
                },
              }}
            />
          </div>
        </div>
      )}
      {viewType !== "Daily" ? null : (
        <Stack
          sx={{
            width: "19rem",
            display: "flex",
            justifyContent: "flex-start",
            gap: "0.5rem",
            transition: "height 0.3s ease",
            flexWrap: "wrap",
            alignItems: "center",
            flexDirection: "row",
            mt: "1rem",
            mb: "0.5rem",
          }}
        >
          {WeeksOptions?.map((day) => (
            <Typography
              key={day}
              sx={{
                width: "2.13rem",
                fontWeight: 700,
                textAlign: "center",
                fontSize: "0.8rem",
                color: theme.palette.color.slate[800],
              }}
            >
              {t(day)}
            </Typography>
          ))}
        </Stack>
      )}

      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          width: "19rem",
          height: viewType === "Daily" ? "unset" : "10rem",
          overflowY: "auto",
          justifyContent: "flex-start",
          gap: "0.5rem",
          transition: "height 0.3s ease",
          ...theme.thinScrollBar,
        }}
      >
        {viewType === "Daily"
          ? previousMonthDays?.map((item) => (
              <ViewItem
                key={item}
                item={item}
                disabled={true}
                viewType={viewType}
                theme={theme}
                notAllowed
              />
            ))
          : null}
        {ListData?.map((item, index) => {
          if (!date || date?.toString() === "Invalid Date") {
            return null;
          }

          let option = null;
          if (viewType === "Daily") {
            option = { date: item };
          }
          if (viewType === "Monthly") {
            option = { month: index };
          }
          if (viewType === "Quarterly") {
            const _quarter = Number(item?.replace("Q", ""));
            const _monthIndex = QuarterToMonthIndex?.[_quarter - 1] || 0;
            option = { month: _monthIndex };
          }
          if (viewType === "Annually") {
            option = { year: item };
          }
          const ItemDate = set(new Date(date), option);
          if (!ItemDate || ItemDate === "Invalid Date") {
            return null;
          }
          const FormattedItemDate = format(ItemDate, "yyyy-MM-dd");
          const isSelected =
            FormattedItemDate === format(new Date(date), "yyyy-MM-dd");

          const disabled =
            type === "end_date" && selectedDate?.start_date
              ? ItemDate < new Date(selectedDate?.start_date)
              : item?.disabled || false;

          return (
            <ViewItem
              key={FormattedItemDate}
              item={item}
              onClick={() => handleMonthClick(item, index)}
              disabled={disabled}
              isSelected={isSelected}
              viewType={viewType}
              theme={theme}
            />
          );
        })}
        {viewType === "Daily"
          ? nextMonthDays?.map((item) => (
              <ViewItem
                key={item}
                item={item}
                disabled={true}
                notAllowed
                viewType={viewType}
                theme={theme}
              />
            ))
          : null}
      </Box>
    </Box>
  );
};

const ViewItem = ({
  item,
  disabled,
  onClick,
  viewType,
  theme,
  isSelected,
  notAllowed,
}) => {
  const { t } = useTranslation();
  let width = "2rem";

  if (viewType === "Monthly") {
    width = "4.1rem";
  }
  if (viewType === "Quarterly") {
    width = "6rem";
  }
  if (viewType === "Annually") {
    width = "4rem";
  }
  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      sx={{
        width,
        color: theme.palette.color.slate[notAllowed ? 900 : 700],
        backgroundColor: isSelected ? theme.palette.primary[50] : "transparent",
        borderColor: theme.palette.primary[500],
        border: `1px solid ${isSelected ? theme.palette.primary[500] : theme.palette.color.slate[notAllowed ? 100 : 300]}`,
        fontSize: "0.8rem",
        fontWeight: 600,
        lineHeight: "normal",
        padding: "0.5rem 1rem",
        textTransform: "initial",
        minWidth: "auto",
        "&:hover": {
          backgroundColor: isSelected
            ? theme.palette.primary[50]
            : theme.palette.color.slate[100],
        },
      }}
    >
      {t(item)}
    </Button>
  );
};
