import {
  ListItemIcon,
  ListItemText,
  Typography,
  ListItem,
  useTheme,
  Divider,
  Drawer,
  List,
  Box,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import React from "react";

const DrawerView = ({ drawerWidth = 18, data, selectedTab }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();

  //function
  const onClickTab = (item) => {
    if (item?.url[0]) {
      navigate(item?.url[0]);
    }
  };

  return (
    <Drawer
      elevation={1}
      sx={{
        width: `${drawerWidth}rem`,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          backgroundColor: theme.palette.color.slate[100],
          width: `${drawerWidth}rem`,
          boxSizing: "border-box",
          position: "relative",
          borderRadius: 0,
          overflowY: "auto",
          ...theme.thinScrollBar,
        },
        "& .MuiDivider-root": {
          borderBottom: `0.3px solid ${theme.palette.color.slate[300]}`,
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <List
        sx={{
          display: "contents",
          justifyContent: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: "1.3rem",
            fontWeight: 800,
            alignSelf: "center",
            mt: "1.5rem",
            mb: "1rem",
            pl: "1.85rem",
            color: theme.palette.color.slate[700],
            width: "100%",
            textTransform: "initial",
          }}
        >
          {t("Account Settings")}
        </Typography>

        {data?.map((item) => {
          const selected = selectedTab === item.id;
          return (
            <Box
              key={item.id}
              sx={{
                display: "flex",
                flexDirection: "column",
                "& .MuiListItem-root": {
                  paddingBlock: "0.5rem",
                  paddingLeft: "1.5rem",
                  paddingRight: 0,
                  alignItems: "start",
                  transition: "all 500ms",

                  backgroundColor: selected
                    ? theme.palette.color.slate[200]
                    : null,
                  "&:hover": {
                    cursor: "pointer",

                    color: theme.palette.color.slate[700],
                    backgroundColor: theme.palette.color.slate[300],
                  },
                },
              }}
            >
              <ListItem key={item.id} onClick={() => onClickTab(item)}>
                <ListItemIcon
                  sx={{
                    minWidth: "2.1rem",
                    mt: "0.5rem",
                    color: selected
                      ? theme.palette.primary.dark
                      : theme.palette.color.slate[600],
                  }}
                >
                  {item?.icon}
                </ListItemIcon>
                <ListItemText
                  primary={t(item?.title)}
                  secondary={t(`${item?.title}_description`)}
                  sx={{
                    "& .MuiListItemText-primary": {
                      fontWeight: selected
                        ? theme.typography.fontWeightBold
                        : theme.typography.fontWeightMediumBold,
                      color: selected
                        ? theme.palette.primary.dark
                        : theme.palette.color.slate[600],
                      fontSize: "1rem",
                    },
                    "& .MuiListItemText-secondary": {
                      pr: "0.75rem",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitLineClamp: "2",
                      WebkitBoxOrient: "vertical",
                      fontSize: "0.8rem",
                      color: selected
                        ? theme.palette.primary.dark
                        : theme.palette.color.description,
                    },
                  }}
                />
              </ListItem>

              {item?.divider && (
                <Divider
                  sx={{
                    width: `${drawerWidth - 1.25}rem`,
                    alignSelf: "center",
                  }}
                />
              )}
            </Box>
          );
        })}
      </List>
    </Drawer>
  );
};
export default DrawerView;
