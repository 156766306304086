import { useSelector } from "react-redux";
import { useIsFetching } from "@tanstack/react-query";
import { queryClient } from "../App";
import {
  addMonths,
  endOfMonth,
  endOfYear,
  format,
  startOfMonth,
  startOfYear,
  subMonths,
} from "date-fns";
import { Constant } from "../Helper";
import { getStaffQueryKey } from "../Helper/data";

// Hook
function useStatusHook(key, payload) {
  const appliedFilterlist = useSelector(
    (state) => state.globalSlice.appliedFilterlist
  );
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);
  const advanceVat = useSelector((state) => state.globalSlice.advanceVat);
  const startDate = useSelector((state) => state.reportSlice?.startDate);
  const endDate = useSelector((state) => state.reportSlice?.endDate);

  let queryKey = [];
  if (key === "normal_monthly_transactions") {
    queryKey = [
      "transactions",
      {
        dataset: dataSetData?.uuid,
        start_date: dataSetData?.start_date,
        apiType: "normal_monthly_transactions",
        type: payload?.type,
        state: payload?.state,
        scenario: payload?.scenario,
        category: appliedFilterlist?.kanban?.selectedCategory,
        cost_unit: appliedFilterlist?.kanban?.selectedCostUnits,
        groupType: appliedFilterlist?.kanban?.groupType || "scenarios",
      },
    ];
  }
  if (key === "Transaction_monthly_statistic") {
    queryKey = [
      "transactions",
      {
        dataset: dataSetData?.uuid,
        apiType: "monthly",
        parentApiType: "Transaction_monthly_statistic",
        from_payment_date: startOfMonth(
          subMonths(new Date(), Constant.Past_Month)
        ),
        to_payment_date: endOfMonth(
          subMonths(new Date(dataSetData?.start_date), 7)
        ),
      },
    ];
  }
  if (key === "transaction_monthly_chart") {
    let _start_date = new Date(dataSetData?.start_date);
    const _last_month_date = startOfMonth(subMonths(new Date(), 1));
    if (_start_date > _last_month_date) {
      _start_date = _last_month_date;
    }
    const from_payment_date = new Date(_start_date);

    const to_payment_date = endOfMonth(
      addMonths(new Date(dataSetData?.start_date), Constant.column_count)
    );
    let vat_pay_months = 0;
    if (advanceVat && advanceVat?.enabled) {
      if (
        String(advanceVat?.frequency) === "1" &&
        !advanceVat?.permanent_extension
      ) {
        vat_pay_months = 1;
      }

      if (
        String(advanceVat?.frequency) === "1" &&
        advanceVat?.permanent_extension
      ) {
        vat_pay_months = 2;
      }

      if (
        String(advanceVat?.frequency) === "2" &&
        !advanceVat?.permanent_extension
      ) {
        vat_pay_months = 3;
      }
      if (
        String(advanceVat?.frequency) === "2" &&
        advanceVat?.permanent_extension
      ) {
        vat_pay_months = 4;
      }
    } else {
      vat_pay_months = 1;
    }
    queryKey = [
      "transactions",
      {
        dataset: dataSetData?.uuid,
        apiType: "transaction_monthly_chart",
        from_date: from_payment_date,
        to_date: to_payment_date,
        subMonthNumber: vat_pay_months,
      },
    ];
  }
  if (key === "transaction_daily_chart") {
    let _start_date = new Date(dataSetData?.start_date);
    const _last_month_date = startOfMonth(subMonths(new Date(), 1));
    if (_start_date > _last_month_date) {
      _start_date = _last_month_date;
    }
    const from_payment_date = new Date(_start_date);

    const to_payment_date = endOfMonth(
      addMonths(new Date(dataSetData?.start_date), Constant.column_count)
    );

    queryKey = [
      "transactions",
      {
        dataset: dataSetData?.uuid,
        apiType: "transaction_daily_chart",
        from_payment_date,
        to_payment_date,
      },
    ];
  }
  if (key === "ReportPages") {
    queryKey = [
      "ReportPages",
      {
        dataset: dataSetData?.uuid,
      },
    ];
  }
  if (key === "period_data") {
    const period_start_date = startDate
      ? format(new Date(startDate), "yyyy-MM-dd")
      : format(startOfYear(new Date()), "yyyy-MM-dd");
    const period_end_date = startDate
      ? format(new Date(endDate), "yyyy-MM-dd")
      : format(endOfYear(new Date()), "yyyy-MM-dd");
    queryKey = [
      "transactions",
      {
        dataset: dataSetData?.uuid,
        apiType: "period_data",
        from_payment_date: period_start_date,
        to_payment_date: period_end_date,
      },
    ];
  }
  if (key === "staff") {
    queryKey = getStaffQueryKey();
  }
  if (!key) {
    queryKey = payload?.queryKey;
  }

  const isFetching = useIsFetching({
    queryKey: queryKey,
  });
  const data = queryClient.getQueryData(queryKey);

  return { isFetching: Boolean(isFetching), data };
}

export default useStatusHook;
