import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Typography } from "@mui/material";

import CategoryTreeSelect from "./CategoryTreeSelect";
import CategoryChip from "../CategoryChip";

const CategoryTreeSelection = ({
  disabled,
  onClickCategoryTitle,
  categoryID = null,
  onDelete,
  hideTitle,
  title = "category",
  type = 3,
  cardID = null,
  isDataSet = false,
  isGlobal = false,
  noCategoryLabel,
  anchorOrigin = {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin = {
    vertical: "top",
    horizontal: "center",
  },
  width,
}) => {
  const { t } = useTranslation();

  //state
  const [anchorEl, setAnchorEl] = useState(null);
  const [category, setCategoryID] = useState(categoryID);

  useEffect(() => {
    setCategoryID(categoryID);
  }, [cardID, categoryID]);

  //function
  const onClickCategory = (e) => {
    if (!disabled) {
      setAnchorEl(e.currentTarget);
    }
  };

  const handleClosePopOver = (e) => {
    e?.stopPropagation();
    setAnchorEl(null);
  };

  const onClickTitle = (e, item) => {
    setAnchorEl(null);
    onClickCategoryTitle(e, item);
    setCategoryID(item?.uuid);
  };

  return (
    <span>
      {!hideTitle && (
        <Typography
          variant="subtitle2"
          color="color.mainTitle"
          fontWeight={"fontWeightBold"}
          sx={{
            mb: "0.25rem",
          }}
        >
          {t(title)}
        </Typography>
      )}
      <CategoryChip
        onClick={onClickCategory}
        onDelete={onDelete}
        showDelete={category}
        categoryId={category}
        width={width}
        disabled={disabled}
        noCategoryLabel={noCategoryLabel}
      />
      <CategoryTreeSelect
        isDataSet={isDataSet}
        isGlobal={isGlobal}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        anchorEl={anchorEl}
        handleClosePopOver={handleClosePopOver}
        onClickCategoryTitle={onClickTitle}
        type={type}
        id="another overlay"
      />
    </span>
  );
};
export default CategoryTreeSelection;
