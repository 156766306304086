import { Button, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import { useContext } from "react";

import { GlobalContext } from "../../../GlobalContextWrapper";
import KeyboardSortCutKey from "../../KeyboardSortCutKey";

const LiveSearch = () => {
  const theme = useTheme();
  const globalContext = useContext(GlobalContext);
  const liveSearchRef = globalContext?.liveSearchRef;

  const onClick = () => {
    liveSearchRef?.current?.handleOpen();
  };

  return (
    <Button
      onClick={onClick}
      variant="contained"
      sx={{
        padding: "0.5rem 0.75rem",
        fontSize: "0.875rem",
        borderRadius: theme.borderRadius.main,
        backgroundColor: theme.palette.color.white,
        boxShadow: "none",
        fontFamily: "monospace",
        display: "inline-flex",
        alignItems: "center",
        gap: "1rem",
        "&:hover": {
          backgroundColor: theme.palette.color.slate[50],
        },
      }}
    >
      <Typography
        variant="body1"
        fontWeight={"fontWeightMediumBold"}
        color="color.slate.700"
        sx={{}}
      >
        Search & Quick action
      </Typography>
      <KeyboardSortCutKey theme={theme} keys={["⌘", "K"]} action={onClick} />
    </Button>
  );
};

export default LiveSearch;
