import {
  eachMonthOfInterval,
  startOfMonth,
  endOfMonth,
  subMonths,
  format,
} from "date-fns";
import {
  Typography,
  Skeleton,
  useTheme,
  Tooltip,
  Chip,
  Box,
} from "@mui/material";
import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useIsFetching, useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import _ from "underscore";

import {
  fetchConnectedBookedBalances,
  formatAmount,
  cloneDeep,
} from "../../../Helper/data";
import IntegrationsDialogView from "../../../components/Header/DatasetHeader/IntegrationsDialogView";
import { useDeferredTimeout } from "../../../hooks/useDeferredTimeout";
import ComponentLoader from "../../../components/ComponentLoader";
import { setDataSource } from "../../../store/slices/global";
import EndPoints from "../../../APICall/EndPoints";
import AccountsChart from "./AccountsChart";
import { Constant } from "../../../Helper";
import AccountView from "./AccountView";
import DsCountView from "./DsCountView";
import HeaderView from "../HeaderView";
import APICall from "../../../APICall";

const BankAccount = ({ dsViewWidth, chart_data, setChartData }) => {
  const navigate = useNavigate();
  const isDSChanged = useRef(false);
  const dispatch = useDispatch();
  const timeoutId = useRef(null);

  //redux
  const isFirstLoginOverlayOpen = useSelector(
    (state) => state.globalSlice.isFirstLoginOverlayOpen
  );
  const accounts = useSelector((state) => state.globalSlice.accounts);
  const dataSource = useSelector((state) => state.globalSlice.dataSource);

  //state
  const [accountingTab, setAccountingTab] = useState("all");
  const [accountingValue, setAccountingValue] = useState({
    connected: [],
    disconnected: [],
    archived: [],
  });
  const [isOpen, setIsOpen] = useState(false);
  const [tab, setTab] = useState("all");
  const [disableRefresh, setDisableRefresh] = useState(false);

  const [value, setValue] = useState({
    connected: [],
    disconnected: [],
    archived: [],
  });

  const DS = useMemo(() => {
    return dataSource?.filter(
      (o1) =>
        o1?.is_connected &&
        (o1.type === 1 || o1.type === 12 ? !o1.internal_dataset : false)
    );
  }, [dataSource]);

  const BookedParams = useMemo(() => {
    let obj = {
      apiParams: [],
      from_date: null,
      to_date: null,
    };
    if (DS?.length > 0) {
      const connectedDS = DS?.map((o1) => o1.uuid);
      obj.to_date = format(endOfMonth(new Date()), "yyyy-MM-dd");
      obj.from_date = format(
        startOfMonth(subMonths(new Date(), 11)),
        "yyyy-MM-dd"
      );
      if (connectedDS?.length > 0) {
        const datasetLinkedAccount = accounts?.filter((o1) =>
          connectedDS?.includes(o1?.data_source)
        );

        if (datasetLinkedAccount?.length > 0) {
          datasetLinkedAccount?.forEach((element) => {
            element?.balances.forEach((o1) => {
              if (o1.type === 12 ? o1.name === "booked" : true) {
                obj.apiParams.push({
                  accountID: element?.uuid,
                  balanceID: o1?.uuid,
                  currency: element?.currency,
                });
              }
            });
          });
        }
      }
    }

    return obj;
  }, [DS, accounts]);

  const { apiParams, from_date, to_date } = BookedParams;
  const AllApiParams = useDeferredTimeout({
    value: apiParams,
    timeoutMs: 2000,
  });

  const Booked_Balance = useQuery({
    queryKey: [
      "transactions",
      {
        apiType: "booked_balance",
        location: "start_page",
        type: "monthly",
        AllApiParams,
      },
    ],
    queryFn: ({ signal }) => {
      const result = fetchConnectedBookedBalances({
        array: cloneDeep(AllApiParams),
        params: {
          config: {
            signal,
          },
          from_date,
          to_date,
        },
      });
      if (result) {
        return result;
      }
    },
    backgroundFetch: true,
    enabled:
      !isFirstLoginOverlayOpen &&
      !!DS &&
      DS?.length > 0 &&
      !!from_date &&
      AllApiParams?.length > 0,
    priority: 5,
    staleTime: 30000,
  });

  const currencyList = useMemo(() => {
    if (accounts) {
      const list = accounts?.map((a) => a.currency);
      let array = [];
      Object.keys(Constant?.CurrencyPrefix)?.forEach((currency) => {
        if (list?.includes(currency)) array.push(currency);
      });
      return array;
    } else {
      return [];
    }
  }, [accounts]);

  //data
  const typeList = useMemo(() => {
    return dataSource?.map((o1) => o1.type);
  }, [dataSource]);

  const data = useMemo(() => {
    let array = [];
    if (Booked_Balance?.data?.data && DS?.length > 0) {
      const Booked_BalanceByMonth = _.groupBy(
        Booked_Balance?.data?.data,
        ({ month }) => month
      );
      const DateRange = eachMonthOfInterval({
        start: new Date(from_date),
        end: new Date(to_date),
      });
      let latest_balance = {};

      DateRange?.forEach((date) => {
        const formattedDate = format(date, "yyyy-MM");
        const monthObj = Booked_BalanceByMonth?.[formattedDate]?.[0] || {};
        let obj = monthObj;
        apiParams?.forEach(({ accountID }) => {
          if (!obj?.hasOwnProperty(accountID)) {
            obj[accountID] = latest_balance?.[accountID] || 0;
          }
          latest_balance[accountID] = monthObj?.[accountID] || 0;
        });
        obj["due_date"] = formattedDate;
        array.push(obj);
      });
    }

    return array?.sort((a, b) => new Date(a?.due_date) - new Date(b?.due_date));
  }, [Booked_Balance?.data?.data, DS?.length, apiParams, from_date, to_date]);

  //lifecycle
  useEffect(() => {
    return () => {
      clearTimeout(timeoutId.current); // Clear the timeout if the component unmounts
    };
  }, []);

  useEffect(() => {
    setChartData({ isFetching: Booked_Balance?.isFetching, data });
  }, [Booked_Balance?.isFetching, data, setChartData]);

  if (!apiParams || apiParams?.length === 0) {
    return (
      <ComponentLoader
        key1={"start_page_integration_no_data_text01"}
        key2={"start_page_integration_no_data_text02"}
        sx={{ width: `${dsViewWidth}rem` }}
      />
    );
  }

  //api
  const getDataSource = async () => {
    await APICall("get", EndPoints.integrations).then((response) => {
      if (response.status === 200 && response.data) {
        let data = response.data.results;
        dispatch(setDataSource(data));
      }
    });
  };

  const onClickEdit = () => {
    navigate("/settings/Integrations");
  };

  const handleClickClose = () => {
    setIsOpen(false);
    if (isDSChanged.current) {
      getDataSource();
    }
  };

  const onClickAddBank = () => {
    setIsOpen("add");
  };

  const onClickRefresh = () => {
    setDisableRefresh(true);

    timeoutId.current = setTimeout(() => {
      setDisableRefresh(false);
    }, 60000);
    Booked_Balance.refetch();
  };

  return (
    <Box
      sx={{
        width: `${dsViewWidth}rem`,
        height: "fit-content",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <HeaderView
        title="Bank Accounts"
        onClickEdit={onClickEdit}
        isManage
        component={
          <DsCountView
            value={value}
            setValue={setValue}
            setTab={setTab}
            tab={tab}
            dsType={[10, 12]}
          />
        }
        sx={{
          mb: "1.25rem",
        }}
      />
      <IntegrationsDialogView
        open={Boolean(isOpen)}
        type={isOpen}
        handleClickClose={handleClickClose}
        isDSChanged={isDSChanged}
      />
      {currencyList?.map((currency) => (
        <Fragment key={currency}>
          <Balances currency={currency} />
          <AccountsChart
            chart_data={chart_data}
            currency={currency}
            AllApiParams={AllApiParams}
            disableRefresh={disableRefresh}
            onClickRefresh={onClickRefresh}
          />
          <AccountView
            currency={currency}
            dsType={[10, 12, 1]}
            tab={tab}
            onClickAddBank={onClickAddBank}
            sx={{ mb: "4rem" }}
            // showTotal
          />
        </Fragment>
      ))}

      <Box
        sx={{
          width: "100%",
          // minHeight: "30rem",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <HeaderView
          title="Integrations"
          sx={{ mt: "4rem" }}
          onClickEdit={onClickEdit}
          component={
            <DsCountView
              value={accountingValue}
              setValue={setAccountingValue}
              setTab={setAccountingTab}
              tab={accountingTab}
              dsType={[19]}
            />
          }
        />
        <AccountView
          dsType={[19]}
          tab={accountingTab}
          onClickAddBank={onClickAddBank}
          sx={{ mt: "1.5rem" }}
        />

        {typeList?.includes(2) ? (
          <>
            <HeaderView
              title="Misc"
              sx={{ mt: "4rem" }}
              onClickEdit={onClickEdit}
            />
            <AccountView
              dsType={[2]}
              sx={{ mt: "1.5rem" }}
              onClickAddBank={onClickAddBank}
            />
          </>
        ) : null}
      </Box>
    </Box>
  );
};

export default BankAccount;

const ItemView = ({
  title,
  value,
  color = "slate",
  tooltip = "",
  backgroundColor,
  border,
  loading,
  currency,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  //redux
  const testLoading = useSelector((state) => state.appSlice.testLoading);

  if (loading || testLoading) {
    return (
      <Skeleton
        animation="wave"
        variant="rounded"
        height={"3rem"}
        sx={{
          borderRadius: "10rem",
          width: { xs: "70%", md: "11rem" },
        }}
      />
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        mx: "0.2rem",
        width: { xs: "70%", md: "11rem" },
        my: { xs: "1rem", md: 0 },
      }}
    >
      <Chip
        label={formatAmount({
          amount: value,
          dataset: {
            currency,
          },
        })}
        variant="outlined"
        sx={{
          borderRadius: "3rem",
          py: "0.75rem",
          height: "fit-content",
          border: border ? `1px solid ${color}` : 0,
          backgroundColor: backgroundColor,
          color: color,
          fontWeight: 600,
          fontSize: { xs: "1.6rem", md: "1rem" },
        }}
      />
      <Typography
        sx={{
          fontSize: "0.8rem",
          color: theme.palette.color.slate[500],
          textAlign: "center",
          mt: "0.5rem",
          mr: "0.3rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {t(title)}
        <Tooltip title={t(tooltip)} followCursor leaveDelay={0}>
          <InfoOutlinedIcon
            sx={{ color: "inherit", fontSize: "0.85rem", ml: 1 }}
          />
        </Tooltip>
      </Typography>
    </Box>
  );
};

const Balances = ({ currency }) => {
  const theme = useTheme();
  const accounts = useSelector((state) => state.globalSlice.accounts);
  const dataSourceById = useSelector(
    (state) => state.globalSlice.dataSourceById
  );
  const isAllHeaderApiFetched = useSelector(
    (state) => state.commonSlice.isAllHeaderApiFetched
  );
  const isFetchingIntegrations = useIsFetching({ queryKey: ["integrations"] });
  const [value, setValue] = useState({
    balances: 0,
    liabilities: 0,
    available_amount: 0,
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (
      JSON.stringify(dataSourceById) !== "{}" &&
      accounts &&
      currency &&
      isFetchingIntegrations === 0 &&
      isAllHeaderApiFetched
    ) {
      setLoading(true);
      let _account = [];
      accounts?.forEach((o1) => {
        const ds = dataSourceById?.[o1?.data_source]?.[0];
        if (
          o1?.name !== "DEMO" &&
          ds?.uuid &&
          o1?.currency === currency &&
          !ds?.internal_dataset &&
          ds?.state !== 2 &&
          ds?.type !== 19
        ) {
          _account.push(o1);
        }
      });

      const balances = _account?.reduce((allTotal, account) => {
        const sum = account?.balances?.reduce((total, record) => {
          return (
            parseFloat(total) +
            parseFloat(
              !["Loan", "CREDIT_CARD", "available"].includes(record?.name)
                ? Number(record?.amount || 0) +
                    Number(record?.ignored_value || 0)
                : 0
            )
          );
        }, 0);
        return parseFloat(allTotal) + parseFloat(sum ?? 0);
      }, 0);
      const liabilities = _account?.reduce((allTotal, account) => {
        const sum = account?.balances?.reduce((total, record) => {
          const amount =
            Number(record?.amount || 0) + Number(record?.ignored_value || 0);
          return (
            parseFloat(total) +
            parseFloat(
              record?.name === "available"
                ? 0
                : amount < 0 || ["Loan", "CREDIT_CARD"].includes(record?.name)
                  ? -Math.abs(amount)
                  : 0
            )
          );
        }, 0);
        return parseFloat(allTotal) + parseFloat(sum ?? 0);
      }, 0);
      const available_amount = _account?.reduce((allTotal, account) => {
        const sum = account?.balances?.reduce((total, record) => {
          return (
            parseFloat(total) +
            parseFloat(account?.limit || 0) +
            parseFloat(
              ["Loan", "CREDIT_CARD"].includes(record?.name)
                ? -Math.abs(Number(record?.amount))
                : Number(record?.amount || 0) +
                    Number(record?.ignored_value || 0)
            )
          );
        }, 0);
        return parseFloat(allTotal) + parseFloat(sum ?? 0);
      }, 0);

      setValue({
        balances: balances?.toFixed(1),
        liabilities: liabilities?.toFixed(1),
        available_amount: available_amount?.toFixed(1),
      });
      setLoading(false);
    }
  }, [
    accounts,
    dataSourceById,
    isFetchingIntegrations,
    isAllHeaderApiFetched,
    currency,
  ]);

  return (
    <Box
      sx={{
        display: "flex",
        height: "fit-content",
        borderRadius: theme.borderRadius.main,
        backgroundColor: theme.palette.color.white,
        p: "1.5rem !important",
        flexDirection: { xs: "column", md: "row" },
        alignItems: "center",
        justifyContent: { xs: "center", md: "space-between" },
        boxShadow: theme.boxShadow,
        width: "100%",
        mb: "1.5rem",
      }}
    >
      <ItemView
        loading={loading}
        title="Balances"
        tooltip="Balances_tooltip"
        color={theme.palette.color.green[800]}
        backgroundColor={theme.palette.color.green[100]}
        value={value?.balances}
        currency={currency}
      />
      <ItemView
        loading={loading}
        title="Liabilities"
        tooltip="Liabilities_tooltip"
        color={theme.palette.color.slate[500]}
        backgroundColor={theme.palette.color.slate[100]}
        value={value?.liabilities}
        currency={currency}
      />
      <ItemView
        loading={loading}
        title="Available amount"
        tooltip="Available amount_tooltip"
        color={theme.palette.color.green[800]}
        backgroundColor={"transparent"}
        value={value?.available_amount}
        currency={currency}
        border
      />
    </Box>
  );
};
