import {
  circularProgressClasses,
  ClickAwayListener,
  CircularProgress,
  Typography,
  Popper,
  Button,
  Paper,
  Fade,
} from "@mui/material";
import {
  MdOutlineAccountBalance,
  MdOutlineDateRange,
  MdStackedBarChart,
  MdAreaChart,
  MdSsidChart,
} from "react-icons/md";
import DragIndicatorOutlinedIcon from "@mui/icons-material/DragIndicatorOutlined";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { TbChartAreaLineFilled, TbCircles, TbInfoSquare } from "react-icons/tb";
import { GrDocumentText, GrDocumentStore, GrMoney } from "react-icons/gr";
import { FaArrowUp, FaEuroSign, FaFilePdf } from "react-icons/fa";
import { Responsive, WidthProvider } from "react-grid-layout";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { BsLayoutTextSidebarReverse } from "react-icons/bs";
import { endOfYear, format, startOfYear } from "date-fns";
import { IoIosArrowRoundForward } from "react-icons/io";
import "/node_modules/react-grid-layout/css/styles.css";
import { useDispatch, useSelector } from "react-redux";
import "/node_modules/react-resizable/css/styles.css";
import { PiChartLineDownFill } from "react-icons/pi";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { de, enUS } from "date-fns/locale";
import { produce } from "immer";
import jsPDF from "jspdf";

import CategoryFilterView from "../../../../components/Overlay/ThemeFilter/Component/CategoryFilterView";
import DateRangePicker from "../../../../components/Overlay/ThemeFilter/Component/DateRangePicker";
import AddFilterPopOver from "../../../../components/Overlay/ThemeFilter/AddFilterPopover";
import AddFilterView from "../../../../components/Overlay/ThemeFilter/AddFilterView";
import ThemeToggle from "../../../../components/ToggleGroupeView/ThemeToggle";
import { setLayouts, setReportPage } from "../../../../store/slices/report";
import TailwindButton from "../../../../components/Overlay/TailwindButton";
import { cloneDeep, getTailwindColor } from "../../../../Helper/data";
import useDebounce from "../../../../hooks/3-useDebounce/useDebounce";
import ThemeFilter from "../../../../components/Overlay/ThemeFilter";
import LatestTransactionsWidget from "./LatestTransactionsWidget";
import useSubscriptions from "../../../../hooks/useSubscriptions";
import OverBudgetIncomeExpense from "./OverBudgetIncomeExpense";
import CashDevelopmentWidget from "./CashDevelopmentWidget";
import CommonFunctions from "./Component/CommonFunctions";
import ProfitabilityWidget from "./ProfitabilityWidget";
import InvoiceStatusWidget from "./InvoiceStatusWidget";
import Translate from "../../../../hooks/HOC/Translate";
import EndPoints from "../../../../APICall/EndPoints";
import OfferStatusWidget from "./OfferStatusWidget";
import { Color, Fonts } from "../../../../Helper";
import LeadStatusWidget from "./LeadStatusWidget";
import Icon from "../../../../components/Icon";
import BarChartWidget from "./BarChartWidget";
import { queryClient } from "../../../../App";
import BurnRateWidget from "./BurnRateWidget";
import APICall from "../../../../APICall";
import CostWidget from "./CostWidget";
import store from "../../../../store";
import theme from "../../../../theme";
import EurWidget from "./EurWidget";

const option1 = [
  { value: "Bars", icon: <MdStackedBarChart /> },
  { value: "Line", icon: <MdSsidChart /> },
];

const option2 = [
  { value: "Pie", label: "Pie Chart" },
  { value: "Table", label: "Table" },
];

const option3 = [
  { value: "All", label: "All" },
  { value: "Inflow", label: "Inflow" },
  { value: "Outflow", label: "Outflow" },
];

const ReportWidget = () => {
  const dispatch = useDispatch();
  const commonRef = useRef();
  const updateTimer = useRef(null);

  const ResponsiveGridLayout = useMemo(() => WidthProvider(Responsive), []);

  //redux
  const Page = useSelector((state) => state.reportSlice.Page);
  const layouts = useSelector((state) => state.reportSlice.layouts);
  if (!layouts) return null;
  if (!Page) return null;

  const batchUpdateWidgets = async (payload) => {
    await APICall(
      "put",
      `${EndPoints.reporting_dashboards}${Page?.uuid}/widgets/batch_update/`,
      payload
    ).then((response) => {
      if (response.status === 200 && response.data) {
        const _widgets = [...(response.data || [])]?.sort(
          (a, b) => a.position - b.position
        );
        commonRef.current?.updatePageWidgets({
          widgets: _widgets,
        });
      }
    });
  };

  const sortByYX = (a, b) => {
    if (a.y === b.y) {
      return a.x - b.x;
    }
    return a.y - b.y;
  };

  const onLayoutChange = (layout, layouts) => {
    let _layouts = [...(layouts?.lg || [])];
    _layouts?.sort(sortByYX);

    if (!global.reportManualUpdate) {
      dispatch(setLayouts({ type: "add_all", obj: cloneDeep(layouts) }));
    }
    if (_layouts?.length > 0) {
      clearTimeout(updateTimer.current);
      updateTimer.current = setTimeout(() => {
        if (global.reportManualUpdate) {
          global.reportManualUpdate = false;
        } else {
          let updateWidgetPositions = [];
          _layouts?.forEach((item, index) => {
            updateWidgetPositions.push({
              uuid: item?.i,
              width: item?.w < item?.minW ? item?.minW : item?.w,
              height: item?.h < item?.minH ? item?.minH : item?.h,
              position: index + 1,
            });
          });
          batchUpdateWidgets(updateWidgetPositions);
        }
      }, 1000);
    }
  };

  return (
    <div style={{ height: "100%", width: "99vw", marginBottom: "2rem" }}>
      <CommonFunctions ref={commonRef} />
      <ResponsiveGridLayout
        layouts={layouts || {}}
        onLayoutChange={onLayoutChange}
        draggableHandle={".widget_grab_icon"}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
        rowHeight={100}
      >
        {Page?.widgets?.map((widget) => {
          return (
            <div key={widget?.uuid}>
              <WidgetView widget={widget} commonRef={commonRef} />
            </div>
          );
        })}
      </ResponsiveGridLayout>
    </div>
  );
};
export default ReportWidget;

const WidgetView = memo(
  ({ widget, commonRef }) => {
    const [isSubscriptionValid] = useSubscriptions();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isFilterOpen = useRef(false);

    //state
    const [option, setOptions] = useState([]);
    const [chartType, setChartType] = useState([]);
    const [isPdfShowing, setPdfShowing] = useState(false);

    const isBarLine = [
      "profit-12-month",
      "income-12-month",
      "expenses-12-month",
    ].includes(widget?.type);
    const isPieTable = ["income-pie", "expense-pie"].includes(widget?.type);
    const isScenario = [
      "profitability",
      "cash-development",
      "over-budget-income",
      "over-budget-expense",
    ].includes(widget?.type);
    const isStateFilter = ["eur"].includes(widget?.type);
    const isCategoryFilter = [
      "eur",
      "income-12-month",
      "expenses-12-month",
      "income-pie",
      "expense-pie",
    ].includes(widget?.type);
    const isDateFilter = [
      "burn-rate-12-month",
      "profit-12-month",
      "income-12-month",
      "expenses-12-month",
      "eur",
      "income-pie",
      "expense-pie",
      "profitability",
      "invoice-status-over-time",
      "lead-status-over-time",
      "offer-status-over-time",
    ].includes(widget?.type);
    const isScenarioFilter = [
      "invoice-status-over-time",
      "lead-status-over-time",
      "offer-status-over-time",
      "eur",
    ].includes(widget?.type);
    const isPdf = ["eur"].includes(widget?.type);

    const isDateRange = ["over-budget-income", "over-budget-expense"].includes(
      widget?.type
    );
    const isTable = [
      "latest-bank-transactions",
      "latest-invoice-transactions",
      "latest-offer-transactions",
      "latest-lead-transactions",
    ].includes(widget?.type);

    const isFilterAllowed =
      isDateFilter || isScenarioFilter || isStateFilter || isCategoryFilter;
    const [anchorEl, setAnchorEl] = useState(null);
    const [isIncludedInFilterList, setIsIncludedInFilterList] = useState([]);

    //api
    const updateWidgetById = async (Page, payload) => {
      await APICall(
        "patch",
        `${EndPoints.reporting_dashboards}${Page?.uuid}/widgets/${widget?.uuid}/`,
        payload
      ).then((response) => {
        if (response.status === 200 && response.data) {
        }
      });
    };

    //functions
    const updateWidget = async (payload) => {
      const Page = store?.getState()?.reportSlice?.Page;
      const dataSet = store?.getState()?.boardSlice?.dataSetData?.uuid;
      const queryKey = ["ReportPages", { dataset: dataSet }];
      let updatedWidgets = [];
      let updatePages = [];
      queryClient?.setQueryData(queryKey, (oldData) => {
        const UpdatedData = produce(oldData, (draft) => {
          const index = draft.findIndex((item) => item?.uuid === Page?.uuid);
          if (index > -1) {
            const widgetIndex = draft[index]?.widgets?.findIndex(
              (item) => item?.uuid === widget?.uuid
            );
            if (widgetIndex > -1) {
              draft[index].widgets[widgetIndex] = cloneDeep({
                ...draft[index].widgets[widgetIndex],
                ...payload,
              });
              updatedWidgets = [...draft[index].widgets];
            }
          }
        });
        updatePages = UpdatedData;
        return UpdatedData;
      });
      const index = updatePages.findIndex((item) => item?.uuid === Page?.uuid);
      if (index > -1) {
        const widgetIndex = updatePages[index]?.widgets?.findIndex(
          (item) => item?.uuid === widget?.uuid
        );
        if (widgetIndex > -1) {
          updatedWidgets = updatePages[index].widgets;
        }
      }
      dispatch(
        setReportPage({
          key: "widgets",
          value: updatedWidgets,
        })
      );
      updateWidgetById(Page, payload);
    };

    const downloadPDF = () => {
      setPdfShowing(true);
      const pdf = new jsPDF("p", "mm", "a4");
      const elementHTML = document.querySelector("#contentToPrint");
      const allElementHTML = document.querySelectorAll(".contentToShow");
      allElementHTML?.forEach((element) => {
        element.style.display = "flex";
      });
      pdf.html(elementHTML, {
        callback: function (doc) {
          doc.save("finban-EUR.pdf");
          allElementHTML?.forEach((element) => {
            element.style.display = "none";
          });
        },
        margin: [10, 10, 10, 10],
        autoPaging: "text",
        x: 0,
        y: 0,
        width: 190, //target width in the PDF document
        windowWidth: 675, //window width in CSS pixels
      });
    };

    //function
    const onClose = (_selectedIds) => {
      isFilterOpen.current = false;
      setIsIncludedInFilterList(_selectedIds);
      setAnchorEl(null);
      let remove = {};

      if (
        widget?.states?.length > 0 &&
        isStateFilter &&
        !_selectedIds?.includes("state")
      ) {
        remove.states = [];
      }

      if (
        widget?.scenarios?.length > 0 &&
        isScenarioFilter &&
        !_selectedIds?.includes("scenario")
      ) {
        remove.scenarios = [];
      }

      if (
        widget?.categories?.length > 0 &&
        isCategoryFilter &&
        !_selectedIds?.includes("category")
      ) {
        remove.categories = [];
      }
      if (Object.keys(remove)?.length > 0) {
        updateWidget(remove);
      }
    };

    const onClickFilter = useCallback((e) => {
      isFilterOpen.current = true;
      setAnchorEl(e.currentTarget);
    }, []);

    const removeFilter = useCallback((key) => {
      setIsIncludedInFilterList((prev) => prev?.filter((item) => item !== key));
    }, []);

    useDebounce(
      () => {
        if (!isFilterOpen.current) {
          let included_array = [...isIncludedInFilterList];

          if (isDateFilter && (widget?.start_date || widget?.end_date)) {
            included_array.push("date");
          }

          if (widget?.states?.length > 0 && isStateFilter) {
            included_array.push("state");
          }

          if (widget?.scenarios?.length > 0 && isScenarioFilter) {
            included_array.push("scenario");
          }

          if (widget?.categories?.length > 0 && isCategoryFilter) {
            included_array.push("category");
          }

          included_array = Array.from(new Set(included_array));
          setIsIncludedInFilterList(included_array);
        }
      },
      500,
      [
        widget?.categories,
        widget?.scenarios,
        widget?.states,
        widget?.start_date,
        widget?.end_date,
      ],
      true
    );

    useEffect(() => {
      if (isBarLine) {
        setOptions(option1);
        setChartType([option1[0]?.value]);
      }
      if (isPieTable) {
        setOptions(option2);
        setChartType([option2[0]?.value]);
      }
      if (isTable) {
        setOptions(option3);
        setChartType([option3[0]?.value]);
      }
    }, [isBarLine, isPieTable, isTable, widget.type]);

    const FilterOptions = useMemo(
      () => [
        {
          uuid: "date",
          title: "add_date_filter",
          icon: <MdOutlineDateRange />,
          hide: !isDateFilter,
          isRequired: true,
        },
        {
          uuid: "state",
          title: "add_state_filter",
          icon: <TbCircles />,
          hide: !isStateFilter,
        },

        {
          uuid: "scenario",
          title: "add_scenario_filter",
          icon: <TbChartAreaLineFilled />,
          hide: !isScenarioFilter,
        },

        {
          uuid: "category",
          title: "add_category_filter",
          icon: <LocalOfferIcon />,
          hide: !isCategoryFilter,
        },
      ],
      [isCategoryFilter, isDateFilter, isScenarioFilter, isStateFilter]
    );

    return (
      <Paper
        elevation={0}
        className="drag"
        sx={{
          boxShadow:
            "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
          borderRadius: 1.5,
          position: "relative",
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "start",
          flexDirection: "column",
          px: "2rem",
          pb: "2rem",
          pt: "0.85rem",
          "& .MuiInputBase-root": {
            p: "0.15rem !important",
            pt: "8px",
            pr: "30px",
          },

          "& input": {
            fontSize: "0.9rem",
            fontWeight: 500,
            color: Color.tailwind.slate[700],
            fontFamily: Fonts.Text,
          },
          "&:hover": {
            "#widget_close": {
              display: "block !important",
            },
            "#widget_default_icon": {
              display: "none !important",
            },
            "#widget_grab_icon": {
              display: "block !important",
            },
          },
        }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              width: "fit-content",
              display: "flex",
              alignItems: "center",
            }}
          >
            <TitleIcon widget={widget} />

            <Typography
              sx={{
                display: "flex",
                fontSize: "1rem",
                lineHeight: "1rem",
                fontWeight: 600,
                ml: "1.25rem",
                color: Color.tailwind.slate[700],
                alignItems: "center",
              }}
            >
              {t(widget?.type)}
              {isBarLine || isPieTable || isTable ? (
                <ChartTypeFilter
                  option={option}
                  chartType={chartType}
                  setChartType={setChartType}
                />
              ) : null}
            </Typography>

            {widget?.type === "cash-development" ? (
              <CashDevelopmentDateRange />
            ) : null}
            {isDateRange ? (
              <DateRange widgetType={widget?.type} widget={widget} />
            ) : null}
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: "2.5rem",
            }}
          >
            {!isFilterAllowed || isIncludedInFilterList?.length > 0 ? null : (
              <AddFilterView
                isIncludedInFilterList={isIncludedInFilterList}
                onClick={onClickFilter}
              />
            )}
            {isFilterAllowed &&
              isIncludedInFilterList?.map((item) => {
                if (item === "date") {
                  return (
                    <DateFilter
                      key={widget?.uuid}
                      widget={widget}
                      updateWidget={updateWidget}
                      filterKey="date"
                      removeFilter={removeFilter}
                    />
                  );
                }
                if (item === "state") {
                  return (
                    <StateFilter
                      key={widget?.uuid}
                      selectedState={widget?.states}
                      updateWidget={updateWidget}
                      filterKey="state"
                      removeFilter={removeFilter}
                    />
                  );
                }

                if (item === "scenario") {
                  return (
                    <ScenarioFilter
                      key={widget?.uuid}
                      selectedScenario={widget?.scenarios}
                      updateWidget={updateWidget}
                      filterKey="scenario"
                      removeFilter={removeFilter}
                    />
                  );
                }

                if (item === "category") {
                  return (
                    <CategoryFilter
                      key={widget?.uuid}
                      widget={widget}
                      selectedCategory={widget?.categories}
                      updateWidget={updateWidget}
                      filterKey="category"
                      removeFilter={removeFilter}
                    />
                  );
                }

                return null;
              })}
            {isFilterAllowed && isIncludedInFilterList?.length > 0 ? (
              <AddFilterView
                isIncludedInFilterList={isIncludedInFilterList}
                onClick={onClickFilter}
              />
            ) : null}

            {isScenario ? (
              <Typography
                sx={{
                  fontSize: "0.85rem",
                  lineHeight: "0.85rem",
                  fontWeight: 400,
                  fontFamily: Fonts.Text,
                  color: Color.tailwind.slate[400],
                  ml: "1.25rem",
                }}
              >
                {t("Scenario")}: {t("Base")}
              </Typography>
            ) : null}
            {isPdf ? (
              <TailwindButton
                onClick={downloadPDF}
                variant="text"
                text={<FaFilePdf fontSize={"1rem"} />}
                sx={{
                  p: "0.4rem",
                  minWidth: "fit-content",
                  backgroundColor: theme.palette.color.slate[200],
                  color: theme.palette.color.slate[500],
                  "&:hover": {
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.color.white,
                  },
                }}
                tooltip="Download PDF"
              />
            ) : null}
            {!!anchorEl ? (
              <AddFilterPopOver
                anchorEl={anchorEl}
                onClose={onClose}
                selectedIds={isIncludedInFilterList}
                options={FilterOptions}
              />
            ) : null}
            <LinkView widget={widget} />
            <RemoveIcon widget={widget} commonRef={commonRef} />
          </div>
        </div>

        {!isSubscriptionValid({ showMessage: false }) ? (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {t(`widget_plan_expired`)}
          </div>
        ) : (
          <>
            {widget?.type === "income-pie" || widget?.type === "expense-pie" ? (
              <CostWidget
                chartType={chartType?.[0]}
                widgetType={widget?.type}
                widget={widget}
              />
            ) : null}
            {widget?.type === "burn-rate-12-month" ? (
              <BurnRateWidget widgetType={widget?.type} widget={widget} />
            ) : null}
            {widget?.type === "profit-12-month" ? (
              <BarChartWidget
                type={0}
                chartType={chartType?.[0]}
                widgetType={widget?.type}
                widget={widget}
              />
            ) : null}
            {widget?.type === "income-12-month" ? (
              <BarChartWidget
                type={1}
                chartType={chartType?.[0]}
                widgetType={widget?.type}
                widget={widget}
              />
            ) : null}
            {widget?.type === "expenses-12-month" ? (
              <BarChartWidget
                type={2}
                chartType={chartType?.[0]}
                widgetType={widget?.type}
                widget={widget}
              />
            ) : null}
            {widget?.type === "cash-development" ? (
              <CashDevelopmentWidget widgetType={widget?.type} />
            ) : null}
            {widget?.type === "profitability" ? (
              <ProfitabilityWidget widgetType={widget?.type} widget={widget} />
            ) : null}
            {widget?.type === "over-budget-income" ? (
              <OverBudgetIncomeExpense
                total_key={"inflow"}
                count_key={"inflow_count"}
                category_type={1}
                widgetType={widget?.type}
              />
            ) : null}
            {widget?.type === "over-budget-expense" ? (
              <OverBudgetIncomeExpense
                total_key={"outflow"}
                count_key={"outflow_count"}
                category_type={2}
                widgetType={widget?.type}
              />
            ) : null}
            {widget?.type === "invoice-status-over-time" ? (
              <InvoiceStatusWidget widgetType={widget?.type} widget={widget} />
            ) : null}
            {widget?.type === "offer-status-over-time" ? (
              <OfferStatusWidget widgetType={widget?.type} widget={widget} />
            ) : null}
            {widget?.type === "lead-status-over-time" ? (
              <LeadStatusWidget widgetType={widget?.type} widget={widget} />
            ) : null}
            {widget?.type === "latest-bank-transactions" ? (
              <LatestTransactionsWidget
                widgetType={widget?.type}
                chartType={chartType?.[0]}
              />
            ) : null}
            {widget?.type === "latest-offer-transactions" ? (
              <LatestTransactionsWidget
                widgetType={widget?.type}
                chartType={chartType?.[0]}
              />
            ) : null}
            {widget?.type === "latest-invoice-transactions" ? (
              <LatestTransactionsWidget
                widgetType={widget?.type}
                chartType={chartType?.[0]}
              />
            ) : null}
            {widget?.type === "latest-lead-transactions" ? (
              <LatestTransactionsWidget
                widgetType={widget?.type}
                chartType={chartType?.[0]}
              />
            ) : null}
            {widget?.type === "eur" ? (
              <EurWidget widgetType={widget?.type} widget={widget} />
            ) : null}
          </>
        )}
      </Paper>
    );
  },
  (prevProps, nextProps) =>
    prevProps?.widget?.states === nextProps?.widget?.states &&
    prevProps?.widget?.scenarios === nextProps?.widget?.scenarios &&
    prevProps?.widget?.categories === nextProps?.widget?.categories &&
    prevProps?.widget?.start_date === nextProps?.widget?.start_date &&
    prevProps?.widget?.uuid === nextProps?.widget?.uuid
);

const TitleIcon = ({ widget }) => {
  let icon = null;
  if (
    widget?.type === "over-budget-income" ||
    widget?.type === "income-12-month" ||
    widget?.type === "profit-12-month"
  ) {
    icon = (
      <Icon
        icon={<FaArrowUp />}
        fontSize={"1.2rem"}
        color={getTailwindColor("green", 600)}
        style={{
          width: "1.5rem",
          height: "1.5rem",
          transform: `rotate(${45}deg)`,
        }}
      />
    );
  } else if (
    widget?.type === "over-budget-expense" ||
    widget?.type === "burn-rate-12-month" ||
    widget?.type === "expenses-12-month"
  ) {
    icon = (
      <Icon
        icon={<FaArrowUp />}
        fontSize={"1.2rem"}
        color={getTailwindColor("red", 600)}
        style={{
          width: "1.5rem",
          height: "1.5rem",
          transform: `rotate(${135}deg)`,
        }}
      />
    );
  } else if (
    widget?.type === "latest-invoice-transactions" ||
    widget?.type === "invoice-status-over-time"
  ) {
    icon = (
      <Icon
        icon={<GrDocumentText />}
        fontSize={"1.2rem"}
        color={getTailwindColor("slate", 600)}
        style={{
          width: "1.5rem",
          height: "1.5rem",
        }}
      />
    );
  } else if (
    widget?.type === "latest-lead-transactions" ||
    widget?.type === "lead-status-over-time"
  ) {
    icon = (
      <Icon
        icon={<GrMoney />}
        fontSize={"1.2rem"}
        color={getTailwindColor("slate", 600)}
        style={{
          width: "1.5rem",
          height: "1.5rem",
        }}
      />
    );
  } else if (
    widget?.type === "latest-offer-transactions" ||
    widget?.type === "offer-status-over-time"
  ) {
    icon = (
      <Icon
        icon={<GrDocumentStore />}
        fontSize={"1.2rem"}
        color={getTailwindColor("slate", 600)}
        style={{
          width: "1.5rem",
          height: "1.5rem",
        }}
      />
    );
  } else if (widget?.type === "profitability") {
    icon = (
      <Icon
        icon={<MdAreaChart />}
        fontSize={"1.2rem"}
        color={getTailwindColor("slate", 600)}
        style={{
          width: "1.5rem",
          height: "1.5rem",
        }}
      />
    );
  } else if (widget?.type === "latest-bank-transactions") {
    icon = (
      <Icon
        icon={<MdOutlineAccountBalance />}
        fontSize={"1.2rem"}
        color={getTailwindColor("slate", 600)}
        style={{
          width: "1.5rem",
          height: "1.5rem",
        }}
      />
    );
  } else if (widget?.type === "eur") {
    icon = (
      <Icon
        icon={<BsLayoutTextSidebarReverse />}
        fontSize={"1.2rem"}
        color={getTailwindColor("slate", 600)}
        style={{
          width: "1.5rem",
          height: "1.5rem",
        }}
      />
    );
  } else if (widget?.type === "cash-development") {
    icon = (
      <Icon
        icon={<FaEuroSign />}
        fontSize={"1.2rem"}
        color={getTailwindColor("slate", 600)}
        style={{
          width: "1.5rem",
          height: "1.5rem",
        }}
      />
    );
  } else if (widget?.type === "burn-rate-12-month") {
    icon = (
      <Icon
        icon={<PiChartLineDownFill />}
        fontSize={"1.2rem"}
        color={getTailwindColor("slate", 600)}
        style={{
          width: "1.5rem",
          height: "1.5rem",
        }}
      />
    );
  }

  return (
    <>
      <span
        id="widget_default_icon"
        style={{
          display: "block",
          position: "absolute",
          top: "1.25rem",
          left: "0.75rem",
        }}
      >
        {icon}
      </span>
      <DragIndicatorOutlinedIcon
        id="widget_grab_icon"
        className="widget_grab_icon"
        sx={{
          display: "none",
          position: "absolute",
          top: "1.25rem",
          left: "0.75rem",
          fontSize: "1.375rem",
          cursor: "grab",
          height: "1.5rem",
          color: Color.tailwind.slate[700],
        }}
      />
    </>
  );
};

const LinkView = ({ widget }) => {
  const { t } = useTranslation();
  const overlayRef = useRef(null);

  //state
  const [anchorEl, setAnchorEl] = useState(null);

  let link = "https://finban.io/finanzielle-metriken/";
  if (widget?.type === "burn-rate-12-month") {
    link = "https://finban.io/gross-burn-net-burn-runway/";
  }
  if (widget?.type === "profitability") {
    link = "https://finban.io/finanzielle-metriken/metrik-rentabilitaet/";
  }

  //functions
  const onClickIcon = () => setAnchorEl(overlayRef.current);

  const onClickAway = () => setAnchorEl(null);

  const onClickLink = (e) => {
    e.stopPropagation();
    window.open(link, "_blank");
  };

  return (
    <div
      onClick={onClickIcon}
      style={{ marginLeft: "0.75rem", height: "1.25rem" }}
      ref={overlayRef}
      onMouseEnter={onClickIcon}
      onMouseLeave={onClickAway}
    >
      <Icon
        icon={<TbInfoSquare />}
        fontSize="1.3rem"
        color="color.slate.500"
        style={{ cursor: "pointer" }}
      />

      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement={"top-end"}
        transition
        disablePortal
        sx={{
          zIndex: 2,
        }}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={onClickAway}>
            <Fade {...TransitionProps} timeout={350}>
              <Paper
                elevation={1}
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  flexDirection: "column",
                  alignItems: "start",
                  width: "35rem",
                  px: "1.5rem",
                  pt: "1.5rem",
                  pb: ".5rem",
                  boxShadow:
                    "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                }}
              >
                <Typography
                  sx={{
                    color: Color.tailwind.slate[500],
                    fontFamily: Fonts.Text,
                    fontSize: "0.9rem",
                    textTransform: "initial",
                    mb: "2rem",
                    textAlign: "left",
                  }}
                >
                  <Translate i18nkey={`widget-${widget?.type}-des`} />
                </Typography>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <Button
                    onClick={onClickLink}
                    sx={{
                      width: "fit-content",
                      ml: "-0.75rem",
                    }}
                  >
                    <Typography
                      sx={{
                        color: Color.tailwind.purple[800],

                        fontSize: "0.9rem",
                        lineHeight: "1rem",
                        textTransform: "initial",
                      }}
                    >
                      {t("Get more information on our website")}
                    </Typography>
                    <Icon
                      icon={<IoIosArrowRoundForward />}
                      fontSize={"1.6rem"}
                      color={Color.tailwind.slate[400]}
                    ></Icon>
                  </Button>
                </div>
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </div>
  );
};

const RemoveIcon = ({ widget, commonRef }) => {
  //state
  const [isDeleting, setDeleteLoader] = useState(false);

  //redux
  const Page = useSelector((state) => state.reportSlice.Page);

  //api
  const deleteWidgetsByIdApi = async (id) => {
    setDeleteLoader(true);
    await APICall(
      "delete",
      EndPoints.reporting_dashboards + `${Page?.uuid}/widgets/${id}/`
    )
      .then((response) => {
        if (response.status === 204 && response) {
          let UpdatedWidgets = Page?.widgets?.filter(
            (item) => item?.uuid !== id
          );
          commonRef.current?.updatePageWidgets({
            widgets: UpdatedWidgets,
            // updateLayout: true,
            // updateBatchPosition: true,
            isDelete: true,
            deletedId: id,
          });
        }
      })
      .finally(() => setDeleteLoader(false));
  };

  //functions
  const onRemove = (e) => {
    e.stopPropagation();
    if (!isDeleting) {
      deleteWidgetsByIdApi(widget?.uuid);
    }
  };

  return (
    <div
      onClick={onRemove}
      style={{
        width: "2.25rem",
        // position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "end",
        position: "absolute",
        top: "1.35rem",
        right: "0.3rem",
      }}
    >
      {isDeleting ? (
        <div
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
          }}
        >
          <CircularProgress
            variant="determinate"
            sx={{
              color: Color.tailwind.grey[200],
            }}
            size={22}
            thickness={4}
            value={100}
          />
          <CircularProgress
            variant="indeterminate"
            disableShrink
            sx={{
              color: Color.tailwind.grey[400],
              animationDuration: "550ms",
              position: "absolute",
              left: 0,
              [`& .${circularProgressClasses.circle}`]: {
                strokeLinecap: "round",
              },
            }}
            size={22}
            thickness={4}
          />
        </div>
      ) : (
        <CloseIcon
          id="widget_close"
          sx={{
            color: Color.tailwind.slate[700],
            display: isDeleting ? "flex" : "none",
            cursor: "pointer",
            "&:hover": {
              transform: "scale(1.2)",
              transition: "transform 0.3s ease-in-out",
            },
          }}
        />
      )}
    </div>
  );
};

const CashDevelopmentDateRange = () => {
  const { t } = useTranslation();
  return (
    <Typography
      sx={{
        display: "flex",
        fontSize: "0.65rem",
        lineHeight: "0.85rem",
        fontWeight: 500,
        fontFamily: Fonts.Text,
        ml: "1.25rem",
        color: Color.tailwind.slate[700],
        backgroundColor: Color.tailwind.slate[50],
        p: "0.1rem",
      }}
    >
      {t("Current Year")}:
      <b style={{ marginLeft: "0.5rem" }}>{new Date().getFullYear()}</b>
    </Typography>
  );
};

const DateRange = ({ start_date, end_date }) => {
  const locale = useSelector((state) => state.settingsSlice?.profile?.locale);
  let _startDate =
    start_date && start_date !== "Invalid Date"
      ? format(new Date(start_date), "MMMM yyyy", {
          locale: locale === "de_DE" ? de : enUS,
        })
      : "";
  let _endDate =
    end_date && end_date !== "Invalid Date"
      ? format(new Date(end_date), "MMMM yyyy", {
          locale: locale === "de_DE" ? de : enUS,
        })
      : "";

  if (!_startDate || !_endDate) return null;
  return (
    <Typography
      sx={{
        display: "flex",
        fontSize: "0.65rem",
        lineHeight: "0.85rem",
        fontWeight: 600,
        ml: "1.25rem",
        color: Color.tailwind.slate[700],
        backgroundColor: Color.tailwind.slate[50],
        p: "0.1rem",
      }}
    >
      {`${_startDate} ~ ${_endDate}`}
    </Typography>
  );
};

const StateFilter = ({
  selectedState,
  updateWidget,
  removeFilter,
  filterKey,
}) => {
  const state = useSelector((state) => state.globalSlice?.state);

  const onChangeValue = (value) => {
    updateWidget({
      states: value,
    });
  };

  const onClickReset = () => {
    removeFilter(filterKey);
    updateWidget({
      states: [],
    });
  };

  return (
    <ThemeFilter
      // heading="state_filter"
      tooltip="tooltip_state_filter"
      icon={<TbCircles style={{ fontSize: "1rem", marginTop: "1px" }} />}
      options={state}
      translate
      selectedIds={selectedState}
      isActive={selectedState?.length > 0}
      onChangeValue={onChangeValue}
      onClickReset={onClickReset}
      onClear={onClickReset}
      isMultiple
    />
  );
};

const ScenarioFilter = ({
  selectedScenario,
  updateWidget,
  removeFilter,
  filterKey,
}) => {
  const scenario = useSelector((state) => state.globalSlice?.scenario);

  const onChangeValue = (value) => {
    updateWidget({
      scenarios: value,
    });
  };

  const onClickReset = () => {
    removeFilter(filterKey);

    updateWidget({
      scenarios: [],
    });
  };

  return (
    <ThemeFilter
      // heading="scenario_filter"
      tooltip="tooltip_scenario_filter"
      icon={
        <TbChartAreaLineFilled style={{ fontSize: "1rem", marginTop: "1px" }} />
      }
      options={scenario}
      translate
      selectedIds={selectedScenario}
      isActive={selectedScenario?.length > 0}
      onChangeValue={onChangeValue}
      onClickReset={onClickReset}
      onClear={onClickReset}
      isMultiple
    />
  );
};

const ChartTypeFilter = ({ option, chartType, setChartType }) => {
  const onChange = (event, value) => {
    event.stopPropagation();
    setChartType([value]);
  };

  return (
    <ThemeToggle
      // tooltip={switchTooltip}
      value={chartType?.[0]}
      onChange={onChange}
      options={option}
      sx={{
        ml: "1rem",
      }}
    />
  );
};

const CategoryFilter = ({
  selectedCategory,
  widget,
  updateWidget,
  removeFilter,
  filterKey,
}) => {
  let categoryType = 3;
  if (widget?.type === "income-12-month") {
    categoryType = 1;
  }
  if (widget?.type === "expenses-12-month") {
    categoryType = 2;
  }

  const onChangeValue = (value) => {
    updateWidget({
      categories: value,
    });
  };

  const onClickReset = (e) => {
    e.stopPropagation();
    removeFilter(filterKey);

    updateWidget({
      categories: [],
    });
  };

  return (
    <CategoryFilterView
      hideHeading
      selectedIds={selectedCategory}
      categoryType={categoryType}
      onClickReset={onClickReset}
      onChangeValue={onChangeValue}
      isMultiple
      hideUncategorize
    />
  );
};

const DateFilter = ({ widget, updateWidget }) => {
  const onClickReset = (e) => {
    updateWidget({
      start_date: format(startOfYear(new Date()), "yyyy-MM-dd"),
      end_date: format(endOfYear(new Date()), "yyyy-MM-dd"),
    });
  };

  return (
    <DateRangePicker
      hideHeading
      start_date={widget?.start_date}
      end_date={widget?.end_date}
      onOk={updateWidget}
      onClickReset={onClickReset}
      sx={{
        ml: "1rem",
      }}
    />
  );
};
