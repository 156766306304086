import {
  ResponsiveContainer,
  CartesianGrid,
  ReferenceLine,
  BarChart,
  Tooltip,
  Legend,
  XAxis,
  YAxis,
  Cell,
  Bar,
} from "recharts";
import { format } from "date-fns";
import React from "react";

import CustomizedAxisTick from "../../components/Charts/CustomizedAxisTick";
import CustomTooltip from "../../components/Charts/CustomTooltip";
import { getTailwindColor, remToPx } from "../../Helper/data";
import useWidth from "../../hooks/useWidth";
import { Color } from "../../Helper";

const BarCharts = React.memo(
  ({
    data,
    barColor,
    hideDefault,
    barsData,
    dynamicColor,
    yAxisProps,
    tooltipProps,
    chartProps,
    currentMonth = format(new Date(), "yyyy-MM"),
  }) => {
    const currentWidth = useWidth();
    return (
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={data}
          margin={{
            top: remToPx(currentWidth, 0.3125),
            // right: remToPx(currentWidth, 1.875),
            // left: remToPx(currentWidth, 1.25),
            // bottom: remToPx(currentWidth, 0.3125),
          }}
          barSize={remToPx(currentWidth, 2)}
          {...chartProps}
        >
          <CartesianGrid
            strokeDasharray="3 3"
            vertical={false}
            horizontal={false}
          />
          <XAxis
            dataKey="due_date"
            tick={
              <CustomizedAxisTick
                dy={remToPx(currentWidth, 1)}
                dx={0}
                highlightValue={currentMonth}
              />
            }
            tickCount={12}
            minTickGap={1}
            // interval={0}
            interval="preserveStartEnd"
            type="category"
            allowDataOverflow={false}
          />
          <YAxis
            tick={
              <CustomizedAxisTick
                dy={remToPx(currentWidth, 0.2)}
                dx={-remToPx(currentWidth, 1.5)}
                formatted
              />
            }
            type="number"
            tickCount={12}
            dataKey={(v) => {
              return (
                parseInt(v.gross_value) ||
                parseInt(v.gross_value_ref) ||
                parseInt(v.overdue) ||
                parseInt(v.open) ||
                parseInt(v.closed)
              );
            }}
            {...yAxisProps}
          />
          <Tooltip
            cursor={{ fill: "transparent" }}
            // trigger="click"
            content={
              <CustomTooltip barsData={barsData} dynamicColor={dynamicColor} />
            }
            wrapperStyle={{ zIndex: 1, ...tooltipProps?.wrapperStyle }}
            {...tooltipProps}
          />
          <Legend wrapperStyle={{ display: "none" }} />
          <ReferenceLine y={0} stroke={Color.tailwind.slate[400]} />
          <ReferenceLine x={currentMonth} stroke={Color.tailwind.slate[200]} />
          {barsData?.map((item) => {
            if (item?.hideBar) return null;
            return (
              <Bar {...item}>
                {data.map((entry, index) => {
                  return (
                    <Cell
                      key={`cell-${index}`}
                      // stroke={item?.stroke}
                      fill={
                        dynamicColor
                          ? entry?.gross_value >= 0
                            ? getTailwindColor("green", 500)
                            : getTailwindColor("red", 500)
                          : item?.fill
                      }
                    />
                  );
                })}
                )
              </Bar>
            );
          })}
          {!hideDefault ? (
            <Bar dataKey="gross_value" fill={barColor} minPointSize={1} />
          ) : null}
        </BarChart>
      </ResponsiveContainer>
    );
  },
  (prev, next) => {
    return (
      prev.data === next.data &&
      prev.data?.length === next.data?.length &&
      prev.twoBar === next.twoBar &&
      prev.barsData === next.barsData
    );
  }
);
export default BarCharts;
