import { Typography, useTheme, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import CustomModal from "../../../components/Model/CustomModal";
import RulesListView from "./RulesListView";

const RulesListViewOverlay = ({ open, setOpen }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();

  const onClickLink = () => {
    onClose()
    navigate(`/settings/rules`);
  };

  const onClose = () => {
    setOpen(null);
  };

  return (
    <CustomModal
      open={Boolean(open)}
      elevation={8}
      onClose={onClose}
      hideAction
    >
      <Box
        sx={{
          border: 0,
          width: "80vw",
          height: "42rem",
          display: "flex",
          alignItems: "start",
          px: "1rem",
          py: "2rem",
          flexDirection: "column",
          backgroundColor: theme.palette.color.appThemeBg,
          position: "relative",
        }}
      >
        <RulesListView
          isOverlay
          sx={{
            width: "calc(100% - 3rem)",
          }}
        />

        <Typography
          onClick={onClickLink}
          sx={{
            position: "absolute",
            bottom: "1rem",
            right: "2rem",
            display: "inline-flex",
            fontSize: "0.8rem !important",
            fontWeight: 600,
            cursor: "pointer",
            color: theme.palette.primary.main,
            "&:hover": {
              textDecoration: "underline",
            },
          }}
        >
          {t("Manage Rules")}
        </Typography>
      </Box>
    </CustomModal>
  );
};
export default RulesListViewOverlay;
