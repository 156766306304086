import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import { Box, Tooltip, useTheme } from "@mui/material";
import Popover from "@mui/material/Popover";
import * as React from "react";

const CustomPopover = React.forwardRef((props, ref) => {
  let {
    children,
    showRight = false,
    anchorEl = null,
    onClose,
    onClick,
    onDoubleClick,
    tooltip = "",
    type = "default",
    height = "fit-content",
    width = "fit-content",
    sx = {},
    popupProps = {},
    PaperPropsSx = {},
    BaseButtonSx = {},
    BaseButton = <span></span>,
  } = props;
  const theme = useTheme();
  if (type === "anchor") {
    return (
      <>
        <Tooltip title={tooltip} disableFocusListener placement="top">
          <Box
            ref={ref}
            onClick={onClick ? onClick : undefined}
            onDoubleClick={onDoubleClick}
            sx={BaseButtonSx}
          >
            {BaseButton}
          </Box>
        </Tooltip>
        <Popover
          id={Boolean(anchorEl) ? "simple-popover" : undefined}
          open={Boolean(anchorEl)}
          container={document.body}
          anchorEl={anchorEl}
          onClose={onClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: showRight ? "left" : "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: showRight ? "left" : "right",
          }}
          slotProps={{
            paper: {
              sx: {
                mt: "0.5rem",
                p: "0.3rem",
                mx: 0,
                boxShadow: theme.boxShadow,
                borderRadius: theme.borderRadius.borderRadiusXL2,
                backgroundColor: theme.palette.color.overlayBorderShade,
                backdropFilter: "blur(2px)",
                overflow: "hidden",
                ...PaperPropsSx,
              },
            },
          }}
          {...popupProps}
          sx={sx}
        >
          <div
            style={{
              position: "relative",
              borderRadius: theme.borderRadius.borderRadiusXL,
              outline: `2px solid ${theme.palette.color.grey[300]}`,
              height,
              width,
              overflow: "hidden",
            }}
          >
            {children}
          </div>
        </Popover>
      </>
    );
  } else {
    return (
      <PopupState
        variant="popper"
        ref={ref}
        id="demo-mutiple-checkbox"
        popupId="demo-popup-popover"
      >
        {(popupState) => {
          return (
            <Box>
              <Box
                ref={ref}
                {...bindTrigger(popupState)}
                onDoubleClick={onDoubleClick}
                sx={BaseButtonSx}
              >
                {BaseButton}
              </Box>
              <Popover
                {...bindPopover(popupState)}
                container={document.body}
                slotProps={{
                  paper: {
                    sx: {
                      mt: "0.5rem",
                      p: "0.3rem",
                      mx: 0,
                      boxShadow: theme.boxShadow,
                      borderRadius: theme.borderRadius.borderRadiusXL2,
                      backgroundColor: theme.palette.color.overlayBorderShade,
                      overflow: "hidden",
                      backdropFilter: "blur(2px)",
                      ...PaperPropsSx,
                    },
                  },
                }}
                {...popupProps}
                sx={sx}
              >
                <div
                  style={{
                    position: "relative",
                    borderRadius: theme.borderRadius.borderRadiusXL,
                    outline: `2px solid ${theme.palette.color.grey[300]}`,
                    height,
                    width,
                    overflow: "hidden",
                  }}
                >
                  {children}
                </div>
              </Popover>
            </Box>
          );
        }}
      </PopupState>
    );
  }
});
export default CustomPopover;
