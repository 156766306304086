import { createSlice, current } from "@reduxjs/toolkit";
import { produce } from "immer";
import _ from "underscore";

import initialData from "../../Helper/data";
import { resetAllState } from "./reset";

const initialState = {
  state: [],
  scenario: [],
  scenarioByTitle: {},
  scenarioById: {},
  stateByTitle: [],
  stateById: [],
  costunits: [],
  costunitsByTitle: {},
  costunitsById: {},
  vat: [],
  contacts: [],
  contactsById: {},
  contactsByName: {},
  accounts: [],
  accountByDS: {},
  accountsById: {},
  dataSource: [],
  dataSourceById: {},
  dataSourceByTitle: {},
  prevLiqCount: { liquidity: 0.0 },
  isListHaveData: false,
  isFirstFetchDs: 0,
  isDsUuidLoading: null,
  isDsUuidUpdating: null,
  filters: [],
  appliedFilter: null,
  appliedFilterlist: null,
  isFirstLoginOverlayOpen: false,
  isUpdatesOverlayOpen: false,
  isAccountSelectOverlayOpen: false,
  consentAlert: 0,
  joyRideStatus: null,
  joyRidePayload: null,
  advanceVat: null,
  recurring_rules: [],
  recurring_rulesById: null,
  transactionSystem: [],
  transactionSystemById: [],
  transactionSystemByName: [],
  banks: [],
  banksById: [],
  onBoardingList: [],
  isPlanExpiredShow: false,
  planningTableData: null,
};

let tabs = initialData?.recurringTypeTabs;
let defaultState = initialData?.defaultState;
let defaultScenario = initialData?.defaultScenario;
const sortTreeByPosition = (node) => {
  // Sort the children recursively first
  if (node?.children?.length > 0) {
    node.children = node.children
      .map(sortTreeByPosition)
      .sort((a, b) => a?.position - b?.position);
  }
  return node;
};
const recurringTabsByValue = _.groupBy(tabs, ({ value }) => value);
const globalSlice = createSlice({
  name: "globalSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(resetAllState, () => initialState);
  },
  reducers: {
    setPlanExpiredShow: (state, action) => {
      state.isPlanExpiredShow = action.payload;
    },
    setOnBoardingList: (state, action) => {
      const sortedData = action.payload
        .map(sortTreeByPosition)
        .sort((a, b) => a?.position - b?.position);
      state.onBoardingList = sortedData;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setConsentAlert: (state, action) => {
      state.consentAlert = action.payload;
    },

    setIsFirstLoginOverlayOpen: (state, action) => {
      state.isFirstLoginOverlayOpen = action.payload;
    },
    setIsUpdatesOverlayOpen: (state, action) => {
      state.isUpdatesOverlayOpen = action.payload;
    },
    setIsAccountSelectOverlayOpen: (state, action) => {
      state.isAccountSelectOverlayOpen = action.payload;
    },
    setIsFirstFetchDs: (state, action) => {
      state.isFirstFetchDs = action.payload;
    },
    setJoyRideStatus: (state, action) => {
      state.joyRideStatus = action.payload;
    },
    setJoyRidePayload: (state, action) => {
      state.joyRidePayload = action.payload;
    },
    setIsDsUuidLoading: (state, action) => {
      state.isDsUuidLoading = action.payload;
    },
    setIsDsUuidUpdating: (state, action) => {
      state.isDsUuidUpdating = action.payload;
    },
    setAdvanceVat: (state, action) => {
      state.advanceVat = action.payload;
    },
    setAppliedFilters: (state, action) => {
      state.appliedFilter = action.payload;
    },
    setAppliedFilterslist: (state, action) => {
      state.appliedFilterlist = action.payload;
    },

    updateKanbanFilters: (state, action) => {
      if (state?.appliedFilterlist?.kanban && action.payload) {
        return produce(state, (draftState) => {
          if (current(draftState) && draftState?.appliedFilterlist?.kanban) {
            Object.keys(action.payload)?.forEach((key) => {
              draftState.appliedFilterlist.kanban[key] = action.payload[key];
            });
          }
        });
      }
    },

    updateFilters: (state, action) => {
      if (
        state?.appliedFilterlist?.[action?.payload?.parent_key] &&
        action.payload
      ) {
        return produce(state, (draftState) => {
          const { parent_key, obj } = action.payload;
          if (
            parent_key &&
            obj &&
            current(draftState) &&
            draftState?.appliedFilterlist?.[parent_key]
          ) {
            Object.keys(obj).forEach((key) => {
              draftState.appliedFilterlist[parent_key][key] = obj[key];
            });
          }
        });
      }
    },
    setPrevLiqCount: (state, action) => {
      state.prevLiqCount = action.payload;
    },
    setIsListHaveData: (state, action) => {
      state.isListHaveData = action.payload;
    },
    setContacts: (state, action) => {
      state.contacts = action.payload;
      state.contactsById = _.groupBy(action.payload, ({ uuid }) => uuid);
      state.contactsByName = _.groupBy(action.payload, ({ name }) => name);
    },
    setBanks: (state, action) => {
      state.banks = action.payload;
      state.banksById = _.groupBy(action.payload, ({ uuid }) => uuid);
    },

    setTransactionSystem: (state, action) => {
      state.transactionSystem = action.payload;
      state.transactionSystemById = _.groupBy(
        action.payload,
        ({ uuid }) => uuid
      );
      state.transactionSystemByName = _.groupBy(
        action.payload,
        ({ name }) => name
      );
    },
    setDataSource: (state, action) => {
      let data = action.payload?.sort(
        (a, b) => new Date(b.creation_date) - new Date(a.creation_date)
      );
      state.dataSource = data;
      state.dataSourceById = _.groupBy(data, ({ uuid }) => uuid);
      state.dataSourceByTitle = _.groupBy(data, ({ title }) => title);
    },
    deleteDataSource: (state, action) => {
      let updatedData = state.dataSource.filter(
        (item) => item.uuid !== action.payload
      );
      state.dataSource = updatedData;
      state.dataSourceById = _.groupBy(updatedData, ({ uuid }) => uuid);
      state.dataSourceByTitle = _.groupBy(updatedData, ({ title }) => title);

    },
    setAccounts: (state, action) => {
      state.accounts = action.payload;
      state.accountsById = _.groupBy(action.payload, ({ uuid }) => uuid);
      state.accountByDS = _.groupBy(
        action.payload,
        ({ data_source }) => data_source
      );
    },
    addAccount: (state, action) => {
      let updatedData = [...state.accounts, action.payload];
      state.accounts = updatedData;
      state.accountsById = _.groupBy(updatedData, ({ uuid }) => uuid);
      state.accountByDS = _.groupBy(
        updatedData,
        ({ data_source }) => data_source
      );
    },
    deleteAccount: (state, action) => {
      let updatedData = state.accounts.filter(
        (item) => item.uuid !== action.payload
      );
      state.accounts = updatedData;
      state.accountsById = _.groupBy(updatedData, ({ uuid }) => uuid);
      state.accountByDS = _.groupBy(
        updatedData,
        ({ data_source }) => data_source
      );
    },
    setStates: (state, action) => {
      let array = [];
      action.payload.forEach((element) => {
        if (defaultState[element?.title]) {
          array.push({ ...element, ...defaultState[element?.title] });
        } else {
          array.push({ ...element, position: 15 + element.position });
        }
      });
      state.state = array?.sort((a, b) => (a.position > b.position ? 1 : -1));
      state.stateByTitle = _.groupBy(array, ({ title }) => title);
      state.stateById = _.groupBy(array, ({ uuid }) => uuid);
    },
    setScenario: (state, action) => {
      let array = [];
      action.payload.forEach((element) => {
        if (defaultScenario[element?.title]) {
          array.push({ ...element, ...defaultScenario[element?.title] });
        } else {
          array.push(element);
        }
      });
      array?.sort((a, b) => (a.position > b.position ? 1 : -1));
      state.scenario = array;
      state.scenarioByTitle = _.groupBy(array, ({ title }) => title?.trim());
      state.scenarioById = _.groupBy(array, ({ uuid }) => uuid);
    },
    addNewState: (state, action) => {
      state.state = [...state.state, action.payload];
    },
    deleteState: (state, action) => {
      let updatedData = state.state.filter(
        (item) => item.uuid !== action.payload
      );
      state.state = updatedData;
    },
    updateState: (state, action) => {
      state.vat[action.payload.value - 1] = action.payload;
    },
    setCostUnits: (state, action) => {
      state.costunitsByTitle = _.groupBy(action.payload, ({ title }) => title);
      state.costunitsById = _.groupBy(action.payload, ({ uuid }) => uuid);
      state.costunits = action.payload;
    },
    setVats: (state, action) => {
      state.vat = action.payload;
    },
    addNewVat: (state, action) => {
      state.vat = [...state.vat, action.payload];
    },
    updateVat: (state, action) => {
      state.vat[action.payload.value - 1] = action.payload;
    },
    deleteVat: (state, action) => {
      let updatedData = state.vat.filter(
        (item) => item.value !== action.payload
      );
      state.vat = updatedData;
    },

    setRecurring_rules: (state, action) => {
      let array = [];

      action.payload.forEach((element) => {
        let recurring_type = element?.recurring_type
          ? element?.recurring_type
          : "simple";
        array.push({
          ...element,
          recurring_type: recurring_type,
          icon: recurringTabsByValue[recurring_type]
            ? recurringTabsByValue[recurring_type][0]?.icon
            : null,
        });
      });
      state.recurring_rules = array;
      state.recurring_rulesById = _.groupBy(array, ({ uuid }) => uuid);
    },
    setPlanningTableData: (state, action) => {
      state.planningTableData = action.payload;
    },
  },
});
export const {
  setPlanExpiredShow,
  setOnBoardingList,
  setConsentAlert,
  setIsDsUuidLoading,
  setIsFirstFetchDs,
  setFilters,
  setAppliedFilterslist,
  updateKanbanFilters,
  updateFilters,
  setAdvanceVat,
  setIsAccountSelectOverlayOpen,
  setJoyRideStatus,
  setJoyRidePayload,
  setAppliedFilters,
  setAccounts,
  setDataSource,
  deleteDataSource,
  setContacts,
  addAccount,
  deleteAccount,
  setStates,
  setScenario,
  addNewState,
  deleteState,
  setCostUnits,
  setVats,
  addNewVat,
  deleteVat,
  updateVat,
  setIsListHaveData,
  setPrevLiqCount,
  setRecurring_rules,
  setIsFirstLoginOverlayOpen,
  setIsUpdatesOverlayOpen,
  setTransactionSystem,
  setBanks,
  setIsDsUuidUpdating,
  setPlanningTableData,
} = globalSlice.actions;
export default globalSlice;
