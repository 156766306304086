import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import { Tooltip, useTheme } from "@mui/material";
import Popover from "@mui/material/Popover";
import * as React from "react";

import Translate from "../../hooks/HOC/Translate";
import { Constant } from "../../Helper";

export default function CustomPopoverScratch({
  children,
  button,
  tooltip,
  disabled,
  borderRadius,
  mr,
  ml,
  paperStyle,
  sx,
  popupProps = {},
  anchorOrigin = {
    vertical: "bottom",
    horizontal: "right",
  },
  transformOrigin = {
    vertical: "top",
    horizontal: "right",
  },
}) {
  const theme = useTheme();
  return (
    <PopupState variant="popper">
      {(popup_state) => (
        <>
          <Tooltip
            placement="top"
            title={<Translate i18nkey={tooltip} />}
            arrow
          >
            <div
              {...bindTrigger(popup_state)}
              style={{
                height: "fit-content",
                display: "flex",
                alignItems: "center",
              }}
            >
              {React?.cloneElement(button, { popup_state })}
            </div>
          </Tooltip>
          <Popover
            {...bindPopover(popup_state)}
            container={document.body}
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
            {...popupProps}
            sx={{
              mt: "0.5rem",
              zIndex: 1303,
              "& .MuiPaper-root": {
                borderRadius: borderRadius
                  ? borderRadius
                  : theme.borderRadius.main,
                ml: ml ? ml : 0,
                mr: mr ? mr : 0,
                boxShadow: Constant.OverlayBoxShadow,
                ...paperStyle,
              },
              ...sx,
            }}
          >
            {React?.cloneElement(children, { popup_state })}
          </Popover>
        </>
      )}
    </PopupState>
  );
}
