import {
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
  useRef,
} from "react";
import { triggerPostMoveFlash } from "@atlaskit/pragmatic-drag-and-drop-flourish/trigger-post-move-flash";
import { monitorForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { Box, Button, Grid, styled, Tooltip, useTheme } from "@mui/material";
import * as liveRegion from "@atlaskit/pragmatic-drag-and-drop-live-region";
import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine";
import CircleIcon from "@mui/icons-material/Circle";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import invariant from "tiny-invariant";
import memoizeOne from "memoize-one";
import _ from "underscore";

import { DependencyContext, TreeContext } from "./tree-context";
import { dataReducer, tree } from "../Tree/data.js";
import TreeItem from "./tree-item";

const iconColor = "#44546F";

const TreeStyled = styled("div")({
  display: "flex",
  boxSizing: "border-box",
  width: "100%",
  padding: 8,
  flexDirection: "column",
});

function createTreeItemRegistry() {
  const registry = new Map();

  const registerTreeItem = ({ itemId, element, actionMenuTrigger }) => {
    registry.set(itemId, { element, actionMenuTrigger });
    return () => {
      registry.delete(itemId);
    };
  };

  return { registry, registerTreeItem };
}

export default function Tree({
  data,
  setData,
  lastAction,
  setLastAction,
  categoryRef,
  onMoveNode,
  openNoteOverlay,
  isLoading,
  SavedCategoryInfo,
  anchorElMenu,
  anchorElNote,
  onClickMore,
  isOverlay,
  hiddenCategory,
  allowParentCategory,
  ruleTitle,
  isSelect,
  preventClose,
  onClickCategoryTitle,
  searchText,
  isCategorySaving,
  hideUncategorize,
  showCategory,
  setShowCategory,
}) {
  const { extractInstruction } = useContext(DependencyContext);
  const ref = useRef(null);
  let CATEGORY_TIMER = useRef(null);

  const [{ registry, registerTreeItem }] = useState(createTreeItemRegistry);

  let lastStateRef = useRef(data);

  useEffect(() => {
    lastStateRef.current = data;
  }, [data]);

  useEffect(() => {
    if (lastAction === null) {
      return;
    }

    if (lastAction?.type === "instruction") {
      const { element } = registry.get(lastAction?.itemId) ?? {};
      if (element) {
        triggerPostMoveFlash(element);
      }

      return;
    }
  }, [lastAction, registry]);
  
  useEffect(() => {
    return () => {
      liveRegion.cleanup();
    };
  }, []);

  /**
   * Returns the items that the item with `itemId` can be moved to.
   *
   * Uses a depth-first search (DFS) to compile a list of possible targets.
   */
  const getMoveTargets = useCallback(({ itemId }) => {
    const data = lastStateRef.current;

    const targets = [];

    const searchStack = Array.from(data);
    while (searchStack.length > 0) {
      const node = searchStack.pop();

      if (!node) {
        continue;
      }

      /**
       * If the current node is the item we want to move, then it is not a valid
       * move target and neither are its children.
       */
      if (node.uuid === itemId) {
        continue;
      }

      /**
       * Draft items cannot have children.
       */
      if (node.isDraft) {
        continue;
      }

      targets.push(node);

      node.children.forEach((childNode) => searchStack.push(childNode));
    }

    return targets;
  }, []);

  const getChildrenOfItem = useCallback((itemId) => {
    const data = lastStateRef.current;

    /**
     * An empty string is representing the root
     */
    if (itemId === "") {
      return data;
    }

    const item = tree.find(data, itemId);
    invariant(item);
    return item.children;
  }, []);

  const context = useMemo(
    () => ({
      // dispatch: updateState,
      uniqueContextId: Symbol("unique-id"),
      // memoizing this function as it is called by all tree items repeatedly
      // An ideal refactor would be to update our data shape
      // to allow quick lookups of parents
      getPathToItem: memoizeOne(
        (targetId) =>
          tree.getPathToItem({ current: lastStateRef.current, targetId }) ?? []
      ),
      getMoveTargets,
      getChildrenOfItem,
      registerTreeItem,
    }),
    [getChildrenOfItem, getMoveTargets, registerTreeItem]
  );

  const updateTree = useCallback(
    async (action) => {
      const updatedData = await dataReducer(data, action);
      const updatedFlattenData = tree.flattenTree(updatedData);
      if (
        action?.itemId &&
        action?.targetId &&
        action?.type === "instruction"
      ) {
        const updatedDataById = _.groupBy(updatedFlattenData, "uuid");
        const node = updatedDataById?.[action?.itemId]?.[0];
        const nextParentNode = updatedDataById?.[node?.parent]?.[0];
        if (preventClose) {
          preventClose.current = true;
        }

        const isAllowed = onMoveNode({
          treeData: updatedData,
          nextParentNode,
          node,
        });
        if (!isAllowed) return;
      }
      setData(updatedData);
      setLastAction(action);
      clearTimeout(CATEGORY_TIMER.current);

      if (!action?.isDragging) {
        isCategorySaving.current = true;

        CATEGORY_TIMER.current = setTimeout(() => {
          let batchArray = [];
          updatedFlattenData?.forEach((item) => {
            batchArray.push({
              uuid: item?.uuid,
              expanded: item?.expanded,
              position: item.position,
              parent: item.parent,
              // title: item.title,
            });
          });
          categoryRef.current?.batchUpdateCategory({
            payload: batchArray,
            doNotUpdate: true,
            preventClose: preventClose,
          });
        }, 0);
      }
    },
    [
      categoryRef,
      data,
      isCategorySaving,
      onMoveNode,
      preventClose,
      setData,
      setLastAction,
    ]
  );

  useEffect(() => {
    invariant(ref.current);
    return combine(
      monitorForElements({
        canMonitor: ({ source }) =>
          source?.data?.uniqueContextId === context?.uniqueContextId,
        onDrop(args) {
          const { location, source } = args;
          // didn't drop on anything
          if (!location.current.dropTargets.length) {
            return;
          }

          if (source?.data?.type === "tree-item") {
            const itemId = source.data?.uuid;

            const target = location.current.dropTargets[0];
            const targetId = target.data?.uuid;

            const instruction = extractInstruction(target.data);
            const action = {
              type: "instruction",
              instruction,
              itemId,
              targetId,
            };

            if (instruction !== null) {
              updateTree(action);
            }
          }
        },
      })
    );
  }, [categoryRef, context, data, extractInstruction, updateTree]);

  return (
    <TreeContext.Provider value={context}>
      <TreeStyled id="tree" ref={ref}>
        {!hideUncategorize ? (
          <UnCategorizedView
            isOverlay={isOverlay}
            onClickCategoryTitle={onClickCategoryTitle}
            showCategory={showCategory}
            setShowCategory={setShowCategory}
          />
        ) : null}
        {data.map((item, index) => {
          if (isOverlay && !item?.visible) {
            return null;
          }
          return (
            <TreeItem
              isOverlay={isOverlay}
              hiddenCategory={hiddenCategory}
              allowParentCategory={allowParentCategory}
              ruleTitle={ruleTitle}
              isSelect={isSelect}
              preventClose={preventClose}
              onClickCategoryTitle={onClickCategoryTitle}
              showCategory={showCategory}
              setShowCategory={setShowCategory}
              item={item}
              level={0}
              key={item?.uuid}
              index={index}
              updateTree={updateTree}
              categoryRef={categoryRef}
              isLoading={isLoading}
              SavedCategoryInfo={SavedCategoryInfo}
              openNoteOverlay={openNoteOverlay}
              anchorElMenu={Boolean(anchorElMenu)}
              anchorElNote={anchorElNote}
              onClickMore={onClickMore}
              searchText={searchText}
            />
          );
        })}
      </TreeStyled>
    </TreeContext.Provider>
  );
}

const UnCategorizedView = ({
  isOverlay,
  onClickCategoryTitle,
  showCategory,
  setShowCategory,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const appliedFilterlist = useSelector(
    (state) => state.globalSlice.appliedFilterlist
  );
  return (
    <Box
      sx={{
        borderBottom: `1px solid ${theme.palette.color.slate[200]}`,
        py: "0.6rem",
        width: "100%",
        padding: "var(--grid)",
        // paddingRight: 40,
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        background: "transparent",
        borderRadius: 3,
        opacity: 1,
        height: "3rem",
        "& .actionBtnText": {
          backgroundColor: theme.palette.color.slate[200],
          borderRadius: theme.borderRadius.main,
          width: "fit-content",
          height: "1.8rem",
          marginLeft: "0.5rem",
          minWidth: "1.8rem",
          display: "none",
          color: theme.palette.color.slate[600],

          "&:hover": {
            backgroundColor: theme.palette.color.slate[100],
          },
        },
        "&:hover": {
          "& .actionBtnText": {
            display: "flex",
          },
        },
      }}
    >
      <Grid
        item
        xs={0.3}
        sx={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
        }}
      ></Grid>
      <Grid
        item
        xs={0.5}
        sx={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <ChildIcon />
      </Grid>
      <Grid
        item
        xs={0.8}
        sx={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <CircleIcon
          sx={{
            fontSize: "1.375rem",
            color: theme.palette.color.slate[300],
          }}
        />
      </Grid>
      <Grid
        item
        xs
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <span
          style={{
            maxWidth: "25rem",
            fontFamily: theme.typography.fontFamily,
            fontWeight: 600,
            color: theme.palette.color.slate[700],
            fontSize: "0.95rem",
            position: "relative",
            display: "block",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            textAlign: "left",
          }}
        >
          {t("unCategorized_category")}
        </span>
        {isOverlay ? (
          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                position: "absolute",
                display: "flex",
                alignItems: "center",
                backgroundColor: theme.palette.color.white,
                width: "10rem",
              }}
            >
              <Tooltip
                title={
                  showCategory?.includes("unCategorized_category")
                    ? t("category_action_unselect")
                    : t("category_action_select")
                }
                followCursor
                placement="top"
              >
                <span>
                  <Button
                    className={"actionBtnText"}
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowCategory((prev) => {
                        if (prev.includes("unCategorized_category")) {
                          return prev.filter(
                            (item) => item !== "unCategorized_category"
                          );
                        } else {
                          return [...prev, "unCategorized_category"];
                        }
                      });
                      onClickCategoryTitle(
                        e,
                        { uuid: "unCategorized_category" },
                        appliedFilterlist
                      );
                    }}
                    sx={{
                      cursor: "pointer !important",
                      backgroundColor: `${theme.palette.color.slate[300]} !important`,
                    }}
                  >
                    {showCategory?.includes("unCategorized_category")
                      ? t("X")
                      : t("Select")}
                  </Button>
                </span>
              </Tooltip>
            </div>
          </div>
        ) : null}
      </Grid>
    </Box>
  );
};

function ChildIcon() {
  return (
    <svg aria-hidden={true} width={24} height={24} viewBox="0 0 24 24">
      <circle cx={12} cy={12} r={2} fill={iconColor} />
    </svg>
  );
}
