import React, { useMemo } from "react";
import { Navigate, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import authSlice from "../store/slices/auth";
import jwt_decode from "jwt-decode";
import { useMediaQuery } from "@mui/material";
// let logoutTime = Date.now() + 30 * 1000;
function ProtectedRoute({ children }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const mdSize = useMediaQuery("(min-width:1280px)");
  const auth = useSelector((state) => state.auth);

  const { exp } = useMemo(() => {
    if (auth?.refreshToken) {
      return jwt_decode(auth?.refreshToken);
    } else {
      return 0;
    }
  }, [auth?.refreshToken]);

  React.useLayoutEffect(() => {
    let logoutTime = exp * 1000;
    if (!exp || Date.now() >= logoutTime) {
      navigate("/login");
      dispatch(authSlice.actions.logout());
      return;
    }
  });

  if (auth?.account && auth?.refreshToken && auth?.token) {
    // if (
    //   window.innerWidth >= 1280 ||
    //   location.pathname === "/dashboard" ||
    //   location.pathname === "/table"
    // ) {
    //   return children;
    // } else {
    //   enqueueSnackbar(t("mobile_view_warn"), {
    //     variant: "warning",
    //     autoHideDuration: 5000,
    //   });
    //   return <Navigate to="/dashboard" />;
    // }
    return children;
  } else {
    return <Navigate to="/login" />;
  }
}

export default ProtectedRoute;
