import { Color } from "../Helper";
import { getTailwindColor } from "../Helper/data";

const RadioCircle = ({
  checked = false,
  size = 20,
  color = "slate",
  shade = 500,
}) => {
  return (
    <div
      id="radio-main-circle"
      style={{
        width: size,
        height: size,
        borderRadius: size / 2,
        border: `2px solid ${getTailwindColor(color, shade)}`,
        position: "relative",
        backgroundColor: Color.white,
      }}
    >
      {checked ? <InnerCircle size={size} color={color} /> : null}
    </div>
  );
};

export default RadioCircle;

const InnerCircle = ({ size = 20, color }) => {
  return (
    <div
      id="radio-inner-circle"
      style={{
        width: size / 3,
        height: size / 3,
        borderRadius: size / 2,
        backgroundColor: getTailwindColor(color, 500),
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    ></div>
  );
};
