import { createSlice } from "@reduxjs/toolkit";

import { resetAllState } from "./reset";

const initialState = {
  staffSearchText: "",
  staffData: [],
  employeeType: [],
  employeeDepartment: [],
  employeeScenarios: [],
  employeeStateTab: "Planned",

  //contract
  contractData: [],

  //customer
  customerSearchText: "",
  customerStates: [],
  // customerScenarios: [],
  // customerCategory: [],

  //supplier
  supplierSearchText: "",
  supplierStates: [],
  // supplierScenarios: [],
  // supplierCategory: [],
};

const staffSlice = createSlice({
  name: "staffSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(resetAllState, () => initialState);
  },
  reducers: {
    setStaffData: (state, action) => {
      state.staffData = action.payload;
    },
    setEmployeeStateTab: (state, action) => {
      state.employeeStateTab = action.payload;
    },
    setEmployeeScenarios: (state, action) => {
      state.employeeScenarios = action.payload;
    },
    setEmployeeType: (state, action) => {
      state.employeeType = action.payload;
    },
    setEmployeeDepartment: (state, action) => {
      state.employeeDepartment = action.payload;
    },
    setStaffSearchText: (state, action) => {
      state.staffSearchText = action.payload;
    },

    //contract functions
    setContractData: (state, action) => {
      state.contractData = action.payload;
    },

    //customer functions
    setCustomerSearchText: (state, action) => {
      state.customerSearchText = action.payload;
    },
    setCustomerStates: (state, action) => {
      state.customerStates = action.payload;
    },
    resetCustomer: (state, action) => {
      if (action.payload) {
        Object.keys(action.payload).forEach((key) => {
          state[key] = action.payload[key];
        });
      } else {
        state.customerSearchText = "";
        state.customerStates = [];
      }
    },

    //supplier functions
    setSupplierSearchText: (state, action) => {
      state.supplierSearchText = action.payload;
    },
    setSupplierStates: (state, action) => {
      state.supplierStates = action.payload;
    },
    resetSupplier: (state, action) => {
      if (action.payload) {
        Object.keys(action.payload).forEach((key) => {
          state[key] = action.payload[key];
        });
      } else {
        state.supplierSearchText = "";
        state.supplierStates = [];
      }
    },
  },
});
export const {
  setStaffData,
  setEmployeeStateTab,
  setEmployeeScenarios,
  setEmployeeType,
  setEmployeeDepartment,
  setStaffSearchText,

  //contract functions
  setContractData,

  // customer functions
  setCustomerSearchText,
  setCustomerStates,
  resetCustomer,

  //supplier functions
  setSupplierSearchText,
  setSupplierStates,
  resetSupplier,
} = staffSlice.actions;
export default staffSlice;
