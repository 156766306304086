import {
  ListItemButton,
  ListItemText,
  IconButton,
  Typography,
  useTheme,
  Tooltip,
  Button,
  Stack,
  Grid,
  Box,
} from "@mui/material";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { memo, useEffect, useMemo, useRef, useState } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import DeleteIcon from "@mui/icons-material/Delete";
import { FaRegPlusSquare } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { v4 as v4uuid } from "uuid";
import { format } from "date-fns";

import {
  getAllTransactionsByParams,
  getTransactionByParams,
  formatDateToLocal,
  formatAmount,
  sleep,
} from "../../../Helper/data";
import SearchFilterView from "../../Overlay/ThemeFilter/Component/SearchFilterView";
import useUpdateEffect from "../../../hooks/4-useUpdateEffect/useUpdateEffect";
import { useDeferredTimeout } from "../../../hooks/useDeferredTimeout";
import ComponentLoader, { SpinLoader } from "../../ComponentLoader";
import useDebounce from "../../../hooks/3-useDebounce/useDebounce";
import DescriptionInput from "../../Overlay/DescriptionInput";
import DateInputPicker from "../../Overlay/DateInputPicker";
import OverlayFooter from "../../Overlay/OverlayFooter";
import EndPoints from "../../../APICall/EndPoints";
import TitleInput from "../../Overlay/TitleInput";
import AmountView from "../../Overlay/AmountView";
import MenuView from "../../Overlay/MenuView";
import CustomPopover from "../../PopOver";
import ThemeTabs from "../../ThemeTabs";
import APICall from "../../../APICall";
import theme from "./../../../theme";
import store from "../../../store";
import Icon from "../../Icon";

const menuOptions = [
  {
    value: 1,
    label: "Edit",
    icon: <ModeEditIcon />,
  },

  {
    value: 2,
    label: "Delete",
    icon: <DeleteIcon />,
  },

  {
    value: 3,
    label: "Unlink",
    icon: <LinkOffIcon />,
  },
];
const LoanSpecialPaymentsView = ({
  modalType,
  recurring_Obj,
  setRecurring_Obj,
  setIsRecurrenceUpdated,
  SpecialPaymentsItemList,
  cardItem,
  setCardItem,
  isLoanSpecialPayments,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const menuItem = useRef({});
  const formData = useRef({ defaultData: {}, formType: "add" });

  //state
  const [anchorEl, setAnchorEl] = useState(false);
  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const [list, setList] = useState([]);
  const [isListLoading, setIsListLoading] = useState(false);

  //api
  const getSpecialPaymentsList = async (id) => {
    const dataSetData = store.getState()?.boardSlice?.dataSetData;
    const stateByTitle = store.getState()?.globalSlice?.stateByTitle;
    const BookedId = stateByTitle?.["Booked"]?.[0]?.uuid;
    setIsListLoading(true);
    try {
      const data = await getAllTransactionsByParams({
        dataset: dataSetData?.uuid,
        category_dataset: dataSetData?.use_global_categories
          ? null
          : dataSetData?.uuid,
        is_reconciled: false,
        order_by: "-due_date",
        state: [BookedId],
        recurring_rule: [id],
        bank_transaction_type: "LOAN_SPECIAL_PAYMENT",
      });

      setList(
        [...(data || [])]
          ?.map((o1) =>
            o1?.uuid === cardItem?.uuid
              ? { ...o1, title: cardItem?.title, note: cardItem?.note }
              : o1
          )
          ?.sort((a, b) => new Date(a?.due_date) - new Date(b?.due_date))
      );
      setIsListLoading(false);
    } catch (error) {
      setIsListLoading(false);
    }
  };

  const deleteSpecialPaymentById = async (uuid) => {
    let data = null;
    await APICall("delete", `${EndPoints.transactions}${uuid}/`).then(
      (response) => {
        if (response.status === 204) {
          removeItem(uuid);
        }
      }
    );

    return data;
  };

  const updateSpecialPaymentById = async (uuid, obj) => {
    let data = null;
    await APICall("patch", `${EndPoints.transactions}${uuid}/`, obj).then(
      (response) => {
        if (response.status === 200 && response?.data) {
          removeItem(uuid);
          setIsRecurrenceUpdated(true);
        }
      }
    );

    return data;
  };

  const total_special_paid_amount = useMemo(
    () =>
      list?.reduce((total, item) => total + Number(item?.gross_value || 0), 0),
    [list]
  );

  const LoanRemainingAmount =
    Number(Math.abs(recurring_Obj?.value || 0)) -
    Number(Math.abs(total_special_paid_amount));

  //lifecycle
  useEffect(() => {
    if (recurring_Obj?.uuid && modalType === "edit") {
      getSpecialPaymentsList(recurring_Obj?.uuid);
    }
  }, [modalType, recurring_Obj?.uuid]);

  useUpdateEffect(() => {
    if (SpecialPaymentsItemList) {
      let obj = { deposit_value: total_special_paid_amount };
      setRecurring_Obj((prev) => ({
        ...prev,
        ...obj,
      }));
    }
  }, [LoanRemainingAmount]);

  useUpdateEffect(() => {
    if (SpecialPaymentsItemList) {
      SpecialPaymentsItemList.current = list;
    }
  }, [LoanRemainingAmount, list]);

  useDebounce(
    () => {
      if (cardItem?.uuid && list?.length > 0 && isLoanSpecialPayments) {
        setList((prev) =>
          prev
            ?.map((o1) =>
              o1?.uuid === cardItem?.uuid
                ? { ...o1, title: cardItem?.title, note: cardItem?.note }
                : o1
            )
            ?.sort((a, b) => new Date(a?.due_date) - new Date(b?.due_date))
        );
      }
    },
    500,
    [cardItem?.title, cardItem?.note, isLoanSpecialPayments],
    true
  );

  //functions
  const onClickAdd = (e) => {
    formData.current.defaultData = {
      title: `${t("loan_special_payment_default_title")} ${list?.length + 1}`,
      uuid: v4uuid(),
      due_date: null,
    };
    formData.current.formType = "add";
    setAnchorEl(e.currentTarget);
  };

  const onClickMenu = ({ e, setLoading, item }) => {
    menuItem.current = {
      setLoading,
      item,
      hideValue: !cardItem?.uuid
        ? [3]
        : cardItem?.uuid === item?.uuid
          ? [2, 3]
          : null,
    };
    setAnchorElMenu(e.currentTarget);
  };

  const closeMenu = () => {
    setAnchorElMenu(null);
  };

  const onClickMenuItem = async (e, _value) => {
    const { item, setLoading } = menuItem.current;

    if (_value === 1) {
      formData.current.formType = "edit";
      formData.current.defaultData = item;
      setAnchorEl(e.currentTarget);
    }
    if (_value === 2) {
      closeMenu();
      setLoading(item?.uuid);
      if (modalType === "edit") {
        setIsRecurrenceUpdated(true);
        await deleteSpecialPaymentById(item?.uuid);
      } else {
        removeItem(item?.uuid);
      }
    }
    if (_value === 3) {
      closeMenu();
      setLoading(item?.uuid);
      const obj = { recurring_rule: null, bank_transaction_type: "DEFAULT" };
      await updateSpecialPaymentById(item?.uuid, obj);
    }
  };

  const removeItem = (uuid) => {
    if (cardItem?.uuid === uuid) {
      setCardItem((prev) => ({ ...prev, uuid: null }));
    }
    setList((prev) => prev?.filter((o1) => o1?.uuid !== uuid));
  };

  const onClosePopOver = () => {
    setAnchorEl(null);
  };

  return (
    <Stack
      sx={{ width: "100%", mt: "2rem", mb: "1rem", ml: "1.5rem", pl: "0.5rem" }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Icon
          icon={<FaRegPlusSquare />}
          color={theme.palette.color.blueGrey[700]}
          fontSize={"1.4rem"}
          style={{
            position: "absolute",
            left: "-3.25rem",
          }}
        />
        <Typography
          variant="subtitle1"
          fontWeight={"fontWeightMediumBold"}
          color="color.mainTitle"
          sx={{
            fontSize: "0.9rem",
          }}
        >
          {t("loan_form_special_payment")}
        </Typography>
        <Tooltip title={t("loan_form_special_payment_info")} placement="top">
          <HelpOutlineOutlinedIcon
            sx={{
              fontSize: "1rem",
              cursor: "pointer",
              ml: "0.5rem",
              mt: "1px",
              color: theme.palette.primary.main,
            }}
          />
        </Tooltip>
      </div>

      <Grid
        container
        columnsSpacing={6}
        sx={{
          mt: "1rem",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {list?.map((item) => (
          <ItemView key={item?.uuid} item={item} onClickMenu={onClickMenu} />
        ))}
      </Grid>
      {Boolean(anchorElMenu) ? (
        <MenuView
          anchorEl={anchorElMenu}
          open={Boolean(anchorElMenu)}
          options={menuOptions}
          handleClose={closeMenu}
          onClickItem={onClickMenuItem}
          hideValue={menuItem.current?.hideValue}
        />
      ) : null}
      <CustomPopover
        type="anchor"
        anchorEl={anchorEl}
        onClose={onClosePopOver}
        BaseButton={
          <Button
            disabled={
              Math.abs(LoanRemainingAmount) <= 0 ||
              isListLoading ||
              !recurring_Obj?.start_date ||
              !recurring_Obj?.end_date
            }
            onClick={onClickAdd}
            size="medium"
            fontWeight={"fontWeightMediumBold"}
            sx={{
              width: "fit-content",
              height: "fit-content",
              mt: "1.5rem",
              fontSize: "0.85rem",
              color: theme.palette.color.slate[700],
              backgroundColor: theme.palette.color.slate[100],
              "&:hover": {
                backgroundColor: theme.palette.color.slate[200],
              },
            }}
          >
            {t("loan_form_add_special_payment")}
          </Button>
        }
      >
        <FormOverlay
          cardItem={cardItem}
          LoanRemainingAmount={LoanRemainingAmount}
          recurring_Obj={recurring_Obj}
          setList={setList}
          defaultData={formData?.current?.defaultData}
          formType={formData?.current?.formType}
          modalType={modalType}
          menuItem={menuItem}
          onClosePopOver={onClosePopOver}
          setIsRecurrenceUpdated={setIsRecurrenceUpdated}
          setCardItem={setCardItem}
        />
      </CustomPopover>
    </Stack>
  );
};

export default memo(LoanSpecialPaymentsView);

const FormOverlay = ({
  cardItem,
  LoanRemainingAmount,
  modalType,
  formType,
  menuItem,
  defaultData,
  recurring_Obj,
  setList,
  onClosePopOver,
  setIsRecurrenceUpdated,
  setCardItem,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);

  //state
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({});
  const [item, setItem] = useState({
    uuid: defaultData?.uuid,
    title: defaultData?.title,
    note: defaultData?.note,
    due_date: defaultData?.due_date,
    gross_value: defaultData?.gross_value,
    recurring_rule: recurring_Obj?.uuid,
    category: cardItem?.category,
    currency: cardItem?.currency,
    data_source: cardItem?.data_source || dataSetData?.internal_data_source,
    state: "Booked",
    scenario: "Base",
    bank_transaction_type: "LOAN_SPECIAL_PAYMENT",
    ...defaultData,
  });
  const [value, setValue] = useState(1);

  useEffect(() => {
    if (value === 1) {
      setItem({
        uuid: defaultData?.uuid,
        title: defaultData?.title,
        note: defaultData?.note,
        due_date: defaultData?.due_date,
        gross_value: defaultData?.gross_value,
        recurring_rule: recurring_Obj?.uuid,
        category: cardItem?.category,
        currency: cardItem?.currency,
        data_source: cardItem?.data_source || dataSetData?.internal_data_source,
        state: "Booked",
        scenario: "Base",
        bank_transaction_type: "LOAN_SPECIAL_PAYMENT",
      });
    } else {
      setItem(null);
    }
  }, [value]);

  //api
  const addSpecialPayment = async (obj) => {
    setIsLoading(true);
    await APICall("post", `${EndPoints.transactions}`, obj).then((response) => {
      if (response.status === 201 && response.data) {
        setIsLoading(false);
        onClosePopOver();
        setList((prev) =>
          [...prev, response.data]?.sort(
            (a, b) => new Date(a?.due_date) - new Date(b?.due_date)
          )
        );
      }
    });
  };

  const updateSpecialPayment = async (uuid, obj) => {
    setIsLoading(true);
    await APICall("patch", `${EndPoints.transactions}${uuid}/`, obj).then(
      (response) => {
        if (response.status === 200 && response.data) {
          menuItem.current = { ...menuItem.current, item: response.data };

          if (cardItem?.uuid === response.data?.uuid) {
            setCardItem((prev) => ({
              ...prev,
              title: response.data?.title,
              note: response.data?.note,
              due_date: response.data?.due_date,
              gross_value: response.data?.gross_value,
            }));
          }

          setIsLoading(false);
          onClosePopOver();
          setList((prev) =>
            prev
              ?.map((o1) =>
                o1?.uuid === item?.uuid ? { ...response.data } : o1
              )
              ?.sort((a, b) => new Date(a?.due_date) - new Date(b?.due_date))
          );
        }
      }
    );
  };

  //functions
  const onClickSave = async () => {
    let errorText = "";
    let name = "";
    if (!item && value === 2) {
      enqueueSnackbar(t("loan_issue_please_select_special_payment"), {
        variant: "error",
        duration: 5000,
        preventDuplicate: true,
      });
      return;
    }
    if (!item?.due_date) {
      name = "due_date";
      errorText = t("loan_issue_please_add_special_payment_date");
    }
    if (Math.abs(item?.gross_value) > Math.abs(LoanRemainingAmount)) {
      name = "gross_value";
      errorText = t(
        "loan_issue_special_payment_amount_can_not_be_greater_than_remaining_principal_amount"
      );
    }
    if (!item?.gross_value) {
      name = "gross_value";
      errorText = t("loan_issue_please_add_special_payment_amount");
    }
    if (!item?.title?.trim("")) {
      name = "title";
      errorText = t("loan_issue_please_add_special_payment_title");
    }
    if (errorText) {
      if (value === 1) {
        setError((prev) => ({ ...prev, [name]: errorText }));
      } else {
        enqueueSnackbar(errorText, {
          variant: "error",
          duration: 5000,
          preventDuplicate: true,
        });
      }
      return;
    }
    if (modalType === "edit") {
      setIsRecurrenceUpdated(true);
      if (value === 1) {
        if (formType === "add") {
          addSpecialPayment(item);
        } else {
          updateSpecialPayment(item?.uuid, item);
        }
      } else {
        const obj = {
          ...item,
          recurring_rule: recurring_Obj?.uuid,
          category: cardItem?.category,
          bank_transaction_type: "LOAN_SPECIAL_PAYMENT",
          skip_transaction_rule: true,
        };
        setList((prev) =>
          [...prev, obj]?.sort(
            (a, b) => new Date(a?.due_date) - new Date(b?.due_date)
          )
        );
        onClosePopOver();
        sleep(0);
        await updateSpecialPayment(item?.uuid, obj);
      }
    } else {
      onClosePopOver();
      if (formType === "add") {
        setList((prev) =>
          [...prev, item]?.sort(
            (a, b) => new Date(a?.due_date) - new Date(b?.due_date)
          )
        );
      } else {
        menuItem.current = { ...menuItem.current, item };
        setList((prev) =>
          prev
            ?.map((o1) => (o1?.uuid === item?.uuid ? { ...item } : o1))
            ?.sort((a, b) => new Date(a?.due_date) - new Date(b?.due_date))
        );
      }
    }
  };

  return (
    <Box
      sx={{
        width: "50rem",
        minHeight: "35rem",
        position: "relative",
        p: "1.5rem",
        pb: "5rem",
        borderRadius: theme.borderRadius.main,
        backgroundColor: theme.palette.color.white,
      }}
    >
      {formType === "add" ? (
        <ThemeTabs
          tab={value}
          setTab={setValue}
          options={[
            { label: t("Add New"), value: 1 },
            { label: t("Select existing"), value: 2 },
          ]}
          sx={{
            mb: "2rem",
            ml: "0.5rem",
            "& .MuiTab-root": {
              minWidth: "5rem",
              marginRight: "0.5rem",
            },
          }}
        />
      ) : null}
      {value === 1 ? (
        <AddNewView
          item={item}
          error={error}
          setError={setError}
          setItem={setItem}
          recurring_Obj={recurring_Obj}
        />
      ) : (
        <ExitingView
          item={item}
          setItem={setItem}
          start_date={recurring_Obj?.start_date}
          end_date={recurring_Obj?.end_date}
          amount={LoanRemainingAmount}
        />
      )}
      <OverlayFooter
        loading={isLoading}
        textAdd={formType === "edit" ? t("Update") : t("Add")}
        onClickAction={onClickSave}
        sx={{
          position: "absolute",
          bottom: "1.5rem",
          left: "1.5rem",
          right: "1.5rem",
          justifyContent: "flex-end",
        }}
      />
    </Box>
  );
};

const ItemView = ({ item, onClickMenu }) => {
  return (
    <Stack
      direction={"row"}
      justifyContent={"space-between"}
      sx={{
        minHeight: "3rem",
        width: "100%",
        ml: "-1rem",
        "&:hover": {
          cursor: "pointer",
          "& #trash-icon": {
            visibility: "visible",
          },
        },
      }}
    >
      <Grid
        item
        xs={4}
        sx={{
          color: theme.palette.color.slate[700],
          fontWeight: "fontWeightMediumBold",
          overflow: "hidden",
          fontSize: "0.8rem",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        <b style={{ marginRight: "0.5rem" }}>~</b>
        {item?.title}
      </Grid>
      <Grid
        item
        xs={2.5}
        sx={{
          color: theme.palette.color.description,
          overflow: "hidden",
          fontSize: "0.8rem",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          pr: "1rem",
        }}
      >
        <Tooltip placement="top" followCursor title={item?.note}>
          {item?.note}
        </Tooltip>
      </Grid>
      <Grid
        item
        xs={3}
        sx={{
          color: theme.palette.color.slate[700],
          fontWeight: "fontWeightMediumBold",
          fontSize: "0.8rem",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {formatAmount({ amount: Math.abs(item?.gross_value || 0) })}
      </Grid>
      <Grid
        item
        xs={2.5}
        sx={{
          color: theme.palette.color.slate[700],
          fontWeight: "fontWeightMediumBold",
          fontSize: "0.8rem",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {formatDateToLocal(item?.due_date, "dd MMM yyyy")}
        <div
          style={{
            width: "2.5rem",
            paddingLeft: "0.5rem",
            marginTop: "-2px",
          }}
        >
          <MenuViewWrapper item={item} onClickMenu={onClickMenu} />
        </div>
      </Grid>
    </Stack>
  );
};

const MenuViewWrapper = ({ onClickMenu, item }) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);

  const onClick = (e) => {
    // setLoading(uuid);
    onClickMenu({
      e,
      setLoading,
      item,
    });
  };

  if (loading === item?.uuid) {
    return (
      <div
        style={{
          position: "relative",
          display: "flex",
          alignItems: "center",
        }}
      >
        <SpinLoader size={18} />
      </div>
    );
  }

  return (
    <IconButton
      aria-label="more"
      id="trash-icon"
      aria-haspopup="true"
      onClick={onClick}
      sx={{
        background: "transparent !important",
        p: 0,
        visibility: "hidden",
        cursor: "pointer",
      }}
    >
      <MoreVertIcon
        sx={{
          fontSize: "1.2rem",
          color: theme.palette.color.slate[600],
        }}
      />
    </IconButton>
  );
};

const AddNewView = ({ setError, setItem, item, error, recurring_Obj }) => {
  const { t } = useTranslation();

  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);

  //functions
  const onChange = (e) => {
    const { name, value } = e.target;
    setError({});
    setItem((prev) => ({ ...prev, [name]: value }));
  };

  const onChangeAmount = (originalValue) => {
    const value = -Math.abs(parseFloat(originalValue)?.toFixed(1));
    setError({});
    setItem((prev) => ({ ...prev, gross_value: value }));
  };

  const onChangeRangeDate = (value, name) => {
    if (value?.toString() !== "Invalid Date") {
      const obj = {
        due_date: format(value, "yyyy-MM-dd"),
      };
      setError({});
      setItem((prev) => ({
        ...prev,
        ...obj,
      }));
    }
  };
  return (
    <>
      <TitleInput
        id="special-payment-title"
        value={item?.title}
        label={t("Title")}
        tooltip={t("Title")}
        name="title"
        onChange={onChange}
        helperText={error?.["title"]}
        error={Boolean(error?.["title"])}
        hideTitle
        likeGoogle
        variant="filled"
        sx={{
          width: "100%",
          "& .MuiInputBase-root": {
            backgroundColor: theme.palette.color.slate[50],
          },
        }}
      />
      <AmountView
        likeGoogle
        defaultValue={item?.gross_value}
        onBlur={onChangeAmount}
        label={t("loan_form_amount")}
        tooltip={t("loan_form_amount_tooltip")}
        error={error?.["gross_value"]}
        helperText={error?.["gross_value"]}
        variant="filled"
        currency={dataSetData?.currency}
        fontSize="0.9rem"
      />
      <DateInputPicker
        showFullDate
        name="due_date"
        label={t("loan_form_special_payment_date")}
        tooltip={t("loan_form_special_payment_date_tooltip")}
        minDate={new Date(recurring_Obj?.start_date)}
        maxDate={new Date(recurring_Obj?.end_date)}
        error={error?.["due_date"]}
        helperText={error?.["due_date"]}
        value={item?.due_date}
        onChange={onChangeRangeDate}
        variant="filled"
        hidePickerBlurEffect
        likeGoogle
        hideTitle
        fontSize="0.9rem"
        sx={{ width: "100%" }}
      />

      <DescriptionInput
        maxRows={3}
        value={item?.note}
        onChange={onChange}
        name="note"
        label={t("Note")}
        hideTitle
        likeGoogle
        variant="filled"
      />
    </>
  );
};

const ExitingView = ({ item, setItem, start_date, end_date, amount }) => {
  const { t } = useTranslation();
  const dataSetData = store.getState()?.boardSlice?.dataSetData;
  const stateByTitle = store.getState()?.globalSlice?.stateByTitle;
  const BookedId = stateByTitle?.["Booked"]?.[0]?.uuid;

  const [searchText, setSearchText] = useState("");
  const _searchText = useDeferredTimeout({ value: searchText });

  const ListData = useInfiniteQuery({
    queryKey: [
      "transactions",
      {
        apiType: "SpecialPaymentsList",
        searchText: _searchText,
        amount,
        start_date,
        end_date,
      },
    ],

    queryFn: ({ pageParam = null, signal }) => {
      let params = {
        page: pageParam,
        page_size: 200,
        title_or_note: _searchText,
        dataset: dataSetData?.uuid,
        min_gross_value: -Math.abs(amount),
        startDate: start_date,
        endDate: end_date,
        state: [BookedId],
        is_recurred: false,
      };

      const response = getTransactionByParams(params);
      if (response) {
        return response;
      }
    },
    getNextPageParam: (lastPage) => {
      return lastPage?.next ?? undefined;
    },
    select: (data) => {
      const flatData = data?.pages?.map((o1) => o1?.results)?.flat();
      return flatData;
    },
    keepPreviousData: true,
    refetchOnMount: true,
    enabled:
      !!dataSetData?.uuid &&
      !!BookedId &&
      !!start_date &&
      !!end_date &&
      !!amount,
  });

  //functions
  const onChangeSearch = (e) => {
    setSearchText(e?.target?.value);
  };

  const onClickClear = (e) => {
    setSearchText("");
  };

  const handleListItemClick = (event, o1) => {
    setItem(o1);
  };

  return (
    <Stack>
      <SearchFilterView
        active={false}
        value={searchText}
        color={"slate"}
        onClickClear={onClickClear}
        onChange={onChangeSearch}
        width="16rem"
        sx={{ mt: "-0.5rem", mb: "2rem", width: "fit-content" }}
      />
      <Grid container>
        {ListData?.isFetching ? (
          <ComponentLoader
            loading
            isSkeleton
            height="14rem"
            skeletonCount={4}
            placeHolderHeight="3.5rem"
            sx={{
              width: "100%",
            }}
            skeltonSx={{
              transform: "scale(1,0.9)",
            }}
          />
        ) : null}
        {!ListData?.isFetching && ListData?.data?.length > 0
          ? ListData?.data?.map((o1) => (
              <ListItemButton
                key={o1?.uuid}
                selected={item?.uuid === o1?.uuid}
                onClick={(event) => handleListItemClick(event, o1)}
              >
                <Grid item xs={6}>
                  <ListItemText primary={o1?.title} />
                </Grid>
                <Grid item xs={3}>
                  <ListItemText primary={o1?.due_date} />
                </Grid>
                <Grid item xs={3}>
                  <ListItemText
                    primary={formatAmount({ amount: o1?.gross_value })}
                  />
                </Grid>
              </ListItemButton>
            ))
          : null}
      </Grid>
      {ListData?.hasNextPage ? (
        <Button
          onClick={() => ListData?.fetchNextPage()}
          disabled={ListData?.isFetching}
          loading={ListData?.isFetching}
          sx={{ width: "fit-content", mt: "1rem" }}
        >
          {t("Load More")}
        </Button>
      ) : null}
    </Stack>
  );
};
