import { Rating, Stack, Typography } from "@mui/material";
import { Images } from "../../Helper";
const googleLogo = (
  <svg viewBox="0 0 14 15" style={{ width: "2rem", marginRight: "0.25rem" }}>
    <path
      d="M13.987 7.311c0-.484-.04-.97-.123-1.447H7.147v2.743h3.846A3.296 3.296 0 019.57 10.77v1.78h2.295c1.347-1.241 2.122-3.073 2.122-5.24z"
      fill="#4285F4"
    ></path>
    <path
      d="M7.147 14.27c1.92 0 3.54-.631 4.72-1.72l-2.295-1.78c-.638.435-1.462.681-2.423.681-1.858 0-3.433-1.253-3.998-2.938H.782v1.834a7.123 7.123 0 006.365 3.923z"
      fill="#34A853"
    ></path>
    <path
      d="M3.148 8.513a4.266 4.266 0 010-2.727V3.95H.782a7.128 7.128 0 000 6.396l2.366-1.834z"
      fill="#FBBC04"
    ></path>
    <path
      d="M7.147 2.844a3.87 3.87 0 012.732 1.068l2.033-2.033A6.845 6.845 0 007.147.026 7.12 7.12 0 00.782 3.951l2.366 1.835c.563-1.688 2.14-2.942 3.999-2.942z"
      fill="#EA4335"
    ></path>
  </svg>
);
const RatingView = () => {
  return (
    <Stack
      sx={{
        display: "flex",
        alignItems: "center",
        position: "absolute",
        bottom: 16,
        right: "50%",
        transform: "translateX(50%)",
        flexDirection: "row",
      }}
    >
      <RatingItem icon={googleLogo} />
      <RatingItem
        icon={
          <img
            src={Images.capterra_logo}
            alt="capterra"
            style={{ width: "9rem", marginLeft: "2rem" }}
          ></img>
        }
      />
    </Stack>
  );
};

export default RatingView;

const RatingItem = ({ icon }) => {
  return (
    <Stack direction={"row"} alignItems={"center"}>
      {icon}
      <Stack>
        <Rating name="read-only" value={5} max={5} size="small" readOnly />
        <Typography
          variant="caption"
          color="color.description"
          fontWeight={"fontWeightMediumBold"}
          sx={{ pl: "0.25rem" }}
        >
          5 out of 5
        </Typography>
      </Stack>
    </Stack>
  );
};
