import {
  FormControlLabel,
  FormControl,
  Typography,
  RadioGroup,
  FormLabel,
  useTheme,
  Divider,
  Tooltip,
  Button,
  Radio,
  Stack,
  alpha,
  Card,
  Grid,
  Box,
} from "@mui/material";
import {
  useImperativeHandle,
  useCallback,
  forwardRef,
  useContext,
  useEffect,
  useState,
  useMemo,
  useRef,
} from "react";
import ModeEditRoundedIcon from "@mui/icons-material/ModeEditRounded";
import { PiExclude, PiGear, PiMagicWandFill } from "react-icons/pi";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { useDispatch, useSelector } from "react-redux";
import AutoSizer from "react-virtualized-auto-sizer";
import DeleteIcon from "@mui/icons-material/Delete";
import { useQuery } from "@tanstack/react-query";
import { BsFillTagsFill } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import ListItem from "@mui/material/ListItem";
import { FixedSizeList } from "react-window";
import { useSnackbar } from "notistack";
import { throttle } from "underscore";

import {
  setSelectedRulesCategory,
  setIsAutoRulesEnabled,
  setSearchText,
} from "../../../store/slices/rules";
import {
  thinScrollbarStyle,
  formatDateToLocal,
  getAllRules,
  remToPx,
} from "../../../Helper/data";
import CategoryFilterView from "../../../components/Overlay/ThemeFilter/Component/CategoryFilterView";
import SearchFilterView from "../../../components/Overlay/ThemeFilter/Component/SearchFilterView";
import AddFilterPopOver from "../../../components/Overlay/ThemeFilter/AddFilterPopover";
import AddFilterView from "../../../components/Overlay/ThemeFilter/AddFilterView";
import FinbanInfoBulb from "../../../components/FInbanInfo/FinbanInfoBulb";
import useDebounce from "../../../hooks/3-useDebounce/useDebounce";
import ComponentLoader from "../../../components/ComponentLoader";
import { setPopupStatus3 } from "../../../store/slices/datasets";
import CustomModal from "../../../components/Model/CustomModal";
import TitleInput from "../../../components/Overlay/TitleInput";
import useSubscriptions from "../../../hooks/useSubscriptions";
import IOSSwitch from "../../../components/Switchs/IOSSwitch";
import { GlobalContext } from "../../../GlobalContextWrapper";
import { setProfile } from "../../../store/slices/settings";
import CategoryChip from "../../../components/CategoryChip";
import AddButton from "../../../components/AddButton";
import ThemeTabs from "../../../components/ThemeTabs";
import Translate from "../../../hooks/HOC/Translate";
import EndPoints from "../../../APICall/EndPoints";
import useWidth from "../../../hooks/useWidth";
import Icon from "../../../components/Icon";
import { queryClient } from "../../../App";
import { Color } from "../../../Helper";
import APICall from "../../../APICall";
import store from "../../../store";

const AutoCategorizationsOptions = [
  {
    label: "auto_cat_radio_title_1",
    des: "auto_cat_radio_des_1",
    value: 0,
    // value: "Off",
  },
  // {
  //   label: "auto_cat_radio_title_2",
  //   des: "auto_cat_radio_des_2",
  //   value: 2,
  //   // value: "Suggestion",
  // },
  {
    label: "auto_cat_radio_title_3",
    des: "auto_cat_radio_des_3",
    value: 1,
    // value: "On",
  },
];
const LevelOptions = [
  {
    label: "level_radio_title_1",
    des: "level_radio_des_1",
    value: 0,
    // value: "All",
  },
  {
    label: "level_radio_title_2",
    des: "level_radio_des_2",
    value: 1,
    // value: "Organization",
  },
  {
    label: "level_radio_title_3",
    des: "level_radio_des_3",
    value: 2,
    // value: "Integration",
  },
];

const tabOptions = [
  {
    value: "auto",
    label: "rule_tab_list_1",
    icon: (
      <Icon
        icon={<PiMagicWandFill />}
        fontSize={"1.2rem"}
        color="inherit"
        style={{
          mr: "0.5rem",
        }}
      />
    ),
  },
  {
    value: "exclude",
    label: "rule_tab_list_4",
    icon: (
      <Icon
        icon={<PiExclude />}
        fontSize={"1.2rem"}
        color="inherit"
        style={{
          mr: "0.5rem",
        }}
      />
    ),
  },
  {
    value: "setting",
    label: "rule_tab_list_3",
    icon: (
      <Icon
        icon={<PiGear />}
        fontSize={"1.2rem"}
        color="inherit"
        style={{
          mr: "0.5rem",
        }}
      />
    ),
  },
];
const type_options = {
  1: {
    uuid: 1,
    title: "Income",
  },
  2: { uuid: 2, title: "Expense" },
};

const RulesListView = ({ isOverlay, sx }) => {
  const theme = useTheme();
  const [isSubscriptionValid] = useSubscriptions();
  const globalContext = useContext(GlobalContext);

  const excludeListRef = useRef(null);
  const rulesRef = globalContext?.rulesRef;

  //state
  const [tab, setTab] = useState("auto");

  //function
  const handleChangeTab = (event, newValue) => {
    if (tab !== newValue) {
      setTab(newValue);
    }
  };

  const onClickExcludeNew = () => {
    excludeListRef.current?.addNewExcludeName();
  };

  const onClickAddNewRule = () => {
    if (isSubscriptionValid({ showMessage: true })) {
      rulesRef.current?.onOpenRule({
        modalType: "add",
      });
    }
  };

  const onClickEditRule = (e, item) => {
    e?.stopPropagation();
    rulesRef.current?.onOpenRule({
      modalType: "edit",
      rule: item,
    });
  };

  return (
    <Box
      sx={{
        width: isOverlay ? "100%" : "80%",
        mx: isOverlay ? "auto" : undefined,
        height: "100%",
        backgroundColor: theme.palette.color.appThemeBg,
        ...sx,
      }}
    >
      <FilterView
        tab={tab}
        onClickAddNewRule={onClickAddNewRule}
        onClickExcludeNew={onClickExcludeNew}
      />
      <ThemeTabs
        options={tabOptions}
        tab={tab}
        onChange={handleChangeTab}
        sx={{ mb: "1rem" }}
      />

      {tab === "auto" ? (
        <ListView
          tab={tab}
          onClickEditRule={onClickEditRule}
          isOverlay={isOverlay}
        />
      ) : null}
      {tab === "exclude" ? <ExcludeView ref={excludeListRef} /> : null}
      {tab === "setting" ? <Setting /> : null}
    </Box>
  );
};
export default RulesListView;

const FilterView = ({ onClickExcludeNew, onClickAddNewRule, tab }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isFilterOpen = useRef(false);

  //redux
  const searchText = useSelector((state) => state.rulesSlice.searchText);
  const selectedRulesCategory = useSelector(
    (state) => state.rulesSlice.selectedRulesCategory
  );
  const isAutoRulesEnabled = useSelector(
    (state) => state.rulesSlice.isAutoRulesEnabled
  );

  const [activeFilter, setActiveFilter] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [filtered, setFiltered] = useState([]);
  const [isIncludedInFilterList, setIsIncludedInFilterList] = useState([]);

  //function
  const onClose = (_selectedIds) => {
    isFilterOpen.current = false;
    setIsIncludedInFilterList(_selectedIds);
    setAnchorEl(null);
    let remove = {};

    if (
      selectedRulesCategory?.length > 0 &&
      !_selectedIds?.includes("category")
    ) {
      remove.selectedRulesCategory = [];
    }
    if (remove.selectedRulesCategory) {
      dispatch(setSelectedRulesCategory(remove.selectedRulesCategory));
    }
  };

  const onClickFilter = useCallback((e) => {
    isFilterOpen.current = true;
    setAnchorEl(e.currentTarget);
  }, []);

  const removeFilter = useCallback((key) => {
    setIsIncludedInFilterList((prev) => prev?.filter((item) => item !== key));
  }, []);

  useDebounce(
    () => {
      // if (!isFilterOpen.current) {
      let array = [];
      let included_array = [...isIncludedInFilterList];

      if (selectedRulesCategory?.length > 0) {
        array.push("category");
        included_array.push("category");
      }

      included_array = Array.from(new Set(included_array));
      setFiltered(array);
      setIsIncludedInFilterList(included_array);
      // }
    },
    500,
    [tab, selectedRulesCategory],
    true
  );

  const FilterOptions = useMemo(
    () => [
      {
        uuid: "category",
        title: "add_category_filter",
        icon: <LocalOfferIcon />,
      },
    ],
    []
  );
  const onClickClear = () => {
    dispatch(setSearchText(""));
  };

  const onFocusInput = () => {
    setActiveFilter(true);
  };

  const onBlurInput = () => {
    setActiveFilter(false);
  };

  const onChangeSearch = (e) => {
    dispatch(setSearchText(e.target?.value));
  };

  const onClickSwitch = (e) => {
    dispatch(setIsAutoRulesEnabled(e.target.checked));
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        mb: "1.5rem",
      }}
    >
      {tab === "setting" ? (
        <></>
      ) : (
        <Stack
          direction="row"
          alignItems={"center"}
          sx={{
            position: "relative",
          }}
        >
          <Tooltip placement="top" title={t("show_auto_rules_tooltip")}>
            <Stack
              direction={"row"}
              alignItems={"center"}
              sx={{ gap: "0.5rem" }}
            >
              <Typography
                variant="subtitle2"
                color="color.description"
                fontWeight={"fontWeightMedium"}
              >
                {t("show_auto_rules")}
              </Typography>
              <span>
                <IOSSwitch
                  onClick={onClickSwitch}
                  checked={!!isAutoRulesEnabled}
                />
              </span>
            </Stack>
          </Tooltip>
          <SearchFilterView
            active={false}
            value={searchText}
            color={"slate"}
            onClickClear={onClickClear}
            onFocusInput={onFocusInput}
            onBlurInput={onBlurInput}
            onChange={onChangeSearch}
          />
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              ml: "0.75rem",
              height: "1.2rem",
              opacity: "0.6",
              alignSelf: "center",
            }}
          />
          <Box
            display="inline-flex"
            alignItems="center"
            justifyContent={"flex-start"}
            width="fit-content"
            sx={{
              minWidth: "25rem",
              transition: `all 0.3s ease-in-out`,
              zIndex: 2,
              flexWrap: "wrap",
            }}
          >
            {isIncludedInFilterList?.length > 0 ? null : (
              <AddFilterView
                isIncludedInFilterList={isIncludedInFilterList}
                onClick={onClickFilter}
                sx={{ ml: 0 }}
              />
            )}

            {isIncludedInFilterList?.map((item) => {
              if (item === "category") {
                return (
                  <CategoryFilter
                    key="category"
                    type="list"
                    isFilterOpen={isFilterOpen}
                    filterKey="category"
                    removeFilter={removeFilter}
                  />
                );
              }

              return null;
            })}

            {isIncludedInFilterList?.length > 0 ? (
              <AddFilterView
                isIncludedInFilterList={isIncludedInFilterList}
                onClick={onClickFilter}
              />
            ) : null}
            <ResetView
              filtered={filtered}
              setFiltered={setFiltered}
              setIsIncludedInFilterList={setIsIncludedInFilterList}
            />
          </Box>
          {!!anchorEl ? (
            <AddFilterPopOver
              anchorEl={anchorEl}
              onClose={onClose}
              selectedIds={isIncludedInFilterList}
              options={FilterOptions}
            />
          ) : null}
        </Stack>
      )}

      <Stack
        direction="row"
        alignItems={"center"}
        sx={{
          position: "relative",
          gap: "1rem",
        }}
      >
        <AddButton
          tooltipLabel={t(`add_new_auto_rule`)}
          label={t(`add_new_auto_rule`)}
          onClick={onClickAddNewRule}
          doNotListenKey={activeFilter}
          isNKey
        />
        {tab === "exclude" ? (
          <AddButton
            tooltipLabel={t("Add_Exclude_Name")}
            label={t("Add_Exclude_Name")}
            onClick={onClickExcludeNew}
            doNotListenKey={activeFilter}
            hideKey
          />
        ) : null}
        <FinbanInfoBulb url={"https://finban.io/documentation/rules/"} />
      </Stack>
    </Box>
  );
};

const ListView = forwardRef((props, _ref) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentWidth = useWidth();
  //state
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  //api
  const deleteRulesByIdApi = async (id) => {
    await APICall("delete", EndPoints.transactions_rules + `${id}/`).then(
      (response) => {
        if (response.status === 204 && response) {
          dispatch(setPopupStatus3(null));
          enqueueSnackbar(t("Rule_Deleted_Successfully"));
          resetRules();
        }
      }
    );
  };

  //functions
  const resetRules = () => {
    setTimeout(() => {
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === "rules",
      });
    }, 0);
  };

  const onClickDeleteRule = (e, item) => {
    e.stopPropagation();
    dispatch(
      setPopupStatus3({
        open: true,
        overlay_type: "delete",
        onConfirm: () => onOk(item.uuid),
        payload: {
          title: t("Attention"),
          message: `${t("Are_you_sure_delete_this")} ${t("Rule")}?`,
        },
      })
    );
  };

  const onOk = (id) => {
    deleteRulesByIdApi(id);
  };

  //render functions
  const ListItemView = ({ index, style }) => {
    const item = data[index];

    return (
      <ListItem
        style={style}
        divider
        sx={{
          py: "0.5rem",
          px: 0,
          height: "fit-content",
          borderBottom: `1px solid ${Color.tailwind.slate[200]}`,
          backgroundColor: Color.white,
          "& .actionBtn": {
            backgroundColor: Color.white,
            borderRadius: 1,
            width: "1.8rem",
            height: "1.8rem",
            marginLeft: "0.5rem",
            minWidth: "1.8rem",
            display: "none",
            border: `1px solid ${Color.tailwind.slate[300]}`,
            "&: hover": {
              backgroundColor: Color.tailwind.slate[100],
            },
            "& .actionBtnIcon": {
              backgroundColor: "transparent",
              color: Color.black,
              fontSize: "1.2rem",
              margin: 0,
            },
          },
          "&: hover": {
            backgroundColor: alpha(theme.palette.color.white, 0.5),

            "& .actionBtn": {
              display: "flex",
            },
          },
        }}
      >
        <Grid
          item
          xs={5}
          sx={{ px: "1rem", display: "flex", alignItems: "center" }}
        >
          <Typography
            variant="h6"
            sx={{
              fontSize: "0.95rem",
              display: "block",
              lineHeight: "1.9rem",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {item?.title}
          </Typography>
          <Button
            onClick={(e) => props?.onClickEditRule(e, item)}
            className={"actionBtn"}
          >
            <ModeEditRoundedIcon className={"actionBtnIcon"} />
          </Button>
          <Button
            className={"actionBtn"}
            onClick={(e) => onClickDeleteRule(e, item)}
          >
            <DeleteIcon className={"actionBtnIcon"} />
          </Button>
        </Grid>
        <Grid
          item
          xs={2}
          sx={{ px: "1rem", display: "flex", alignItems: "center" }}
        >
          <BsFillTagsFill
            style={{
              marginRight: "0.5rem",
              fontSize: "0.85rem",
              color: theme.palette.color.slate[400],
            }}
          />{" "}
          <CategoryChip categoryId={item?.category} width={"8rem"} />
        </Grid>
        <Grid item xs={2} sx={{ pl: "1rem" }}>
          <Typography
            sx={{
              textAlign: "left",
              fontSize: "0.8rem",
              fontWeight: 600,
              textTransform: "capitalize",
              height: "fit-content",
            }}
            variant="h6"
            component="div"
          >
            {type_options?.[item?.transaction_type]?.title
              ? t(type_options?.[item?.transaction_type]?.title)
              : ""}
            {` (${item?.type})`}
          </Typography>
        </Grid>

        <Grid item xs={1.5} sx={{ pl: "1rem" }}>
          <Typography
            variant="h6"
            sx={{
              fontSize: "0.95rem",
              display: "flex",
              alignItems: "center",
              lineHeight: "1.9rem",
            }}
          >
            {t(LevelOptions?.[item?.level]?.label)}
          </Typography>
        </Grid>
        <Grid
          item
          xs={1.5}
          sx={{ pl: "1rem", display: "flex", flexDirection: "column" }}
        >
          <Typography
            variant="caption"
            color={"color.description"}
            sx={{ opacity: 0.8, fontSize: "0.7rem", lineHeight: "0.8rem" }}
          >
            {`Created: ${formatDateToLocal(item?.creation_date, "dd MMMyy")}`}
          </Typography>
          <Typography
            variant="caption"
            color={"color.description"}
            sx={{ opacity: 0.8, fontSize: "0.7rem", lineHeight: "0.8rem" }}
          >
            {`Updated: ${formatDateToLocal(item?.last_modified_date, "dd MMMyy")}`}
          </Typography>
        </Grid>
      </ListItem>
    );
  };

  const innerElementType = forwardRef(({ style, ...rest }, ref) => (
    <div
      ref={ref}
      style={{
        ...style,
        height: `${parseFloat(style.height)}px`,
      }}
      {...rest}
    />
  ));

  const ITEM_SIZE = remToPx(currentWidth, 3.4);

  return (
    <Stack
      sx={{
        overflowY: "auto",
        width: "100%",
        maxHeight: `calc(100% - ${props?.isOverlay ? "8rem" : "20rem"})`,
        ...theme.thinScrollBar,
      }}
    >
      <Grid
        container
        sx={{
          ml: 0,
          mb: "1rem",
        }}
      >
        <Grid item xs={5} sx={{ px: "1rem" }}>
          <Typography
            sx={{
              textAlign: "left",
              fontSize: "0.8rem",
              fontWeight: 600,
              textTransform: "capitalize",
              height: "fit-content",
            }}
            variant="h6"
            component="div"
          >
            {t("Title")}
          </Typography>
        </Grid>
        <Grid item xs={2} sx={{ px: "1rem" }}>
          <Typography
            sx={{
              textAlign: "left",
              fontSize: "0.8rem",
              fontWeight: 600,
              textTransform: "capitalize",
              height: "fit-content",
            }}
            variant="h6"
            component="div"
          >
            {t("Action")}
          </Typography>
        </Grid>
        <Grid item xs={2} sx={{ px: "0.5rem" }}>
          <Typography
            sx={{
              textAlign: "left",
              fontSize: "0.8rem",
              fontWeight: 600,
              textTransform: "capitalize",
              height: "fit-content",
            }}
            variant="h6"
            component="div"
          >
            {t("Type")}
          </Typography>
        </Grid>
        <Grid item xs={1.5} sx={{ px: "0.5rem" }}>
          <Typography
            sx={{
              textAlign: "left",
              fontSize: "0.8rem",
              fontWeight: 600,
              textTransform: "capitalize",
              height: "fit-content",
            }}
            variant="h6"
            component="div"
          >
            {t("Level")}
          </Typography>
        </Grid>
        <Grid item xs={1.5} sx={{ px: "0.5rem" }}></Grid>
      </Grid>
      <ListViewLogicFunction
        setData={setData}
        tab={props?.tab}
        setLoading={setLoading}
      />
      {!isLoading && data?.length > 0 ? (
        <Grid
          container
          sx={{
            ml: 0,
            width: "100%",
            height: "100vh",
            "& .task-list": {
              ...theme.thinScrollBar,
            },
          }}
        >
          <AutoSizer>
            {throttle(
              ({ height, width }) => (
                <FixedSizeList
                  innerElementType={innerElementType}
                  itemCount={data?.length || 0}
                  itemSize={ITEM_SIZE}
                  height={height}
                  width={width}
                  className="task-list"
                >
                  {ListItemView}
                </FixedSizeList>
              ),
              100
            )}
          </AutoSizer>
        </Grid>
      ) : (
        <ComponentLoader
          loading={isLoading}
          placeHolderWidth={"35%"}
          key1={`setting_rules_no_data_tab_${props?.tab}_01`}
          key2={`setting_rules_no_data_tab_${props?.tab}_02`}
          sx={{ width: "70%", mx: "auto" }}
        />
      )}
    </Stack>
  );
});

const ListViewLogicFunction = ({ setData, setLoading, tab }) => {
  //redux
  const searchText = useSelector((state) => state.rulesSlice.searchText);
  const isAutoRulesEnabled = useSelector(
    (state) => state.rulesSlice.isAutoRulesEnabled
  );
  const selectedRulesCategory = useSelector(
    (state) => state.rulesSlice.selectedRulesCategory
  );
  //query
  const Rules = useQuery({
    queryKey: ["rules"],
    queryFn: ({ signal }) => {
      const result = getAllRules();
      if (result) {
        return result;
      }
    },
    backgroundFetch: true,
    refetchOnMount: true,
    enabled: !!tab,
    priority: 3,
  });

  useDebounce(
    () => {
      setLoading(Rules?.isFetching || searchText?.length > 0);
    },
    500,
    [Rules?.isFetching, searchText],
    true
  );

  useDebounce(
    () => {
      if (Rules?.data && !Rules?.isFetching) {
        const filterData = Rules?.data?.filter((item) => {
          return (
            item?.title?.toLowerCase()?.includes(searchText?.toLowerCase()) &&
            (isAutoRulesEnabled ? true : item?.type === "manual") &&
            (selectedRulesCategory?.length > 0
              ? selectedRulesCategory?.includes(item?.category)
              : true)
          );
        });
        setData(filterData || []);
        setLoading(false);
      }
    },
    500,
    [
      Rules?.isFetching,
      Rules?.data,
      selectedRulesCategory,
      searchText,
      isAutoRulesEnabled,
    ],
    true
  );
};

const ExcludeLogicFunction = ({ setData }) => {
  //redux
  const searchText = useSelector((state) => state.rulesSlice.searchText);
  const excluded_transaction_titles = useSelector(
    (state) => state.settingsSlice?.profile?.excluded_transaction_titles
  );

  useDebounce(
    () => {
      const filterData = excluded_transaction_titles?.filter((title) => {
        return title?.toLowerCase()?.includes(searchText?.toLowerCase());
      });
      setData(filterData || []);
    },
    500,
    [excluded_transaction_titles, searchText],
    true
  );
};

const Setting = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const auto_categorization_timer = useRef(null);
  const auto_categorization_level_timer = useRef(null);

  //redux
  const auto_categorization = useSelector(
    (state) => state.settingsSlice?.profile?.auto_categorization
  );
  const auto_categorization_level = useSelector(
    (state) => state.settingsSlice?.profile?.auto_categorization_level
  );

  //api
  const updateProfile = async (obj) => {
    await APICall("patch", EndPoints.profile, obj).then((response) => {
      if (response.status === 200 && response.data) {
      }
    });
  };

  //functions
  const handleChange1 = (event) => {
    dispatch(
      setProfile({ key: "auto_categorization", value: event.target.value })
    );
    clearTimeout(auto_categorization_timer.current);
    auto_categorization_timer.current = setTimeout(
      () => updateProfile({ auto_categorization: event.target.value }),
      1000
    );
  };

  const handleChange2 = (event) => {
    dispatch(
      setProfile({
        key: "auto_categorization_level",
        value: event.target.value,
      })
    );
    clearTimeout(auto_categorization_level_timer.current);
    auto_categorization_level_timer.current = setTimeout(
      () => updateProfile({ auto_categorization_level: event.target.value }),
      1000
    );
  };

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        borderRadius: 2,
        p: "2rem",
      }}
    >
      <FormControl
        sx={{ display: "flex", alignItems: "center", flexDirection: "row" }}
      >
        <FormLabel
          sx={{
            mb: "0.5rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            fontSize: "1.5rem",
            fontWeight: 600,
            width: "18rem",
            color: `${theme.palette.color.slate[700]} !important`,
          }}
        >
          {t("Auto Categorization")}
          <Typography Color="color.description" variant="caption">
            {t("Auto Categorization Description")}
          </Typography>
        </FormLabel>
        <RadioGroup
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={auto_categorization}
          onChange={handleChange1}
          sx={{
            ml: "1rem",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            "& .MuiButtonBase-root": {
              p: "0.25rem",
            },
          }}
        >
          {AutoCategorizationsOptions.map((item) => {
            return (
              <ItemView
                label={item.label}
                value={item.value}
                des={item.des}
                key={item.label}
              />
            );
          })}
        </RadioGroup>
      </FormControl>

      <Divider sx={{ my: "2rem" }} />

      <FormControl
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
        }}
      >
        <FormLabel
          sx={{
            mb: "0.5rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            fontSize: "1.5rem",
            fontWeight: 600,
            width: "18rem",
            color: `${theme.palette.color.slate[700]} !important`,
          }}
        >
          {t("Level")}
          <Typography Color="color.description" variant="caption">
            {t("Level Description")}
          </Typography>
        </FormLabel>
        <RadioGroup
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={auto_categorization_level}
          onChange={handleChange2}
          sx={{
            ml: "1rem",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            "& .MuiButtonBase-root": {
              p: "0.25rem",
            },
          }}
        >
          {LevelOptions.map((item) => {
            return (
              <ItemView
                label={item.label}
                value={item.value}
                des={item.des}
                key={item.label}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    </Card>
  );
};

const ExcludeView = forwardRef((props, _ref) => {
  let modalType = useRef("add");
  const theme = useTheme();
  let itemRow = useRef(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isSubscriptionValid] = useSubscriptions();

  //state
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(null);

  useImperativeHandle(_ref, () => ({
    addNewExcludeName: onClickExcludeNew,
  }));

  //api
  const updateProfile = async (obj) => {
    await APICall("patch", EndPoints.profile, obj).then((response) => {
      if (response.status === 200 && response.data) {
      }
    });
  };

  //functions
  const onClickExcludeNew = () => {
    if (isSubscriptionValid({ showMessage: true })) {
      modalType.current = "add";
      setOpen(true);
    }
  };

  const onClickEdit = (e, item) => {
    if (isSubscriptionValid({ showMessage: true })) {
      modalType.current = "edit";
      itemRow.current = item;
      setOpen(true);
    }
  };

  const onClickDelete = (e, item) => {
    const excluded_transaction_titles =
      store.getState()?.settingsSlice?.profile?.excluded_transaction_titles;
    const data = excluded_transaction_titles?.filter((i) => i !== item);
    dispatch(
      setProfile({
        key: "excluded_transaction_titles",
        value: data,
      })
    );
    updateProfile({ excluded_transaction_titles: data });
  };

  return (
    <>
      {open && (
        <ExcludeModal
          setOpen={setOpen}
          open={open}
          modalType={modalType.current}
          editItem={itemRow.current}
        />
      )}
      <Box
        sx={{
          display: "flex",
          mt: "0.5rem",
          width: "100%",
        }}
      >
        <span style={{ width: "3.5rem" }}></span>
        <Typography
          sx={{
            mb: "0.5rem",
            textAlign: "left",
            fontSize: "0.8rem",
            fontWeight: 600,
            textTransform: "capitalize",
            height: "fit-content",
            flex: 2,
          }}
          variant="h6"
          component="div"
        >
          {t("exclude_form_title")}
        </Typography>
      </Box>
      <ExcludeLogicFunction setData={setData} />
      {data?.length > 0 ? (
        <Box
          sx={{
            width: "100%",
            height: "80%",
            overflowY: "auto",
            ...thinScrollbarStyle,
          }}
        >
          {data?.map((item) => {
            return (
              <ListItem
                key={item}
                divider
                sx={{
                  py: "0.5rem",
                  borderBottom: `1px solid ${Color.tailwind.slate[200]}`,
                  backgroundColor: Color.white,

                  "&: hover": {
                    backgroundColor: alpha(theme.palette.color.white, 0.5),

                    "& .actionBtn": {
                      display: "flex",
                    },
                  },
                  "& .actionBtn": {
                    backgroundColor: Color.white,
                    borderRadius: 1,
                    width: "1.8rem",
                    height: "1.8rem",
                    marginLeft: "0.5rem",
                    minWidth: "1.8rem",
                    display: "none",
                    border: `1px solid ${Color.tailwind.slate[300]}`,
                    "&: hover": {
                      backgroundColor: Color.tailwind.slate[100],
                    },
                    "& .actionBtnIcon": {
                      backgroundColor: "transparent",
                      color: Color.black,
                      fontSize: "1.2rem",
                      margin: 0,
                    },
                  },
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: "0.95rem",
                    display: "flex",
                    alignItems: "center",
                    lineHeight: "1.9rem",
                    pl: "2rem",
                    flex: 2,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {item}
                  <Button
                    onClick={(e) => onClickEdit(e, item)}
                    className={"actionBtn"}
                  >
                    <ModeEditRoundedIcon className={"actionBtnIcon"} />
                  </Button>
                  <Button
                    className={"actionBtn"}
                    onClick={(e) => onClickDelete(e, item)}
                  >
                    <DeleteIcon className={"actionBtnIcon"} />
                  </Button>
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    flex: 1,
                    fontSize: "0.95rem",
                    display: "flex",
                    alignItems: "center",
                    lineHeight: "1.9rem",
                    pl: "2rem",
                  }}
                >
                  {t(LevelOptions?.[item?.level]?.label)}
                </Typography>
              </ListItem>
            );
          })}
        </Box>
      ) : (
        <span style={{ width: "100%" }}>
          <ComponentLoader
            loading={false}
            placeHolderWidth={"40%"}
            key1={"setting_rules_no_data_tab_exclude_01"}
            key2={"setting_rules_no_data_tab_exclude_02"}
            sx={{
              width: "100%",
              mx: "auto",
            }}
          />
        </span>
      )}
    </>
  );
});

const ExcludeModal = ({ open, setOpen, modalType, editItem = null }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isSubscriptionValid] = useSubscriptions();
  const isDataUpdated = useRef(false);

  //redux
  const excluded_transaction_titles = useSelector(
    (state) => state.settingsSlice?.profile?.excluded_transaction_titles
  );

  //state
  const [dataSetItem, setDataSetItem] = useState({
    title: "",
  });
  const [error, setError] = useState({
    title: "",
  });

  useEffect(() => {
    if (editItem && modalType === "edit" && open) {
      setDataSetItem({ title: editItem });
    }
  }, [editItem, modalType, open]);

  //api
  const updateProfile = async (obj) => {
    await APICall("patch", EndPoints.profile, obj).then((response) => {
      if (response.status === 200 && response.data) {
      }
    });
  };

  //functions
  const onAdd = () => {
    if (isSubscriptionValid({ showMessage: true })) {
      if (!dataSetItem?.title || dataSetItem?.title?.trim() === "") {
        setError((prev) => ({ ...prev, title: "title_empty_error" }));
        return;
      }
      const isAlreadyAdded = excluded_transaction_titles?.find(
        (i) => i === dataSetItem?.title
      );
      if (isAlreadyAdded) {
        setError((prev) => ({ ...prev, title: "title_already_added_error" }));
        return;
      }
      if (modalType === "add") {
        const data = [
          ...(excluded_transaction_titles || []),
          dataSetItem?.title,
        ];
        dispatch(
          setProfile({
            key: "excluded_transaction_titles",
            value: data,
          })
        );
        updateProfile({ excluded_transaction_titles: data });
      } else {
        const index = excluded_transaction_titles?.findIndex(
          (i) => i === editItem
        );
        if (index > -1) {
          let data = [...excluded_transaction_titles];
          data[index] = dataSetItem?.title;
          dispatch(
            setProfile({
              key: "excluded_transaction_titles",
              value: data,
            })
          );
          updateProfile({ excluded_transaction_titles: data });
        }
      }

      setOpen(false);
    }
  };

  const handleEditChange = (e) => {
    let text = e.target.value;
    if (error?.["title"]) setError({ ...error, title: "" });
    isDataUpdated.current = true;
    setDataSetItem({ ...dataSetItem, title: text });
    if (text !== "") {
    } else {
      if (error?.["title"]) setError({ ...error, title: "title_empty_error" });
    }
  };

  const handleClose = () => {
    isDataUpdated.current = false;
    setOpen(false);
  };

  return (
    <CustomModal
      open={open}
      onAdd={onAdd}
      onClose={handleClose}
      disableADD={modalType === "edit" && !isDataUpdated.current}
      heading={modalType === "edit" ? t("Edit Names") : t("Add Names")}
      textAdd={modalType === "edit" ? t("save") : t("Add new")}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "fit-content",
          backgroundColor: Color.BodyBG,
          width: "40rem",
          p: "2.5rem",
        }}
      >
        <TitleInput
          value={dataSetItem?.title || ""}
          onChange={handleEditChange}
          helperText={t(error?.["title"])}
          error={Boolean(error?.["title"])}
          hideTitle
          label={t("Title")}
          likeGoogle
          variant="filled"
        />
      </Box>
    </CustomModal>
  );
};

const ItemView = ({ label, des, value }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "15rem",
        marginRight: "2rem",
      }}
    >
      <FormControlLabel
        value={value}
        control={<Radio />}
        label={t(label)}
        sx={{
          ml: "-0.25rem",
          mr: 0,
        }}
      />
      <FormLabel
        sx={{
          color: `${theme.palette.color.slate[400]} !important`,
          fontSize: "0.7rem",
          overflow: "hidden",
          textAlign: "left",
        }}
      >
        <Translate i18nkey={des} />
      </FormLabel>
    </div>
  );
};

const CategoryFilter = ({ isFilterOpen, filterKey, removeFilter }) => {
  const dispatch = useDispatch();

  //redux
  const selectedRulesCategory = useSelector(
    (state) => state.rulesSlice?.selectedRulesCategory
  );

  //functions
  const onChangeValue = (value) => {
    dispatch(setSelectedRulesCategory(value));
  };

  const onClickReset = () => {
    onChangeValue([]);
    removeFilter(filterKey);
  };

  return (
    <CategoryFilterView
      selectedIds={selectedRulesCategory}
      categoryType={3}
      onClickReset={onClickReset}
      onChangeValue={onChangeValue}
      isFilterOpen={isFilterOpen}
      hideUncategorize
    />
  );
};

const ResetView = ({
  color = "slate",
  filtered,
  setFiltered,
  setIsIncludedInFilterList,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();

  const activeFilter = filtered?.length > 0;
  if (!activeFilter) {
    return null;
  }

  //functions
  const onClickReset = () => {
    setIsIncludedInFilterList([]);
    setFiltered([]);
    dispatch(setSelectedRulesCategory([]));
  };

  return (
    <Button
      onClick={onClickReset}
      sx={{
        borderRadius: 0,
        border: "none",
        textTransform: "initial",
        color: theme.palette.color[color][500],
        fontWeight: 600,
        ml: "1.5rem",
        mr: "0.5rem",
        lineHeight: "0.875rem",
        "&:hover": {
          backgroundColor: theme.palette.color[color][100],
          textDecoration: "underline",
        },
      }}
    >
      {t("reset_filters")}
    </Button>
  );
};
