import {
  toggleButtonGroupClasses,
} from "@mui/material/ToggleButtonGroup";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { Box, Tooltip, useTheme } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import * as React from "react";

interface CustomToggleButtonGroupProps {
  isHaveIcon?: boolean;
  theme?: any;
}
const StyledToggleButtonGroup = styled(ToggleButtonGroup, {
  shouldForwardProp: (prop) => prop !== "isHaveIcon",
})<CustomToggleButtonGroupProps>(({ theme, isHaveIcon }) => ({
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    padding: isHaveIcon ? "0.35rem" : "0.5rem",
    paddingInline: "0.8rem",
    margin: "0.3rem",
    border: 0,
    fontSize: isHaveIcon ? "1rem" : "0.7rem",
    lineHeight: isHaveIcon ? "1rem" : "0.6rem",
    borderRadius: theme.borderRadius.borderRadiusMD,
    color: theme?.palette?.color?.slate?.[800],
    fontWeight: theme.typography?.fontWeightMediumBold,

    [`&.${toggleButtonGroupClasses.disabled}`]: {
      border: 0,
    },
    "&.Mui-selected": {
      backgroundColor: theme.palette?.color?.white,
      color: theme?.palette?.primary?.main,
    },
  },
  [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]:
    {
      marginLeft: -1,
      borderLeft: "1px solid transparent",
    },
}));
interface ThemeToggleProps {
  options: any;
  value: any;
  tooltip: string;
  sx: any;
  onChange: (
    event: React.MouseEvent<HTMLElement>,
    newFormats: string[]
  ) => void;
}

export default function ThemeToggle({
  options,
  value,
  onChange,
  tooltip,
  sx,
}: ThemeToggleProps) {
  const { t } = useTranslation();
  const theme: any = useTheme();
  const isHaveIcon = !!options?.[0]?.icon;
  return (
    <Tooltip title={tooltip ? t(tooltip) : ""} arrow placement="top">
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          backgroundColor: theme.palette?.color?.slate?.[100],
          borderRadius: theme.borderRadius.main,
          ...sx,
        }}
      >
        <StyledToggleButtonGroup
          size="small"
          value={value}
          exclusive
          onChange={onChange}
          aria-label="text alignment"
          isHaveIcon={isHaveIcon}
        >
          {options?.map((item: any) => (
            <Tooltip
              key={item?.value}
              title={item?.tooltip ? t(item?.tooltip) : ""}
              arrow
              placement="top"
            >
              <ToggleButton
                key={item?.value}
                value={item?.value}
                aria-label="left aligned"
                sx={{
                  width: "max-content",
                  fontSize: item?.icon ? "0.9rem" : "0.6rem",
                  lineHeight: item?.icon ? "1rem !important" : "0.8rem !important",
                }}
              >
                {item?.icon}
                {item?.label ? t(item?.label) : ""}
              </ToggleButton>
            </Tooltip>
          ))}
        </StyledToggleButtonGroup>
      </Box>
    </Tooltip>
  );
}
