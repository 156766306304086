import { useCallback, useState, useMemo, useRef } from "react";
import { Box, Button, Divider, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { IoPeopleCircle } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { TbCircles } from "react-icons/tb";

import {
  setContactSearchText,
  setContactStates,
  setContactTypes,
  resetCustomer,
} from "../../../../store/slices/staff";
import SearchFilterView from "../../../../components/Overlay/ThemeFilter/Component/SearchFilterView";
import AddFilterPopOver from "../../../../components/Overlay/ThemeFilter/AddFilterPopover";
import AddFilterView from "../../../../components/Overlay/ThemeFilter/AddFilterView";
import FinbanInfoBulb from "../../../../components/FInbanInfo/FinbanInfoBulb";
import useDebounce from "../../../../hooks/3-useDebounce/useDebounce";
import ThemeFilter from "../../../../components/Overlay/ThemeFilter";

const ClientHeader = ({ hideState = false, width, tab }) => {
  const dispatch = useDispatch();
  const isFilterOpen = useRef(false);

  //redux state
  const searchText = useSelector(
    (state) => state.staffSlice?.["contactSearchText"]
  );
  const contactStates = useSelector(
    (state) => state.staffSlice?.["contactStates"]
  );
  const contactTypes = useSelector(
    (state) => state.staffSlice?.["contactTypes"]
  );

  //state
  const [anchorEl, setAnchorEl] = useState(null);
  const [filtered, setFiltered] = useState([]);
  const [isIncludedInFilterList, setIsIncludedInFilterList] = useState([]);

  //function
  const onClose = (_selectedIds) => {
    isFilterOpen.current = false;
    setIsIncludedInFilterList(_selectedIds);
    setAnchorEl(null);
    let remove = {};

    if (
      !hideState &&
      contactStates?.length > 0 &&
      !_selectedIds?.includes("state")
    ) {
      remove.contactStates = [];
    }
    if (contactTypes?.length > 0 && !_selectedIds?.includes("type")) {
      remove.contactTypes = [];
    }

    if (Object?.keys(remove)?.length > 0) {
      updateFilterObj(remove);
    }
  };

  const updateFilterObj = (obj) => {
    dispatch(resetCustomer(obj));
  };

  const onClickFilter = useCallback((e) => {
    isFilterOpen.current = true;
    setAnchorEl(e.currentTarget);
  }, []);

  const removeFilter = useCallback((key) => {
    setIsIncludedInFilterList((prev) => prev?.filter((item) => item !== key));
  }, []);

  useDebounce(
    () => {
      if (!isFilterOpen.current) {
        let array = [];
        let included_array = [...isIncludedInFilterList];

        if (searchText?.length > 0) {
          array.push("search");
          included_array.push("search");
        }
        if (contactStates?.length > 0 && !hideState) {
          array.push("state");
          included_array.push("state");
        }
        if (contactTypes?.length > 0) {
          array.push("type");
          included_array.push("type");
        }

        included_array = Array.from(new Set(included_array));
        setFiltered(array);
        setIsIncludedInFilterList(included_array);
      }
    },
    500,
    [searchText, contactStates, hideState, tab],
    true
  );

  const FilterOptions = useMemo(
    () => [
      {
        uuid: "state",
        title: "add_state_filter",
        icon: <TbCircles />,
        hide: hideState,
      },
      {
        uuid: "type",
        title: "add_contact_type_filter",
        icon: <TbCircles />,
      },
    ],
    [hideState]
  );

  //render functions
  const FoundedFilter = useMemo(() => {
    return FilterOptions?.filter((item) => !item?.hide);
  }, [FilterOptions]);

  const DetailsView = useMemo(() => {
    return (
      <div
        style={{
          display: "inline-flex",
          alignItems: "start",
        }}
      >
        <SearchView />
        <Divider
          orientation="vertical"
          flexItem
          sx={{
            height: "1.2rem",
            opacity: "0.6",
            alignSelf: "center",
            ml: "0.75rem",
            visibility: FoundedFilter?.length === 0 ? "hidden" : "visible",
          }}
        />
        <Box
          display={FoundedFilter?.length === 0 ? "none" : "inline-flex"}
          alignItems="center"
          justifyContent={"flex-start"}
          width="fit-content"
          sx={{
            minWidth: "25rem",
            transition: `all 0.3s ease-in-out`,
            zIndex: 2,
            gap: "0.5rem",
            flexWrap: "wrap",
          }}
        >
          {isIncludedInFilterList?.length > 0 ? null : (
            <AddFilterView
              isIncludedInFilterList={isIncludedInFilterList}
              onClick={onClickFilter}
            />
          )}

          {isIncludedInFilterList?.map((item) => {
            if (item === "state") {
              return (
                <StateFilter
                  key="state"
                  isFilterOpen={isFilterOpen}
                  filterKey="state"
                  removeFilter={removeFilter}
                />
              );
            }
            if (item === "type") {
              return (
                <ContactTypeFilter
                  key="type"
                  isFilterOpen={isFilterOpen}
                  filterKey="type"
                  removeFilter={removeFilter}
                />
              );
            }
            return null;
          })}

          {isIncludedInFilterList?.length > 0 ? (
            <AddFilterView
              isIncludedInFilterList={isIncludedInFilterList}
              onClick={onClickFilter}
            />
          ) : null}
          <ResetView
            filtered={filtered}
            setIsIncludedInFilterList={setIsIncludedInFilterList}
          />
        </Box>
      </div>
    );
  }, [
    FoundedFilter?.length,
    isIncludedInFilterList,
    onClickFilter,
    filtered,
    removeFilter,
  ]);

  return (
    <Box
      sx={{
        width,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      {DetailsView}
      {!!anchorEl ? (
        <AddFilterPopOver
          anchorEl={anchorEl}
          onClose={onClose}
          selectedIds={isIncludedInFilterList}
          options={FilterOptions}
        />
      ) : null}
      <FinbanInfoBulb
        url={"https://finban.io/documentation/list/listen-ansicht-ueberblick/"}
      />
    </Box>
  );
};

export default ClientHeader;

const SearchView = ({ color = "slate" }) => {
  const dispatch = useDispatch();

  //redux
  const searchText = useSelector(
    (state) => state.staffSlice?.["contactSearchText"]
  );

  //functions
  const updateFilterObj = (text) => {
    dispatch(setContactSearchText(text));
  };

  const onClickClear = () => {
    updateFilterObj("");
  };

  const onChangeSearch = (e) => {
    updateFilterObj(e.target.value);
  };

  return (
    <SearchFilterView
      active={searchText?.length > 0}
      value={searchText}
      color={color}
      onClickClear={onClickClear}
      onChange={onChangeSearch}
    />
  );
};

const StateFilter = ({ isFilterOpen, filterKey, removeFilter }) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.globalSlice?.state);
  const contactStates = useSelector(
    (state) => state.staffSlice?.["contactStates"]
  );

  const updateFilterObj = (value) => {
    dispatch(setContactStates(value));
  };

  const onClickReset = () => {
    updateFilterObj([]);
    removeFilter(filterKey);
  };

  return (
    <ThemeFilter
      heading="state_filter"
      tooltip="tooltip_state_filter"
      icon={<TbCircles style={{ fontSize: "1rem", marginTop: "1px" }} />}
      options={state}
      translate
      selectedIds={contactStates}
      isActive={contactStates?.length > 0}
      onChangeValue={updateFilterObj}
      onClickReset={onClickReset}
      onClear={onClickReset}
      isFilterOpen={isFilterOpen}
      maxWidth="fit-content"
    />
  );
};

const ContactOptions = [
  {
    uuid: 1,
    title: "Customer",
    color: "green",
  },
  {
    uuid: 2,
    title: "Supplier",
    color: "red",
  },
];

const ContactTypeFilter = ({ isFilterOpen, filterKey, removeFilter }) => {
  const dispatch = useDispatch();

  const contactTypes = useSelector(
    (state) => state.staffSlice?.["contactTypes"]
  );

  //functions
  const updateFilterObj = (value) => {
    dispatch(setContactTypes(value));
  };

  const onClickReset = () => {
    updateFilterObj([]);
    removeFilter(filterKey);
  };

  return (
    <ThemeFilter
      heading="Contact Type Filter"
      tooltip="tooltip_contact_type_filter"
      icon={<IoPeopleCircle style={{ fontSize: "1rem", marginTop: "1px" }} />}
      options={ContactOptions}
      translate
      selectedIds={contactTypes}
      isActive={contactTypes?.length > 0}
      onChangeValue={updateFilterObj}
      onClickReset={onClickReset}
      onClear={onClickReset}
      isFilterOpen={isFilterOpen}
      maxWidth="fit-content"
    />
  );
};

const ResetView = ({
  color = "slate",
  filtered,
  setIsIncludedInFilterList,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();

  const activeFilter = filtered?.length > 0;
  if (!activeFilter) {
    return null;
  }

  //functions
  const onClickReset = () => {
    setIsIncludedInFilterList([]);
    dispatch(resetCustomer());
  };

  return (
    <Button
      onClick={onClickReset}
      sx={{
        borderRadius: 0,
        border: "none",
        textTransform: "initial",
        color: theme.palette.color[color][500],
        fontWeight: 600,
        ml: "1.5rem",
        mr: "0.5rem",
        lineHeight: "0.875rem",
        "&:hover": {
          backgroundColor: theme.palette.color[color][100],
          textDecoration: "underline",
        },
      }}
    >
      {t("reset_filters")}
    </Button>
  );
};
