import { Box, Divider, Stack, Typography, useTheme } from "@mui/material";
import { MdOutlineFileUpload } from "react-icons/md";
import { IoCloudUpload } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";
import React, { useCallback } from "react";
import { read } from "xlsx";

import OverlayFooter from "../../../../../components/Overlay/OverlayFooter";
import AddButton from "../../../../../components/AddButton";

const UploadStep = ({ state, onContinue, onCancel }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  //functions
  const readFileAsync = useCallback((acceptedFiles) => {
    acceptedFiles?.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        const workbook = read(reader.result, {
          cellDates: true,
          dense: true,
          codepage: 65001,
        });

        onContinue(workbook, file);
      };
      reader.readAsArrayBuffer(file);
    });
  }, []);

  const onSelectFile = async (event) => {
    readFileAsync([event.target.files?.[0]]);
  };

  const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
    useDropzone({
      accept: {
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
          ".xlsx",
        ],
      },
      onDrop: readFileAsync,
    });

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        {...getRootProps()}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "70%",
          height: "12rem",
          border: `1px dashed ${isDragActive ? theme.palette.primary.main : theme.palette.color.slate[300]}`,
          borderRadius: theme.borderRadius.main,
          backgroundColor: isDragActive
            ? theme.palette.color.white
            : theme.palette.color.slate[50],
          cursor: "pointer",

          "&:hover": {
            border: `1px dashed ${theme.palette.primary.main}`,
          },
        }}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <Typography variant="body2" color={theme.palette.color.slate[400]}>
            {t("Drop here")}
          </Typography>
        ) : (
          <Stack alignItems="center" justifyContent="center" spacing={2}>
            <IoCloudUpload
              fontSize={"3rem"}
              color={theme.palette.primary.main}
            />
            <Typography variant="body2" color={theme.palette.color.slate[400]}>
              {t("Drop your .xlsx file into this area to upload")}
            </Typography>
          </Stack>
        )}
      </Box>
      <Divider
        textAlign="center"
        variant="middle"
        sx={{
          my: "1.5rem",
          width: "50%",
          visibility: isDragActive ? "hidden" : "visible",
          fontSize: "0.85rem",
          color: theme.palette.color.slate[400],
          "&:before, &:after": {
            borderWidth: 1,
            borderStyle: "dashed",
            opacity: 0.5,
          },
        }}
      >
        {t("Or")}
      </Divider>
      <label htmlFor="contained-button-file">
        <input
          accept=".xlsx"
          id="contained-button-file"
          type="file"
          onChange={onSelectFile}
          style={{ display: "none" }}
        />
        <AddButton
          component="span"
          tooltipLabel=""
          label={t("Upload Excel File")}
          icon={<MdOutlineFileUpload fontSize={"1.25rem"} />}
          isSecondary
          hideKey
          sx={{
            visibility: isDragActive ? "hidden" : "visible",
            width: "fit-content",
          }}
        />
      </label>
      <Stack
        sx={{
          width: "100%",
          position: "absolute",
          bottom: "0",
          left: "0",
          right: "0",
          px: "1.75rem",
          py: "1rem",
          backgroundColor: theme.palette.color.slate[100],
        }}
      >
        <OverlayFooter
          hideAddAction
          onCancel={onCancel}
          addIcon={null}
          cancelIcon={null}
          addButtonSx={{
            py: "0.75rem",
          }}
          cancelButtonSx={{
            py: "0.75rem",
          }}
        />
      </Stack>
    </div>
  );
};

export default UploadStep;
