import open_booked_planned_insights from "../Helper/Images/open_booked_planned_insights.png";
import income_statement_placeholder from "../Helper/Images/income_statement_placeholder.png";
import latest_invoice_transactions from "../Helper/Images/latest-invoice-transactions.png";
import animation_double_click_cell from "../Helper/Images/animation_double-click-cell.gif";
import success_report_placeholder from "../Helper/Images/success_report_placeholder.png";
import setup_offline_organisation from "../Helper/Images/setup_offline_organisation.png";
import bank_integrations_insights from "../Helper/Images/bank_integrations_insights.png";
import BuchhaltungsButlerTransparentLogo from "../Helper/Images/BuchhaltungsButler.png";
import setup_online_bank_account from "../Helper/Images/setup_online_bank_account.png";
import latest_offer_transactions from "../Helper/Images/latest-offer-transactions.png";
import TinkTransparentLogo3 from "../Helper/Images/europe-banking-provider_small.png";
import latest_bank_transactions from "../Helper/Images/latest-bank-transactions.png";
import latest_lead_transactions from "../Helper/Images/latest-lead-transactions.png";
import invoice_status_over_time from "../Helper/Images/invoice-status-over-time.png";
import single_card_explanation from "../Helper/Images/single_card_explanation.png";
import first_login_page_2_ref from "../Helper/Images/first_login_page_2_ref.png";
import dataset_lists_insights from "../Helper/Images/dataset_lists_insights.png";
import cost_ratio_placeholder from "../Helper/Images/cost_ratio_placeholder.png";
import group_card_explanation from "../Helper/Images/group_card_explanation.png";
import ActiveCampaignTransparentLogo from "../Helper/Images/activecampaingn.png";
import offer_status_over_time from "../Helper/Images/offer-status-over-time.png";
import TinkTransparentLogo2 from "../Helper/Images/europe-banking-provider.png";
import first_login_page_2_Bg from "../Helper/Images/first_login_page_2_Bg.png";
import quick_start_insight_4 from "../Helper/Images/quick_start_insight_4.png";
import quick_start_insight_5 from "../Helper/Images/quick_start_insight_5.png";
import quick_start_insight_6 from "../Helper/Images/quick_start_insight_6.png";
import quick_start_insight_7 from "../Helper/Images/quick_start_insight_7.png";
import quick_start_insight_8 from "../Helper/Images/quick_start_insight_8.png";
import LexOfficeTransparentLogo from "../Helper/Images/lexoffice-Logo-RGB.png";
import latest_updates_banner from "../Helper/Images/latest_updates_banner.png";
import lead_status_over_time from "../Helper/Images/lead-status-over-time.png";
import employees_placeholder from "../Helper/Images/employees_placeholder.png";
import getMyInvoicesTransparentLogo from "../Helper/Images/getmyinvoices.png";
import quick_start_insights from "../Helper/Images/quick_start_insights.png";
import googleSheetsTransparentLogo from "../Helper/Images/google-sheets.png";
import forcast_placeholder from "../Helper/Images/forcast_placeholder.png";
import over_budget_expense from "../Helper/Images/over-budget-expense.png";
import reports_placeholder from "../Helper/Images/reports_placeholder.png";
import ZOHOBookingTransparentLogo from "../Helper/Images/zohobooking.png";
import ZOHOInvoiceTransparentLogo from "../Helper/Images/zohoinvoice.png";
import GoogleDriveTransparentLogo from "../Helper/Images/googledrive.png";
import kanban_daily_chart from "../Helper/Images/kanban_daily_chart.png";
import cost_unit_insights from "../Helper/Images/cost_unit_insights.png";
import burn_rate_12_month from "../Helper/Images/burn-rate-12-month.png";
import over_budget_income from "../Helper/Images/over-budget-income.png";
import QuickBooksTransparentLogo from "../Helper/Images/quickbooks.png";
import SalesforceTransparentLogo from "../Helper/Images/salesforce.png";
import onboarding_avatar from "../Helper/Images/onboarding_avatar.png";
import dashboard_insight from "../Helper/Images/dashboard_insight.png";
import liquidity_insight from "../Helper/Images/liquidity_insight.png";
import PaperWorkTransparentLogo from "../Helper/Images/papierkram.png";
import expenses_12_month from "../Helper/Images/expenses-12-month.png";
import empty_placeholder from "../Helper/Images/empty_placeholder.png";
import finban_logo_small from "../Helper/Images/finban_logo_small.png";
import PipedriveTransparentLogo from "../Helper/Images/pipedrive.png";
import HighLevelTransparentLogo from "../Helper/Images/highlevel.png";
import InsightlyTransparentLogo from "../Helper/Images/insightly.png";
import client_portfolio from "../Helper/Images/client_portfolio.png";
import chart_and_states from "../Helper/Images/chart_and_states.png";
import categories_group from "../Helper/Images/categories_group.png";
import cash_development from "../Helper/Images/cash-development.png";
import finban_logo_full from "../Helper/Images/finban_logo_full.png";
import BillomatTransparentLogo from "../Helper/Images/billomat.png";
import NetsuiteTransparentLogo from "../Helper/Images/netsuite.png";
import easybillTransparentLogo from "../Helper/Images/easybill.png";
import FastbillTransparentLogo from "../Helper/Images/fastbill.png";
import SpendeskTransparentLogo from "../Helper/Images/spendesk.png";
import PersonioTransparentLogo from "../Helper/Images/personio.png";
import BullhornTransparentLogo from "../Helper/Images/bullhorn.png";
import OutreachTransparentLogo from "../Helper/Images/outreach.png";
import AffinityTransparentLogo from "../Helper/Images/spendesk.png";
import incomeStatement from "../Helper/Images/incomeStatement.png";
import sheet_to_kanban from "../Helper/Images/sheet_to_kanban.png";
import Smart_Sequences from "../Helper/Images/Smart_Sequences.png";
import betriebskapital from "../Helper/Images/betriebskapital.png";
import profit_12_month from "../Helper/Images/profit-12-month.png";
import income_12_month from "../Helper/Images/income-12-month.png";
import SevDeskTransparentLogo from "../Helper/Images/sevdesk.png";
import billbeeTransparentLogo from "../Helper/Images/billbee.png";
import ShopifyTransparentLogo from "../Helper/Images/shopify.png";
import WeclappTransparentLogo from "../Helper/Images/weclapp.png";
import OrgamaxTransparentLogo from "../Helper/Images/orgamax.png";
import XentralTransparentLogo from "../Helper/Images/xentral.png";
import WorkdayTransparentLogo from "../Helper/Images/workday.png";
import HubSpotTransparentLogo from "../Helper/Images/hubspot.png";
import ZOHOCRMTransparentLogo from "../Helper/Images/zohocrm.png";
import default_avatar from "../Helper/Images/default_avatar.png";
import table_insights from "../Helper/Images/table_insights.png";
import states_insight from "../Helper/Images/states_insight.png";
import MocoTransparentLogo from "../Helper/Images/logo_moco.png";
import ShareTransparentLogo from "../Helper/Images/personio.png";
import mailing_banner from "../Helper/Images/mailing-banner.png";
import chart_widget_1 from "../Helper/Images/chart_widget_1.png";
import authPageImage from "../Helper/Images/authPageImage.jpeg";
import StripeTransparentLogo from "../Helper/Images/stripe.png";
import PaypalTransparentLogo from "../Helper/Images/paypal.png";
import ZapierTransparentLogo from "../Helper/Images/zapier.png";
import PliantTransparentLogo from "../Helper/Images/pliant.png";
import AuthPageImage from "../Helper/Images/AuthPageImage.png";
import kanban_normal from "../Helper/Images/kanban_normal.png";
import card_insights from "../Helper/Images/card_insights.png";
import mollieTransparentLogo from "../Helper/Images/molli.png";
import profitability from "../Helper/Images/profitability.png";
import capterra_logo from "../Helper/Images/capterra_logo.png";
import ExcelTransparentLogo from "../Helper/Images/excel.png";
import mBankTransparentLogo from "../Helper/Images/mbank.png";
import AttioTransparentLogo from "../Helper/Images/attio.png";
import CloseTransparentLogo from "../Helper/Images/close.png";
import hello_avatar from "../Helper/Images/hello_avatar.png";
import ruls_insight from "../Helper/Images/ruls_insight.png";
import group_single from "../Helper/Images/group_single.png";
import banner_empty from "../Helper/Images/banner_empty.png";
import SageTransparentLogo from "../Helper/Images/sage.png";
import XeroTransparentLogo from "../Helper/Images/xero.png";
import MakeTransparentLogo from "../Helper/Images/Make.png";
import WaveTransparentLogo from "../Helper/Images/wave.png";
import MossTransparentLogo from "../Helper/Images/moss.png";
import HibobTransparentLogo from "../Helper/Images/bob.png";
import PleoTransparentLogo from "../Helper/Images/pleo.png";
import logo_finban from "../Helper/Images/logo_finban.webp";
import news_avatar from "../Helper/Images/news_avatar.png";
import register_bg from "../Helper/Images/register_bg.png";
import staff_chart from "../Helper/Images/staff_chart.png";
import Kpi_insight from "../Helper/Images/Kpi_insight.png";
import group_state from "../Helper/Images/group_state.png";
import onboarding from "../Helper/Images/onboarding.png";
import ds_insight from "../Helper/Images/ds_insight.png";
import defaultRobot from "../Helper/Images/default.png";
import bgImage1 from "../Helper/Images/bgImage1.jpeg";
import bgImage2 from "../Helper/Images/bgImage2.jpeg";
import bgImage3 from "../Helper/Images/bgImage3.jpeg";
import bgImage4 from "../Helper/Images/bgImage4.jpeg";
import Level_up from "../Helper/Images/Level_up.png";
import progress from "../Helper/Images/progress.svg";
import image1 from "../Helper/Images/image1.jpg";
import signup from "../Helper/Images/signup.jpg";
import search from "../Helper/Images/search.png";
import hello from "../Helper/Images/hello.png";
import error from "../Helper/Images/error.png";
import image from "../Helper/Images/image.png";
import staff from "../Helper/Images/staff.png";
import news from "../Helper/Images/news.png";
import cost from "../Helper/Images/cost.png";
import eur from "../Helper/Images/eur.png";

const Images = {
  Svg: {
    AppLogoTheme:
      "https://finban.io/wp-content/uploads/2022/08/finban-logo_v2-1.svg",
  },
  animated: {
    animation_double_click_cell,
  },
  transparent: {
    TinkTransparentLogo3: TinkTransparentLogo3,
    TinkTransparentLogo2: TinkTransparentLogo2,
    LexOfficeTransparentLogo: LexOfficeTransparentLogo,
    SevDeskTransparentLogo: SevDeskTransparentLogo,
    MocoTransparentLogo: MocoTransparentLogo,
    ExcelTransparentLogo: ExcelTransparentLogo,
    mBankTransparentLogo: mBankTransparentLogo,
    googleSheetsTransparentLogo: googleSheetsTransparentLogo,
    getMyInvoicesTransparentLogo: getMyInvoicesTransparentLogo,
    BuchhaltungsButlerTransparentLogo: BuchhaltungsButlerTransparentLogo,
    billbeeTransparentLogo: billbeeTransparentLogo,
    easybillTransparentLogo: easybillTransparentLogo,
    FastbillTransparentLogo: FastbillTransparentLogo,
    BillomatTransparentLogo: BillomatTransparentLogo,
    StripeTransparentLogo: StripeTransparentLogo,
    ShopifyTransparentLogo: ShopifyTransparentLogo,
    mollieTransparentLogo: mollieTransparentLogo,

    PleoTransparentLogo: PleoTransparentLogo,
    PliantTransparentLogo: PliantTransparentLogo,
    MossTransparentLogo: MossTransparentLogo,
    SpendeskTransparentLogo: SpendeskTransparentLogo,

    PipedriveTransparentLogo: PipedriveTransparentLogo,
    HubSpotTransparentLogo: HubSpotTransparentLogo,
    AttioTransparentLogo: AttioTransparentLogo,
    ActiveCampaignTransparentLogo: ActiveCampaignTransparentLogo,
    CloseTransparentLogo: CloseTransparentLogo,
    HighLevelTransparentLogo: HighLevelTransparentLogo,
    InsightlyTransparentLogo: InsightlyTransparentLogo,
    SalesforceTransparentLogo: SalesforceTransparentLogo,
    BullhornTransparentLogo: BullhornTransparentLogo,
    OutreachTransparentLogo: OutreachTransparentLogo,
    ZOHOCRMTransparentLogo: ZOHOCRMTransparentLogo,
    AffinityTransparentLogo: AffinityTransparentLogo,

    PersonioTransparentLogo: PersonioTransparentLogo,
    ShareTransparentLogo: ShareTransparentLogo,
    HibobTransparentLogo: HibobTransparentLogo,
    WorkdayTransparentLogo: WorkdayTransparentLogo,

    GoogleDriveTransparentLogo: GoogleDriveTransparentLogo,
    ZapierTransparentLogo: ZapierTransparentLogo,

    XentralTransparentLogo: XentralTransparentLogo,
    WeclappTransparentLogo: WeclappTransparentLogo,
    WaveTransparentLogo: WaveTransparentLogo,
    SageTransparentLogo: SageTransparentLogo,
    PaperWorkTransparentLogo: PaperWorkTransparentLogo,
    NetsuiteTransparentLogo: NetsuiteTransparentLogo,
    XeroTransparentLogo: XeroTransparentLogo,
    QuickBooksTransparentLogo: QuickBooksTransparentLogo,
    ZOHOBookingTransparentLogo: ZOHOBookingTransparentLogo,
    ZOHOInvoiceTransparentLogo: ZOHOInvoiceTransparentLogo,
    OrgamaxTransparentLogo: OrgamaxTransparentLogo,
    MakeTransparentLogo: MakeTransparentLogo,
    PaypalTransparentLogo: PaypalTransparentLogo,
  },
  mailing_banner,
  latest_updates_banner,
  default_avatar,
  news_avatar,
  onboarding_avatar,
  hello_avatar,
  authPageImage,
  AuthPageImage,
  image1,
  bgImage1,
  bgImage2,
  bgImage3,
  bgImage4,
  signup,
  onboarding,
  search,
  news,
  hello,
  error,
  defaultRobot,
  register_bg,
  first_login_page_2_Bg,
  kanban_normal,
  image,
  open_booked_planned_insights,
  quick_start_insights,
  quick_start_insight_4,
  quick_start_insight_5,
  quick_start_insight_6,
  quick_start_insight_7,
  quick_start_insight_8,
  dashboard_insight,
  table_insights,
  bank_integrations_insights,
  incomeStatement,
  income_statement_placeholder,
  client_portfolio,
  staff_chart,
  kanban_daily_chart,
  first_login_page_2_ref,
  staff,
  dataset_lists_insights,
  ds_insight,
  ruls_insight,
  states_insight,
  cost_unit_insights,
  liquidity_insight,
  Kpi_insight,
  forcast_placeholder,
  success_report_placeholder,
  cost_ratio_placeholder,
  setup_offline_organisation,
  setup_online_bank_account,
  single_card_explanation,
  group_card_explanation,
  group_single,
  chart_and_states,
  categories_group,
  card_insights,
  group_state,
  sheet_to_kanban,
  Smart_Sequences,
  betriebskapital,
  Level_up,
  progress,
  chart_widget_1,
  banner_empty,
  "burn-rate-12-month": burn_rate_12_month,
  "profit-12-month": profit_12_month,
  "income-12-month": income_12_month,
  "expenses-12-month": expenses_12_month,
  "cash-development": cash_development,
  "invoice-status-over-time": invoice_status_over_time,
  "lead-status-over-time": lead_status_over_time,
  "offer-status-over-time": offer_status_over_time,
  "over-budget-income": over_budget_income,
  "over-budget-expense": over_budget_expense,
  "latest-bank-transactions": latest_bank_transactions,
  "latest-invoice-transactions": latest_invoice_transactions,
  "latest-lead-transactions": latest_lead_transactions,
  "latest-offer-transactions": latest_offer_transactions,
  "income-pie": cost,
  "expense-pie": cost,
  cost,
  profitability,
  eur,
  empty_placeholder,
  employees_placeholder,
  reports_placeholder,
  finban_logo_small,
  finban_logo_full,
  capterra_logo,
  logo_finban,
};
export default Images;
