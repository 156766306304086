import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useTheme,
  Table,
} from "@mui/material";
import { MdArrowForward } from "react-icons/md";
import { useTranslation } from "react-i18next";
import React, { useEffect } from "react";

import DropDown from "../../../components/Overlay/DropDown";
import { cloneDeep } from "../../../Helper/data";
import { Constant } from "../../../Helper";

const HeaderMappingView = ({
  headersFromSheet,
  headersSetByUser,
  dropdownValues,
  setDropdownValues,
  errors,
  setErrors,
  fileColumnHeader = "gsheet_header_mapping_file_column",
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  useEffect(() => {
    if (headersSetByUser) {
      setDropdownValues(headersSetByUser);
    } else if (headersFromSheet) {
      const initialDropdownStateClone = cloneDeep(Constant.FinbanMappingFields);
      headersFromSheet.forEach((header) => {
        const key = header.replace(/\s+/g, "_").toLowerCase();
        if (initialDropdownStateClone.hasOwnProperty(key)) {
          initialDropdownStateClone[key] = header;
        }
      });
      setDropdownValues(initialDropdownStateClone);
    }
  }, [headersSetByUser, headersFromSheet]);

  const handleDropdownChange = (event, value, name) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
    setDropdownValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  return (
    <Table
      sx={{
        minWidth: "70rem",
        mb: "2.25rem",
        borderRadius: theme.borderRadius.main,
        border: `1px solid ${theme.palette.color.slate[200]}`,
      }}
    >
      <TableHead>
        <TableRow sx={{ backgroundColor: theme.palette.color.slate[50] }}>
          <TableCell
            sx={{
              py: "0.25rem",
              pl: "2rem",
              fontSize: "0.8rem",
              color: theme.palette.color.slate[400],
            }}
          >
            {t("finban")}
          </TableCell>
          <TableCell sx={{ py: "0.5rem" }}></TableCell>
          <TableCell
            sx={{
              py: "0.25rem",
              pr: "0.5rem",
              fontSize: "0.8rem",
              color: theme.palette.color.slate[400],
            }}
          >
            {t(fileColumnHeader)}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.keys(Constant.FinbanMappingFields).map((dropdownKey) => (
          <TableRow key={dropdownKey}>
            <TableCell
              sx={{
                py: "0.5rem",
                pl: "2rem",
                fontSize: "0.8rem",
                width: "45%",
                color: theme.palette.color.slate[700],
                fontWeight: theme.typography.fontWeightBold,
              }}
            >
              {t(`finban_header_mapping_${dropdownKey}`)}
            </TableCell>
            <TableCell
              sx={{
                py: "0.25rem",
                opacity: dropdownValues?.[dropdownKey] ? 1 : 0.2,
              }}
            >
              <MdArrowForward />
            </TableCell>
            <TableCell
              sx={{
                py: "0.35rem",
                pr: "1.75rem",
              }}
            >
              <DropDown
                hideTitle
                likeGoogle
                variant="filled"
                placeholder={t("Select attribute")}
                error={Boolean(errors?.[dropdownKey])}
                value={dropdownValues?.[dropdownKey] ?? null}
                onChange={(e, value) =>
                  handleDropdownChange(e, value, dropdownKey)
                }
                options={headersFromSheet}
                getOptionLabel={(option) => option ?? ""}
                renderOption={(option) => option ?? ""}
                mb={0}
                sx={{
                  // "& .MuiAutocomplete-endAdornment": {
                  //   top: "14%",
                  // },
                  "& .MuiInputBase-root": {
                    px: "0.5rem !important",
                    py: "0.15rem !important",
                    border: `1px solid ${Boolean(errors?.[dropdownKey]) ? theme.palette.color.red[300] : theme.palette.color.slate[300]}`,
                    borderRadius: `${theme.borderRadius.main} !important`,

                    "& input": {
                      fontSize: "0.7rem !important",
                      lineHeight: "0.7rem !important",
                      color:
                        theme.palette.color.grey[
                          dropdownValues?.[dropdownKey] ? 800 : 500
                        ],
                      fontWeight: theme.typography.fontWeightMediumBold,
                      fontFamily: theme.typography.fontFamily,
                    },
                  },
                }}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default HeaderMappingView;
