import { useState } from "react";
import { Box, Card, TextField } from "@mui/material";
import APICall from "../../APICall";
import EndPoints from "../../APICall/EndPoints";
import { Color, Images } from "../../Helper";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { setIsOpenSnackbar } from "../../store/slices/common";
import TailwindButton from "../../components/Overlay/TailwindButton";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

const PasswordResetScreen = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  //state
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);

  //api
  const initiatePassResetApi = async (obj) => {
    await APICall("post", EndPoints.password_reset, obj).then((response) => {
      if (response.status === 200 && response.data.status === "OK") {
        enqueueSnackbar(t("Password reset link sent to your email"), {
          variant: "success",
          autoHideDuration: 4000,
        });
        setTimeout(() => {
          navigate("/login");
        }, 500);
      } else {
        dispatch(setIsOpenSnackbar(false));
        if (response?.data?.email && response?.data?.email?.length > 0) {
          enqueueSnackbar(t(response?.data?.email[0]), {
            variant: "error",
            autoHideDuration: 5000,
          });
        }
      }
    });
  };

  //functions
  const onSubmit = () => {
    if (ValidateEmail(email)) {
      initiatePassResetApi({ email });
    }
  };

  const handleChangeEmail = (event) => {
    if (error) setError(null);
    setEmail(event.target.value);
  };

  const ValidateEmail = (mail) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }
    if (mail.trim() === "") {
      setError("please enter email address!");
    } else {
      setError("You have entered an invalid email address!");
    }
    return false;
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: Color.appThemeBg,
      }}
    >
      <Card
        sx={{
          width: "50rem",
          height: "26rem",
          borderRadius: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <img
          src={Images.Svg.AppLogoTheme}
          height={50}
          alt="App_Logo"
          style={{ position: "absolute", top: "2rem" }}
        />
        <TextField
          id="outlined-name"
          value={email}
          label="Email"
          size="small"
          onChange={handleChangeEmail}
          error={Boolean(error)}
          helperText={error}
          sx={{ width: "70%" }}
        />
        <Box sx={{ position: "absolute", bottom: "7rem" }}>
          <TailwindButton text={"Submit"} onClick={onSubmit} />
        </Box>
      </Card>
    </Box>
  );
};
export default PasswordResetScreen;
