import { memo } from "react";

const BarShape = memo((props) => {
  const { x, y, width, height, payload, theme, _key } = props;

  const fill =
    payload?.[_key] >= 0
      ? theme.palette?.color?.green[500]
      : theme.palette?.color?.red[500];

  return <rect x={x} y={y} width={width} height={height} fill={fill} />;
});

export default BarShape;
