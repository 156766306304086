import {
  CircularProgress,
  Typography,
  useTheme,
  Tooltip,
  Divider,
  Stack,
  Card,
  Grid,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useQuery } from "@tanstack/react-query";
import { TbPlugConnected } from "react-icons/tb";
import { useTranslation } from "react-i18next";
import Button from "@mui/lab/LoadingButton";
import { useSelector } from "react-redux";

import { getTransactionByParams, formatAmount } from "../../../Helper/data";
import IOSSwitch from "../../../components/Switchs/IOSSwitch";
import useStatusHook from "../../../hooks/useStatusHook";
import Translate from "../../../hooks/HOC/Translate";
import initialData from "../../../Helper/data";
import Icon from "../../../components/Icon";
import { Constant } from "../../../Helper";
import StatusView from "./StatusView";

const CardUi = ({
  card,
  style,
  disabled,
  clickable,
  isLoading,
  onClickShowMoreActions,
  handleClickCard,
  onClickCard = () => undefined,
  onClickDelete = () => undefined,
  onClickDataSet = () => undefined,
  onClickErrorLogs = () => {},
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const accountByDS = useSelector((state) => state.globalSlice.accountByDS);

  const ds = card?.ds;
  const account = accountByDS?.[ds?.uuid]?.[0] || null;
  const isActive = ds?.uuid;
  // const isActive = false;

  const title = ds?.title ? ds?.title : card?.title;
  const des = isActive ? (
    account?.name ? (
      `${account?.name} / ${account?.iban}`
    ) : (
      ""
    )
  ) : (
    <Translate i18nkey={card?.des} />
  );

  const onClick = (e) => {
    if (!card?.disabled || !disabled) {
      e.stopPropagation();
      if (ds?.uuid) {
        onClickDeleteDataSet(e);
      } else {
        handleClickCard(card);
      }
    }
  };

  const onClickDataSetText = (e) => {
    e.stopPropagation();
    onClickDataSet(e, ds, account);
  };

  const onClickItem = (e) => {
    e.stopPropagation();
    onClickCard(e, ds, account);
  };

  const onClickDeleteDataSet = (e) => {
    e.stopPropagation();
    onClickDelete(e, ds, account);
  };

  return (
    <Grid
      item
      sx={{
        width: "33rem !important",
      }}
    >
      <Card
        className={card?.className}
        disabled={card?.disabled}
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "11rem",
          width: "100%",
          cursor: "arrow",
          position: "relative",
          backgroundColor: theme.palette.color.white,
          boxShadow: theme.boxShadow,
          borderRadius: theme.borderRadius.main,
          p: "1.5rem",
          ...((card?.disabled || disabled) && {
            opacity: 0.5,
            pointerEvents: "none",
          }),
          ...style,
        }}
      >
        {!isActive ? <IntegrationStatus state={card.state} /> : null}

        <Stack direction={"row"}>
          {Boolean(account?.bank_details?.logo_url) ? (
            <img
              component="img"
              style={{
                width: "6rem",
                height: "2rem",
                objectFit: "contain",
                p: "1rem",
              }}
              src={account?.bank_details?.logo_url}
              alt="Logo"
            />
          ) : card?.img ? (
            <img
              style={{
                width: "6rem",
                height: "2rem",
                objectFit: "cover",
                ...card?.imgStyle,
              }}
              src={card?.img}
              alt="Logo"
            />
          ) : (
            <Icon
              icon={card?.icon}
              fontSize={"1.3rem"}
              color="inherit"
              style={{
                "& svg": {
                  width: "3rem",
                  height: "3rem",
                  ...card?.imageStyle,
                },
              }}
            ></Icon>
          )}
          <Stack sx={{ width: "68%" }}>
            <Tooltip placement="top" title={t(title)}>
              <Typography
                variant="body1"
                textAlign={"left"}
                fontWeight={"fontWeightMediumBold"}
                sx={{
                  color: theme.palette.color.slate[700],
                  pl: "0.75rem",
                  width: "100%",
                  display: "flow-root",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {t(title)}
              </Typography>
            </Tooltip>
            {isActive ? (
              <Stack direction={"row"} alignItems={"center"}>
                <IntegrationsType ds={ds} />
                <IntegrationsApi ds={ds} />
              </Stack>
            ) : null}
          </Stack>

          <div style={{ position: "absolute", right: "1.5rem" }}>
            {isLoading ? (
              <CircularProgress size="1.5rem" />
            ) : (clickable ? !isActive : true) ? (
              <Tooltip
                placement="top"
                title={
                  isActive
                    ? t("integration_switch_on_tooltip")
                    : t("integration_switch_off_tooltip")
                }
              >
                <span>
                  {card?.hideSwitch ? null : (
                    <IOSSwitch
                      disabled={card?.disabled || isLoading}
                      onClick={onClick}
                      checked={!!isActive}
                    />
                  )}
                </span>
              </Tooltip>
            ) : null}
          </div>
        </Stack>

        <Tooltip placement="top" title={des}>
          <Typography
            variant={isActive ? "caption" : "body2"}
            color={isActive ? "color.slate.400" : "color.description"}
            fontWeight={"fontWeightMedium"}
            display="flex"
            textAlign="left"
            sx={{ mt: "1rem" }}
          >
            {des}
          </Typography>
        </Tooltip>

        <Stack
          sx={{
            position: "absolute",
            bottom: "0",
            marginInline: "auto",
            width: "calc(100% - 3.5rem)",
          }}
        >
          {isActive ? <Divider sx={{ opacity: 0.6 }} /> : null}
          {isActive ? (
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              sx={{ py: "0.5rem" }}
            >
              <div>
                <AccountBalance account={account} ds={ds} />
              </div>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                {clickable ? (
                  <AssignIntegration onClick={onClickItem} />
                ) : (
                  <>
                    <AssignOrg
                      ds={ds}
                      onClickDataSetText={onClickDataSetText}
                    />
                    <span style={{ width: "0.5rem" }}></span>
                    <StatusView
                      uuid={ds?.uuid}
                      onClickErrorLogs={onClickErrorLogs}
                      sx={{
                        maxWidth: "12rem",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                    />
                    <MoreVertIcon
                      onClick={(e) =>
                        onClickShowMoreActions(e, ds, account, card)
                      }
                      sx={{ cursor: "pointer", mr: "-0.75rem" }}
                    />
                  </>
                )}
              </Stack>
            </Stack>
          ) : null}
        </Stack>
      </Card>
    </Grid>
  );
};

export default CardUi;

const IntegrationStatus = ({ state }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  let isInDevelopment = false;
  let isOnline = false;
  let isComingSoon = false;
  let isNew = false;
  if (["New", "Neu"]?.includes(t(state))) {
    isNew = true;
  }
  if (["In development", "In Entwicklung"]?.includes(t(state))) {
    isInDevelopment = true;
  }
  if (["Coming soon", "Kommt bald"]?.includes(t(state))) {
    isComingSoon = true;
  }
  if (["Available", "Verfügbar"]?.includes(t(state))) {
    return null;
    // isOnline = true;
  }

  return (
    <Typography
      variant="body2"
      fontWeight={"fontWeightMediumBold"}
      sx={{
        px: "0.5rem",
        py: "0.25rem",
        backgroundColor: isInDevelopment
          ? theme.palette.color.violet[200]
          : isOnline
            ? theme.palette.color.emerald[400]
            : isNew
              ? theme.palette.primary[200]
              : isComingSoon
                ? theme.palette.color.slate[200]
                : theme.palette.color.yellow[200],
        color: isInDevelopment
          ? theme.palette.color.violet[500]
          : isOnline
            ? theme.palette.color.white
            : isNew
              ? theme.palette.primary[500]
              : isComingSoon
                ? theme.palette.color.slate[500]
                : theme.palette.color.yellow[700],
        position: "absolute",
        fontSize: "0.65rem",
        width: "fit-content",
        bottom: 0,
        right: 0,
      }}
    >
      {t(state)}
    </Typography>
  );
};

const AccountBalance = ({ account: item, ds }) => {
  const theme = useTheme();
  const isFirstFetchDs = useSelector(
    (state) => state.globalSlice.isFirstFetchDs
  );
  const isDsUuidLoading = useSelector(
    (state) => state.globalSlice.isDsUuidLoading
  );

  const getBalance = (balances) => {
    const total = balances?.reduce(
      (total, item) =>
        parseFloat(total) +
        parseFloat(
          Number(item?.amount || 0) + Number(item?.ignored_value || 0)
        ),
      0
    );
    return parseFloat(total).toFixed(2);
  };

  const data_source_by_uuid_external_connections = useStatusHook(null, {
    queryKey: [
      "integrations",
      {
        data_source: ds?.uuid,
        apiType: "data_source_external_connections",
      },
    ],
  });

  const Integration_Transactions = useQuery({
    queryKey: [
      "integrations",
      {
        page: 1,
        page_size: 1,
        data_source: [ds?.uuid],
        apiType: "Integration_Transactions",
      },
    ],
    queryFn: ({ signal }) => {
      let params = {
        config: {
          signal: signal,
        },
        page: 1,
        page_size: 1,
        data_source: [ds?.uuid],
      };

      const result = getTransactionByParams(params);
      if (result) {
        return result;
      }
    },
    cacheTime: 5000,
    backgroundFetch: true,
    enabled:
      !!ds?.uuid &&
      !!ds?.is_connected &&
      !!ds?.last_sync_date &&
      !isDsUuidLoading &&
      ds?.type === 19 &&
      isFirstFetchDs === 0 &&
      !data_source_by_uuid_external_connections?.isFetching &&
      data_source_by_uuid_external_connections?.data === "UPDATED",
  });

  let value = null;
  let num = 0;
  if (
    !!ds?.uuid &&
    !!ds?.is_connected &&
    !!ds?.last_sync_date &&
    data_source_by_uuid_external_connections?.data === "UPDATED"
  ) {
    num = Integration_Transactions?.data?.count || 0;
    value = (Integration_Transactions?.data?.count || 0)?.toString();
  }
  if (ds?.type === 1 && item && item?.balances) {
    num = getBalance(item?.balances) || 0;
    value = formatAmount({
      amount: num,
      dataset: {
        currency: item?.currency,
      },
    });
  }
  if (ds?.type === 12 && item && item?.balances) {
    item?.balances?.forEach((element) => {
      if (element?.name === "booked") {
        num =
          Number(element?.amount || 0) + Number(element?.ignored_value || 0);

        value = formatAmount({
          amount: num,
          dataset: {
            currency: item?.currency,
          },
        });
      }
    });
  }

  if (!value) return null;

  return (
    <Button
      loading={!!Integration_Transactions?.isFetching}
      disableElevation
      color="primary"
      variant="outlined"
      size="small"
      sx={{
        height: "1.8rem",
        textTransform: "initial",
        fontWeight: 600,
        color: theme.palette.color[num >= 0 ? "green" : "red"][500],
        width: "max-content",
        fontSize: "0.7rem",
        lineHeight: "0.7rem",
        border: "none",
        backgroundColor: theme.palette.color[num >= 0 ? "green" : "red"][50],
        cursor: "default",
        "& .MuiCircularProgress-root": {
          width: "1rem !important",
          height: "1rem !important",
          color: theme.palette.color[num >= 0 ? "green" : "red"][500],
        },

        "&:hover": {
          border: "none",
          backgroundColor: theme.palette.color[num >= 0 ? "green" : "red"][50],
        },
      }}
    >
      {value}
    </Button>
  );
};

const AssignIntegration = ({ onClick }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Button
      onClick={onClick}
      disableElevation
      color="primary"
      variant="outlined"
      size="small"
      sx={{
        height: "1.8rem",
        color: theme.palette.primary.main,
        width: "max-content",
        fontWeight: 600,
        fontSize: "0.7rem",
        lineHeight: "inherit",
        backgroundColor: theme.palette.primary[50],
        border: `1px solid ${theme.palette.primary.main}`,
        "&:hover": {
          border: `1px solid ${theme.palette.primary.main}`,
          backgroundColor: theme.palette.primary[100],
        },
      }}
    >
      <Icon
        icon={<TbPlugConnected />}
        fontSize={"1rem"}
        color={theme.palette.primary.main}
        style={{
          marginRight: "0.25rem",
        }}
      ></Icon>

      {t("Assign")}
    </Button>
  );
};

const AssignOrg = ({ ds, onClickDataSetText }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  if (ds?.state !== 1) {
    return null;
  }
  return (
    <Tooltip arrow placement="top" title={t("Manage Organizations")}>
      <Button
        onClick={onClickDataSetText}
        disableElevation
        color="primary"
        variant="outlined"
        size="small"
        sx={{
          height: "1.8rem",
          color:
            ds?.datasets?.length === 0
              ? theme.palette.primary.main
              : theme.palette.color.green[500],
          // width: "max-content",
          justifyContent: "flex-start",
          fontWeight: 600,
          fontSize: "0.7rem",
          lineHeight: "inherit",
          backgroundColor:
            ds?.datasets?.length === 0
              ? "transparent"
              : theme.palette.color.green[50],
          border:
            ds?.datasets?.length === 0
              ? `1px solid ${theme.palette.primary.main}`
              : 0,
          maxWidth: "9rem",

          "&:hover": {
            border:
              ds?.datasets?.length === 0
                ? `1px solid ${theme.palette.primary.main}`
                : 0,
            backgroundColor:
              ds?.datasets?.length === 0
                ? theme.palette.primary[50]
                : theme.palette.color.green[100],
          },
        }}
      >
        {ds?.datasets?.length === 0 ? (
          <Icon
            icon={<TbPlugConnected />}
            fontSize={"1.2rem"}
            color={theme.palette.primary.main}
            style={{
              marginRight: "0.25rem",
            }}
          ></Icon>
        ) : null}
        <span
          style={{
            maxWidth: "8rem",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {ds?.datasets?.length !== 0
            ? `${ds?.datasets?.length} ${
                ds?.datasets?.length === 1
                  ? t(initialData.path.Organization)
                  : t(initialData.path.Organizations)
              }`
            : t("Assign to Organization(s)")}
        </span>
      </Button>
    </Tooltip>
  );
};

const IntegrationsApi = ({ ds }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const type = Constant?.IntegrationsSource?.[ds?.type];
  if (!type) return null;
  return (
    <Button
      disableElevation
      disableRipple
      color="primary"
      variant="text"
      size="small"
      sx={{
        ml: "0.5rem",
        fontSize: "0.6rem",
        lineHeight: "normal",
        borderRadius: theme.borderRadius.main,
        color: theme.palette.color.slate[500],
        backgroundColor: theme.palette.color.slate[50],
        width: "fit-content",
        minWidth: "unset",
        cursor: "default",
        px: "0.4rem",
        "&:hover": {
          backgroundColor: theme.palette.color.slate[50],
        },
      }}
    >
      {t(type)}
    </Button>
  );
};

const IntegrationsType = ({ ds }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const card = Constant?.integrations?.IntegrationsByName?.[ds?.note]?.[0];
  if (!card) return null;
  return (
    <Button
      disableElevation
      disableRipple
      variant="outlined"
      size="small"
      sx={{
        ml: "0.5rem",
        fontSize: "0.55rem",
        textTransform: "capitalize",
        lineHeight: "normal",
        color: theme.palette.color.slate[500],
        borderColor: theme.palette.color.slate[300],
        width: "fit-content",
        minWidth: "unset",
        px: "0.4rem",
        cursor: "default",
        "&:hover": {
          color: theme.palette.color.slate[500],
        },
      }}
    >
      {t(
        Constant?.IntegrationsTypeOptionsByID?.[card?.integration_type]?.[0]
          ?.label
      )}
    </Button>
  );
};
