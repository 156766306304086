import { useCallback, useEffect, useState, useMemo, useRef, memo } from "react";
import { Outlet, useLocation, useNavigate } from "react-router";
import { useMediaQuery, Box, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { BsFillTagsFill } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";

import useUpdateEffect from "../../../../hooks/4-useUpdateEffect/useUpdateEffect";
import { setContactOverlayStatus } from "../../../../store/slices/datasets";
import { useDeferredTimeout } from "../../../../hooks/useDeferredTimeout";
import { getAllTransactionsByParams } from "../../../../Helper/data";
import { getContactQueryKey } from "../../../../Helper/queryKeys";
import ClientTransactionsList from "./ClientTransactionsList";
import { setContacts } from "../../../../store/slices/global";
import ThemeTabs from "../../../../components/ThemeTabs";
import AddButton from "../../../../components/AddButton";
import ClientHeader from "./ClientHeader";
import ClientMerge from "./ClientMerge";
import ClientList from "./ClientList";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      backgroundColor: theme?.palette?.color?.appThemeBg,
      height: "100%",
      paddingTop: "1rem",
      width: "100%",
      overflow: "hidden",
    },
  };
});

const Clients = ({ type = 1 }) => {
  const classes = useStyles();
  const viewRef = useRef();
  const timerRef = useRef();
  const s2133 = useMediaQuery("(min-width:2133px)");
  const s2400 = useMediaQuery("(min-width:2400px)");

  let HEIGHT = 2.5;
  let spacing = 110;
  const _width = document.body.clientWidth;
  const width = (_width * 85) / 100;
  if (s2133) {
    HEIGHT = 3;
  }
  if (s2400) {
    HEIGHT = 5;
  }

  //states
  const [tab, setTab] = useState(false);
  const [refreshUI, setRefreshUI] = useState(false);

  const onResize = useCallback(() => {
    if (viewRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => {
        setRefreshUI((prev) => !prev);
      }, 100);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, [onResize]);

  return (
    <Box className={classes.root} ref={viewRef}>
      <ContactFunctions />

      <ClientHeader width={width} tab={1} hideState={tab !== 2} />
      <TabView width={width} tab={tab} setTab={setTab} />
      {tab === 1 ? <ClientList width={width} HEIGHT={HEIGHT} /> : null}
      {tab === 2 ? (
        <ClientTransactionsList
          type={type}
          width={width}
          spacing={spacing}
          HEIGHT={HEIGHT}
        />
      ) : null}
      {tab === 4 ? (
        <ClientMerge
          type={type}
          width={width}
          spacing={spacing}
          HEIGHT={HEIGHT}
        />
      ) : null}
      <Outlet />
    </Box>
  );
};

export default memo(Clients);

const TabView = ({ width, tab, setTab }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  //data
  const key = "customer";
  const url_keys = "customers";

  const tabOptions = useMemo(
    () => [
      {
        id: 1,
        url_path: `${url_keys}-list`,
        value: 1,
        iconPosition: "start",
        label: `${key}_tab_01`,
        icon: <BsFillTagsFill />,
      },
      // {
      //   id: 2,
      //   url_path: `${url_keys}-portfolio-analysis`,
      //   value: 2,
      //   iconPosition: "start",
      //   label: `${key}_tab_02`,
      //   icon: <BsFillTagsFill />,
      // },
      // {
      //   id: 3,
      //   url_path: `top-${url_keys}`,
      //   value: 3,
      //   iconPosition: "start",
      //   label: `${key}_tab_03`,
      //   icon: <BsFillTagsFill />,
      // },
      {
        id: 4,
        url_path: `merge-${url_keys}`,
        value: 4,
        iconPosition: "start",
        label: `${key}_tab_04`,
        icon: <BsFillTagsFill />,
      },
    ],
    [key, url_keys]
  );

  //redux
  const dataset = useSelector((state) => state.boardSlice?.dataSetData?.uuid);

  const handleChangeTab = (event, newValue) => {
    if (tab !== newValue) {
      setTab(newValue);

      const item = tabOptions?.find((o1) => o1?.value === newValue);
      let array = location.pathname.split("/");
      array.pop();
      array.push(item?.url_path);
      let url = array.join("/");
      navigate(url);
    }
  };

  const onClickAddNewClient = () => {
    dispatch(
      setContactOverlayStatus({
        open: true,
        overlay_type: "drawer_modal_contact_edit_form",
        anchor: "right",
        payload: {
          modalType: "add",
        },
      })
    );
  };

  useEffect(() => {
    if (dataset) {
      const array = location.pathname.split("/");
      const url_path = array[array.length - 1];
      const filter = tabOptions?.find((o1) => o1?.url_path === url_path);
      if (filter) {
        setTab(filter?.value);
      }
    }
  }, [location.pathname, dataset, tabOptions, setTab]);

  return (
    <Stack
      direction="row"
      alignItems={"center"}
      justifyContent={"space-between"}
      sx={{
        width: `calc(${width}px - 0.75rem)`,
        mt: "1rem",
      }}
    >
      <ThemeTabs options={tabOptions} tab={tab} onChange={handleChangeTab} />
      {tab === 1 ? (
        <AddButton
          tooltipLabel={t(`add_new_customer`)}
          label={t(`add_new_customer`)}
          onClick={onClickAddNewClient}
          keyboardShortcuts={["N"]}
        />
      ) : null}
    </Stack>
  );
};
const ContactFunctions = () => {
  const dispatch = useDispatch();
  //redux
  const dataset = useSelector((state) => state.boardSlice?.dataSetData?.uuid);
  const refreshData = useSelector((state) => state.appSlice?.refreshData);

  //query
  const ContactList = useQuery({
    queryKey: getContactQueryKey({
      dataset,
    }),
    queryFn: async ({ signal }) => {
      const results = await getAllTransactionsByParams({
        endpoint: "customers",
        dataset,
        type: [1, 2],
        config: {
          signal,
        },
      });
      if (results) {
        return results;
      }
    },
    backgroundFetch: true,
    keepPreviousData: true,
    refetchOnMount: true,
    priority: 1,
    cacheTime: 10000,
    enabled: !!dataset,
  });

  useEffect(() => {
    dispatch(setContacts(ContactList?.data || []));
  }, [ContactList?.data, dispatch]);

  useUpdateEffect(() => {
    ContactList.refetch();
  }, [refreshData]);

  return null;
};
