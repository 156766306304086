import { eachMonthOfInterval, format } from "date-fns";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { useMemo, useState } from "react";
import _ from "underscore";

import {
  getStatisticsDataWithParams,
  getTailwindColor,
} from "../../../../Helper/data";
import useDebounce from "../../../../hooks/3-useDebounce/useDebounce";
import ComponentLoader from "../../../../components/ComponentLoader";
import BarCharts from "../../../../components/Charts/BarCharts";
import QuickLinkView from "./Component/QuickLinkView";
import initialData from "../../../../Helper/data";
import { Constant } from "../../../../Helper";
import EmptyView from "./Component/EmptyView";

const open_state = ["Lead - potential"];
const closed_state = ["Lead - won"];

const state_color = initialData?.defaultState?.["Lead - won"]?.color || "slate";

const LeadStatusWidget = ({ widgetType, widget }) => {
  //redux
  const dataset = useSelector((state) => state.boardSlice?.dataSetData?.uuid);
  const use_global_categories = useSelector(
    (state) => state.boardSlice?.dataSetData?.use_global_categories
  );
  const stateByTitle = useSelector((state) => state.globalSlice.stateByTitle);

  //state
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const multiStatesIds = useMemo(() => {
    let array = [];
    Constant.leadState.forEach((item) => {
      if (stateByTitle?.[item]?.[0]?.uuid) {
        array.push(stateByTitle?.[item]?.[0]?.uuid);
      }
    });
    return array;
  }, [stateByTitle]);

  const WidgetData = useQuery({
    queryKey: [
      "transactions",
      {
        dataset: dataset,
        apiType: "lead_status_over_time",
        widget: {
          start_date: widget?.start_date,
          end_date: widget?.end_date,
          scenarios: widget?.scenarios,
        },
      },
    ],
    queryFn: ({ signal }) => {
      let param = {
        config: {
          signal,
        },
        type: "monthly",
        dataset: dataset,
        from_payment_date: widget?.start_date,
        to_payment_date: widget?.end_date,
        multiScenarioIds: widget?.scenarios,
        group_by: ["state"],
        multiStatesIds,
      };
      if (!use_global_categories) {
        param.category_dataset = dataset;
      } else {
        param.global_category = true;
      }
      const result = getStatisticsDataWithParams(param);

      if (result) {
        return result;
      }
    },
    backgroundFetch: true,
    priority: 3,
    enabled:
      !!dataset &&
      !!widget?.end_date &&
      !!widget?.start_date &&
      multiStatesIds?.length > 0,
  });

  let isFetching =
    WidgetData.isFetching || WidgetData.isLoading || !WidgetData.isSuccess;

  //life cycle method
  useDebounce(
    () => {
      if (
        widget?.start_date &&
        widget?.end_date &&
        WidgetData?.data &&
        !isFetching
      ) {
        let dummyData = [];
        setLoading(true);
        let formateString = "yyyy-MM-dd";

        formateString = "MMM-yyyy";
        let dates = eachMonthOfInterval({
          start: new Date(widget?.start_date),
          end: new Date(widget?.end_date),
        });

        let periodData = _.groupBy(
          WidgetData?.data?.results || [],
          ({ month }) => format(new Date(month), formateString)
        );
        let obj = {};
        if (WidgetData?.data?.results?.length > 0) {
          dates?.forEach((element) => {
            let date = format(new Date(element), formateString);
            obj = {
              due_date: date,
            };

            const item = periodData?.[date] ?? [];

            let open = item
              ?.filter((o1) => o1?.state && open_state?.includes(o1?.state))
              ?.reduce(
                (total, entry) =>
                  parseFloat(total) +
                  parseFloat(entry?.outflow ?? 0) +
                  parseFloat(entry?.inflow ?? 0),
                0
              );
            obj.open = open;

            let closed = item
              ?.filter((o1) => o1?.state && closed_state?.includes(o1?.state))
              ?.reduce(
                (total, entry) =>
                  parseFloat(total) +
                  parseFloat(entry?.outflow ?? 0) +
                  parseFloat(entry?.inflow ?? 0),
                0
              );
            obj.closed = closed;
            dummyData.push(obj);
          });
        }
        setTimeout(() => {
          setLoading(false);
          setData(dummyData);
        }, 250);
      }
    },
    300,
    [widget?.start_date, widget?.end_date, WidgetData?.data],
    true
  );

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: "1rem",
      }}
    >
      {isLoading || isFetching ? (
        <ComponentLoader
          loading
          hideNoDataPlaceholder
          height={"100%"}
          size={60}
        />
      ) : data?.length > 0 ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "80%",
            }}
          >
            <BarCharts
              data={data}
              hideDefault
              barsData={[
                {
                  key: "lead_status_paid_tooltip_label",
                  dataKey: "closed",
                  stackId: "a",
                  fill: getTailwindColor(state_color, 500),
                  color: state_color,
                  shade: 500,
                },
                {
                  key: "lead_status_open_tooltip_label",
                  dataKey: "open",
                  stackId: "a",
                  fill: getTailwindColor(state_color, 200),
                  color: state_color,
                  shade: 200,
                },
              ]}
            />
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              position: "absolute",
              right: "1.5rem",
              bottom: "1.5rem",
              marginTop: "0.75rem",
            }}
          >
            {Constant.leadState?.map((item) => (
              <QuickLinkView
                key={item}
                title={item}
                moreState={Constant.leadState}
                data={WidgetData?.data?.results || []}
                start_date={widget?.start_date}
                end_date={widget?.end_date}
              />
            ))}
            <QuickLinkView
              key={"All"}
              title={"All"}
              state_color={state_color}
              moreState={Constant.leadState}
              data={WidgetData?.data?.results || []}
              start_date={widget?.start_date}
              end_date={widget?.end_date}
            />
          </div>
        </div>
      ) : (
        <EmptyView type={widgetType} />
      )}
    </div>
  );
};

export default LeadStatusWidget;
