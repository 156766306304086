import ReactDOM from "react-dom/client";

import reportWebVitals from "./reportWebVitals";
import App from "./App";

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement);
Error.stackTraceLimit = process.env.NODE_ENV === "development" ? 10 : 30;
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
