import {
  ClickAwayListener,
  Typography,
  TextField,
  Tooltip,
  Popper,
  Paper,
  Fade,
  Box,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useDispatch, useSelector } from "react-redux";
import lastDayOfYear from "date-fns/lastDayOfYear";
import CloseIcon from "@mui/icons-material/Close";
import { startOfYear, subYears } from "date-fns";
import { useTranslation } from "react-i18next";
import endOfMonth from "date-fns/endOfMonth";
import { useEffect, useState } from "react";
import addMonths from "date-fns/addMonths";
import endOfYear from "date-fns/endOfYear";
import addYears from "date-fns/addYears";
import enUS from "date-fns/locale/en-US";
import { useSnackbar } from "notistack";
import setDate from "date-fns/setDate";
import format from "date-fns/format";
import de from "date-fns/locale/de";
import { v1 as uuid } from "uuid";
import _ from "underscore";

import {
  convertToValidManualState,
  addBatchRecurringRules,
  addBatchTransactions,
  getTransactionByUrl,
  getRecurDateArray,
} from "../../../../Helper/data";
import {
  setRefreshColumnData,
  setStageLoadingText,
  setRefreshData,
} from "../../../../store/slices/appmain";
import {
  setPopupStatus2,
  setPopupStatus3,
  setPopupStatus,
} from "../../../../store/slices/datasets";
import TailwindButton from "../../../../components/Overlay/TailwindButton";
import { setRecurring_rules } from "../../../../store/slices/global";
import { setTransactionData } from "../../../../store/slices/board";
import DropDown from "../../../../components/Overlay/DropDown";
import { Color, Constant, Fonts } from "../../../../Helper";
import EndPoints from "../../../../APICall/EndPoints";
import APICall from "../../../../APICall";

const RecurOverlay = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  //redux
  const transactionData = useSelector(
    (state) => state.boardSlice?.transactionData
  );
  const recurring_rules = useSelector(
    (state) => state.globalSlice?.recurring_rules
  );
  const profile = useSelector((state) => state.settingsSlice?.profile);

  const recur_action_confirm = useSelector(
    (state) => state.globalSlice.appliedFilterlist?.kanban?.recur_action_confirm
  );
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);
  const popupStatus = useSelector((state) => state.datasetSlice?.popupStatus);
  const popupStatus3 = useSelector((state) => state.datasetSlice?.popupStatus3);

  let title = popupStatus?.payload?.date || "Set Recurrence";
  let url = popupStatus?.payload?.url || null;
  //state
  const [recurring_Obj, setRecurring_Obj] = useState({
    start_date: format(
      addMonths(new Date(popupStatus?.payload?.date), 1),
      "yyyy-MM-dd"
    ),
    name: "recurrence",
  });
  const [showError, setShowError] = useState(false);
  const [loader, setLoader] = useState(false);
  const [option, setOption] = useState({
    period: [],
    repetition: [],
  });

  //lifeCycle
  useEffect(() => {
    getOptions();
  }, []);

  //api
  const addBatch = async (array) => {
    dispatch(setStageLoadingText("common_process_loader_text"));
    let response = await addBatchTransactions(array);
    if (response) {
      let data = [...transactionData, ...response];
      let updatedNormalCards = response?.find((o1) =>
        popupStatus?.payload?.normalCardStates?.includes(o1.state)
      );
      dispatch(setTransactionData(data));
      let key = [
        "monthly",
        "transaction_daily_chart",
        "transaction_monthly_chart",
      ];
      dispatch(setStageLoadingText(null));
      dispatch(
        setRefreshData({
          key: key,
        })
      );
      if (!!updatedNormalCards) {
        let dates = response.map((o1) => o1.due_date);
        dispatch(
          setRefreshColumnData({
            boardType: [popupStatus?.type],
            date: dates,
          })
        );
      }

      enqueueSnackbar(t("Transactions Added Successfully"));
    }
  };

  const getOptions = async () => {
    await APICall("OPTIONS", EndPoints.recurring_rules).then((response) => {
      if (response.status === 200 && response.data) {
        let data = response.data.actions.POST;
        setOption({
          period: data["period"]?.choices,
          repetition: data["repetition"]?.choices,
        });
      }
    });
  };

  //function
  const onChangeDropDown = (e, value, name) => {
    if (name === "period") {
      let start_date =
        recurring_Obj?.start_date ??
        format(
          addMonths(new Date(popupStatus?.payload?.date), 1),
          "yyyy-MM-dd"
        );
      let end_date = null;
      if (value) {
        if (value?.value !== -2) {
          end_date = format(
            new Date(getLastDate(value?.value, start_date)),
            "yyyy-MM-dd"
          );
        }
      }
      setRecurring_Obj({
        ...recurring_Obj,
        [name]: value?.value,
        start_date: start_date,
        end_date: end_date,
      });
    } else {
      setRecurring_Obj({ ...recurring_Obj, [name]: value?.value });
    }
    setShowError(false);
  };

  const onChangeRangeDate = (value, name) => {
    setShowError(false);

    if (name === "start_date") {
      let day = value ? new Date(value).getDate() : "1";
      let start_date = null;
      if (day > 28) {
        start_date = value ? format(setDate(value, 28), "yyyy-MM-dd") : null;
      } else {
        start_date = value ? format(value, "yyyy-MM-dd") : null;
      }

      setRecurring_Obj({
        ...recurring_Obj,
        period: -2,
        [name]: start_date,
        end_date:
          recurring_Obj.end_date &&
          start_date &&
          new Date(recurring_Obj.end_date) <= new Date(start_date)
            ? null
            : recurring_Obj.end_date,
      });
    } else {
      setRecurring_Obj({
        ...recurring_Obj,
        period: -2,
        [name]: value ? format(value, "yyyy-MM-dd") : null,
      });
    }
  };

  const getLastDate = (id, date) => {
    let result = null;
    if (id === -1) {
      result = lastDayOfYear(new Date(date));
    } else {
      result = endOfMonth(addMonths(new Date(date), id - 1));
    }
    return result;
  };

  const onSaveRecurrence = async () => {
    let { period, repetition, end_date, start_date } = recurring_Obj;
    if (!start_date || !end_date || !period || !repetition) {
      setShowError(true);
      return null;
    }

    let data = [];
    if (url) {
      setLoader(true);
      data = await getTransactionByUrl(dataSetData?.uuid, url);
      setLoader(false);
    } else {
      data = popupStatus?.payload?.columnData;
    }
    if (!data || data?.length === 0) {
      setShowError(true);
      enqueueSnackbar(t("transaction_not_availabel_for_recur_groups"), {
        variant: "warning",
        autoHideDuration: 5000,
      });
      return null;
    }
    let dateArray = getRecurDateArray(repetition, start_date, end_date);
    setLoader(false);
    let length = dateArray.length * data.length;
    if (!recur_action_confirm && length >= 100) {
      dispatch(
        setPopupStatus3({
          id: "simple-popper",
          from: "recur_action_confirm",
          open: true,
          showCheckBox: true,
          onConfirm: () => onConfirm(data),
          payload: {
            title: t("Attention"),
            message: t("recur_action_warning_msg"),
            length: length,
          },
        })
      );
    } else {
      onConfirm(data);
    }
  };

  const onConfirm = async (data) => {
    dispatch(setPopupStatus3(null));
    let { repetition, end_date, start_date } = recurring_Obj;
    // onClosePopover();
    dispatch(setStageLoadingText("common_process_loader_text"));

    let recurRuleArray = [];

    data?.forEach((element) => {
      let obj = {
        uuid: uuid(),
        name: element?.uuid,
        period: -2,
        repetition: repetition,
        start_date: start_date,
        end_date: end_date,
        value: element?.gross_value,
        recurring_type: "simple",
      };
      recurRuleArray.push(obj);
    });
    let recurRuleResponse = await addBatchRecurringRules(recurRuleArray);
    if (recurRuleResponse) {
      let recurData = [...recurring_rules, ...recurRuleResponse];
      dispatch(setRecurring_rules(recurData));
    }
    let groupedRecurRulesByName = _.groupBy(
      recurRuleResponse,
      ({ name }) => name
    );

    let dateArray = getRecurDateArray(repetition, start_date, end_date);
    let day = new Date(start_date).getDate();
    let addArray = [];
    dateArray.forEach((item) => {
      data?.forEach((element) => {
        let recurring_rule = groupedRecurRulesByName[element?.uuid][0]?.uuid;
        addArray.push({
          alias: element?.alias,
          gross_value: element?.gross_value,
          category: element?.category,
          contact: element?.contact,
          invoice_date: element?.invoice_date,
          note: element?.note,
          tax: element?.tax,
          title: element?.title,
          scenario: element?.scenario,
          state: convertToValidManualState(element.state),
          recurring_rule: recurring_rule,
          source: 1,
          position: element?.position || 1001,
          due_date: format(setDate(item, day > 28 ? 28 : day), "yyyy-MM-dd"),
          data_source: dataSetData?.internal_data_source,
        });
      });
    });

    addBatch(addArray);
    onClosePopover();
  };

  const onClosePopover = () => {
    if (!popupStatus3?.open) {
      dispatch(
        setPopupStatus({
          open: false,
          anchorEl: null,
        })
      );
      dispatch(
        setPopupStatus2({
          open: false,
          anchorEl: null,
        })
      );
    }
  };

  //render function
  const View2 = () => {
    return (
      <Box
        sx={{
          width: "100%",
          display: "flex:",
          flexDirection: "column",
          backgroundColor: Color.BodyBG,
          px: "1rem",
        }}
      >
        <Box sx={{ width: "50%", mt: "1.5rem" }}>
          <DropDown
            star
            disablePortal
            fontSize="0.8rem"
            name="period"
            value={
              option?.period?.find(
                (o1) => o1.value === recurring_Obj?.period
              ) || null
            }
            onChange={(e, value) => onChangeDropDown(e, value, "period")}
            options={option?.period}
            renderOption={(option) => `${option?.display_name} ` ?? ""}
            getOptionLabel={(option) => option?.display_name ?? ""}
            error={showError && !Boolean(recurring_Obj?.period)}
            title={t("label_period")}
            tooltip={t("tooltip_period")}
          />
        </Box>
        <Box sx={{ width: "50%", mt: "1.5rem" }}>
          <DropDown
            star
            disablePortal
            fontSize="0.8rem"
            name="repetition"
            value={
              option?.repetition?.find(
                (o1) => o1.value === recurring_Obj?.repetition
              ) || null
            }
            onChange={(e, value) => onChangeDropDown(e, value, "repetition")}
            options={option?.repetition}
            renderOption={(option) => `${option?.display_name} ` ?? ""}
            getOptionLabel={(option) => option?.display_name ?? ""}
            error={showError && !Boolean(recurring_Obj?.repetition)}
            title={t("label_repetition")}
            tooltip={t("tooltip_repetition")}
          />
        </Box>
        <Box
          sx={{
            width: "100%",
            mt: "1.5rem",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Tooltip arrow placement="top" title={t("tooltip_start_date")}>
            <Typography
              variant="h6"
              component={"h6"}
              sx={{
                color: Color.blueGrey900,
                fontWeight: 700,
                fontFamily: Fonts.Text,
                mb: "0.25rem",
                textAlign: "left",
                fontSize: "0.8rem",
                cursor: "default",
              }}
            >
              {t("label_start_date")} *
            </Typography>
          </Tooltip>

          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={profile?.locale === "de_DE" ? de : enUS}
          >
            <DatePicker
              views={["year", "month", "day"]}
              minDate={startOfYear(
                subYears(new Date(recurring_Obj?.start_date), 2)
              )}
              maxDate={endOfYear(
                addYears(new Date(recurring_Obj?.start_date), 6)
              )}
              error={showError && !Boolean(recurring_Obj?.start_date)}
              value={new Date(recurring_Obj?.start_date)}
              onChange={(value) => {
                onChangeRangeDate(value, "start_date");
              }}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
          </LocalizationProvider>
        </Box>
        <Box
          sx={{
            width: "100%",
            mt: "1.5rem",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Tooltip arrow placement="top" title={t("tooltip_end_date")}>
            <Typography
              variant="h6"
              component={"h6"}
              sx={{
                color: Color.blueGrey900,
                fontWeight: 700,
                fontFamily: Fonts.Text,
                mb: "0.25rem",
                textAlign: "left",
                fontSize: "0.8rem",
                cursor: "default",
              }}
            >
              {t("label_end_date")} *
            </Typography>
          </Tooltip>

          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={profile?.locale === "de_DE" ? de : enUS}
          >
            <DatePicker
              views={["year", "month"]}
              minDate={new Date(recurring_Obj?.start_date)}
              maxDate={endOfYear(
                addYears(new Date(recurring_Obj?.start_date), 6)
              )}
              error={showError && !Boolean(recurring_Obj?.end_date)}
              value={
                recurring_Obj?.end_date
                  ? new Date(recurring_Obj?.end_date)
                  : null
              }
              onChange={(value) => {
                onChangeRangeDate(value, "end_date");
              }}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
          </LocalizationProvider>
        </Box>
      </Box>
    );
  };

  return (
    <Popper
      transition
      placement="left-end"
      sx={{ zIndex: 1303 }}
      id={popupStatus?.id}
      open={popupStatus?.open}
      anchorEl={popupStatus?.anchorEl}
    >
      {({ TransitionProps }) => (
        <ClickAwayListener onClickAway={onClosePopover}>
          <Fade {...TransitionProps} timeout={350}>
            <Paper sx={{ width: "28.125rem", height: "fit-content" }}>
              <Typography
                component="div"
                sx={{
                  FontSize: "1.6rem",
                  fontFamily: Fonts.Text,
                  fontWeight: 700,
                  position: "relative",
                  backgroundColor: Color.HeaderBG,
                  p: "1rem",
                }}
              >
                {title}
                <CloseIcon
                  onClick={onClosePopover}
                  sx={{
                    position: "absolute",
                    right: "0.5rem",
                    cursor: "pointer",
                    color: Color.theme.grey[500],
                  }}
                />
              </Typography>
              {View2()}
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  mt: "1.5rem",
                  backgroundColor: Color.FooterBG,
                  p: "1rem",
                }}
              >
                <TailwindButton
                  type="cancel"
                  text={t("Cancel")}
                  onClick={onClosePopover}
                />
                <TailwindButton
                  loading={loader}
                  onClick={onSaveRecurrence}
                  text={t("Add")}
                />
              </Box>
            </Paper>
          </Fade>
        </ClickAwayListener>
      )}
    </Popper>
  );
};
export default RecurOverlay;
