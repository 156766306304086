import {
  eachMonthOfInterval,
  startOfMonth,
  endOfMonth,
  subMonths,
  format,
} from "date-fns";
import { Box, Button, Typography } from "@mui/material";
import { useMemo, useRef, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import _ from "underscore";

import {
  getStatisticsDataWithParams,
  thinScrollbarStyle,
  getTailwindColor,
  formatAmount,
  getCellInfo,
} from "../../../../Helper/data";
import useDebounce from "../../../../hooks/3-useDebounce/useDebounce";
import ComponentLoader from "../../../../components/ComponentLoader";
import CategoryChip from "../../../../components/CategoryChip";
import { Constant } from "../../../../Helper/Constant";
import { Color, Fonts } from "../../../../Helper";
import EmptyView from "./Component/EmptyView";

const OverBudgetIncomeExpense = ({
  total_key,
  count_key,
  category_type,
  widgetType,
}) => {
  const allData = useRef([]);

  //redux
  const state = useSelector((state) => state.globalSlice.state);
  const dataset = useSelector((state) => state.boardSlice?.dataSetData?.uuid);
  const stateByTitle = useSelector((state) => state.globalSlice.stateByTitle);
  const scenarioByTitle = useSelector(
    (state) => state.globalSlice.scenarioByTitle
  );
  const use_global_categories = useSelector(
    (state) => state.boardSlice?.dataSetData?.use_global_categories
  );
  const _selectionCategories = useSelector(
    (state) => state.categorySlice?.selectionCategories
  );
  const selectionCategories = useMemo(() => {
    return _selectionCategories?.filter((o1) =>
      use_global_categories ? !o1?.dataset : o1?.dataset === dataset
    );
  }, [_selectionCategories, dataset, use_global_categories]);
  const period_start_date = format(
    startOfMonth(subMonths(new Date(), 6)),
    "yyyy-MM-dd"
  );
  const period_end_date = format(endOfMonth(new Date()), "yyyy-MM-dd");

  //state
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const multiStatesIds = useMemo(() => {
    let array = [];
    state?.forEach((item) => {
      const _stateUuid = stateByTitle?.[item?.title]?.[0]?.uuid;
      if (
        !Constant.calculationExcludeStates2?.includes(item?.title) &&
        _stateUuid
      ) {
        array.push(_stateUuid);
      }
    });
    return array;
  }, [state, stateByTitle]);

  const BaseUuid = scenarioByTitle?.["Base"]?.[0]?.uuid;

  const WidgetData = useQuery({
    queryKey: [
      "transactions",
      {
        dataset: dataset,
        apiType: "over-budget",
        from_payment_date: period_start_date,
        to_payment_date: period_end_date,
        group_by: ["state", "category"],
        multiScenarioIds: [BaseUuid],
        multiStatesIds,
      },
    ],
    queryFn: ({ signal }) => {
      let param = {
        config: {
          signal,
        },
        type: "monthly",
        dataset: dataset,
        from_payment_date: period_start_date,
        to_payment_date: period_end_date,
        group_by: ["state", "category"],
        multiScenarioIds: [BaseUuid],
        multiStatesIds,
      };
      if (!use_global_categories) {
        param.category_dataset = dataset;
      } else {
        param.global_category = true;
      }
      const result = getStatisticsDataWithParams(param);

      if (result) {
        return result;
      }
    },
    refetchOnMount: true,
    enabled:
      !!dataset &&
      !!period_end_date &&
      !!period_start_date &&
      !!BaseUuid &&
      multiStatesIds?.length > 0,
  });

  const isFetching =
    WidgetData.isFetching || WidgetData.isLoading || !WidgetData.isSuccess;

  //life cycle method
  useDebounce(
    () => {
      if (
        period_start_date &&
        period_end_date &&
        WidgetData?.data &&
        !isFetching
      ) {
        setLoading(true);
        let dummyData = [];
        const dates = eachMonthOfInterval({
          start: startOfMonth(subMonths(new Date(), 2)),
          end: new Date(period_end_date),
        });
        const periodData = _.groupBy(
          WidgetData?.data?.results || [],
          ({ month }) => format(new Date(month), "yyyy-MM")
        );
        const currentMonth = format(new Date(), "yyyy-MM");
        const pastAllData = WidgetData?.data?.results?.filter(
          (o1) =>
            o1.month < currentMonth &&
            Constant.openPositionsStates.includes(o1.state) &&
            o1?.[count_key] > 0
        );

        selectionCategories?.forEach((element) => {
          if (
            element?.visible &&
            !element?.hasChildren &&
            category_type === element?.type
          ) {
            const categoryData = getCategoryInfo({
              currentMonth,
              periodData,
              dates,
              pastAllData,
              uuid: element?.uuid,
              title: element?.title,
              category: element?.uuid,
            });
            dummyData.push(categoryData);
          }
        });

        const allowedData = [];
        dummyData?.forEach((element) => {
          if (total_key === "inflow") {
            if (
              (element?.obj_1?.plannedValue !== 0 &&
                element?.obj_1?.compareValue !== 0 &&
                element?.obj_1?.percentageChange < 100) ||
              (element?.obj_2?.plannedValue !== 0 &&
                element?.obj_2?.compareValue !== 0 &&
                element?.obj_2?.percentageChange < 100) ||
              (element?.obj_3?.plannedValue !== 0 &&
                element?.obj_3?.compareValue !== 0 &&
                element?.obj_3?.percentageChange < 100)
            ) {
              allowedData.push(element);
            }
          } else {
            if (
              (element?.obj_1?.plannedValue !== 0 &&
                element?.obj_1?.compareValue !== 0 &&
                element?.obj_1?.percentageChange > 100) ||
              (element?.obj_2?.plannedValue !== 0 &&
                element?.obj_2?.compareValue !== 0 &&
                element?.obj_2?.percentageChange > 100) ||
              (element?.obj_3?.plannedValue !== 0 &&
                element?.obj_3?.compareValue !== 0 &&
                element?.obj_3?.percentageChange > 100)
            ) {
              allowedData.push(element);
            }
          }
        });

        if (total_key === "inflow") {
          let sortedObjects = allowedData.sort(
            (a, b) =>
              (a.obj_2?.percentageChange || 0) -
              (b.obj_2?.percentageChange || 0)
          );
          allData.current = sortedObjects;
        } else {
          let sortedObjects = allowedData.sort(
            (a, b) =>
              (b.obj_2?.percentageChange || 0) -
              (a.obj_2?.percentageChange || 0)
          );
          allData.current = sortedObjects;
        }
        const updatedData = allData.current.splice(0, 5);
        setTimeout(() => {
          setData(updatedData);
          setLoading(false);
        }, 250);
      }
    },
    300,
    [period_start_date, period_end_date, WidgetData?.data, isFetching],
    true
  );

  const getCategoryInfo = ({
    currentMonth,
    periodData,
    dates,
    pastAllData,
    uuid,
    title,
    category,
  }) => {
    let obj = {};
    dates?.forEach((element, index) => {
      const monthDate = format(new Date(element), "yyyy-MM");
      const isPastMonth = monthDate < currentMonth;
      const isCurrentMonth = monthDate === currentMonth;
      const isFutureMonth = monthDate > currentMonth;
      const monthData = periodData?.[monthDate] ?? [];

      let pastTotal = 0;
      const categoryData = monthData.filter((o1) => o1.category === category);
      const categoryPastData = pastAllData.filter(
        (o1) => o1.category === category
      );
      if (isCurrentMonth) {
        pastTotal = categoryPastData?.reduce(
          (a, b) => a + (b?.[total_key] || 0),
          0
        );
      }

      const categorizedCell = getCellInfo({
        data: categoryData,
        total_key: total_key,
        count_key: count_key,
        isPastMonth,
        isFutureMonth,
        isCurrentMonth,
        highlightedScenarios: [BaseUuid],
        pastTotal: pastTotal,
      });

      if (index === 0) {
        obj.uuid = uuid;
        obj.title = title;
        obj.category = category;
      }
      obj[`obj_${index + 1}`] = {
        monthDate,
        value: categorizedCell?.value,
        percentageChange:
          categorizedCell?.plannedValue === 0 ||
          categorizedCell?.compareValue === 0
            ? 0
            : categorizedCell?.percentageChange,
        plannedValue: categorizedCell?.plannedValue,
        compareValue: categorizedCell?.compareValue,
        isPastMonth,
        plannedCount: categorizedCell?.count?.plannedCount,
      };
    });
    return obj;
  };

  const onClickLink = () => {
    setData((prev) => [...prev, ...allData.current?.splice(0, 5)]);
  };

  //functions
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {isLoading || isFetching ? (
        <ComponentLoader
          loading
          hideNoDataPlaceholder
          height={"100%"}
          size={60}
        />
      ) : data?.length > 0 ? (
        <TableView
          data={data}
          total_key={total_key}
          allData={allData}
          onClickLink={onClickLink}
        />
      ) : (
        <EmptyView type={widgetType} />
      )}
    </div>
  );
};

export default OverBudgetIncomeExpense;

const TableView = ({ data, total_key, allData, onClickLink }) => {
  const { t } = useTranslation();
  const date1 = data?.[0]?.obj_1?.monthDate;

  const headerStyle = {
    flex: 1,
    fontSize: "0.8rem",
    color: Color.tailwind.grey[500],
    fontFamily: Fonts.Text,
    fontWeight: 600,
    paddingBlock: "0.4rem",
    textAlign: "left",
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        height: "100%",
        marginTop: "1rem",
        overflowY: "auto",
        ...thinScrollbarStyle,
      }}
    >
      {data?.length > 0 ? (
        <div
          style={{
            width: "99.8%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <span
            style={{ ...headerStyle, textAlign: "left", paddingLeft: "0.6rem" }}
          >
            {t("category")}
          </span>
          <span style={headerStyle}>
            {date1 ? format(new Date(date1), "MMMM yy") : ""}
          </span>
          <span style={headerStyle}>{t("Last Month")}</span>
          <span style={headerStyle}>{t("Current Month")}</span>
        </div>
      ) : null}
      {data?.map((item, index) => {
        return (
          <TableItem
            key={item?.uuid}
            item={item}
            total_key={total_key}
            isOddLine={index % 2 === 1}
          />
        );
      })}
      {allData.current?.length > 0 ? (
        <Button
          onClick={onClickLink}
          sx={{ width: "fit-content", alignSelf: "flex-end" }}
        >
          <Typography
            sx={{
              color: Color.themeColor,
              fontSize: "0.8rem",
              fontWeight: 500,
              textDecorationLine: "underline",
              textTransform: "capitalize",
            }}
          >
            {t("Show More")}
          </Typography>
        </Button>
      ) : null}
    </Box>
  );
};

const TableItem = ({ item, total_key, isOddLine }) => {
  return (
    <Box display={"flex"} alignItems={"center"} py="0.2rem">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flex: 1,
          height: "100%",
          backgroundColor: isOddLine ? Color.tailwind.slate[50] : "transparent",
        }}
      >
        <CategoryChip
          categoryId={item?.category}
          width="9.2rem"
          hideBG
          colorShade={1000}
          style={{ justifyContent: "flex-start" }}
          labelStyle={{ fontSize: "0.85rem", lineHeight: "0.9rem" }}
        />
      </div>
      <Cell item={item?.obj_1} total_key={total_key} isOddLine={isOddLine} />
      <Cell item={item?.obj_2} total_key={total_key} isOddLine={isOddLine} />
      <Cell item={item?.obj_3} total_key={total_key} isOddLine={isOddLine} />
    </Box>
  );
};

const Cell = ({ item, total_key, isOddLine }) => {
  const hidePercentage = item?.plannedCount === 0;
  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        paddingBlock: isOddLine ? "0.4rem" : 0,
        fontSize: "inherit",
        height: "100%",
        backgroundColor: isOddLine ? Color.tailwind.slate[50] : "transparent",
        color:
          item?.value >= 0
            ? Color.tailwind.green[500]
            : Color.tailwind.red[500],
      }}
    >
      <div
        style={{
          width: "fit-content",
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          fontSize: "0.8rem",
          fontWeight: (
            total_key === "inflow"
              ? item?.percentageChange < 50
              : item?.percentageChange > 150
          )
            ? 700
            : 500,
        }}
      >
        {formatAmount({
          amount: item?.value,
          hidePrefix: true,
        })}
      </div>

      <div
        style={{
          flex: 1,
          width: "fit-content",
          paddingLeft: "6px",
          display: "flex",
          alignItems: "center",
        }}
      >
        {item?.value !== 0 && !hidePercentage ? (
          <PercentageChangeChip item={item} total_key={total_key} />
        ) : null}
      </div>
    </div>
  );
};

const PercentageChangeChip = ({ item, total_key = "inflow" }) => {
  let color = "slate";
  let bgShade = 100;
  let colorShade = 500;
  const percentageChange = item?.percentageChange;
  let change =
    !isNaN(percentageChange) && percentageChange ? Number(percentageChange) : 0;
  if (total_key === "inflow") {
    if (item?.compareValue >= item?.plannedValue) {
      color = "emerald";
      bgShade = 100;
      colorShade = 600;
    }
    if (item?.compareValue < item?.plannedValue) {
      if (item.isPastMonth) {
        color = "orange";
        bgShade = 100;
        colorShade = 600;
      } else {
        color = "slate";
        bgShade = 100;
        colorShade = 500;
      }
    }
  }
  if (total_key === "outflow") {
    if (item?.compareValue < item?.plannedValue) {
      color = "red";
      bgShade = 100;
      colorShade = 600;
    }
    if (item?.compareValue >= item?.plannedValue) {
      if (item.isPastMonth) {
        color = "lime";
        bgShade = 100;
        colorShade = 600;
      } else {
        color = "slate";
        bgShade = 100;
        colorShade = 500;
      }
    }
  }

  if (percentageChange === 0) {
    color = "slate";
    bgShade = 100;
    colorShade = 500;
  }

  const chipStyle = {
    borderRadius: 4,
    backgroundColor: getTailwindColor(
      color,
      (
        total_key === "inflow"
          ? item?.percentageChange < 50
          : item?.percentageChange > 150
      )
        ? 500
        : bgShade
    ),
    color: (
      total_key === "inflow"
        ? item?.percentageChange < 50
        : item?.percentageChange > 150
    )
      ? Color.white
      : getTailwindColor(color, colorShade),
    width: "fit-content",
    minWidth: "40px",
    fontWeight: (
      total_key === "inflow"
        ? item?.percentageChange < 50
        : item?.percentageChange > 150
    )
      ? 700
      : 600,
    height: "100%",
    fontSize: change?.toString()?.length > 3 ? "8px" : "9px",
    fontFamily: Fonts.Text,
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    paddingInline: "4px",
    paddingBlock: "3px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  };

  return <div style={chipStyle}>{change}%</div>;
};
