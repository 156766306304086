import { Box, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Color } from "../../Helper/Color";

export const Card = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.color.description,
  backgroundColor: Color.activeSheetBg,
  maxHeight: "fit-content",
  flexGrow: 1,
  width: "63%",
  minHeight: "2.5rem",
  borderRadius: 5,
}));

export const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "center",
  display: "flex",
  color: theme.palette.color.description,
  borderRadius: 0,
  minHeight: "2.5rem",
}));
