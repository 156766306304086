import {
  eachQuarterOfInterval,
  eachMonthOfInterval,
  eachYearOfInterval,
  format,
} from "date-fns";
import { useEffect, useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { subMonths } from "date-fns";
import _ from "underscore";

import {
  getStatisticsDataWithParams,
  getTailwindColor,
} from "../../../../Helper/data";
import BalanceView from "../../../../components/Header/DatasetHeader/BalanceView";
import useDebounce from "../../../../hooks/3-useDebounce/useDebounce";
import ComponentLoader from "../../../../components/ComponentLoader";
import StackCharts from "../../../../components/Charts/StackCharts";
import EmptyView from "./Component/EmptyView";

const BurnRateWidget = ({ widgetType, widget }) => {
  //state
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(false);

  return (
    <div style={{ height: "90%", width: "100%" }}>
      <LogicFunctions
        widget={widget}
        setData={setData}
        widgetType={widgetType}
        setIsFetching={setIsFetching}
        setLoading={setLoading}
      />
      {isLoading || isFetching ? (
        <ComponentLoader
          loading
          hideNoDataPlaceholder
          height={"100%"}
          size={60}
        />
      ) : data?.length > 0 ? (
        <div style={{ height: "100%", width: "100%" }}>
          <StackCharts
            data={data}
            absoluteValue
            barsData={[
              {
                key: "Net Burn",
                dataKey: "net_burn",
                stackId: "a",
                fill: getTailwindColor("red", 400),
                color: "red",
                shade: 500,
                position: 2,
                barSize: 20,
                absoluteValue: true,
              },
              {
                key: "Gross Burn",
                dataKey: "gross_burn",
                stackId: "a",
                fill: getTailwindColor("red", 100),
                color: "red",
                shade: 200,
                position: 1,
                barSize: 20,
                absoluteValue: true,
              },
            ]}
            linesData={[
              {
                key: "Runway",
                dataKey: "runway",
                stroke: getTailwindColor("purple", 700),
                strokeWidth: 2,
                color: "purple",
                position: 3,
              },
              {
                key: "Limit",
                dataKey: "limit",
                stroke: getTailwindColor("red", 700),
                strokeWidth: 1,
                color: "red",
                strokeDasharray: "2 2",
                position: 4,
                dot: false,
                absoluteValue: true,
              },
              {
                // hideTooltip: true,
                key: "Trend",
                dataKey: "trend",
                type: "linear",
                stroke: getTailwindColor("purple", 700),
                strokeWidth: 2,
                color: "purple",
                position: 5,
                strokeDasharray: "6 6",
                opacity: 0.4,
              },
            ]}
          />
        </div>
      ) : (
        <EmptyView type={widgetType} />
      )}
    </div>
  );
};

export default BurnRateWidget;

const LogicFunctions = ({
  setData,
  setIsFetching,
  setLoading,
  widget,
  widgetType,
  type,
}) => {
  const start_date = widget?.start_date;
  const end_date = widget?.end_date;

  //redux
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);
  const selectionCategories = useSelector(
    (state) => state.categorySlice?.selectionCategories
  );
  const datasetAccountList = useSelector(
    (state) => state.boardSlice.datasetAccountList
  );

  const WidgetDataBalance = useQuery({
    queryKey: [
      "transactions",
      {
        dataset: dataSetData?.uuid,
        apiType: "period_data_balance",
        widget: {
          start_date: start_date,
          end_date: end_date,
        },
        widgetType,
      },
    ],
    queryFn: ({ signal }) => {
      let param = {
        type: "transaction_monthly_chart",
        dataset: dataSetData?.uuid,
        from_date: subMonths(new Date(start_date), 7),
        to_date: end_date,
      };
      if (!dataSetData.use_global_categories) {
        param.category_dataset = dataSetData?.uuid;
      } else {
        param.global_category = true;
      }
      const result = getStatisticsDataWithParams(param);

      if (result) {
        return result;
      }
    },
    backgroundFetch: true,
    enabled: !!start_date && !!end_date && !!dataSetData?.uuid,
    priority: 3,
  });

  const WidgetData = useQuery({
    queryKey: [
      "transactions",
      {
        dataset: dataSetData?.uuid,
        apiType: "period_data",
        widget: {
          start_date: widget?.start_date,
          end_date: widget?.end_date,
        },
        widgetType,
      },
    ],
    queryFn: ({ signal }) => {
      let param = {
        config: {
          signal,
        },
        type: "monthly",
        dataset: dataSetData?.uuid,
        // from_payment_date: start_date,
        to_payment_date: end_date,
        filter: "&type=1",
      };
      if (!dataSetData.use_global_categories) {
        param.category_dataset = dataSetData?.uuid;
      } else {
        param.global_category = true;
      }
      const result = getStatisticsDataWithParams(param);

      if (result) {
        return result;
      }
    },
    backgroundFetch: true,
    enabled: !!start_date && !!end_date && !!dataSetData?.uuid,
    priority: 3,
  });

  const RangeType = "Monthly";
  let formateString = "yyyy-MM-dd";
  if (RangeType === "Monthly") {
    formateString = "MMM-yyyy";
  }
  if (RangeType === "Quarterly") {
    formateString = "QQQ, yyyy";
  }
  if (RangeType === "Annually") {
    formateString = "yyyy";
  }

  const booked_balancesByDate = useMemo(() => {
    const booked_balances = WidgetDataBalance?.data?.booked_balances || [];
    return _.groupBy(booked_balances, ({ month }) =>
      format(new Date(month), formateString)
    );
  }, [WidgetDataBalance?.data?.booked_balances, formateString]);

  useEffect(() => {
    setIsFetching(WidgetData?.isFetching || WidgetDataBalance?.isFetching);
    if (WidgetData?.isFetching || WidgetDataBalance?.isFetching) {
      setLoading(true);
    }
  }, [WidgetData?.isFetching || WidgetDataBalance?.isFetching]);

  useDebounce(
    () => {
      if (
        !WidgetData?.isFetching &&
        !WidgetDataBalance?.isFetching &&
        WidgetDataBalance?.data &&
        WidgetData?.data
      ) {
        let dummyData = [];
        setLoading(true);
        let resultMain = [];
        if (RangeType === "Monthly") {
          resultMain = eachMonthOfInterval({
            start: new Date(start_date),
            end: new Date(end_date),
          });
        }
        if (RangeType === "Quarterly") {
          resultMain = eachQuarterOfInterval({
            start: new Date(start_date),
            end: new Date(end_date),
          });
        }
        if (RangeType === "Annually") {
          resultMain = eachYearOfInterval({
            start: new Date(start_date),
            end: new Date(end_date),
          });
        }
        let periodTranData = WidgetData?.data?.results || [];

        const periodData = _.groupBy(periodTranData, ({ month }) =>
          format(new Date(month), formateString)
        );

        let totalOF = 0;
        const array = datasetAccountList?.filter(
          (o1) =>
            o1.account?.show_limit &&
            o1.account?.datasets?.includes(dataSetData?.uuid) &&
            o1.active
        );
        const Limit = array?.map((o1) => o1.account);
        Limit?.forEach((element) => {
          totalOF = parseInt(element?.limit ?? 0) + parseInt(totalOF);
        });
        let balanceData = {};
        let mean = null;
        if (periodTranData?.length > 0) {
          resultMain?.forEach((element) => {
            const date = format(new Date(element), formateString);
            const item = periodData?.[date] ?? [];
            const isCurrent =
              format(new Date(date), formateString) ===
              format(new Date(), formateString);
            const isFuture = new Date(date) > new Date();
            const Balance =
              booked_balancesByDate?.[date]?.[0]?.booked_balance ?? 0;

            const Outflow = item?.reduce(
              (total, entry) =>
                parseFloat(total) + parseFloat(entry?.outflow || 0),
              0
            );
            const Inflow = item?.reduce(
              (total, entry) =>
                parseFloat(total) + parseFloat(entry?.inflow ?? 0),
              0
            );
            const gross_burn = Math.abs(Outflow ?? 0);
            const _net_burn = gross_burn - (Inflow ?? 0);
            const net_burn = _net_burn >= 0 ? _net_burn : 0;
            const runway = isFuture ? null : Balance;
            if (!isFuture) {
              balanceData[date] = Balance;
            }
            let trend = null;

            if (isCurrent) {
              trend = Balance;
              const startBalanceDate = format(
                subMonths(new Date(), 7),
                formateString
              );
              const endBalanceDate = format(
                subMonths(new Date(), 1),
                formateString
              );

              const startBalance =
                booked_balancesByDate?.[startBalanceDate]?.[0]
                  ?.booked_balance || 0;
              const endBalance =
                booked_balancesByDate?.[endBalanceDate]?.[0]?.booked_balance ||
                0;
              console.log("Balance:", startBalance, endBalance);
              mean = (endBalance - startBalance) / 6;
              console.log("🚀 // mean:", mean);
            }
            if (isFuture) {
              const prevDate = format(
                subMonths(new Date(date), 1),
                formateString
              );
              trend = (balanceData?.[prevDate] || 0) + (mean || 0);
              console.log("🚀 // trend:", date, trend);
              balanceData[date] = trend;
            }

            dummyData.push({
              due_date: date,
              gross_burn: -gross_burn,
              net_burn: -net_burn,
              runway,
              trend,
              limit: -totalOF,
            });
          });
        }

        setTimeout(() => {
          setLoading(false);
          setData(dummyData);
        }, 250);
      }
    },
    300,
    [
      WidgetData?.data,
      WidgetDataBalance?.data,
      WidgetDataBalance?.isFetching,
      WidgetData?.isFetching,
      booked_balancesByDate,
      type,
      selectionCategories,
      datasetAccountList,
      start_date,
      end_date,
    ],
    true
  );

  return <BalanceView hide />;
};
