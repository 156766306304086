import React, { useCallback } from "react";
import { Chip, Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import { Fonts } from "../Helper";
import initialData, { getTailwindColor } from "../Helper/data";
import { useTranslation } from "react-i18next";
import StateDotView from "../Pages/Datasets/Kanban/Card/StateDotView";

const StateChip = ({
  title = null,
  hideIcon = false,
  hideBorder = false,
  width = "8rem",
  height = "2rem",
  fontSize = "0.7rem",
  defaultShade = 50,
  borderRadius = 2,
  isSelected = false,
  disabled = false,
  showClickCursor = false,
  isEmployeeState = false,
  onClick,
  sx,
}) => {
  const { t } = useTranslation();
  const stateByTitle = useSelector((state) => state.globalSlice.stateByTitle);
  const item = stateByTitle?.[title]?.[0];
  const cursor = !!onClick || showClickCursor;

  const getShade = useCallback(() => {
    return initialData.defaultState?.[title]?.bgShade || defaultShade;
  }, [defaultShade, title]);
  const shade = getShade();

  const onClickChip = () => {
    onClick && onClick(item);
  };

  return (
    <Tooltip
      key={item?.uuid}
      title={t(`${item?.title}_tooltip`)}
      followCursor
      placement="top"
    >
      <Chip
        onClick={onClickChip}
        size="small"
        label={isEmployeeState ? t(`Employee-${item?.title}`) : t(item?.title)}
        disabled={disabled}
        icon={!hideIcon ? <StateDotView title={item?.title} /> : undefined}
        sx={{
          width: width,
          height: height,
          fontWeight: 500,
          fontFamily: Fonts.Text,
          borderRadius: borderRadius,
          display: "inline-flex",
          alignItems: "center",
          color: getTailwindColor(item?.color, 700),
          backgroundColor: getTailwindColor(
            item?.color,
            isSelected ? (shade === 50 ? shade + 250 : shade + 200) : shade
          ),
          cursor: cursor ? "pointer" : "auto",
          border: !hideBorder
            ? `3px solid ${
                isSelected ? getTailwindColor(item?.color, 600) : "transparent"
              }`
            : "none",
          "& .MuiChip-label": {
            width: "75%",
            fontSize: fontSize,
            textAlign: "center",
            lineHeight: "normal",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            px: hideIcon ? 0 : "0.5rem",
          },
          "&.Mui-disabled": {
            opacity: 0.5,
            cursor: "disable",
          },
          "&:hover": {
            backgroundColor: getTailwindColor(
              item?.color,
              shade === 50 ? shade + 250 : shade + 200
            ),
          },
          ...sx,
        }}
      />
    </Tooltip>
  );
};
export default React.memo(StateChip);
