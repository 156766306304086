import { Box, Typography, TextField, CircularProgress } from "@mui/material";
import Button from "@mui/lab/LoadingButton";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Color, Fonts } from "../../../Helper";
import axios from "axios";
import { useSnackbar } from "notistack";
import { useTheme } from "@mui/styles";

const OtpVerification = ({ values, getProfile, is_readyLoader }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const otpInputRefs = useRef([]);

  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("");
  const [btnLoader, setBtnLoader] = useState(false);

  const handleOtpChange = (event, index) => {
    if (otpError) setOtpError("");
    const value = event.target.value;
    if (otp?.length < 6) {
      const updatedOtp = [...otp];
      if (value === "" || !isNaN(value)) {
        updatedOtp[index] = value;
        setOtp(updatedOtp.slice(0, 6).join(""));

        if (value !== "" && index < otpInputRefs.current.length - 1) {
          otpInputRefs.current[index + 1].focus();
        }
      } else if (value === "" && index > 0) {
        updatedOtp[index] = value;
        setOtp(updatedOtp.slice(0, 6).join(""));
        otpInputRefs.current[index - 1].focus();
      }
    }
  };

  const handleKeyDown = (event, index) => {
    if (event.key === "Backspace") {
      if (otpError) setOtpError("");
      event.preventDefault(); // Prevent browser navigation
      setOtp((prevOtp) => {
        const updatedOtp = [...prevOtp];
        updatedOtp[index] = "";
        return updatedOtp.slice(0, 6).join("");
      });
      if (index > 0) {
        otpInputRefs.current[index - 1].focus();
      }
    } else if (event.key === "ArrowRight" && index < 5) {
      otpInputRefs.current[index + 1].focus();
    } else if (event.key === "ArrowLeft" && index > 0) {
      otpInputRefs.current[index - 1].focus();
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (otp?.length !== 6) {
      setOtpError(t("invalid_otp_msg"));
    } else {
      setBtnLoader(true);
      axios
        .post(`${process.env.REACT_APP_API_URL}/auth/login/`, {
          email: values?.email,
          password: values?.password,
          otp_token: otp,
        })
        .then((res) => {
          localStorage.setItem("token", res?.data.access);
          localStorage.setItem("LoggedInUser", values?.email);

          getProfile(res);
          setTimeout(() => {
            setBtnLoader(false);
          }, 2000);
        })
        .catch((err) => {
          setBtnLoader(false);
          enqueueSnackbar(t("login_credenitals_wrong_msg"), {
            variant: "error",
            autoHideDuration: 4000,
          });
        });
    }
  };

  const handleOtpContainerPaste = (event) => {
    if (otpError) setOtpError("");
    const pastedValue = event.clipboardData.getData("text");
    const updatedOtp = pastedValue
      .slice(0, 6)
      .split("")
      .map((value, index) =>
        value === "" || !isNaN(value) ? value : otp[index] || ""
      );

    setOtp(updatedOtp.join("").slice(0, 6));
    otpInputRefs.current.forEach((ref, index) => {
      ref.value = updatedOtp[index] || "";
    });
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      padding="2rem"
    >
      <Typography
        variant="h2"
        component="h2"
        style={{
          marginBottom: "2rem",
          color: Color.themeColor2,
          fontWeight: 700,
          fontSize: "2rem",
        }}
      >
        {t("OTP Verification")}
      </Typography>
      <Typography
        variant="body1"
        style={{ marginBottom: "1rem", textAlign: "center", color: "#757575" }}
      >
        {t("An OTP has been sent to your registered email.")}
      </Typography>
      <form onSubmit={handleSubmit}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          marginBottom="1rem"
          onPaste={handleOtpContainerPaste}
        >
          {Array.from({ length: 6 }, (_, index) => (
            <TextField
              key={index}
              type="text"
              maxLength="1"
              value={otp[index] || ""}
              onChange={(event) => handleOtpChange(event, index)}
              onKeyDown={(event) => handleKeyDown(event, index)}
              variant="outlined"
              size="small"
              autoComplete={`new-password${index}`}
              inputRef={(ref) => (otpInputRefs.current[index] = ref)}
              sx={{ mr: "0.5rem" }}
              inputProps={{
                inputMode: "numeric",
                pattern: "[0-9]*",
                style: {
                  textAlign: "center",
                  mx: "0.5rem",
                  width: "3rem",
                  height: "3rem",
                  border: "none",
                  borderBottom: `2px solid ${Color.tailwind.purple[500]}`,
                  fontSize: "1.5rem",
                  fontWeight: 700,
                  padding: 0,
                  color: "inherit",
                },
              }}
            />
          ))}
        </Box>
        <Typography variant="body2" color="error" height="1rem">
          {otpError}
        </Typography>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          disabled={is_readyLoader}
          loading={btnLoader}
          loadingIndicator="Loading…"
          sx={{
            mt: "2rem",
            fontFamily: Fonts.Text,
          }}
        >
          {t("Verify")}
        </Button>
      </form>
      {is_readyLoader && (
        <Box
          sx={{
            color: Color?.themeColor2,
            flexDirection: "row",
            display: "inline-flex",
            alignItems: "center",
            my: "1rem",
          }}
        >
          <CircularProgress
            color="inherit"
            size={23}
            sx={{
              color: Color.themeColor2,
              mr: 1,
            }}
          />
          <Typography
            sx={{
              ml: 1,
              fontFamily: Fonts.Text,
              [theme.breakpoints.down("s1536")]: { fontSize: "0.8rem" },
            }}
          >
            {t("user_account_ready_status_message")}
          </Typography>
        </Box>
      )}
      <ResendOtpView values={values} />
    </Box>
  );
};

export default OtpVerification;

const ResendOtpView = ({ values }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [resendTimer, setResendTimer] = useState(60);
  const [isResendDisabled, setIsResendDisabled] = useState(false);

  //lifeCycle
  useEffect(() => {
    let interval;
    if (isResendDisabled) {
      interval = setInterval(() => {
        setResendTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [isResendDisabled]);

  useEffect(() => {
    if (resendTimer <= 0) {
      setIsResendDisabled(false);
      setResendTimer(60);
    }
  }, [resendTimer]);

  //functions
  const handleResendOTP = () => {
    setIsResendDisabled(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}/auth/login/`, {
        email: values?.email,
        password: values?.password,
        resend_token: true,
      })
      .then((res) => {})
      .catch((error) => {
        if (
          error?.response?.status === 400 &&
          error?.response?.data?.otp_token?.[0]
        ) {
          enqueueSnackbar(t("resend_otp_successfully"), {
            variant: "success",
            autoHideDuration: 4000,
          });
        }
      });
  };

  return (
    <Button
      variant="text"
      color="primary"
      onClick={handleResendOTP}
      style={{ fontWeight: 600, textTransform: "initial", mt: "2rem" }}
      disabled={isResendDisabled}
    >
      {isResendDisabled ? `Resend OTP in ${resendTimer}s` : t("Resend OTP")}
    </Button>
  );
};
