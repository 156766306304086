import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import AttentionOverlay from "./AttentionOverlay";

const RulesCommonView = forwardRef((props, _ref) => {
  const data = useRef(null);

  //state
  const [open, setOpen] = useState(false);

  useImperativeHandle(_ref, () => ({
    onOpen,
  }));

  //functions
  const onOpen = (_data) => {
    data.current = _data;
    setOpen(true);
  };
  if (!open) {
    return null;
  }
  return (
    <AttentionOverlay
      open={open}
      setOpen={setOpen}
      data={data}
      title="Attention"
      message="category_rule_already_available"
    />
  );
});

export default RulesCommonView;
