import { useEffect, useState, useMemo } from "react";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import {
  Alert,
  AlertTitle,
  Box,
  Card,
  TextField,
  useTheme,
} from "@mui/material";

import APICall from "../../APICall";
import EndPoints from "../../APICall/EndPoints";
import { Color, Images } from "../../Helper";
import { setIsOpenSnackbar } from "../../store/slices/common";
import TailwindButton from "../../components/Overlay/TailwindButton";
import { useTranslation } from "react-i18next";

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const PassResetFormScreen = () => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let query = useQuery();
  const location = useLocation();
  let token = query.get("token");
  //state
  const [form, setForm] = useState({ new: "", confirm: "" });
  const [error, setError] = useState(null);
  const [isShowForm, setIsShowForm] = useState(null);

  //life cycle method
  useEffect(() => {
    if (token && !location?.state?.byPassToken) {
      validatePassToken({ token });
    }
    if (location?.state?.byPassToken && !token) {
      setIsShowForm("form");
    }
  }, [location?.state?.byPassToken, token]);

  //api
  const validatePassToken = async (obj) => {
    await APICall("post", EndPoints.validate_token, obj).then((response) => {
      if (response.status === 200 && response.data.status === "OK") {
        setIsShowForm("form");
      } else {
        dispatch(setIsOpenSnackbar(false));
        setIsShowForm("error");
      }
    });
  };

  const confirmPassApi = async (obj) => {
    await APICall("post", EndPoints.pass_confirm, obj, {
      doNotCatchRespond: true,
    }).then((response) => {
      if (response?.status === 200 && response?.data?.status === "OK") {
        enqueueSnackbar(t("Password Changed Successfully"), {
          variant: "success",
          autoHideDuration: 4000,
        });
        navigate("/login", { replace: true });
      }
      if (response?.status === 400 && response?.data?.password?.length > 0) {
        enqueueSnackbar(response?.data?.password?.[0], {
          variant: "error",
          autoHideDuration: 4000,
        });
      }
    });
  };

  //functions
  const validatePassword = (password) => {
    const uppercaseRegExp = /(?=.*?[A-Z])/;
    const lowercaseRegExp = /(?=.*?[a-z])/;
    const digitsRegExp = /(?=.*?[0-9])/;
    const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
    const minLengthRegExp = /.{8,}/;
    const passwordLength = password?.length;
    const uppercasePassword = uppercaseRegExp.test(password);
    const lowercasePassword = lowercaseRegExp.test(password);
    const digitsPassword = digitsRegExp.test(password);
    const specialCharPassword = specialCharRegExp.test(password);
    const minLengthPassword = minLengthRegExp.test(password);
    let errMsg = "";
    if (passwordLength === 0) {
      errMsg = "Password is empty";
    } else if (!uppercasePassword) {
      errMsg = "At least one Uppercase";
    } else if (!lowercasePassword) {
      errMsg = "At least one Lowercase";
    } else if (!digitsPassword) {
      errMsg = "At least one digit";
    } else if (!specialCharPassword) {
      errMsg = "At least one Special Characters";
    } else if (!minLengthPassword) {
      errMsg = "At least minumum 8 characters";
    } else {
      errMsg = "";
    }
    setError({
      ...error,
      new: errMsg,
    });
    if (errMsg) {
      return false;
    }
    return true;
  };

  const onSubmit = () => {
    if (validatePassword(form?.new)) {
      if (form.new !== form.confirm) {
        setError({ ...error, confirm: "Confirm password is not matched" });
        return;
      }
      confirmPassApi({ password: form?.confirm, token });
    }
  };

  const handleChange = (event) => {
    if (error) setError(null);
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: Color.appThemeBg,
      }}
    >
      <Card
        sx={{
          width: "50rem",
          height: "28rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          borderRadius: theme.borderRadius.main,
        }}
      >
        <img
          src={Images.Svg.AppLogoTheme}
          height={50}
          alt="App_Logo"
          style={{ position: "absolute", top: "2rem" }}
        />
        <Alert
          severity="info"
          sx={{
            my: "2rem",
            width: "90%",
          }}
        >
          {t("reset_pass_form_info")}
        </Alert>

        {isShowForm === "form" ? (
          <>
            <TextField
              id="outlined-name"
              value={form.new}
              label="New Password"
              size="small"
              name="new"
              type="password"
              onChange={handleChange}
              error={Boolean(error?.new)}
              helperText={error?.new}
              sx={{
                width: "90%",
                height: 80,
                "& .MuiOutlinedInput-notchedOutline": {
                  borderRadius: theme.borderRadius.main,
                },
              }}
            />
            <TextField
              id="outlined-name"
              value={form.confirm}
              label="Confirm Password"
              name="confirm"
              size="small"
              type="password"
              onChange={handleChange}
              error={Boolean(error?.confirm)}
              helperText={error?.confirm}
              sx={{
                width: "90%",
                height: 80,
                "& .MuiOutlinedInput-notchedOutline": {
                  borderRadius: theme.borderRadius.main,
                },
              }}
            />
            <Box sx={{ position: "absolute", bottom: "2rem", right: "2.4rem" }}>
              <TailwindButton text={"Submit"} onClick={onSubmit} />
            </Box>
          </>
        ) : (
          isShowForm === "error" && (
            <Alert severity="error">
              <AlertTitle>{t("Error")}</AlertTitle>
              {t("your password reset link has expired")}
            </Alert>
          )
        )}
      </Card>
    </Box>
  );
};
export default PassResetFormScreen;
