import { forwardRef, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/styles";
import { Stack } from "@mui/material";

import CustomModal from "../../Model/CustomModal";

const FeatureRequestOverlay = forwardRef((props, _ref) => {
  const { t } = useTranslation();
  const theme = useTheme();

  //state
  const [isOpen, setOpen] = useState(false);

  //functions
  const close = () => {
    setOpen(false);
  };

  const open = () => {
    setOpen(true);
  };

  useImperativeHandle(_ref, () => ({
    close,
    open,
  }));

  return (
    <CustomModal
      hideAction
      //   heading={t("feature_request_overlay_heading")}
      open={isOpen}
      onClose={close}
    >
      <Stack
        sx={{
          width: "80vw",
          minHeight: "60vh",
          maxHeight: "80vh",
          overflowY: "auto",
          p: "1rem",
          ...theme.thinScrollBar,
        }}
      >
        feature_request_overlay
      </Stack>
    </CustomModal>
  );
});

export default FeatureRequestOverlay;
