/* eslint-disable import/no-webpack-loader-syntax */
import { useEffect, useState, useMemo, useDeferredValue } from "react";
import Worker from "worker-loader!../../../../workers/worker.js";
import { useIsFetching, useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { unstable_runWithPriority } from "scheduler";

import {
  setAccountBalanceUpdateDate,
  setMonthlyChartData,
  setChartLineKeys,
} from "../../../../store/slices/board";
import {
  getStatisticsDataWithParams,
  getDataSetColumnCount,
} from "../../../../Helper/data";
import { setIsMonthChartLoading } from "../../../../store/slices/appmain";
import { getPlanningChartQueryKey } from "../../../../Helper/queryKeys";
import useDebounce from "../../../../hooks/3-useDebounce/useDebounce";
import { ThemeSpinner } from "../../../../components/ThemeSpinLoader";
// import { generateMonthlyChart } from "../../../../workers/worker";
import { updateFilters } from "../../../../store/slices/global";
import useStatusHook from "../../../../hooks/useStatusHook";
import { Color } from "../../../../Helper";
import theme from "../../../../theme";

const CommonView = () => {
  const dispatch = useDispatch();
  //redux
  const advanceVat = useSelector((state) => state.globalSlice.advanceVat);
  const scenario = useSelector((state) => state.globalSlice.scenario);
  const scenarioById = useSelector((state) => state.globalSlice.scenarioById);
  const state = useSelector((state) => state.globalSlice?.state);
  const datasetAccountList = useSelector(
    (state) => state.boardSlice.datasetAccountList
  );
  const isAllHeaderApiFetched = useSelector(
    (state) => state.commonSlice.isAllHeaderApiFetched
  );
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);
  const planningChartData = useSelector((state) => state.boardSlice?.chartData);
  const isChartExpanded = useSelector(
    (state) => state.chartSlice?.isChartExpanded
  );
  const isFooterExpanded = useSelector(
    (state) => state.chartSlice?.isFooterExpanded
  );
  const chart_keys = useSelector(
    (state) => state.globalSlice?.appliedFilterlist?.kanban?.chart_keys
  );
  const selectionCategoriesByID = useSelector(
    (state) => state.categorySlice?.selectionCategoriesByID
  );
  const isMonthChartLoading = useSelector(
    (state) => state.appSlice.isMonthChartLoading
  );
  const isFirstLoginOverlayOpen = useSelector(
    (state) => state.globalSlice.isFirstLoginOverlayOpen
  );
  const isAccountSelectOverlayOpen = useSelector(
    (state) => state.globalSlice.isAccountSelectOverlayOpen
  );
  const tableType = useSelector((state) => state.boardSlice?.tableType);
  const updateChart = useSelector((state) => state.boardSlice.updateChart);
  const todayLiq = useSelector((state) => state.boardSlice?.todayLiq);

  const _selectionCategories = useSelector(
    (state) => state.categorySlice?.selectionCategories
  );

  const selectionCategories = useMemo(() => {
    return _selectionCategories?.filter((o1) =>
      dataSetData?.use_global_categories
        ? !o1?.dataset
        : o1?.dataset === dataSetData?.uuid
    );
  }, [
    _selectionCategories,
    dataSetData?.uuid,
    dataSetData?.use_global_categories,
  ]);
  const appliedFilterlist = {
    kanban: {
      chart_keys: chart_keys,
    },
  };

  const isKanbanGroupFetching = useIsFetching({
    queryKey: [
      "transactions",
      {
        dataset: dataSetData?.uuid,
        apiType: "monthly",
        start_date: null,
        end_date: null,
      },
    ],
    exect: true,
  });
  const past_table_data = useStatusHook("past_table_data");

  //state
  const [webWorkerMonthly, setWebWorker] = useState(null);
  const [webWorkerDaily, setWebWorkerDaily] = useState(null);

  const allowMonthFetch = isFooterExpanded || isChartExpanded;

  const monthly_chart_query_key_obj = useDeferredValue({
    tableType,
    default_vat_pay_months: 1,
    from_date: dataSetData?.start_date,
    to_date: dataSetData?.end_date,
    dataset: dataSetData?.uuid,
  });
  const monthly_chart_query_key = getPlanningChartQueryKey(
    monthly_chart_query_key_obj
  );
  const Limit = useMemo(() => {
    const array = datasetAccountList?.filter(
      (o1) =>
        o1.account?.show_limit &&
        o1.account?.datasets?.includes(dataSetData?.uuid) &&
        o1.active
    );
    return array?.map((o1) => o1.account);
  }, [datasetAccountList, dataSetData?.uuid]);

  const column_count = useMemo(() => {
    return getDataSetColumnCount({
      tableType,
      start_date: dataSetData?.start_date,
      end_date: dataSetData?.end_date,
    });
  }, [dataSetData?.end_date, dataSetData?.start_date, tableType]);

  //query
  const transaction_monthly_chart = useQuery({
    queryKey: monthly_chart_query_key,
    queryFn: () => {
      let param = {
        type: monthly_chart_query_key?.[1]?.type,
        dataset: monthly_chart_query_key?.[1]?.dataset,
        from_date: monthly_chart_query_key?.[1]?.from_date,
        to_date: monthly_chart_query_key?.[1]?.to_date,
        subMonthNumber: monthly_chart_query_key?.[1]?.subMonthNumber,
      };
      if (!dataSetData.use_global_categories) {
        param.category_dataset = dataSetData?.uuid;
      } else {
        param.global_category = true;
      }
      const result = getStatisticsDataWithParams(param);

      if (result) {
        return result;
      }
    },
    backgroundFetch: true,
    serialize: (data) => {
      const serializedChartData = JSON.stringify(data);
      return serializedChartData;
    },
    deserialize: (data) => {
      const deserializedChartData = JSON.parse(data);
      return deserializedChartData;
    },
    priority: 1,
    enabled:
      !!isAllHeaderApiFetched &&
      !isAccountSelectOverlayOpen &&
      !isFirstLoginOverlayOpen &&
      !!allowMonthFetch &&
      !!monthly_chart_query_key_obj?.from_date &&
      !!monthly_chart_query_key_obj?.to_date &&
      !!monthly_chart_query_key_obj?.dataset,
  });

  const fetching_transaction_monthly_chart =
    transaction_monthly_chart.isFetching ||
    transaction_monthly_chart.isLoading ||
    !transaction_monthly_chart.isSuccess;

  const isChartInProgress =
    past_table_data?.isFetching ||
    fetching_transaction_monthly_chart ||
    isMonthChartLoading;

  //life cycle method
  useEffect(() => {
    if (!webWorkerMonthly) setWebWorker(new Worker());
    if (!webWorkerDaily) setWebWorkerDaily(new Worker());
    return () => {
      webWorkerMonthly?.terminate();
      webWorkerDaily?.terminate();
    };
  }, []);

  //updatePlanningChart
  useDebounce(
    () => {
      if (
        !!allowMonthFetch &&
        !!webWorkerMonthly &&
        !isFirstLoginOverlayOpen &&
        !isKanbanGroupFetching &&
        isAllHeaderApiFetched &&
        !isAccountSelectOverlayOpen &&
        !fetching_transaction_monthly_chart &&
        transaction_monthly_chart?.data &&
        dataSetData?.uuid
      ) {
        unstable_runWithPriority(4, () => {
          updatePlanningChart();
        });
      }
    },
    500,
    [
      isKanbanGroupFetching,
      isAccountSelectOverlayOpen,
      fetching_transaction_monthly_chart,
      isAllHeaderApiFetched,
      transaction_monthly_chart?.data,
      past_table_data?.data,
      appliedFilterlist?.kanban?.selectedScenarios,
      appliedFilterlist?.kanban?.scenarioValue,
      Limit,
      allowMonthFetch,
      webWorkerMonthly,
      isFooterExpanded,
      isChartExpanded,
      selectionCategoriesByID,
      column_count,
      tableType,
      dataSetData?.start_date,
      dataSetData?.business_start_date,
      scenarioById,
      todayLiq,
    ],
    true
  );

  useDebounce(
    () => {
      if (isAllHeaderApiFetched && appliedFilterlist) {
        dispatch(setChartLineKeys(getChartKeys()));
      }
    },
    200,
    [
      isMonthChartLoading,
      scenario,
      state,
      Limit,
      planningChartData,
      updateChart,
    ],
    true
  );

  const updatePlanningChart = () => {
    let includedScenarios = scenario?.map((o1) => o1.title);
    let includedStates = state?.map((o1) => o1.title);
    let obj = JSON.stringify({
      Limit,
      advanceVat,
      selectionCategoriesByID,
      scenario,
      business_start_date: dataSetData?.business_start_date,
      start_date: dataSetData?.start_date,
      end_date: dataSetData?.end_date,
      past_table_data: past_table_data?.data?.results,
      booked_balances: transaction_monthly_chart?.data?.booked_balances,
      transaction_monthly_chart: transaction_monthly_chart?.data?.results,
      includedScenarios,
      includedStates,
      scenarioById,
      selectionCategories,
      isDataset: true,
      column_count,
      tableType,
      todayLiq,
    });
    dispatch(setAccountBalanceUpdateDate());
    dispatch(setIsMonthChartLoading(true));

    // const data = generateMonthlyChart({ data: obj });
    // const result = JSON.parse(data);
    // dispatch(setMonthlyChartData(result));
    // dispatch(setIsMonthChartLoading(false));

    webWorkerMonthly?.postMessage({
      type: "updatePlanningChart",
      data: obj,
    });

    webWorkerMonthly.onmessage = (e) => {
      if (e.data) {
        let { data, type } = e.data;
        if (type === "updatePlanningChart") {
          let result = JSON.parse(data);
          dispatch(setMonthlyChartData(result));
          dispatch(setIsMonthChartLoading(false));
          // const chart1 = performance.now();
          // console.log(`<<-- Chart Process took --> ${chart1 - chart0} ms.`);
        }
      } else {
        console.log("worker client Error");
        dispatch(setIsMonthChartLoading(false));
      }
    };
  };

  const getChartKeys = () => {
    if (appliedFilterlist?.kanban && planningChartData) {
      let chart_keys = appliedFilterlist?.kanban?.chart_keys;

      let vat_array = [];
      if (advanceVat?.enabled) {
        vat_array = [
          {
            uuid: "VAT",
            name: "VAT",
            title: "VAT",
            color: Color.theme.pink["A400"],
            fill: Color.theme.pink["A400"],
            stroke: Color.theme.pink["A400"],
            isSelected:
              !chart_keys || !chart_keys?.hasOwnProperty("VAT")
                ? true
                : chart_keys["VAT"],
            position: 2,
          },
        ];
      }
      let array = [
        ...vat_array,
        Limit && {
          uuid: "Limit",
          name: "Limit",
          stroke: Color.theme.red[500],
          color: Color.theme.red[500],
          fill: Color.theme.red[500],
          title: "Limit",
          can_be_deleted: true,
          isSelected:
            !chart_keys || !chart_keys?.hasOwnProperty("Limit")
              ? true
              : chart_keys?.["Limit"],
          position: 5,
          type: "account",
        },
        ...scenario?.map((o1, index) => {
          return {
            uuid: o1.uuid,
            name: o1.title,
            stroke: o1.color,
            color: o1.color,
            title: o1.title,
            style: o1.style,
            can_be_deleted: o1.can_be_deleted,
            fill: o1.color,
            isSelected: o1.title === "Base" || chart_keys?.[o1.uuid],
            position: 5 + o1.position,
            isScenario: true,
            isLast: index === scenario?.length - 1,
          };
        }),
        // ...state?.map((o1) => {
        //   return {
        //     uuid: o1.uuid,
        //     name: o1.title,
        //     stroke: o1.color,
        //     color: o1.color,
        //     title: o1.title,
        //     can_be_deleted: o1.can_be_deleted,
        //     fill: o1.color,
        //     position: 5 + scenario?.length + o1.position,
        //     isState: true,
        //   };
        // }),
      ];

      const keys = array?.map((o1) => o1?.uuid);
      let update_chart_keys = {};
      if (chart_keys && keys?.length !== Object.keys(chart_keys)?.length) {
        Object.keys(chart_keys)?.forEach((key) => {
          update_chart_keys[key] = chart_keys?.[key] || false;
        });
        updateFilter("chart_keys", update_chart_keys);
      }
      return array?.sort((a, b) => (a?.position > b?.position ? 1 : -1));
    }
  };

  const updateFilter = (key, value) => {
    dispatch(updateFilters({ parent_key: "kanban", obj: { [key]: value } }));
  };

  if (isChartInProgress && isChartExpanded) {
    return (
      <ThemeSpinner
        loading={true}
        theme={theme}
        style={{
          position: "absolute",
          top: 150,
          left: "48%",
          zIndex: 4,
        }}
      />
    );
  }
  return null;
};
export default CommonView;
