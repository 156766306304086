import { Tooltip, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

import { getStaffQueryKey } from "../Helper/data";
import { queryClient } from "../App";

const SubscriptionBadge = ({ type, ds_type, sx }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  //redux
  const dataSetList = useSelector((state) => state.boardSlice.dataSetList);
  const dataSource = useSelector((state) => state.globalSlice.dataSource);
  const subscription = useSelector(
    (state) => state.settingsSlice?.subscription
  );

  const StaffList = queryClient.getQueryData(getStaffQueryKey());
  const userList = queryClient.getQueryData(["users"]);

  const onClick = () => {
    navigate("/settings/subscription");
  };

  let text = "";
  let tooltip = "";
  let color = "slate";
  let total = 0;
  let used = 0;

  if (type === "dataset") {
    text = `Organizations Limit`;
    total = subscription?.dataset_limit || 0;
    used = dataSetList?.length || 0;
  }
  if (type === "employee") {
    text = `Employees Limit`;
    total = subscription?.employee_limit || 0;
    used = StaffList?.length || 0;
  }
  if (type === "users") {
    text = `Users Limit`;
    const activeUserList = userList?.filter((o1) => o1.is_active);
    total = subscription?.user_limit || 0;
    used = activeUserList?.length || 0;
  }
  if (type === "data_source") {
    used =
      dataSource?.filter(
        (o1) => ds_type?.includes(o1.type) && !o1.internal_dataset
      )?.length || 0;
    if (ds_type?.includes(1)) {
      total = subscription?.offline_data_source_limit || 0;
      text = "Offline DS Limit";
    }
    if (ds_type?.includes(12)) {
      total = subscription?.online_data_source_limit || 0;
      text = "Online DS Limit";
    }
    if (ds_type?.includes(19)) {
      total = subscription?.integration_data_source_limit || 0;
      // total = 7;
      text = "Integrations DS Limit";
    }
  }
  if (used >= total) {
    color = "red";
  }
  return (
    <Tooltip title={t(tooltip)} placement="top" arrow followCursor>
      <Typography
        onClick={onClick}
        variant="caption"
        fontWeight={"fontWeightMediumBold"}
        sx={{
          cursor: "pointer",
          borderRadius: theme.borderRadius.main,
          backgroundColor:
            theme.palette.color[color][color === "red" ? 50 : 100],
          color: theme.palette.color[color][500],
          px: "1rem",
          py: "0.5rem",
          ...sx,
        }}
      >
        {`${used}/${total} ${t(text)}`}
      </Typography>
    </Tooltip>
  );
};

export default SubscriptionBadge;
