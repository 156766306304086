import { createSlice } from "@reduxjs/toolkit";

import { resetAllState } from "./reset";

const initialState = {
  currencyFormate: {
    prefix: "$",
    groupSeparator: ",",
    decimalSeparator: ".",
    disableGroupSeparators: false,
  },
  country: "", //{code: "", label: "", phone: ""}
  profile: null,
  subscription: null,
  isRestriction: false,
  isMainDrawerOpen: false,
  addFrom: null,
};

const settingsSlice = createSlice({
  name: "settingsSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(resetAllState, () => initialState);
  },
  reducers: {
    setCountry: (state, action) => {
      state.country = action.payload;
    },

    setCurrencyFormate: (state, action) => {
      state.currencyFormate = action.payload;
    },
    toggleRestriction: (state, action) => {
      state.isRestriction = !state.isRestriction;
    },
    setProfile: (state, action) => {
      if (action.payload?.key) {
        state.profile[action.payload?.key] = action.payload?.value;
      } else {
        state.profile = action.payload;
        let obj = {
          name: action.payload?.subscription?.plan?.name,
          monthly_price: action.payload?.subscription?.plan?.monthly_price,
          currency: action.payload?.subscription?.plan?.currency,
          currency_symbol: action.payload?.subscription?.plan?.currency_symbol,
          is_active: action.payload?.subscription?.is_active,
          start_date: action.payload?.subscription?.start_date,
          expiry_date: action.payload?.subscription?.expiry_date,
          expired: action.payload?.subscription?.expired,
          stripe_subscription:
            action.payload?.subscription?.stripe_subscription,
          planId: action.payload?.subscription?.stripe_subscription?.plan?.id,
          canceled_at:
            action.payload?.subscription?.stripe_subscription?.canceled_at ??
            null,
        };
        action.payload?.subscription?.plan?.quotas?.forEach((o1) => {
          obj[o1?.code] = o1.limit;
        });

        action.payload?.subscription?.plan?.features?.forEach((o1) => {
          obj[o1?.code] = !action.payload?.subscription?.expired;
        });
        if (action.payload?.subscription?.custom_quotas) {
          Object.keys(action.payload?.subscription?.custom_quotas)?.forEach(
            (code) => {
              if (action.payload?.subscription?.custom_quotas?.[code]) {
                obj[code] = action.payload?.subscription?.custom_quotas?.[code];
              }
            }
          );
        }

        if (action.payload?.subscription) {
          state.subscription = obj;
        }
      }
    },
    setMainDrawer: (state, action) => {
      state.isMainDrawerOpen = action.payload;
    },
    setAddFrom: (state, action) => {
      state.addFrom = action.payload;
    },
  },
});
export const {
  setCountry,
  setCurrencyFormate,
  setProfile,
  toggleRestriction,
  setMainDrawer,
  setAddFrom,
} = settingsSlice.actions;
export default settingsSlice;
