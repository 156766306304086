import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import React, { useContext, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, Typography } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import { useTranslation } from "react-i18next";
import { differenceInMonths } from "date-fns";
import { useTheme } from "@mui/styles";

import CustomPopoverScratch from "../../../../components/PopOver/CustomPopover";
import { getDataSetDateRange } from "../../../../Helper/functions";
import { GlobalContext } from "../../../../GlobalContextWrapper";
import { setTableType } from "../../../../store/slices/board";
import useStatusHook from "../../../../hooks/useStatusHook";
import { Constant } from "../../../../Helper";
import { queryClient } from "../../../../App";

const CalDayIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={props.width || "22"} // Default width
    height={props.height || "22"} // Default height
    viewBox="0 0 101 101"
    stroke="#475569"
    {...props} // Pass other props like className, onClick, etc.
  >
    <defs>
      <clipPath id="clip-cal_day">
        <rect width="101" height="101" />
      </clipPath>
    </defs>
    <g id="cal_day" clipPath="url(#clip-cal_day)">
      <g
        id="Rechteck_1"
        transform="translate(16 20)"
        fill="none"
        stroke="currentColor"
        strokeWidth="5"
      >
        <rect width="70" height="70" rx="12" stroke="none" />
        <rect x="2.5" y="2.5" width="65" height="65" rx="9.5" fill="none" />
      </g>
      <line
        id="Linie_8"
        y2="6"
        transform="translate(34.5 15.5)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="5"
      />
      <line
        id="Linie_9"
        y2="6"
        transform="translate(67.5 15.5)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="5"
      />
      <circle
        id="Ellipse_1"
        cx="5"
        cy="5"
        r="5"
        transform="translate(30 45)"
        fill="currentColor"
      />
      <rect
        id="Rechteck_5"
        width="62"
        height="15"
        transform="translate(20 24)"
        fill="currentColor"
      />
    </g>
  </svg>
);

const CalWeekIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={props.width || "22"} // Default width
    height={props.height || "22"} // Default height
    viewBox="0 0 101 101"
    stroke="#475569"
    {...props} // Pass other props like className, onClick, etc.
  >
    <defs>
      <clipPath id="clip-cal_week">
        <rect width="101" height="101" />
      </clipPath>
    </defs>
    <g id="cal_week" clipPath="url(#clip-cal_week)">
      <g
        id="Rechteck_1"
        transform="translate(16 20)"
        fill="none"
        stroke="currentColor"
        strokeWidth="5"
      >
        <rect width="70" height="70" rx="12" stroke="none" />
        <rect x="2.5" y="2.5" width="65" height="65" rx="9.5" fill="none" />
      </g>
      <line
        id="Linie_8"
        y2="6"
        transform="translate(34.5 15.5)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="5"
      />
      <line
        id="Linie_9"
        y2="6"
        transform="translate(67.5 15.5)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="5"
      />
      <rect
        id="Rechteck_6"
        width="62"
        height="15"
        transform="translate(20 23)"
        fill="currentColor"
      />
      <circle
        id="Ellipse_7"
        cx="5"
        cy="5"
        r="5"
        transform="translate(30 46)"
        fill="currentColor"
      />
      <circle
        id="Ellipse_8"
        cx="5"
        cy="5"
        r="5"
        transform="translate(46 46)"
        fill="currentColor"
      />
      <circle
        id="Ellipse_9"
        cx="5"
        cy="5"
        r="5"
        transform="translate(62 46)"
        fill="currentColor"
      />
    </g>
  </svg>
);

const CalMonthIcon = ({
  width = 22,
  height = 22,
  backgroundColor = "#fff",
  style = {},
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 101 101"
      color="#475569"
      style={style}
    >
      <defs>
        <clipPath id="clip-cal_month">
          <rect width="101" height="101" />
        </clipPath>
      </defs>
      <g clipPath="url(#clip-cal_month)">
        {/* Outer Rectangle */}
        <g
          transform="translate(16 20)"
          fill={backgroundColor}
          stroke={"currentColor"}
          strokeWidth="5"
        >
          <rect width="70" height="70" rx="12" stroke="none" />
          <rect x="2.5" y="2.5" width="65" height="65" rx="9.5" fill="none" />
        </g>

        {/* Top Lines */}
        <line
          y2="6"
          transform="translate(34.5 15.5)"
          fill="none"
          stroke={"currentColor"}
          strokeLinecap="round"
          strokeWidth="5"
        />
        <line
          y2="6"
          transform="translate(67.5 15.5)"
          fill="none"
          stroke={"currentColor"}
          strokeLinecap="round"
          strokeWidth="5"
        />

        {/* Header */}
        <rect
          width="62"
          height="15"
          transform="translate(20 24)"
          fill={"currentColor"}
        />

        {/* Circles for Calendar Dots */}
        {[31, 46, 61].map((x, i) => (
          <React.Fragment key={`row-1-${i}`}>
            <circle
              cx="5"
              cy="5"
              r="5"
              transform={`translate(${x} 44)`}
              fill={"currentColor"}
            />
            <circle
              cx="5"
              cy="5"
              r="5"
              transform={`translate(${x} 58)`}
              fill={"currentColor"}
            />
            {i === 0 || i === 1 ? (
              <circle
                cx="5"
                cy="5"
                r="5"
                transform={`translate(${x} 71)`}
                fill={"currentColor"}
              />
            ) : null}
          </React.Fragment>
        ))}

        {/* Duplicate Outer Rectangle */}
        <g
          transform="translate(16 20)"
          fill={backgroundColor}
          stroke={"currentColor"}
          strokeWidth="5"
        >
          <rect width="70" height="70" rx="12" stroke="none" />
          <rect x="2.5" y="2.5" width="65" height="65" rx="9.5" fill="none" />
        </g>

        {/* Duplicate Top Lines */}
        <line
          y2="6"
          transform="translate(34.5 15.5)"
          fill="none"
          stroke={"currentColor"}
          strokeLinecap="round"
          strokeWidth="5"
        />
        <line
          y2="6"
          transform="translate(67.5 15.5)"
          fill="none"
          stroke={"currentColor"}
          strokeLinecap="round"
          strokeWidth="5"
        />

        {/* Duplicate Header */}
        <rect
          width="62"
          height="15"
          transform="translate(20 24)"
          fill={"currentColor"}
        />

        {/* Duplicate Circles */}
        {[31, 46, 61].map((x, i) => (
          <React.Fragment key={`row-2-${i}`}>
            <circle
              cx="5"
              cy="5"
              r="5"
              transform={`translate(${x} 44)`}
              fill={"currentColor"}
            />
            <circle
              cx="5"
              cy="5"
              r="5"
              transform={`translate(${x} 57)`}
              fill={"currentColor"}
            />
            <circle
              cx="5"
              cy="5"
              r="5"
              transform={`translate(${x} 71)`}
              fill={"currentColor"}
            />
          </React.Fragment>
        ))}
      </g>
    </svg>
  );
};

const CalQuarterIcon = ({
  width = 22,
  height = 22,
  backgroundColor = "#fff",
  style = {},
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      viewBox="0 0 101 101"
      style={style}
    >
      <defs>
        <clipPath id="clip-cal_quarter">
          <rect width="101" height="101" />
        </clipPath>
      </defs>
      <g id="cal_quarter" clipPath="url(#clip-cal_quarter)">
        <g
          id="Rechteck_1"
          transform="translate(16 20)"
          fill={backgroundColor}
          stroke="currentColor"
          strokeWidth="5"
        >
          <rect width="70" height="70" rx="12" stroke="none" />
          <rect x="2.5" y="2.5" width="65" height="65" rx="9.5" fill="none" />
        </g>
        <line
          id="Linie_8"
          y2="6"
          transform="translate(34.5 15.5)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="5"
        />
        <line
          id="Linie_9"
          y2="6"
          transform="translate(67.5 15.5)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="5"
        />
        <rect
          id="Rechteck_7"
          width="62"
          height="15"
          transform="translate(20 24)"
          fill="currentColor"
        />
        <circle
          id="Ellipse_11"
          cx="5"
          cy="5"
          r="5"
          transform="translate(31 44)"
          fill="currentColor"
        />
        <circle
          id="Ellipse_12"
          cx="5"
          cy="5"
          r="5"
          transform="translate(46 44)"
          fill="currentColor"
        />
        <circle
          id="Ellipse_13"
          cx="5"
          cy="5"
          r="5"
          transform="translate(61 44)"
          fill="currentColor"
        />
        <circle
          id="Ellipse_14"
          cx="5"
          cy="5"
          r="5"
          transform="translate(31 58)"
          fill="currentColor"
        />
        <circle
          id="Ellipse_15"
          cx="5"
          cy="5"
          r="5"
          transform="translate(46 58)"
          fill="currentColor"
        />
        <circle
          id="Ellipse_16"
          cx="5"
          cy="5"
          r="5"
          transform="translate(61 58)"
          fill="currentColor"
        />
        <circle
          id="Ellipse_17"
          cx="5"
          cy="5"
          r="5"
          transform="translate(31 71)"
          fill="currentColor"
        />
        <g
          id="Rechteck_8"
          transform="translate(16 20)"
          fill={backgroundColor}
          stroke="currentColor"
          strokeWidth="5"
        >
          <rect width="70" height="70" rx="12" stroke="none" />
          <rect x="2.5" y="2.5" width="65" height="65" rx="9.5" fill="none" />
        </g>
        <line
          id="Linie_10"
          y2="6"
          transform="translate(34.5 15.5)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="5"
        />
        <line
          id="Linie_11"
          y2="6"
          transform="translate(67.5 15.5)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="5"
        />
        <rect
          id="Rechteck_9"
          width="62"
          height="15"
          transform="translate(20 24)"
          fill="currentColor"
        />
        <line
          id="Linie_12"
          y2="27"
          transform="translate(36 49)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="8"
        />
        <line
          id="Linie_13"
          y2="27"
          transform="translate(51 49)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="8"
        />
        <line
          id="Linie_14"
          y2="27"
          transform="translate(66 49)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="8"
        />
      </g>
    </svg>
  );
};

const TableViewFilterOptions = [
  {
    value: "daily",
    label: "table_view_option_daily",
    tooltip: "table_view_option_daily_tooltip",
    icon: <CalDayIcon />,
  },
  {
    value: "weekly",
    label: "table_view_option_weekly",
    tooltip: "table_view_option_weekly_tooltip",
    icon: <CalWeekIcon />,
  },
  {
    value: "monthly",
    label: "table_view_option_monthly",
    tooltip: "table_view_option_monthly_tooltip",
    icon: <CalMonthIcon />,
  },
  {
    value: "quarterly",
    label: "table_view_option_quarterly",
    tooltip: "table_view_option_quarterly_tooltip",
    icon: <CalQuarterIcon />,
  },
];
const TableTypeView = ({ width = "12rem", color = "slate" }) => {
  const dispatch = useDispatch();

  const tableType = useSelector((state) => state.boardSlice?.tableType);
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);

  useEffect(() => {
    if (dataSetData?.uuid && !Constant.isProd) {
      const difference = differenceInMonths(
        new Date(dataSetData?.start_date),
        new Date(dataSetData?.end_date)
      );

      if (Math.abs(difference) <= 2 && tableType !== "daily") {
        dispatch(setTableType("daily"));
      } else if (Math.abs(difference) < 6 && tableType !== "weekly") {
        // dispatch(setTableType("weekly"));
      }
    }
  }, [dataSetData?.uuid]);

  return (
    <CustomPopoverScratch
      button={<View width={width} color={color} tableType={tableType} />}
    >
      <OverlayView tableType={tableType} />
    </CustomPopoverScratch>
  );
  // return (
  //   <ThemeToggle
  //     value={tableType}
  //     onChange={onChange}
  //     options={TableViewFilterOptions}
  //   />
  // );
};

export default TableTypeView;

const View = ({ width, color, tableType, popup_state }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const isOpen = popup_state?.isOpen;

  const item = useMemo(() => {
    return TableViewFilterOptions?.find(
      (item) => item?.value === (tableType || "monthly")
    );
  }, [tableType]);

  const title = t(item?.label);

  return (
    <Box
      sx={{
        borderRadius: theme.borderRadius.main,
        backgroundColor: theme.palette.color.white,
        border: `1px solid ${theme.palette.color?.[color]?.[300]}`,
        px: "0.6rem",
        py: "0.5rem",
        cursor: "pointer",
        width: width,
        mr: "1rem",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        "& svg": {
          fontSize: "1.15rem",
          color: theme.palette.color?.[color]?.[600],
        },
        "&:hover": {
          "& svg, p": {
            color: `${theme.palette.primary.main} !important`,
            borderColor: `${theme.palette.primary.main} !important`,
          },
        },
      }}
    >
      {item?.icon}
      <Typography
        variant={"body2"}
        fontWeight={"fontWeightMediumBold"}
        sx={{
          width: "80%",
          overflow: "hidden",
          whiteSpace: "nowrap",
          fontSize: "0.85rem",
          textOverflow: "ellipsis",
          color: theme.palette.color?.[color]?.[600],
          textAlign: "center",
        }}
      >
        {title}
      </Typography>
      <KeyboardArrowDownIcon
        sx={{
          color: theme.palette.color?.[color]?.[600],
          transform: isOpen ? "rotate(-180deg)" : "rotate(0deg)",
          transition: "transform 0.3s ease-in-out",
        }}
      />
    </Box>
  );
};

const OverlayView = ({ tableType, popup_state }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const globalContext = useContext(GlobalContext);
  const dataSetRef = globalContext?.dataSetRef;
  const dispatch = useDispatch();
  const past_table_data = useStatusHook("past_table_data");
  const table_data = useStatusHook("table");
  const transaction_type_chart = useStatusHook(
    `transaction_${tableType}_chart`
  );

  //function
  const onChange = (e, value) => {
    queryClient.removeQueries({
      queryKey: transaction_type_chart,
    });
    queryClient.removeQueries({
      queryKey: past_table_data,
    });
    queryClient.removeQueries({
      queryKey: table_data,
    });
    if (value === "daily") {
      Constant.default_column_count = 59;
    } else if (value === "weekly") {
      Constant.default_column_count = 31;
    } else if (value === "quarterly") {
      Constant.default_column_count = 15;
    } else {
      Constant.default_column_count = 31;
    }
    const obj = getDataSetDateRange({
      tableType: value,
      sub_count: Constant.start_column_skip_count,
      column_count: Constant.default_column_count,
    });
    dispatch(setTableType(value));
    popup_state?.close();
    dataSetRef?.current?.updateDataSetData({
      start_date: obj?.start_date,
      end_date: obj?.end_date,
    });
  };

  return (
    <Grid
      container
      spacing={2}
      sx={{
        pt: "1rem",
        pb: "0.5rem",
        width: "15rem",
        display: "flex",
        flexDirection: "column",
        backgroundColor: theme.palette.color.white,
      }}
    >
      {TableViewFilterOptions?.map((item, index) => {
        const isActive = tableType === item?.value;

        return (
          <ItemView
            key={item?.uuid}
            item={item}
            t={t}
            theme={theme}
            isActive={isActive}
            onClick={(e) => onChange(e, item?.value)}
          />
        );
      })}
    </Grid>
  );
};

const ItemView = ({ item, theme, isActive, t, onClick }) => {
  return (
    <ToggleButton
      key={item?.value}
      value={item?.value}
      onClick={onClick}
      aria-label="left aligned"
      sx={{
        width: "100%",
        gap: "0.5rem",
        fontSize: "0.8rem",
        lineHeight: "0.8rem",
        textAlign: "left",
        display: "flex",
        justifyContent: "flex-start",
        py: "0.5rem",
        px: "1.5rem",
        color: isActive
          ? theme.palette.color.primary.main
          : theme.palette.color?.slate?.[800],
        border: 0,
        backgroundColor: isActive
          ? theme.palette.color.primary[50]
          : "transparent",
        "& svg": {
          color: isActive
            ? theme.palette.primary.main
            : theme.palette.color?.slate?.[600],
        },
      }}
    >
      {item?.icon}
      {item?.label ? t(item?.label) : ""}
    </ToggleButton>
  );
};
