import { CardMedia } from "@mui/material";

import { getIntegrationCard } from "../../../Helper/data";
import Icon from "../../../components/Icon";
import store from "../../../store";

const general_style = {
  width: "3rem",
  height: "3rem",
};
const DSImage = ({ ds_uuid, integration_title, sx }) => {
  const accountByDS = store?.getState()?.globalSlice?.accountByDS;
  const dataSourceById = store?.getState()?.globalSlice?.dataSourceById;
  const data_source = dataSourceById?.[ds_uuid]?.[0];
  const account = accountByDS?.[ds_uuid]?.[0];
  const card = getIntegrationCard({
    ds: { ...data_source, note: data_source?.note || integration_title },
  });

  if (account?.bank_details?.logo_url) {
    return (
      <CardMedia
        component="img"
        sx={{
          objectFit: "contain",
          ...general_style,
          ...sx,
        }}
        image={account?.bank_details?.logo_url}
        alt="Logo"
      />
    );
  }
  if (card?.img) {
    return (
      <CardMedia
        component="img"
        sx={{
          objectFit: "contain",
          ...general_style,
          ...sx,
        }}
        image={card?.img}
        alt="Logo"
      />
    );
  }
  if (card?.icon) {
    return (
      <Icon
        icon={card?.icon}
        fontSize={"1.3rem"}
        color="inherit"
        style={{
          width: "fit-content",
          ...general_style,
          "& svg": {
            width: "100%",
            height: "100%",
          },
          ...sx,
        }}
      />
    );
  }
};
export default DSImage;
