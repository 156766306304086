import DataUsageIcon from "@mui/icons-material/DataUsage";
import { useTranslation } from "react-i18next";
import { Box, Collapse } from "@mui/material";
import { useSelector } from "react-redux";
import { useState } from "react";

import DropDown from "../Overlay/DropDown";
import ToggleButton from "./ToggleButton";

const CostUnitView = ({
  theme,
  cardItem,
  modalType,
  onChangeCostUnit,
  onChangeDataSource,
}) => {
  const { t } = useTranslation();

  const costunitsById = useSelector((state) => state.globalSlice.costunitsById);
  const costunits = useSelector((state) => state.globalSlice.costunits);

  const datasetAccountList = useSelector(
    (state) => state.boardSlice.datasetAccountList
  );

  //state
  const [isExpanded, setIsExpanded] = useState(false);

  //functions
  const handleClickBtn = () => {
    setIsExpanded((prev) => !prev);
  };

  return (
    <>
      <Collapse
        orientation="vertical"
        in={isExpanded}
        collapsedSize={"0rem"}
        sx={{ width: "100%" }}
      >
        <Box
          sx={{
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "space-between",
            mt: "2rem",
            width: "100%",
          }}
        >
          <div style={{ display: "flex", marginTop: "2rem", width: "100%" }}>
            <DataUsageIcon
              sx={{
                color: theme.palette.color.blueGrey[700],
                ml: "0.2rem",
                mr: "1rem",
                fontSize: "1.5rem",
              }}
            />
            <DropDown
              sx={{ width: "30%" }}
              disablePortal
              hideTitle
              fontSize="0.9rem"
              name="data_source"
              options={datasetAccountList?.filter((o1) => o1.type === 1)}
              value={
                datasetAccountList?.find(
                  (o1) => o1?.uuid === cardItem?.data_source
                ) || null
              }
              onChange={onChangeDataSource}
              getOptionLabel={(option) => option?.title ?? ""}
              label={t("Integration")}
              tooltip={t("Integration")}
              likeGoogle
              disabled={modalType === "edit" && cardItem.source !== 1}
              variant="filled"
            />

            <DropDown
              sx={{ width: "30%" }}
              disablePortal
              hideTitle
              fontSize="0.9rem"
              name="cost_unit"
              options={costunits}
              value={costunitsById?.[cardItem?.cost_unit]?.[0]}
              onChange={onChangeCostUnit}
              getOptionLabel={(option) => option?.title ?? ""}
              label={t("Cost_Unit")}
              tooltip={t("Cost_Unit")}
              likeGoogle
              variant="filled"
            />
          </div>
        </Box>
      </Collapse>
      <ToggleButton
        isExpanded={isExpanded}
        onClick={handleClickBtn}
        style={{
          marginLeft: "3rem",
          marginTop: "1rem",
          marginBottom: "4rem",
        }}
      />
    </>
  );
};

export default CostUnitView;
