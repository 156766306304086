import React, { useContext, useEffect, useMemo } from "react";
import { FaEye, FaGlobeAmericas } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@mui/styles";
import Box from "@mui/material/Box";

import DateRangePicker from "../../../../components/Overlay/ThemeFilter/Component/DateRangePicker";
import CostUnitDropdown from "../../../../components/Overlay/CostUnitDropdown";
import ScenarioDropdown from "../../../../components/Overlay/ScenarioDropdown";
import FinbanInfoBulb from "../../../../components/FInbanInfo/FinbanInfoBulb";
import { setChartExapanded } from "../../../../store/slices/chart";
import { GlobalContext } from "../../../../GlobalContextWrapper";
import { toggleView } from "../../../../store/slices/board";
// import ChartOptionsPopOver from "./ChartOptionsPopOver";
// import CardOptionsPopOver from "./CardOptionsPopOver";
import { Color, Constant } from "../../../../Helper";
import ExportDialogView from "./ExportDialogView";
import MonthSkipView from "./MonthSkipView";
import TableTypeView from "./TableTypeView";

const BoardHeader = () => {
  // const childRef = useRef(null);
  // const chartOptionRef = useRef(null);
  // const cardOptionRef = useRef(null);

  //redux
  const isKanbanView = useSelector((state) => state.boardSlice.isKanbanView);
  const beta_enabled = useSelector(
    (state) => state.settingsSlice?.profile?.beta_enabled
  );

  //lifecycle
  useEffect(() => {
    // Emitter.on("Event_Close_Grouped_Options", () => {
    //   cardOptionRef.current.handleClose();
    // });
    // Emitter.on("Event_Open_Grouped_Options", (index = 11) => {
    //   cardOptionRef.current.openManualy();
    //   chartOptionRef.current.handleClose();
    //   childRef.current.handleClose();
    //   if (index) {
    //     setTimeout(() => {
    //       dispatch(setSteps({ key: "kanban", t }));
    //       dispatch(setStepIndex(index));
    //       dispatch(startTour());
    //     }, 500);
    //   }
    // });
    // Emitter.on("Event_Open_Chart_Options", () => {
    //   cardOptionRef.current.handleClose();
    //   childRef.current.handleClose();
    //   chartOptionRef.current.openManualy();
    //   setTimeout(() => {
    //     dispatch(setSteps({ key: "kanban", t }));
    //     dispatch(setStepIndex(16));
    //     dispatch(startTour());
    //   }, 500);
    // });
    // Emitter.on("Event_Open_Help_Overlay", () => {
    //   cardOptionRef.current.handleClose();
    //   chartOptionRef.current.handleClose();
    //   childRef.current.openManualy(true);
    // });
    // return () => {
    //   Emitter.removeAllListeners();
    // };
  }, []);

  return (
    <Box
      display="flex"
      alignItems={"center"}
      position="relative"
      zIndex={100}
      sx={{ mx: { xs: "2rem", md: "178px", s2133: "170px" } }}
    >
      {Constant?.isProd || !beta_enabled ? null : <CostUnitDropdown />}
      <MonthSkipView />

      <div
        style={{
          display: "inline-flex",
          alignItems: "center",
          width: "80%",
        }}
      >
        <ScenarioDropdown />
        {Constant?.isProd || !beta_enabled ? null : <TableTypeView />}
        {Constant?.isProd || !beta_enabled ? null : <DateFilter />}

        <div
          style={{
            display: "inline-flex",
            position: "relative",
            alignItems: "center",
          }}
        >
          <Box
            display="inline-flex"
            alignItems="center"
            justifyContent={"flex-start"}
            backgroundColor={Color.tailwind.purple[200]}
            borderRadius={14}
            pr={isKanbanView ? "4px" : 0}
            sx={{
              position: "absolute",
              left: 0,
              top: "-1.5rem",
              zIndex: 2,
            }}
          >
            <TableSwitch />

            {/* {isKanbanView ? <CardOptionsPopOver ref={cardOptionRef} /> : null} */}
          </Box>
        </div>
      </div>

      <Box
        display="inline-flex"
        alignItems="center"
        justifyContent={"flex-end"}
        minWidth={beta_enabled ? "15%" : "50%"}
        width={"fit-content"}
      >
        <ExportDialogView />

        {/* <ChartOptionsPopOver ref={chartOptionRef} /> */}

        <FinbanInfoBulb
          url="https://finban.io/documentation/planungsansicht/"
          sx={{ ml: "1rem" }}
        />
      </Box>
    </Box>
  );
};
export default React.memo(BoardHeader);

const TableSwitch = () => {
  const dispatch = useDispatch();
  const ToggleOptions = [
    {
      value: 2,
      label: "Table-View", //overview //default
      icon: <FaGlobeAmericas />,
    },
    {
      value: 1,
      label: "Card-View", //focus
      icon: <FaEye />,
    },
  ];

  const isKanbanView = useSelector((state) => state.boardSlice.isKanbanView);

  const onChange = () => {
    dispatch(toggleView());
  };

  useEffect(() => {
    if (Constant.isProd || Constant.isTest || Constant.isStag) {
      if (isKanbanView) {
        dispatch(toggleView());
      }
    }
  }, [dispatch, isKanbanView]);

  useEffect(() => {
    dispatch(setChartExapanded(true));
  }, []);

  return null;
  // return (
  //   <ToggleTheme
  //     options={ToggleOptions}
  //     value={isKanbanView ? 1 : 2}
  //     onChange={onChange}
  //   />
  // );
};

const DateFilter = () => {
  const theme = useTheme();
  const globalContext = useContext(GlobalContext);
  const dataSetRef = globalContext?.dataSetRef;
  const start_date = useSelector(
    (state) => state.boardSlice?.dataSetData?.start_date
  );
  const end_date = useSelector(
    (state) => state.boardSlice?.dataSetData?.end_date
  );
  const tableType = useSelector((state) => state.boardSlice?.tableType);
  const RangeType = useMemo(() => {
    if (tableType === "quarterly") {
      return ["Quarterly", "Daily"];
    } else if (tableType === "weekly") {
      return ["Monthly", "Weekly", "Daily"];
    } else {
      //monthly or daily
      return ["Monthly", "Daily"];
    }
  }, [tableType]);

  //functions
  const updateDataSetData = (obj) => {
    dataSetRef?.current?.updateDataSetData(obj);
  };

  return (
    <DateRangePicker
      start_date={start_date}
      end_date={end_date}
      onOk={updateDataSetData}
      RangeType={RangeType}
      options={{ type: tableType, maxRange: Constant.default_column_count }}
      baseSx={{
        backgroundColor: theme.palette.color.white,
        ml: "0rem",
        mr: "1rem",
        height: "2.7rem",
        "& .filter-base-view-placeholder": {
          ":hover": {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.primary[50],
          },
        },
      }}
    />
  );
};
