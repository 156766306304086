import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { FaArrowUp } from "react-icons/fa";
import { useTheme } from "@mui/styles";

import { Color, Fonts } from "../Helper";

const ComingSoonView = ({ isBeta }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const onClickRoadmap = () => {
    window.open("https://finban.io/finban-roadmap/", "_blank");
  };

  return (
    <Typography
      component={"div"}
      sx={{
        display: "inline-flex",
        textAlign: "center",
        mt: "2rem",
        fontSize: "1rem",
        fontFamily: Fonts.Text,
        color: Color.tailwind.slate[500],
      }}
    >
      {`${isBeta ? t("ClosedBeta") : t("Coming_Soon")} `} {t("Check Our")}
      <Typography
        component={"span"}
        onClick={onClickRoadmap}
        sx={{
          textAlign: "center",
          fontSize: "1rem",
          fontFamily: Fonts.Text,
          color: Color.tailwind.slate[500],
          fontWeight: 700,
          ml: 1,
          cursor: "pointer",
          "&:hover": {
            color: Color.tailwind.slate[700],
          },
        }}
      >
        {t("Roadmap")}
      </Typography>
      <FaArrowUp
        strokeWidth={0.5}
        style={{
          transform: `rotate(45deg)`,
          color: theme.palette.primary.main,
          marginLeft: "0.5rem",
        }}
      />
    </Typography>
  );
};

export default ComingSoonView;
