import { Tooltip } from "@mui/material";
import { format } from "date-fns";
import React from "react";

import { formatAmount, getIntegrationCard } from "../Helper/data";
import store from "../store";

// DateCell Component
export const DateCell = React.memo(({ value }) => {
  if (!value) return null;

  const profile = store?.getState()?.settingsSlice?.profile;
  const formattedDate = value
    ? format(
        new Date(value),
        profile?.locale === "de_DE" ? "dd.MM.yyyy" : "dd/MM/yyyy"
      )
    : null;
  return <span>{formattedDate}</span>;
});

// GrossValueCell Component
export const GrossValueCell = React.memo(({ row, value, theme }) => {
  if (!value) return null;

  const formattedAmount = formatAmount({ amount: value });
  return (
    <Tooltip title={formattedAmount} arrow>
      <p
        style={{
          color:
            parseFloat(row?.gross_value) >= 0
              ? theme.palette.color.green[500]
              : theme.palette.color.red[500],
        }}
        className="cell-text-truncate"
      >
        {formattedAmount}
      </p>
    </Tooltip>
  );
});

// DataSourceCell Component
export const DataSourceCell = React.memo(({ row, t, bankName }) => {
  const dataSourceById = store?.getState()?.globalSlice?.dataSourceById;
  const ds = dataSourceById?.[row?.data_source]?.[0];
  let card = getIntegrationCard({ ds });
  if (ds?.internal_dataset || card?.title === "Manual_DS_title") {
    card = { title: "Internal data source" };
  }
  if (row?.source === 2) {
    card = { title: bankName?.[row?.account] };
  }
  return (
    <Tooltip title={t(card?.title)}>
      <p className="cell-text-truncate">{t(card?.title)}</p>
    </Tooltip>
  );
});
