import {
  ResponsiveContainer,
  ComposedChart,
  CartesianGrid,
  ReferenceLine,
  Tooltip,
  XAxis,
  YAxis,
  Line,
  Bar,
} from "recharts";
import { endOfQuarter, endOfWeek, startOfQuarter, startOfWeek } from "date-fns";
import React, { useDeferredValue, useMemo } from "react";
import { TbChartAreaLineFilled } from "react-icons/tb";
import PercentIcon from "@mui/icons-material/Percent";
import { MdOutlineShowChart } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { alpha, Box } from "@mui/material";
import { FaArrowUp } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/styles";
import format from "date-fns/format";
import _ from "underscore";

import {
  formatDateInQuarter,
  formatDateToLocal,
  getTailwindColor,
  formatDateInWeek,
  remToPx,
} from "../../../../Helper/data";
import { formatAmount, getDataSetColumnCount } from "../../../../Helper/data";
// import Placeholder from "../../../../components/Placeholder";
import useWidth from "../../../../hooks/useWidth";
import Icon from "../../../../components/Icon";
import { Constant } from "../../../../Helper";
import CommonView from "./CommonView";
import theme from "../../../../theme";
import BarShape from "./BarShape";

const Chart = ({ column_width, start_column_extra_width }) => {
  const currentWidth = useWidth();
  const theme = useTheme();

  let shadowWidth = column_width;
  let offset = Constant.offset;
  let marginLeft = Constant.chart_margin_left;
  let _extraSpace = Constant.chart_extra_offset;
  let height = "400px";
  let spacing = column_width + start_column_extra_width - marginLeft;

  //redux
  const isChartExpanded = useSelector(
    (state) => state.chartSlice?.isChartExpanded
  );
  const start_date = useSelector(
    (state) => state.boardSlice?.dataSetData?.start_date
  );
  const end_date = useSelector(
    (state) => state.boardSlice?.dataSetData?.end_date
  );
  const highlightedScenarios = useSelector(
    (state) => state.boardSlice?.highlightedScenarios
  );
  const tableType = useSelector((state) => state.boardSlice?.tableType);
  const advanceVat = useSelector((state) => state.globalSlice.advanceVat);
  const chartLineKeys = useSelector((state) => state.boardSlice?.chartLineKeys);

  const planningChartData = useSelector((state) => state.boardSlice?.chartData);

  const isMonthChartFetching = useSelector(
    (state) => state.appSlice.isMonthChartFetching
  );
  const isMonthChartLoading = useSelector(
    (state) => state.appSlice.isMonthChartLoading
  );
  const scenarioById = useSelector((state) => state.globalSlice.scenarioById);

  const deferredHighlightedScenarios = useDeferredValue(highlightedScenarios);

  const column_count = useMemo(() => {
    return getDataSetColumnCount({
      tableType,
      start_date,
      end_date,
    });
  }, [end_date, start_date, tableType]);

  const optionsKeys = useMemo(() => {
    if (chartLineKeys) {
      return _.groupBy(
        chartLineKeys.map((o1) => o1?.name && o1),
        (props) => (props && props?.name ? props?.name?.trim() : "null")
      );
    }
  }, [chartLineKeys]);

  let VAT = optionsKeys?.["VAT"]?.[0];
  let VAT_KEY = "";
  // let BASE = optionsKeys?.["Base"]?.[0];

  const isChartInProgress = isMonthChartFetching || isMonthChartLoading;

  if (deferredHighlightedScenarios) {
    let _scenario =
      scenarioById?.[deferredHighlightedScenarios?.[0]]?.[0]?.title;
    VAT_KEY = `VAT_Imputation_${_scenario}`;
  }
  // const isScenarioIncludedInFilter = (title) => {
  //   return isKanbanView
  //     ? appliedFilterlist?.kanban?.selectedScenarios?.includes(title)
  //     : true;
  // };

  // const isStateIncludedInFilter = (title) => {
  //   return appliedFilterlist?.kanban?.selectedStates?.includes(title);
  // };

  const tickFormatter = (tick) => {
    return formatAmount({
      amount: String(tick ?? 0),
    });
  };

  return (
    <Box
      style={{
        height: isChartExpanded ? height : 0,
        maxHeight: isChartExpanded ? height : 0,
        minHeight: isChartExpanded ? height : 0,
        transition: "all 500ms",
        marginLeft: marginLeft + _extraSpace,
        width: column_width * (column_count + 2) + spacing,
      }}
    >
      <CommonView />
      <Box
        sx={{
          display: isChartExpanded ? "flex" : "none",
          width: "100%",
          height: "100%",
          minHeight: 0,
          minWidth: 0,
          position: "relative",

          "& .recharts-active-dot": {
            display: "none",
          },
          "& .recharts-cartesian-grid-vertical line:nth-of-type(2)": {
            display: "none",
          },
        }}
      >
        {!isChartInProgress && isChartExpanded ? (
          <ResponsiveContainer>
            <ComposedChart
              renderer="canvas"
              data={planningChartData?.data}
              barSize={remToPx(currentWidth, 2)}
            >
              <CartesianGrid strokeDasharray="3" horizontal={false} />
              <XAxis
                dataKey="name"
                tickCount={12}
                type="category"
                allowDataOverflow={false}
                tickLine={false}
                tick={false}
                interval={1}
                stroke={theme?.palette?.color?.primary.main}
                strokeWidth={0}
              />
              <ReferenceLine
                x={format(new Date(), "yyyy-MM")}
                stroke={theme?.palette?.color?.slate[200]}
                strokeDasharray="0"
              />

              <ReferenceLine
                y={0}
                fill={theme.palette.color.black}
                strokeWidth={1}
                strokeDasharray="3"
                fillOpacity={0.8}
              />

              <YAxis
                type="number"
                tick={{
                  dx: 0,
                  fontSize: "0.6rem",
                  fontWeight: 500,
                  fill: theme.palette.color.grey[400],
                }}
                tickFormatter={tickFormatter}
                tickCount={8}
                interval={"preserveStartEnd"}
                strokeWidth={0}
              />

              <Tooltip
                // trigger="click"
                content={
                  <CustomTooltip
                    optionsKeys={optionsKeys}
                    shadowWidth={shadowWidth}
                  />
                }
                offset={offset}
                cursor={{ stroke: "transparent" }}
                wrapperStyle={{
                  zIndex: 16,
                  width: shadowWidth,
                  height: `calc(100vh - ${Constant.HEADER_HEIGHT} - ${Constant.BANNER_HEIGHT} - 6.5rem)`,
                }}
              />

              {chartLineKeys?.map((entry) => {
                if (entry?.name && entry?.color && entry?.position > 4) {
                  const color = entry?.color
                    ? getTailwindColor(entry?.color, 600)
                    : theme.palette.color.red[400];
                  const lightColor = entry?.color
                    ? getTailwindColor(entry?.color, 300)
                    : theme.palette.color.red[300];
                  const isScenarioAddedInFilter = true;
                  {
                    /* isScenarioIncludedInFilter(entry?.name); */
                  }

                  const showHighlighted =
                    deferredHighlightedScenarios?.[0] === entry?.uuid;

                  let stroke_width = 3;

                  return (
                    <React.Fragment key={`chart-${entry?.name}`}>
                      {entry.type && entry?.isSelected ? (
                        <Line
                          dataKey={entry?.name}
                          strokeWidth={1}
                          stroke={theme.palette.color.red[400]}
                          dot
                          style={
                            entry.uuid === "Limit"
                              ? {
                                  transform: `translateX(${offset}px)`,
                                }
                              : undefined
                          }
                        />
                      ) : null}

                      {entry?.isScenario &&
                      entry?.isSelected &&
                      isScenarioAddedInFilter ? (
                        <>
                          {entry?.title === "Base" ? (
                            <>
                              <Bar
                                dataKey={(v) =>
                                  Math.abs(v[`income_type_booked`] || 0)
                                }
                                stackId="a"
                                // fill={theme.palette.color.green[500]}
                                shape={(props) => (
                                  <BarShape
                                    {...props}
                                    theme={theme}
                                    _key={"income_type_booked"}
                                  />
                                )}
                                // radius={entry?.isLast ? [4, 4, 0, 0] : [0, 0, 0, 0]}
                              />
                              <Bar
                                dataKey={(v) =>
                                  Math.abs(v[`income_type_open_bar`] || 0)
                                }
                                stackId="a"
                                fill={theme.palette.color.green[200]}
                                // radius={entry?.isLast ? [4, 4, 0, 0] : [0, 0, 0, 0]}
                              />
                              <defs>
                                <pattern
                                  id={`income_type_remaining`}
                                  width="8"
                                  height="4"
                                  patternUnits="userSpaceOnUse"
                                  patternTransform="rotate(45)"
                                >
                                  <rect
                                    width="100%"
                                    height="100%"
                                    fill={theme.palette.color.green[200]}
                                    fillOpacity={0.25}
                                  />
                                  <rect
                                    width="2"
                                    height="4"
                                    fill={theme.palette.color.green[200]}
                                  />
                                </pattern>
                              </defs>
                              <Bar
                                dataKey={(v) =>
                                  Math.abs(v[`income_type_remaining`] || 0)
                                }
                                stackId="a"
                                fill={`url(#income_type_remaining`}
                              />
                            </>
                          ) : (
                            <>
                              <defs>
                                <pattern
                                  id={`income-scenario-${entry?.uuid}`}
                                  width="10"
                                  height="10"
                                  patternUnits="userSpaceOnUse"
                                  patternTransform="rotate(45)"
                                >
                                  <rect
                                    width="100%"
                                    height="100%"
                                    fill={lightColor}
                                    fillOpacity={0.25}
                                  />
                                  <circle
                                    cx="4"
                                    cy="4"
                                    r="3"
                                    fill={color}
                                    fillOpacity={0.2}
                                  />
                                </pattern>
                              </defs>
                              <Bar
                                dataKey={`income-scenario-${entry?.title}`}
                                stackId="a"
                                fill={`url(#income-scenario-${entry?.uuid}`}
                                // stroke={color}
                                // radius={entry?.isLast ? [4, 4, 0, 0] : [0, 0, 0, 0]}
                              />
                            </>
                          )}
                        </>
                      ) : null}
                      {entry?.isScenario &&
                      entry?.isSelected &&
                      isScenarioAddedInFilter ? (
                        <>
                          {entry?.title === "Base" ? (
                            <>
                              <Bar
                                dataKey={(v) =>
                                  Math.abs(v[`expense_type_booked`] || 0)
                                }
                                stackId="b"
                                shape={(props) => (
                                  <BarShape
                                    {...props}
                                    theme={theme}
                                    _key={"expense_type_booked"}
                                  />
                                )}
                                // stroke={theme.palette.color.red[500]}
                              />
                              {advanceVat &&
                              advanceVat?.enabled &&
                              VAT?.isSelected ? (
                                <Bar
                                  key={VAT_KEY}
                                  dataKey={(v) => Math.abs(v[VAT_KEY] || 0)}
                                  stackId="b"
                                  fill={theme.palette.color.rose[400]}
                                />
                              ) : null}
                              <Bar
                                dataKey={(v) =>
                                  Math.abs(v[`expense_type_open_bar`] || 0)
                                }
                                stackId="b"
                                fill={theme.palette.color.red[200]}
                                // stroke={theme.palette.color.red[500]}
                              />

                              <defs>
                                <pattern
                                  id={`expense_type_remaining`}
                                  width="8"
                                  height="4"
                                  patternUnits="userSpaceOnUse"
                                  patternTransform="rotate(45)"
                                >
                                  <rect
                                    width="100%"
                                    height="100%"
                                    fill={theme.palette.color.red[200]}
                                    fillOpacity={0.25}
                                  />
                                  <rect
                                    width="2"
                                    height="4"
                                    fill={theme.palette.color.red[200]}
                                  />
                                </pattern>
                              </defs>
                              <Bar
                                dataKey={(v) =>
                                  Math.abs(v[`expense_type_remaining`] || 0)
                                }
                                stackId="b"
                                fill={`url(#expense_type_remaining`}
                              />
                            </>
                          ) : (
                            <>
                              <defs>
                                <pattern
                                  id={`expense-scenario-${entry?.uuid}`}
                                  width="10"
                                  height="10"
                                  patternUnits="userSpaceOnUse"
                                  patternTransform="rotate(45)"
                                >
                                  <rect
                                    width="100%"
                                    height="100%"
                                    fill={lightColor}
                                    fillOpacity={0.25}
                                  />
                                  <circle
                                    cx="4"
                                    cy="4"
                                    r="3"
                                    fill={color}
                                    fillOpacity={0.2}
                                  />
                                </pattern>
                              </defs>
                              <Bar
                                dataKey={(v) =>
                                  Math.abs(
                                    v[`expense-scenario-${entry?.title}`] || 0
                                  )
                                }
                                stackId="b"
                                fill={`url(#expense-scenario-${entry?.uuid}`}
                                // stroke={color}
                                // radius={entry?.isLast ? [4, 4, 0, 0] : [0, 0, 0, 0]}
                              />
                            </>
                          )}
                        </>
                      ) : null}

                      {!entry.type &&
                      entry?.isScenario &&
                      entry?.isSelected &&
                      isScenarioAddedInFilter ? (
                        entry?.name === "Base" ? (
                          <>
                            <Line
                              style={{
                                transform: `translateX(${offset}px)`,
                              }}
                              strokeWidth={stroke_width}
                              stroke={getTailwindColor(color, 900)}
                              dataKey={`Base_solid`}
                              dot
                            />
                            <Line
                              strokeWidth={stroke_width}
                              stroke={getTailwindColor(color, 600)}
                              dataKey={`liquidity_start_Base`}
                              fillOpacity={1}
                              strokeDasharray={"6 6"}
                              dot
                              style={{
                                transform: `translateX(${offset}px)`,
                              }}
                            />
                          </>
                        ) : (
                          <Line
                            strokeWidth={
                              showHighlighted ? stroke_width + 1 : stroke_width
                            }
                            fill={entry?.can_be_deleted ? lightColor : color}
                            dataKey={`liquidity_start_${entry?.name}`}
                            fillOpacity={0.09}
                            strokeOpacity={showHighlighted ? 0.8 : 0.4}
                            stroke={color}
                            zIndex={showHighlighted ? 10 : 1}
                            strokeDasharray={"4 4"}
                            dot={false}
                            style={{
                              transform: `translateX(${offset}px)`,
                            }}
                          />
                        )
                      ) : null}
                    </React.Fragment>
                  );
                } else {
                  return null;
                }
              })}
            </ComposedChart>
          </ResponsiveContainer>
        ) : null}
      </Box>

      {/* {!subscription?.kanban_daily_chart && chartType === 2 ? (
        <Placeholder
          image={Images.kanban_daily_chart}
          description={"Only from Advanced Plan Available"}
          showText={isChartExpanded}
        />
      ) : isChartExpanded ? (
        <ComposedChartView column_width={column_width} />
      ) : (
        <span style={{ minHeight: "28.125rem" }}></span>
      )} */}
    </Box>
  );
};
export default Chart;

const CustomTooltip = React.memo((props) => {
  let { active, payload, optionsKeys = [], shadowWidth } = props;
  const { t } = useTranslation();
  const theme = useTheme();

  //redux
  const end_date = useSelector(
    (state) => state.boardSlice?.dataSetData?.end_date
  );

  const advanceVat = useSelector((state) => state.globalSlice.advanceVat);
  const tableType = useSelector((state) => state.boardSlice.tableType);

  // useEffect(() => {
  //   if (props?.label) {
  //     const allKanbanColumn = document?.querySelectorAll(
  //       `[all-kanban-column="all-kanban-column"]`
  //     );
  //     allKanbanColumn?.forEach((element) => {
  //       let id = `column-${new Date(props?.label).getMonth() + 1}`;
  //       if (id === element?.id.toString()) {
  //         element.style.zIndex = 6;
  //       } else {
  //         element.style.zIndex = 0;
  //       }
  //     });
  //   }
  // }, [props?.label]);

  if (
    payload?.[0]?.payload?.name &&
    new Date(payload?.[0]?.payload?.name) > new Date(end_date)
  ) {
    return null;
  }

  if (active && payload && payload.length) {
    const data = payload[0].payload?.payload?.filter(
      (entry) =>
        entry?.key !== "Base" &&
        optionsKeys?.[entry?.key]?.[0]?.isScenario &&
        optionsKeys?.[entry?.key]?.[0]?.isSelected &&
        (entry?.amount || entry?.amount === 0)
    );
    let _data = [...data];
    _data?.reverse();
    const currentMonth =
      tableType === "quarterly"
        ? format(startOfQuarter(new Date()), "yyyy-MM-dd")
        : tableType === "weekly"
          ? format(
              startOfWeek(new Date(), {
                weekStartsOn: 1,
              }),
              "yyyy-MM-dd"
            )
          : tableType === "daily"
            ? format(new Date(), "yyyy-MM-dd")
            : format(new Date(), "yyyy-MM");
    const currentMonthEnd =
      tableType === "quarterly"
        ? format(endOfQuarter(new Date()), "yyyy-MM-dd")
        : tableType === "weekly"
          ? format(
              endOfWeek(new Date(), {
                weekStartsOn: 1,
              }),
              "yyyy-MM-dd"
            )
          : tableType === "daily"
            ? format(new Date(), "yyyy-MM-dd")
            : format(new Date(), "yyyy-MM");
    let monthDate = format(
      new Date(payload[0]?.payload?.name),
      tableType === "monthly" ? "yyyy-MM" : "yyyy-MM-dd"
    );
    const isCurrentMonth = monthDate === currentMonth;
    const isFutureMonth = monthDate > currentMonthEnd;

    return (
      <div
        style={{
          position: "relative",
        }}
      >
        <span
          style={{
            width: shadowWidth,
            height: `calc(125vh - ${Constant.HEADER_HEIGHT} - ${Constant.BANNER_HEIGHT} )`,
            backgroundColor: alpha(theme.palette.color.slate[300], 0.15),
            position: "absolute",
            right: 0,
          }}
        ></span>
        <div
          style={{
            minWidth: "20rem",
            height: "fit-content",
            borderRadius: 8,
            backgroundColor: theme.palette.primary.dark,
            display: "inline-flex",
            justifyContent: "flex-start",
            paddingInline: "1rem",
            paddingBlock: "0.5rem",
            flexDirection: "column",
            boxShadow: "0 4px -2px 0 rgba(0,0,0,0.13)",
            position: "absolute",
            left: props?.coordinate?.x > 1193 ? undefined : "120%",
            right: props?.coordinate?.x > 1193 ? "120%" : undefined,
          }}
        >
          <Header
            isCurrentMonth={isCurrentMonth}
            tableType={tableType}
            payload={payload}
          />
          <>
            {/* /////////////////// */}
            <ItemHeader
              theme={theme}
              t={t}
              title={`${t("Base")} ${t("Liquidity")}`}
              backgroundColor={theme.palette.color.lightGreen[700]}
              iconStyle={{ transform: `translate(-50%, -50%) rotate(10deg)` }}
              noMargin
            />
            <ItemView
              t={t}
              key={`liquidity-Begin`}
              icon={false}
              entry={{
                name: "Begin",
                amount: payload?.[0]?.payload["liquidity_start_Base"] ?? 0,
              }}
            />
            <ItemView
              t={t}
              key={`liquidity-End`}
              icon={false}
              entry={{
                name: "End",
                amount: payload?.[0]?.payload["liquidity_end_Base"] ?? 0,
              }}
            />
            <ItemView
              t={t}
              key={`liquidity-diff`}
              icon={false}
              entry={{
                name: "Net Change",
                amount: payload?.[0]?.payload["liquidity_net_change_Base"] ?? 0,
              }}
            />

            {/* /////////////////// */}
            <ItemHeader
              theme={theme}
              t={t}
              icon={<FaArrowUp />}
              iconSize="0.9rem"
              title={"Inflow"}
              backgroundColor={theme.palette.color.lightGreen[700]}
              iconStyle={{ transform: `translate(-50%, -50%) rotate(45deg)` }}
            />
            {isFutureMonth ? null : (
              <ItemView
                t={t}
                key={`income_type_booked`}
                backgroundColor={theme.palette.color.green[500]}
                entry={{
                  name: "overlay_actual_value",
                  amount: payload?.[0]?.payload["income_type_booked"] ?? 0,
                }}
              />
            )}

            {isCurrentMonth || isFutureMonth ? (
              <>
                <ItemView
                  t={t}
                  key={`income_type_open`}
                  backgroundColor={theme.palette.color.green[200]}
                  entry={{
                    name: "overlay_open",
                    amount: payload?.[0]?.payload?.["income_type_open"] || 0,
                    late_amount:
                      payload?.[0]?.payload?.["income_type_open_late"] || null,
                  }}
                />

                <ItemView
                  t={t}
                  key={`income_type_remaining`}
                  backgroundColor={theme.palette.color.green[200]}
                  pattern={
                    <svg
                      width="100%"
                      height="100%"
                      style={{ borderRadius: "6px" }}
                    >
                      <defs>
                        <pattern
                          id="myPattern"
                          width="8"
                          height="4"
                          patternUnits="userSpaceOnUse"
                          patternTransform="rotate(45)"
                        >
                          <rect
                            width="2"
                            height="4"
                            fill={theme.palette.color.green[400]}
                          />
                        </pattern>
                      </defs>
                      <rect width="100%" height="100%" fill="url(#myPattern)" />
                    </svg>
                  }
                  entry={{
                    name: "overlay_remaining",
                    amount: payload[0].payload["income_type_remaining"] ?? 0,
                  }}
                />
              </>
            ) : null}

            {/* /////////////////// */}

            <ItemHeader
              theme={theme}
              t={t}
              icon={<FaArrowUp />}
              iconSize="0.9rem"
              backgroundColor={theme.palette.color.red[600]}
              title={"Outflow"}
              iconStyle={{ transform: `translate(-50%, -50%) rotate(125deg)` }}
            />
            {isFutureMonth ? null : (
              <ItemView
                t={t}
                key={`expense_type_booked`}
                backgroundColor={theme.palette.color.red[500]}
                entry={{
                  name: "overlay_actual_value",
                  amount: payload[0].payload["expense_type_booked"] ?? 0,
                }}
              />
            )}
            {isCurrentMonth || isFutureMonth ? (
              <>
                <ItemView
                  t={t}
                  key={`expense_type_open`}
                  backgroundColor={theme.palette.color.red[200]}
                  entry={{
                    name: "overlay_open",
                    amount: payload?.[0]?.payload?.["expense_type_open"] || 0,
                    late_amount:
                      payload?.[0]?.payload?.["expense_type_open_late"] || null,
                  }}
                />
                <ItemView
                  t={t}
                  key={`expense_type_remaining`}
                  backgroundColor={theme.palette.color.red[200]}
                  pattern={
                    <svg
                      width="100%"
                      height="100%"
                      style={{ borderRadius: "6px" }}
                    >
                      <defs>
                        <pattern
                          id="myPatternRed"
                          width="8"
                          height="4"
                          patternUnits="userSpaceOnUse"
                          patternTransform="rotate(45)"
                        >
                          <rect
                            width="2"
                            height="4"
                            fill={theme.palette.color.red[400]}
                          />
                        </pattern>
                      </defs>
                      <rect
                        width="100%"
                        height="100%"
                        fill="url(#myPatternRed)"
                      />
                    </svg>
                  }
                  entry={{
                    name: "overlay_remaining",
                    amount: payload[0].payload["expense_type_remaining"] ?? 0,
                  }}
                />
              </>
            ) : null}
          </>
          {/* /////////////////// */}
          {_data?.length > 0 && (isCurrentMonth || isFutureMonth) ? (
            <>
              <ItemHeader
                theme={theme}
                t={t}
                icon={<TbChartAreaLineFilled />}
                iconSize="1.4rem"
                title={"Scenarios"}
                helperText={"chart_overlay_scenario_helper_text"}
              />
              {_data?.map((entry, index) => {
                return (
                  <ItemView2
                    key={`data-${index}`}
                    t={t}
                    entry={entry}
                    mergeText={""}
                    backgroundColor={
                      entry?.name === "Base"
                        ? getTailwindColor(entry.color, 400)
                        : undefined
                    }
                    noPattern={entry?.name === "Base"}
                  />
                );
              })}
            </>
          ) : null}
          {/* /////////////////// */}
          {advanceVat && advanceVat?.enabled ? (
            <>
              <ItemHeader
                theme={theme}
                t={t}
                icon={<PercentIcon sx={{ fontSize: "1rem", strokeWidth: 2 }} />}
                title={"Vat_Base"}
              />

              <ItemView2
                key={`VAT_Imputation`}
                t={t}
                noPattern
                type="vat"
                helperText={"VAT_Imputation"}
                backgroundColor={theme.palette.color.rose[400]}
                entry={{
                  ...payload[0].payload,
                  name: "VAT",
                  amount: payload[0].payload["VAT"] ?? 0,
                }}
              />
            </>
          ) : null}
          {payload?.[0]?.payload["Limit"] ? (
            <ItemView
              key={`Limit`}
              t={t}
              backgroundColor={theme.palette.color.rose[400]}
              entry={{
                name: "Limit",
                amount: payload?.[0]?.payload["Limit"] ?? 0,
              }}
              style={{
                marginTop: "1rem",
              }}
            />
          ) : null}
        </div>
      </div>
    );
  }

  return null;
});

const Header = ({ isCurrentMonth, tableType, payload }) => {
  const date = isCurrentMonth ? new Date() : payload[0]?.payload?.name;
  return (
    <p
      style={{
        display: "inline-flex",
        color: theme.palette.color.white,
        fontFamily: theme.typography.fontFamily,
        fontSize: "1rem",
        fontWeight: 700,
        textAlign: "left",
      }}
    >
      {isCurrentMonth || tableType === "daily"
        ? formatDateToLocal(date, "dd MMMM yyyy")
        : tableType === "quarterly"
          ? formatDateInQuarter(date)
          : tableType === "weekly"
            ? formatDateInWeek(date)
            : formatDateToLocal(date, "MMMM yyyy")}
    </p>
  );
};

const ItemHeader = ({
  t,
  theme,
  noMargin,
  title,
  helperText = "",
  backgroundColor = theme.palette.color.purple[400],
  iconStyle,
  iconSize = "1.6rem",
  icon = <MdOutlineShowChart />,
}) => {
  return (
    <div
      style={{
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginTop: noMargin ? 0 : "1.5rem",
        marginBottom: "1rem",
      }}
    >
      <div
        style={{
          display: "inline-flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "inline-flex",
            alignItems: "center",
            borderRadius: "4px",
            marginRight: "1rem",
            backgroundColor: backgroundColor,
            position: "relative",
            width: "1.3rem",
            height: "1.3rem",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          <Icon
            icon={icon}
            fontSize={iconSize}
            color={theme.palette.color.white}
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: `translate(-50%, -50%)`,
              ...iconStyle,
            }}
          ></Icon>
        </div>
        <p
          style={{
            display: "inline-flex",
            color: theme.palette.color.white,
            fontFamily: theme.typography.fontFamily,
            fontSize: "1rem",
            fontWeight: 600,
            textAlign: "left",
            marginBlock: "0rem",
          }}
        >
          {t(title)}
        </p>
      </div>
      {helperText ? (
        <span
          style={{
            fontSize: "0.75rem",
            fontWeight: 600,
            color: theme.palette.color.white,
          }}
        >
          {t(helperText)}
        </span>
      ) : null}
    </div>
  );
};

const ItemView = ({
  t,
  entry,
  icon = true,
  backgroundColor,
  pattern,
  style,
}) => {
  return (
    <div
      style={{
        display: "inline-flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "space-between",
        marginBottom: "0.5rem",
        ...style,
      }}
    >
      <span
        style={{
          display: "inline-flex",
          alignItems: "center",
        }}
      >
        {icon ? (
          <div
            style={{
              width: 16,
              height: 16,
              borderRadius: 4,
              backgroundColor: backgroundColor
                ? backgroundColor
                : entry?.name === "Base"
                  ? entry?.amount >= 0
                    ? theme.palette.color.green[500]
                    : theme.palette.color.red[500]
                  : getTailwindColor(entry?.stroke || "slate", 100),
              marginLeft: "0.1rem",
            }}
          >
            {pattern}
          </div>
        ) : null}
        <p
          style={{
            display: "inline-flex",
            alignItems: "center",
            fontSize: "0.8rem",
            fontWeight: 600,
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.color.white,
            width: "6rem",
            textAlign: "left",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            lineHeight: "normal",
            marginLeft: icon ? "1rem" : 0,
            marginBlock: "0rem",
          }}
        >
          {`${t(entry?.name)} ${
            entry?.late_amount > 0 ? `/ ${t("Late")}` : ""
          }`}
        </p>
      </span>

      <p
        style={{
          display: "inline-flex",
          alignItems: "center",
          fontSize: "0.8rem",
          fontWeight: 600,
          fontFamily: theme.typography.fontFamily,
          color: theme.palette.color.white,
          marginLeft: "0.5rem",
          marginBlock: "0rem",
        }}
      >
        {`${formatAmount({
          amount: String(parseFloat(entry?.amount ?? 0).toFixed(0)),
        })} ${
          entry?.late_amount
            ? `/ ${formatAmount({
                amount: String(parseFloat(entry?.late_amount ?? 0).toFixed(0)),
              })}`
            : ""
        }`}
      </p>
    </div>
  );
};

const ItemView2 = ({
  t,
  entry,
  mergeText = "",
  helperText = "",
  backgroundColor,
  noPattern = false,
  type,
}) => {
  const highlightedScenarios = useSelector(
    (state) => state.boardSlice?.highlightedScenarios
  );
  const scenarioById = useSelector((state) => state.globalSlice.scenarioById);
  const deferredHighlightedScenarios = useDeferredValue(highlightedScenarios);

  let VAT = 0;
  let VAT_Imputation = 0;

  if (deferredHighlightedScenarios) {
    let _scenario =
      scenarioById?.[deferredHighlightedScenarios?.[0]]?.[0]?.title;
    VAT = entry?.[`VAT_${_scenario}`] || 0;
    VAT_Imputation = entry?.[`VAT_Imputation_${_scenario}`] || 0;
  }
  return (
    <div
      style={{
        display: "inline-flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "space-between",
        marginBottom: "0.5rem",
      }}
    >
      <span
        style={{
          display: "inline-flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: 16,
            height: 16,
            borderRadius: 4,
            backgroundColor: backgroundColor
              ? backgroundColor
              : getTailwindColor(entry?.stroke || "slate", 500),
            marginLeft: "0.1rem",
          }}
        >
          {!noPattern ? (
            <svg width="100%" height="100%" style={{ borderRadius: "6px" }}>
              <defs>
                <pattern
                  id={`myPattern-${entry?.key?.replace(" ", "-")}`}
                  width="10"
                  height="10"
                  patternUnits="userSpaceOnUse"
                  patternTransform="rotate(45)"
                >
                  <circle
                    cx="4"
                    cy="4"
                    r="3"
                    fill={
                      entry?.name === "Base"
                        ? entry?.amount >= 0
                          ? theme.palette.color.green[500]
                          : theme.palette.color.red[500]
                        : getTailwindColor(entry?.stroke || "slate", 500)
                    }
                    fillOpacity={0.5}
                  />
                </pattern>
              </defs>
              <rect
                width="100%"
                height="100%"
                fill={`url(#myPattern-${entry?.key?.replace(" ", "-")})`}
              />
            </svg>
          ) : null}
        </div>
        <p
          style={{
            display: "flow-root",
            alignItems: "center",
            fontSize: "0.7rem",
            fontWeight: 800,
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.color.white,
            width: "7rem",
            textAlign: "left",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            lineHeight: "normal",
            marginLeft: "1.1rem",
            marginBlock: "0rem",
          }}
        >
          {`${t(entry?.name)} ${t(mergeText)}`}{" "}
          {helperText ? (
            <span style={{ fontWeight: 500 }}>({t(helperText)})</span>
          ) : null}
        </p>
      </span>

      <p
        style={{
          display: "inline-flex",
          alignItems: "center",
          fontSize: "0.7rem",
          fontWeight: 700,
          fontFamily: theme.typography.fontFamily,
          color: theme.palette.color.white,
          marginLeft: "0.1rem",
          marginBlock: "0rem",
        }}
      >
        {type === "vat"
          ? formatAmount({
              amount: String(parseFloat(VAT ?? 0).toFixed(0)),
            })
          : `${formatAmount({
              amount: String(
                parseFloat(
                  entry?.[`liquidity_start_${entry.name}`] ?? 0
                ).toFixed(0)
              ),
            })} / ${formatAmount({
              amount: String(
                parseFloat(entry?.[`liquidity_end_${entry.name}`] ?? 0).toFixed(
                  0
                )
              ),
            })}`}

        {type === "vat" ? (
          <span
            style={{
              display: "inline-flex",
              alignItems: "center",
              fontWeight: 600,
              marginLeft: "0.1rem",
            }}
          >
            ({entry?.monthly_amount > 0 ? (type === "vat" ? "-" : "+") : ""}
            {formatAmount({
              amount:
                type === "vat" ? VAT_Imputation : entry?.monthly_amount ?? 0,
            })}
            )
          </span>
        ) : null}
      </p>
    </div>
  );
};
