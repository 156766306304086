import { Container, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { TbError404 } from "react-icons/tb";
import { useDispatch } from "react-redux";
import React from "react";

import { setPageNotFound } from "../store/slices/common";
import Icon from "../components/Icon";
import theme from "../theme";

const NotFoundPage = ({ isLogin }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const onClickHome = () => {
    navigate("/", { replace: true });
    dispatch(setPageNotFound(false));
  };

  React.useEffect(() => {
    if (!isLogin) {
      navigate("/login", { replace: true });
    }
  }, [isLogin, navigate]);

  return (
    <Container
      component="main"
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        backgroundColor: "background.paper",
      }}
    >
      <Typography
        variant="h1"
        component="h2"
        gutterBottom
        fontWeight={"fontWeightBold"}
        color={theme.palette.color.slate[600]}
      >
        4O4
      </Typography>
      <Typography
        variant="h4"
        component="h2"
        gutterBottom
        color={theme.palette.color.slate[600]}
      >
        {t("Page Not Found")}
      </Typography>
      <Typography
        variant="body1"
        component="p"
        color="color.description"
        sx={{ maxWidth: 400, mb: 4 }}
      >
        {t(
          "Sorry, the page you are looking for does not exist. You can return to the home page"
        )}
      </Typography>
      <Button variant="contained" color="primary" onClick={onClickHome}>
        {t("Go to Home")}
      </Button>
    </Container>
  );
};

export default NotFoundPage;
