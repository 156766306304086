import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Box } from "@mui/material";
import React from "react";

const SortableColumnHeader = ({
  label,
  sortField,
  currentSortField,
  sortBy,
  onSort,
  sx,
}) => {
  const isSorted = sortField === currentSortField;

  const handleSortClick = () => {
    if (!isSorted || sortBy === "default") {
      onSort(sortField, "asc"); // Start sorting ascending
    } else if (sortBy === "asc") {
      onSort(sortField, "desc"); // Then switch to descending
    } else {
      onSort(sortField, "default"); // Finally, reset to default
    }
  };

  return (
    <Box
      onClick={handleSortClick}
      sx={{ display: "flex", alignItems: "center", cursor: "pointer", ...sx }}
    >
      {label}
      {isSorted ? (
        sortBy === "desc" ? (
          <ArrowUpwardIcon fontSize="small" />
        ) : sortBy === "asc" ? (
          <ArrowDownwardIcon fontSize="small" />
        ) : null // No icon for 'default'
      ) : null}
    </Box>
  );
};

export default SortableColumnHeader;
