import { DialogContent, DialogActions, useTheme, Dialog } from "@mui/material";
import * as React from "react";

import OverlayFooter from "../Overlay/OverlayFooter";
import OverlayHeader from "../Overlay/OverlayHeader";

export default function CustomModal({
  open,
  onClose,
  children,
  hideAction,
  modalType,
  onAdd,
  textAdd,
  heading,
  hideClose = false,
  loadingAdd = false,
  disableADD = false,
  icon = null,
  linkView = null,
  dialogContentStyle = {},
  PaperPropsSx = {},
  rootStyle = {},
  footerStyle = {},
  restProps = {},
  backdropClose = true,
  tooltipAdd,
  backgroundColor,
  footerView,
  height = "fit-content",
  width = "fit-content",
}) {
  const theme = useTheme();
  return (
    <Dialog
      onClose={backdropClose ? onClose : null}
      aria-labelledby="customized-dialog-title"
      open={!!open}
      PaperProps={{
        sx: {
          maxWidth: "none",
          maxHeight: "90vh",
          position: "fixed",
          display: "flex",
          m: 0,
          p: "0.3rem",
          flexDirection: "column",
          overflow: "hidden",
          boxShadow: theme.boxShadow,
          borderRadius: theme.borderRadius.borderRadiusXL2,
          backgroundColor: theme.palette.color.overlayBorderShade,
          backdropFilter: "blur(2px)",
          ...PaperPropsSx,
        },
      }}
      sx={{
        position: "fixed",
        ...rootStyle,
      }}
      {...restProps}
    >
      <div
        style={{
          position: "relative",
          borderRadius: theme.borderRadius.borderRadiusXL,
          outline: `2px solid ${theme.palette.color.grey[300]}`,
          height,
          width,
          overflow: "hidden",
        }}
      >
        {heading ? (
          <OverlayHeader
            hideClose={hideClose}
            onClose={onClose}
            subTitle={heading}
            icon={icon}
            linkView={linkView}
            height={"5rem"}
          />
        ) : null}

        <DialogContent
          sx={{
            p: 0,
            backgroundColor: backgroundColor || theme.palette.color.white,
            width: "100%",
            height: `calc(100% - ${!!heading ? "5rem" : "0rem"} - ${!hideAction ? "4rem" : "0rem"})`,
            ...theme.thinScrollBar,
            ...dialogContentStyle,
          }}
        >
          {children}
        </DialogContent>
        {!hideAction && (
          <DialogActions
            sx={{
              backgroundColor: theme.palette.color.slate[50],
              py: "1rem",
              px: "1.75rem",
              borderTop: `1px solid ${theme.palette.color.slate[200]}`,
              height: "4rem",
            }}
          >
            <OverlayFooter
              footerView={footerView}
              loading={loadingAdd}
              tooltipAdd={tooltipAdd}
              modalType={modalType}
              onClickAction={disableADD || loadingAdd ? undefined : onAdd}
              onCancel={onClose}
              disable={disableADD}
              textAdd={textAdd}
              sx={{
                width: "100%",
                ...footerStyle,
              }}
            />
          </DialogActions>
        )}
      </div>
    </Dialog>
  );
}
