import {
  ResponsiveContainer,
  ComposedChart,
  CartesianGrid,
  ReferenceLine,
  Tooltip,
  XAxis,
  YAxis,
  Line,
  Bar,
} from "recharts";
import React, { useDeferredValue, useEffect, useMemo } from "react";
import { Box, alpha, useMediaQuery, useTheme } from "@mui/material";
import { TbChartAreaLineFilled } from "react-icons/tb";
import PercentIcon from "@mui/icons-material/Percent";
import { MdOutlineShowChart } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { FaArrowUp } from "react-icons/fa";
import { de, enUS } from "date-fns/locale";
import { useSelector } from "react-redux";
import format from "date-fns/format";
import { addMonths } from "date-fns";
import _ from "underscore";

import {
  getTailwindColor,
  formatAmount,
  remToPx,
} from "../../../../Helper/data";
import { Color, Constant, Fonts } from "../../../../Helper";
import useStatusHook from "../../../../hooks/useStatusHook";
import useWidth from "../../../../hooks/useWidth";
import Icon from "../../../../components/Icon";

const ComposedChartView = ({ column_width }) => {
  // console.log("🚀  ComposedChartView:");
  const currentWidth = useWidth();
  const theme = useTheme();
  const s2300 = useMediaQuery("(min-width:2300px)");
  // const s2220 = useMediaQuery("(min-width:2220px)");
  // const s2420 = useMediaQuery("(min-width:2420px)");
  // const s2550 = useMediaQuery("(min-width:2550px)");
  let shadowWidth = column_width;
  let offset = Constant.offset;
  if (s2300) {
    offset = Constant.offset_2300;
  }

  //redux
  const advanceVat = useSelector((state) => state.globalSlice.advanceVat);
  const chartLineKeys = useSelector((state) => state.boardSlice?.chartLineKeys);
  const isKanbanView = useSelector((state) => state.boardSlice.isKanbanView);

  const highlightedScenarios = useSelector(
    (state) => state.boardSlice?.highlightedScenarios
  );
  const deferredHighlightedScenarios = useDeferredValue(highlightedScenarios);
  const chartData = useSelector((state) => state.boardSlice?.chartData);
  const dailyChartData = useSelector(
    (state) => state.boardSlice?.dailyChartData
  );
  const isChartExpanded = useSelector(
    (state) => state.chartSlice?.isChartExpanded
  );

  const selectedScenarios = useSelector(
    (state) => state.globalSlice.appliedFilterlist?.kanban?.selectedScenarios
  );
  const selectedStates = useSelector(
    (state) => state.globalSlice.appliedFilterlist?.kanban?.selectedStates
  );
  const precisionValue = useSelector(
    (state) => state.globalSlice.appliedFilterlist?.kanban?.precisionValue
  );

  const isDailyChartLoading = useSelector(
    (state) => state.appSlice.isDailyChartLoading
  );
  const isMonthChartLoading = useSelector(
    (state) => state.appSlice.isMonthChartLoading
  );
  const scenarioById = useSelector((state) => state.globalSlice.scenarioById);
  const appliedFilterlist = {
    kanban: {
      precisionValue: precisionValue,
      selectedScenarios: selectedScenarios,
      selectedStates: selectedStates,
    },
  };

  //data
  let optionsKeys = useMemo(() => {
    if (chartLineKeys) {
      return _.groupBy(
        chartLineKeys.map((o1) => o1?.name && o1),
        (props) => (props && props?.name ? props?.name?.trim() : "null")
      );
    }
  }, [chartLineKeys]);

  let VAT = optionsKeys?.["VAT"]?.[0];
  let VAT_KEY = "";
  const transaction_monthly_chart = useStatusHook("transaction_monthly_chart");
  const transaction_daily_chart = useStatusHook("transaction_daily_chart");

  const isChartInProgress =
    transaction_monthly_chart?.isFetching ||
    (appliedFilterlist?.kanban?.precisionValue === 2 &&
      transaction_daily_chart?.isFetching) ||
    isMonthChartLoading ||
    isDailyChartLoading;

  // let BASE = optionsKeys?.["Base"]?.[0];
  if (deferredHighlightedScenarios) {
    let _scenario =
      scenarioById?.[deferredHighlightedScenarios?.[0]]?.[0]?.title;
    VAT_KEY = `VAT_Imputation_${_scenario}`;
  }
  const isScenarioIncludedInFilter = (title) => {
    return isKanbanView
      ? appliedFilterlist?.kanban?.selectedScenarios?.includes(title)
      : true;
  };

  // const isStateIncludedInFilter = (title) => {
  //   return appliedFilterlist?.kanban?.selectedStates?.includes(title);
  // };

  const data = useMemo(() => {
    return appliedFilterlist?.kanban?.precisionValue === 2
      ? dailyChartData?.data || []
      : chartData?.data || [];
  }, [appliedFilterlist?.kanban?.precisionValue, chartData, dailyChartData]);

  const tickFormatter = (tick) => {
    return formatAmount({
      amount: String(tick ?? 0),
    });
  };

  const chart = useMemo(() => {
    return (
      <ResponsiveContainer>
        <ComposedChart
          renderer="canvas"
          data={data}
          barSize={remToPx(currentWidth, 2)}
        >
          <CartesianGrid strokeDasharray="3" horizontal={false} />
          <XAxis
            dataKey="name"
            tickCount={12}
            type="category"
            allowDataOverflow={false}
            tickLine={false}
            tick={false}
            interval={1}
            stroke={Color.deepPurple}
            strokeWidth={0}
          />
          <ReferenceLine
            x={format(new Date(), "yyyy-MM")}
            stroke={theme?.palette?.color?.slate[200]}
            strokeDasharray="0"
          />

          <ReferenceLine
            y={0}
            fill={Color.black}
            strokeWidth={1}
            strokeDasharray="3"
            fillOpacity={0.8}
          />

          <YAxis
            type="number"
            tick={{
              dx: 0,
              fontSize: "0.6rem",
              fontWeight: 500,
              fill: Color.tailwind.grey[400],
            }}
            tickFormatter={tickFormatter}
            tickCount={8}
            interval={"preserveStartEnd"}
            strokeWidth={0}
          />

          <Tooltip
            // trigger="click"
            content={
              <CustomTooltip
                optionsKeys={optionsKeys}
                column_width={column_width}
              />
            }
            offset={offset}
            cursor={{ stroke: "transparent" }}
            wrapperStyle={{
              zIndex: 16,
              width: shadowWidth,
              height: `calc(100vh - ${Constant.HEADER_HEIGHT} - ${Constant.BANNER_HEIGHT} - 6.5rem)`,
            }}
          />

          {chartLineKeys?.map((entry) => {
            if (entry?.name && entry?.color && entry?.position > 4) {
              const color = entry?.color
                ? getTailwindColor(entry?.color, 600)
                : Color.tailwind.red[400];
              const lightColor = entry?.color
                ? getTailwindColor(entry?.color, 300)
                : Color.tailwind.red[300];
              const isScenarioAddedInFilter = isScenarioIncludedInFilter(
                entry?.name
              );

              const showHighlighted =
                deferredHighlightedScenarios?.[0] === entry?.uuid;

              let stroke_width = 3;
              if (appliedFilterlist?.kanban?.precisionValue === 2) {
                stroke_width = 2;
              }
              return (
                <React.Fragment key={`chart-${entry?.name}`}>
                  {entry.type && entry?.isSelected ? (
                    <Line
                      dataKey={entry?.name}
                      strokeWidth={1}
                      stroke={Color.tailwind.red[400]}
                      dot={appliedFilterlist?.kanban?.precisionValue === 1}
                      style={
                        entry.uuid === "Limit"
                          ? {
                              transform: `translateX(${offset}px)`,
                            }
                          : undefined
                      }
                    />
                  ) : null}

                  {entry?.isScenario &&
                  entry?.isSelected &&
                  isScenarioAddedInFilter ? (
                    <>
                      {entry?.title === "Base" ? (
                        <>
                          <Bar
                            dataKey={`income_type_booked`}
                            stackId="a"
                            fill={Color.tailwind.green[500]}
                            // stroke={Color.tailwind.green[500]}
                            // radius={entry?.isLast ? [4, 4, 0, 0] : [0, 0, 0, 0]}
                          />
                          <Bar
                            dataKey={`income_type_open_bar`}
                            stackId="a"
                            fill={Color.tailwind.green[100]}
                            // stroke={Color.tailwind.green[500]}
                            // radius={entry?.isLast ? [4, 4, 0, 0] : [0, 0, 0, 0]}
                          />
                          <defs>
                            <pattern
                              id={`income_type_remaining`}
                              width="8"
                              height="4"
                              patternUnits="userSpaceOnUse"
                              patternTransform="rotate(45)"
                            >
                              <rect
                                width="100%"
                                height="100%"
                                fill={Color.tailwind.green[200]}
                                fillOpacity={0.25}
                              />
                              <rect
                                width="2"
                                height="4"
                                fill={Color.tailwind.green[200]}
                              />
                            </pattern>
                          </defs>
                          <Bar
                            dataKey={`income_type_remaining`}
                            stackId="a"
                            fill={`url(#income_type_remaining`}
                          />
                        </>
                      ) : (
                        <>
                          <defs>
                            <pattern
                              id={`income-scenario-${entry?.uuid}`}
                              width="10"
                              height="10"
                              patternUnits="userSpaceOnUse"
                              patternTransform="rotate(45)"
                            >
                              <rect
                                width="100%"
                                height="100%"
                                fill={lightColor}
                                fillOpacity={0.25}
                              />
                              <circle
                                cx="4"
                                cy="4"
                                r="3"
                                fill={color}
                                fillOpacity={0.2}
                              />
                            </pattern>
                          </defs>
                          <Bar
                            dataKey={`income-scenario-${entry?.title}`}
                            stackId="a"
                            fill={`url(#income-scenario-${entry?.uuid}`}
                            // stroke={color}
                            // radius={entry?.isLast ? [4, 4, 0, 0] : [0, 0, 0, 0]}
                          />
                        </>
                      )}
                    </>
                  ) : null}
                  {entry?.isScenario &&
                  entry?.isSelected &&
                  isScenarioAddedInFilter ? (
                    <>
                      {entry?.title === "Base" ? (
                        <>
                          <Bar
                            dataKey={(v) =>
                              Math.abs(v[`expense_type_booked`] || 0)
                            }
                            stackId="b"
                            fill={Color.tailwind.red[500]}
                            // stroke={Color.tailwind.red[500]}
                          />
                          {advanceVat &&
                          advanceVat?.enabled &&
                          VAT?.isSelected ? (
                            <Bar
                              key={VAT_KEY}
                              dataKey={(v) => Math.abs(v[VAT_KEY] || 0)}
                              stackId="b"
                              fill={Color.tailwind.rose[400]}
                            />
                          ) : null}
                          <Bar
                            dataKey={(v) =>
                              Math.abs(v[`expense_type_open_bar`] || 0)
                            }
                            stackId="b"
                            fill={Color.tailwind.red[200]}
                            // stroke={Color.tailwind.red[500]}
                          />

                          <defs>
                            <pattern
                              id={`expense_type_remaining`}
                              width="8"
                              height="4"
                              patternUnits="userSpaceOnUse"
                              patternTransform="rotate(45)"
                            >
                              <rect
                                width="100%"
                                height="100%"
                                fill={Color.tailwind.red[200]}
                                fillOpacity={0.25}
                              />
                              <rect
                                width="2"
                                height="4"
                                fill={Color.tailwind.red[200]}
                              />
                            </pattern>
                          </defs>
                          <Bar
                            dataKey={(v) =>
                              Math.abs(v[`expense_type_remaining`] || 0)
                            }
                            stackId="b"
                            fill={`url(#expense_type_remaining`}
                          />
                        </>
                      ) : (
                        <>
                          <defs>
                            <pattern
                              id={`expense-scenario-${entry?.uuid}`}
                              width="10"
                              height="10"
                              patternUnits="userSpaceOnUse"
                              patternTransform="rotate(45)"
                            >
                              <rect
                                width="100%"
                                height="100%"
                                fill={lightColor}
                                fillOpacity={0.25}
                              />
                              <circle
                                cx="4"
                                cy="4"
                                r="3"
                                fill={color}
                                fillOpacity={0.2}
                              />
                            </pattern>
                          </defs>
                          <Bar
                            dataKey={(v) =>
                              Math.abs(
                                v[`expense-scenario-${entry?.title}`] || 0
                              )
                            }
                            stackId="b"
                            fill={`url(#expense-scenario-${entry?.uuid}`}
                            // stroke={color}
                            // radius={entry?.isLast ? [4, 4, 0, 0] : [0, 0, 0, 0]}
                          />
                        </>
                      )}
                    </>
                  ) : null}

                  {!entry.type &&
                  entry?.isScenario &&
                  entry?.isSelected &&
                  isScenarioAddedInFilter ? (
                    entry?.name === "Base" ? (
                      <>
                        <Line
                          style={{
                            transform: `translateX(${
                              appliedFilterlist?.kanban?.precisionValue === 1
                                ? offset
                                : 0
                            }px)`,
                          }}
                          strokeWidth={stroke_width}
                          stroke={getTailwindColor(color, 900)}
                          dataKey={`Base_solid`}
                          dot={appliedFilterlist?.kanban?.precisionValue === 1}
                        />
                        <Line
                          strokeWidth={stroke_width}
                          stroke={getTailwindColor(color, 600)}
                          dataKey={`liquidity_start_Base`}
                          fillOpacity={1}
                          strokeDasharray={"6 6"}
                          dot={appliedFilterlist?.kanban?.precisionValue === 1}
                          style={{
                            transform: `translateX(${
                              appliedFilterlist?.kanban?.precisionValue === 1
                                ? offset
                                : 0
                            }px)`,
                          }}
                        />
                      </>
                    ) : (
                      <Line
                        strokeWidth={
                          showHighlighted ? stroke_width + 1 : stroke_width
                        }
                        fill={entry?.can_be_deleted ? lightColor : color}
                        dataKey={`liquidity_start_${entry?.name}`}
                        fillOpacity={0.09}
                        strokeOpacity={showHighlighted ? 0.8 : 0.4}
                        stroke={color}
                        zIndex={showHighlighted ? 10 : 1}
                        strokeDasharray={"4 4"}
                        dot={false}
                        style={{
                          transform: `translateX(${
                            appliedFilterlist?.kanban?.precisionValue === 1
                              ? offset
                              : 0
                          }px)`,
                        }}
                      />
                    )
                  ) : null}
                </React.Fragment>
              );
            } else {
              return null;
            }
          })}
        </ComposedChart>
      </ResponsiveContainer>
    );
  }, [
    VAT?.isSelected,
    currentWidth,
    chartLineKeys,
    data,
    column_width,
    deferredHighlightedScenarios,
    isKanbanView,
  ]);

  return (
    <Box
      sx={{
        display: isChartExpanded ? "flex" : "none",
        width: "100%",
        height: "100%",
        minHeight: 0,
        minWidth: 0,
        position: "relative",

        "& .recharts-active-dot": {
          display: "none",
        },
        "& .recharts-cartesian-grid-vertical line:nth-of-type(2)": {
          display: "none",
        },
      }}
    >
      {!isChartInProgress && isChartExpanded ? <>{chart}</> : null}
    </Box>
  );
};
export default ComposedChartView;

const CustomTooltip = React.memo((props) => {
  let { active, payload, optionsKeys = [], column_width } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  //redux
  const start_date = useSelector(
    (state) => state.boardSlice?.dataSetData?.start_date
  );

  const precisionValue = useSelector(
    (state) => state.globalSlice.appliedFilterlist?.kanban?.precisionValue
  );
  const advanceVat = useSelector((state) => state.globalSlice.advanceVat);
  const profile = useSelector((state) => state.settingsSlice?.profile);

  useEffect(() => {
    if (props?.label) {
      const allKanbanColumn = document?.querySelectorAll(
        `[all-kanban-column="all-kanban-column"]`
      );
      allKanbanColumn?.forEach((element) => {
        let id = `column-${new Date(props?.label).getMonth() + 1}`;
        if (id === element?.id.toString()) {
          element.style.zIndex = 6;
        } else {
          element.style.zIndex = 0;
        }
      });
    }
  }, [props?.label]);
  if (
    precisionValue === 1 &&
    payload?.[0]?.payload?.name &&
    new Date(payload?.[0]?.payload?.name) >=
      addMonths(new Date(start_date), Constant.column_count)
  ) {
    return null;
  }

  if (active && payload && payload.length) {
    const data = payload[0].payload?.payload?.filter(
      (entry) =>
        entry?.key !== "Base" &&
        optionsKeys?.[entry?.key]?.[0]?.isScenario &&
        optionsKeys?.[entry?.key]?.[0]?.isSelected &&
        (entry?.amount || entry?.amount === 0)
    );
    let _data = [...data];
    _data?.reverse();

    const isCurrentMonth =
      format(new Date(payload[0]?.payload?.name), "yyyy-MM") ===
      format(new Date(), "yyyy-MM");
    const isFutureMonth =
      format(new Date(payload[0]?.payload?.name), "yyyy-MM") >
      format(new Date(), "yyyy-MM");
    const Header = () => {
      return (
        <p
          style={{
            display: "inline-flex",
            color: Color.white,
            fontFamily: Fonts.Text,
            fontSize: "1rem",
            fontWeight: 700,
            textAlign: "left",
          }}
        >
          {format(
            precisionValue === 1 && isCurrentMonth
              ? new Date()
              : new Date(payload[0]?.payload?.name),
            precisionValue === 2 || isCurrentMonth
              ? "dd MMMM yyyy"
              : "MMMM yyyy",
            { locale: profile?.locale === "de_DE" ? de : enUS }
          )}
        </p>
      );
    };

    const ItemHeader = ({
      noMargin,
      title,
      helperText = "",
      backgroundColor = Color.tailwind.purple[400],
      iconColor = Color.themeColor2,
      iconStyle,
      iconSize = "1.6rem",
      icon = <MdOutlineShowChart />,
    }) => {
      return (
        <div
          style={{
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: noMargin ? 0 : "1.5rem",
            marginBottom: "1rem",
          }}
        >
          <div
            style={{
              display: "inline-flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "inline-flex",
                alignItems: "center",
                borderRadius: "4px",
                marginRight: "1rem",
                backgroundColor: backgroundColor,
                position: "relative",
                width: "1.3rem",
                height: "1.3rem",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              <Icon
                icon={icon}
                fontSize={iconSize}
                color={iconColor}
                style={{
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  transform: `translate(-50%, -50%)`,
                  ...iconStyle,
                }}
              ></Icon>
            </div>
            <p
              style={{
                display: "inline-flex",
                color: Color.white,
                fontFamily: Fonts.Text,
                fontSize: "1rem",
                fontWeight: 600,
                textAlign: "left",
                marginBlock: "0rem",
              }}
            >
              {t(title)}
            </p>
          </div>
          {helperText ? (
            <span
              style={{
                fontSize: "0.75rem",
                fontWeight: 600,
                color: Color.white,
              }}
            >
              {t(helperText)}
            </span>
          ) : null}
        </div>
      );
    };

    const ItemView = ({
      entry,
      icon = true,
      backgroundColor,
      pattern,
      style,
    }) => {
      return (
        <div
          style={{
            display: "inline-flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between",
            marginBottom: "0.5rem",
            ...style,
          }}
        >
          <span
            style={{
              display: "inline-flex",
              alignItems: "center",
            }}
          >
            {icon ? (
              <div
                style={{
                  width: 16,
                  height: 16,
                  borderRadius: 4,
                  backgroundColor: backgroundColor
                    ? backgroundColor
                    : entry?.name === "Base"
                      ? entry?.amount >= 0
                        ? Color.tailwind.green[500]
                        : Color.tailwind.red[500]
                      : getTailwindColor(entry?.stroke, 100),
                  marginLeft: "0.1rem",
                }}
              >
                {pattern}
              </div>
            ) : null}
            <p
              style={{
                display: "inline-flex",
                alignItems: "center",
                fontSize: "0.8rem",
                fontWeight: 600,
                fontFamily: Fonts.Text,
                color: Color.white,
                width: "6rem",
                textAlign: "left",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                lineHeight: "normal",
                marginLeft: icon ? "1rem" : 0,
                marginBlock: "0rem",
              }}
            >
              {`${t(entry?.name)} ${
                entry?.late_amount > 0 ? `/ ${t("Late")}` : ""
              }`}
            </p>
          </span>

          <p
            style={{
              display: "inline-flex",
              alignItems: "center",
              fontSize: "0.8rem",
              fontWeight: 600,
              fontFamily: Fonts.Text,
              color: Color.white,
              marginLeft: "0.5rem",
              marginBlock: "0rem",
            }}
          >
            {`${formatAmount({
              amount: String(parseFloat(entry?.amount ?? 0).toFixed(0)),
            })} ${
              entry?.late_amount
                ? `/ ${formatAmount({
                    amount: String(
                      parseFloat(entry?.late_amount ?? 0).toFixed(0)
                    ),
                  })}`
                : ""
            }`}
          </p>
        </div>
      );
    };

    const ItemView2 = ({
      entry,
      mergeText = "",
      helperText = "",
      backgroundColor,
      noPattern = false,
      type,
    }) => {
      const highlightedScenarios = useSelector(
        (state) => state.boardSlice?.highlightedScenarios
      );
      const scenarioById = useSelector(
        (state) => state.globalSlice.scenarioById
      );
      const deferredHighlightedScenarios =
        useDeferredValue(highlightedScenarios);

      let VAT = 0;
      let VAT_Imputation = 0;

      if (deferredHighlightedScenarios) {
        let _scenario =
          scenarioById?.[deferredHighlightedScenarios?.[0]]?.[0]?.title;
        VAT = entry?.[`VAT_${_scenario}`] || 0;
        VAT_Imputation = entry?.[`VAT_Imputation_${_scenario}`] || 0;
      }
      return (
        <div
          style={{
            display: "inline-flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between",
            marginBottom: "0.5rem",
          }}
        >
          <span
            style={{
              display: "inline-flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: 16,
                height: 16,
                borderRadius: 4,
                backgroundColor: backgroundColor
                  ? backgroundColor
                  : getTailwindColor(entry?.stroke, 200),
                marginLeft: "0.1rem",
              }}
            >
              {!noPattern ? (
                <svg width="100%" height="100%" style={{ borderRadius: "6px" }}>
                  <defs>
                    <pattern
                      id={`myPattern-${entry?.key?.replace(" ", "-")}`}
                      width="10"
                      height="10"
                      patternUnits="userSpaceOnUse"
                      patternTransform="rotate(45)"
                    >
                      <circle
                        cx="4"
                        cy="4"
                        r="3"
                        fill={
                          entry?.name === "Base"
                            ? entry?.amount >= 0
                              ? Color.tailwind.green[500]
                              : Color.tailwind.red[500]
                            : getTailwindColor(entry?.stroke, 200)
                        }
                        fillOpacity={0.5}
                      />
                    </pattern>
                  </defs>
                  <rect
                    width="100%"
                    height="100%"
                    fill={`url(#myPattern-${entry?.key?.replace(" ", "-")})`}
                  />
                </svg>
              ) : null}
            </div>
            <p
              style={{
                display: "flow-root",
                alignItems: "center",
                fontSize: "0.7rem",
                fontWeight: 800,
                fontFamily: Fonts.Text,
                color: Color.white,
                width: "7rem",
                textAlign: "left",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                lineHeight: "normal",
                marginLeft: "1.1rem",
                marginBlock: "0rem",
              }}
            >
              {`${t(entry?.name)} ${t(mergeText)}`}{" "}
              {helperText ? (
                <span style={{ fontWeight: 500 }}>({t(helperText)})</span>
              ) : null}
            </p>
          </span>

          <p
            style={{
              display: "inline-flex",
              alignItems: "center",
              fontSize: "0.7rem",
              fontWeight: 700,
              fontFamily: Fonts.Text,
              color: Color.white,
              marginLeft: "0.1rem",
              marginBlock: "0rem",
            }}
          >
            {type === "vat"
              ? formatAmount({
                  amount: String(parseFloat(VAT ?? 0).toFixed(0)),
                })
              : `${formatAmount({
                  amount: String(
                    parseFloat(
                      entry?.[`liquidity_start_${entry.name}`] ?? 0
                    ).toFixed(0)
                  ),
                })} / ${formatAmount({
                  amount: String(
                    parseFloat(
                      entry?.[`liquidity_end_${entry.name}`] ?? 0
                    ).toFixed(0)
                  ),
                })}`}

            {type === "vat" ? (
              <span
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  fontWeight: 600,
                  marginLeft: "0.1rem",
                }}
              >
                ({entry?.monthly_amount > 0 ? (type === "vat" ? "-" : "+") : ""}
                {formatAmount({
                  amount: String(
                    parseFloat(
                      type === "vat"
                        ? VAT_Imputation
                        : entry?.monthly_amount ?? 0
                    ).toFixed(0)
                  ),
                })}
                )
              </span>
            ) : null}
          </p>
        </div>
      );
    };

    return (
      <div
        style={{
          position: "relative",
        }}
      >
        <span
          style={{
            width: column_width,
            height: `calc(125vh - ${Constant.HEADER_HEIGHT} - ${Constant.BANNER_HEIGHT} )`,
            backgroundColor:
              precisionValue === 1
                ? alpha(theme.palette.color.slate[300], 0.15)
                : "transparent",
            position: "absolute",
            right: 0,
          }}
        ></span>
        <div
          style={{
            minWidth: "20rem",
            height: "fit-content",
            borderRadius: 8,
            backgroundColor: theme.palette.primary.dark,
            display: "inline-flex",
            justifyContent: "flex-start",
            paddingInline: "1rem",
            paddingBlock: "0.5rem",
            flexDirection: "column",
            boxShadow: "0 4px -2px 0 rgba(0,0,0,0.13)",
            position: "absolute",
            left: props?.coordinate?.x > 1193 ? undefined : "120%",
            right: props?.coordinate?.x > 1193 ? "120%" : undefined,
          }}
        >
          <Header />
          <>
            {/* /////////////////// */}
            <ItemHeader
              title={`${t("Base")} ${t("Liquidity")}`}
              backgroundColor={Color.tailwind.lightGreen[700]}
              iconStyle={{ transform: `translate(-50%, -50%) rotate(10deg)` }}
              noMargin
            />
            <ItemView
              key={`liquidity-Begin`}
              icon={false}
              entry={{
                name: "Begin",
                amount: payload[0].payload["liquidity_start_Base"] ?? 0,
              }}
            />
            <ItemView
              key={`liquidity-End`}
              icon={false}
              entry={{
                name: "End",
                amount: payload[0].payload["liquidity_end_Base"] ?? 0,
              }}
            />
            <ItemView
              key={`liquidity-diff`}
              icon={false}
              entry={{
                name: "Net Change",
                amount: payload[0].payload["liquidity_net_change_Base"] ?? 0,
              }}
            />

            {/* /////////////////// */}
            <ItemHeader
              icon={<FaArrowUp />}
              iconSize="0.9rem"
              title={"Inflow"}
              backgroundColor={Color.tailwind.lightGreen[700]}
              iconStyle={{ transform: `translate(-50%, -50%) rotate(45deg)` }}
            />
            {isFutureMonth ? null : (
              <ItemView
                key={`income_type_booked`}
                backgroundColor={Color.tailwind.green[500]}
                entry={{
                  name: "overlay_actual_value",
                  amount: payload?.[0]?.payload["income_type_booked"] ?? 0,
                }}
              />
            )}

            {isCurrentMonth || isFutureMonth ? (
              <>
                <ItemView
                  key={`income_type_open`}
                  backgroundColor={Color.tailwind.green[200]}
                  entry={{
                    name: "overlay_open",
                    amount: payload?.[0]?.payload?.["income_type_open"] || 0,
                    late_amount:
                      payload?.[0]?.payload?.["income_type_open_late"] || null,
                  }}
                />

                <ItemView
                  key={`income_type_remaining`}
                  backgroundColor={Color.tailwind.green[200]}
                  pattern={
                    <svg
                      width="100%"
                      height="100%"
                      style={{ borderRadius: "6px" }}
                    >
                      <defs>
                        <pattern
                          id="myPattern"
                          width="8"
                          height="4"
                          patternUnits="userSpaceOnUse"
                          patternTransform="rotate(45)"
                        >
                          <rect
                            width="2"
                            height="4"
                            fill={Color.tailwind.green[400]}
                          />
                        </pattern>
                      </defs>
                      <rect width="100%" height="100%" fill="url(#myPattern)" />
                    </svg>
                  }
                  entry={{
                    name: "overlay_remaining",
                    amount: payload[0].payload["income_type_remaining"] ?? 0,
                  }}
                />
              </>
            ) : null}

            {/* /////////////////// */}

            <ItemHeader
              icon={<FaArrowUp />}
              iconSize="0.9rem"
              backgroundColor={Color.tailwind.red[600]}
              title={"Outflow"}
              iconStyle={{ transform: `translate(-50%, -50%) rotate(125deg)` }}
            />
            {isFutureMonth ? null : (
              <ItemView
                key={`expense_type_booked`}
                backgroundColor={Color.tailwind.red[500]}
                entry={{
                  name: "overlay_actual_value",
                  amount: payload[0].payload["expense_type_booked"] ?? 0,
                }}
              />
            )}
            {isCurrentMonth || isFutureMonth ? (
              <>
                <ItemView
                  key={`expense_type_open`}
                  backgroundColor={Color.tailwind.red[200]}
                  entry={{
                    name: "overlay_open",
                    amount: payload?.[0]?.payload?.["expense_type_open"] || 0,
                    late_amount:
                      payload?.[0]?.payload?.["expense_type_open_late"] || null,
                  }}
                />
                <ItemView
                  key={`expense_type_remaining`}
                  backgroundColor={Color.tailwind.red[200]}
                  pattern={
                    <svg
                      width="100%"
                      height="100%"
                      style={{ borderRadius: "6px" }}
                    >
                      <defs>
                        <pattern
                          id="myPatternRed"
                          width="8"
                          height="4"
                          patternUnits="userSpaceOnUse"
                          patternTransform="rotate(45)"
                        >
                          <rect
                            width="2"
                            height="4"
                            fill={Color.tailwind.red[400]}
                          />
                        </pattern>
                      </defs>
                      <rect
                        width="100%"
                        height="100%"
                        fill="url(#myPatternRed)"
                      />
                    </svg>
                  }
                  entry={{
                    name: "overlay_remaining",
                    amount: payload[0].payload["expense_type_remaining"] ?? 0,
                  }}
                />
              </>
            ) : null}
          </>
          {/* /////////////////// */}
          {_data?.length > 0 && (isCurrentMonth || isFutureMonth) ? (
            <>
              <ItemHeader
                icon={<TbChartAreaLineFilled />}
                iconSize="1.4rem"
                title={"Scenarios"}
                helperText={"chart_overlay_scenario_helper_text"}
              />
              {_data?.map((entry, index) => {
                return (
                  <ItemView2
                    key={`data-${index}`}
                    entry={entry}
                    mergeText={""}
                    backgroundColor={
                      entry?.name === "Base"
                        ? getTailwindColor(entry.color, 400)
                        : undefined
                    }
                    noPattern={entry?.name === "Base"}
                  />
                );
              })}
            </>
          ) : null}
          {/* /////////////////// */}
          {advanceVat && advanceVat?.enabled ? (
            <>
              <ItemHeader
                icon={<PercentIcon sx={{ fontSize: "1rem", strokeWidth: 2 }} />}
                title={"Vat_Base"}
              />

              <ItemView2
                key={`VAT_Imputation`}
                noPattern
                type="vat"
                helperText={"VAT_Imputation"}
                backgroundColor={Color.tailwind.rose[400]}
                entry={{
                  ...payload[0].payload,
                  name: "VAT",
                  amount: payload[0].payload["VAT"] ?? 0,
                }}
              />
            </>
          ) : null}
          {payload?.[0]?.payload["Limit"] ? (
            <ItemView
              key={`Limit`}
              backgroundColor={Color.tailwind.rose[400]}
              entry={{
                name: "Limit",
                amount: payload?.[0]?.payload["Limit"] ?? 0,
              }}
              style={{
                marginTop: "1rem",
              }}
            />
          ) : null}
        </div>
      </div>
    );
  }

  return null;
});
