import { alpha, Box, Paper, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import format from "date-fns/format";
import React from "react";

import { formatAmount } from "../../Helper/data";
import { Color } from "../../Helper";

const CategoryTooltip = ({
  active,
  payload,
  data,
  type = "pie",
  aggregates = null,
}) => {
  const theme = useTheme();

  if (active && payload && payload.length && data) {
    return (
      <Paper
        sx={{
          height: "fit-content",
          borderRadius: 2,
          backgroundColor: Color.white,
          display: "inline-flex",
          justifyContent: "flex-start",
          p: "0.5rem",
          flexDirection: "column",
          boxShadow: "0 4px -2px 0 rgba(0,0,0,0.13)",
          zIndex: 10,
        }}
      >
        {type === "stacked" && (
          <Typography
            sx={{
              display: "inline-flex",
              color: Color.black,
              fontSize: "0.8rem",
              fontWeight: 600,
              minWidth: "6.25rem",
              textAlign: "left",
              mb: "0.5rem",
            }}
          >
            {aggregates.value === "year"
              ? format(new Date(payload[0]?.payload.due_date), "yyyy")
              : aggregates.value === "month"
                ? format(new Date(payload[0]?.payload.due_date), "MMM yyyy")
                : aggregates.value === "daily"
                  ? format(new Date(payload[0]?.payload.due_date), "MMM dd")
                  : ""}
          </Typography>
        )}
        {data?.map((entry, index) => {
          const found =
            payload[0]?.payload[entry.value ? entry.value : entry.label];
          if (
            type === "pie" ||
            (type === "stacked" && (found || found === 0))
          ) {
            return (
              <Box
                key={index}
                sx={{
                  display: "inline-flex",
                  alignItems: "flex-start",
                  width: "fit-content",
                }}
              >
                <Box
                  sx={{
                    width: "0.45rem",
                    height: "0.45rem",
                    borderRadius: 3,
                    backgroundColor:
                      entry?.color ?? alpha(theme.palette.primary.main, 0.7),
                    mr: "0.5rem",
                    mt: "0.3rem",
                  }}
                />

                <Typography
                  sx={{
                    fontSize: "0.7rem",
                    fontWeight: 400,
                    textAlign: "left",
                    width: "9rem",
                  }}
                >
                  {type === "stacked" ? entry?.label : entry?.categoryName}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "0.8rem",
                    fontWeight: 600,
                    ml: "0.5rem",
                    minWidth: "7rem",
                    width: "fit-content",
                    textAlign: "right",
                  }}
                >
                  {type === "stacked"
                    ? formatAmount({
                        amount: String(
                          parseFloat(
                            payload[0]?.payload[
                              entry.value ? entry.value : entry.label
                            ] ?? 0
                          ).toFixed(1)
                        ),
                      })
                    : entry?.share}
                </Typography>
              </Box>
            );
          }
        })}
        {type === "stacked" && (
          <Box
            sx={{
              display: "inline-flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                width: "0.45rem",
                height: "0.45rem",
                borderRadius: 3,
                backgroundColor: Color.black,
                mr: "0.5rem",
                mt: "0.3rem",
              }}
            />
            <Typography
              sx={{
                fontSize: "0.7rem",
                fontWeight: 400,
                width: "10rem",
                textAlign: "left",
              }}
            >
              Total
            </Typography>
            <Typography
              sx={{
                fontSize: "0.8rem",
                fontWeight: 600,
                minWidth: "7rem",
                width: "fit-content",
                textAlign: "right",
                pr: "0.5rem",
              }}
            >
              {formatAmount({
                amount: String(
                  parseFloat(payload[0]?.payload.total ?? 0).toFixed(1)
                ),
              })}
            </Typography>
          </Box>
        )}
      </Paper>
    );
  }

  return null;
};
export default CategoryTooltip;
