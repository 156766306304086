import { Outlet } from "react-router";
import { Box } from "@mui/material";
import React from "react";

import { Color } from "../../Helper";

const Datasets = () => {
  return (
    <Box
      sx={{
        backgroundColor: Color.appThemeBg,
        textAlign: ["-webkit-center", "-moz-center"],
        height: "100%",
      }}
    >
      <Outlet />
    </Box>
  );
};
export default Datasets;
