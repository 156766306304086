import RecurringView from "./Component/RecurringView";

const tabOptions = [{ id: "0", path: "recurring-subscriptions" }];
// {
//   /* <Tab
// value="0"
// label="Recurring & Subscriptions"
// className="dashboard_overview_3_step_after"
// /> */
// }
const Contracts = () => {
  return <RecurringView />;
};

export default Contracts;
