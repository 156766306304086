import * as React from "react";
import Box from "@mui/material/Box";
import ToggleButton from "@mui/material/ToggleButton";
import StyledToggleButtonGroup from "./StyledToggleButtonGroup";
import { useTranslation } from "react-i18next";

export default React.forwardRef(function Toggle(
  { value, onChange, options, sx, ...otherProps },
  ref
) {
  const { t } = useTranslation();
  //render function
  return (
    <Box
      {...otherProps}
      ref={ref}
      sx={{
        display: "flex",
        width: "fit-content",
        flexWrap: "wrap",
        ...sx,
      }}
    >
      <StyledToggleButtonGroup
        size="small"
        value={value}
        exclusive
        onChange={onChange}
        aria-label="text alignment"
      >
        {options?.map((item) => (
          <ToggleButton key={item?.value} value={item?.value}>
            {t(item?.label)}
          </ToggleButton>
        ))}
      </StyledToggleButtonGroup>
    </Box>
  );
});
