import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setJoyRideStatus } from "../store/slices/global";
import { setSteps, startTour } from "../store/slices/tour";
import { useLocation } from "react-router";
import useDebounce from "./3-useDebounce/useDebounce";
import { useIsFetching } from "@tanstack/react-query";

// Hook
function useJoyRide({ key, allow, timer, callback }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();

  const isFetching = useIsFetching();
  //redux
  const joyRideStatus = useSelector(
    (state) => state.globalSlice?.joyRideStatus
  );
  const isAllHeaderApiFetched = useSelector(
    (state) => state.commonSlice.isAllHeaderApiFetched
  );
  const isAccountSelectOverlayOpen = useSelector(
    (state) => state.globalSlice.isAccountSelectOverlayOpen
  );
  const isFirstLoginOverlayOpen = useSelector(
    (state) => state.globalSlice.isFirstLoginOverlayOpen
  );
  const shouldRun =
    Boolean(allow) &&
    isFetching === 0 &&
    isAllHeaderApiFetched &&
    key &&
    joyRideStatus?.filters &&
    !joyRideStatus?.filters[key] &&
    !isFirstLoginOverlayOpen &&
    !isAccountSelectOverlayOpen;

  useDebounce(
    () => {
      let joyRideStatusFilter = joyRideStatus?.filters;
      if (shouldRun && joyRideStatusFilter) {
        let filterObj = {
          ...joyRideStatus,
          data_type: "transaction",
          filters: { ...joyRideStatusFilter, [key]: true },
        };
        setTimeout(() => {
            dispatch(setSteps({ key: key, t }));
            dispatch(startTour());
            dispatch(setJoyRideStatus(filterObj));
            if (callback) callback();
        }, timer);
      }
    },
    1000,
    [
      allow,
      shouldRun,
      key,
      joyRideStatus?.filters,
      t,
      timer,
      location.pathname,
      callback,
    ]
  );

  return true;
}

export default useJoyRide;
