import {
  startOfQuarter,
  startOfMonth,
  endOfQuarter,
  startOfWeek,
  subQuarters,
  endOfMonth,
  subMonths,
  endOfWeek,
  subWeeks,
  subDays,
  format,
} from "date-fns";

import { getVatPayMonths } from "./data";
import { Constant } from "./Constant";
import store from "../store";

export const getReportPagesQueryKey = (data) => {
  return ["ReportPages", { dataset: data?.dataset }];
};

export const getContactQueryKey = (data) => {
  return [
    "contact",
    {
      dataset: data?.dataset,
      type: [1, 2],
    },
  ];
};

export const getPlanningChartQueryKey = (data) => {
  let _last_month_date = null;
  if (data?.tableType === "quarterly") {
    _last_month_date = new Date(
      startOfQuarter(subQuarters(new Date(), Constant.start_column_skip_count))
    );
  } else if (data?.tableType === "weekly") {
    _last_month_date = new Date(
      startOfWeek(subWeeks(new Date(), Constant.start_column_skip_count), {
        weekStartsOn: 1,
      })
    );
  } else if (data?.tableType === "daily") {
    _last_month_date = new Date(
      subDays(new Date(), Constant.start_column_skip_count)
    );
  } else {
    _last_month_date = new Date(
      startOfMonth(subMonths(new Date(), Constant.start_column_skip_count))
    );
  }

  let from_payment_date = data?.from_date
    ? format(new Date(data?.from_date), "yyyy-MM-dd")
    : null;
  let to_payment_date = data?.to_date
    ? format(new Date(data?.to_date), "yyyy-MM-dd")
    : null;

  if (from_payment_date > _last_month_date) {
    from_payment_date = _last_month_date;
  }
  const vat_pay_months = getVatPayMonths({
    default_vat_pay_months: data?.default_vat_pay_months,
  });
  return [
    "transactions",
    {
      dataset: data?.dataset,
      type: `transaction_${data?.tableType}_chart`,
      from_date: from_payment_date,
      to_date: to_payment_date,
      subMonthNumber: vat_pay_months,
    },
  ];
};

export const pastTableQueryKey = (data) => {
  const state = store?.getState()?.globalSlice?.state;
  const from_payment_date = format(
    startOfMonth(subMonths(new Date(), Constant.Past_Month)),
    "yyyy-MM-dd"
  );
  let to_payment_date = null;
  if (data?.from_date) {
    if (data?.tableType === "quarterly") {
      to_payment_date = format(
        endOfQuarter(subQuarters(new Date(data?.from_date), 1)),
        "yyyy-MM-dd"
      );
    } else if (data?.tableType === "weekly") {
      to_payment_date = format(
        endOfWeek(subWeeks(new Date(data?.from_date), 1), {
          weekStartsOn: 1,
        }),
        "yyyy-MM-dd"
      );
    } else if (data?.tableType === "daily") {
      to_payment_date = format(
        subDays(new Date(data?.from_date), 1),
        "yyyy-MM-dd"
      );
    } else {
      to_payment_date = format(
        endOfMonth(subMonths(new Date(data?.from_date), 1)),
        "yyyy-MM-dd"
      );
    }
  }
  const multiStatesIds = state
    ?.filter((o1) => !Constant.calculationExcludeStates2.includes(o1?.title))
    ?.map((o1) => o1?.uuid);
  return [
    "transactions",
    {
      type: data?.tableType, //monthly
      dataset: data?.dataset,
      from_date: from_payment_date,
      to_date: to_payment_date,
      multiStatesIds,
    },
  ];
};

export const tableQueryKey = (data) => {
  return [
    "transactions",
    {
      type: data?.tableType, //monthly
      multiScenarioIds: data?.multiScenarioIds,
      dataset: data?.dataset,
      from_date: data?.from_date,
      to_date: data?.to_date,
    },
  ];
};
