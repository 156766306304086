import { forwardRef, useImperativeHandle } from "react";
import APICall from "../../../APICall";
import EndPoints from "../../../APICall/EndPoints";
import { setDataSetAccountList } from "../../../store/slices/board";
import { useDispatch, useSelector } from "react-redux";

const EnableInternalDSFunctions = forwardRef((props, _ref) => {
  const dispatch = useDispatch();
  const datasetAccountList = useSelector(
    (state) => state.boardSlice.datasetAccountList
  );
  const dataset = useSelector((state) => state.boardSlice?.dataSetData?.uuid);

  const updateAccountLinkedToDataSet = async (
    dataset_id,
    integration_id,
    obj
  ) => {
    await APICall(
      "patch",
      EndPoints.datasets + `${dataset_id}/data_sources/${integration_id}/`,
      obj
    ).then((response) => {
      if (response.status === 200 && response.data) {
      }
    });
  };

  const enableInternalDS = () => {
    let internal_data_source = datasetAccountList?.find(
      (o1) => o1.internal_dataset === dataset
    );
    if (!internal_data_source?.active) {
      let obj;
      let updatedData = [
        ...datasetAccountList?.map((item) => {
          if (item.internal_dataset === dataset) {
            let dataSourceId = item.datasets.find(
              (o1) => o1.dataset === dataset
            );
            obj = { ...item, active: !item?.active };
            updateAccountLinkedToDataSet(dataset, dataSourceId?.uuid, {
              active: true,
            });
            return obj;
          }
          return item;
        }),
      ];
      dispatch(setDataSetAccountList(updatedData));
    }
  };

  useImperativeHandle(_ref, () => {
    return {
      enableInternalDS,
    };
  });

  return null;
});

export default EnableInternalDSFunctions;
