import { Box, TextField, Tooltip, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Color, Fonts } from "../../Helper";
const DescriptionInput = ({
  value = "",
  onChange,
  error,
  helperText,
  fontSize="0.85rem",
  name = "",
  disabled = false,
  tooltip = "",
  variant = "outlined",
  placeholder = "",
  label = null,
  likeGoogle = false,
  hideTitle = false,
  borderRadius = 1,
  rows = 4,
  maxRows = 4,
  onBlur = () => {},
  onFocus = () => {},
  sx,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [isFocus, setIsFocus] = useState(false);

  const onFocusInput = (e) => {
    setIsFocus(true);
    onFocus(e);
  };

  const onBlurInput = (e) => {
    setIsFocus(false);
    onBlur(e);
  };
  return (
    <Box sx={sx}>
      {" "}
      {!hideTitle && (
        <Tooltip arrow placement="top" title={t(`${tooltip}`)}>
          <Typography
            variant="h6"
            component={"h6"}
            color="color.mainTitle"
            sx={{
              display: "flex",
              width: "70%",
              textAlign: "left",
              mb: "0.25rem",
              fontWeight: theme.typography.fontWeightBold,
              fontSize: fontSize ,
            }}
          >
            {" "}
            {`${t("Note")} (${"optional"})`}
          </Typography>
        </Tooltip>
      )}
      <Box
        sx={{
          height: "fit-content",
          minHeight: "2.5rem",
          ".MuiFormControl-root": {
            width: "100%",
          },
          "& .MuiInputLabel-root": {
            color: theme.palette.color.mainTitle,
            fontWeight: theme.typography.fontWeightMediumBold,
            fontSize:fontSize,
            transform: "translate(12px, 13px) scale(1)",
            "&[data-shrink=true]": {
              transform: "translate(12px, 4px) scale(1)",
            },
          },
          "& input": {
            fontSize: "0.9rem !important",
          },
          ".MuiOutlinedInput-root": {
            width: "100%",
            fontSize: fontSize,
            fontWeight: 600,
            fontFamily: Fonts.Text,
            p: 0,
            "&:hover": {
              "& .MuiOutlinedInput-notchedOutline": {
                backgroundColor: "rgba(0,0,0,0.03)",
              },
            },
          },
          ".MuiOutlinedInput-input": {
            p: "0.5rem",
            backgroundColor: Color.white,
            "&:focus": {
              p: "0.4rem",
              border: `1px solid ${Color.oInputBorderBg}`,
              borderRadius: 1,
            },
          },
          ".MuiOutlinedInput-notchedOutline": {
            border: `1px solid ${Color.oInputBorderBg}`,
          },
          ".MuiInputBase-root": {
            // p: likeGoogle && "0.6rem",
            borderRadius: borderRadius,
            backgroundColor:
              likeGoogle && isFocus ? Color.inputBG : "transparent",
            "&:before": {
              borderBottom: likeGoogle && "0px !important",
            },
            "&:hover": {
              backgroundColor: likeGoogle && Color.inputBG,
              "&:before": {
                borderBottom: likeGoogle && "0px !important",
              },
            },
            "&:focus": {
              backgroundColor: likeGoogle && Color.inputBG,
            },
            "&.Mui-disabled": {
              backgroundColor: Color.theme.grey[50],
            },
          },
        }}
      >
        <Tooltip arrow placement="top" title={likeGoogle && t(`${tooltip}`)}>
          {rows ? (
            <TextField
              id="filled-multiline-flexible"
              multiline
              maxRows={maxRows}
              rows={rows}
              size="small"
              name={name}
              value={value || ""}
              onChange={onChange}
              disabled={disabled}
              helperText={helperText}
              error={error}
              variant={variant}
              placeholder={placeholder}
              label={label}
              onFocus={onFocusInput}
              onBlur={onBlurInput}
            />
          ) : (
            <TextField
              id="filled-multiline-flexible"
              multiline
              maxRows={maxRows}
              size="small"
              name={name}
              value={value || ""}
              onChange={onChange}
              disabled={disabled}
              helperText={helperText}
              error={error}
              variant={variant}
              placeholder={placeholder}
              label={label}
              onFocus={onFocus}
              onBlur={onBlurInput}
            />
          )}
        </Tooltip>
      </Box>
    </Box>
  );
};
export default DescriptionInput;
